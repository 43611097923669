export const ORDER_FORM_GOOD_COLORS = [
  '#5D73FE',
  '#F4AA3B',
  '#FF6B66',
  '#48C0A0',
  '#C080CF',
  '#9CB25B',
  '#D85DA5',
  '#956952',
  '#455B99',
  '#368C76',
] as const;
