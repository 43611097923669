<div class="marker">
  <onyx-icon class="marker-icon" [name]="icon()" [size]="12"></onyx-icon>
</div>

<div class="header">
  <div class="name f-h6">
    @if (border().address; as address) {
      <p>{{ address.zipCode }} {{ address.city }}</p>
    } @else {
      <app-address
        [address]="$any({ coordinates: border().coordinates })"
        format="coordinates"
      ></app-address>
    }

    <onyx-flags [flags]="[border().from, border().to]" [size]="16"></onyx-flags>
  </div>

  <p class="f-medium-4 f-gray">{{ 'labels.borderCrossing' | translate }}</p>
</div>

<div class="actions">
  <onyx-icon-button
    type="ghost"
    color="black"
    size="s"
    [circle]="true"
    [selected]="true"
    onyxTooltip="labels.copyCoordinates"
    onyxTooltipColor="white"
    (click)="copyCoordinates()"
  >
    <onyx-icon name="copy" [size]="12"></onyx-icon>
  </onyx-icon-button>
</div>
