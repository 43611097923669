@let displayValue = decimalValue();
@if (displayValue != null) {
  @if (!disablePipe()) {
    {{ displayValue | number: pipeFormat() }}
  } @else {
    {{ displayValue }}
  }
  {{ unit() }}
} @else {
  -
}
