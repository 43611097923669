<form
  [formGroup]="form()"
  [ngClass]="{ fix: type() === OrderTimeWindowType.FIX }"
>
  @let controls = form().controls;
  <onyx-form-group [controls]="controls.date">
    <onyx-datepicker
      [formControl]="controls.date"
      label="labels.day"
      [forceError]="isOverlapping()"
      [showErrors]="false"
    ></onyx-datepicker>

    <onyx-suggestions
      [control]="controls.date"
      [suggestions]="DATE_SUGGESTIONS"
    ></onyx-suggestions>
  </onyx-form-group>

  @switch (type()) {
    @case (OrderTimeWindowType.FIX) {
      <onyx-timepicker
        [formControl]="controls.time"
        [label]="timeWindowTimeLabel()"
        [forceError]="isOverlapping()"
      ></onyx-timepicker>
    }
    @default {
      <onyx-form-group [controls]="controls.timeRange">
        <onyx-timepicker
          [formControl]="controls.timeRange"
          [label]="timeWindowTimeLabel()"
          [showTimeRange]="true"
          [forceError]="isOverlapping()"
          [showErrors]="false"
        ></onyx-timepicker>

        <onyx-suggestions
          [control]="controls.timeRange"
          [suggestions]="TIME_RANGE_SUGGESTIONS"
        ></onyx-suggestions>
      </onyx-form-group>
    }
  }

  <div class="options">
    @if (!(first() && last())) {
      <onyx-icon-button
        class="remove-button"
        type="transparent"
        color="black"
        size="m"
        (click)="remove.emit()"
      >
        <onyx-icon name="delete" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }

    @if (last() && type() !== OrderTimeWindowType.FIX) {
      <div class="separator"></div>

      <onyx-button type="outlined" color="black" (click)="add.emit()">
        <onyx-icon
          leftIcon
          class="add-icon"
          name="zoom-in"
          [size]="16"
        ></onyx-icon>

        {{ 'labels.anotherDay' | translate }}
      </onyx-button>
    }
  </div>
</form>

@if (isOverlapping()) {
  <p class="error f-label-2">{{ I18N + '.overlapError' | translate }}</p>
}
