<form [formGroup]="form()">
  @let controls = form().controls;
  <onyx-form-group [controls]="controls.paymentDay" width="200px">
    <onyx-datepicker
      [formControl]="controls.paymentDay"
      label="labels.paymentDate"
      [showErrors]="false"
    ></onyx-datepicker>

    <onyx-suggestions
      [suggestions]="DATE_SUGGESTIONS"
      [control]="controls.paymentDay"
    ></onyx-suggestions>
  </onyx-form-group>

  <onyx-toggle
    [label]="I18N + '.partiallyPaid'"
    [(value)]="isPartiallyPaid"
    [disabled]="
      [OrderStatus.PARTIALLY_PAID, OrderStatus.PAID].includes(
        this.order().status.value
      )
    "
  ></onyx-toggle>

  @if (isPartiallyPaid()) {
    <onyx-form-group
      class="partial-payment"
      [controls]="controls.partialPayment"
      width="170px"
    >
      <onyx-text-field
        [formControl]="controls.partialPayment"
        [decimalPlaces]="0"
        [unit]="order().price.currency | uppercase"
        [showErrors]="false"
      ></onyx-text-field>

      <onyx-suggestions
        [control]="controls.partialPayment"
        [suggestions]="remainingPriceSuggestions()"
      ></onyx-suggestions>
    </onyx-form-group>
  }
</form>
