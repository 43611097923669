import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { OnyxTimeRange } from '../components';

export const onyxTimeRangeValidator: ValidatorFn = (
  control: AbstractControl<OnyxTimeRange | null>,
): ValidationErrors | null => {
  if (!control.value) return null;

  if (control.value.from >= control.value.to) {
    return { timeRange: true };
  }
  return null;
};
