import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxAmountPipe,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxModalService,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { isEqual } from 'lodash';
import { filter, switchMap, tap } from 'rxjs';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { I18nPipe } from '../../../../../common/pipes/i18n.pipe';
import { JoinPipe } from '../../../../../common/pipes/join.pipe';
import { DictionariesService } from '../../../../../common/services/dictionaries.service';
import { OrderFormService } from '../../../common/services/order-form.service';
import {
  OrderFormRouteConfigModalComponent,
  OrderFormRouteConfigModalData,
  OrderFormRouteConfigModalResult,
} from '../order-form-route-config-modal/order-form-route-config-modal.component';
import { OrderFormRouteModalFooterComponent } from './order-form-route-modal-footer/order-form-route-modal-footer.component';
import { OrderFormRouteModalRouteComponent } from './order-form-route-modal-route/order-form-route-modal-route.component';
import { OrderFormRouteModalSummaryComponent } from './order-form-route-modal-summary/order-form-route-modal-summary.component';
import { OrderFormRouteModalTabsComponent } from './order-form-route-modal-tabs/order-form-route-modal-tabs.component';

@Component({
  selector: 'app-order-form-route-modal',
  standalone: true,
  imports: [
    OnyxModalComponent,
    OnyxIconComponent,
    I18nPipe,
    TranslatePipe,
    OnyxIconButtonComponent,
    JoinPipe,
    OrderFormRouteModalFooterComponent,
    OrderFormRouteModalSummaryComponent,
    AsyncPipe,
    OnyxAmountPipe,
    OrderFormRouteModalTabsComponent,
    OrderFormRouteModalRouteComponent,
    OnyxTooltipDirective,
    DecimalPipe,
  ],
  templateUrl: './order-form-route-modal.component.html',
  styleUrl: './order-form-route-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormRouteModalComponent {
  protected readonly I18N = 'orders.orderForm.route';

  protected readonly DictionaryCode = DictionaryCode;

  protected order = this.orderFormService.order;
  protected price$ = this.orderFormService.price$;
  protected hasSafeParking = computed(
    () => this.orderFormService.value().parameters.hasSafeParking,
  );
  protected routeConfig = this.orderFormService.routeConfig;

  protected fuelUnit$ = toObservable(this.routeConfig).pipe(
    switchMap((config) =>
      this.dictionariesService.getFuelUnit(config?.fuelType),
    ),
  );

  constructor(
    private orderFormService: OrderFormService,
    private modalService: OnyxModalService,
    private dictionariesService: DictionariesService,
  ) {}

  protected editRouteConfig(): void {
    this.modalService
      .open<OrderFormRouteConfigModalData, OrderFormRouteConfigModalResult>(
        OrderFormRouteConfigModalComponent,
        this.routeConfig(),
      )
      .result.pipe(
        filter((config) => config != null),
        filter((config) => !isEqual(config, this.routeConfig())),
        tap((config) => this.orderFormService.routeConfig.set(config)),
        switchMap(() => this.orderFormService.updateRoutes()),
      )
      .subscribe();
  }
}
