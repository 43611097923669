<div class="details f-medium-2">
  <div class="item">
    <onyx-icon
      [name]="includedInOrder() ? 'check-fill' : 'minus'"
      [size]="16"
    ></onyx-icon>

    <div class="content include-in-order">
      <p [class.f-gray]="!includedInOrder()">
        {{ `${I18N}.${includedInOrder() ? 'includedInOrder' : 'notIncludedInOrder'}` | translate }}
      </p>

      <onyx-tooltip
        [tooltip]="`${I18N}.${includedInOrder() ? 'includedInOrderHint' : 'notIncludedInOrderHint'}`"
      ></onyx-tooltip>
    </div>
  </div>

  @if (transitRoute$() | async; as transitRoute) {
    <div class="item">
      <onyx-icon name="contractor" [size]="16"></onyx-icon>

      <p>{{ transitRoute.operator }}</p>
    </div>

    <div class="item">
      <onyx-icon name="location" [size]="16"></onyx-icon>

      <div class="content">
        @let fromAddress = transitRoute.from.address;
        @let toAddress = transitRoute.to.address;

        <p>
          {{ transitRoute.from.name }},
          {{ fromAddress.countryCode | uppercase }} -
          {{ transitRoute.to.name }}, {{ toAddress.countryCode | uppercase }}
        </p>

        <p class="line f-gray">
          <span>{{ 'labels.from' | translate }}:</span>

          <app-address
            [address]="fromAddress"
            format="full"
            [showFlag]="false"
          ></app-address>
        </p>

        <p class="line f-gray">
          <span>{{ 'labels.to' | translate }}:</span>

          <app-address
            [address]="toAddress"
            format="full"
            [showFlag]="false"
          ></app-address>
        </p>
      </div>
    </div>
  } @else {
    <div class="item">
      <onyx-icon name="location" [size]="16"></onyx-icon>

      <div class="content">
        @if (addressName(); as name) {
          @if (poiLink(); as poiLink) {
            <button class="f-link" (click)="poiLink()">
              {{ name }}
            </button>
          } @else {
            <p>{{ name }}</p>
          }
        }

        <app-address
          [class.f-gray]="addressName()"
          [address]="address()"
          format="full"
          [showFlag]="false"
        ></app-address>
      </div>

      @if (address(); as address) {
        <div class="actions">
          <onyx-icon
            name="copy"
            [size]="16"
            [frame]="28"
            onyxTooltip="labels.copyCoordinates"
            onyxTooltipColor="white"
            (click)="
              actionHelper.copy(AddressHelper.composeCoordinatesLabel(address))
            "
          ></onyx-icon>
        </div>
      }
    </div>
  }

  @if (!isHandlingPoint()) {
    <div class="item">
      <onyx-icon name="telephone" [size]="16"></onyx-icon>

      @if (phone(); as phone) {
        @let formattedPhone = phone | onyxPhone;
        <button class="f-link" (click)="ActionHelper.makeCall(phone)">
          {{ formattedPhone }}
        </button>

        <div class="actions">
          <onyx-icon
            name="copy"
            [size]="16"
            [frame]="28"
            onyxTooltip="labels.copy"
            onyxTooltipColor="white"
            (click)="actionHelper.copy(formattedPhone)"
          ></onyx-icon>
        </div>
      } @else {
        <p class="f-gray">{{ 'labels.missingPhone' | translate }}</p>
      }
    </div>
  }

  @if (isHandlingPoint() && goods(); as goods) {
    <div class="item">
      <onyx-icon name="good" [size]="16"></onyx-icon>

      <div class="content">
        @for (good of goods; track good.uuid) {
          <p class="line">
            <span>
              {{ good.type ? (good.type.names | onyxLanguage) : good.typeName }}
            </span>
            <span class="f-gray">
              {{ good.quantity }}x {{ good.unit.names | onyxLanguage }}
            </span>
            <span class="f-gray">·</span>
            <span class="f-gray">{{ good.totalWeight | number }} kg</span>
          </p>
        }
      </div>

      <div class="actions">
        <onyx-icon
          name="side-modal"
          [size]="16"
          [frame]="28"
          onyxTooltip="labels.preview"
          onyxTooltipColor="white"
          (click)="openGoodsPreview()"
        ></onyx-icon>
      </div>
    </div>
  }

  @if (isHandlingPoint()) {
    <div class="item">
      <onyx-icon name="hashtag" [size]="16"></onyx-icon>

      <p class="line">
        @if (referenceNumber()) {
          <span class="f-gray">{{ 'labels.referenceNumber' | translate }}</span>
        }

        <span [class.f-gray]="!referenceNumber()">
          {{
            referenceNumber() || ('labels.missingReferenceNumber' | translate)
          }}
        </span>
      </p>

      @if (referenceNumber(); as referenceNumber) {
        <div class="actions">
          <onyx-icon
            name="copy"
            [size]="16"
            [frame]="28"
            onyxTooltip="labels.copy"
            onyxTooltipColor="white"
            (click)="actionHelper.copy(referenceNumber)"
          ></onyx-icon>
        </div>
      }
    </div>
  }

  <div class="item">
    <onyx-icon name="note" [size]="16"></onyx-icon>

    <p [class.f-gray]="!point().driverNote">
      {{ point().driverNote || ('labels.missingNote' | translate) }}
    </p>
  </div>
</div>

<div class="status f-medium-2">
  <app-route-point-time-windows
    [point]="point()"
  ></app-route-point-time-windows>

  <p class="line">
    <span class="f-gray">
      {{ `orders.orderForm.points.serviceTime.${point().type}` | translate }}
      ({{ 'labels.plan' | translate | lowercase }})
    </span>

    <span>
      @let serviceTime = point().serviceTime;
      {{
        serviceTime
          ? (serviceTime | duration: { format: 'to' })
          : ('labels.lack' | translate)
      }}
    </span>
  </p>
</div>
