<div class="hero-badges">
  <app-driver-modal-status [driver]="driver()"></app-driver-modal-status>

  @if (driver().status.status !== DriverStatus.ARCHIVED) {
    <app-driver-modal-delegation
      [driver]="driver()"
      [close$]="close$()"
    ></app-driver-modal-delegation>
  }
</div>

<div class="parameters">
  @let assignedVehicle = driver().assignedVehicle;
  <onyx-icon-box
    color="gray"
    [label]="
      !assignedVehicle ? ('missingVehicle' | i18n: I18N) : 'labels.vehicle'
    "
    [hasColoredBackground]="true"
    [editCallback]="assignVehicle.bind(this)"
    [showCallback]="assignedVehicle ? openVehicleModal.bind(this) : undefined"
  >
    <onyx-icon
      [name]="assignedVehicle?.generalInformation?.category ?? 'semi-truck'"
      [size]="16"
    ></onyx-icon>
    @let assignedVehicleIdentifier = assignedVehicle | fleetIdentifier;

    {{ assignedVehicleIdentifier !== '-' ? assignedVehicleIdentifier : '' }}
  </onyx-icon-box>

  <onyx-icon-box
    color="gray"
    label="labels.experience"
    [hasColoredBackground]="true"
    [editCallback]="edit.bind(this)"
  >
    <onyx-icon name="rating" [size]="16"></onyx-icon>

    @let experience = driver().skills.experienceLevel;
    @if (experience) {
      {{ experience }}/5
    } @else {
      {{ 'labels.unknown' | translate }}
    }
  </onyx-icon-box>

  @let base = driver().driverData.assignedBase;
  <onyx-icon-box
    color="gray"
    [label]="!base ? ('missingBase' | i18n: I18N) : 'labels.base'"
    [hasColoredBackground]="true"
    [editCallback]="edit.bind(this)"
    [showCallback]="base ? openBaseModal.bind(this) : undefined"
  >
    <onyx-icon name="home" [size]="16"></onyx-icon>

    {{ base?.name ?? '' }}
  </onyx-icon-box>
</div>
