import { environment } from './environment';

export const PUBLIC_API_NAMESPACE = (() => {
  switch (environment.environment) {
    case 'sandbox':
      return 'onyx-sandbox-carrier';
    case 'stage':
      return 'onyx-demo-carrier';

    default:
      return 'auth';
  }
})();

export const SENTRY_DSN =
  'https://229f3aec4883901402c1b1cd5e597142@o4504039106871296.ingest.sentry.io/4506774896377856';

export const HOTJAR_SITE_ID = 5127840;
export const HOTJAR_VERSION = 6;
