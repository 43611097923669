import {
  OnyxColor,
  OnyxIconName,
  OnyxOption,
  OnyxOptionsGroup,
} from '@onyx/angular';
import { TransportLicenseType } from '../../auth/common/enums/business/transport-license-type';
import { DelegationStatus } from '../../dashboard/delegations/common/enums/delegation-status';
import { ContractType } from '../../dashboard/drivers/common/enums/contract-type';
import { DriverSettlementMethod } from '../../dashboard/drivers/common/enums/driver-settlement-method';
import { DriversLicenseCategory } from '../../dashboard/drivers/common/enums/drivers-license-category';
import { DriverCategory } from '../../dashboard/drivers/common/interfaces/driver-category';
import { DriverStatus } from '../../dashboard/drivers/common/interfaces/driver-status';
import { FleetCategory } from '../../dashboard/fleet/common/enums/fleet-category';
import { FleetState } from '../../dashboard/fleet/common/enums/fleet-state';
import { OwnershipType } from '../../dashboard/fleet/common/enums/ownership-type';
import { SemiTrailerSize } from '../../dashboard/fleet/common/enums/semi-trailer-size';
import { TrailerSize } from '../../dashboard/fleet/common/enums/trailer-size';
import { VehiclePlanningMode } from '../../dashboard/fleet/common/enums/vehicle-planning-mode';
import { ContractorDocumentType } from '../../dashboard/management-panel/contractors/common/enums/contractor-document-type';
import { ContractorStatus } from '../../dashboard/management-panel/contractors/common/enums/contractor-status';
import { ContractorType } from '../../dashboard/management-panel/contractors/common/enums/contractor-type';
import { PaymentTermType } from '../../dashboard/management-panel/contractors/common/enums/payment-term-type';
import { CompanyDictionaryCode } from '../../dashboard/management-panel/dictionaries/common/enums/company-dictionary-code';
import { EmployeeRole } from '../../dashboard/management-panel/employees/common/enums/employee-role';
import { EmployeeStatus } from '../../dashboard/management-panel/employees/common/enums/employee-status';
import { PointOfInterestType } from '../../dashboard/management-panel/points-of-interest/common/enums/point-of-interest-type';
import { OrderCategory } from '../../dashboard/orders/common/enums/order-category';
import { OrderPointCategory } from '../../dashboard/orders/common/enums/order-point-category';
import { OrderPointType } from '../../dashboard/orders/common/enums/order-point-type';
import { OrderRateType } from '../../dashboard/orders/common/enums/order-rate-type';
import { OrderRejectReason } from '../../dashboard/orders/common/enums/order-reject-reason';
import { OrderSemiTrailerSize } from '../../dashboard/orders/common/enums/order-semi-trailer-size';
import { OrderStatus } from '../../dashboard/orders/common/enums/order-status';
import { OrderTimeWindowType } from '../../dashboard/orders/common/enums/order-time-window-type';
import { AccountType } from './account-type';
import { CalculateRouteFeature } from './calculate-route-feature';
import { DriverUnavailabilityReason } from './driver-unavailability-reason';
import { EmployeeUnavailabilityReason } from './employee-unavailability-reason';
import { FuelType } from './fuel-type';
import { GoodsSizeType } from './goods-size-type';
import { TachographWorkingMode } from './tachograph-working-mode';

export enum DictionaryCode {
  ACCOUNT_TYPE = 'account-type',
  ADR_CLASS = 'adr-class',
  CALCULATE_ROUTE_FEATURE = 'calculate-route-avoid-feature',
  CHAMBERS_NUMBER = 'chambers-number',
  COMPANY_DICTIONARY_CODE = 'company-dictionary-code',
  CONTRACT_TYPE = 'contract-type',
  CONTRACTOR_BLOCK_REASON = 'contractor-block-reason',
  CONTRACTOR_DOCUMENT_TYPE = 'contractor-document-type',
  CONTRACTOR_STATUS = 'contractor-status',
  CONTRACTOR_TYPE = 'contractor-type',
  COUNTRIES_GROUP = 'countries-group',
  COUNTRY = 'country',
  CURRENCY = 'currency',
  DELEGATION_CATEGORY = 'delegation-category',
  DELEGATION_STATUS = 'delegation-status',
  DISCHARGE_TYPE = 'discharge-type',
  DRIVER_CATEGORY = 'driver-category',
  DRIVER_EXPERIENCE_LEVEL = 'driver-experience-level',
  DRIVER_SETTLEMENT_METHOD = 'driver-settlement-method',
  DRIVER_STATUS = 'driver-status',
  DRIVER_TECHNICAL_SKILL = 'driver-technical-skill',
  DRIVER_UNAVAILABILITY_REASON = 'driver-unavailability-reason',
  DRIVERS_LICENSE_CATEGORY = 'drivers-license-category',
  EMISSION_STANDARD = 'emission-standard',
  EMPLOYEE_ROLE = 'employee-role',
  EMPLOYEE_STATUS = 'employee-status',
  EMPLOYEE_UNAVAILABILITY_REASON = 'employee-unavailability-reason',
  FLEET_STATE = 'fleet-state',
  FUEL_TYPE = 'fuel-type',
  GOODS_SIZE_TYPE = 'goods-size-type',
  INTEREST_RATE_TYPE = 'interest-rate-type',
  INTERFACE_LANGUAGE = 'interface-language',
  LANGUAGE = 'language',
  ORDER_CATEGORY = 'order-category',
  ORDER_POINT_TYPE = 'order-point-type',
  ORDER_REJECT_REASON = 'order-reject-reason',
  ORDER_SEMI_TRAILER_SIZE = 'order-semi-trailer-size',
  ORDER_STATUS = 'order-status',
  ORDER_TIME_WINDOW_TYPE = 'order-time-window-type',
  OWNERSHIP_TYPE = 'ownership-type',
  PAYMENT_TERM_TYPE = 'payment-term-type',
  POINT_OF_INTEREST_TYPE = 'point-of-interest-type',
  RATE_TYPE = 'rate-type',
  SEMI_TRAILER_SIZE = 'semi-trailer-size',
  TACHOGRAPH_MAKE = 'tachograph-make',
  TACHOGRAPH_MODEL = 'tachograph-model',
  TACHOGRAPH_WORKING_MODE = 'tachograph-working-mode',
  TANK_CERTIFICATE = 'tank-certificate',
  TRAILER_CATEGORY = 'trailer-category',
  TRAILER_MAKE = 'trailer-make',
  TRAILER_SIZE = 'trailer-size',
  TRANSPORT_LICENSE_TYPE = 'transport-license-type',
  VAT_RATE = 'vat-rate',
  VEHICLE_AXLE_COUNT = 'vehicle-axle-count',
  VEHICLE_CATEGORY = 'vehicle-category',
  VEHICLE_MAKE = 'vehicle-make',
  VEHICLE_PLANNING_MODE = 'vehicle-planning-mode',
  VEHICLE_TYPE = 'vehicle-type',
  VEHICLE_WEIGHT_CATEGORY = 'vehicle-weight-category',
}

export interface DictionaryResponseByCode {
  [DictionaryCode.ACCOUNT_TYPE]: OnyxOption<AccountType>;
  [DictionaryCode.ADR_CLASS]: OnyxOption<string>;
  [DictionaryCode.CALCULATE_ROUTE_FEATURE]: OnyxOption<CalculateRouteFeature>;
  [DictionaryCode.CHAMBERS_NUMBER]: OnyxOption<number>;
  [DictionaryCode.COMPANY_DICTIONARY_CODE]: OnyxOption<CompanyDictionaryCode>;
  [DictionaryCode.CONTRACT_TYPE]: OnyxOption<ContractType>;
  [DictionaryCode.CONTRACTOR_BLOCK_REASON]: OnyxOption<string>;
  [DictionaryCode.CONTRACTOR_DOCUMENT_TYPE]: OnyxOption<ContractorDocumentType> & {
    color: OnyxColor;
  };
  [DictionaryCode.CONTRACTOR_STATUS]: OnyxOption<ContractorStatus>;
  [DictionaryCode.CONTRACTOR_TYPE]: OnyxOption<ContractorType>;
  [DictionaryCode.COUNTRIES_GROUP]: OnyxOption<string>;
  [DictionaryCode.COUNTRY]: OnyxOption<string> & { areaCode: string };
  [DictionaryCode.CURRENCY]: OnyxOption<string> & { flag: string };
  [DictionaryCode.DELEGATION_CATEGORY]: OnyxOption<DelegationStatus> & {
    statuses: DelegationStatus[];
  };
  [DictionaryCode.DELEGATION_STATUS]: OnyxOption<DelegationStatus> & {
    color: OnyxColor;
  };
  [DictionaryCode.DISCHARGE_TYPE]: OnyxOption<string>;
  [DictionaryCode.DRIVER_CATEGORY]: OnyxOption<DriverCategory>;
  [DictionaryCode.DRIVER_EXPERIENCE_LEVEL]: OnyxOption<string>;
  [DictionaryCode.DRIVER_SETTLEMENT_METHOD]: OnyxOption<DriverSettlementMethod>;
  [DictionaryCode.DRIVER_STATUS]: OnyxOption<DriverStatus>;
  [DictionaryCode.DRIVER_TECHNICAL_SKILL]: OnyxOption<string>;
  [DictionaryCode.DRIVER_UNAVAILABILITY_REASON]: OnyxOption<DriverUnavailabilityReason>;
  [DictionaryCode.DRIVERS_LICENSE_CATEGORY]: OnyxOption<DriversLicenseCategory> & {
    requireProfessionalQualifications: boolean;
    requireDriverCard: boolean;
  };
  [DictionaryCode.EMISSION_STANDARD]: OnyxOption<string>;
  [DictionaryCode.EMPLOYEE_ROLE]: OnyxOption<EmployeeRole> & {
    color: OnyxColor;
  };
  [DictionaryCode.EMPLOYEE_STATUS]: OnyxOption<EmployeeStatus> & {
    color: OnyxColor;
  };
  [DictionaryCode.EMPLOYEE_UNAVAILABILITY_REASON]: OnyxOption<EmployeeUnavailabilityReason>;
  [DictionaryCode.FLEET_STATE]: OnyxOption<FleetState>;
  [DictionaryCode.FUEL_TYPE]: OnyxOption<FuelType> & {
    units: [string, string];
    requireAdditionalTank: boolean;
    averageSemiTruckFuelConsumption: number;
    averageStraightTruckFuelConsumption: number;
    averageVanFuelConsumption: number;
    adBlueConsumptionCoefficient: number;
  };
  [DictionaryCode.GOODS_SIZE_TYPE]: OnyxOption<GoodsSizeType>;
  [DictionaryCode.INTEREST_RATE_TYPE]: OnyxOption<string>;
  [DictionaryCode.INTERFACE_LANGUAGE]: OnyxOption<string>;
  [DictionaryCode.LANGUAGE]: OnyxOption<string>;
  [DictionaryCode.ORDER_CATEGORY]: OnyxOption<OrderCategory> & {
    statuses: OrderStatus[];
  };
  [DictionaryCode.ORDER_POINT_TYPE]: OnyxOption<OrderPointType> & {
    icon: OnyxIconName;
    color: OnyxColor;
    category: OrderPointCategory;
    includeInOrder: boolean;
    isAlwaysOpen: boolean;
  };
  [DictionaryCode.ORDER_REJECT_REASON]: OnyxOption<OrderRejectReason>;
  [DictionaryCode.ORDER_SEMI_TRAILER_SIZE]: OnyxOption<OrderSemiTrailerSize>;
  [DictionaryCode.ORDER_STATUS]: OnyxOption<OrderStatus>;
  [DictionaryCode.ORDER_TIME_WINDOW_TYPE]: OnyxOption<OrderTimeWindowType>;
  [DictionaryCode.OWNERSHIP_TYPE]: OnyxOption<OwnershipType>;
  [DictionaryCode.PAYMENT_TERM_TYPE]: OnyxOption<PaymentTermType>;
  [DictionaryCode.POINT_OF_INTEREST_TYPE]: OnyxOption<PointOfInterestType>;
  [DictionaryCode.RATE_TYPE]: OnyxOption<OrderRateType>;
  [DictionaryCode.SEMI_TRAILER_SIZE]: OnyxOption<SemiTrailerSize>;
  [DictionaryCode.TACHOGRAPH_MAKE]: OnyxOption<string>;
  [DictionaryCode.TACHOGRAPH_MODEL]: OnyxOption<string>;
  [DictionaryCode.TACHOGRAPH_WORKING_MODE]: OnyxOption<TachographWorkingMode>;
  [DictionaryCode.TANK_CERTIFICATE]: OnyxOption<string>;
  [DictionaryCode.TRAILER_CATEGORY]: OnyxOption<
    FleetCategory.SEMI_TRAILER | FleetCategory.TRAILER
  >;
  [DictionaryCode.TRAILER_MAKE]: OnyxOption<string>;
  [DictionaryCode.TRAILER_SIZE]: OnyxOption<TrailerSize>;
  [DictionaryCode.TRANSPORT_LICENSE_TYPE]: OnyxOption<TransportLicenseType>;
  [DictionaryCode.VAT_RATE]: OnyxOption<string>;
  [DictionaryCode.VEHICLE_AXLE_COUNT]: OnyxOption<number>;
  [DictionaryCode.VEHICLE_CATEGORY]: OnyxOption<
    FleetCategory.SEMI_TRUCK | FleetCategory.STRAIGHT_TRUCK | FleetCategory.VAN
  >;
  [DictionaryCode.VEHICLE_MAKE]: OnyxOption<string>;
  [DictionaryCode.VEHICLE_PLANNING_MODE]: OnyxOption<VehiclePlanningMode>;
  [DictionaryCode.VEHICLE_TYPE]: OnyxOption<string>;
  [DictionaryCode.VEHICLE_WEIGHT_CATEGORY]: OnyxOption<string>;
}

export interface DictionaryValueByCode {
  [DictionaryCode.CURRENCY]: OnyxOptionsGroup<string, { flag: string }>;
}

export type DictionaryType<Code extends DictionaryCode> =
  Code extends keyof DictionaryValueByCode
    ? DictionaryValueByCode[Code][]
    : DictionaryResponseByCode[Code][];
