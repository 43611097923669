import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Inject,
  Injector,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { chain, isEqual } from 'lodash';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { ActionHelper, AddressHelper, FormHelper } from '../../../helpers';
import { CoordinatesHelper } from '../../../helpers/coordinates.helper';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxDictionariesService, OnyxToastService } from '../../../services';
import { OnyxAddressService } from '../../../services/onyx-address.service';
import { onyxCoordinatesValidator } from '../../../validators';
import { OnyxButtonComponent, OnyxIconButtonComponent } from '../../buttons';
import { OnyxDropdownComponent } from '../../dropdown';
import { OnyxIconComponent } from '../../icons';
import { OnyxMapComponent, OnyxMapEvent, OnyxMapMarkerType } from '../../map';
import { OnyxModalComponent } from '../../modal';
import { OnyxTextFieldComponent } from '../../text-field/onyx-text-field/onyx-text-field.component';
import { OnyxToastType } from '../../toast';
import { OnyxAddressType } from '../enums';
import { OnyxCustomAddress } from '../interfaces';

export interface OnyxAddressPickerModalData {
  address: OnyxCustomAddress | null;
  heading?: string;
}

type FormAddress = Omit<
  OnyxCustomAddress,
  'type' | 'label' | 'coordinates' | 'name'
>;

@Component({
  selector: 'onyx-address-picker-modal',
  imports: [
    OnyxModalComponent,
    OnyxButtonComponent,
    ReactiveFormsModule,
    OnyxTextFieldComponent,
    OnyxDropdownComponent,
    OnyxMapComponent,
    TranslatePipe,
    AsyncPipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
  ],
  templateUrl: './onyx-address-picker-modal.component.html',
  styleUrl: './onyx-address-picker-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxAddressPickerModalComponent implements AfterViewInit {
  protected readonly I18N = `${I18N_NAMESPACE}.addressPicker`;
  protected readonly countries$ =
    this.dictionariesService.getDictionary('country');

  protected close$ = new Subject<OnyxCustomAddress | null>();
  protected form = this.buildForm();

  protected coordinatesString = computed(() =>
    CoordinatesHelper.toString(this.coordinates()),
  );

  private coordinates = toSignal(
    this.form.controls.coordinates.valueChanges.pipe(
      startWith(this.form.controls.coordinates.value),
      map((value) => (value ? CoordinatesHelper.fromString(value) : null)),
      takeUntilDestroyed(this.destroyRef),
    ),
  );
  private map = viewChild.required(OnyxMapComponent);

  constructor(
    @Inject(DIALOG_DATA) protected data: OnyxAddressPickerModalData,
    private dialogRef: DialogRef<OnyxCustomAddress | null>,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private destroyRef: DestroyRef,
    private addressService: OnyxAddressService,
    private dictionariesService: OnyxDictionariesService,
    private injector: Injector,
  ) {
    const coordinates = this.data.address?.coordinates;
    this.form.patchValue({
      street: this.data.address?.street,
      houseNumber: this.data.address?.houseNumber,
      apartmentNumber: this.data.address?.apartmentNumber,
      zipCode: this.data.address?.zipCode,
      city: this.data.address?.city,
      countryCode: this.data.address?.countryCode,
      coordinates: coordinates ? CoordinatesHelper.toString(coordinates) : '',
    });

    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        map(() => this.form.getRawValue()),
        debounceTime(500),
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        switchMap((form) => {
          const hasRequiredFields = chain(form)
            .pick(['zipCode', 'city', 'countryCode'])
            .values()
            .every()
            .value();
          if (!hasRequiredFields) return of(null);

          return this.addressService.geocode(
            [OnyxAddressType.CUSTOM],
            AddressHelper.composeLabel(form as FormAddress),
            1,
          );
        }),
        map((addresses) => addresses?.items?.[0]),
        filter((address) => address != null),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(({ coordinates }) =>
        this.form.patchValue({
          coordinates: CoordinatesHelper.toString(coordinates),
        }),
      );
  }

  public ngAfterViewInit(): void {
    effect(
      () => {
        const MARKER_ID = 'marker';

        const coordinates = this.coordinates();
        if (coordinates) {
          this.map().dispatch(
            new OnyxMapEvent.AddUpdateMarker({
              id: MARKER_ID,
              type: OnyxMapMarkerType.POINT,
              coordinates,
            }),
          );
        } else {
          this.map().dispatch(new OnyxMapEvent.RemoveMarker({ id: MARKER_ID }));
        }

        this.map().dispatch(new OnyxMapEvent.FitContent());
      },
      { injector: this.injector },
    );
  }

  protected copyCoordinates(): void {
    const coordinates = this.coordinatesString();
    if (coordinates) ActionHelper.copy(coordinates, this.toastService);
  }

  protected clear(): void {
    FormHelper.reset(this.form);
  }

  protected cancel(): void {
    this.close$.next(this.data.address);
  }

  protected save(): void {
    if (this.form.invalid) {
      FormHelper.submit(this.form);
      this.toastService.showCustom(OnyxToastType.INVALID_DATA);
      return;
    }

    const value = this.form.getRawValue() as FormAddress;
    const address = {
      ...value,
      type: OnyxAddressType.CUSTOM as const,
      label: AddressHelper.composeLabel(value),
      coordinates: this.coordinates()!,
      name: null,
    };

    this.close$.next(address);
  }

  protected close(address?: OnyxCustomAddress | null): void {
    this.dialogRef.close(address);
  }

  private buildForm() {
    return this.fb.group({
      street: this.fb.control<string | null>(null),
      houseNumber: this.fb.control<string | null>(null),
      apartmentNumber: this.fb.control<string | null>(null),
      zipCode: this.fb.control<string | null>(null, [Validators.required]),
      city: this.fb.control<string | null>(null, [Validators.required]),
      countryCode: this.fb.control<string | null>(null, [Validators.required]),
      coordinates: this.fb.control<string | null>(null, [
        Validators.required,
        onyxCoordinatesValidator,
      ]),
    });
  }
}
