<onyx-modal
  type="right"
  size="m"
  [heading]="delegation()?.identifier"
  [loading]="loading()"
  [(error)]="error"
  [extend]="true"
  [close$]="close$"
  (errorChange)="delegation$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container headingLeftOptions>
    @if (delegation()) {
      <onyx-icon class="f-gray" name="delegation" [size]="16"></onyx-icon>
    }
  </ng-container>

  <ng-container rightOptions>
    @if (delegation(); as delegation) {
      <onyx-icon-button
        class="f-gray"
        color="black"
        type="transparent"
        size="m"
        [disabled]="!options()?.length"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        onyxTooltip="labels.options"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
      </onyx-icon-button>

      @if (FleetHelper.hasTelematics(delegation.vehicle)) {
        <onyx-icon-button
          color="black"
          type="transparent"
          size="m"
          onyxTooltip="labels.showOnGantt"
          (click)="showOnGantt()"
        >
          <onyx-icon class="f-gray" name="menu-gantt" [size]="16"></onyx-icon>
        </onyx-icon-button>

        <onyx-icon-button
          color="blue"
          type="primary"
          size="m"
          [circle]="true"
          onyxTooltip="labels.showOnMap"
          (click)="showOnMap()"
        >
          <onyx-icon name="menu-map" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }
      @if (headerActionButton(); as button) {
        <onyx-button size="s" (click)="button.value()">
          {{ button.name | translate }}
        </onyx-button>
      }
    }
  </ng-container>

  <ng-container bottomOptions>
    @if (delegation(); as delegation) {
      <app-delegation-modal-heading
        [primaryDriver]="delegation.primaryDriver"
        [secondaryDriver]="delegation.secondaryDriver"
      >
      </app-delegation-modal-heading>
    }
  </ng-container>

  @if (delegation(); as delegation) {
    <div class="container">
      <app-delegation-modal-main-section
        [delegation]="delegation"
        [close$]="close$"
      ></app-delegation-modal-main-section>

      <div class="chips-container">
        <onyx-chips
          class="chips"
          [chips]="chips()"
          type="secondary"
          [single]="true"
          [wrap]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>

        @switch (group()[0]) {
          <!-- @case (DelegationModalSection.FINANCES) {
            N/A
          }
          @case (DelegationModalSection.ORDERS) {
            N/A
          }
          @case (DelegationModalSection.DOCUMENTS) {
            N/A
          }
          @case (DelegationModalSection.EXPENSES) {
            N/A
          } -->

          @case (DelegationModalSection.TACHOGRAPH) {
            <app-drivers-tachograph
              [primaryDriver]="delegation.primaryDriver"
              [secondaryDriver]="delegation.secondaryDriver"
              type="vehicle"
              format="full"
            ></app-drivers-tachograph>
          }
          @case (DelegationModalSection.NOTE) {
            <app-modal-note
              [note]="delegation.note"
              [route]="
                DelegationRoute.EDIT_DELEGATION.replace(
                  ':uuid',
                  delegation.uuid
                )
              "
              [close$]="close$"
            ></app-modal-note>
          }
        }
      </div>
    </div>
  }
</onyx-modal>
