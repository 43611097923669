import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddressHelper,
  OnyxAddresByType,
  OnyxAddress,
  OnyxAddressService,
  OnyxAddressType,
  OnyxPaginated,
} from '@onyx/angular';
import { chain } from 'lodash';
import { Observable, of } from 'rxjs';
import { AddressStorageKey } from '../enums/storage/address-storage-key';
import { CalculateRouteRequest } from '../interfaces/address/calculate-route-request';
import { CalculateRouteResponse } from '../interfaces/address/calculate-route-response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends ApiService implements OnyxAddressService {
  private recentSearches: OnyxAddress[] = [];

  constructor(protected override http: HttpClient) {
    super(http);

    const recentSearches = localStorage.getItem(
      AddressStorageKey.RECENT_SEARCHES,
    );
    this.recentSearches = JSON.parse(recentSearches ?? '[]');
  }

  public geocode<T extends OnyxAddressType>(
    types: T[],
    query: string,
    limit: number,
  ): Observable<OnyxPaginated<OnyxAddresByType[T]>> {
    return this.get('/geocode', {
      params: {
        'types[]': types,
        query,
        limit,
      },
    });
  }

  public getRecentSearches<T extends OnyxAddressType>(
    types: T[],
    limit: number,
  ): Observable<OnyxAddresByType[T][]> {
    return chain(this.recentSearches)
      .filter((address): address is OnyxAddresByType[T] =>
        types.includes(address.type as T),
      )
      .thru((addresses) => of(addresses.slice(0, limit)))
      .value();
  }

  public addToRecentSearches(address: OnyxAddress): Observable<void> {
    if (address.type !== OnyxAddressType.CUSTOM) return of(undefined);

    this.recentSearches = chain(this.recentSearches)
      .unshift(address)
      .uniqBy((address) => AddressHelper.composeCoordinatesLabel(address))
      .value();

    localStorage.setItem(
      AddressStorageKey.RECENT_SEARCHES,
      JSON.stringify(this.recentSearches),
    );

    return of(undefined);
  }

  public calculateRoute(
    body: CalculateRouteRequest,
  ): Observable<CalculateRouteResponse> {
    return this.post('/calculate-route', body);
  }
}
