import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxTooltipDirective } from '../../../directives';
import { OnyxCustomIconComponent } from '../../icons/onyx-custom-icon/onyx-custom-icon.component';
@Component({
  selector: 'onyx-flag',
  imports: [OnyxCustomIconComponent, OnyxTooltipDirective],
  templateUrl: './onyx-flag.component.html',
  styleUrl: './onyx-flag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxFlagComponent {
  public countryCode = input.required<string>();
  public size = input(16);
  public showTooltip = input(true);
}
