import { OnyxOption } from '../../directives/interfaces';
import { OnyxPagination } from './interfaces';

export const PAGINATION_LIMIT_OPTIONS: OnyxOption<number>[] = [
  10, 20, 30, 40, 50, 100,
].map((value) => ({
  name: `${value}`,
  value,
}));

export const ONYX_PAGINATION: OnyxPagination = {
  page: 1,
  limit: 20,
};
