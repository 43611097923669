import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { OnyxDatePipe, OnyxWidgetBoxComponent } from '@onyx/angular';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { DurationPipe } from '../../../../../common/pipes/duration.pipe';
import { DelegationsQueryParamKey } from '../../../../delegations/common/enums/delegations-query-param-key';
import { DelegationsRoute } from '../../../../delegations/delegations.routes';
import { Driver } from '../../../common/interfaces/driver';

@Component({
  selector: 'app-driver-modal-delegation',
  imports: [OnyxWidgetBoxComponent, TranslatePipe],
  templateUrl: './driver-modal-delegation.component.html',
  styleUrl: './driver-modal-delegation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalDelegationComponent {
  protected readonly I18N = 'drivers.driverModal';

  public driver = input.required<Driver>();
  public close$ = input.required<Subject<void>>();

  protected delegationContext = computed(() => {
    const delegation = this.driver().delegation;
    if (!delegation) {
      return {
        heading: `${this.I18N}.noDelegation`,
        content: 'labels.add',
        color: 'red',
      } as const;
    }

    if (!delegation.end.date) {
      return { content: `${this.I18N}.noEnd`, color: 'yellow' } as const;
    }

    const endDate = delegation.end.date;
    const paymentDelay = this.durationPipe.transform(endDate, {
      end: DateTime.now().startOf('day'),
      units: ['d'],
      size: 'xs',
    });

    return {
      content: `${this.translateService.instant('labels.to')} ${this.datePipe.transform(endDate, 'short-date-dot')}`,
      color: paymentDelay !== '-' ? 'red' : 'blue',
      delay: paymentDelay !== '-' ? paymentDelay : null,
    } as const;
  });

  constructor(
    private durationPipe: DurationPipe,
    private translateService: TranslateService,
    private datePipe: OnyxDatePipe,
    private router: Router,
  ) {}

  protected addDelegation(): void {
    const route = this.router
      .createUrlTree([DelegationsRoute.ADD_DELEGATION], {
        queryParams: {
          [DelegationsQueryParamKey.PRIMARY_DRIVER_UUID]: this.driver().uuid,
        },
      })
      .toString();
    this.router.navigateByUrl(route);
    this.close$().next();
  }
}
