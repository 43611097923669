import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onyxDistance',
  pure: true,
})
export class OnyxDistancePipe implements PipeTransform {
  public transform(distance: number): string | null {
    if (distance < 1000) {
      return `${distance} m`;
    }

    return `${+(distance / 1000).toFixed(1)} km`;
  }
}
