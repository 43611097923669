<section class="details">
  <p class="f-medium-5 f-gray">
    {{ `${I18N}.orderDetails` | translate | uppercase }}
  </p>

  <div class="container f-regular-3">
    <div class="item">
      <onyx-icon name="good" [size]="12"></onyx-icon>

      <div class="wrapper">
        <p class="f-gray">{{ 'labels.loads' | translate }}</p>

        <div class="box">
          @for (good of loadingGoods(); track good.uuid) {
            <p class="box-item">
              {{ good.type ? (good.type.names | onyxLanguage) : good.typeName }}
              {{ good.quantity }}x
              {{ good.unit.names | onyxLanguage }}
              {{ !$last ? '·' : '' }}
            </p>
          }
        </div>
      </div>
    </div>

    @let outsourcingData = order().outsourcingData!;
    <div class="item">
      <onyx-icon name="vehicle" [size]="12"></onyx-icon>

      <div class="wrapper">
        <p class="f-gray">{{ 'labels.vehicle' | translate }}</p>

        {{ outsourcingData.vehicleRegistrationNumber }}

        @if (outsourcingData.trailerRegistrationNumber; as trailer) {
          <p>·</p>
          <p class="f-gray">{{ 'labels.semiTrailerOrTrailer' | translate }}</p>
          {{ trailer }}
        }
      </div>
    </div>

    <div class="item">
      <onyx-icon name="user" [size]="12"></onyx-icon>

      <div class="wrapper">
        <p class="f-gray">{{ 'labels.driver' | translate }}</p>

        @let primaryDriver = outsourcingData.primaryDriver;
        <div class="box">
          {{ primaryDriver.name }}
          {{ primaryDriver.phone | onyxPhone }}
        </div>

        @if (outsourcingData.secondaryDriver; as secondaryDriver) {
          <p>·</p>
          {{ secondaryDriver.name }}
          {{ secondaryDriver.phone | onyxPhone }}
        }
      </div>
    </div>

    <div class="item start">
      <onyx-icon name="more-alt" [size]="12"></onyx-icon>

      <div class="wrapper">
        <p class="f-gray">{{ 'labels.cargoSpace' | translate }}</p>

        @let basicInformation = order().basicInformation;
        {{
          basicInformation.vehicleTypes
            | join: ', ' : 2 : DictionaryCode.VEHICLE_TYPE
        }},

        <p class="f-gray">{{ 'labels.minSemiTrailerSize' | translate }}</p>

        {{`${DictionaryCode.ORDER_SEMI_TRAILER_SIZE}.${basicInformation.minSemiTrailerSize}` | translate}}

        <app-parameters-cell
          [additionalParameters]="order().parameters"
          [visibleParametersCount]="null"
        ></app-parameters-cell>
      </div>
    </div>
  </div>
</section>
