import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxIconComponent } from '../../icons';
import { OnyxChip } from '../interfaces';

@Component({
  selector: 'onyx-chip',
  imports: [NgClass, TranslatePipe, OnyxIconComponent],
  templateUrl: './onyx-chip.component.html',
  styleUrl: './onyx-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxChipComponent<T> {
  public chip = input.required<OnyxChip<T>>();
  public active = input.required<boolean>();
  public color = input<'white' | 'blue' | 'black'>('white');
  public disabled = input(false);
}
