<app-order-point-base-form [form]="form()"></app-order-point-base-form>

<onyx-input-label
  class="goods-label"
  [label]="I18N + '.goodsToLoad' | translate"
></onyx-input-label>

<div class="goods">
  @for (control of goodsFormArray().controls; track control) {
    <app-order-loading-point-good-form
      [form]="control"
      [first]="$first"
      [goods]="goods()"
      (remove)="removeGood($index)"
    ></app-order-loading-point-good-form>
  }
</div>

<onyx-button
  class="add-good-button"
  type="outlined"
  color="black"
  (click)="addGood()"
>
  <onyx-icon leftIcon name="zoom-in" [size]="16"></onyx-icon>
  {{ I18N + '.addGood' | translate }}
</onyx-button>
