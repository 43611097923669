<section class="template">
  <app-order-pdf-template-contractors
    [order]="order()"
  ></app-order-pdf-template-contractors>

  <div class="divider"></div>

  <app-order-pdf-template-details
    [order]="order()"
  ></app-order-pdf-template-details>

  <div class="divider"></div>

  <app-order-pdf-template-route
    [order]="order()"
  ></app-order-pdf-template-route>

  <div class="divider"></div>

  <app-order-pdf-template-rate [order]="order()"></app-order-pdf-template-rate>

  @if (order().outsourcingData?.executionTerms; as terms) {
    <div class="divider"></div>

    <section class="execution-terms f-regular-4">
      <p class="f-regular-4 f-gray">
        {{ 'labels.terms' | translate | uppercase }}
      </p>

      <div class="terms">{{ terms }}</div>
    </section>
  }

  <section class="signature">
    <p class="f-regular-1">
      {{ order().author | onyxName }}
    </p>

    <div class="separator"></div>

    <div class="f-regular-5">
      {{`${I18N}.signatureBy` | translate}}
    </div>
  </section>
</section>
