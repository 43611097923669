import { TelematicsProvider } from '../enums/telematics-provider';

export class IntegrationHelper {
  public static getProviderName(provider: TelematicsProvider): string {
    return {
      [TelematicsProvider.GBOX]: 'GBox',
      [TelematicsProvider.TRANSICS]: 'Transics',
      [TelematicsProvider.ONYX]: 'ONYX ',
      [TelematicsProvider.TRIMBLE]: 'Trimble',
      [TelematicsProvider.FORMULA]: 'Formula',
    }[provider];
  }

  public static getCompanyName(name: string): string {
    return {
      inelo: 'Inelo',
      zf: 'ZF',
    }[name]!;
  }
}
