<form [formGroup]="form">
  @let controls = form.controls;
  <div class="contractor">
    <onyx-dropdown
      [formControl]="controls.contractor"
      label="labels.contractor"
      [options]="CONTRACTORS_SOURCE"
      [link]="(contractorLink$ | async) ?? undefined"
      [showAddOption]="true"
      (selectedValueChange)="contractor$.next($event)"
      (addOption)="addContractor()"
    ></onyx-dropdown>

    <onyx-dropdown
      [formControl]="controls.contractorBranch"
      label="labels.branch"
      [options]="contractorBranches$ | async"
      [showAddOption]="true"
      compareKey="uuid"
      (addOption)="editContractor()"
    ></onyx-dropdown>

    <onyx-dropdown
      [formControl]="controls.contactPerson"
      label="labels.contactPerson"
      [options]="contractorContactPersons$ | async"
      [showAddOption]="true"
      compareKey="uuid"
      (addOption)="editContractor()"
    ></onyx-dropdown>
  </div>

  <div class="vehicle">
    <onyx-text-field
      [formControl]="controls.vehicleRegistrationNumber"
      [label]="I18N + '.vehicleRegistrationNumber'"
    ></onyx-text-field>

    <onyx-text-field
      [formControl]="controls.trailerRegistrationNumber"
      [label]="I18N + '.trailerRegistrationNumber'"
    ></onyx-text-field>
  </div>

  @let primaryDriverFormGroup = controls.primaryDriver;
  <div class="driver" [formGroup]="primaryDriverFormGroup">
    @let primaryDriverControls = primaryDriverFormGroup.controls;
    <onyx-text-field
      [formControl]="primaryDriverControls.name"
      [label]="I18N + '.primaryDriverName'"
    ></onyx-text-field>

    <onyx-phone-number-input
      [formControl]="primaryDriverControls.phone"
      [label]="I18N + '.primaryDriverPhone'"
    ></onyx-phone-number-input>

    <onyx-tabs
      [tabs]="YES_NO_OPTIONS"
      size="m"
      label="labels.doubleCrew"
      [(value)]="hasDoubleCrew"
      [forceOptional]="true"
    ></onyx-tabs>
  </div>

  @if (hasDoubleCrew()) {
    @let secondaryDriverFormGroup = controls.secondaryDriver;
    <div class="driver" [formGroup]="secondaryDriverFormGroup">
      @let secondaryDriverControls = secondaryDriverFormGroup.controls;
      <onyx-text-field
        [formControl]="secondaryDriverControls.name"
        [label]="I18N + '.secondaryDriverName'"
      ></onyx-text-field>

      <onyx-phone-number-input
        [formControl]="secondaryDriverControls.phone"
        [label]="I18N + '.secondaryDriverPhone'"
      ></onyx-phone-number-input>
    </div>
  }

  <div class="billing">
    @let rateGroup = controls.rate;
    @let rateControls = rateGroup.controls;
    <onyx-form-group
      [formGroup]="rateGroup"
      [controls]="[rateControls.value, rateControls.currency]"
      [gap]="16"
    >
      <onyx-text-field
        [formControl]="rateControls.value"
        label="labels.sellRate"
        [decimalPlaces]="2"
        width="60%"
        [showErrors]="false"
      ></onyx-text-field>

      <onyx-dropdown
        [formControl]="rateControls.currency"
        label="labels.currency"
        [options]="currencies$ | async"
        width="40%"
        [showErrors]="false"
      ></onyx-dropdown>
    </onyx-form-group>

    <onyx-dropdown
      [formControl]="controls.vatRate"
      label="labels.vat"
      [options]="vatRates$ | async"
    ></onyx-dropdown>
  </div>

  <div class="margin">
    <onyx-text-field
      [formControl]="controls.marginPercentage"
      label="labels.margin"
      [decimalPlaces]="2"
      unit="%"
    ></onyx-text-field>

    <onyx-text-field
      [formControl]="controls.marginRate.controls.value"
      label="labels.marginRate"
      [decimalPlaces]="2"
      [unit]="DictionaryCode.CURRENCY + '.' + currency() | translate"
    ></onyx-text-field>
  </div>

  <onyx-textarea
    [formControl]="controls.executionTerms"
    [label]="I18N + '.executionTerms'"
    minResizeHeight="80px"
  ></onyx-textarea>

  <onyx-textarea
    [formControl]="controls.note.controls.content"
    label="labels.note"
    minResizeHeight="80px"
  ></onyx-textarea>
</form>

<onyx-button
  color="orange"
  [spinner]="loading()"
  (click)="!loading() ? submit() : null"
>
  {{ I18N + '.addOutsourcing' | translate }}
</onyx-button>
