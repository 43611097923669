import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Subject } from 'rxjs';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';
import { OrderModalOutsourcingDataComponent } from './order-modal-outsourcing-data/order-modal-outsourcing-data.component';
import { OrderModalPlanComponent } from './order-modal-plan/order-modal-plan.component';
import { OrderModalRealizedByComponent } from './order-modal-realized-by/order-modal-realized-by.component';
import { OrderModalRouteSummaryComponent } from './order-modal-route-summary/order-modal-route-summary.component';

@Component({
  selector: 'app-order-modal-summary',
  imports: [
    OrderModalRouteSummaryComponent,
    OrderModalPlanComponent,
    OrderModalOutsourcingDataComponent,
    OrderModalRealizedByComponent,
  ],
  templateUrl: './order-modal-summary.component.html',
  styleUrl: './order-modal-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalSummaryComponent {
  protected readonly OrderHelper = OrderHelper;

  public order = input.required<Order>();
  public close$ = input.required<Subject<void>>();
}
