<onyx-section [heading]="I18N + '.heading'">
  <div heading class="heading">
    <onyx-icon name="tooltip" [size]="16"></onyx-icon>

    <p class="f-medium-2">{{ i18n() + '.warning' | translate }}</p>
  </div>

  @let controls = form().controls;
  <form [formGroup]="form()">
    <div class="parameters">
      @if (showAdrClasses()) {
        <div class="item">
          <onyx-toggle
            label="labels.adr"
            [(value)]="hasAdrClasses"
            [showOptional]="false"
          ></onyx-toggle>

          @if (hasAdrClasses()) {
            <onyx-dropdown
              [formControl]="controls.adrClasses"
              placeholder="labels.adrClass"
              [options]="adrClasses$ | async"
              [multiple]="true"
              [search]="true"
              size="s"
              [showErrors]="false"
            ></onyx-dropdown>
          }
        </div>
      }

      @if (controls.hasSafeParking?.enabled) {
        <onyx-toggle
          [formControl]="controls.hasSafeParking!"
          label="labels.safeParking"
        ></onyx-toggle>
      }

      @if (controls.isDedicated?.enabled) {
        <onyx-toggle
          [formControl]="controls.isDedicated!"
          label="labels.dedicated"
        ></onyx-toggle>
      }

      @if (controls.hasEcmr?.enabled) {
        <onyx-toggle
          [formControl]="controls.hasEcmr!"
          label="labels.ecmr"
        ></onyx-toggle>
      }

      @if (controls.isExpress?.enabled) {
        <onyx-toggle
          [formControl]="controls.isExpress!"
          label="labels.isExpress"
        ></onyx-toggle>
      }

      @if (controls.hasHaccp.enabled) {
        <onyx-toggle
          [formControl]="controls.hasHaccp"
          label="labels.haccp"
        ></onyx-toggle>
      }

      @if (controls.hooksNumber.enabled) {
        <div class="item">
          <onyx-toggle
            label="labels.hooks"
            [value]="true"
            [disabled]="true"
          ></onyx-toggle>

          <onyx-text-field
            [formControl]="controls.hooksNumber"
            placeholder="labels.quantity"
            [decimalPlaces]="0"
            unit="units.pieces"
            size="s"
            [showErrors]="false"
          ></onyx-text-field>
        </div>
      }

      @if (controls.hasHdsCrane.enabled) {
        <onyx-toggle
          [formControl]="controls.hasHdsCrane"
          label="labels.hdsCrane"
        ></onyx-toggle>
      }

      @if (type() === 'order') {
        <div class="item">
          <onyx-toggle
            label="labels.foreignLanguages"
            [(value)]="hasDriverLanguages"
            [showOptional]="false"
          ></onyx-toggle>

          @if (hasDriverLanguages()) {
            <onyx-dropdown
              [formControl]="controls.driverLanguages!"
              [options]="languages$ | async"
              [multiple]="true"
              [search]="true"
              size="s"
              [showErrors]="false"
            ></onyx-dropdown>
          }
        </div>
      }

      @if (controls.hasCustomsSecuringRope.enabled) {
        <onyx-toggle
          [formControl]="controls.hasCustomsSecuringRope"
          label="labels.customsSecuringRope"
        ></onyx-toggle>
      }

      @if (controls.isLowDeck && hasLowDeck()) {
        <onyx-toggle
          [formControl]="controls.isLowDeck"
          label="labels.lowDeck"
          [onyxTooltip]="'tooltips.lowDeck' | i18n: I18N"
          [onyxTooltipEnabled]="controls.isLowDeck.disabled"
        ></onyx-toggle>
      }

      @if (showMatsNumber()) {
        <div class="item">
          <onyx-toggle
            label="labels.mats"
            [(value)]="hasMatsNumber"
            [showOptional]="false"
          ></onyx-toggle>

          @if (hasMatsNumber()) {
            <onyx-text-field
              [formControl]="controls.matsNumber"
              placeholder="labels.quantity"
              [decimalPlaces]="0"
              unit="units.pieces"
              size="s"
              [showErrors]="false"
            ></onyx-text-field>
          }
        </div>
      }

      @if (showCoilWellLength()) {
        <div class="item">
          <onyx-toggle
            label="labels.coilWell"
            [(value)]="hasCoilWellLength"
            [showOptional]="false"
          ></onyx-toggle>

          @if (hasCoilWellLength()) {
            <onyx-text-field
              [formControl]="controls.coilWellLength"
              placeholder="labels.length"
              [decimalPlaces]="2"
              unit="m"
              size="s"
              [showErrors]="false"
            ></onyx-text-field>
          }
        </div>
      }

      @if (controls.isExclusive?.enabled) {
        <div class="tooltip-item">
          <onyx-toggle
            [formControl]="controls.isExclusive!"
            label="labels.exclusive"
            [showErrors]="false"
          ></onyx-toggle>

          <onyx-tooltip
            [tooltip]="i18n() + '.exlusiveTooltip' | translate"
          ></onyx-tooltip>
        </div>
      }

      @if (controls.hasLoadingsRamps.enabled) {
        <onyx-toggle
          [formControl]="controls.hasLoadingsRamps"
          label="labels.loadingRamps"
        ></onyx-toggle>
      }

      @if (type() === 'order') {
        <div class="item">
          <onyx-toggle
            label="labels.sentNotification"
            [(value)]="hasSentNotificationNumber"
            [showOptional]="false"
          ></onyx-toggle>

          @if (hasSentNotificationNumber()) {
            <onyx-text-field
              [formControl]="controls.sentNotificationNumber!"
              placeholder="labels.number"
              size="s"
              [showErrors]="false"
            ></onyx-text-field>
          }
        </div>
      }

      @if (showBeltsNumber()) {
        <div class="item">
          <onyx-toggle
            label="labels.belts"
            [(value)]="hasBeltsNumber"
            [showOptional]="false"
          ></onyx-toggle>

          @if (hasBeltsNumber()) {
            <onyx-text-field
              [formControl]="controls.beltsNumber"
              placeholder="labels.quantity"
              [decimalPlaces]="0"
              unit="units.pieces"
              size="s"
              [showErrors]="false"
            ></onyx-text-field>
          }
        </div>
      }

      @if (controls.hasDoubleCrew?.enabled) {
        <onyx-toggle
          [formControl]="controls.hasDoubleCrew!"
          label="labels.doubleCrew"
        ></onyx-toggle>
      }

      @if (controls.hasDoubleDeck?.enabled) {
        <onyx-toggle
          [formControl]="controls.hasDoubleDeck!"
          label="labels.doubleDeck"
        ></onyx-toggle>
      }

      @if (controls.hasSanitaryInspection.enabled) {
        <onyx-toggle
          [formControl]="controls.hasSanitaryInspection"
          label="labels.sanitaryInspection"
        ></onyx-toggle>
      }

      @if (controls.temperatureRange.enabled) {
        <div class="item">
          <onyx-toggle
            label="labels.temperature"
            [value]="true"
            [disabled]="true"
          ></onyx-toggle>

          @let temperatureRangeFormGroup = controls.temperatureRange;
          <div
            class="temperature-range"
            [formGroup]="temperatureRangeFormGroup"
          >
            @let temperatureRangeControls = temperatureRangeFormGroup.controls;
            <onyx-text-field
              [formControl]="temperatureRangeControls.from"
              placeholder="labels.from"
              [decimalPlaces]="0"
              [acceptNegative]="true"
              unit="°C"
              size="s"
              width="64px"
              [showErrors]="false"
            ></onyx-text-field>

            <span>-</span>

            <onyx-text-field
              [formControl]="temperatureRangeControls.to"
              placeholder="labels.to"
              [decimalPlaces]="0"
              [acceptNegative]="true"
              unit="°C"
              size="s"
              width="64px"
              [showErrors]="false"
            ></onyx-text-field>
          </div>
        </div>
      }

      @let semiTrailerSizeControl = controls.semiTrailerSize;
      @if (semiTrailerSizeControl && hasSemiTrailerSize()) {
        <div class="item">
          <onyx-toggle
            label="labels.semiTrailerSize"
            [value]="true"
            [disabled]="true"
          ></onyx-toggle>

          @let tooltip =
            semiTrailerSizeControl.value === SemiTrailerSize.MEGA
              ? 'tooltips.mega'
              : 'tooltips.standard';
          <onyx-tabs
            [formControl]="semiTrailerSizeControl"
            [tabs]="semiTrailerSizes$ | async"
            [onyxTooltip]="tooltip | i18n: I18N"
            [onyxTooltipEnabled]="semiTrailerSizeControl.disabled"
          ></onyx-tabs>
        </div>
      }

      @let trailerSizeControl = controls.trailerSize;
      @if (trailerSizeControl && hasTrailerSize()) {
        <div class="item">
          <onyx-toggle
            label="labels.trailerFor"
            [value]="true"
            [disabled]="true"
          ></onyx-toggle>

          @let tooltip =
            trailerSizeControl.value === TrailerSize.VAN
              ? 'tooltips.van'
              : 'tooltips.straightTruck';
          <onyx-tabs
            [formControl]="trailerSizeControl"
            [tabs]="trailerSizes$ | async"
            [onyxTooltip]="tooltip | i18n: I18N"
            [onyxTooltipEnabled]="trailerSizeControl.disabled"
          ></onyx-tabs>
        </div>
      }

      @if (controls.hasTailLift.enabled) {
        <onyx-toggle
          [formControl]="controls.hasTailLift"
          label="labels.tailLift"
        ></onyx-toggle>
      }

      @if (controls.hasDumpContainer.enabled) {
        <onyx-toggle
          [formControl]="controls.hasDumpContainer"
          label="labels.dumpContainer"
        ></onyx-toggle>
      }

      @if (controls.hasForklift.enabled) {
        <onyx-toggle
          [formControl]="controls.hasForklift"
          label="labels.forklift"
        ></onyx-toggle>
      }

      @if (controls.hasPalletExchange?.enabled) {
        <onyx-toggle
          [formControl]="controls.hasPalletExchange!"
          label="labels.palletExchange"
        ></onyx-toggle>
      }
    </div>

    <onyx-dropdown
      [formControl]="controls.other"
      label="labels.otherParameters"
      [hint]="I18N + '.otherParametersHint'"
      [options]="otherParameters$ | async"
      [multiple]="true"
      [search]="true"
      [showAddOption]="true"
      (addOption)="addOtherParameter($event)"
    ></onyx-dropdown>
  </form>
</onyx-section>
