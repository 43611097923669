import { OnyxComponentInputs, OnyxTableColumn } from '@onyx/angular';
import { AddressCellComponent } from '../../../../../common/components/cells/address-cell/address-cell.component';
import { AmountCellComponent } from '../../../../../common/components/cells/amount-cell/amount-cell.component';
import { BooleanCellComponent } from '../../../../../common/components/cells/boolean-cell/boolean-cell.component';
import { CountryCellComponent } from '../../../../../common/components/cells/country-cell/country-cell.component';
import { NumberCellComponent } from '../../../../../common/components/cells/number-cell/number-cell.component';
import { PhoneCellComponent } from '../../../../../common/components/cells/phone-cell/phone-cell.component';
import { StringCellComponent } from '../../../../../common/components/cells/string-cell/string-cell.component';
import { UnitCellComponent } from '../../../../../common/components/cells/unit-cell/unit-cell.component';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ContractorCashDiscountCellComponent } from '../cells/contractor-cash-discount-cell/contractor-cash-discount-cell.component';
import { ContractorsImportData } from './contractors-import-schema';

export const CONTRACTORS_IMPORT_COLUMNS: OnyxTableColumn<
  ContractorsImportData & { index: number }
>[] = [
  {
    required: true,
    id: 'index',
    name: 'labels.row',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.index,
      }),
    },
  },

  /* companyProfile */
  {
    id: 'country',
    name: 'labels.country',
    width: 'fill',
    component: {
      ref: CountryCellComponent,
      inputs: (data): OnyxComponentInputs<CountryCellComponent> => ({
        countryCode: data.item.companyProfile.country,
      }),
    },
  },
  {
    id: 'vatId',
    name: 'labels.vatId',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.companyProfile.vatId,
      }),
    },
  },
  {
    id: 'displayName',
    name: 'labels.displayName',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.companyProfile.displayName,
      }),
    },
  },
  {
    id: 'types',
    name: 'labels.businessType',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.companyProfile.types,
        dictionaryCode: DictionaryCode.CONTRACTOR_TYPE,
      }),
    },
  },
  {
    id: 'phone',
    name: 'labels.officePhone',
    width: 'fill',
    component: {
      ref: PhoneCellComponent,
      inputs: (data): OnyxComponentInputs<PhoneCellComponent> => ({
        phone: data.item.companyProfile.phone,
      }),
    },
  },

  /* addresses */
  {
    id: 'mainAddress',
    name: 'labels.mainAddress',
    width: 'fill',
    component: {
      ref: AddressCellComponent,
      inputs: (data): OnyxComponentInputs<AddressCellComponent> => ({
        address: data.item.addresses.mainAddress,
        format: 'full',
      }),
    },
  },
  {
    id: 'correspondenceAddress',
    name: 'labels.correspondenceAddress',
    width: 'fill',
    component: {
      ref: AddressCellComponent,
      inputs: (data): OnyxComponentInputs<AddressCellComponent> => ({
        address: data.item.addresses.correspondenceAddress,
        format: 'full',
      }),
    },
  },

  /* payment */
  {
    id: 'termDays',
    name: 'labels.paymentTerm',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.payment.termDays,
        unit: 'labels.days',
      }),
    },
  },
  {
    id: 'termFrom',
    name: 'labels.termFrom',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.payment.termType,
        dictionaryCode: DictionaryCode.PAYMENT_TERM_TYPE,
      }),
    },
  },
  {
    id: 'dayOfMonth',
    name: 'labels.dayOfMonth',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.payment.selectedTermDay,
      }),
    },
  },
  {
    id: 'tradeCreditLimitValue',
    name: 'labels.tradeCredit',
    width: 'fill',
    component: {
      ref: AmountCellComponent,
      inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
        amount: data.item.payment.parameters.tradeCreditLimit,
        decimalPlaces: 0,
      }),
    },
  },
  {
    id: 'cashDiscount',
    name: 'labels.cashDiscount',
    width: '200px',
    component: {
      ref: ContractorCashDiscountCellComponent,
      inputs: (
        data,
      ): OnyxComponentInputs<ContractorCashDiscountCellComponent> => ({
        cashDiscount: data.item.payment.parameters.cashDiscount,
      }),
    },
  },
  {
    id: 'hasFactoringConsent',
    name: 'labels.hasFactoringConsent',
    width: 'fill',
    component: {
      ref: BooleanCellComponent,
      inputs: (data): OnyxComponentInputs<BooleanCellComponent> => ({
        value: data.item.payment.parameters.hasFactoringConsent,
      }),
    },
  },

  /* note */
  {
    id: 'note',
    name: 'labels.note',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.note.content || '-',
      }),
    },
  },
];
