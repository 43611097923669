@if (document(); as document) {
  <div class="information">
    <p class="f-medium-2">
      {{
        DictionaryCode.CONTRACTOR_DOCUMENT_TYPE + '.' + document.type
          | translate
      }}
    </p>

    <p class="information-date f-regular-4">
      @if (document.expirationDate?.indefinite) {
        {{ 'labels.indefiniteTime' | translate | lowercase }}
      } @else {
        {{ 'labels.to' | translate | lowercase }}
        {{ document.expirationDate?.date! | onyxDate: 'date' }}
      }
    </p>
  </div>
} @else {
  -
}
