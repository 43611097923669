<onyx-widget-box [heading]="I18N + '.loadingTo'" [color]="loadingStatusColor()">
  @let timeWindows = firstLoadingPoint().timeWindows;
  @let isDelayed = loadingStatus() === LoadingStatus.DELAYED;
  <p class="opening-details f-paragraph-semibold-2">
    @switch (timeWindows.type) {
      @case (OrderTimeWindowType.FIX) {
        {{ timeWindows.windows[0].date | onyxDate: 'date' }}
        <span>·</span>

        {{ timeWindows.windows[0].time | onyxTime }}
      }
      @default {
        @let windows = timeWindows.windows;
        {{ windows[0].date | onyxDate: 'date' }}
        <span>·</span>

        {{ windows[0].timeRange.to | onyxTime }}
      }
    }

    @if (isDelayed) {
      <span class="delayed-timer">
        +{{ loadingPointDeadline() | duration }}
      </span>
    }
  </p>
</onyx-widget-box>
