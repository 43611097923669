import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxAmountPipe,
  OnyxDistancePipe,
  OnyxIconBoxComponent,
  OnyxIconComponent,
  OnyxTooltipComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { map, switchMap } from 'rxjs';
import { FuelSummaryTooltipComponent } from '../../../../../../common/components/tooltips/fuel-summary-tooltip/fuel-summary-tooltip.component';
import { TollsTooltipComponent } from '../../../../../../common/components/tooltips/tolls-tooltip/tolls-tooltip.component';
import { DictionaryCode } from '../../../../../../common/enums/dictionary-code';
import { DurationPipe } from '../../../../../../common/pipes/duration.pipe';
import { I18nPipe } from '../../../../../../common/pipes/i18n.pipe';
import { DictionariesService } from '../../../../../../common/services/dictionaries.service';
import { TelematicsHelper } from '../../../../../map/common/helpers/telematics.helper';
import { OrderFormService } from '../../../../common/services/order-form.service';

@Component({
  selector: 'app-order-form-route-modal-summary',
  standalone: true,
  imports: [
    OnyxIconBoxComponent,
    OnyxIconComponent,
    TranslatePipe,
    DurationPipe,
    OnyxDistancePipe,
    OnyxAmountPipe,
    TollsTooltipComponent,
    OnyxTooltipDirective,
    OnyxTooltipComponent,
    DecimalPipe,
    AsyncPipe,
    I18nPipe,
    FuelSummaryTooltipComponent,
  ],
  templateUrl: './order-form-route-modal-summary.component.html',
  styleUrl: './order-form-route-modal-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormRouteModalSummaryComponent {
  protected readonly DictionaryCode = DictionaryCode;

  protected route = this.orderFormService.activeRoute;
  protected compliance = this.orderFormService.activeRouteCompliance;
  protected routeConfig = this.orderFormService.routeConfig;

  protected complianceColor = computed(() => {
    const routeCompliance = this.compliance()?.routeCompliance;
    if (!routeCompliance) return 'violet';

    return TelematicsHelper.getScheduleComplianceColor(routeCompliance);
  });
  protected fuelUnit$ = toObservable(this.routeConfig).pipe(
    switchMap((config) =>
      this.dictionariesService.getFuelUnit(config?.fuelType),
    ),
  );
  protected pricePerKm = computed(() => {
    const price = this.price();
    const distance = this.route()?.summary?.distance;
    if (!price || !distance) return null;

    return {
      ...price,
      value: price.value / (distance / 1000),
    };
  });

  private price = toSignal(
    this.orderFormService.price$.pipe(map(({ price }) => price)),
    { initialValue: null },
  );

  constructor(
    private orderFormService: OrderFormService,
    private dictionariesService: DictionariesService,
  ) {}
}
