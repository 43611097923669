@if (pointTimeWindows(); as pointTimeWindows) {
  @let timeWindows = pointTimeWindows.value;
  <onyx-widget-box [heading]="pointTimeWindows.label">
    <div class="container">
      {{ timeWindows[0] }}

      @if (timeWindows.length > 1) {
        <p
          class="count"
          [onyxTooltip]="tooltipTemplate"
          onyxTooltipColor="white"
        >
          +{{ timeWindows.length - 1 }}
        </p>
      }
    </div>
  </onyx-widget-box>

  <ng-template #tooltipTemplate>
    <div class="tooltip">
      @for (timeWindow of timeWindows; track timeWindow) {
        <p>{{ timeWindow }}</p>
      }
    </div>
  </ng-template>
}
