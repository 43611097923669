import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Injector,
  input,
  OnInit,
  Signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxAddressInputComponent,
  OnyxAddressType,
  OnyxIconBoxComponent,
  OnyxIconComponent,
  OnyxMessageComponent,
  OnyxTabsComponent,
  OnyxTextFieldComponent,
  OnyxTime,
  OnyxTimepickerComponent,
  OnyxTimePipe,
  OnyxTimeRange,
} from '@onyx/angular';
import { chain } from 'lodash';
import { DateTime } from 'luxon';
import {
  combineLatestWith,
  filter,
  map,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
} from 'rxjs';
import { DictionaryCode } from '../../../../../../common/enums/dictionary-code';
import { BusinessHoursPipe } from '../../../../../../common/pipes/business-hours.pipe';
import { DictionariesService } from '../../../../../../common/services/dictionaries.service';
import { PointsOfInterestQueryParamKey } from '../../../../../management-panel/points-of-interest/common/enums/points-of-interest-query-param-key';
import { PointOfInterest } from '../../../../../management-panel/points-of-interest/common/interfaces/point-of-interest';
import { PointsOfInterestService } from '../../../../../management-panel/points-of-interest/common/services/points-of-interest.service';
import { PointsOfInterestRoute } from '../../../../../management-panel/points-of-interest/points-of-interest.routes';
import { OrderFormRestorePointType } from '../../../../common/enums/order-form-cancel-action-type';
import { OrderPointCategory } from '../../../../common/enums/order-point-category';
import { OrderTimeWindowType } from '../../../../common/enums/order-time-window-type';
import { OrderFormService } from '../../../../common/services/order-form.service';
import { orderPointTimeWindowOverlap } from '../../../../common/validators/order-point-time-window-overlap.validator';
import { OrderLoadingPointFormGroup } from '../../order-loading-point-form/order-loading-point-form.component';
import { OrderUnloadingPointFormGroup } from '../../order-unloading-point-form/order-unloading-point-form.component';
import { OrderPointTimeWindowFormComponent } from './order-point-time-window-form/order-point-time-window-form.component';

@Component({
  selector: 'app-order-point-base-form',
  imports: [
    ReactiveFormsModule,
    OnyxIconBoxComponent,
    OnyxIconComponent,
    OnyxAddressInputComponent,
    OnyxTextFieldComponent,
    OnyxTimepickerComponent,
    OnyxTabsComponent,
    AsyncPipe,
    OrderPointTimeWindowFormComponent,
    OnyxMessageComponent,
    BusinessHoursPipe,
    OnyxTimePipe,
    TranslatePipe,
  ],
  templateUrl: './order-point-base-form.component.html',
  styleUrl: './order-point-base-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPointBaseFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.points';

  protected readonly OnyxAddressType = OnyxAddressType;
  protected readonly OrderTimeWindowType = OrderTimeWindowType;

  protected readonly timeWindowTypes$ = this.dictionariesService.getDictionary(
    DictionaryCode.ORDER_TIME_WINDOW_TYPE,
  );

  public form = input.required<
    OrderLoadingPointFormGroup | OrderUnloadingPointFormGroup
  >();

  protected timeWindowType!: Signal<OrderTimeWindowType>;

  protected pointCategory = computed(() => this.form().controls.category.value);
  protected serviceTimeLabel = computed(
    () => `${this.I18N}.serviceTime.${this.pointCategory()}`,
  );
  protected timeWindowTypeLabel = computed(
    () => `${this.I18N}.term.${this.pointCategory()}`,
  );
  protected timeWindowTimeLabel = computed(
    () =>
      ({
        [OrderTimeWindowType.INTERVAL]: 'labels.interval',
        [OrderTimeWindowType.FIX]: 'Fix',
        [OrderTimeWindowType.OPENING_HOURS]: 'labels.openingHours',
      })[this.timeWindowType()],
  );
  protected timeWindowsFormArray = computed(
    () => this.form().controls.timeWindows.controls.windows,
  );

  protected pointOfInterest!: Signal<PointOfInterest | null>;
  protected showAfterHoursWarning!: Signal<false | OnyxTimeRange | OnyxTime>;

  constructor(
    private dictionariesService: DictionariesService,
    private injector: Injector,
    private fb: NonNullableFormBuilder,
    private orderFormService: OrderFormService,
    private router: Router,
    private pointOfInterestService: PointsOfInterestService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    const controls = this.form().controls;
    const timeWindowTypeControl = controls.timeWindows.controls.type;
    this.timeWindowType = toSignal(timeWindowTypeControl.valueChanges, {
      initialValue: timeWindowTypeControl.value,
      injector: this.injector,
    });

    const addressControl = controls.address;
    const pointOfInterest$ = addressControl.valueChanges.pipe(
      startWith(addressControl.value),
      switchMap((address) => {
        if (!address || address.type !== OnyxAddressType.POINT_OF_INTEREST) {
          return of(null);
        }
        return this.pointOfInterestService.getPointOfInterest(address.poiUuid);
      }),
      shareReplay(1),
      takeUntilDestroyed(this.destroyRef),
    );

    pointOfInterest$
      .pipe(
        skip(1),
        filter((pointOfInterest) => pointOfInterest != null),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((pointOfInterest) =>
        controls.serviceTime.setValue(pointOfInterest.time.averageServiceTime),
      );

    this.pointOfInterest = toSignal(pointOfInterest$, {
      initialValue: null,
      injector: this.injector,
    });

    this.showAfterHoursWarning = toSignal(
      this.timeWindowsFormArray().valueChanges.pipe(
        startWith(this.timeWindowsFormArray().value),
        combineLatestWith(pointOfInterest$),
        map(([windows, pointOfInterest]) => {
          if (this.timeWindowsFormArray().invalid) return false;
          if (!pointOfInterest) return false;

          for (const window of windows) {
            if (!window.date) return false;

            const openingHours = chain(window.date)
              .thru((date) => DateTime.fromISO(date).weekday)
              .thru((weekday): OnyxTimeRange | null => {
                const { businessHours } = pointOfInterest;
                if (weekday === 7) return businessHours.sunday;
                if (weekday === 6) return businessHours.saturday;
                return businessHours.weekday;
              })
              .value();

            const type = this.timeWindowType();
            if (type === OrderTimeWindowType.INTERVAL) {
              const timeRange = window.timeRange;
              if (!timeRange) return false;
              if (!openingHours) return timeRange;

              if (
                timeRange.from < openingHours.from ||
                timeRange.to > openingHours.to
              ) {
                return timeRange;
              }
            } else if (type === OrderTimeWindowType.FIX) {
              const time = window.time;
              if (!time) return false;
              if (!openingHours) return time;

              if (time < openingHours.from || time > openingHours.to) {
                return time;
              }
            } else {
              return false;
            }
          }

          return false;
        }),
      ),
      { initialValue: false, injector: this.injector },
    );

    effect(
      () => {
        if (this.timeWindowType() !== OrderTimeWindowType.FIX) return;
        this.timeWindowsFormArray().controls.splice(1);
      },
      { injector: this.injector },
    );
  }

  protected addPointOfInterest(name: string | undefined): void {
    const url = this.router
      .createUrlTree([PointsOfInterestRoute.ADD_POINT_OF_INTEREST], {
        queryParams: {
          ...(name && { [PointsOfInterestQueryParamKey.NAME]: name }),
        },
      })
      .toString();
    ActionHelper.openLink(url);
  }

  protected addTimeWindow(): void {
    this.timeWindowsFormArray().push(
      OrderPointTimeWindowFormComponent.buildForm(this.fb),
    );
  }

  protected removeTimeWindow(index: number): void {
    this.orderFormService.addRestorePoint(
      {
        [OrderPointCategory.LOADING]:
          OrderFormRestorePointType.REMOVED_LOADING_TIME_WINDOW,
        [OrderPointCategory.UNLOADING]:
          OrderFormRestorePointType.REMOVED_UNLOADING_TIME_WINDOW,
      }[this.pointCategory()],
    );
    this.timeWindowsFormArray().removeAt(index);
  }

  public static buildTimeWindowsForm(fb: NonNullableFormBuilder, count = 1) {
    return fb.group({
      type: fb.control(OrderTimeWindowType.INTERVAL, [Validators.required]),
      windows: fb.array(
        Array.from({ length: count }).map(() =>
          OrderPointTimeWindowFormComponent.buildForm(fb),
        ),
        [orderPointTimeWindowOverlap],
      ),
    });
  }
}
