import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxAmountPipe } from '@onyx/angular';

@Component({
  selector: 'app-amount-cell',
  imports: [OnyxAmountPipe, DecimalPipe],
  templateUrl: './amount-cell.component.html',
  styleUrl: './amount-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountCellComponent {
  public amount = input.required<Parameters<OnyxAmountPipe['transform']>[0]>();
  public show = input<Parameters<OnyxAmountPipe['transform']>[1]>('value');
  public short = input(true);
  public decimalPlaces = input(2);
  public percentage = input<number | null>(null);
}
