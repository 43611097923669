@let companyProfile = contractor()?.companyProfile ?? null;
<onyx-modal
  type="right"
  [heading]="companyProfile?.displayName ?? ''"
  [loading]="loading()"
  [(error)]="error"
  [roles]="companyProfile?.types ?? []"
  [dictionaryCode]="DictionaryCode.CONTRACTOR_TYPE"
  [close$]="close$"
  [avatarCompany]="{ logo: contractor()?.logo }"
  (errorChange)="contractor$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container leftOptions>
    @if (contractor()?.companyProfile; as companyProfile) {
      <!-- TEMP: N/A -->
      <!-- <onyx-icon
        class="verified"
        name="verified"
        [size]="16"
        [onyxTooltip]="I18N + '.contractorVerified'"
      ></onyx-icon> -->

      <p class="description">
        {{ 'labels.vatId' | translate }}: {{ companyProfile.country | uppercase
        }}{{ companyProfile.vatId }}
      </p>
    }
  </ng-container>

  <ng-container rightOptions>
    @if (contractor(); as contractor) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        onyxTooltip="labels.options"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
      </onyx-icon-button>

      @let isActive = contractor.status.value === ContractorStatus.ACTIVE;
      @if (!isArchived()) {
        <onyx-icon-button
          color="black"
          type="outlined"
          size="m"
          [circle]="true"
          (click)="isActive ? block() : unblock()"
          [onyxTooltip]="isActive ? 'labels.block' : 'labels.unblock'"
          [onyxTooltipPositions]="[OnyxOverlayPosition.BOTTOM]"
          [onyxTooltipOverridePositions]="{ panelClass: ['bottom'] }"
        >
          <onyx-icon
            [name]="isActive ? 'locked' : 'unlocked'"
            [size]="16"
          ></onyx-icon>
        </onyx-icon-button>

        <onyx-icon-button
          color="black"
          type="outlined"
          size="m"
          [circle]="true"
          onyxTooltip="labels.edit"
          (click)="edit()"
        >
          <onyx-icon name="edit" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }

      <onyx-icon-button
        color="blue"
        type="primary"
        size="m"
        [circle]="true"
        onyxTooltip="labels.call"
        (click)="ActionHelper.makeCall(companyProfile!.phone)"
      >
        <onyx-icon name="telephone" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </ng-container>

  @if (contractor(); as contractor) {
    <div class="container">
      @let payment = contractor.payment;
      <onyx-information-heading>
        {{ 'labels.payment' | translate }}
      </onyx-information-heading>

      <onyx-information-row label="labels.tradeCredit">
        <!-- TEMP: Replace with usedTradeCredit when available -->
        <div class="limit">
          {{ 'labels.limit' | translate }}:

          @if (payment.parameters.tradeCreditLimit; as limit) {
            {{ limit | onyxAmount: undefined : false : 0 }}
          } @else {
            {{ 'labels.lack' | translate }}
          }
        </div>
      </onyx-information-row>

      <onyx-information-row label="labels.paymentTerm">
        {{
          'daysNumber'
            | onyxPluralTranslate: payment.termDays
            | translate: { days: payment.termDays }
        }}
      </onyx-information-row>

      <!-- <onyx-information-row label="labels.timeliness">
        N/A
      </onyx-information-row> -->

      <onyx-information-row label="labels.cashDiscount">
        @let cashDiscounts = payment.parameters.cashDiscount;
        @if (cashDiscounts && cashDiscounts.length) {
          @for (cashDiscount of cashDiscounts; track $index) {
            {{ cashDiscount.percentage / 100 | number: '1.0-2' }}%
            {{ 'labels.inTerm' | translate | lowercase }}
            {{ cashDiscount.termDays }}
            {{ 'labels.days' | translate | lowercase }}{{ !$last ? ', ' : '' }}
          }
        } @else {
          {{ 'labels.no' | translate }}
        }
      </onyx-information-row>

      <onyx-information-row label="labels.factoringConsent">
        {{ `labels.${payment.parameters.hasFactoringConsent ? 'yes' : 'no'}` | translate }}
      </onyx-information-row>

      <app-contractor-contacts
        [contractor]="contractor"
      ></app-contractor-contacts>

      <div class="chips">
        <onyx-chips
          [chips]="groupChips()"
          type="secondary"
          [single]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>
      </div>

      @switch (group()[0]) {
        <!-- @case (ContractorModalSection.ORDERS) {
          <p>N/A</p>
        } -->

        @case (ContractorModalSection.BRANCHES) {
          <app-contractor-modal-branches
            [contractor]="contractor"
          ></app-contractor-modal-branches>

          <!-- <div class="addresses-container">
            <div class="company-addresses">
              <onyx-information-row label="labels.mainAddress">
                <app-address
                  [address]="address.mainAddress"
                  format="full"
                  [showFlag]="false"
                ></app-address>
              </onyx-information-row>

              @let correspondenceAddress = address.correspondenceAddress;
              @if (correspondenceAddress) {
                <onyx-information-row label="labels.correspondenceAddress">
                  <app-address
                    [address]="correspondenceAddress"
                    format="full"
                    [showFlag]="false"
                  ></app-address>
                </onyx-information-row>
              }

              @let branches = contractor.branches;
              @if (branches) {
                @for (branch of branches; track branch.name) {
                  <onyx-information-row label="labels.branch">
                    <div class="branch">
                      <p>{{ branch.name }}</p>

                      <app-address
                        [address]="branch.addresses.correspondenceAddress"
                        format="full"
                        [showFlag]="false"
                      ></app-address>
                    </div>
                  </onyx-information-row>
                }
              }
            </div>

            <onyx-map></onyx-map>
          </div> -->
        }

        @case (ContractorModalSection.DOCUMENTS) {
          <onyx-table
            [data]="contractor.documents"
            [columns]="COLUMNS"
            tableSize="s"
            [rowSize]="48"
            [tableShadow]="false"
          ></onyx-table>
        }

        @case (ContractorModalSection.COMPANY) {
          <onyx-information-row label="labels.companyName">
            {{ companyProfile!.companyName }}
          </onyx-information-row>
        }
      }
    </div>
  }
</onyx-modal>
