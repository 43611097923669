import { NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { AddressHelper, OnyxAddress, OnyxFlagComponent } from '@onyx/angular';
import { DictionaryCode } from '../../enums/dictionary-code';
import { I18nPipe } from '../../pipes/i18n.pipe';

export type AddressFormat =
  | 'extended'
  | 'full'
  | 'short'
  | 'double-line'
  | 'city'
  | 'city-flag'
  | 'pdf'
  | 'transit'
  | 'coordinates';

@Component({
  selector: 'app-address',
  imports: [
    UpperCasePipe,
    OnyxFlagComponent,
    TranslatePipe,
    NgTemplateOutlet,
    I18nPipe,
  ],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public address = input.required<OnyxAddress | null>();
  public endAddress = input<OnyxAddress | null>(null);
  public format = input.required<AddressFormat>();
  public showFlag = input(true);

  protected coordinatesLabel = computed(() => {
    const address = this.address();
    return address ? AddressHelper.composeCoordinatesLabel(address) : null;
  });
}
