import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { OnyxAmount, OnyxAmountForm } from '../interfaces';

@Pipe({
  name: 'onyxAmount',
  pure: true,
})
export class OnyxAmountPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  public transform(
    amount: Pick<OnyxAmountForm, 'value' | 'currency'> | OnyxAmount | null,
    show: 'value' | 'euro' | 'defaultCurrency' | 'resultCurrency' = 'value',
    short = true,
    decimalPlaces = 2,
  ): string {
    if (!amount) return '-';

    const [value, currency] = (() => {
      if (!('euroValue' in amount)) return [amount.value, amount.currency];

      switch (show) {
        case 'value':
          return [amount.value, amount.currency];
        case 'euro':
          return [amount.euroValue, 'eur'];
        case 'defaultCurrency':
          return [amount.defaultCurrencyValue, amount.defaultCurrency];
        case 'resultCurrency':
          return [amount.resultCurrencyValue, amount.resultCurrency];
      }
    })();
    if (value == null || !currency) return '-';

    return this.currencyPipe.transform(
      value / 10 ** decimalPlaces,
      currency.toUpperCase(),
      short ? 'symbol' : 'code',
      '1.0-2',
    )!;
  }
}
