import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ONYX_TABLE_TOOLTIP_DELAY } from '../../../constants';
import { OnyxTooltipDirective } from '../../../directives';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxFlagComponent } from '../onyx-flag/onyx-flag.component';

@Component({
  selector: 'onyx-flags',
  imports: [OnyxFlagComponent, TranslatePipe, OnyxTooltipDirective],
  templateUrl: './onyx-flags.component.html',
  styleUrl: './onyx-flags.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxFlagsComponent {
  protected readonly I18N = `${I18N_NAMESPACE}.flags`;
  protected readonly TOOLTIP_DELAY = ONYX_TABLE_TOOLTIP_DELAY;

  public flags = input.required<string[]>();
  public size = input(24);
  public max = input(3);

  protected visibleFlags = computed(() => this.flags().slice(0, this.max()));
}
