import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  OnyxConfirmModalComponent,
  OnyxConfirmModalData,
  OnyxDropdownOptionsSource,
  OnyxModalService,
  OnyxOptionsGroup,
  OnyxToastService,
} from '@onyx/angular';
import { map } from 'rxjs';
import { CommonHelper } from '../../../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { HelperOptions } from '../../../../../common/interfaces/utilities/helper-options';
import { PointOfInterestForm } from '../../point-of-interest-form/point-of-interest-form.component';
import {
  PointOfInterestModalComponent,
  PointOfInterestModalData,
} from '../../point-of-interest-modal/point-of-interest-modal.component';
import { PointsOfInterestRoute } from '../../points-of-interest.routes';
import {
  PointOfInterest,
  SimplifiedPointOfInterest,
} from '../interfaces/point-of-interest';
import { PointsOfInterestService } from '../services/points-of-interest.service';

@Injectable({
  providedIn: 'root',
})
export class PointOfInterestHelper {
  private readonly I18N = 'pointsOfInterest';

  constructor(
    private router: Router,
    private pointsOfInterestService: PointsOfInterestService,
    private toastService: OnyxToastService,
    private modalService: OnyxModalService,
    private translateService: TranslateService,
  ) {}

  public getPointsOfInterestSource(): OnyxDropdownOptionsSource<PointOfInterest> {
    return {
      list: (query, limit) =>
        this.pointsOfInterestService.searchPointsOfInterest(query, limit, {
          type: 'all',
        }),
      get: (uuid) =>
        this.pointsOfInterestService.getPointOfInterest(uuid).pipe(
          map((pointOfInterest) => ({
            name: pointOfInterest.name,
            value: pointOfInterest,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getOptions(
    pointOfInterest: PointOfInterest,
    options: HelperOptions,
  ): OnyxOptionsGroup<() => void>[] {
    return [
      {
        options: [
          {
            name: 'labels.edit',
            value: () => this.edit(pointOfInterest.uuid, options),
            leftIcon: { name: 'edit' as const, size: 16 },
            leftIconColor: 'gray-alt' as const,
          },
        ],
      },
      {
        options: [
          {
            name: 'labels.delete',
            value: () => this.delete(pointOfInterest, options),
            leftIcon: { name: 'delete-fill' as const, size: 16 },
            leftIconColor: 'gray-alt' as const,
          },
        ],
      },
    ];
  }

  public openModal(pointOfInterest: PointOfInterestModalData): void {
    this.modalService.open<PointOfInterestModalData>(
      PointOfInterestModalComponent,
      pointOfInterest,
    );
  }

  public edit(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(
      `${PointsOfInterestRoute.EDIT_POINT_OF_INTEREST.replace(':uuid', uuid)}`,
    );
    CommonHelper.handleOptions(uuid, options);
  }

  public delete(
    pointOfInterest: PointOfInterest,
    options: HelperOptions,
  ): void {
    this.modalService
      .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
        heading: this.translateService.instant(`${this.I18N}.delete.heading`, {
          pointOfInterest: pointOfInterest.name,
        }),
        message: `${this.I18N}.delete.message`,
        confirmButtonText: 'labels.delete',
        confirmButtonColor: 'red',
      })
      .result.subscribe((ok) => {
        if (!ok) return;

        this.pointsOfInterestService
          .deletePointOfInterest(pointOfInterest.uuid)
          .subscribe({
            next: () => {
              this.toastService.showSuccess(`${this.I18N}.pointDeleted`);
              CommonHelper.handleOptions(pointOfInterest, options);
            },
            error: (response) =>
              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              ),
          });
      });
  }

  public static isPointOfInterestType(
    pointOfInterest: SimplifiedPointOfInterest | PointOfInterest,
  ): pointOfInterest is PointOfInterest {
    return 'businessHours' in pointOfInterest;
  }

  public static fromDto(dto: PointOfInterest): PointOfInterestForm {
    return {
      ...dto,
      pointInformation: {
        address: dto.address,
        name: dto.name,
      },
      times: {
        averageServiceTime: dto.time.averageServiceTime,
      },
      contact: { phone: dto.phone },
      contractor: {
        selectedContractors: dto.contractors.map(
          (contractor) => contractor.contractor.uuid,
        ),
        contractors: dto.contractors.map((contractor) => ({
          contractor: contractor.contractor,
          gate: contractor.gate,
          uuid: contractor.contractor.uuid,
        })),
      },
      note: {
        content: dto.note?.content ?? null,
      },
    };
  }
}
