import { DecimalPipe, LowerCasePipe, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Contractor } from '../../interfaces/contractor';

@Component({
  selector: 'app-contractor-cash-discount-cell',
  imports: [SlicePipe, TranslatePipe, LowerCasePipe, DecimalPipe],
  templateUrl: './contractor-cash-discount-cell.component.html',
  styleUrl: './contractor-cash-discount-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorCashDiscountCellComponent {
  protected readonly MAX_VISIBLE = 3;

  public cashDiscount = input.required<
    Contractor['payment']['parameters']['cashDiscount'] | null
  >();
}
