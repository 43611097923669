import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  I18N_LANGUAGES,
  I18nLanguage,
  I18nLanguageRecord,
  OnyxDropdownOptionsSourceResult,
  OnyxFilterPipe,
  OnyxLanguagePipe,
} from '@onyx/angular';
import { chain, orderBy } from 'lodash';
import { map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { ApiService } from '../../../../../common/services/api.service';
import {
  CompanyDictionaryByCode,
  CompanyDictionaryCode,
  CompanyDictionaryFormByCode,
} from '../enums/company-dictionary-code';

@Injectable({
  providedIn: 'root',
})
export class CompanyDictionariesService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private translateService: TranslateService,
    private languagePipe: OnyxLanguagePipe,
    private filterPipe: OnyxFilterPipe,
  ) {
    super(http);
  }

  public listDictionary<T extends CompanyDictionaryCode>(
    type: T,
  ): Observable<CompanyDictionaryByCode[T][]> {
    return this.get<CompanyDictionaryByCode[T][]>(
      `/business/dictionaries/${type}`,
    ).pipe(
      map((items) =>
        orderBy(
          items,
          [
            (item) => ('isDefault' in item ? item.isDefault : false),
            (item) => this.languagePipe.transform(item.names),
          ],
          ['desc', 'asc'],
        ),
      ),
    );
  }

  public searchDictionary<T extends CompanyDictionaryCode>(
    type: T,
    query: string,
    limit: number,
  ): Observable<OnyxDropdownOptionsSourceResult<CompanyDictionaryByCode[T]>> {
    return this.listDictionary(type).pipe(
      map((items) => ({
        options: chain(items)
          .thru((options) =>
            this.filterPipe
              .transform(options, query, (item) => JSON.stringify(item.names))
              .slice(0, limit),
          )
          .map((item) => ({
            name: this.languagePipe.transform(item.names),
            value: item,
          }))
          .value(),
        totalItems: items.length,
      })),
    );
  }

  public getDictionary<T extends CompanyDictionaryCode>(
    uuid: string,
  ): Observable<CompanyDictionaryByCode[T]> {
    return this.get(`/business/dictionaries/${uuid}`);
  }

  public addDictionaryItem<T extends CompanyDictionaryCode>(
    type: T,
    form: CompanyDictionaryFormByCode[T],
  ): Observable<void> {
    return of(this.fillEmptyNames(form)).pipe(
      switchMap((form) =>
        this.post<void>(`/business/dictionaries/${type}`, form),
      ),
      tap(() => this._reload$.next()),
    );
  }

  public editDictionaryItem<T extends CompanyDictionaryCode>(
    uuid: string,
    form: CompanyDictionaryFormByCode[T],
  ): Observable<void> {
    return of(this.fillEmptyNames(form)).pipe(
      switchMap((form) =>
        this.put<void>(`/business/dictionaries/${uuid}`, form),
      ),
      tap(() => this._reload$.next()),
    );
  }

  public deleteDictionaryItem(uuid: string): Observable<void> {
    return this.delete<void>(`/business/dictionaries/${uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public suggestTranslations(
    language: I18nLanguage,
    name: string,
  ): Observable<I18nLanguageRecord<string>> {
    return this.post('/business/dictionary/translation', { language, name });
  }

  private fillEmptyNames<T extends CompanyDictionaryCode>(
    form: CompanyDictionaryFormByCode[T],
  ): CompanyDictionaryFormByCode[T] {
    const defaultLanguage = (this.translateService.currentLang ??
      this.translateService.defaultLang) as I18nLanguage;
    const defaultTranslation = form.names[defaultLanguage];

    return {
      ...form,
      names: Object.fromEntries(
        I18N_LANGUAGES.map((language) => [
          language,
          form.names[language] || defaultTranslation,
        ]),
      ),
    };
  }
}
