import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  onyxMaxNumberValidator,
  OnyxModalComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { I18nPipe } from '../../../../../../common/pipes/i18n.pipe';
import { OrderStatus } from '../../../../common/enums/order-status';
import { Order } from '../../../../common/interfaces/order';
import { OrdersService } from '../../../../common/services/orders.service';
import { PaymentsHistoryData } from '../../order-modal-payment-form-container/order-modal-payment-form-container.component';
import { OrderModalPaymentFormComponent } from '../../order-modal-payment-form/order-modal-payment-form.component';

export interface OrderPaymentEditModalData {
  order: Order;
  paymentsHistoryData: PaymentsHistoryData;
}

@Component({
  selector: 'app-order-modal-payment-edit',
  imports: [
    OnyxModalComponent,
    OnyxButtonComponent,
    TranslatePipe,
    I18nPipe,
    OrderModalPaymentFormComponent,
  ],
  templateUrl: './order-modal-payment-edit.component.html',
  styleUrl: './order-modal-payment-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPaymentEditComponent implements OnInit {
  protected readonly I18N = 'orders.orderModal';

  protected close$ = new Subject<void>();
  protected loading = signal(false);
  protected form = OrderModalPaymentFormComponent.buildForm(this.fb);

  constructor(
    @Inject(DIALOG_DATA) protected data: OrderPaymentEditModalData,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private ordersService: OrdersService,
  ) {}

  public ngOnInit(): void {
    const { paymentsHistoryData, order } = this.data;
    const partialPaymentValue = paymentsHistoryData.value.value / 100;

    this.form.patchValue({
      paymentDay: paymentsHistoryData.date,
      partialPayment: partialPaymentValue,
    });

    if (order.status.value === OrderStatus.PAID) {
      this.form.controls.partialPayment.addValidators(
        onyxMaxNumberValidator(partialPaymentValue),
      );
    }
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const dto = this.form.getRawValue();

    this.loading.set(true);
    this.ordersService
      .editPayment(
        this.data.order.uuid,
        dto,
        this.data.paymentsHistoryData.uuid,
      )
      .subscribe({
        next: () => this.toastService.showSuccess('labels.savedChanges'),
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected deletePayment() {
    this.ordersService
      .deletePayment(this.data.order.uuid, this.data.paymentsHistoryData.uuid)
      .subscribe({
        next: () =>
          this.toastService.showSuccess(`${this.I18N}.toasts.paymentDeleted`, {
            keepOnNavigation: true,
          }),
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      });
  }
}
