import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnyxDatePipe, OnyxOption, OnyxOptional } from '@onyx/angular';
import { chain, groupBy, intersection, orderBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { DriverHelper } from '../../dashboard/drivers/common/helpers/driver.helper';
import { Driver } from '../../dashboard/drivers/common/interfaces/driver';
import { FleetCategory } from '../../dashboard/fleet/common/enums/fleet-category';
import { Fleet } from '../../dashboard/fleet/common/interfaces/fleet';
import { DictionaryCode } from '../enums/dictionary-code';
import { ValidationSeverity } from '../enums/validation-severity';
import { ValidationIssue } from '../interfaces/validation/validation-issue';
import { JoinPipe } from '../pipes/join.pipe';
import { CellColor, ColorHelper } from './color.helper';

export enum ValidationContext {
  FLEET = 'fleet',
  SET = 'set',
  DELEGATION = 'delegation',
  DRIVER = 'driver',
}

@Injectable({
  providedIn: 'root',
})
export class ValidationConditionsHelper {
  private readonly I18N = 'issues';

  constructor(
    private translateService: TranslateService,
    private joinPipe: JoinPipe,
    private datePipe: OnyxDatePipe,
  ) {}

  public checkCountries(
    context: ValidationContext,
    issues: ValidationIssue[],
    data?: {
      driver?: Driver;
      secondaryDriver?: Driver;
      vehicle?: Fleet;
      trailer?: Fleet;
    },
  ): void {
    const { driver, secondaryDriver, vehicle, trailer } = data ?? {};
    if (!vehicle || !driver) return;

    const countries = uniq([
      ...(vehicle.allowedCountries.whitelist ?? []),
      ...(trailer?.allowedCountries.whitelist ?? []),
    ]);

    const driverCountries = uniq([
      ...(driver?.countries.whitelist ?? []),
      ...(secondaryDriver?.countries.whitelist ?? []),
    ]);

    if (intersection(driverCountries, countries).length === 0) {
      issues.push({
        message: `${this.I18N}.driverAndVehicleDifferentCountries`,
        severity: {
          [ValidationContext.DELEGATION]: ValidationSeverity.ERROR,
          [ValidationContext.DRIVER]: ValidationSeverity.WARNING,
          [ValidationContext.FLEET]: ValidationSeverity.WARNING,
          [ValidationContext.SET]: ValidationSeverity.WARNING,
        }[context],
      });
    }
  }

  public checkSkills(
    driver: Driver,
    issues: ValidationIssue[],
    vehicle?: Fleet,
  ): void {
    if (!vehicle?.trailer) return;

    const requiredSkill = vehicle.trailer.generalInformation.type;
    const hasSkill = driver.skills.semiTrailers?.includes(requiredSkill ?? '');
    if (hasSkill) return;

    issues.push({
      message: `${this.I18N}.driverMissingSkill`,
      data: {
        skill: this.translateService.instant(
          `${DictionaryCode.VEHICLE_TYPE}.${requiredSkill}`,
        ),
      },
      severity: ValidationSeverity.WARNING,
    });
  }

  public checkAdr(
    driver: Driver,
    issues: ValidationIssue[],
    vehicle?: Fleet,
  ): void {
    if (!vehicle) return;

    const fleetAdrClasses = uniq([
      ...(vehicle.additionalParameters.adrClasses ?? []),
      ...(vehicle.trailer?.additionalParameters.adrClasses ?? []),
    ]);

    if (
      fleetAdrClasses.length &&
      !intersection(
        DriverHelper.getDriverAdrClasses(
          driver,
          vehicle.generalInformation.type,
        ),
        fleetAdrClasses,
      ).length
    ) {
      issues.push({
        message: `${this.I18N}.noAdrClasses`,
        severity: ValidationSeverity.WARNING,
      });
    }
  }

  public checkRequiredDrivingLicense(
    context: ValidationContext,
    driver: Driver,
    issues: ValidationIssue[],
    options?: {
      vehicle?: Fleet;
      trailer?: Fleet;
    },
  ): void {
    const { vehicle, trailer } = options ?? {};
    if (!vehicle) return;

    const categories =
      driver.driversLicenseAndProfessionalQualifications.categories.map(
        ({ category }) => category,
      );

    const checkRequiredCategories = (requiredCategories: string[]) => {
      if (
        !requiredCategories.some((category) => categories.includes(category))
      ) {
        issues.push({
          message: `${this.I18N}.requiredDrivingLicense`,
          data: {
            category: requiredCategories
              .map((key) =>
                this.translateService.instant(
                  `${DictionaryCode.DRIVERS_LICENSE_CATEGORY}.${key}`,
                ),
              )
              .join(` ${this.translateService.instant('labels.or')} `),
          },
          severity: ValidationSeverity.ERROR,
        });
      }
    };

    const requiredCategoriesData =
      this.getRequiredCategoryMap()[vehicle.generalInformation.category];
    if (requiredCategoriesData) {
      const requiredCategories =
        (trailer ??
        (context === ValidationContext.DELEGATION ? false : !!vehicle.trailer))
          ? requiredCategoriesData.withTrailer
          : requiredCategoriesData.withoutTrailer;

      checkRequiredCategories(requiredCategories);
    }
  }

  public checkDrivingLicenseExpiryDate(
    context: ValidationContext,
    driver: Driver,
    issues: ValidationIssue[],
    options?: {
      vehicle?: Fleet;
      trailer?: Fleet;
    },
  ): void {
    const { vehicle, trailer } = options ?? {};
    const drivingStatuses = chain(
      driver.driversLicenseAndProfessionalQualifications.categories,
    )
      .filter(({ expirationDate }) => expirationDate?.date != null)
      .map(({ category, expirationDate }) => ({
        category,
        expirationDate: expirationDate.date,
      }))
      .thru((categories) =>
        this.getExpiryStatuses(
          categories,
          (category) => category.expirationDate,
          ColorHelper.getCellColor,
        ),
      )
      .value();
    if (!drivingStatuses) return;

    const filteredDrivingStatuses = () => {
      const translateCategory = (category: string) =>
        this.translateService.instant(
          `${DictionaryCode.DRIVERS_LICENSE_CATEGORY}.${category}`,
        );

      const mapCategories = (
        statuses:
          | typeof drivingStatuses.errors
          | typeof drivingStatuses.warnings,
      ) =>
        statuses.map((status) => ({
          ...status,
          category: translateCategory(status.category),
        }));

      if (!vehicle) {
        return {
          errors: mapCategories(drivingStatuses.errors),
          warnings: mapCategories(drivingStatuses.warnings),
        };
      }

      const hasTrailer =
        trailer ??
        (context === ValidationContext.DELEGATION ? false : !!vehicle.trailer);
      const requiredCategories =
        this.getRequiredCategoryMap()[vehicle.generalInformation.category]?.[
          hasTrailer ? 'withTrailer' : 'withoutTrailer'
        ] ?? [];

      const errors: typeof drivingStatuses.errors = [];
      const warnings = drivingStatuses.warnings;

      const invalidRequired = requiredCategories.some((categeory) =>
        drivingStatuses.errors.find((status) => status.category === categeory),
      );
      for (const status of drivingStatuses.errors) {
        if (requiredCategories.includes(status.category) && invalidRequired) {
          errors.push({ ...status, color: 'red' });
        } else {
          warnings.push({ ...status, color: 'yellow' });
        }
      }

      return {
        errors: mapCategories(errors),
        warnings: mapCategories(drivingStatuses.warnings),
      };
    };

    const handleDrivingLicenseExpiry = (
      items: typeof drivingStatuses.errors | typeof drivingStatuses.warnings,
      single: string,
      multiple: string,
      showDate = false,
    ) =>
      this.addExpiryIssue(
        items,
        `${this.I18N}.drivingLicense.${single}`,
        `${this.I18N}.drivingLicense.${multiple}`,
        issues,

        {
          getData: (item) => ({
            licenses: item.category,
            ...(showDate && {
              date: this.datePipe.transform(item.expirationDate!, 'date-dot'),
            }),
          }),
          getListData: (items) => ({
            licenses: this.joinPipe.transform(
              items.map((item) => item.category),
              ', ',
              5,
              null,
            ),
          }),
        },
      );

    const groupedWarnings = groupBy(
      filteredDrivingStatuses().warnings,
      (item) => DateTime.fromISO(item.expirationDate!) < DateTime.now(),
    );
    const expiredWarnings = groupedWarnings['true'] ?? [];
    const expiringWarnings = groupedWarnings['false'] ?? [];

    handleDrivingLicenseExpiry(
      filteredDrivingStatuses().errors,
      'drivingLicenseExpired',
      'drivingLicenseExpired',
    );
    handleDrivingLicenseExpiry(
      expiredWarnings,
      'drivingLicenseExpired',
      'drivingLicenseExpired',
    );
    handleDrivingLicenseExpiry(
      expiringWarnings,
      'drivingLicenseExpires',
      'drivingLicenseExpires',
      true,
    );
  }

  public getExpiryStatuses<T>(
    items: T[],
    getDate: (item: T) => string | null | undefined,
    getColor: (date: string) => CellColor,
  ) {
    if (!items.length) return null;

    const sortedItems = orderBy(items, getDate)
      .map((item) => {
        const expirationDate = getDate(item);
        if (!expirationDate) return null;

        return { ...item, color: getColor(expirationDate) };
      })
      .filter((item) => item != null);

    return {
      errors: sortedItems.filter((item) => item.color === 'red'),
      warnings: sortedItems.filter((item) => item.color === 'yellow'),
    };
  }

  public groupOptions<T>(options: OnyxOption<T>[]): {
    options: OnyxOption<T>[];
    disabled: boolean;
  }[] {
    return chain(options)
      .groupBy((option) => option?.disabled ?? false)
      .map((groupOptions, disabled) => ({
        options: groupOptions,
        disabled: disabled === 'true',
      }))
      .filter(({ options }) => options.length > 0)
      .orderBy(({ disabled }) => disabled)
      .value();
  }

  public addExpiryIssue<
    T extends { color: CellColor; expirationDate?: OnyxOptional<string> },
  >(
    expiryList: T[],
    singleMessage: string,
    multipleMessage: string,
    issues: ValidationIssue[],
    options?: {
      forceSeverity?: ValidationSeverity;
      getData?: (item: T) => Record<string, unknown>;
      getListData?: (items: T[]) => Record<string, unknown>;
    },
  ): void {
    const count = expiryList.length;
    const { forceSeverity, getData, getListData } = options ?? {};

    if (count === 1) {
      issues.push({
        severity:
          forceSeverity ??
          (expiryList[0].color === 'red'
            ? ValidationSeverity.ERROR
            : ValidationSeverity.WARNING),
        message: singleMessage,
        data: getData ? getData(expiryList[0]) : {},
      });
    } else if (count > 1) {
      issues.push({
        severity:
          forceSeverity ??
          (expiryList.every((item) => item.color === 'red')
            ? ValidationSeverity.ERROR
            : ValidationSeverity.WARNING),
        message: multipleMessage,
        data: getListData ? getListData(expiryList) : { count },
      });
    }
  }

  private getRequiredCategoryMap(): Partial<
    Record<
      FleetCategory,
      {
        withTrailer: string[];
        withoutTrailer: string[];
      }
    >
  > {
    return {
      [FleetCategory.STRAIGHT_TRUCK]: {
        withTrailer: ['ce', 'c1e'],
        withoutTrailer: ['c', 'ce', 'c1', 'c1e'],
      },
      [FleetCategory.SEMI_TRUCK]: {
        withTrailer: ['ce'],
        withoutTrailer: ['c', 'ce'],
      },
      [FleetCategory.VAN]: {
        withTrailer: ['be'],
        withoutTrailer: ['b', 'be'],
      },
    };
  }
}
