<div class="options-container">
  <p>{{ driver().name }}</p>

  @let phone = driver().phone;
  <div class="options">
    <onyx-icon-button
      type="transparent"
      color="black"
      size="s"
      [onyxTooltip]="phoneTooltipTemplate"
      [onyxTooltipTemplateContext]="{ phone }"
      (click)="ActionHelper.makeCall(phone)"
    >
      <onyx-icon name="telephone" [size]="16"></onyx-icon>
    </onyx-icon-button>
  </div>
</div>

<ng-template let-phone="phone" #phoneTooltipTemplate>
  <div class="phone">
    {{ 'labels.call' | translate }}
    {{ phone | onyxPhone }}
  </div>
</ng-template>
