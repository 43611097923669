import { Routes } from '@angular/router';

export enum GanttRoute {
  GANTT = '/gantt',
}

export const GANTT_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./gantt/gantt.component').then((m) => m.GanttComponent),
  },
] as const;
