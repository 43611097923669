import { AsyncPipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxAmountPipe } from '@onyx/angular';
import { of } from 'rxjs';
import { CommonHelper } from '../../../../../../../common/helpers/common.helper';
import { ContractorsService } from '../../../../../../management-panel/contractors/common/services/contractors.service';
import { OrderHelper } from '../../../../../common/helpers/order.helper';
import { Order } from '../../../../../common/interfaces/order';

@Component({
  selector: 'app-order-pdf-template-rate',
  imports: [TranslatePipe, UpperCasePipe, OnyxAmountPipe, AsyncPipe],
  templateUrl: './order-pdf-template-rate.component.html',
  styleUrl: './order-pdf-template-rate.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPdfTemplateRateComponent {
  protected readonly I18N = 'orders.pdfTemplate';

  protected readonly CommonHelper = CommonHelper;

  public order = input.required<Order>();

  protected contractor$ = computed(() => {
    const contractorUuid = this.order().outsourcingData?.contractor.uuid;
    if (!contractorUuid) return of(null);

    return this.contractorsService.getContractor(contractorUuid);
  });
  protected settlementData = computed(() => {
    const settlementEntries = OrderHelper.getSettlementData(
      this.order(),
      'outsourcing',
    );
    return {
      gross: settlementEntries[0].gross,
      net: settlementEntries[0].net,
      vat: settlementEntries[0].vatRate,
    };
  });

  constructor(private contractorsService: ContractorsService) {}
}
