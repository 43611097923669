@let employeeStatus = employee().status.value;
@switch (employeeStatus) {
  @case (EmployeeStatus.UNAVAILABLE) {
    <div class="status">
      <div class="dot">
        <onyx-dot [color]="employee().status.color"></onyx-dot>
      </div>
      @if (employee().unavailability; as unavailability) {
        @let from = unavailability.dateRange.from!;
        @let to = unavailability.dateRange.to!;
        @if (!displayStatus()) {
          <div class="text">
            <span>
              {{
                DictionaryCode.EMPLOYEE_UNAVAILABILITY_REASON +
                  '.' +
                  unavailability.reason | translate
              }}
            </span>

            <span class="reason f-regular-3">
              {{ from | onyxDate: 'date-dot' : to }}
            </span>
          </div>
        } @else {
          <div class="display-status f-medium-2">
            <span>
              {{
                DictionaryCode.EMPLOYEE_STATUS + '.' + employeeStatus
                  | translate
              }}
            </span>

            <span class="reason">
              <span>
                {{
                  DictionaryCode.EMPLOYEE_UNAVAILABILITY_REASON +
                    '.' +
                    unavailability.reason | translate
                }}
              </span>

              ({{ from | onyxDate: 'date-dot' : to }})
            </span>
          </div>
        }
      } @else {
        <span>
          {{
            DictionaryCode.EMPLOYEE_STATUS + '.' + employeeStatus | translate
          }}
        </span>
      }
    </div>
  }
  @case (EmployeeStatus.INVITED) {
    <div class="status">
      <div class="dot">
        <onyx-dot [color]="employee().status.color"></onyx-dot>
      </div>
      <div class="text">
        <span>
          {{
            DictionaryCode.EMPLOYEE_STATUS + '.' + employeeStatus | translate
          }}
        </span>

        <onyx-link color="blue" font="f-regular-3" (click)="resendInvite()">
          {{ 'labels.sendAgain' | translate }}
        </onyx-link>
      </div>
    </div>
  }
  @default {
    <div class="status">
      <div class="dot">
        <onyx-dot [color]="employee().status.color"></onyx-dot>
      </div>

      <span>
        {{ DictionaryCode.EMPLOYEE_STATUS + '.' + employeeStatus | translate }}
      </span>
    </div>
  }
}
