import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  OnyxAddressInputComponent,
  OnyxCustomAddress,
  OnyxDatepickerComponent,
  OnyxDatePipe,
  OnyxDropdownComponent,
  OnyxFormGroupComponent,
  OnyxIconBoxComponent,
  OnyxIconComponent,
  OnyxPhone,
  OnyxPhoneNumberInputComponent,
  OnyxRoutePoint,
  OnyxSuggestionsComponent,
  OnyxTab,
  OnyxTabsComponent,
  OnyxTextareaComponent,
  OnyxTextFieldComponent,
  OnyxTime,
  OnyxTimepickerComponent,
} from '@onyx/angular';
import { filter, Subject } from 'rxjs';
import { SuggestionHelper } from '../../../../../common/helpers/suggestions.helper';
import { TransitHelper } from '../../../../../common/helpers/transit.helper';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { TransitRoute } from '../../../../../common/interfaces/transit/transit-route';
import { OrderPointCategory } from '../../../common/enums/order-point-category';
import { OrderPointTypeByCategory } from '../../../common/enums/order-point-type';
import { OrderTransitPointTimeWindowType } from '../../../common/enums/order-transit-point-time-window-type';

export type OrderTransitFormGroup = ReturnType<
  typeof OrderTransitFormComponent.buildForm
>;

@Component({
  selector: 'app-order-transit-form',
  imports: [
    ReactiveFormsModule,
    OnyxIconBoxComponent,
    OnyxIconComponent,
    OnyxAddressInputComponent,
    OnyxTextFieldComponent,
    OnyxTimepickerComponent,
    OnyxPhoneNumberInputComponent,
    OnyxTabsComponent,
    OnyxDatepickerComponent,
    OnyxSuggestionsComponent,
    OnyxFormGroupComponent,
    OnyxDropdownComponent,
    OnyxTextareaComponent,
  ],
  templateUrl: './order-transit-form.component.html',
  styleUrl: './order-transit-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTransitFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.points';
  protected readonly TRANSIT_POINT_TIME_WINDOW_TYPES: OnyxTab<OrderTransitPointTimeWindowType>[] =
    [
      { name: '24/7', value: OrderTransitPointTimeWindowType.ALL_TIME },
      { name: 'Fix', value: OrderTransitPointTimeWindowType.FIX },
    ];
  protected readonly DATE_SUGGESTIONS = SuggestionHelper.getDateSuggestions(
    this.translateService,
    this.datePipe,
    { days: 3 },
  );

  public form = input.required<OrderTransitFormGroup>();
  public compact = input(false);

  protected routesSource = computed(() =>
    this.transitHelper.getRoutesSource(this.form().controls.type.value),
  );
  protected serviceTimeLabel = computed(
    () => `${this.I18N}.serviceTime.${this.form().controls.type.value}`,
  );

  protected route$ = new Subject<TransitRoute | null>();

  constructor(
    private translateService: TranslateService,
    private datePipe: OnyxDatePipe,
    private injector: Injector,
    private transitHelper: TransitHelper,
  ) {}

  public ngOnInit(): void {
    this.route$.pipe(filter((route) => route != null)).subscribe((route) =>
      this.form().patchValue({
        startAddress: route.from.address,
        endAddress: route.to.address,
        serviceTime: route.duration,
        phone: route.from.phone,
      }),
    );

    const timeWindowForm = this.form().controls.timeWindow;
    const timeWindowControls = timeWindowForm.controls;
    const timeWindowType = toSignal(timeWindowControls.type.valueChanges, {
      initialValue: timeWindowControls.type.value,
      injector: this.injector,
    });

    effect(
      () => {
        const type = timeWindowType();
        setTimeout(() =>
          ValidationHelper.toggleControls(
            [timeWindowControls.date, timeWindowControls.time],
            type === OrderTransitPointTimeWindowType.FIX,
          ),
        );
      },
      { injector: this.injector },
    );
  }

  public static buildForm(
    fb: NonNullableFormBuilder,
    type: OrderPointTypeByCategory[OrderPointCategory.TRANSIT],
    includeInOrder: boolean,
    isAlwaysOpen: boolean,
  ) {
    return fb.group({
      uuid: fb.control<string | null>(null),
      category: fb.control(OrderPointCategory.TRANSIT as const),
      type: fb.control(type),
      includeInOrder: fb.control(includeInOrder),
      name: fb.control<string | null>(null, [Validators.required]),
      driverNote: fb.control<string | null>(null),
      startAddress: fb.control<OnyxCustomAddress | null>({
        value: null,
        disabled: true,
      }),
      endAddress: fb.control<OnyxCustomAddress | null>({
        value: null,
        disabled: true,
      }),
      serviceTime: fb.control<number | null>({
        value: null,
        disabled: true,
      }),
      phone: fb.control<OnyxPhone | null>({
        value: null,
        disabled: true,
      }),
      timeWindow: fb.group({
        type: fb.control(
          isAlwaysOpen
            ? OrderTransitPointTimeWindowType.ALL_TIME
            : OrderTransitPointTimeWindowType.FIX,
          [Validators.required],
        ),
        date: fb.control<string | null>(null, [Validators.required]),
        time: fb.control<OnyxTime | null>(null, [Validators.required]),
      }),
      route: fb.control<OnyxRoutePoint | null>(null),
    });
  }
}
