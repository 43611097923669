import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxTooltipDirective } from '../../../directives';
import {
  OnyxTooltipColor,
  OnyxTooltipConfig,
} from '../../../internal/components/onyx-tooltip-overlay/onyx-tooltip-overlay.component';
import { OnyxIconComponent } from '../../icons';
import { OnyxTooltipContext } from '../interfaces';

@Component({
  selector: 'onyx-tooltip',
  imports: [OnyxTooltipDirective, OnyxIconComponent],
  templateUrl: './onyx-tooltip.component.html',
  styleUrls: ['./onyx-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTooltipComponent {
  public tooltip = input.required<OnyxTooltipContext>();
  public color = input<OnyxTooltipColor>('black');
  public showArrow = input(true);
  public textAlign = input<OnyxTooltipConfig['textAlign']>('left');
  public delay = input(0);
  public enabled = input<boolean | (() => boolean)>(true);
}
