import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onyxPluralTranslate',
  pure: true,
})
export class OnyxPluralTranslatePipe implements PipeTransform {
  public transform(key: string, number: number): string {
    return `${key}.${
      {
        [0]: 'none',
        [1]: 'singular',
      }[number] ?? 'plural'
    }`;
  }
}
