import { TranslateService } from '@ngx-translate/core';
import {
  OnyxCustomAddress,
  OnyxDatePipe,
  OnyxSuggestion,
  OnyxTimeRange,
} from '@onyx/angular';
import { DateTime } from 'luxon';
import { TIME_RANGE_ALL_DAY } from '../constants/common/time-range-all-day';
import { BusinessHours } from '../interfaces/common/business-hours';
import { CommonHelper } from './common.helper';

export class SuggestionHelper {
  public static getDateSuggestions = (
    translateService: TranslateService,
    datePipe: OnyxDatePipe,
    options?: {
      days?: number;
      future?: boolean;
      businessHours?: BusinessHours;
    },
  ) => {
    const { days = 2, future = true, businessHours } = options ?? {};

    const today = DateTime.now();
    const todayLabel =
      `(${translateService.instant('labels.today')})`.toLowerCase();
    const tomorrowLabel =
      `(${translateService.instant('labels.tommorow')})`.toLowerCase();
    const yesterdayLabel =
      `(${translateService.instant('labels.yesterday')})`.toLowerCase();

    const isDayOpen = (date: DateTime) => {
      if (!businessHours) return true;

      const openingHours = CommonHelper.getOpeningHours(date, businessHours);
      if (!openingHours) return false;

      return !(
        date.hasSame(today, 'day') &&
        `${today.hour}:${today.minute}:00` > openingHours.to
      );
    };

    const getLabel = (date: DateTime): string => {
      if (date.hasSame(today, 'day')) return todayLabel;
      if (date.hasSame(today.plus({ days: 1 }), 'day')) return tomorrowLabel;
      if (date.hasSame(today.minus({ days: 1 }), 'day')) return yesterdayLabel;
      return '';
    };

    const suggestions = [];
    let date = today;

    while (suggestions.length < days) {
      if (isDayOpen(date)) {
        suggestions.push({
          name: `${datePipe.transform(date.toISODate(), 'short-date-dot')} ${getLabel(date)}`,
          value: date.toISODate(),
        });
      }
      date = future ? date.plus({ days: 1 }) : date.minus({ days: 1 });
    }

    return suggestions;
  };

  public static getDelegationAdressSuggestions = (
    driverBaseAddress: OnyxCustomAddress | null,
    driverHomeAddress: OnyxCustomAddress | null,
  ) => {
    const I18N = 'delegations.delegationPeriodForm';
    return [
      ...(driverBaseAddress
        ? [
            {
              name: `${I18N}.driverBase`,
              value: driverBaseAddress,
            },
          ]
        : []),
      ...(driverHomeAddress
        ? [
            {
              name: `${I18N}.driverHome`,
              value: driverHomeAddress,
            },
          ]
        : []),
    ];
  };

  public static getTimeRangeSuggestions(): OnyxSuggestion<OnyxTimeRange>[] {
    return [
      {
        name: '00:00 - 23:59',
        value: TIME_RANGE_ALL_DAY,
      },
      {
        name: '06:00 - 22:00',
        value: { from: '06:00:00', to: '22:00:00' },
      },
    ];
  }
}
