import { DecimalPipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxDataWidgetComponent, OnyxTooltipDirective } from '@onyx/angular';
import { AmountCellComponent } from '../../../../../../../common/components/cells/amount-cell/amount-cell.component';
import { I18nPipe } from '../../../../../../../common/pipes/i18n.pipe';
import { NumberDecorationPipe } from '../../../../../../../common/pipes/number-decoration.pipe';
import { OrderHelper } from '../../../../../common/helpers/order.helper';
import { Order } from '../../../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-plan-tolls',
  imports: [
    AmountCellComponent,
    OnyxDataWidgetComponent,
    TranslatePipe,
    NumberDecorationPipe,
    DecimalPipe,
    UpperCasePipe,
    I18nPipe,
    OnyxTooltipDirective,
  ],
  templateUrl: './order-modal-plan-tolls.component.html',
  styleUrl: './order-modal-plan-tolls.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPlanTollsComponent {
  protected readonly I18N = 'orders.orderModal.plan';

  public order = input.required<Order>();

  protected tolls = computed(() => this.order().route.summary.tollsCost);
  protected tollsTotalPrice = computed(
    () => this.tolls().defaultCurrencyValue / 100,
  );
  protected plannedData = computed(() => {
    const { status, route } = this.order();
    if (OrderHelper.isOrderPending(status.value)) return;

    const tolls = route.summary.tollsCost;
    return {
      total: {
        value: tolls.defaultCurrencyValue,
        currency: tolls.defaultCurrency,
      },
      tolls,
    };
  });
  protected realizationResult = computed(() => {
    const plannedData = this.plannedData();
    if (!OrderHelper.isOrderFinished(this.order()) || !plannedData) {
      return null;
    }

    const plannedTolls = plannedData.total.value / 100;
    const difference = this.tollsTotalPrice() - plannedTolls;
    return {
      price: {
        value: Math.abs(difference),
        currency: plannedData.total.currency,
      },
      color: difference > 0 ? 'red' : 'green',
      sign: difference > 0 ? '+' : '-',
    };
  });
}
