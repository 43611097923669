import { computed, DestroyRef, Injectable, signal } from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { isObject } from 'lodash';
import { DateTime } from 'luxon';
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  skip,
  startWith,
} from 'rxjs';
import { AuthService } from '../../../auth/common/services/auth.service';
import { BusinessDocument } from '../../../common/interfaces/documents/business-documents';
import { ImportZodSchema } from '../interfaces/import-config';
import { TopBar } from '../interfaces/top-bar';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private readonly I18N = 'dashboard.messages';

  public networkConnection = toSignal(
    merge(fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(
      startWith(navigator.onLine),
      map(() => navigator.onLine),
    ),
    { initialValue: true },
  );

  public documentsStatuses = computed(() => {
    const documents = this.authService.documents();
    const statuses = {
      outdated: 0,
      oneDay: 0,
      warning: 0,
    };

    if (!documents) return statuses;

    return Object.values(documents)
      .filter((document): document is BusinessDocument => isObject(document))
      .filter((document): document is any => 'expiryDate' in document)
      .reduce((result: typeof statuses, document) => {
        const expiryDate = DateTime.fromISO(document.expiryDate);
        const days = expiryDate.diffNow('days').days;

        if (days <= 0) {
          result.outdated += 1;
        } else if (days <= 1) {
          result.oneDay += 1;
        } else if (days <= 7) {
          result.warning += 1;
        }

        return result;
      }, statuses);
  });

  private _isScrolled = signal<boolean>(false);
  public get isScrolled() {
    return this._isScrolled.asReadonly();
  }
  private _topBar = signal<TopBar<any> | null>(null);
  public get topBar() {
    return this._topBar.asReadonly();
  }

  constructor(
    private destroyRef: DestroyRef,
    private toastService: OnyxToastService,
    private router: Router,
    private authService: AuthService,
  ) {
    toObservable(this.networkConnection)
      .pipe(
        skip(1),
        filter((online) => !!online),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() =>
        this.toastService.showSuccess(`${this.I18N}.networkConnectionRestored`),
      );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event.urlAfterRedirects.split('?')[0]),
        distinctUntilChanged(),
        map(() => undefined),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this._topBar.set({
          color: 'gray',
          breadcrumbs: [
            {
              segment: '/dashboard',
              label: 'Dashboard',
            },
          ],
        });
      });
  }

  public setTopBar<T extends ImportZodSchema>(topBar: TopBar<T>): void {
    this._topBar.set(topBar);
  }

  public setIsScrolled(isScrolled: boolean): void {
    this._isScrolled.set(isScrolled);
  }
}
