import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxNamePipe } from '@onyx/angular';
import { Order } from '../../../../common/interfaces/order';
import { OrderPdfTemplateContractorsComponent } from './order-pdf-template-contractors/order-pdf-template-contractors.component';
import { OrderPdfTemplateDetailsComponent } from './order-pdf-template-details/order-pdf-template-details.component';
import { OrderPdfTemplateRateComponent } from './order-pdf-template-rate/order-pdf-template-rate.component';
import { OrderPdfTemplateRouteComponent } from './order-pdf-template-route/order-pdf-template-route.component';

@Component({
  selector: 'app-order-pdf-template',
  imports: [
    OnyxNamePipe,
    TranslatePipe,
    UpperCasePipe,
    OrderPdfTemplateContractorsComponent,
    OrderPdfTemplateDetailsComponent,
    OrderPdfTemplateRouteComponent,
    OrderPdfTemplateRateComponent,
  ],
  templateUrl: './order-pdf-template.component.html',
  styleUrl: './order-pdf-template.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPdfTemplateComponent {
  protected readonly I18N = 'orders.pdfTemplate';

  public order = input.required<Order>();
}
