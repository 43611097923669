import { DecimalPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  InjectionToken,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { compact, get, has, isArray, isEqual, orderBy, uniqBy } from 'lodash';
import { distinctUntilChanged, fromEventPattern, map, skip } from 'rxjs';
import {
  OnyxAvatarComponent,
  OnyxFlagComponent,
  OnyxIconComponent,
  OnyxSearchComponent,
  OnyxSpinnerComponent,
} from '../../../components';
import { OnyxTooltipDirective } from '../../../directives';
import {
  OnyxDropdownConfig,
  OnyxOption,
  OnyxOptionsGroup,
} from '../../../directives/interfaces';
import { OnyxOverlayPosition } from '../../../enums';
import { UtilityHelper } from '../../../helpers';
import { OnyxHighlightPipe } from '../../../pipes';
import { I18N_NAMESPACE } from '../../constants';

export const ONYX_DROPDOWN_CONFIG = new InjectionToken<OnyxDropdownConfig<any>>(
  'Dropdown configuration',
);

@Component({
  selector: 'onyx-dropdown-overlay',
  imports: [
    NgClass,
    NgTemplateOutlet,
    OnyxIconComponent,
    OnyxSpinnerComponent,
    OnyxHighlightPipe,
    ReactiveFormsModule,
    TranslatePipe,
    forwardRef(() => OnyxSearchComponent),
    OnyxTooltipDirective,
    DecimalPipe,
    OnyxFlagComponent,
    OnyxAvatarComponent,
  ],
  templateUrl: './onyx-dropdown-overlay.component.html',
  styleUrl: './onyx-dropdown-overlay.component.scss',
})
export class OnyxDropdownOverlayComponent<T> {
  protected readonly I18N = `${I18N_NAMESPACE}.dropdown`;

  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;

  protected groups = signal<OnyxOptionsGroup<T>[] | null | undefined>(null);
  protected isEmpty = computed(
    () => !!this.groups()?.every((group) => group.options.length === 0),
  );
  protected value = computed<T[]>(() =>
    this.selectedOptions().map((option) => option.value),
  );
  protected selectedAny = computed(
    () =>
      this.groups()
        ?.flatMap((group) => group.options)
        .some((option) => !!option._selected) ?? false,
  );
  protected selectedAll = computed(
    () =>
      this.groups()
        ?.flatMap((group) => group.options)
        .every((option) => !!option._selected || !!option.disabled) ?? false,
  );

  private searchRef = viewChild(OnyxSearchComponent);

  private selectedOptions = signal<OnyxOption<T>[]>([]);

  constructor(
    @Inject(ONYX_DROPDOWN_CONFIG) protected config: OnyxDropdownConfig<T>,
    private elementRef: ElementRef<HTMLElement>,
    private destroyRef: DestroyRef,
  ) {
    const element = config.elementRef;
    if (element && config.width === 'inherit') {
      fromEventPattern<void>(
        (handler) => {
          const resizeObserver = new ResizeObserver(() => handler());
          resizeObserver.observe(element);

          return resizeObserver;
        },
        (_, resizeObserver?: ResizeObserver) => resizeObserver?.disconnect(),
      )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.elementRef.nativeElement.style.minWidth = `${Math.max(240, element.clientWidth)}px`;
        });
    }

    this.selectedOptions.set(
      config.selectedOptions ??
        compact(config.values ?? []).map((value) => ({ name: '', value })),
    );
    this.setOptions();

    toObservable(this.value)
      .pipe(
        distinctUntilChanged(isEqual),
        skip(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.config.changeValues({
          values: this.value(),
          options: this.selectedOptions(),
        });
        if (!this.config.multiple) this.config.close();
      });
  }

  public focus(): void {
    this.focusSearch();
  }

  @HostListener('keydown.escape')
  @HostListener('keydown.shift.tab')
  @HostListener('keydown.tab')
  protected close(): void {
    this.config.close();
  }

  protected focusElement(): void {
    this.config.focusElement();
  }

  protected focusSearch(): void {
    if (this.config.search) this.searchRef()?.focus();
  }

  protected toggleAll(selectAll?: boolean): void {
    selectAll ??= !this.selectedAll();
    this.groups.update((groups) =>
      groups?.map((group) => ({
        ...group,
        options: group.options.map(
          (option): OnyxOption<T> => ({
            ...option,
            _selected: option.disabled ? option._selected : selectAll,
          }),
        ),
      })),
    );

    const toggledOptions =
      this.groups()
        ?.flatMap((group) => group.options)
        ?.filter((option) => option._selected === selectAll) ?? [];
    this.selectedOptions.update((selectedOptions) => {
      if (selectAll) {
        return uniqBy([...toggledOptions, ...selectedOptions], 'value');
      }
      return selectedOptions.filter(
        (option) =>
          !toggledOptions.some((toggled) =>
            isEqual(
              this.getCompareValue(toggled.value),
              this.getCompareValue(option.value),
            ),
          ),
      );
    });
  }

  protected toggleOption(value: T, force?: boolean): void {
    if (!this.config.optional && !this.config.multiple) force = true;

    this.groups.update((groups) =>
      groups?.map((group) => ({
        ...group,
        options: group.options.map((option) => {
          const toggle = isEqual(option.value, value)
            ? (force ?? !option._selected)
            : this.config.multiple
              ? null
              : false;
          if (toggle === null) return option;

          if (toggle) {
            this.selectedOptions.update((selectedOptions) => {
              if (this.config.multiple) {
                return uniqBy([...selectedOptions, option], 'value');
              }
              return [option];
            });
          } else {
            this.selectedOptions.update((selectedOptions) =>
              selectedOptions.filter(
                (selectedOption) =>
                  !isEqual(
                    this.getCompareValue(selectedOption.value),
                    this.getCompareValue(option.value),
                  ),
              ),
            );
          }

          return {
            ...option,
            _selected: toggle,
          };
        }),
      })),
    );

    if (!this.config.multiple) setTimeout(() => this.config.close());
  }

  protected clear(): void {
    this.toggleAll(false);
    setTimeout(() => this.config.close());
  }

  private getCompareValue(value: T): T | unknown {
    return this.config.compareKey ? get(value, this.config.compareKey) : value;
  }

  private setOptions(): void {
    const source$ = UtilityHelper.maybeAsyncToObservable(this.config.options);

    let first = true;
    source$
      .pipe(
        map((data): OnyxOptionsGroup<T>[] | null => {
          if (data == null || !isArray(data)) {
            return null;
          } else if (has(data[0], 'options')) {
            return data as OnyxOptionsGroup<T>[];
          }
          return [
            {
              subheading: this.config.subheading,
              subheadingTemplateRef: this.config.subheadingTemplateRef,
              options: data as OnyxOption<T>[],
            },
          ];
        }),
        map((groups) => {
          return groups?.map((group) => ({
            ...group,
            options: group.options
              .filter((option) => !option._hidden)
              .map(
                (option): OnyxOption<T> => ({
                  ...option,
                  _selected: this.value().some((value) =>
                    isEqual(
                      this.getCompareValue(value),
                      this.getCompareValue(option.value),
                    ),
                  ),
                }),
              ),
          }));
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((groups) => {
        this.groups.set(
          first && this.config.multiple
            ? groups?.map((group) => ({
                ...group,
                options: orderBy(
                  group.options,
                  [
                    (option) => option.disabled && option._selected,
                    (option) => option._selected,
                    (option) => !option._selected,
                  ],
                  ['desc', 'desc', 'desc'],
                ),
              }))
            : groups,
        );

        if (!first) return;
        first = false;

        if (this.isEmpty()) return this.focusSearch();
      });
  }
}
