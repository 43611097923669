import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { addBaseResolver } from './common/resolvers/add-base.resolver';
import { editBaseResolver } from './common/resolvers/edit-base.resolver';

export enum BasesRoute {
  BASES_LIST = '/management-panel/bases',
  ADD_BASE = '/management-panel/bases/add',
  EDIT_BASE = '/management-panel/bases/:uuid/edit',
}

export const BASES_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./bases-list/bases-list.component').then(
        (m) => m.BasesListComponent,
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./base-form/base-form.component').then(
        (m) => m.BaseFormComponent,
      ),
    data: { mode: OnyxFormMode.ADD },
    resolve: { form: addBaseResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./base-form/base-form.component').then(
        (m) => m.BaseFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { form: editBaseResolver },
  },
] as const;
