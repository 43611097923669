import {
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';
import {
  DecimalPipe,
  NgClass,
  NgComponentOutlet,
  NgStyle,
  NgTemplateOutlet,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Injector,
  OnInit,
  TemplateRef,
  computed,
  effect,
  input,
  model,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  difference,
  differenceBy,
  get,
  intersectionBy,
  isArray,
  isFunction,
  orderBy,
} from 'lodash';
import {
  fromEventPattern,
  map,
  of,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import {
  OnyxDropdownDirective,
  OnyxTooltipDirective,
} from '../../../directives';
import { OnyxOption, OnyxOptionsGroup } from '../../../directives/interfaces';
import { OnyxFormMode } from '../../../enums';
import { OverflowHelper } from '../../../helpers/overflow.helper';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxModalService } from '../../../services';
import { OnyxUserSettingsService } from '../../../services/onyx-user-settings.service';
import { OnyxIconButtonComponent } from '../../buttons';
import { OnyxCheckboxComponent } from '../../checkbox';
import { OnyxDropdownComponent } from '../../dropdown';
import { OnyxErrorComponent } from '../../error/onyx-error/onyx-error.component';
import { OnyxIcon, OnyxIconComponent } from '../../icons';
import { OnyxLinkComponent } from '../../links';
import { OnyxLoadingBannerComponent } from '../../loading-banner';
import { OnyxPagination, OnyxPaginationComponent } from '../../pagination';
import { OnyxRadioButtonComponent } from '../../radio-button';
import { OnyxRibbonComponent } from '../../ribbon/onyx-ribbon/onyx-ribbon.component';
import { OnyxSpinnerComponent } from '../../spinner';
import { OnyxTabsComponent } from '../../tabs';
import { ONYX_TABLE_VIEW_MODE_TABS } from '../constants';
import {
  OnyxTableAction,
  OnyxTableColor,
  OnyxTableRowSize,
  OnyxTableSelectionType,
  OnyxTableViewMode,
} from '../enums';
import {
  OnyxPaginated,
  OnyxPaths,
  OnyxTableColumn,
  OnyxTableCustomAction,
  OnyxTableData,
  OnyxTableNotFound,
  OnyxTableRowOptions,
  OnyxTableView,
} from '../interfaces';
import {
  OnyxTableViewModalComponent,
  OnyxTableViewModalData,
} from './onyx-table-view-modal/onyx-table-view-modal.component';

@Component({
  selector: 'onyx-table',
  imports: [
    ScrollingModule,
    NgClass,
    NgStyle,
    OnyxSpinnerComponent,
    OnyxPaginationComponent,
    TranslatePipe,
    OnyxLinkComponent,
    OnyxIconComponent,
    NgTemplateOutlet,
    NgComponentOutlet,
    OnyxTooltipDirective,
    OnyxCheckboxComponent,
    OnyxRadioButtonComponent,
    ReactiveFormsModule,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxRibbonComponent,
    OnyxDropdownDirective,
    OnyxDropdownComponent,
    OnyxTabsComponent,
    DecimalPipe,
    NgTemplateOutlet,
    OnyxErrorComponent,
    OnyxLoadingBannerComponent,
  ],
  templateUrl: './onyx-table.component.html',
  styleUrl: './onyx-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTableComponent<T> implements OnInit, AfterViewInit {
  protected readonly I18N = `${I18N_NAMESPACE}.table`;
  protected readonly VIEW_MODE_TABS = ONYX_TABLE_VIEW_MODE_TABS;
  protected readonly SELECTION_COLUMN_WIDTH = 32;
  protected readonly OPTIONS_COLUMN_WIDTH = 48;
  protected readonly DEFAULT_NOT_FOUND_ICON: OnyxIcon = {
    name: 'no-results',
    size: 24,
  };
  protected readonly DEFAULT_NOT_FOUND_TITLE = `${this.I18N}.noResults`;

  protected readonly OnyxTableSelectionType = OnyxTableSelectionType;
  protected readonly OnyxTableColor = OnyxTableColor;
  protected readonly get = get;
  protected readonly OverflowHelper = OverflowHelper;
  protected readonly isFunction = isFunction;
  protected readonly OnyxListViewMode = OnyxTableViewMode;

  public data = input.required({
    transform: (
      data: OnyxPaginated<T> | T[] | null,
    ): OnyxPaginated<T> | null => {
      if (data == null) return null;

      const items = isArray(data) ? data : data.items;
      this.selectedItems.update((selectedItems) => {
        const existingSelectedItems = differenceBy(
          selectedItems,
          items,
          (item) => this.getItemId(item),
        );
        const updatedSelectedItems = intersectionBy(
          items,
          selectedItems,
          (item) => this.getItemId(item),
        );
        return [...existingSelectedItems, ...updatedSelectedItems];
      });

      if (isArray(data)) {
        return {
          items,
          totalItems: items.length,
          page: 1,
          limit: items.length,
        };
      }
      return data;
    },
  });
  public columns = input.required<OnyxTableColumn<T>[]>();
  public loading = input(false);
  public showHeader = input(true);
  public defaultViews = input<OnyxTableView[]>();
  public customViews = input<string>();
  public showViewMode = input(false);
  public viewMode = model(OnyxTableViewMode.LIST);

  public selectionType = input<OnyxTableSelectionType>();
  public compareKey = input<OnyxPaths<T>>();
  public selectedItems = model<T[]>([]);
  public selectedItemsIdChange = output<unknown[]>();
  public batchActionsTemplateRef = input<TemplateRef<any>>();

  public tableSize = input<'s' | 'm'>('m');
  public tableShadow = input(true);
  public rowSize = input(OnyxTableRowSize.SMALL);
  public rowColor = input<(data: OnyxTableData<T>) => OnyxTableColor>();
  public rowOptions = input<OnyxTableRowOptions<T>>();
  public rowOptionTemplateRef = input<TemplateRef<any>>();
  public rowClick = output<OnyxTableData<T>>();

  public notFound = input<OnyxTableNotFound>({});
  public error = model(false);
  public pagination = model<OnyxPagination>();
  public paginationShowLimit = input(true);
  public paginationPadding = input(8);

  protected initialLoading = computed(
    () => this.data() == null && !this.error(),
  );
  protected visibleColumns = computed(() => {
    const view = this.view() ?? this.defaultViews()?.[0];
    const visibleColumns = this.columns().filter((column) => {
      if (view == null) return true;
      return view.value.includes(column.id);
    });
    const mappedColumns = visibleColumns.map((column) => ({
      ...column,
      actions: this.mapActions(column.actions),
    }));
    const orderedColumns = orderBy(mappedColumns, (column) =>
      view?.value.indexOf(column.id),
    );
    return orderedColumns;
  });

  protected views = signal<OnyxOptionsGroup<string>[]>([]);
  protected view = signal<OnyxTableView | null>(null);

  protected selectedItemsId = computed(() =>
    this.selectedItems().map((item) => this.getItemId(item)),
  );
  protected isPageCheckboxChecked = computed(() => {
    const selectedItemsId = this.selectedItemsId();
    const items = this.data()?.items;
    if (!items) return false;

    return items.every((item) =>
      selectedItemsId.includes(this.getItemId(item)),
    );
  });

  protected isPageCheckboxIndeterminate = computed(() => {
    if (this.isPageCheckboxChecked()) return false;

    const selectedItemsId = this.selectedItemsId();
    const items = this.data()?.items ?? [];

    return items.some((item) => selectedItemsId.includes(this.getItemId(item)));
  });
  protected radioButtonSelection = this.fb.group({
    itemId: this.fb.control<unknown | null>(null),
  });

  protected tablePadding = computed(
    () =>
      ({
        s: 0,
        m: 16,
      })[this.tableSize()],
  );
  protected hasFrozenLeft = computed(
    () => !!(this.selectionType() || this.columns()[0]?.required),
  );
  protected frozenRequiredLeft = computed(
    () =>
      this.tablePadding() +
      (this.selectionType() != null ? this.SELECTION_COLUMN_WIDTH : 0),
  );
  protected hasFrozenRight = computed(() => this.rowOptions() != null);
  protected hasFrozenShadows = signal({ left: false, right: false });
  protected showRowClick = computed(() => this.rowClick['listeners'] != null);

  private scrollViewportRef = viewChild.required(CdkVirtualScrollViewport);

  protected get stickyTop(): string {
    const offset =
      this.scrollViewportRef().getOffsetToRenderedContentStart() ?? 0;
    return `-${offset}px`;
  }

  constructor(
    private fb: NonNullableFormBuilder,
    private modalService: OnyxModalService,
    private userSettingsService: OnyxUserSettingsService,
    private destroyRef: DestroyRef,
    private injector: Injector,
  ) {
    effect(() => {
      this.selectionType();
      untracked(() => {
        this.selectedItems.set([]);
        this.radioButtonSelection.setValue({ itemId: null });
      });
    });

    const radioButtonSelectedItemId = toSignal(
      this.radioButtonSelection.controls.itemId.valueChanges,
    );
    effect(() => {
      if (this.selectionType() !== OnyxTableSelectionType.RADIO) return;

      const selectedItemId = radioButtonSelectedItemId();
      this.selectedItems.update((selectedItems) =>
        selectedItems.filter((item) => this.getItemId(item) === selectedItemId),
      );
    });

    effect(() => this.selectedItemsIdChange.emit(this.selectedItemsId()));
  }

  public ngOnInit(): void {
    const defaultViews = this.defaultViews()?.map((view) => ({
      ...view,
      default: true,
    }));
    if (defaultViews) {
      this.userSettingsService.reload$
        .pipe(
          startWith(null),
          switchMap(() => {
            const customViews = this.customViews();
            if (!customViews) {
              return of({
                customViews: [],
                selected: null,
              });
            }

            return this.userSettingsService.getSettings(customViews).pipe(
              map(({ settings, selected }) => ({
                customViews: settings.map(
                  (setting): OnyxTableView => ({
                    uuid: setting.uuid,
                    name: setting.name,
                    value: JSON.parse(setting.value),
                  }),
                ),
                selected,
              })),
            );
          }),
          tap(({ customViews, selected }) => {
            const activeView = [...defaultViews, ...customViews].find(
              (view) =>
                view.uuid === selected || view.uuid === this.view()?.uuid,
            );
            this.view.set(activeView ?? null);
          }),
          map(({ customViews }) => {
            const viewToOption = (
              view: OnyxTableView,
            ): OnyxOption<string> & { view: OnyxTableView } => ({
              name: view.name,
              value: view.uuid,
              view,
            });

            return [
              { options: defaultViews.map(viewToOption) },
              ...(customViews.length
                ? [{ options: customViews.map(viewToOption) }]
                : []),
            ];
          }),
        )
        .subscribe((views) => this.views.set(views));
    }
  }

  public ngAfterViewInit(): void {
    effect(
      () => {
        if (this.initialLoading() || this.loading()) return;

        setTimeout(() => {
          this.scrollViewportRef().scrollToOffset(0);
          this.scrollViewportRef().checkViewportSize();
          this.updateFrozenShadows();
        });
      },
      { injector: this.injector },
    );

    this.scrollViewportRef()
      .elementScrolled()
      .pipe(startWith(null), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateFrozenShadows());

    fromEventPattern<void>(
      (handler) => {
        const resizeObserver = new ResizeObserver(() => handler());
        resizeObserver.observe(
          this.scrollViewportRef().elementRef.nativeElement,
        );

        return resizeObserver;
      },
      (_, resizeObserver?: ResizeObserver) => resizeObserver?.disconnect(),
    )
      .pipe(skip(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateFrozenShadows());
  }

  protected changeView(view: OnyxTableView | null): void {
    if (view == null) return;
    if (view.uuid === this.defaultViews()?.[0]?.uuid) view = null;

    this.view.set(view);

    const customViews = this.customViews();
    if (customViews) {
      this.userSettingsService
        .selectSetting(customViews, view?.uuid ?? null)
        .subscribe();
    }
  }

  protected addView(): void {
    this.modalService
      .open<OnyxTableViewModalData, OnyxTableView>(
        OnyxTableViewModalComponent,
        {
          scope: this.customViews()!,
          columns: this.columns(),
          defaultView: this.defaultViews()![0],
          mode: OnyxFormMode.ADD,
          value: this.visibleColumns().map((column) => column.id),
        },
      )
      .result.subscribe((view) => {
        if (view) this.changeView(view);
      });
  }

  protected editView(view: OnyxTableView): void {
    this.modalService
      .open<OnyxTableViewModalData, OnyxTableView>(
        OnyxTableViewModalComponent,
        {
          scope: this.customViews()!,
          columns: this.columns(),
          defaultView: this.defaultViews()![0],
          mode: OnyxFormMode.EDIT,
          ...view,
        },
      )
      .result.subscribe((view) => {
        if (view) this.changeView(view);
      });
  }

  protected togglePageItems(page: OnyxPaginated<T>, selected: boolean): void {
    const pageItemsId = page.items.map((item) => this.getItemId(item));
    const newItemsId = difference(pageItemsId, this.selectedItemsId());

    this.selectedItems.update((selectedItems) => {
      if (selected) {
        return [
          ...selectedItems,
          ...page.items.filter((item) =>
            newItemsId.includes(this.getItemId(item)),
          ),
        ];
      }
      return selectedItems.filter(
        (selectedItem) => !pageItemsId.includes(this.getItemId(selectedItem)),
      );
    });
  }

  protected toggleItem(item: T, selected: boolean): void {
    if (this.selectionType() === OnyxTableSelectionType.RADIO) {
      this.radioButtonSelection.setValue({ itemId: this.getItemId(item) });
      return;
    }

    const id = this.getItemId(item);
    this.selectedItems.update((selectedItems) => {
      if (selected) return [...selectedItems, item];
      return selectedItems.filter(
        (selectedItem) => this.getItemId(selectedItem) !== id,
      );
    });
  }

  protected clearSelection(): void {
    this.selectedItems.set([]);
  }

  protected itemIdentity(_: number, value: T): unknown {
    return this.getItemId(value);
  }

  protected getItemId(item: T): unknown {
    const compareKey = this.compareKey();
    return compareKey ? get(item, compareKey) : item;
  }

  private updateFrozenShadows(): void {
    const scrollViewport = this.scrollViewportRef();
    this.hasFrozenShadows.set({
      left: scrollViewport.measureScrollOffset('left') > 0,
      right: scrollViewport.measureScrollOffset('right') > 0,
    });
  }

  private mapActions(
    actions: OnyxTableColumn<T>['actions'],
  ): OnyxTableCustomAction<T>[] {
    if (!actions) return [];

    return orderBy(actions)
      .slice(0, 2)
      .map((action) => {
        const ICONS: Record<OnyxTableAction, OnyxIcon> = {
          [OnyxTableAction.EDIT]: { name: 'edit', size: 16 },
          [OnyxTableAction.OPEN_PROFILE]: { name: 'arrow-right', size: 16 },
          [OnyxTableAction.CALL]: { name: 'telephone', size: 16 },
          [OnyxTableAction.COPY]: { name: 'copy', size: 16 },
        };
        const TOOLTIPS: Record<OnyxTableAction, string> = {
          [OnyxTableAction.EDIT]: `${this.I18N}.actions.edit`,
          [OnyxTableAction.OPEN_PROFILE]: `${this.I18N}.actions.openProfile`,
          [OnyxTableAction.CALL]: `${this.I18N}.actions.call`,
          [OnyxTableAction.COPY]: `${this.I18N}.actions.copy`,
        };

        const isDefault = Object.values(OnyxTableAction).includes(
          action.icon as OnyxTableAction,
        );
        return {
          icon: isDefault ? ICONS[action.icon as OnyxTableAction] : action.icon,
          callback: action.callback,
          tooltip:
            action.tooltip ??
            (isDefault ? TOOLTIPS[action.icon as OnyxTableAction] : undefined),
          hidden: action.hidden,
        };
      });
  }
}
