import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import {
  AbstractControlOptions,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxAddressInputComponent,
  OnyxButtonComponent,
  OnyxCustomAddress,
  OnyxDatepickerComponent,
  OnyxDatePipe,
  OnyxFormGroupComponent,
  onyxMinDateTimeValidator,
  OnyxModalComponent,
  OnyxNamePipe,
  OnyxSuggestionsComponent,
  OnyxTime,
  OnyxTimepickerComponent,
  OnyxToastService,
} from '@onyx/angular';
import { chain } from 'lodash';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { SuggestionHelper } from '../../../common/helpers/suggestions.helper';
import { ValidationHelper } from '../../../common/helpers/validation.helper';
import { FleetIdentifierPipe } from '../../../common/pipes/fleet-identifier.pipe';
import { JoinPipe } from '../../../common/pipes/join.pipe';
import { Delegation } from '../common/interfaces/delegation';
import { DelegationsService } from '../common/services/delegations.service';

type DelegationActionModalFormGroup = ReturnType<
  DelegationActionModalComponent['buildForm']
>;

export type DelegationActionModalForm = ReturnType<
  DelegationActionModalFormGroup['getRawValue']
>;

export interface DelegationActionModalData {
  mode: DelegationActionModalMode;
  delegation: Delegation;
}

export enum DelegationActionModalMode {
  ACTIVATE = 'activate',
  FINISH = 'finish',
}

@Component({
  selector: 'app-delegation-action-modal',
  imports: [
    OnyxModalComponent,
    TranslatePipe,
    JoinPipe,
    FleetIdentifierPipe,
    OnyxDatepickerComponent,
    OnyxTimepickerComponent,
    OnyxAddressInputComponent,
    OnyxButtonComponent,
    ReactiveFormsModule,
    OnyxSuggestionsComponent,
    OnyxFormGroupComponent,
  ],
  templateUrl: './delegation-action-modal.component.html',
  styleUrl: './delegation-action-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationActionModalComponent {
  protected readonly I18N = 'delegations';
  protected readonly DATE_SUGGESTIONS = SuggestionHelper.getDateSuggestions(
    this.translateService,
    this.datePipe,
    { future: false },
  );
  protected readonly TIME_SUGGESTIONS = [
    {
      name: 'labels.now',
      value: `${DateTime.now().toFormat('HH:mm')}:00`,
    },
  ];

  protected readonly isActivate: boolean;
  protected readonly drivers = chain(this.data.delegation)
    .thru((delegation) => [
      delegation.primaryDriver,
      delegation.secondaryDriver,
    ])
    .compact()
    .map(({ driverData }) => this.namePipe.transform(driverData))
    .value();

  protected form = this.buildForm();
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected data: DelegationActionModalData,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private translateService: TranslateService,
    private datePipe: OnyxDatePipe,
    private delegationsService: DelegationsService,
    private namePipe: OnyxNamePipe,
  ) {
    this.isActivate = this.data.mode === DelegationActionModalMode.ACTIVATE;

    const delegationPeriodData = this.isActivate
      ? data.delegation.start
      : data.delegation.end;
    this.form.setValue(delegationPeriodData);
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();
    const action$ = {
      [DelegationActionModalMode.ACTIVATE]:
        this.delegationsService.activateDelegation(
          this.data.delegation.uuid,
          form,
        ),
      [DelegationActionModalMode.FINISH]:
        this.delegationsService.finishDelegation(
          this.data.delegation.uuid,
          form,
        ),
    }[this.data.mode];

    action$.subscribe({
      next: () => {
        const toastMessage = {
          [DelegationActionModalMode.ACTIVATE]: `${this.I18N}.delegationActivated`,
          [DelegationActionModalMode.FINISH]: `${this.I18N}.delegationFinished`,
        }[this.data.mode];

        this.toastService.showSuccess(toastMessage, { keepOnNavigation: true });
        this.close$.next();
      },
      error: (response: HttpErrorResponse) => {
        if (
          response.error ===
          'trailerUuid: Trailer is assigned to vehicle already.'
        ) {
          this.toastService.showError(
            `${this.I18N}.trailerIsAssignedToOtherVehicle`,
          );
        } else {
          ValidationHelper.handleUnexpectedError(response, this.toastService);
        }
      },
    });
  }

  protected buildForm() {
    let minDateTime: DateTime | null = null;
    if (!this.isActivate) {
      const { date, time } = this.data.delegation.start;
      minDateTime = DateTime.fromISO(`${date}T${time}`).plus({ minutes: 1 });
    }

    return this.fb.group(
      {
        date: this.fb.control<string | null>(null, [Validators.required]),
        time: this.fb.control<OnyxTime | null>(null, [Validators.required]),
        address: this.fb.control<OnyxCustomAddress | null>(null, [
          Validators.required,
        ]),
      },
      {
        validators: [
          ...(minDateTime
            ? [onyxMinDateTimeValidator(minDateTime, minDateTime)]
            : []),
        ],
      } as AbstractControlOptions,
    );
  }
}
