import { inject } from '@angular/core';
import { RedirectCommand, ResolveFn, Router } from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, of, tap } from 'rxjs';
import { AuthRoute } from '../../../../../auth/auth.routes';
import { AuthService } from '../../../../../auth/common/services/auth.service';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { Business } from '../interfaces/business';
import { BusinessService } from '../services/business.service';

export const businessResolver: ResolveFn<Business> = () => {
  const businessService = inject(BusinessService);
  const authService = inject(AuthService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  return businessService.getBusiness().pipe(
    tap((business) => authService.updateBusiness(business)),
    catchError((response) => {
      ValidationHelper.handleUnexpectedError(response, toastService, {
        keepOnNavigation: true,
      });
      authService.logout(false);

      const urlTree = router.parseUrl(AuthRoute.LOGIN);
      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};
