@if (amount(); as amount) {
  <div class="amount-cell">
    <span>{{ amount | onyxAmount: show() : short() : decimalPlaces() }} </span>

    @if (percentage(); as percentage) {
      <span>({{ percentage | number: '1.0-2' }}%)</span>
    }
  </div>
} @else {
  -
}
