import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  OnyxDatePipe,
  OnyxTimePipe,
  OnyxWidgetBoxComponent,
} from '@onyx/angular';
import { Duration } from 'luxon';
import { timer } from 'rxjs';
import { DurationPipe } from '../../../../../common/pipes/duration.pipe';
import { OrderStatus } from '../../../common/enums/order-status';
import { OrderTimeWindowType } from '../../../common/enums/order-time-window-type';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';
import { OrderLoadingPoint } from '../../../common/interfaces/order-point';

enum LoadingStatus {
  NO_VEHICLE = 'noVehicle',
  ON_TIME = 'onTime',
  DELAYED = 'delayed',
}

@Component({
  selector: 'app-order-modal-loading-deadline',
  imports: [OnyxTimePipe, OnyxDatePipe, DurationPipe, OnyxWidgetBoxComponent],
  templateUrl: './order-modal-loading-deadline.component.html',
  styleUrl: './order-modal-loading-deadline.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalLoadingDeadlineComponent {
  protected readonly I18N = 'orders.orderModal';

  protected readonly OrderTimeWindowType = OrderTimeWindowType;
  protected readonly OrderStatus = OrderStatus;
  protected readonly LoadingStatus = LoadingStatus;

  public order = input.required<Order>();

  protected firstLoadingPoint = computed<OrderLoadingPoint>(() =>
    OrderHelper.getFirstLoadingPoint(this.order().points),
  );
  protected loadingPointDeadline = computed(() =>
    OrderHelper.getLoadingPointDeadline(this.firstLoadingPoint()),
  );
  protected loadingStatus = computed<LoadingStatus>(() => {
    this.timer();

    if (!OrderHelper.hasApprovedVehicle(this.order().status.value)) {
      return LoadingStatus.NO_VEHICLE;
    }

    const remainingTime = this.durationPipe.transform(
      this.loadingPointDeadline(),
      { format: 'to' },
    );
    return remainingTime === '-'
      ? LoadingStatus.DELAYED
      : LoadingStatus.ON_TIME;
  });

  protected loadingStatusColor = computed(() => {
    const statusColors = {
      [LoadingStatus.ON_TIME]: 'green',
      [LoadingStatus.DELAYED]: 'red',
      [LoadingStatus.NO_VEHICLE]: 'gray',
    } as const;

    return statusColors[this.loadingStatus()];
  });
  private timer = toSignal(
    timer(0, Duration.fromObject({ second: 1 }).toMillis()),
    { initialValue: 0 },
  );

  constructor(private durationPipe: DurationPipe) {}
}
