import { chain } from 'lodash';
import { OnyxMapVehicleStatus, OnyxRoute } from '../components';
import { UtilityHelper } from './utility.helper';

export class MapHelper {
  public static getVehicleStatusAzimuth(
    status: OnyxMapVehicleStatus,
    azimuth: number,
  ): number | null {
    switch (status) {
      case OnyxMapVehicleStatus.OFF_ROUTE_ON_TIME:
      case OnyxMapVehicleStatus.OFF_ROUTE_DELAY_RISK:
      case OnyxMapVehicleStatus.OFF_ROUTE_DELAYED:
      case OnyxMapVehicleStatus.FAILURE:
      case OnyxMapVehicleStatus.FAILURE_FREE:
      case OnyxMapVehicleStatus.SEMI_TRAILER_FREE:
      case OnyxMapVehicleStatus.SEMI_TRAILER_LOADED:
      case OnyxMapVehicleStatus.TRAILER_FREE:
      case OnyxMapVehicleStatus.TRAILER_LOADED:
        return null;

      default:
        return azimuth;
    }
  }

  public static getRouteId(route: OnyxRoute): number {
    return chain(route.points)
      .flatMap((point) => point.legs)
      .map((leg) => leg.polyline)
      .join()
      .thru((polyline) => UtilityHelper.djb2Hash(polyline))
      .value();
  }
}
