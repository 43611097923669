import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  Injector,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxDropdownComponent,
  OnyxFormMode,
  OnyxIconComponent,
  OnyxLanguagePipe,
  onyxMinNumberValidator,
  OnyxModalService,
  OnyxOption,
  OnyxSectionComponent,
  OnyxTab,
  OnyxTabsComponent,
  OnyxTextFieldComponent,
  OnyxToggleComponent,
  OnyxTooltipComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { map, shareReplay, startWith, switchMap } from 'rxjs';
import { SemiTrailerSize } from '../../../../dashboard/fleet/common/enums/semi-trailer-size';
import { TrailerSize } from '../../../../dashboard/fleet/common/enums/trailer-size';
import { CompanyDictionaryCode } from '../../../../dashboard/management-panel/dictionaries/common/enums/company-dictionary-code';
import { CompanyDictionariesService } from '../../../../dashboard/management-panel/dictionaries/common/services/company-dictionaries.service';
import {
  DictionariesVehicleParameterModalComponent,
  DictionariesVehicleParameterModalData,
} from '../../../../dashboard/management-panel/dictionaries/dictionaries/dictionaries-vehicle-parameters/dictionaries-vehicle-parameter-modal/dictionaries-vehicle-parameter-modal.component';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { ValidationHelper } from '../../../helpers/validation.helper';
import { ForbiddenField } from '../../../interfaces/validation/validation-field';
import { ValidationGroup } from '../../../interfaces/validation/validation-group';
import { I18nPipe } from '../../../pipes/i18n.pipe';
import { DictionariesService } from '../../../services/dictionaries.service';

export type FleetAdditionalParametersFormGroup = ReturnType<
  typeof ParametersFormComponent.buildForm<'fleet'>
>;

export type OrderParametersFormGroup = ReturnType<
  typeof ParametersFormComponent.buildForm<'order'>
>;

@Component({
  selector: 'app-parameters-form',
  imports: [
    OnyxSectionComponent,
    TranslatePipe,
    ReactiveFormsModule,
    OnyxIconComponent,
    OnyxToggleComponent,
    OnyxDropdownComponent,
    AsyncPipe,
    OnyxTextFieldComponent,
    OnyxTabsComponent,
    OnyxTooltipComponent,
    OnyxTooltipDirective,
    I18nPipe,
  ],
  templateUrl: './parameters-form.component.html',
  styleUrl: './parameters-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParametersFormComponent<T extends 'fleet' | 'order'>
  implements OnInit
{
  protected readonly I18N = 'forms.parameters';

  protected readonly SemiTrailerSize = SemiTrailerSize;
  protected readonly TrailerSize = TrailerSize;

  protected readonly languages$ = this.dictionariesService.getDictionary(
    DictionaryCode.LANGUAGE,
  );
  protected readonly semiTrailerSizes$ = this.dictionariesService
    .getDictionary(DictionaryCode.SEMI_TRAILER_SIZE)
    .pipe(
      map((options) =>
        options.map(
          (option): OnyxTab<string> => ({
            value: option.value,
            icon: {
              name: `semi-trailer-${option.value}`,
              size: 16,
            },
            tooltip: option.name,
          }),
        ),
      ),
    );
  protected readonly trailerSizes$ = this.dictionariesService
    .getDictionary(DictionaryCode.TRAILER_SIZE)
    .pipe(
      map((options) =>
        options.map(
          (option): OnyxTab<string> => ({
            value: option.value,
            icon: {
              name: option.value,
              size: 16,
            },
            tooltip: option.name,
          }),
        ),
      ),
    );
  protected readonly otherParameters$ =
    this.companyDictionariesService.reload$.pipe(
      startWith(undefined),
      switchMap(() =>
        this.companyDictionariesService
          .listDictionary(CompanyDictionaryCode.VEHICLE_PARAMETER)
          .pipe(
            map((parameters): OnyxOption<string>[] =>
              parameters.map((parameter) => ({
                value: parameter.uuid,
                name: this.languagePipe.transform(parameter.names),
              })),
            ),
          ),
      ),
      shareReplay(1),
      takeUntilDestroyed(this.destroyRef),
    );

  public type = input.required<T>();
  public form =
    input.required<
      T extends 'fleet'
        ? FleetAdditionalParametersFormGroup
        : OrderParametersFormGroup
    >();
  public validation = input.required<ValidationGroup | undefined>();
  public hasAssignedFleet = input<boolean>(false);

  protected hasLowDeck = computed(() => {
    const field = this.validation()?.fields['isLowDeck'];
    return field && field.type !== 'forbidden';
  });
  protected hasSemiTrailerSize = computed(() => {
    const field = this.validation()?.fields['semiTrailerSize'];
    return field && field.type !== 'forbidden';
  });
  protected hasTrailerSize = computed(() => {
    const field = this.validation()?.fields['trailerSize'];
    return field && field.type !== 'forbidden';
  });

  protected i18n = computed(
    () =>
      ({
        fleet: 'fleet.fleetForm.additionalParameters',
        order: 'orders.orderForm.parameters',
      })[this.type()],
  );

  protected showAdrClasses = computed(() => {
    if (this.type() === 'order') return true;

    const field = this.validation()?.fields['adrClasses'];
    return field && field.type !== 'forbidden';
  });
  protected hasAdrClasses = signal(false);
  protected adrClasses$ = this.dictionariesService.getDictionary(
    DictionaryCode.ADR_CLASS,
  );

  protected hasDriverLanguages = signal(false);

  protected showMatsNumber = computed(() => {
    const field = this.validation()?.fields['matsNumber'];
    return field && field.type !== 'forbidden';
  });
  protected hasMatsNumber = signal(false);

  protected showCoilWellLength = computed(() => {
    const field = this.validation()?.fields['coilWellLength'];
    return field && field.type !== 'forbidden';
  });
  protected hasCoilWellLength = signal(false);

  protected hasSentNotificationNumber = signal(false);

  protected showBeltsNumber = computed(() => {
    const field = this.validation()?.fields['beltsNumber'];
    return field && field.type !== 'forbidden';
  });
  protected hasBeltsNumber = signal(false);

  constructor(
    private dictionariesService: DictionariesService,
    private injector: Injector,
    private companyDictionariesService: CompanyDictionariesService,
    private languagePipe: OnyxLanguagePipe,
    private destroyRef: DestroyRef,
    private modalService: OnyxModalService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    const controls = this.form().controls;
    this.reset();

    effect(
      () => {
        const FORBIDDEN_FIELD: ForbiddenField = { type: 'forbidden' } as const;

        this.hasAdrClasses();
        this.hasMatsNumber();
        this.hasCoilWellLength();
        this.hasBeltsNumber();

        // Order
        this.hasDriverLanguages();
        this.hasSentNotificationNumber();

        const fields = this.validation()?.fields;
        setTimeout(() => {
          ValidationHelper.toggleControls(
            controls.adrClasses,
            this.hasAdrClasses(),
          );
          ValidationHelper.updateControl(
            controls.hasHaccp,
            fields?.['hasHaccp'] ?? FORBIDDEN_FIELD,
          );
          ValidationHelper.updateControl(
            controls.hasHdsCrane,
            fields?.['hasHdsCrane'] ?? FORBIDDEN_FIELD,
          );
          ValidationHelper.updateControl(
            controls.hooksNumber,
            fields?.['hooksNumber'] ?? FORBIDDEN_FIELD,
          );
          ValidationHelper.updateControl(
            controls.hasCustomsSecuringRope,
            fields?.['hasCustomsSecuringRope'] ?? FORBIDDEN_FIELD,
          );
          ValidationHelper.toggleControls(
            controls.matsNumber,
            this.hasMatsNumber(),
          );
          ValidationHelper.toggleControls(
            controls.coilWellLength,
            this.hasCoilWellLength(),
          );
          ValidationHelper.updateControl(
            controls.hasLoadingsRamps,
            fields?.['hasLoadingsRamps'] ?? FORBIDDEN_FIELD,
          );
          ValidationHelper.toggleControls(
            controls.beltsNumber,
            this.hasBeltsNumber(),
          );
          ValidationHelper.updateControl(
            controls.hasSanitaryInspection,
            fields?.['hasSanitaryInspection'] ?? FORBIDDEN_FIELD,
          );
          ValidationHelper.updateControl(
            controls.temperatureRange,
            fields?.['temperatureRange'] ?? FORBIDDEN_FIELD,
          );
          ValidationHelper.updateControl(
            controls.hasTailLift,
            fields?.['hasTailLift'] ?? FORBIDDEN_FIELD,
          );
          ValidationHelper.updateControl(
            controls.hasDumpContainer,
            fields?.['hasDumpContainer'] ?? FORBIDDEN_FIELD,
          );
          ValidationHelper.updateControl(
            controls.hasForklift,
            fields?.['hasForklift'] ?? FORBIDDEN_FIELD,
          );

          // Fleet
          if (controls.isLowDeck) {
            ValidationHelper.updateControl(
              controls.isLowDeck,
              fields?.['isLowDeck'] ?? FORBIDDEN_FIELD,
            );
            ValidationHelper.toggleControls(
              controls.isLowDeck,
              !this.hasAssignedFleet(),
              false,
            );
          }
          if (controls.semiTrailerSize) {
            ValidationHelper.updateControl(
              controls.semiTrailerSize,
              fields?.['semiTrailerSize'] ?? FORBIDDEN_FIELD,
            );
            ValidationHelper.toggleControls(
              controls.semiTrailerSize,
              !this.hasAssignedFleet(),
              false,
            );
          }
          if (controls.trailerSize) {
            ValidationHelper.updateControl(
              controls.trailerSize,
              fields?.['trailerSize'] ?? FORBIDDEN_FIELD,
            );
            ValidationHelper.toggleControls(
              controls.trailerSize,
              !this.hasAssignedFleet(),
              false,
            );
          }

          // Order
          if (controls.driverLanguages) {
            ValidationHelper.toggleControls(
              controls.driverLanguages,
              this.hasDriverLanguages(),
            );
          }
          if (controls.sentNotificationNumber) {
            ValidationHelper.toggleControls(
              controls.sentNotificationNumber,
              this.hasSentNotificationNumber(),
            );
          }

          this.changeDetectorRef.detectChanges();
        });
      },
      { injector: this.injector },
    );
  }

  public reset(): void {
    const controls = this.form().controls;
    this.hasAdrClasses.set(!!controls.adrClasses.value?.length);
    this.hasDriverLanguages.set(!!controls.driverLanguages?.value?.length);
    this.hasMatsNumber.set(!!controls.matsNumber.value);
    this.hasCoilWellLength.set(!!controls.coilWellLength.value);
    this.hasSentNotificationNumber.set(
      !!controls.sentNotificationNumber?.value,
    );
    this.hasBeltsNumber.set(!!controls.beltsNumber.value);
  }

  protected addOtherParameter(name?: string): void {
    this.modalService.open<DictionariesVehicleParameterModalData>(
      DictionariesVehicleParameterModalComponent,
      { mode: OnyxFormMode.ADD, name },
    );
  }

  public static buildForm<T>(type: T, fb: NonNullableFormBuilder) {
    return fb.group({
      adrClasses: fb.control<string[] | null>(null, [Validators.required]),
      hasHaccp: fb.control<boolean | null>(false),
      hasHdsCrane: fb.control<boolean | null>(false),
      hooksNumber: fb.control<number | null>(null, [
        Validators.required,
        onyxMinNumberValidator(1),
      ]),
      hasCustomsSecuringRope: fb.control<boolean | null>(false),
      matsNumber: fb.control<number | null>(null, [
        Validators.required,
        onyxMinNumberValidator(1),
      ]),
      coilWellLength: fb.control<number | null>(null, [
        Validators.required,
        onyxMinNumberValidator(Number.EPSILON, 2),
      ]),
      hasLoadingsRamps: fb.control<boolean | null>(false),
      beltsNumber: fb.control<number | null>(null, [
        Validators.required,
        onyxMinNumberValidator(1),
      ]),
      hasSanitaryInspection: fb.control<boolean | null>(false),
      temperatureRange: FormHelper.buildNumberRangeForm(fb),
      hasTailLift: fb.control<boolean | null>(false),
      hasDumpContainer: fb.control<boolean | null>(false),
      hasForklift: fb.control<boolean | null>(false),
      other: fb.control<string[] | null>(null),

      ...(type === 'fleet' && {
        isLowDeck: fb.control<boolean | null>(false),
        semiTrailerSize: fb.control<SemiTrailerSize | null>(null),
        trailerSize: fb.control<TrailerSize | null>(null),
      }),

      ...(type === 'order' && {
        hasSafeParking: fb.control(false, [Validators.required]),
        isDedicated: fb.control(true, [Validators.required]),
        hasEcmr: fb.control(false, [Validators.required]),
        isExpress: fb.control(false, [Validators.required]),
        driverLanguages: fb.control<string[] | null>(null),
        isExclusive: fb.control(false, [Validators.required]),
        sentNotificationNumber: fb.control<string | null>(null, [
          Validators.required,
        ]),
        hasDoubleCrew: fb.control(false, [Validators.required]),
        hasDoubleDeck: fb.control(false, [Validators.required]),
        hasPalletExchange: fb.control(false, [Validators.required]),
      }),
    });
  }
}
