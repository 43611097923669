import { UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxIconComponent,
  OnyxLanguagePipe,
  OnyxPhonePipe,
} from '@onyx/angular';
import { ParametersCellComponent } from '../../../../../../../common/components/cells/parameters-cell/parameters-cell.component';
import { DictionaryCode } from '../../../../../../../common/enums/dictionary-code';
import { JoinPipe } from '../../../../../../../common/pipes/join.pipe';
import { OrderPointCategory } from '../../../../../common/enums/order-point-category';
import { Order } from '../../../../../common/interfaces/order';
import { OrderLoadingPoint } from '../../../../../common/interfaces/order-point';

@Component({
  selector: 'app-order-pdf-template-details',
  imports: [
    OnyxIconComponent,
    TranslatePipe,
    UpperCasePipe,
    ParametersCellComponent,
    JoinPipe,
    OnyxPhonePipe,
    OnyxLanguagePipe,
  ],
  templateUrl: './order-pdf-template-details.component.html',
  styleUrl: './order-pdf-template-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPdfTemplateDetailsComponent {
  protected readonly I18N = 'orders.pdfTemplate';

  protected readonly DictionaryCode = DictionaryCode;

  public order = input.required<Order>();

  protected loadingPoints = computed<OrderLoadingPoint[]>(() =>
    this.order().points.filter(
      (point) => point.category === OrderPointCategory.LOADING,
    ),
  );
  protected loadingGoods = computed(() =>
    this.loadingPoints().flatMap((item) => item.goods),
  );
}
