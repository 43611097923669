import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  DropdownHelper,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxDropdownOptionsSource,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxMessageComponent,
  OnyxModalComponent,
  OnyxTab,
  OnyxTabsComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { chain } from 'lodash';
import { concatMap, map, shareReplay, Subject } from 'rxjs';
import {
  ValidationConditionsHelper,
  ValidationContext,
} from '../../../../../common/helpers/validation-conditions.helper';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { FleetIdentifierPipe } from '../../../../../common/pipes/fleet-identifier.pipe';
import { FleetCategory } from '../../enums/fleet-category';
import { FleetState } from '../../enums/fleet-state';
import { FleetValidationHelper } from '../../helpers/fleet-validation.helper';
import { FleetHelper } from '../../helpers/fleet.helper';
import { Fleet } from '../../interfaces/fleet';
import { FleetService } from '../../services/fleet.service';

export type FleetSetsModalData = Pick<
  Fleet,
  'uuid' | 'generalInformation' | 'vehicle' | 'trailer'
>;

interface WarningMessage {
  type: 'unassign' | 'reassign';
  vehicle: string;
  trailer: string;
}

enum FleetSetsModalSection {
  CHANGE,
  SWAP,
}

@Component({
  selector: 'app-fleet-sets-modal',
  imports: [
    OnyxModalComponent,
    OnyxTabsComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    TranslatePipe,
    OnyxButtonComponent,
    OnyxMessageComponent,
    OnyxIconComponent,
    OnyxTextFieldComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    FleetIdentifierPipe,
  ],
  templateUrl: './fleet-sets-modal.component.html',
  styleUrl: './fleet-sets-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetSetsModalComponent {
  protected readonly I18N = 'fleet.fleetSetsModal';
  protected readonly TABS: OnyxTab<FleetSetsModalSection>[] = [
    { name: `${this.I18N}.changeTrailer`, value: FleetSetsModalSection.CHANGE },
    { name: `${this.I18N}.changeSets`, value: FleetSetsModalSection.SWAP },
  ];
  protected readonly VEHICLES_SOURCE: OnyxDropdownOptionsSource<Fleet> = {
    list: (query, limit) =>
      this.fleetService
        .searchFleet(query, limit, {
          category:
            this.fleet.generalInformation.category ===
            FleetCategory.SEMI_TRAILER
              ? [FleetCategory.SEMI_TRUCK]
              : [FleetCategory.STRAIGHT_TRUCK, FleetCategory.VAN],
          state: FleetState.ACTIVE,
        })
        .pipe(
          map((result) => {
            const trailer = this.trailer();
            if (!trailer) return result;

            return {
              ...result,
              options: chain(DropdownHelper.mapToOptions(result.options)!)
                .map(
                  (option) =>
                    this.fleetValidationHelper.validateSets({
                      context: ValidationContext.SET,
                      primary: option.value,
                      options: {
                        secondary: trailer,
                        option,
                      },
                    }).option!,
                )
                .thru((options) =>
                  this.validationConditionsHelper.groupOptions(options),
                )
                .value(),
            };
          }),
        ),
    get: (uuid) =>
      this.fleetService.getFleet(uuid).pipe(
        map((vehicle) => ({
          name: this.fleetIdentifierPipe.transform(vehicle),
          value: vehicle,
        })),
      ),
    idKey: 'uuid',
  };
  protected readonly TRAILERS_SOURCE: OnyxDropdownOptionsSource<Fleet> = {
    list: (query, limit) =>
      this.fleetService
        .searchFleet(query, limit, {
          category: [
            this.fleet.generalInformation.category === FleetCategory.SEMI_TRUCK
              ? FleetCategory.SEMI_TRAILER
              : FleetCategory.TRAILER,
          ],
          state: FleetState.ACTIVE,
        })
        .pipe(
          map((result) => {
            const vehicle = this.vehicle();
            if (!vehicle) return result;

            return {
              ...result,
              options: chain(DropdownHelper.mapToOptions(result.options)!)
                .map(
                  (option) =>
                    this.fleetValidationHelper.validateSets({
                      context: ValidationContext.SET,
                      primary: vehicle,
                      options: {
                        warningsFocus: 'trailer',
                        secondary: option.value,
                        option,
                      },
                    }).option!,
                )
                .thru((options) =>
                  this.validationConditionsHelper.groupOptions(options),
                )
                .value(),
            };
          }),
        ),
    get: (uuid) =>
      this.fleetService.getFleet(uuid).pipe(
        map((trailer) => ({
          name: this.fleetIdentifierPipe.transform(trailer),
          value: trailer,
        })),
      ),
    idKey: 'uuid',
  };
  protected readonly SETS_SOURCE: OnyxDropdownOptionsSource<Fleet> = {
    list: (query, limit) =>
      this.fleetService
        .searchFleet(query, limit, {
          category: [],
          state: FleetState.ACTIVE,
          showSetsOnly: true,
        })
        .pipe(
          map((result) => ({
            ...result,
            options: DropdownHelper.updateGroupOptions(
              result.options,
              (options) =>
                options.filter(
                  ({ value }) =>
                    value.uuid !== this.changeForm.getRawValue()?.vehicle,
                ),
            ),
          })),
          map((result) => {
            const vehicle = this.vehicle();
            if (!vehicle) return result;

            return {
              ...result,
              options: chain(DropdownHelper.mapToOptions(result.options)!)
                .filter(({ value }) => {
                  const category = value.trailer!.generalInformation.category;
                  return vehicle.generalInformation.category ===
                    FleetCategory.SEMI_TRUCK
                    ? category === FleetCategory.SEMI_TRAILER
                    : category === FleetCategory.TRAILER;
                })
                .map(
                  (option) =>
                    this.fleetValidationHelper.validateSets({
                      context: ValidationContext.SET,
                      primary: vehicle,
                      options: {
                        warningsFocus: 'trailer',
                        secondary: option.value.trailer!,
                        option,
                      },
                    }).option!,
                )
                .thru((options) =>
                  this.validationConditionsHelper.groupOptions(options),
                )
                .value(),
            };
          }),
        ),
    get: (uuid) =>
      this.fleetService.getFleet(uuid).pipe(
        map((fleet) => ({
          name: `${this.fleetIdentifierPipe.transform(
            fleet,
            'bracket',
          )} · ${this.fleetIdentifierPipe.transform(
            fleet.trailer!,
            'bracket',
          )}`,
          value: fleet,
        })),
      ),
    idKey: 'uuid',
  };

  protected readonly isVehicle = FleetHelper.isVehicle(this.fleet);
  protected readonly initialVehicle = this.isVehicle
    ? this.fleet.uuid
    : (this.fleet.vehicle?.uuid ?? null);
  protected readonly initialTrailer = this.isVehicle
    ? (this.fleet.trailer?.uuid ?? null)
    : this.fleet.uuid;

  protected readonly FleetSetsModalSection = FleetSetsModalSection;

  protected changeForm = this.buildChangeForm();
  protected changeFormChanges = toSignal(
    this.changeForm.valueChanges.pipe(
      map(() => this.changeForm.getRawValue()),
      shareReplay(1),
    ),
    { initialValue: this.changeForm.getRawValue() },
  );
  protected changeFormChanged = computed(() => {
    const { vehicle, trailer } = this.changeFormChanges();
    if (vehicle !== this.initialVehicle) return true;
    if (trailer !== this.initialTrailer) return true;
    return false;
  });
  protected vehicle = signal<Fleet | null>(null);
  protected trailer = signal<Fleet | null>(null);
  protected trailerLabel = computed(() =>
    FleetHelper.getTrailerLabel(this.vehicle()),
  );

  protected swapForm = this.buildSwapForm();
  protected firstSet = signal<Fleet | null>(null);
  protected secondSet = signal<Fleet | null>(null);

  protected mode = signal(FleetSetsModalSection.CHANGE);
  protected warningMessage = signal<WarningMessage | null>(null);
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected fleet: FleetSetsModalData,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
    private fleetHelper: FleetHelper,
    private fleetValidationHelper: FleetValidationHelper,
    private validationConditionsHelper: ValidationConditionsHelper,
  ) {
    effect(() => {
      if (!this.changeFormChanged()) {
        this.warningMessage.set(null);
        return;
      }

      const vehicle = this.vehicle();
      const trailer = this.trailer();

      if (!trailer || !vehicle) {
        this.warningMessage.set({
          type: 'unassign',
          vehicle: this.fleetIdentifierPipe.transform(
            vehicle ?? trailer?.vehicle,
          ),
          trailer: this.fleetIdentifierPipe.transform(
            trailer ?? vehicle?.trailer,
          ),
        });
      } else if (
        vehicle &&
        trailer?.vehicle &&
        vehicle.uuid !== trailer.vehicle.uuid
      ) {
        this.warningMessage.set({
          type: 'reassign',
          trailer: this.fleetIdentifierPipe.transform(trailer),
          vehicle: this.fleetIdentifierPipe.transform(trailer.vehicle),
        });
      } else {
        this.warningMessage.set(null);
      }
    });
  }

  protected openFleetModal(fleet: Fleet | null): void {
    if (fleet) this.fleetHelper.openModal(fleet);
  }

  protected submitChangeForm(): void {
    if (!this.changeFormChanged()) return;
    if (!ValidationHelper.checkValidity(this.changeForm, this.toastService)) {
      return;
    }

    const form = this.changeForm.getRawValue();
    this.loading.set(true);

    let action$;
    if (form.vehicle) {
      action$ = this.fleetService.assignTrailer(
        form.vehicle,
        form.trailer ?? null,
      );
    } else {
      const previousVehicle = this.fleet.vehicle?.uuid;
      if (!previousVehicle) return this.close$.next();

      action$ = this.fleetService.assignTrailer(previousVehicle, null);
    }

    action$
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.changeSucced`);
          this.close$.next();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected submitSwapForm(): void {
    if (!ValidationHelper.checkValidity(this.swapForm, this.toastService)) {
      return;
    }

    const form = this.swapForm.getRawValue();

    this.loading.set(true);
    this.fleetService
      .assignTrailer(form.firstSet, this.secondSet()!.trailer!.uuid, false)
      .pipe(
        concatMap(() =>
          this.fleetService.assignTrailer(
            this.secondSet()!.uuid,
            this.firstSet()!.trailer!.uuid,
          ),
        ),
      )
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.swapSucceed`);
          this.close$.next();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildChangeForm() {
    return this.fb.group({
      vehicle: this.fb.control<string | null>({
        value: this.initialVehicle,
        disabled: this.isVehicle,
      }),
      trailer: this.fb.control<string | null>({
        value: this.initialTrailer,
        disabled: !this.isVehicle,
      }),
    });
  }

  private buildSwapForm() {
    return this.fb.group({
      firstSet: this.fb.control<string>(
        { value: this.fleet.uuid, disabled: true },
        [Validators.required],
      ),
      secondSet: this.fb.control<string | null>(null, [Validators.required]),
    });
  }
}
