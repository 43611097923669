import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { AuthService } from '../../../../../auth/common/services/auth.service';
import { ApiService } from '../../../../../common/services/api.service';
import { EngineConfig } from '../interfaces/engine-config';

@Injectable({
  providedIn: 'root',
})
export class EngineConfigService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private authService: AuthService,
  ) {
    super(http);
  }

  public getGlobalEngineConfig(): Observable<EngineConfig> {
    return this.get<EngineConfig>('/engine-config/global');
  }

  public updateGlobalEngineConfig(
    config: Omit<EngineConfig, 'group'>,
  ): Observable<void> {
    return this.put<EngineConfig>('/engine-config/global', config).pipe(
      tap((engineConfig) => this.authService.updateEngineConfig(engineConfig)),
      map(() => undefined),
    );
  }
}
