import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnyxOptional, OnyxTimePipe } from '@onyx/angular';
import { isEqual } from 'lodash';
import { TIME_RANGE_ALL_DAY } from '../constants/common/time-range-all-day';
import { BusinessHours } from '../interfaces/common/business-hours';

@Pipe({
  name: 'businessHours',
  pure: true,
})
export class BusinessHoursPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private timePipe: OnyxTimePipe,
  ) {}

  public transform(
    businessHours: OnyxOptional<BusinessHours>,
    key?: keyof BusinessHours,
    format?: 'short' | 'long',
    only?: 'label' | 'value',
  ): string {
    if (!businessHours) return '';

    format ??= 'short';

    const formatRange = (key: keyof BusinessHours): string => {
      const label = this.translateService.instant(
        `labels.${key}${format === 'short' ? 'Shortcut' : ''}`,
      );
      if (only === 'label') return label;

      const value = businessHours[key]
        ? this.timePipe.transform(businessHours[key])
        : format === 'short'
          ? '-'
          : this.translateService.instant('labels.closed');
      if (only === 'value') return value;

      return `${label}: ${value}`;
    };

    if (key) return formatRange(key);

    if (
      Object.values(businessHours).every((openingHours) =>
        isEqual(openingHours, TIME_RANGE_ALL_DAY),
      )
    ) {
      return '24/7';
    }

    const weekday = formatRange('weekday');
    const saturday = formatRange('saturday');
    const sunday = formatRange('sunday');

    return `${weekday}, ${saturday}, ${sunday}`;
  }
}
