<section class="contractors">
  <div class="heading">
    <h4>{{`${I18N}.heading` | translate}} {{ order().identifier }}</h4>

    <p class="f-regular-5 f-gray">
      {{ 'labels.issued' | translate }} {{ today | onyxDate: 'date-dot-time' }}
    </p>
  </div>

  <div class="container">
    <div class="item">
      <p class="f-medium-5 f-gray">
        {{ 'labels.client' | translate | uppercase }}
      </p>

      <div class="details">
        <p class="f-medium-3">{{ business.companyName }}</p>

        <p class="f-regular-4">
          {{ 'labels.vatId' | translate }}: {{ business.country | uppercase
          }}{{ business.vatId }}
        </p>

        <app-address
          class="f-regular-4 f-gray"
          [address]="business!.mainAddress"
          format="pdf"
        ></app-address>
      </div>
    </div>

    <div class="item">
      <p class="f-medium-5 f-gray">
        {{ 'labels.contractor' | translate | uppercase }}
      </p>

      @let outsourcingData = this.order().outsourcingData;
      @if (contractor$() | async; as contractor) {
        <div class="details">
          @let companyProfile = contractor.companyProfile;
          <p class="f-medium-3">{{ companyProfile.companyName }}</p>

          <p class="f-regular-4">
            {{ 'labels.vatId' | translate }}:
            {{ companyProfile.country | uppercase }}{{ companyProfile.vatId }}
          </p>

          <app-address
            class="f-regular-4 f-gray"
            [address]="contractor.companyData!.mainAddress"
            format="pdf"
          ></app-address>
        </div>
      }

      @if (outsourcingData?.contactPerson; as contactPerson) {
        <div class="details">
          <p class="f-medium-3">
            {{ contactPerson | onyxName }}
          </p>

          <p class="f-regular-4">
            {{ contactPerson.phone | onyxPhone }}
          </p>

          <!-- HOLD: Wait for BE implementation -->
          <!-- <p class="f-regular-4 f-gray">
            {{ contactPerson.email }}
          </p> -->
        </div>
      }
    </div>
  </div>
</section>
