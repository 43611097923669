@if (label() === true) {
  <div class="label-placeholder"></div>
} @else if (label()) {
  <onyx-input-label
    [label]="$any(label())"
    [hint]="hint()"
    [optional]="isOptional() && showOptional()"
    [disabled]="disabled()"
    (click)="focus()"
  ></onyx-input-label>
}

<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
@let isDisabled = disabled() || (value().indefinite && showIndefinite());
<div
  class="datepicker-input"
  [ngClass]="{
    active: value().year,
    disabled: isDisabled,
    invalid: isInvalid() || forceError(),
  }"
  (click)="isDisabled ? null : inputClick.emit($event)"
  (focusout)="inputFocusout.emit($event)"
  #inputElement
>
  <div class="input-date">
    <input
      class="day"
      type="text"
      placeholder="DD"
      [disabled]="isDisabled"
      (keypress)="validateInput($event)"
      (input)="changeDay($event)"
      (paste)="pasteDate($event)"
    />

    <span class="input-date-separator">/</span>

    <input
      class="month"
      [ngClass]="{ value: value().month }"
      placeholder="MM"
      [disabled]="isDisabled"
      [tabIndex]="value().day ? 0 : -1"
      (keypress)="validateInput($event)"
      (input)="changeMonth($event)"
      (paste)="pasteDate($event)"
      (focus)="focus(value().day, 0)"
      (keydown.backspace)="previousInput($event)"
    />

    <span class="input-date-separator">/</span>

    <input
      class="year"
      placeholder="RRRR"
      [maxLength]="4"
      [disabled]="isDisabled"
      [tabIndex]="value().month ? 0 : -1"
      (keypress)="validateInput($event)"
      (input)="changeYear($event)"
      (paste)="pasteDate($event)"
      (focus)="focus(value().month, 2)"
      (keydown.backspace)="previousInput($event)"
    />
  </div>

  <div class="actions">
    <div class="hidden">
      @if (value().day && !isDisabled && showClear()) {
        <onyx-clear-button
          (click)="clearValue(); $event.stopPropagation()"
        ></onyx-clear-button>
      }
    </div>
  </div>

  <onyx-icon class="calendar-button" name="calendar" [size]="16"></onyx-icon>
</div>

@if (showIndefinite()) {
  <onyx-checkbox
    [label]="
      (indefiniteLabel() ? indefiniteLabel()! : I18N + '.indefinite')
        | translate
    "
    [value]="!!value().indefinite"
    (valueChange)="changeIndefinite($event)"
    [showOptional]="false"
    [showErrors]="false"
  ></onyx-checkbox>
}
