<onyx-data-widget
  supheading="labels.kilometers"
  [headingIcon]="{ name: 'road', size: 16, color: 'violet' }"
  [color]="isEmptyDistanceError() ? 'red' : 'gray'"
>
  <div heading class="heading">
    <span
      [innerHTML]="totalDistance() | number: '1.2-2' | numberDecoration: 'km'"
    ></span>

    @let result = realizationResult();
    @if (result && result.distance !== 0) {
      <span class="result {{ result.color }} f-semibold-2">
        {{ result.sign }}{{ result.distance | number: '1.0-2' }} km
      </span>
    }
  </div>

  <div class="container">
    <div class="list">
      @let remainingDistance = order().realizationData?.remainingDistance;
      @if (remainingDistance && !realizationResult) {
        <div class="item f-medium-3">
          <p>{{ 'labels.remaining' | translate }}</p>

          <app-unit-cell
            [value]="remainingDistance"
            [decimalPlaces]="3"
            unit="km"
          >
          </app-unit-cell>
        </div>
      }

      <div class="item f-medium-3">
        <p>{{ 'loaded' | i18n: I18N | translate }}</p>

        {{ distance() | onyxDistance }}
      </div>

      @if (emptyDistance(); as empty) {
        <div
          class="item f-medium-3"
          [ngClass]="{ error: isEmptyDistanceError() }"
        >
          <p>{{ 'labels.empty' | translate }}</p>

          <app-unit-cell
            [value]="empty"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>

          @if (isEmptyDistanceError()) {
            <onyx-icon
              name="error-circle-empty"
              [size]="14"
              onyxTooltip="labels.emptyDistanceExceeded"
              onyxTooltipColor="white"
            ></onyx-icon>
          }
        </div>
      }
    </div>

    @if (plannedData(); as plannedData) {
      <div class="plan" [onyxTooltip]="planTooltip" onyxTooltipColor="white">
        <span>{{ 'labels.plan' | translate }}</span>

        <div class="plan-list f-medium-3">
          <app-unit-cell
            [value]="plannedData.totalDistance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>

          <span>·</span>

          <app-unit-cell
            [value]="plannedData.distance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>

          <span>·</span>

          <app-unit-cell
            [value]="plannedData.emptyDistance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>
        </div>
      </div>
    }
  </div>
</onyx-data-widget>

<ng-template #planTooltip>
  @if (plannedData(); as plannedData) {
    <div class="tooltip f-medium-3">
      <p>{{ 'labels.plan' | translate }}</p>

      <div class="tooltip-data">
        <div class="item">
          <p>{{ 'totalKilometers' | i18n: I18N | translate }}</p>

          <app-unit-cell
            [value]="plannedData.totalDistance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>
        </div>

        <div class="item">
          <p>{{ 'loaded' | i18n: I18N | translate }}</p>

          <app-unit-cell
            [value]="plannedData.distance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>
        </div>

        <div class="item">
          <p>{{ 'labels.empty' | translate }}</p>

          <app-unit-cell
            [value]="plannedData.emptyDistance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>
        </div>
      </div>
    </div>
  }
</ng-template>
