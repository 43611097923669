import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxOverlayPosition,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-modal-documents-buttons',
  imports: [OnyxIconComponent, OnyxIconButtonComponent, OnyxTooltipDirective],
  templateUrl: './modal-documents-buttons.component.html',
  styleUrl: './modal-documents-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalDocumentsButtonsComponent {
  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;

  public scans = input.required<string[] | null>();

  constructor(private storageService: StorageService) {}

  protected showFiles(): void {
    const scans = this.scans();
    if (!scans) return;

    for (const scan of scans) {
      this.storageService.previewFile(scan).subscribe();
    }
  }

  protected downloadFiles(): void {
    const scans = this.scans();
    if (!scans) return;

    for (const scan of scans) {
      this.storageService.downloadFile(scan).subscribe();
    }
  }
}
