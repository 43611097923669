@let standardRealization = hasApprovedVehicle() && !order().outsourcingData;
<div class="statuses" [class.outsourcing-realization]="!standardRealization">
  <app-order-modal-status
    [order]="order()"
    [color]="pointEstimationDifference()?.color"
    [point]="point()"
  ></app-order-modal-status>

  @let status = order().status.value;
  @if (!isOrderFinished()) {
    @if (!standardRealization) {
      <app-order-modal-loading-deadline
        [order]="order()"
      ></app-order-modal-loading-deadline>
    }

    @if (standardRealization) {
      <app-order-modal-eta
        [pointDeadline]="pointDeadline()"
        [pointEstimationDifference]="pointEstimationDifference()!"
        [status]="status"
      ></app-order-modal-eta>
    }

    @if (hasApprovedVehicle()) {
      <app-order-modal-point-status
        [point]="point()"
      ></app-order-modal-point-status>
    }

    @if (standardRealization) {
      <app-order-modal-point-time-windows
        [point]="point()"
      ></app-order-modal-point-time-windows>
    }
  } @else {
    <app-order-modal-finished-section-main-widget
      [order]="order()"
    ></app-order-modal-finished-section-main-widget>

    @if (
      [OrderStatus.WAITING_FOR_PAYMENT, OrderStatus.PARTIALLY_PAID].includes(
        status
      )
    ) {
      <onyx-widget-box heading="labels.invoice">
        <p>{{ this.order().payments.invoice?.number }}</p>
      </onyx-widget-box>

      <onyx-widget-box heading="labels.paymentMethod">
        <p>{{ this.order().payments.paymenthMethod }}</p>
      </onyx-widget-box>
    }

    @if (status === OrderStatus.PAID) {
      @if (finishedOrderData(); as finishedData) {
        <onyx-widget-box [heading]="I18N + '.finishedOrder'">
          <div class="finished-order">
            <p>{{ finishedData.date | onyxDate: 'date' }}</p>

            <span>·</span>

            <p>{{ finishedData.time | onyxTime }}</p>
          </div>
        </onyx-widget-box>
      }

      <onyx-widget-box heading="labels.paymentTerm">
        <p>
          {{ this.order().payments.invoice!.paymentTerm | onyxDate: 'date' }}
        </p>
      </onyx-widget-box>
    }
  }
</div>
