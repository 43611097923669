<div class="unavailabilities">
  <div class="options">
    <onyx-button
      bottomLeftOptions
      color="black"
      type="outlined"
      size="s"
      [disabled]="!enabled()"
      (click)="enabled() ? addUnavailability() : null"
    >
      <onyx-icon left class="plus-icon" name="zoom-in" [size]="16"></onyx-icon>

      {{
        (this.type() === UnavailabilityType.EMPLOYEE
          ? I18N + '.addUnavailability'
          : 'labels.add'
        ) | translate
      }}
    </onyx-button>

    <onyx-toggle
      [label]="
        this.type() === UnavailabilityType.EMPLOYEE
          ? I18N + '.showPastUnavailabilities'
          : 'drivers.driverModal.showPast'
      "
      [(value)]="showPastUnavailabilities"
    ></onyx-toggle>
  </div>

  <onyx-table
    [data]="unavailabilities()"
    [columns]="COLUMNS"
    [loading]="loading()"
    tableSize="s"
    [rowSize]="48"
    [notFound]="UNAVAILABILITIES_NOT_FOUND"
    [(pagination)]="pagination"
    (rowClick)="editUnavailability($event.item)"
  ></onyx-table>
</div>
