<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="address" (click)="focus()">
  @if (label(); as label) {
    <onyx-input-label
      [label]="label"
      [hint]="hint()"
      [disabled]="disabled()"
      [optional]="isOptional() && showOptional()"
    ></onyx-input-label>
  }

  <div
    class="input"
    [ngClass]="{
      active: value(),
      disabled: disabled(),
      invalid: isInvalid(),
    }"
    [onyxDropdownOptions]="addresses$"
    [onyxDropdownValues]="[value()]"
    [onyxDropdownDisabled]="disabled()"
    [(onyxDropdownAttached)]="expanded"
    [(onyxDropdownQuery)]="query"
    [onyxDropdownNotFoundMessage]="notFoundMessage()"
    [onyxDropdownShowAddOption]="showAddOption()"
    [onyxDropdownAddOptionMessage]="addOptionMessage()"
    [onyxDropdownSubheadingTemplateRef]="subheadingTemplate"
    [onyxDropdownOptionTemplateRef]="optionTemplate"
    onyxDropdownWidth="inherit"
    (onyxDropdownValueChange)="changeValue($event)"
    (onyxDropdownAttachedChange)="$event ? null : restoreOnFocusout()"
    (onyxDropdownAddOption)="addOption.emit($event)"
    (onyxDropdownFocus)="focus()"
  >
    <input
      [value]="inputValue()"
      [placeholder]="placeholder() | translate"
      [disabled]="disabled()"
      (input)="changeQuery($event)"
      #inputElement
    />

    <div class="actions">
      <div class="hidden">
        @if (value() && !disabled()) {
          <onyx-clear-button
            (click)="clearValue(); $event.stopPropagation()"
          ></onyx-clear-button>
        }
      </div>

      @if (showPicker() && types().includes(OnyxAddressType.CUSTOM)) {
        <onyx-icon-button
          type="transparent"
          color="black"
          size="s"
          [disabled]="disabled()"
          [tabindex]="-1"
          (click)="openPicker(); $event.stopPropagation()"
        >
          <onyx-icon name="location" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }
    </div>
  </div>

  @if (formControl()) {
    <onyx-form-control-error
      [controls]="[formControl()!]"
    ></onyx-form-control-error>
  }
</div>

@if (showApartment()) {
  <onyx-text-field
    [label]="I18N + '.apartment'"
    size="m"
    [width]="apartmentWidth()"
    [(value)]="apartmentNumber"
    [forceOptional]="true"
    [disabled]="disabled()"
    [showErrors]="false"
  ></onyx-text-field>
}

<ng-template #subheadingTemplate>
  <p class="header f-label-1">
    {{ I18N + (query() ? '.suggestions' : '.lastSearches') | translate }}
  </p>
</ng-template>

<ng-template let-option="option" let-query="query" #optionTemplate>
  <div class="option">
    @switch (option.value.type) {
      @case (OnyxAddressType.POINT_OF_INTEREST) {
        <onyx-icon
          class="point-of-interest"
          name="menu-poi"
          [size]="16"
        ></onyx-icon>
      }
      @default {
        <onyx-icon name="location" [size]="16"></onyx-icon>
      }
    }

    @let poiName = option.value.poiName;
    @let labelName = poiName ?? option.value.name;
    @let label = (labelName ? labelName + ', ' : '') + option.name;
    <p
      [onyxTooltip]="label"
      [onyxTooltipEnabled]="
        OverflowHelper.enableOverflowTooltip.bind(this, labelElement)
      "
      #labelElement
    >
      @if (labelName) {
        <span
          [ngClass]="{ 'f-medium-2': poiName }"
          [innerHTML]="labelName | onyxHighlight: query"
        ></span
        >{{ poiName ? ' ' : ', ' }}
      }
      <span [innerHTML]="option.name | onyxHighlight: query"></span>
    </p>
  </div>
</ng-template>
