import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mapValues, round } from 'lodash';
import { map, Observable, shareReplay } from 'rxjs';
import { DataProvidersStorageKey } from '../enums/storage/data-providers-storage-key';
import { CompanyData } from '../interfaces/data-providers/company-data';
import { CountryGroups } from '../interfaces/data-providers/country-groups';
import { FuelPrices } from '../interfaces/data-providers/fuel-prices';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class DataProvidersService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private translateService: TranslateService,
    private cacheService: CacheService,
  ) {
    super(http);
  }

  public getCompanyData(
    country: string,
    vatId: string,
  ): Observable<CompanyData> {
    return this.get(`/data-providers/company/${country}/${vatId}`);
  }

  public getCountryGroups(): Observable<CountryGroups> {
    const language =
      this.translateService.currentLang ?? this.translateService.defaultLang;
    const cacheKey = `${DataProvidersStorageKey.COUNTRY_GROUPS}-${language}`;

    return this.cacheService.cacheRequest(
      cacheKey,
      this.get('/data-providers', {
        params: {
          type: 'countries',
          language,
        },
      }),
    );
  }

  public getFuelPrices(): Observable<FuelPrices> {
    return this.cacheService.cacheRequest(
      DataProvidersStorageKey.FUEL_PRICES,
      this.get<FuelPrices>('/data-providers', {
        params: { type: 'fuel-prices' },
      }).pipe(
        map((fuelPrices) => {
          const VAT_RATES = {
            at: 0.2,
            be: 0.21,
            dk: 0.25,
            fi: 0.24,
            fr: 0.2,
            de: 0.19,
            it: 0.22,
            nl: 0.21,
            pl: 0.23,
            pt: 0.23,
            sk: 0.2,
            es: 0.21,
            se: 0.25,
          } as const;

          return {
            ...fuelPrices,
            prices: mapValues(fuelPrices.prices, (currencies, countryCode) => {
              const vatRate =
                VAT_RATES[countryCode as keyof typeof VAT_RATES] ?? 0;

              return mapValues(currencies, (types) =>
                mapValues(types, (price) => ({
                  ...price,
                  price: round(price.price / (1 + vatRate), 2),
                })),
              );
            }),
          };
        }),
        shareReplay(1),
      ),
    );
  }
}
