import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  FormHelper,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxFormGroupComponent,
  OnyxOption,
  OnyxPhone,
  OnyxPhoneNumberInputComponent,
  onyxPhoneValidator,
  OnyxTabsComponent,
  OnyxTextareaComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { round } from 'lodash';
import { DateTime } from 'luxon';
import {
  debounceTime,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { Merge } from 'type-fest';
import { NoteFormComponent } from '../../../../../common/components/forms/note-form/note-form.component';
import { YES_NO_OPTIONS } from '../../../../../common/constants/common/yes-no-options';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { AmountService } from '../../../../../common/services/amount.service';
import { DictionariesService } from '../../../../../common/services/dictionaries.service';
import { PreferencesService } from '../../../../../common/services/preferences.service';
import { ContractorHelper } from '../../../../management-panel/contractors/common/helpers/contractor.helper';
import { Contractor } from '../../../../management-panel/contractors/common/interfaces/contractor';
import { ContractorContactPerson } from '../../../../management-panel/contractors/common/interfaces/contractor-contact-person';
import { ContractorsRoute } from '../../../../management-panel/contractors/contractors.routes';
import { OrderRateType } from '../../../common/enums/order-rate-type';
import { Order } from '../../../common/interfaces/order';
import { OrdersService } from '../../../common/services/orders.service';

type OrderModalOutsourcingFormGroup = ReturnType<
  OrderModalOutsourcingFormComponent['buildForm']
>;

export type OrderModalOutsourcingForm = ReturnType<
  OrderModalOutsourcingFormGroup['getRawValue']
>;

export type OrderModalOutsourcingFormDto = Merge<
  OrderModalOutsourcingForm,
  { secondaryDriver: OrderModalOutsourcingForm['secondaryDriver'] | null }
>;

@Component({
  selector: 'app-order-modal-outsourcing-form',
  imports: [
    OnyxDropdownComponent,
    OnyxTextFieldComponent,
    OnyxPhoneNumberInputComponent,
    OnyxTabsComponent,
    OnyxTextFieldComponent,
    OnyxButtonComponent,
    OnyxFormGroupComponent,
    ReactiveFormsModule,
    TranslatePipe,
    AsyncPipe,
    OnyxTextareaComponent,
    AsyncPipe,
  ],
  templateUrl: './order-modal-outsourcing-form.component.html',
  styleUrl: './order-modal-outsourcing-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalOutsourcingFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderModal.outsourcingForm';
  protected readonly CONTRACTORS_SOURCE =
    this.contractorHelper.getContractorsSource();
  protected readonly YES_NO_OPTIONS = YES_NO_OPTIONS;

  protected readonly DictionaryCode = DictionaryCode;

  protected readonly currencies$ = this.dictionariesService.getDictionary(
    DictionaryCode.CURRENCY,
  );
  protected readonly vatRates$ = this.dictionariesService.getDictionary(
    DictionaryCode.VAT_RATE,
  );

  public order = input.required<Order>();
  public close$ = input.required<Subject<void>>();

  protected orderPrice = computed(() => this.order().price);

  protected form = this.buildForm();
  protected loading = signal(false);
  protected hasDoubleCrew = signal(false);
  protected contractor$ = new ReplaySubject<Contractor | null>(1);
  protected contractorBranches$!: Observable<OnyxOption<string>[] | null>;
  protected contractorContactPersons$!: Observable<
    OnyxOption<ContractorContactPerson>[] | null
  >;
  protected contractorLink$ = this.contractor$.pipe(
    map((contractor) => {
      if (!contractor) return undefined;

      return {
        link: 'labels.preview',
        color: 'blue' as const,
        font: 'f-label-2' as const,
        callback: () => this.contractorHelper.openModal(contractor),
      };
    }),
  );

  protected currencyControl = this.form.controls.rate.controls.currency;
  protected currency = toSignal(this.currencyControl.valueChanges, {
    initialValue: this.currencyControl.getRawValue(),
  });

  constructor(
    private fb: NonNullableFormBuilder,
    private preferencesService: PreferencesService,
    private dictionariesService: DictionariesService,
    private toastService: OnyxToastService,
    private amountService: AmountService,
    private destroyRef: DestroyRef,
    private ordersService: OrdersService,
    private contractorHelper: ContractorHelper,
  ) {
    effect(() => {
      ValidationHelper.toggleControls(
        this.form.controls.secondaryDriver,
        this.hasDoubleCrew(),
      );
    });

    this.form.controls.rate.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((amount) => {
          if (!amount.value && !amount.currency) return of(null);
          if (amount.currency === this.orderPrice().currency) {
            return of(this.orderPrice().value);
          }

          return this.amountService
            .calculateAmount({
              date: DateTime.now().toISODate(),
              value: this.orderPrice().value,
              currency: this.orderPrice().currency,
              resultCurrency: amount.currency!,
            })
            .pipe(map((orderPrice) => orderPrice.resultCurrencyValue));
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((orderPrice) => {
        const controls = this.form.controls;
        const rateValue = controls.rate.getRawValue()?.value;
        if (!orderPrice || !rateValue) return;

        const marginRate = rateValue - orderPrice;
        const marginPercentage = round(
          (marginRate / orderPrice) * Math.pow(10, 2 * 2),
          2,
        );

        controls.marginPercentage.setValue(marginPercentage);
        controls.marginRate.patchValue({
          value: marginRate,
          currency: controls.rate.getRawValue().currency,
        });
      });
  }

  public ngOnInit(): void {
    const controls = this.form.controls;

    const contractorBranchControl = controls.contractorBranch;
    this.contractorBranches$ = this.contractor$.pipe(
      map((contractor) =>
        contractor
          ? [{ name: 'labels.mainBranch', value: contractor.uuid }]
          : null,
      ),
      tap((branches) => {
        ValidationHelper.toggleControls(
          contractorBranchControl,
          branches != null,
        );
        setTimeout(() =>
          contractorBranchControl.setValue(branches?.[0]?.value ?? null),
        );
      }),
    );

    this.contractorContactPersons$ = this.contractor$.pipe(
      map((contractor) =>
        contractor
          ? contractor.contactPersons.map((contactPerson) => ({
              name: `${contactPerson.firstName} ${contactPerson.lastName}`,
              value: contactPerson,
            }))
          : null,
      ),
      tap((contactPersons) => {
        ValidationHelper.toggleControls(
          controls.contactPerson,
          !!contactPersons?.length,
        );
        setTimeout(() =>
          controls.contactPerson.setValue(contactPersons?.[0]?.value ?? null),
        );
      }),
    );
  }

  protected addContractor(): void {
    ActionHelper.openLink(ContractorsRoute.ADD_CONTRACTOR);
  }

  protected editContractor(): void {
    const contractorUuid = this.form.controls.contractor.getRawValue();
    if (!contractorUuid) return;

    ActionHelper.openLink(
      ContractorsRoute.EDIT_CONTRACTOR.replace(':uuid', contractorUuid),
    );
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();
    const dto: OrderModalOutsourcingFormDto = {
      ...form,
      secondaryDriver: this.hasDoubleCrew() ? form.secondaryDriver : null,
    };

    this.loading.set(true);
    this.ordersService
      .addOutsourcing(this.order().uuid, dto)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            'orders.orderModal.toasts.outsourcingReady',
          );
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    const buildDriverForm = (options?: { disabled: boolean }) => {
      const group = this.fb.group({
        name: this.fb.control<string | null>(null, [Validators.required]),
        phone: this.fb.control<OnyxPhone | null>(null, [
          Validators.required,
          onyxPhoneValidator,
        ]),
      });
      if (options?.disabled) group.disable();
      return group;
    };

    return this.fb.group({
      contractor: this.fb.control<string | null>(null, [Validators.required]),
      contractorBranch: this.fb.control<string | null>(null, [
        Validators.required,
      ]),
      contactPerson: this.fb.control<ContractorContactPerson | null>(
        { value: null, disabled: true },
        [Validators.required],
      ),
      vehicleRegistrationNumber: this.fb.control<string | null>(null, [
        Validators.required,
      ]),
      trailerRegistrationNumber: this.fb.control<string | null>(null),
      primaryDriver: buildDriverForm(),
      secondaryDriver: buildDriverForm({ disabled: true }),
      rateType: this.fb.control<OrderRateType | null>(OrderRateType.FREIGHT, [
        Validators.required,
      ]),
      rate: FormHelper.buildAmountForm(this.fb, this.preferencesService, {
        minValue: 0,
      }),
      vatRate: this.fb.control<string | null>(null, [Validators.required]),
      marginPercentage: this.fb.control<number | null>(
        { value: null, disabled: true },
        [Validators.required],
      ),
      marginRate: FormHelper.buildAmountForm(this.fb, this.preferencesService, {
        disabled: true,
      }),
      executionTerms: this.fb.control<string | null>(null),
      note: NoteFormComponent.buildForm(this.fb),
    });
  }
}
