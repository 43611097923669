import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxColor } from '../../badges';

@Component({
  selector: 'onyx-widget-box',
  imports: [TranslatePipe],
  templateUrl: './onyx-widget-box.component.html',
  styleUrl: './onyx-widget-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxWidgetBoxComponent {
  public heading = input.required<string>();
  public color = input<OnyxColor>('gray');
  public hover = input(false);
}
