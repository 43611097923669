import { AsyncPipe, NgClass, NgStyle, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxIconBoxComponent,
  OnyxIconComponent,
  OnyxLanguagePipe,
  OnyxToggleComponent,
} from '@onyx/angular';
import { combineLatestWith, distinctUntilChanged, map } from 'rxjs';
import { validate as validateUuid } from 'uuid';
import { OrderFormGood } from '../../../../common/interfaces/order-form-good';
import { OrderFormService } from '../../../../common/services/order-form.service';
import {
  OrderUnloadingPointGoodFormComponent,
  OrderUnloadingPointGoodFormGroup,
} from './order-unloading-point-good-form/order-unloading-point-good-form.component';

@Component({
  selector: 'app-order-unloading-point-form-good',
  imports: [
    TranslatePipe,
    OnyxIconBoxComponent,
    OnyxIconComponent,
    NgStyle,
    OnyxToggleComponent,
    NgClass,
    UpperCasePipe,
    OrderUnloadingPointGoodFormComponent,
    AsyncPipe,
  ],
  templateUrl: './order-unloading-point-form-good.component.html',
  styleUrl: './order-unloading-point-form-good.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderUnloadingPointFormGoodComponent {
  protected readonly I18N = 'orders.orderForm.points.unloadingPoint';

  private readonly goodTypes$ = this.orderFormService.goodTypes$;

  public good = input.required<OrderFormGood>();
  public form = input.required<OrderUnloadingPointGoodFormGroup | null>();

  public add = output<void>();
  public remove = output<void>();

  protected goodTypeName$ = toObservable(this.good).pipe(
    map((good) => good.good.type),
    distinctUntilChanged(),
    combineLatestWith(this.goodTypes$),
    map(([goodType, goodTypes]) => {
      if (!goodType) return null;
      if (!validateUuid(goodType)) return goodType;

      const type = goodTypes.find((type) => type.uuid === goodType);
      return type ? this.languagePipe.transform(type.names) : null;
    }),
    takeUntilDestroyed(this.destroyRef),
  );

  constructor(
    private destroyRef: DestroyRef,
    private languagePipe: OnyxLanguagePipe,
    private orderFormService: OrderFormService,
  ) {}
}
