import { OrderBaseParameters } from '../interfaces/order';

export const ORDER_BASE_PARAMETERS_KEYS: (keyof OrderBaseParameters)[] = [
  'hasSafeParking',
  'isDedicated',
  'hasEcmr',
  'isExpress',
  'driverLanguages',
  'isExclusive',
  'sentNotificationNumber',
  'hasDoubleCrew',
  'hasDoubleDeck',
  'hasPalletExchange',
] as const;
