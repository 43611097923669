@if (!OrderHelper.isOrderFinished(order().status.value)) {
  <app-order-modal-route-summary
    [order]="order()"
    [close$]="close$()"
  ></app-order-modal-route-summary>
}

<app-order-modal-plan [order]="order()"></app-order-modal-plan>

@if (OrderHelper.isOrderFinished(order().status.value)) {
  @if (order().outsourcingData) {
    <app-order-modal-outsourcing-data
      [order]="order()"
    ></app-order-modal-outsourcing-data>
  } @else {
    <app-order-modal-realized-by
      [order]="order()"
    ></app-order-modal-realized-by>
  }
}
