import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  concatMap,
  from,
  map,
  merge,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs';
import { AuthService } from '../../../../../auth/common/services/auth.service';
import { ApiService } from '../../../../../common/services/api.service';
import { BusinessFormDto } from '../../business/business.component';
import { Business } from '../interfaces/business';

@Injectable({
  providedIn: 'root',
})
export class BusinessService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private authService: AuthService,
  ) {
    super(http);
  }

  public getBusiness(): Observable<Business> {
    return this.get('/business');
  }

  public updateBusiness(dto: BusinessFormDto): Observable<void> {
    const previousBranchesUuid = new Set(
      this.authService.business()!.branches.map((branch) => branch.uuid),
    );
    const { branches, ...companyDto } = dto;

    const addBranches$ = from(
      branches.filter(({ uuid }) => !previousBranchesUuid.has(uuid!)),
    ).pipe(concatMap((branch) => this.addBranch(branch)));
    const editBranches$ = from(
      branches.filter(({ uuid }) => previousBranchesUuid.has(uuid!)),
    ).pipe(mergeMap((branch) => this.editBranch(branch)));
    const deleteBranches$ = from(
      [...previousBranchesUuid.values()].filter(
        (uuid) => !branches.some((branch) => branch.uuid === uuid),
      ),
    ).pipe(mergeMap((uuid) => this.deleteBranch(uuid)));

    return merge(
      this.put('/business', companyDto),
      addBranches$,
      editBranches$,
      deleteBranches$,
    ).pipe(
      switchMap(() => this.getBusiness()),
      tap((business) => this.authService.updateBusiness(business)),
      map(() => undefined),
    );
  }

  public updateBusinessLogo(logo: File): Observable<void> {
    const data = new FormData();
    data.set('logo', logo);

    return this.post<{ storageUuid: string }>('/business/logo', data).pipe(
      map(({ storageUuid }) => ({
        ...this.authService.business()!,
        logo: storageUuid,
      })),
      tap((business) => this.authService.updateBusiness(business)),
      map(() => undefined),
    );
  }

  public deleteBusinessLogo(): Observable<void> {
    return this.delete<void>('/business/logo').pipe(
      tap(() =>
        this.authService.updateBusiness({
          ...this.authService.business()!,
          logo: undefined,
        }),
      ),
    );
  }

  private addBranch(
    branch: BusinessFormDto['branches'][number],
  ): Observable<void> {
    return this.post(`/business/branches`, branch);
  }

  private editBranch(
    branch: BusinessFormDto['branches'][number],
  ): Observable<void> {
    return this.put(`/business/branches/${branch.uuid}`, branch);
  }

  private deleteBranch(uuid: string): Observable<void> {
    return this.delete(`/business/branches/${uuid}`);
  }
}
