@let controls = form().controls;
<form [class.compact]="compact()" [formGroup]="form()">
  <div class="name">
    <onyx-icon-box class="bar-icon" color="white" [hasShadow]="true">
      <onyx-icon name="tooltip" [size]="16"></onyx-icon>
    </onyx-icon-box>

    <onyx-dropdown
      [formControl]="controls.name"
      label="labels.name"
      [options]="routesSource()"
      (selectedValueChange)="route$.next($event)"
    ></onyx-dropdown>
  </div>

  @if (!compact()) {
    <onyx-text-field
      class="driver-note"
      [formControl]="controls.driverNote"
      label="labels.driverNote"
    ></onyx-text-field>
  } @else {
    <onyx-textarea
      class="driver-note"
      [formControl]="controls.driverNote"
      label="labels.driverNote"
      minResizeHeight="80px"
    ></onyx-textarea>
  }

  <div class="start-address">
    <onyx-icon-box class="bar-icon" color="white" [hasShadow]="true">
      <onyx-icon name="location" [size]="16"></onyx-icon>
    </onyx-icon-box>

    <onyx-address-input
      [formControl]="controls.startAddress"
      label="labels.startAddress"
      [showApartment]="true"
      apartmentWidth="calc(2/9 * 100% - 12px)"
      [gap]="16"
    ></onyx-address-input>
  </div>

  <onyx-address-input
    class="end-address"
    [formControl]="controls.endAddress"
    label="labels.endAddress"
    [showApartment]="true"
    apartmentWidth="calc(2/9 * 100% - 12px)"
    [gap]="16"
  ></onyx-address-input>

  <onyx-timepicker
    class="service-time"
    [formControl]="controls.serviceTime"
    [label]="serviceTimeLabel()"
    type="duration"
  ></onyx-timepicker>

  <onyx-phone-number-input
    class="phone"
    [formControl]="controls.phone"
    label="labels.phone"
  ></onyx-phone-number-input>

  <ng-container [formGroup]="controls.timeWindow">
    @let timeWindowControls = controls.timeWindow.controls;
    <div class="time-window-type">
      <onyx-icon-box class="bar-icon" color="white" [hasShadow]="true">
        <onyx-icon name="clock" [size]="16"></onyx-icon>
      </onyx-icon-box>

      <onyx-tabs
        [formControl]="timeWindowControls.type"
        label="labels.arrival"
        [tabs]="TRANSIT_POINT_TIME_WINDOW_TYPES"
        [fill]="true"
        size="m"
        width="100%"
      ></onyx-tabs>
    </div>

    @if (timeWindowControls.date.enabled) {
      <onyx-form-group
        class="time-window-date"
        [controls]="timeWindowControls.date"
      >
        <onyx-datepicker
          [formControl]="timeWindowControls.date"
          label="labels.day"
          [showErrors]="false"
        ></onyx-datepicker>

        <onyx-suggestions
          [control]="timeWindowControls.date"
          [suggestions]="DATE_SUGGESTIONS"
        ></onyx-suggestions>
      </onyx-form-group>
    }

    @if (timeWindowControls.time.enabled) {
      <onyx-timepicker
        class="time-window-time"
        [formControl]="timeWindowControls.time"
        label="Fix"
      ></onyx-timepicker>
    }
  </ng-container>
</form>
