<div
  class="container {{ type() }}"
  [ngClass]="{ 'close-animation': closeAnimation() }"
>
  <div
    class="dialog {{ color() }} {{ size() }}"
    [ngClass]="{ empty: error() || loading() }"
  >
    @if (loading() && showLoading()) {
      <onyx-spinner [size]="48"></onyx-spinner>
    } @else if (error()) {
      <onyx-error [(error)]="error"></onyx-error>
    } @else {
      <div
        class="top-bar"
        [ngClass]="{
          'extended-m':
            (avatarUser() || avatarCompany() || roles()) && !isCenter(),
          'extended-s': extend() && !isCenter(),
          sticky: isHeaderSticky(),
        }"
      >
        <div class="title-container">
          @if (avatarUser() || avatarCompany()) {
            <onyx-avatar
              [type]="avatarUser() ? 'user' : 'company'"
              [user]="$any(avatarUser())"
              [company]="$any(avatarCompany())"
              size="l"
            ></onyx-avatar>
          } @else {
            <ng-content select="[headingImage]"></ng-content>
          }

          @let modalHeading = heading() ?? '' | translate;
          <div class="title-wrapper">
            <div class="title">
              @if (isCenter()) {
                <div class="mobile">
                  <p class="f-paragraph-medium-2">{{ modalHeading }}</p>
                </div>

                <div class="desktop">
                  <ng-content select="[headingIcon]"></ng-content>

                  <h2>{{ modalHeading }}</h2>
                </div>
              } @else {
                <div class="title-group">
                  <div class="text-group">
                    <div class="number-group">
                      <div class="heading-container">
                        <ng-content select="[headingLeftOptions]"></ng-content>
                        <h3>{{ modalHeading }}</h3>
                      </div>
                      @if (count() != null) {
                        <h4 class="count">{{ count() | number }}</h4>
                      }
                    </div>

                    <div class="left-options">
                      <ng-content select="[leftOptions]"></ng-content>
                    </div>
                  </div>

                  @if (roles()) {
                    <div class="roles">
                      @for (role of roles(); track role) {
                        <onyx-badge [color]="role.color">
                          {{
                            (dictionaryCode()
                              ? dictionaryCode() + '.' + role.value
                              : role.value
                            ) | translate
                          }}
                        </onyx-badge>
                      }
                    </div>
                  } @else if (extend()) {
                    <ng-content select="[bottomOptions]"></ng-content>
                  }
                </div>
              }
            </div>
          </div>
        </div>

        <div class="right-side">
          <ng-content select="[rightOptions]"></ng-content>

          @if (showClose() && !loading() && !error()) {
            <div class="internal-close-button">
              <onyx-icon-button
                type="transparent"
                color="black"
                [size]="isCenter() ? 'l' : 'm'"
                [tabindex]="-1"
                [onyxTooltip]="I18N + '.close'"
                (click)="closeDialog()"
              >
                <onyx-icon name="close" [size]="16"></onyx-icon>
              </onyx-icon-button>
            </div>
          }
        </div>
      </div>

      <div
        class="content"
        [class.padding]="padding()"
        [class.center]="isCenter()"
        (scroll)="handleContentScroll(content)"
        #content
      >
        <ng-content></ng-content>
      </div>

      @if (showBottomBar() == null ? isCenter() : showBottomBar()) {
        <div class="bottom-bar" [class.sticky]="isFooterSticky()">
          <div class="group">
            <ng-content select="[bottomLeftOptions]"></ng-content>
          </div>
          <div class="group">
            <ng-content select="[bottomRightOptions]"></ng-content>
          </div>

          <ng-content select="[bottomBar]"></ng-content>
        </div>
      }
    }
  </div>
</div>
