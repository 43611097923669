import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Optional,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxIconComponent,
  OnyxInformationRowComponent,
  OnyxLanguagePipe,
  OnyxModalComponent,
} from '@onyx/angular';
import { sumBy } from 'lodash';
import { OrderLoadingGood } from '../../../../../dashboard/orders/common/interfaces/order-point';
import { OrderFormService } from '../../../../../dashboard/orders/common/services/order-form.service';
import { OrderLoadingPointForm } from '../../../../../dashboard/orders/order-form/order-points-form/order-loading-point-form/order-loading-point-form.component';
import { OrderUnloadingPointForm } from '../../../../../dashboard/orders/order-form/order-points-form/order-unloading-point-form/order-unloading-point-form.component';
import { DictionaryCode } from '../../../../enums/dictionary-code';
import { GoodsSizePipe } from '../../../../pipes/goods-size.pipe';
import { I18nPipe } from '../../../../pipes/i18n.pipe';

export interface RoutePointGoodsModalComponentData {
  point: OrderLoadingPointForm | OrderUnloadingPointForm;
  goods: OrderLoadingGood[];
}

@Component({
  selector: 'app-route-point-goods-modal',
  imports: [
    OnyxModalComponent,
    I18nPipe,
    TranslatePipe,
    OnyxDataWidgetComponent,
    OnyxIconComponent,
    OnyxLanguagePipe,
    TranslatePipe,
    DecimalPipe,
    OnyxInformationRowComponent,
    GoodsSizePipe,
  ],
  templateUrl: './route-point-goods-modal.component.html',
  styleUrl: './route-point-goods-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutePointGoodsModalComponent {
  protected readonly DictionaryCode = DictionaryCode;

  protected readonly totalGoods: Pick<
    OrderLoadingGood,
    'unit' | 'quantity'
  > | null;
  protected readonly totalWeight: number;

  protected readonly goods: (OrderLoadingGood & {
    color: string | undefined;
  })[];

  constructor(
    @Inject(DIALOG_DATA)
    protected readonly data: RoutePointGoodsModalComponentData,
    protected readonly dialogRef: DialogRef<void>,
    @Optional() private readonly orderFormService?: OrderFormService,
  ) {
    const { goods } = data;

    this.totalGoods = null;
    if (goods.every((good) => good.unit === goods[0].unit)) {
      this.totalGoods = {
        unit: goods[0].unit,
        quantity: sumBy(goods, (good) => good.quantity),
      };
    }
    this.totalWeight = sumBy(goods, (good) => good.totalWeight);

    const formGoods = this.orderFormService?.getGoods();
    this.goods = goods.map((good) => ({
      ...good,
      color: formGoods?.find((formGood) => formGood.good.uuid === good.uuid)
        ?.goodColor,
    }));
  }
}
