import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Injector,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  OnyxCheckboxComponent,
  OnyxFormGroupComponent,
  OnyxSectionComponent,
  OnyxSuggestionsComponent,
  OnyxTimepickerComponent,
  OnyxTimeRange,
  onyxTimeRangeValidator,
  OnyxToggleComponent,
} from '@onyx/angular';
import { isEqual, toArray } from 'lodash';
import { map } from 'rxjs';
import { TIME_RANGE_ALL_DAY } from '../../../constants/common/time-range-all-day';
import { SuggestionHelper } from '../../../helpers/suggestions.helper';
import { ValidationHelper } from '../../../helpers/validation.helper';

type BusinessHoursFormGroup = ReturnType<
  (typeof BusinessHoursFormComponent)['buildForm']
>;

@Component({
  selector: 'app-business-hours-form',
  imports: [
    OnyxSectionComponent,
    ReactiveFormsModule,
    OnyxTimepickerComponent,
    OnyxToggleComponent,
    OnyxSuggestionsComponent,
    OnyxCheckboxComponent,
    OnyxFormGroupComponent,
  ],
  templateUrl: './business-hours-form.component.html',
  styleUrl: './business-hours-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessHoursFormComponent implements OnInit {
  protected readonly I18N = 'forms.businessHours';
  protected readonly TIME_RANGE_SUGGESTIONS =
    SuggestionHelper.getTimeRangeSuggestions();

  public form = input.required<BusinessHoursFormGroup>();

  protected isAlwaysOpen = signal(false);
  protected isSaturdayClosed = signal(false);
  protected isSundayClosed = signal(false);

  constructor(private injector: Injector) {}

  public ngOnInit(): void {
    const businessHours = toSignal(
      this.form().valueChanges.pipe(map(() => this.form().getRawValue())),
      { initialValue: this.form().getRawValue(), injector: this.injector },
    );

    const formValues = toArray(businessHours());
    const isAlwaysOpen =
      formValues.length > 0 &&
      formValues.every((value) => isEqual(value, TIME_RANGE_ALL_DAY));
    this.isAlwaysOpen.set(isAlwaysOpen);

    this.isSaturdayClosed.set(businessHours()?.saturday === null);
    this.isSundayClosed.set(businessHours()?.sunday === null);

    effect(
      () => {
        if (this.isSaturdayClosed() || this.isSundayClosed()) {
          this.isAlwaysOpen.set(false);
        }
      },
      { injector: this.injector },
    );

    effect(
      () => {
        ValidationHelper.toggleControls(this.form(), !this.isAlwaysOpen());

        if (this.isAlwaysOpen()) {
          this.isSaturdayClosed.set(false);
          this.isSundayClosed.set(false);

          const controls = toArray(this.form().controls);
          for (const control of controls) {
            control.setValue(TIME_RANGE_ALL_DAY);
          }
        }

        const weekendControls = new Map([
          [this.form().controls.saturday, this.isSaturdayClosed()],
          [this.form().controls.sunday, this.isSundayClosed()],
        ]);
        for (const [control, disabled] of weekendControls) {
          if (disabled) ValidationHelper.disableControls(control);
        }
      },
      { injector: this.injector },
    );
  }

  public static buildForm(fb: NonNullableFormBuilder, isAlwaysOpen = false) {
    const form = fb.group({
      weekday: fb.control<OnyxTimeRange | null>(null, [
        Validators.required,
        onyxTimeRangeValidator,
      ]),
      saturday: fb.control<OnyxTimeRange | null>(null, [
        Validators.required,
        onyxTimeRangeValidator,
      ]),
      sunday: fb.control<OnyxTimeRange | null>(null, [
        Validators.required,
        onyxTimeRangeValidator,
      ]),
    });

    if (isAlwaysOpen) {
      for (const control of toArray(form.controls)) {
        control.setValue(TIME_RANGE_ALL_DAY);
      }
    }

    return form;
  }
}
