import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxBadgeComponent,
  OnyxColor,
  OnyxIconComponent,
  OnyxIconName,
  OnyxWidgetBoxComponent,
} from '@onyx/angular';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { OrderStatus } from '../../../common/enums/order-status';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';
import { OrderPoint } from '../../../common/interfaces/order-point';

interface OrderStatusData {
  name: string;
  icon?: OnyxIconName;
}

@Component({
  selector: 'app-order-modal-status',
  imports: [
    TranslatePipe,
    OnyxBadgeComponent,
    OnyxWidgetBoxComponent,
    OnyxIconComponent,
  ],
  templateUrl: './order-modal-status.component.html',
  styleUrl: './order-modal-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalStatusComponent {
  protected readonly I18N = 'orders.orderModal';

  protected readonly DictionaryCode = DictionaryCode;

  public order = input.required<Order>();
  public color = input<OnyxColor | null>();
  public point = input<OrderPoint | null>();

  protected statusData = computed<OrderStatusData>(() => {
    const status = this.order().status.value;

    if (status === OrderStatus.VEHICLE_SEARCH) {
      return { name: 'labels.unassignedVehicle' };
    }

    if (status === OrderStatus.TO_DO && this.order().outsourcingData) {
      return { name: 'labels.toOutsourcing' };
    }

    if (status === OrderStatus.IN_PROGRESS) {
      return { name: `${DictionaryCode.ORDER_STATUS}.${status}` };
      // TEMP: N/A
      // const point = this.point()!;
      // const pointStatusMap: Partial<
      //   Record<
      //     OrderPointCategory,
      //     Partial<Record<OrderPointStatus, OrderStatusData>>
      //   >
      // > = {
      //   [OrderPointCategory.LOADING]: {
      //     [OrderPointStatus.WAITING]: {
      //       name: 'labels.waitingForLoading',
      //       icon: 'vehicle-waiting-alt-two-tone',
      //     },
      //     [OrderPointStatus.IN_PROGRESS]: {
      //       name: 'labels.loadingInProgress',
      //       icon: 'vehicle-loading-two-tone',
      //     },
      //   },
      //   [OrderPointCategory.UNLOADING]: {
      //     [OrderPointStatus.WAITING]: {
      //       name: 'labels.waitingForUnloading',
      //       icon: 'vehicle-waiting-alt-two-tone',
      //     },
      //     [OrderPointStatus.IN_PROGRESS]: {
      //       name: 'labels.unloadingInProgress',
      //       icon: 'vehicle-unloading-two-tone',
      //     },
      //   },
      // };
      // const categoryMap = pointStatusMap[point.category];
      // if (categoryMap && categoryMap[point.status]) {
      //   return categoryMap[point.status]!;
      // }
      // return {
      //   name: `${this.translateService.instant('labels.toPoint')}: ${(point.route?.remainingDistance ?? 0) / 1000}km`,
      //   icon: 'vehicle-free-outlined',
      // };
    }

    return { name: `${DictionaryCode.ORDER_STATUS}.${status}` };
  });

  protected statusColor = computed(() => {
    const { status, outsourcingData } = this.order();
    const { value, color } = status;

    switch (value) {
      case OrderStatus.VEHICLE_SEARCH:
        return 'blue';

      case OrderStatus.WAITING_FOR_PAYMENT:
      case OrderStatus.PARTIALLY_PAID:
        return this.orderHelper.getPaymentDelay(this.order()) ? 'red' : color;

      default: {
        if (
          !OrderHelper.hasApprovedVehicle(value) ||
          OrderHelper.isOrderFinished(value)
        ) {
          return color;
        }

        return outsourcingData ? 'orange' : (this.color() ?? color);
      }
    }
  });

  protected paidPercentage = computed(() =>
    OrderHelper.getPaidPercentage(this.order()),
  );

  constructor(
    private translateService: TranslateService,
    private orderHelper: OrderHelper,
  ) {}
}
