import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxAmount } from '@onyx/angular';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AmountService extends ApiService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public calculateAmount(
    amount: Pick<OnyxAmount, 'date' | 'value' | 'currency' | 'resultCurrency'>,
  ): Observable<Required<OnyxAmount>> {
    return this.post(`/amount`, amount);
  }
}
