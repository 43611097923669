@if (timeWindows(); as timeWindows) {
  @let window = timeWindows[0];
  <span class="f-gray">
    {{ (window.time ? 'fix' : 'timeWindow') | i18n: 'labels' | translate }}
    &nbsp;</span
  >

  <span>{{ window.time ?? window.timeRange | onyxTime }}</span>
  <span class="f-gray"> · {{ window.date! | onyxDate: 'day-short-date' }}</span>

  @if (timeWindows.length > 1) {
    <span>, +{{ timeWindows.length - 1 }}</span>
  }
}

@if (businessHours(); as businessHours) {
  @if (businessHoursAlwaysOpen()) {
    <span class="f-gray">{{ 'labels.opened' | translate }} </span>
    <span>24/7</span>
  } @else {
    @for (key of BUSINESS_HOURS_KEYS; track key) {
      <span class="f-gray">
        {{ !$first ? ' · ' : '' }}
        {{ businessHours | businessHours: key : 'short' : 'label' }}
      </span>

      <span>
        {{ businessHours | businessHours: key : 'short' : 'value' }}
      </span>
    }
  }
}
