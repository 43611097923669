import { DecimalPipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Injector,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  OnyxDatepickerComponent,
  OnyxDatePipe,
  OnyxFormGroupComponent,
  onyxMaxNumberValidator,
  OnyxSuggestion,
  OnyxSuggestionsComponent,
  OnyxTextFieldComponent,
  OnyxToggleComponent,
} from '@onyx/angular';
import { sumBy } from 'lodash';
import { SuggestionHelper } from '../../../../../common/helpers/suggestions.helper';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { OrderStatus } from '../../../common/enums/order-status';
import { Order } from '../../../common/interfaces/order';

type OrderModalPaymentFormGroup = ReturnType<
  (typeof OrderModalPaymentFormComponent)['buildForm']
>;

export type OrderModalPaymentForm = ReturnType<
  OrderModalPaymentFormGroup['getRawValue']
>;

@Component({
  selector: 'app-order-modal-payment-form',
  imports: [
    OnyxDatepickerComponent,
    OnyxToggleComponent,
    OnyxTextFieldComponent,
    OnyxSuggestionsComponent,
    ReactiveFormsModule,
    OnyxFormGroupComponent,
    UpperCasePipe,
  ],
  templateUrl: './order-modal-payment-form.component.html',
  styleUrl: './order-modal-payment-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPaymentFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderModal';
  protected readonly DATE_SUGGESTIONS = SuggestionHelper.getDateSuggestions(
    this.translateService,
    this.datePipe,
    { future: false },
  );

  protected readonly OrderStatus = OrderStatus;

  public order = input.required<Order>();
  public form = input.required<OrderModalPaymentFormGroup>();

  public partiallyPaidChanged = output<boolean>();

  protected isPartiallyPaid = signal(false);
  protected remainingPriceSuggestions = signal<OnyxSuggestion<number>[] | null>(
    null,
  );

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
    private decimalPipe: DecimalPipe,
    private datePipe: OnyxDatePipe,
  ) {}

  public ngOnInit(): void {
    const controls = this.form().controls;

    effect(
      () => {
        const order = this.order();
        const { status, payments, price } = order;
        const totalPrice = price.value;
        const totalPayments = sumBy(
          payments.history,
          (payment) => payment.value.value,
        );
        const remainingPrice = (totalPrice - totalPayments) / 100;

        this.isPartiallyPaid.set(
          [OrderStatus.PARTIALLY_PAID, OrderStatus.PAID].includes(status.value),
        );

        if (status.value === OrderStatus.PARTIALLY_PAID) {
          this.remainingPriceSuggestions.set([
            {
              name: this.decimalPipe.transform(remainingPrice)!,
              value: remainingPrice,
            },
          ]);

          controls.partialPayment.addValidators(
            onyxMaxNumberValidator(remainingPrice),
          );
        } else if (status.value === OrderStatus.WAITING_FOR_PAYMENT) {
          controls.partialPayment.addValidators(
            onyxMaxNumberValidator(price.value / 100),
          );
        }
      },
      { injector: this.injector },
    );

    effect(
      () => {
        ValidationHelper.toggleControls(
          controls.partialPayment,
          this.isPartiallyPaid(),
        );
        this.partiallyPaidChanged.emit(this.isPartiallyPaid());
      },
      { injector: this.injector },
    );
  }

  public static buildForm(fb: NonNullableFormBuilder) {
    return fb.group({
      paymentDay: fb.control<string | null>(null, [Validators.required]),
      partialPayment: fb.control<number | null>(null, [Validators.required]),
    });
  }
}
