import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxCallbackOption,
  OnyxChip,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxToastService,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { isString } from 'lodash';
import { catchError, EMPTY, Subject, switchMap, tap } from 'rxjs';
import { DriversTachographComponent } from '../../../common/components/drivers-tachograph/drivers-tachograph.component';
import { ModalNoteComponent } from '../../../common/components/modals/modal-note/modal-note.component';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../common/helpers/validation.helper';
import { FleetHelper } from '../../fleet/common/helpers/fleet.helper';
import { DelegationStatus } from '../common/enums/delegation-status';
import { DelegationHelper } from '../common/helpers/delegation.helper';
import {
  Delegation,
  SimplifiedDelegation,
} from '../common/interfaces/delegation';
import { DelegationsService } from '../common/services/delegations.service';
import { DelegationActionModalMode } from '../delegation-action-modal/delegation-action-modal.component';
import { DelegationsRoute } from '../delegations.routes';
import { DelegationModalHeadingComponent } from './delegation-modal-heading/delegation-modal-heading.component';
import { DelegationModalMainSectionComponent } from './delegation-modal-main-section/delegation-modal-main-section.component';

export type DelegationModalData = Delegation | SimplifiedDelegation | string;

enum DelegationModalSection {
  // TEMP: N/A
  // FINANCES = 'finances',
  // ORDERS = 'orders',
  // DOCUMENTS = 'documents',
  // EXPENSES = 'expenses',
  TACHOGRAPH = 'tachograph',
  NOTE = 'note',
}

@Component({
  selector: 'app-delegation-modal',
  imports: [
    OnyxModalComponent,
    DelegationModalHeadingComponent,
    TranslatePipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxButtonComponent,
    DelegationModalMainSectionComponent,
    OnyxChipsComponent,
    OnyxDropdownDirective,
    OnyxTooltipDirective,
    ModalNoteComponent,
    DriversTachographComponent,
  ],
  templateUrl: './delegation-modal.component.html',
  styleUrl: './delegation-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalComponent {
  protected readonly I18N = 'delegations.delegationModal';

  protected readonly FleetHelper = FleetHelper;
  protected readonly DelegationRoute = DelegationsRoute;

  private readonly uuid: string;

  protected DelegationModalSection = DelegationModalSection;

  protected delegation = signal<Delegation | null>(null);
  protected group = signal([DelegationModalSection.NOTE]);
  protected loading = signal(false);
  protected error = signal(false);
  protected close$ = new Subject<void>();
  protected delegation$ = new Subject<void>();

  protected headerActionButton = computed<OnyxCallbackOption | null>(() => {
    const delegation = this.delegation();
    if (!delegation) return null;

    const headerActionButtons: Partial<
      Record<DelegationStatus, OnyxCallbackOption>
    > = {
      [DelegationStatus.PLANNED]: {
        name: 'labels.activate',
        value: () =>
          this.openDelegationActionModal(
            DelegationActionModalMode.ACTIVATE,
            this.delegation()!,
          ),
      },
      [DelegationStatus.ACTIVE]: {
        name: 'labels.finish',
        value: () =>
          this.openDelegationActionModal(
            DelegationActionModalMode.FINISH,
            this.delegation()!,
          ),
      },
      [DelegationStatus.UNSETTLED]: {
        name: 'labels.settle',
        value: () => alert('HOLD'),
      },
    };

    return headerActionButtons[delegation.status.value] ?? null;
  });
  protected chips = computed<OnyxChip<DelegationModalSection>[]>(() => {
    const delegation = this.delegation();
    if (!delegation) return [];

    return [
      // {
      //   name: `${this.I18N}.chips.finances`,
      //   value: DelegationModalSection.FINANCES,
      // },
      // { name: 'labels.orders', value: DelegationModalSection.ORDERS },
      // {
      //   name: `${this.I18N}.chips.expenses`,
      //   value: DelegationModalSection.EXPENSES,
      // },
      ...(delegation.status.value === DelegationStatus.ACTIVE
        ? [
            {
              name: 'labels.tachograph',
              value: DelegationModalSection.TACHOGRAPH,
            },
          ]
        : []),
      // TEMP: N/A
      // {
      //   name: 'labels.documents',
      //   value: DelegationModalSection.DOCUMENTS,
      // },
      {
        name: 'labels.note',
        value: DelegationModalSection.NOTE,
      },
    ];
  });
  protected options = computed(() => {
    const delegation = this.delegation();
    return delegation
      ? this.delegationHelper.getOptions(delegation, { close$: this.close$ })
      : null;
  });
  protected hasEdit = computed(() => {
    const delegation = this.delegation();
    return delegation ? DelegationHelper.hasEdit(delegation) : false;
  });

  constructor(
    @Inject(DIALOG_DATA)
    protected delegationData: DelegationModalData,
    protected dialogRef: DialogRef<void>,
    private delegationService: DelegationsService,
    private destroyRef: DestroyRef,
    private toastService: OnyxToastService,
    private delegationHelper: DelegationHelper,
    private router: Router,
  ) {
    this.uuid = isString(this.delegationData)
      ? this.delegationData
      : this.delegationData.uuid;

    this.delegation$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() =>
          this.delegationService.getDelegation(this.uuid).pipe(
            catchError((response) => {
              this.delegation.set(null);
              this.loading.set(false);
              this.error.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (delegation) => {
          this.delegation.set(delegation);
          this.loading.set(false);
          this.error.set(false);
        },
      });

    if (
      isString(this.delegationData) ||
      !DelegationHelper.isDelegationType(this.delegationData)
    ) {
      this.delegation$.next();
    } else {
      this.delegation.set(this.delegationData);
    }

    this.delegationService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.delegation$.next());
  }

  protected edit(): void {
    this.delegationHelper.edit(this.uuid, { close$: this.close$ });
  }

  protected showOnMap(): void {
    const vehicle = this.delegation()?.vehicle;
    if (!vehicle || !FleetHelper.hasTelematics(vehicle)) return;

    CommonHelper.showOnMap(this.router, vehicle.uuid);
    this.close$.next();
  }

  protected showOnGantt(): void {
    const vehicle = this.delegation()?.vehicle;
    if (!vehicle || !FleetHelper.hasTelematics(vehicle)) return;

    CommonHelper.showOnGantt(this.router, 'vehicle', vehicle.uuid);
    this.close$.next();
  }

  protected openDelegationActionModal(
    mode: DelegationActionModalMode,
    delegation: Delegation,
  ): void {
    this.delegationHelper.openDelegationActionModal(mode, delegation);
  }
}
