@let fullName = employee.firstName + ' ' + employee.lastName;

<onyx-modal
  type="center"
  size="m"
  [heading]="fullName + ' - ' + (I18N + '.heading' | translate)"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <onyx-avatar headingIcon [user]="employee" type="user" size="s"></onyx-avatar>

  <div class="container">
    <form [formGroup]="form">
      <div class="item">
        <onyx-dropdown
          [formControl]="form.controls.vehicles"
          label="labels.vehicles"
          [options]="VEHICLES_SOURCE"
          [multiple]="true"
          (selectedValueChange)="vehicle.set($event)"
        ></onyx-dropdown>

        <onyx-icon-button
          type="transparent"
          color="black"
          size="l"
          [disabled]="!vehicle()"
          (click)="openFleetModal(vehicle())"
        >
          <onyx-icon name="side-modal" [size]="16"></onyx-icon>
        </onyx-icon-button>
      </div>
    </form>
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'labels.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [disabled]="form.invalid"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'labels.assign' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
