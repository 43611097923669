<onyx-modal
  type="center"
  size="s"
  [heading]="I18N + '.heading'"
  [close$]="close$"
  (keydown.enter)="next()"
  (closeModal)="dialogRef.close()"
>
  <form [formGroup]="form">
    @let controls = form.controls;
    <onyx-phone-number-input
      [formControl]="controls.privatePhone"
      label="labels.privatePhone"
    ></onyx-phone-number-input>

    <onyx-dropdown
      [formControl]="controls.nationality"
      label="labels.nationality"
      [options]="countries$ | async"
      [search]="true"
    ></onyx-dropdown>
  </form>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'labels.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? next() : null"
    >
      {{ 'labels.next' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>

<ng-template #conflictToastTemplate>
  <p class="error">
    {{
      'drivers.driverForm.uniqueErrors.privatePhone'
        | translate: { privatePhone: privatePhone() }
    }}
  </p>

  <onyx-button size="s" (click)="showExistingDriver()">
    {{ 'labels.show' | translate }}
  </onyx-button>
</ng-template>
