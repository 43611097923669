import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  OnyxConfirmModalComponent,
  OnyxConfirmModalData,
  OnyxLanguagePipe,
  OnyxModalService,
  OnyxToastService,
} from '@onyx/angular';
import { filter, map, Observable, of, Subject } from 'rxjs';
import { CONFLICT_ERROR_CODE } from '../../../../../common/constants/common/conflict-error-code';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import {
  CompanyDictionaryByCode,
  CompanyDictionaryCode,
  CompanyDictionaryFormByCode,
} from '../enums/company-dictionary-code';

@Injectable({
  providedIn: 'root',
})
export class DictionariesHelper {
  private readonly I18N = 'dictionaries';

  constructor(
    private modalService: OnyxModalService,
    private translateService: TranslateService,
    private languagePipe: OnyxLanguagePipe,
    private toastService: OnyxToastService,
  ) {}

  public confirmEdit(
    code: CompanyDictionaryCode,
    item: CompanyDictionaryByCode[CompanyDictionaryCode],
    cancel$: Subject<void>,
  ): Observable<void> {
    if (!item.hasRelatedObjects) return of(undefined);

    return this.modalService
      .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
        heading: this.translateService.instant(
          `${this.I18N}.confirmEdit.heading.${code}`,
          { name: this.languagePipe.transform(item.names) },
        ),
        message: `${this.I18N}.confirmEdit.message`,
        confirmButtonText: 'labels.saveChanges',
        confirmButtonColor: 'blue',
      })
      .result.pipe(
        filter((ok) => {
          if (ok === false) cancel$.next();
          return !!ok;
        }),
        map(() => undefined),
      );
  }

  public handleEditError(
    response: HttpErrorResponse,
    code: CompanyDictionaryCode,
    form: CompanyDictionaryFormByCode[CompanyDictionaryCode],
  ): void {
    if (
      response.status === HttpStatusCode.BadRequest &&
      response.error.names === CONFLICT_ERROR_CODE
    ) {
      this.toastService.showError(
        this.translateService.instant(`${this.I18N}.uniqueErrors.${code}`, {
          name: this.languagePipe.transform(form.names),
        }),
      );
      return;
    }

    ValidationHelper.handleUnexpectedError(response, this.toastService);
  }
}
