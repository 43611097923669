import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxFlagComponent } from '@onyx/angular';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { I18nPipe } from '../../../pipes/i18n.pipe';

@Component({
  selector: 'app-country-cell',
  imports: [OnyxFlagComponent, TranslatePipe, UpperCasePipe, I18nPipe],
  templateUrl: './country-cell.component.html',
  styleUrl: './country-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryCellComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public countryCode = input.required<string | null>();
  public short = input(false);
  public onlyFlag = input(false);
}
