<onyx-data-widget
  supheading="labels.tolls"
  [headingIcon]="{ name: 'finance', size: 16, color: 'violet' }"
>
  <div heading class="heading">
    <span
      [innerHTML]="
        tollsTotalPrice()
          | number: '1.2-2'
          | numberDecoration: (tolls().defaultCurrency | uppercase)
      "
    ></span>

    @let result = realizationResult();
    @if (result && result.price.value !== 0) {
      <span class="result {{ result.color }} f-semibold-2">
        {{ result.sign }}

        <app-amount-cell
          [amount]="{
            value: result.price.value,
            currency: result.price.currency,
          }"
          [short]="false"
        ></app-amount-cell>
      </span>
    }
  </div>

  <div class="container">
    <div class="list">
      <div class="item f-medium-3">
        <p>{{ 'labels.roads' | translate }}</p>

        <app-amount-cell
          [amount]="{
            value: tolls().defaultCurrencyValue,
            currency: tolls().defaultCurrency,
          }"
          [short]="false"
        ></app-amount-cell>
      </div>

      <!-- <div class="item f-medium-3">
        <p>{{ 'labels.other' | translate }}</p>

        N/A
      </div> -->
    </div>

    @if (plannedData(); as plannedData) {
      <div class="plan" [onyxTooltip]="planTooltip" onyxTooltipColor="white">
        <span>{{ 'labels.plan' | translate }}</span>

        <div class="plan-list f-medium-3">
          @let total = plannedData.total;
          <app-amount-cell
            [amount]="{
              value: total.value,
              currency: total.currency,
            }"
            [short]="false"
          ></app-amount-cell>

          <span>·</span>

          @let tolls = plannedData.tolls;
          <app-amount-cell
            [amount]="{
              value: tolls.defaultCurrencyValue,
              currency: tolls.defaultCurrency,
            }"
            [short]="false"
          ></app-amount-cell>

          <!-- <span>·</span>

          N/A -->
        </div>
      </div>
    }
  </div>
</onyx-data-widget>

<ng-template #planTooltip>
  @if (plannedData(); as plannedData) {
    <div class="tooltip f-medium-3">
      <p>{{ 'labels.plan' | translate }}</p>

      <div class="tooltip-data">
        <div class="item">
          <p>{{ 'totalFees' | i18n: I18N | translate }}</p>

          @let total = plannedData.total;
          <app-amount-cell
            [amount]="{
              value: total.value,
              currency: total.currency,
            }"
            [short]="false"
          ></app-amount-cell>
        </div>

        <div class="item">
          <p>{{ 'labels.roads' | translate }}</p>

          @let tolls = plannedData.tolls;
          <app-amount-cell
            [amount]="{
              value: tolls.defaultCurrencyValue,
              currency: tolls.defaultCurrency,
            }"
            [short]="false"
          ></app-amount-cell>
        </div>

        <!-- <div class="item">
          <p>{{ 'labels.other' | translate }}</p>

          N/A
        </div> -->
      </div>
    </div>
  }
</ng-template>
