import { Weekdays } from '../../../dashboard/delegations/delegation-form/delegation-daily-returns-form/delegation-daily-returns-form.component';

export const WEEKDAYS: (keyof Weekdays)[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
