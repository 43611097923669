import {
  OnyxAmount,
  OnyxComponentInputs,
  OnyxTableColumn,
} from '@onyx/angular';
import { AmountCellComponent } from '../../../../common/components/cells/amount-cell/amount-cell.component';
import { BaseCellComponent } from '../../../../common/components/cells/base-cell/base-cell.component';
import { BooleanCellComponent } from '../../../../common/components/cells/boolean-cell/boolean-cell.component';
import { CountryCellComponent } from '../../../../common/components/cells/country-cell/country-cell.component';
import { DateCellComponent } from '../../../../common/components/cells/date-cell/date-cell.component';
import { DateRangeCellComponent } from '../../../../common/components/cells/date-range-cell/date-range-cell.component';
import { DimensionsCellComponent } from '../../../../common/components/cells/dimensions-cell/dimensions-cell.component';
import { FuelTankCellComponent } from '../../../../common/components/cells/fuel-tank-cell/fuel-tank-cell.component';
import { MakeAndModelCellComponent } from '../../../../common/components/cells/make-and-model-cell/make-and-model-cell.component';
import { NumberCellComponent } from '../../../../common/components/cells/number-cell/number-cell.component';
import { ParametersCellComponent } from '../../../../common/components/cells/parameters-cell/parameters-cell.component';
import { StringCellComponent } from '../../../../common/components/cells/string-cell/string-cell.component';
import { UnitCellComponent } from '../../../../common/components/cells/unit-cell/unit-cell.component';
import { VehicleValueCellComponent } from '../../../../common/components/cells/vehicle-value-cell/vehicle-value-cell.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { Dimensions } from '../../../../common/interfaces/common/goods-size';
import { Fleet } from '../interfaces/fleet';
import { FleetImportData } from './fleet-import-schema';

export const FLEET_IMPORT_COLUMNS: OnyxTableColumn<
  FleetImportData & { index: number }
>[] = [
  {
    required: true,
    id: 'index',
    name: 'labels.row',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.index,
      }),
    },
  },
  {
    id: 'category',
    name: 'labels.vehicleCategory',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.generalInformation.category,
        dictionaryCode: data.item.generalInformation.category.includes(
          'trailer',
        )
          ? DictionaryCode.TRAILER_CATEGORY
          : DictionaryCode.VEHICLE_CATEGORY,
      }),
    },
  },
  {
    id: 'type',
    name: 'labels.vehicleType',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.generalInformation.type,
        dictionaryCode: DictionaryCode.VEHICLE_TYPE,
      }),
    },
  },
  {
    id: 'registrationNumber',
    name: 'labels.registrationNumber',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.generalInformation.registrationNumber,
      }),
    },
  },
  {
    id: 'sideNumber',
    name: 'labels.sideNumber',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.generalInformation.sideNumber,
      }),
    },
  },
  {
    id: 'vinNumber',
    name: 'labels.vinNumber',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.generalInformation.vinNumber,
      }),
    },
  },
  {
    id: 'makeAndModel',
    name: 'labels.makeAndModel',
    width: 'fill',
    component: {
      ref: MakeAndModelCellComponent,
      inputs: (data): OnyxComponentInputs<MakeAndModelCellComponent> => ({
        make: data.item.generalInformation.make,
        model: data.item.generalInformation.model,
      }),
    },
  },
  {
    id: 'manufactureYear',
    name: 'labels.manufactureYear',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.generalInformation.manufactureYear,
        disablePipe: true,
      }),
    },
  },
  {
    id: 'firstRegistrationDate',
    name: 'labels.firstRegistrationDate',
    width: 'fill',
    component: {
      ref: DateCellComponent,
      inputs: (data): OnyxComponentInputs<DateCellComponent> => ({
        date: data.item.generalInformation.firstRegistrationDate,
      }),
    },
  },
  {
    id: 'registrationCountry',
    name: 'labels.registrationCountry',
    width: 'fill',
    component: {
      ref: CountryCellComponent,
      inputs: (data): OnyxComponentInputs<CountryCellComponent> => ({
        countryCode: data.item.generalInformation.registrationCountry,
      }),
    },
  },
  {
    id: 'state',
    name: 'labels.state',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.generalInformation.state,
        dictionaryCode: DictionaryCode.FLEET_STATE,
      }),
    },
  },
  {
    id: 'planningMode',
    name: 'labels.planning',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.generalInformation.planningMode,
        dictionaryCode: DictionaryCode.VEHICLE_PLANNING_MODE,
      }),
    },
  },
  {
    id: 'mileage',
    name: 'labels.mileage',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.generalInformation.initialMileage,
        unit: 'km',
      }),
    },
  },
  {
    id: 'maxSpeed',
    name: 'labels.maxSpeed',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.generalInformation.maxSpeed,
        unit: 'km/h',
      }),
    },
  },
  {
    id: 'assignedBase',
    name: 'labels.base',
    width: 'fill',
    component: {
      ref: BaseCellComponent,
      inputs: (data): OnyxComponentInputs<BaseCellComponent> => ({
        base: data.item.base,
      }),
    },
  },
  {
    id: 'curbWeight',
    name: 'labels.curbWeight',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.vehicleMeasurements.curbWeight,
        unit: 'kg',
      }),
    },
  },
  {
    id: 'axleCount',
    name: 'labels.axleCount',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.vehicleMeasurements.axleCount,
      }),
    },
  },
  {
    id: 'vehicleDimensions',
    name: 'labels.vehicleDimensions',
    width: 'fill',
    component: {
      ref: DimensionsCellComponent,
      inputs: (data): OnyxComponentInputs<DimensionsCellComponent> => ({
        dimensions: data.item.vehicleMeasurements,
      }),
    },
  },
  {
    id: 'loadCapacity',
    name: 'labels.loadCapacity',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.cargoSpace?.loadCapacity,
        unit: 'kg',
      }),
    },
  },
  {
    id: 'volume',
    name: 'labels.volume',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.cargoSpace?.volumeCapacity,
        unit: 'm³',
        decimalPlaces: 2,
      }),
    },
  },
  {
    id: 'chambersNumber',
    name: 'labels.chambersNumber',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.cargoSpace?.chambersNumber,
      }),
    },
  },
  {
    id: 'tankCertificates',
    name: 'labels.tankCertificates',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.cargoSpace?.tankCertificates,
        dictionaryCode: DictionaryCode.TANK_CERTIFICATE,
      }),
    },
  },
  {
    id: 'dischargeType',
    name: 'labels.dischargeType',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.cargoSpace?.dischargeTypes,
        dictionaryCode: DictionaryCode.DISCHARGE_TYPE,
      }),
    },
  },
  {
    id: 'europalletsNumber',
    name: 'labels.europalletsNumber',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.cargoSpace?.europalletsNumber,
      }),
    },
  },
  {
    id: 'cargoSpaceDimensions',
    name: 'labels.cargoSpaceDimensions',
    width: 'fill',
    component: {
      ref: DimensionsCellComponent,
      inputs: (data): OnyxComponentInputs<DimensionsCellComponent> => ({
        dimensions: data.item.cargoSpace as Dimensions | null,
      }),
    },
  },
  {
    id: 'mainFuelTank',
    name: 'labels.mainFuelTank',
    width: 'fill',
    component: {
      ref: FuelTankCellComponent,
      inputs: (data): OnyxComponentInputs<FuelTankCellComponent> => ({
        fuelType: data.item.fuelTankCapacity?.fuelType,
        tankCapacity: data.item.fuelTankCapacity?.mainTank,
      }),
    },
  },
  {
    id: 'additionalTank',
    name: 'labels.additionalTank',
    width: 'fill',
    component: {
      ref: FuelTankCellComponent,
      inputs: (data): OnyxComponentInputs<FuelTankCellComponent> => ({
        fuelType: data.item.fuelTankCapacity?.fuelType,
        tankCapacity: data.item.fuelTankCapacity?.additionalTank,
      }),
    },
  },
  {
    id: 'adBlueTank',
    name: 'labels.adBlue',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.fuelTankCapacity?.adBlueTank,
        unit: 'L',
        decimalPlaces: 2,
      }),
    },
  },
  {
    id: 'generatorFuelTank',
    name: 'labels.generatorFuelTank',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.fuelTankCapacity?.generatorFuelTank,
        unit: 'L',
        decimalPlaces: 2,
      }),
    },
  },
  {
    id: 'isForSale',
    name: 'labels.forSale',
    width: 'fill',
    component: {
      ref: BooleanCellComponent,
      inputs: (data): OnyxComponentInputs<BooleanCellComponent> => ({
        value: data.item.ownership.isForSale,
      }),
    },
  },
  {
    id: 'ownershipType',
    name: 'labels.ownershipType',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.ownership.type,
        dictionaryCode: DictionaryCode.OWNERSHIP_TYPE,
      }),
    },
  },
  {
    id: 'vehicleValue',
    name: 'labels.vehicleValue',
    width: 'fill',
    component: {
      ref: VehicleValueCellComponent,
      inputs: (data): OnyxComponentInputs<VehicleValueCellComponent> => ({
        ownership: data.item.ownership as any as Fleet['ownership'],
      }),
    },
  },
  {
    id: 'depreciationPeriod',
    name: 'labels.depreciationPeriod',
    width: 'fill',
    component: {
      ref: DateRangeCellComponent,
      inputs: (data): OnyxComponentInputs<DateRangeCellComponent> => ({
        range: data.item.ownership.depreciationPeriod
          ? {
              from: data.item.ownership.depreciationPeriod.from!,
              to: data.item.ownership.depreciationPeriod.to!,
            }
          : null,
      }),
    },
  },
  {
    id: 'selfDeposit',
    name: 'labels.selfDeposit',
    width: 'fill',
    component: {
      ref: AmountCellComponent,
      inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
        amount: data.item.ownership.selfDeposit as OnyxAmount | null,
      }),
    },
  },
  {
    id: 'buyout',
    name: 'labels.buyout',
    width: 'fill',
    component: {
      ref: AmountCellComponent,
      inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
        amount: data.item.ownership.buyout as OnyxAmount | null,
      }),
    },
  },
  {
    id: 'installment',
    name: 'labels.installment',
    width: 'fill',
    component: {
      ref: AmountCellComponent,
      inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
        amount: data.item.ownership.installment as OnyxAmount | null,
      }),
    },
  },
  {
    id: 'firstAndLastInstallment',
    name: 'labels.firstAndLastInstallment',
    width: 'fill',
    component: {
      ref: DateRangeCellComponent,
      inputs: (data): OnyxComponentInputs<DateRangeCellComponent> => ({
        range: data.item.ownership.firstAndLastInstallmentDate
          ? {
              from: data.item.ownership.firstAndLastInstallmentDate.from!,
              to: data.item.ownership.firstAndLastInstallmentDate.to!,
            }
          : null,
      }),
    },
  },
  {
    id: 'margin',
    name: 'labels.margin',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.ownership.margin,
        unit: '%',
        decimalPlaces: 2,
      }),
    },
  },
  {
    id: 'interestRateType',
    name: 'labels.interestRate',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.ownership.interestRateType,
        dictionaryCode: DictionaryCode.INTEREST_RATE_TYPE,
      }),
    },
  },
  {
    id: 'licensePermitIssuingCountry',
    name: 'labels.licensePermitIssuingCountry',
    width: 'fill',
    component: {
      ref: CountryCellComponent,
      inputs: (data): OnyxComponentInputs<CountryCellComponent> => ({
        countryCode: data.item.licensesAndPermits?.issuingCountry,
      }),
    },
  },
  {
    id: 'communityLicenseAbstractNumber',
    name: 'labels.communityLicenseAbstractNumber',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.licensesAndPermits?.communityLicenseAbstractNumber,
      }),
    },
  },
  {
    id: 'permitAbstractNumber',
    name: 'labels.permitAbstractNumber',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.licensesAndPermits?.permitAbstractNumber,
      }),
    },
  },
  {
    id: 'countriesWhitelist',
    name: 'forms.countriesWhitelist.heading',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.allowedCountries.whitelist,
        dictionaryCode: DictionaryCode.COUNTRY,
      }),
    },
  },
  {
    id: 'note',
    name: 'labels.note',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.note?.content,
      }),
    },
  },
  {
    id: 'parameters',
    name: 'labels.parameters',
    width: 'fill',
    component: {
      ref: ParametersCellComponent,
      inputs: (data): OnyxComponentInputs<ParametersCellComponent> => ({
        additionalParameters: data.item
          .additionalParameters as Fleet['additionalParameters'],
      }),
    },
  },
  {
    id: 'tachograph',
    name: 'labels.tachograph',
    width: 'fill',
    component: {
      ref: MakeAndModelCellComponent,
      inputs: (data): OnyxComponentInputs<MakeAndModelCellComponent> => ({
        make: data.item.tachograph?.make,
        model: data.item.tachograph?.model,
        makeDictionaryCode: DictionaryCode.TACHOGRAPH_MAKE,
        modelDictionaryCode: DictionaryCode.TACHOGRAPH_MODEL,
      }),
    },
  },
  {
    id: 'tachographLegalization',
    name: 'labels.tachographLegalization',
    width: 'fill',
    component: {
      ref: DateCellComponent,
      inputs: (data): OnyxComponentInputs<DateCellComponent> => ({
        date: data.item.tachograph?.nextLegalizationDate,
        showWarnings: true,
      }),
    },
  },
  {
    id: 'technicalInspection',
    name: 'labels.technicalInspection',
    width: 'fill',
    component: {
      ref: DateCellComponent,
      inputs: (data): OnyxComponentInputs<DateCellComponent> => ({
        date: data.item.registrationCertificate.technicalInspectionExpiryDate,
        showWarnings: true,
      }),
    },
  },
  {
    id: 'emissionStandard',
    name: 'labels.emissionStandard',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.co2Emissions?.emissionStandard,
        dictionaryCode: DictionaryCode.EMISSION_STANDARD,
      }),
    },
  },
  {
    id: 'ocNumber',
    name: 'labels.ocNumber',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.thirdPartyLiabilityInsurance.insuranceNumber,
      }),
    },
  },
  {
    id: 'ocExpiryDate',
    name: 'labels.ocExpiryDate',
    width: 'fill',
    component: {
      ref: DateCellComponent,
      inputs: (data): OnyxComponentInputs<DateCellComponent> => ({
        date: data.item.thirdPartyLiabilityInsurance.expiryDate,
        showWarnings: true,
      }),
    },
  },
  {
    id: 'ocAnnualCost',
    name: 'labels.ocAnnualCost',
    width: 'fill',
    component: {
      ref: AmountCellComponent,
      inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
        amount: data.item.thirdPartyLiabilityInsurance.annualCost,
      }),
    },
  },
  {
    id: 'ocInsuranceSum',
    name: 'labels.ocInsuranceSum',
    width: 'fill',
    component: {
      ref: AmountCellComponent,
      inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
        amount: data.item.thirdPartyLiabilityInsurance.insuranceSum,
      }),
    },
  },
  {
    id: 'acNumber',
    name: 'labels.acNumber',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.comprehensiveInsurance?.insuranceNumber,
      }),
    },
  },
  {
    id: 'acExpiryDate',
    name: 'labels.ocExpiryDate',
    width: 'fill',
    component: {
      ref: DateCellComponent,
      inputs: (data): OnyxComponentInputs<DateCellComponent> => ({
        date: data.item.comprehensiveInsurance?.expiryDate,
        showWarnings: true,
      }),
    },
  },
  {
    id: 'acAnnualCost',
    name: 'labels.ocAnnualCost',
    width: 'fill',
    component: {
      ref: AmountCellComponent,
      inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
        amount: data.item.comprehensiveInsurance
          ?.annualCost as OnyxAmount | null,
      }),
    },
  },
  {
    id: 'acInsuranceSum',
    name: 'labels.ocInsuranceSum',
    width: 'fill',
    component: {
      ref: AmountCellComponent,
      inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
        amount: data.item.comprehensiveInsurance
          ?.insuranceSum as OnyxAmount | null,
      }),
    },
  },
];
