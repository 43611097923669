import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxInformationHeadingComponent,
  OnyxNamePipe,
} from '@onyx/angular';
import { FleetIdentifierPipe } from '../../../../../../common/pipes/fleet-identifier.pipe';
import { I18nPipe } from '../../../../../../common/pipes/i18n.pipe';
import { OrderStatus } from '../../../../common/enums/order-status';
import { Order } from '../../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-realized-by',
  imports: [
    OnyxInformationHeadingComponent,
    OnyxDataWidgetComponent,
    FleetIdentifierPipe,
    TranslatePipe,
    OnyxNamePipe,
    I18nPipe,
  ],
  templateUrl: './order-modal-realized-by.component.html',
  styleUrl: './order-modal-realized-by.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalRealizedByComponent {
  protected readonly I18N = 'orders.orderModal';

  protected readonly OrderStatus = OrderStatus;

  public order = input.required<Order>();
}
