import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxIconButtonComponent } from '../../buttons/onyx-icon-button/onyx-icon-button.component';
import { OnyxIconComponent } from '../../icons/onyx-icon/onyx-icon.component';
import { OnyxIconBoxColor } from '../enums';

@Component({
  selector: 'onyx-icon-box',
  imports: [TranslatePipe, OnyxIconButtonComponent, OnyxIconComponent],
  templateUrl: './onyx-icon-box.component.html',
  styleUrl: './onyx-icon-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxIconBoxComponent {
  public color = input<`${OnyxIconBoxColor}`>(OnyxIconBoxColor.BLACK);
  public label = input<string | null>(null);
  public hasColoredBackground = input(false);
  public hasTransparentBackground = input(false);
  public hasShadow = input(false);
  public editCallback = input<() => void>();
  public showCallback = input<() => void>();
  public size = input(24);
  public gap = input(12);
}
