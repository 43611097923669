import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'fuzzy';
import { castArray, get, isFunction, isString } from 'lodash';
import { OnyxPaths } from '../components';
import { OnyxMaybeArray } from '../interfaces';

export type OnyxFilterExtract<T> =
  | OnyxMaybeArray<OnyxPaths<T> | ((item: T) => string)>
  | undefined;

@Pipe({
  name: 'onyxFilter',
  pure: true,
})
export class OnyxFilterPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform<T>(
    items: T[],
    query: string,
    extract: OnyxFilterExtract<T>,
  ): T[] {
    if (!query) return items;

    return filter(query, items, {
      extract: (item) => {
        const value = this.extractValue(item, extract);
        return isString(value) ? this.translateService.instant(value) : value;
      },
    }).map((result) => result.original);
  }

  private extractValue<T>(item: T, extract: OnyxFilterExtract<T>): string {
    extract = castArray(extract);
    return extract
      .map((extractor) => {
        if (isString(extractor)) {
          return get(item, extractor) ?? '';
        } else if (isFunction(extractor)) {
          return extractor(item);
        }
        return JSON.stringify(item);
      })
      .join(' ');
  }
}
