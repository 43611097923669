export enum OnyxMapRoutePointType {
  CAR_WASH = 'car-wash',
  CUSTOMS = 'customs',
  PARKING = 'parking',
  TRAIN = 'train',
  FERRY = 'ferry',
  UNLOADING = 'unloading',
  GAS_STATION = 'gas-station',
  TUNNEL = 'tunnel',
  LOADING = 'loading',
  OTHER = 'other',
}
