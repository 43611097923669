import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Injector,
  OnChanges,
  WritableSignal,
  computed,
  effect,
  input,
  isSignal,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { chain, isArray, isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { OnyxMaybeArray, OnyxSuggestion } from '../../../interfaces';

@Component({
  selector: 'onyx-suggestions',
  imports: [TranslatePipe, NgClass],
  templateUrl: './onyx-suggestions.component.html',
  styleUrl: './onyx-suggestions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxSuggestionsComponent<T> implements OnChanges {
  public control = input.required<
    AbstractControl | WritableSignal<OnyxMaybeArray<T>>
  >();
  public suggestions = input.required<OnyxSuggestion<T>[] | null>();
  public multiple = input(false);
  public disabled = input(false);

  protected suggestionsData = computed(() => {
    const value = this.value();
    if (!this.suggestions()) return;

    return this.suggestions()!.map((suggestion) => ({
      ...suggestion,
      disabled:
        this.disabled_() ||
        (isArray(value)
          ? value.includes(suggestion.value)
          : isEqual(value, suggestion.value)),
    }));
  });

  private value_ = signal<T | null>(null);
  protected get value() {
    return this.value_.asReadonly();
  }

  private disabled_ = signal(false);

  constructor(
    private injector: Injector,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnChanges(): void {
    effect(
      () => {
        this.update();

        const control = this.control();
        if (isSignal(control)) return;

        const statusChanges$ = (
          control.statusChanges as Observable<any> | undefined
        )?.pipe(takeUntilDestroyed(this.destroyRef));

        const valueChanges$ = (
          control.valueChanges as Observable<any> | undefined
        )?.pipe(takeUntilDestroyed(this.destroyRef));

        statusChanges$?.subscribe(() => this.update());
        valueChanges$?.subscribe(() => this.update());
      },
      { injector: this.injector },
    );

    effect(() => (isSignal(this.control()) ? this.update() : null), {
      injector: this.injector,
    });
  }

  protected setValue(value: T): void {
    const control = this.control();

    const updatedValue = this.multiple()
      ? chain(isSignal(control) ? control() : control.value)
          .defaultTo([])
          .concat(value)
          .value()
      : value;

    isSignal(control)
      ? control.set(updatedValue as T)
      : control.setValue(updatedValue);
  }

  private update(): void {
    const control = this.control();
    const value = isSignal(control) ? control() : control.value;
    const disabled = isSignal(control) ? this.disabled() : control.disabled;

    this.value_.set(value);
    this.disabled_.set(disabled);
  }
}
