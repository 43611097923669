import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxIconComponent, OnyxTooltipDirective } from '@onyx/angular';
import { chain } from 'lodash';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { Cabotage } from '../../../../../common/interfaces/common/cabotage';
import { I18nPipe } from '../../../../../common/pipes/i18n.pipe';
import { Fleet, SimplifiedFleet } from '../../interfaces/fleet';

@Component({
  selector: 'app-fleet-description-parameters',
  imports: [
    TranslatePipe,
    OnyxIconComponent,
    OnyxTooltipDirective,
    I18nPipe,
    UpperCasePipe,
  ],
  templateUrl: './fleet-description-parameters.component.html',
  styleUrl: './fleet-description-parameters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetDescriptionParametersComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public fleet = input.required<Fleet | SimplifiedFleet>();
  public cabotages = input(undefined, {
    transform: (cabotages: Cabotage[] | undefined) => {
      cabotages = chain(cabotages)
        .filter(({ value, limit }) => value > limit)
        .orderBy(({ value, limit }) => limit - value, 'desc')
        .value();
      if (!cabotages?.length) return null;
      return cabotages;
    },
  });
}
