import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxAmount,
  OnyxConfirmModalComponent,
  OnyxConfirmModalData,
  OnyxDatePipe,
  OnyxModalService,
  OnyxOptionsGroup,
  OnyxRoute,
  OnyxTime,
  OnyxTimePipe,
  OnyxTimeRange,
  OnyxToastService,
} from '@onyx/angular';
import { chain, isArray, isString, omit, sumBy, uniq } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { AuthService } from '../../../../auth/common/services/auth.service';
import { TIME_RANGE_ALL_DAY } from '../../../../common/constants/common/time-range-all-day';
import { VatRate } from '../../../../common/enums/vat-rate';
import { AmountHelper } from '../../../../common/helpers/amount.helper';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { BusinessHours } from '../../../../common/interfaces/common/business-hours';
import { HelperOptions } from '../../../../common/interfaces/utilities/helper-options';
import { BusinessHoursPipe } from '../../../../common/pipes/business-hours.pipe';
import { DurationPipe } from '../../../../common/pipes/duration.pipe';
import { FleetCategory } from '../../../fleet/common/enums/fleet-category';
import { SemiTrailerSize } from '../../../fleet/common/enums/semi-trailer-size';
import { FleetHelper } from '../../../fleet/common/helpers/fleet.helper';
import { SimplifiedFleet } from '../../../fleet/common/interfaces/fleet';
import { EmptyDistanceStrategy } from '../../../management-panel/engine-config/common/enums/empty-distance-strategy';
import { OrderForm } from '../../order-form/order-form.component';
import { OrderLoadingPointGoodForm } from '../../order-form/order-points-form/order-loading-point-form/order-loading-point-good-form/order-loading-point-good-form.component';
import {
  OrderModalComponent,
  OrderModalData,
} from '../../order-modal/order-modal.component';
import { OrdersRoute } from '../../orders.routes';
import { OrderPlanningMode } from '../enums/order-planning-mode';
import { OrderPointCategory } from '../enums/order-point-category';
import { OrderSemiTrailerSize } from '../enums/order-semi-trailer-size';
import { OrderStatus } from '../enums/order-status';
import { OrderTimeWindowType } from '../enums/order-time-window-type';
import { OrderTransitPointTimeWindowType } from '../enums/order-transit-point-time-window-type';
import { OrdersQueryParamKey } from '../enums/orders-query-param-key';
import { Order, SimplifiedOrder } from '../interfaces/order';
import { OrderLoadingPoint, OrderPoint } from '../interfaces/order-point';
import { OrdersService } from '../services/orders.service';

export interface OrderSettlementData {
  net: Pick<OnyxAmount, 'value' | 'currency'>;
  gross: Pick<OnyxAmount, 'value' | 'currency'>;
  vatRate: Pick<OnyxAmount, 'value' | 'currency'> & { vatRate: VatRate };
}

@Injectable({
  providedIn: 'root',
})
export class OrderHelper {
  constructor(
    private router: Router,
    private modalService: OnyxModalService,
    private durationPipe: DurationPipe,
    private authService: AuthService,
    private toastService: OnyxToastService,
    private ordersService: OrdersService,
    private translateService: TranslateService,
    private datePipe: OnyxDatePipe,
    private timePipe: OnyxTimePipe,
    private businessHoursPipe: BusinessHoursPipe,
  ) {}

  public getOptions(
    order: Order,
    options: HelperOptions,
  ): OnyxOptionsGroup<() => void>[] {
    const hasEdit = OrderHelper.hasEdit(order);
    const hasRouteEdit = this.hasRouteEdit(order);

    return [
      {
        options: [
          ...(hasEdit
            ? [
                {
                  name: 'labels.edit',
                  value: () => this.edit(order.uuid, { options }),
                  leftIcon: { name: 'edit', size: 16 },
                  leftIconColor: 'gray-alt',
                } as const,
                {
                  name: 'labels.planRoute',
                  value: () =>
                    this.edit(order.uuid, {
                      options,
                      params: {
                        [OrdersQueryParamKey.PLAN_ROUTE]: true,
                      },
                    }),
                  leftIcon: { name: 'route', size: 16 },
                  leftIconColor: 'gray-alt',
                  ...(!hasRouteEdit &&
                    ({
                      rightIcon: { name: 'locked', size: 16 },
                      rightIconColor: 'gray-alt',
                      rightIconTooltip: 'labels.routeBlocked',
                      disabled: true,
                    } as const)),
                } as const,
              ]
            : []),
        ],
      },
      {
        options: [
          {
            name: 'labels.share',
            value: () => this.share(order),
            leftIcon: { name: 'share', size: 16 },
            leftIconColor: 'gray-alt',
          } as const,
          {
            name: 'labels.showOnGantt',
            value: () => this.showOnGantt(order, options),
            leftIcon: { name: 'menu-gantt', size: 16 },
            leftIconColor: 'gray-alt',
          } as const,
          ...(OrderHelper.hasShowOnMap(order)
            ? [
                {
                  name: 'labels.showOnMap',
                  value: () => this.showOnMap(order, options),
                  leftIcon: { name: 'menu-map', size: 16 },
                  leftIconColor: 'gray-alt',
                } as const,
              ]
            : []),
        ],
      },
      {
        options: [
          ...([OrderStatus.VEHICLE_SEARCH, OrderStatus.TO_ACCEPT].includes(
            order.status.value,
          )
            ? [
                {
                  name: 'labels.chooseVehicle',
                  value: () => {
                    this.openModal(order, {
                      planningMode: OrderPlanningMode.MANUAL,
                    });
                    CommonHelper.handleOptions(order, options);
                  },
                  leftIcon: { name: 'vehicle', size: 16 },
                  leftIconColor: 'gray-alt',
                } as const,
              ]
            : []),
        ],
      },
      {
        options: [
          ...(!OrderHelper.hasApprovedVehicle(order)
            ? [
                {
                  name: 'orders.actions.deleteOrder',
                  value: () => this.delete(order, options),
                  leftIcon: { name: 'delete-fill', size: 16 },
                  leftIconColor: 'gray-alt',
                } as const,
              ]
            : []),
        ],
      },
    ].filter((group) => group.options.length !== 0);
  }

  public openModal(
    order: OrderModalData['order'],
    options?: Omit<OrderModalData, 'order'>,
  ): void {
    this.modalService.open<OrderModalData>(OrderModalComponent, {
      order,
      ...options,
    });
  }

  public hasRouteEdit(order: Order): boolean {
    if (!OrderHelper.hasEdit(order)) return false;
    if (!order.route.isBlocked) return true;

    const whitelist = chain(order)
      .thru((order) => [order.author, order.route.author])
      .map((author) => author.uuid)
      .uniq()
      .value();
    const employeeUuid = this.authService.user()!.employeeUuid;

    return whitelist.includes(employeeUuid);
  }

  public edit(
    uuid: string,
    data: {
      options: HelperOptions;
      params?: Partial<{
        [OrdersQueryParamKey.PLAN_ROUTE]: boolean;
      }>;
    },
  ): void {
    const route = this.router.createUrlTree(
      [`${OrdersRoute.EDIT_ORDER.replace(':uuid', uuid)}`],
      { queryParams: data.params },
    );
    this.router.navigateByUrl(route);
    CommonHelper.handleOptions(uuid, data.options);
  }

  public share(order: Order): void {
    ActionHelper.share(
      OrdersRoute.ORDER_CARD.replace(':uuid', order.uuid),
      this.toastService,
    );
  }

  public showOnGantt(order: Order, options: HelperOptions): void {
    CommonHelper.showOnGantt(this.router, 'order', order.uuid);
    CommonHelper.handleOptions(order.uuid, options);
  }

  public showOnMap(order: Order, options: HelperOptions): void {
    const vehicle = OrderHelper.getOrderVehicle(order);
    if (!OrderHelper.hasShowOnMap(order) || !vehicle) return;

    CommonHelper.showOnMap(this.router, vehicle.uuid);
    CommonHelper.handleOptions(order.uuid, options);
  }

  public delete(order: Order, options: HelperOptions): void {
    this.modalService
      .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
        heading: this.translateService.instant('orders.delete.heading', {
          identifier: order.identifier,
        }),
        message: 'orders.delete.message',
        confirmButtonText: 'labels.delete',
        confirmButtonColor: 'red',
      })
      .result.subscribe((ok) => {
        if (!ok) return;

        this.ordersService.deleteOrder(order.uuid).subscribe({
          next: () => {
            this.toastService.showSuccess('orders.delete.success');
            CommonHelper.handleOptions(order, options);
          },
          error: (response) =>
            ValidationHelper.handleUnexpectedError(response, this.toastService),
        });
      });
  }

  public getPaymentDelay(order: Order): string | null {
    if (!order.payments?.invoice) return null;

    const paymentTerm = DateTime.fromISO(
      order.payments.invoice!.paymentTerm,
    ).startOf('day');

    switch (order.status.value) {
      case OrderStatus.WAITING_FOR_PAYMENT:
      case OrderStatus.PARTIALLY_PAID: {
        const paymentDelay = this.durationPipe.transform(paymentTerm, {
          end: DateTime.now().startOf('day'),
          units: ['d'],
          size: 'xs',
        });
        return paymentDelay !== '-' ? paymentDelay : null;
      }

      case OrderStatus.PAID: {
        const paidDate = DateTime.fromISO(order.timestamps.paidAt!).startOf(
          'day',
        );
        const paymentDelay = this.durationPipe.transform(paymentTerm, {
          end: paidDate,
          units: ['d'],
          size: 'xs',
        });

        return paymentDelay !== '-' ? paymentDelay : null;
      }

      default:
        return null;
    }
  }

  public getTimeWindows(
    point: OrderPoint,
    businessHoursFormat?: 'short' | 'long',
  ) {
    const formatTimeWindow = (date: string, time: OnyxTime | OnyxTimeRange) =>
      `${this.timePipe.transform(time)} · ${this.datePipe.transform(date, 'date-dot')}`;

    const formatBusinessHours = (businessHours: BusinessHours) => [
      this.businessHoursPipe.transform(
        businessHours,
        'weekday',
        businessHoursFormat,
      ),
      this.businessHoursPipe.transform(
        businessHours,
        'saturday',
        businessHoursFormat,
      ),
      this.businessHoursPipe.transform(
        businessHours,
        'sunday',
        businessHoursFormat,
      ),
    ];

    switch (point.category) {
      case OrderPointCategory.CHECKPOINT: {
        const businessHours = point.businessHours;
        const isAllTime = Object.values(businessHours).every(
          (range) => range === TIME_RANGE_ALL_DAY,
        );

        return {
          label: 'labels.openingHours',
          value: isAllTime ? ['24/7'] : formatBusinessHours(businessHours),
        };
      }

      case OrderPointCategory.TRANSIT: {
        return {
          label: 'labels.fix',
          value:
            point.timeWindow.type === OrderTransitPointTimeWindowType.ALL_TIME
              ? ['24/7']
              : [
                  formatTimeWindow(
                    point.timeWindow.date,
                    point.timeWindow.time,
                  ),
                ],
        };
      }

      default: {
        if (point.timeWindows.type === OrderTimeWindowType.FIX) {
          return {
            label: 'labels.fix',
            value: [
              formatTimeWindow(
                point.timeWindows.windows[0].date,
                point.timeWindows.windows[0].time,
              ),
            ],
          };
        }

        const isInterval =
          point.timeWindows.type === OrderTimeWindowType.INTERVAL;
        return {
          label: isInterval ? 'labels.interval' : 'labels.openingHours',
          value: point.timeWindows.windows.map((timeWindow) =>
            formatTimeWindow(timeWindow.date, timeWindow.timeRange),
          ),
        };
      }
    }
  }

  public static getFirstLoadingPoint(points: OrderPoint[]): OrderLoadingPoint {
    return points.find(
      (point) => point.category === OrderPointCategory.LOADING,
    )!;
  }

  public static getLastUnloadingPoint(points: OrderPoint[]) {
    return points
      .reverse()
      .find((point) => point.category === OrderPointCategory.UNLOADING)!;
  }

  public static getLoadingPointDeadline(
    loadingPoint: OrderLoadingPoint,
  ): string {
    const { type, windows } = loadingPoint.timeWindows;
    if (type === OrderTimeWindowType.FIX) {
      return `${windows[0].date}T${windows[0].time}`;
    }

    const lastTimeWindow = windows[windows.length - 1];
    return `${lastTimeWindow.date}T${lastTimeWindow.timeRange.to}`;
  }

  public static hasShowOnMap(order: Order): boolean {
    const vehicle = OrderHelper.getOrderVehicle(order);
    return (
      !OrderHelper.isOrderFinished(order) &&
      vehicle != null &&
      FleetHelper.hasTelematics(vehicle)
    );
  }

  public static hasApprovedVehicle(order: Order | OrderStatus): boolean {
    const status = isString(order) ? order : order.status.value;
    return ![OrderStatus.VEHICLE_SEARCH, OrderStatus.TO_ACCEPT].includes(
      status,
    );
  }

  public static isOrderPending(order: Order | OrderStatus): boolean {
    const status = isString(order) ? order : order.status.value;
    return [
      OrderStatus.VEHICLE_SEARCH,
      OrderStatus.TO_DO,
      OrderStatus.TO_ACCEPT,
    ].includes(status);
  }

  public static isOrderFinished(order: Order | OrderStatus): boolean {
    const status = isString(order) ? order : order.status.value;
    return !this.isOrderPending(status) && status !== OrderStatus.IN_PROGRESS;
  }

  public static getEmptyDistance(order: Order): number | null {
    return (
      order.realizationData?.emptyDistance ??
      order.assignedData?.emptyDistance ??
      order.manualProposalData?.result?.emptyDistance ??
      order.engineProposalData?.emptyDistance ??
      null
    );
  }

  public static getOrderVehicle(order: Order): SimplifiedFleet | null {
    return (
      order.engineProposalData?.vehicle ??
      order.manualProposalData?.data?.vehicle ??
      order.assignedData?.vehicle ??
      null
    );
  }

  public static getDistance(order: Order): number {
    return (
      order.realizationData?.elapsedDistance ?? order.route.summary.distance
    );
  }

  public static getDuration(order: Order): number {
    return (
      order.realizationData?.elapsedDuration ?? order.route.summary.duration
    );
  }

  public static getTotalDistance(order: Order): number {
    const emptyDistance = OrderHelper.getEmptyDistance(order);
    const distance = OrderHelper.getDistance(order);

    return (distance + (emptyDistance ?? 0)) / 1000;
  }

  public static getEmptyDistanceDuration(order: Order): number | null {
    return (
      order.realizationData?.emptyDistanceDuration ??
      order.assignedData?.emptyDistanceDuration ??
      order.manualProposalData?.result?.emptyDistanceDuration ??
      order.engineProposalData?.emptyDistanceDuration ??
      null
    );
  }

  public static getTotalDuration(order: Order): number {
    const emptyDistanceDuration = OrderHelper.getEmptyDistanceDuration(order);
    const duration = OrderHelper.getDuration(order);

    return (emptyDistanceDuration ?? 0) + duration;
  }

  public static getRatePerKm(order: Order): number {
    return (
      order.price.defaultCurrencyValue / OrderHelper.getTotalDistance(order)
    );
  }

  public static getPricePerDay(order: Order): number {
    const duration = OrderHelper.getDuration(order);
    const emptyDistanceDuration = OrderHelper.getEmptyDistanceDuration(order);

    const days = Duration.fromObject({
      seconds: duration + (emptyDistanceDuration ?? 0),
    }).as('days');

    return order.price.defaultCurrencyValue / days;
  }

  public static getSearchingTrailerTypes(order: Order): string[] | null {
    const basicInformation = order.basicInformation;
    const {
      loadedSemiTrailerUuid,
      loadedSemiTrailerRegistrationNumber,
      minSemiTrailerSize,
    } = basicInformation;
    if (
      loadedSemiTrailerUuid ||
      loadedSemiTrailerRegistrationNumber ||
      minSemiTrailerSize !== OrderSemiTrailerSize.AUTO
    ) {
      return basicInformation.vehicleTypes;
    }

    return basicInformation.vehicleTypes;
  }

  public static getSearchingVehicleData(
    order: Order,
    translateService: TranslateService,
  ) {
    const basicInformation = order.basicInformation;

    const areAllVans = basicInformation.vehicleTypes.every((type) =>
      type.startsWith(`${FleetCategory.VAN}-`),
    );
    const parameters = CommonHelper.getSortedParameters(
      order.parameters,
      translateService,
    );

    if (areAllVans) {
      return {
        iconName: FleetCategory.VAN as const,
        categories: [FleetCategory.VAN],
        isLowDeck: null,
        types: basicInformation.vehicleTypes,
        parameters,
      };
    }

    const {
      loadedSemiTrailerUuid,
      loadedSemiTrailerRegistrationNumber,
      minSemiTrailerSize,
    } = basicInformation;
    if (
      loadedSemiTrailerUuid ||
      loadedSemiTrailerRegistrationNumber ||
      minSemiTrailerSize !== OrderSemiTrailerSize.AUTO
    ) {
      const isLowDeck =
        loadedSemiTrailerUuid?.additionalParameters?.semiTrailerSize ===
          SemiTrailerSize.MEGA ||
        minSemiTrailerSize === OrderSemiTrailerSize.MEGA;

      return {
        iconName: FleetCategory.SEMI_TRUCK as const,
        categories: [FleetCategory.SEMI_TRUCK],
        isLowDeck,
        types: null,
        parameters,
      };
    }

    return {
      iconName: 'vehicle' as const,
      categories: [
        FleetCategory.SEMI_TRUCK,
        FleetCategory.STRAIGHT_TRUCK,
        FleetCategory.VAN,
      ],
      isLowDeck: null,
      types: basicInformation.vehicleTypes,
      parameters,
    };
  }

  public static getPaidPercentage(order: Order): number | null {
    const { status, payments, price } = order;
    if (status.value !== OrderStatus.PARTIALLY_PAID) return null;

    const totalPayments = sumBy(
      payments.history,
      (payment) => payment.value.value,
    );
    return Math.max((totalPayments / price.value) * 100, 100);
  }

  public static getSettlementData(
    order: Order,
    variant: 'order' | 'outsourcing' = 'order',
  ): OrderSettlementData[] {
    const { defaultCurrencyValue, defaultCurrency, value, currency } =
      order.price;
    const outsourcingData = order.outsourcingData;
    const isOutsourcing = variant === 'outsourcing';

    const vatRate = (() =>
      isOutsourcing && outsourcingData
        ? outsourcingData.vatRate
        : order.basicInformation.vatRate)();
    const vatRateValue = (() => {
      if (isOutsourcing && outsourcingData) {
        const outsourcingVatRate = outsourcingData.vatRate;
        return CommonHelper.isNumeric(outsourcingVatRate)
          ? +outsourcingVatRate
          : null;
      }

      return CommonHelper.isNumeric(vatRate) ? +vatRate : null;
    })();

    const calculateSettlementEntry = (
      netValue: number,
      currency: string,
    ): OrderSettlementData => {
      const vatAmount = vatRateValue ? (netValue * vatRateValue) / 100 : 0;
      const grossValue = netValue + vatAmount;

      return {
        net: { value: netValue, currency },
        gross: { value: grossValue, currency },
        vatRate: {
          value: vatAmount,
          currency,
          vatRate: vatRate,
        },
      };
    };

    if (isOutsourcing && outsourcingData) {
      const { value, currency } = outsourcingData.rate;
      return [calculateSettlementEntry(value, currency)];
    }

    return [
      calculateSettlementEntry(defaultCurrencyValue, defaultCurrency),
      ...(defaultCurrency !== currency
        ? [calculateSettlementEntry(value, currency)]
        : []),
    ];
  }

  public static hasEmptyDistanceError(
    emptyDistance: number,
    distance: number,
    authService: AuthService,
  ): boolean {
    const emptyKilometers = emptyDistance / 1_000;
    const engineConfig = authService.engineConfig()!.emptyDistance;

    const invalidDistanceStrategy =
      emptyKilometers > (engineConfig.distanceThreshold ?? Infinity);
    const invalidPercentageStrategy =
      emptyKilometers >
      ((distance / 1000) * (engineConfig.percentageThreshold ?? Infinity)) /
        100;

    switch (engineConfig.strategy) {
      case EmptyDistanceStrategy.VALUE:
        return invalidDistanceStrategy;
      case EmptyDistanceStrategy.PERCENTAGE:
        return invalidPercentageStrategy;
      case EmptyDistanceStrategy.PERCENTAGE_AND_VALUE:
        return invalidPercentageStrategy && invalidDistanceStrategy;
      case EmptyDistanceStrategy.PERCENTAGE_OR_VALUE:
        return invalidPercentageStrategy || invalidDistanceStrategy;
      default:
        return false;
    }
  }

  public static fromDto(dto: Order): OrderForm {
    return {
      basicInformation: {
        ...dto.basicInformation,
        sender: dto.basicInformation.sender.uuid,
        ourBranch: dto.basicInformation.ourBranch as any, // TEMP: Replace by Branch.uuid when available in the API
        senderBranch: dto.basicInformation.senderBranch as any, // TEMP: Replace by Branch.uuid when available in the API
        loadedSemiTrailer:
          dto.basicInformation.loadedSemiTrailerUuid?.uuid ??
          dto.basicInformation.loadedSemiTrailerRegistrationNumber ??
          null,
      },
      parameters: {
        ...dto.parameters,
        temperatureRange: {
          from: dto.parameters.temperatureRange?.from ?? null,
          to: dto.parameters.temperatureRange?.to ?? null,
        },
        other: dto.parameters.other?.map((parameter) => parameter.uuid) ?? null,
      },
      documents: dto.documents,
      note: { content: dto.note?.content ?? null },
      points: dto.points.map((point) => {
        if (point.category !== OrderPointCategory.LOADING) return point;
        return {
          ...point,
          goods: point.goods.map(
            (good): OrderLoadingPointGoodForm => ({
              ...good,
              type: good.type?.uuid ?? good.typeName,
              unit: good.unit.uuid,
            }),
          ),
        };
      }),
      route: dto.route ? omit(dto.route, ['author', 'updatedAt']) : null,
    };
  }

  public static hasEdit(order: Order | OrderStatus): boolean {
    const status = isString(order) ? order : order.status.value;
    return ![OrderStatus.CANCELED, OrderStatus.ARCHIVED].includes(status);
  }

  public static isOrderType(order: SimplifiedOrder | Order): order is Order {
    return 'status' in order;
  }

  public static getNextPoint(
    point: OrderPoint,
    order: Order,
  ): OrderPoint | null {
    const orderPoints = order.points;
    const pointIndex = orderPoints.findIndex(
      (orderPoint) => orderPoint.uuid === point.uuid,
    );
    if (pointIndex === -1) return null;

    return orderPoints.at(pointIndex + 1) ?? null;
  }

  public static getDeadline(
    point: OrderPoint,
    order: Order,
    enableEstimations = true,
  ): DateTime | null {
    const estimatedDeadline = point.estimations?.deadlineTime;
    if (estimatedDeadline && enableEstimations) {
      return DateTime.fromISO(estimatedDeadline);
    }

    const getNext = (): [OrderPoint, DateTime] | [null, null] => {
      const nextPoint = OrderHelper.getNextPoint(point, order);
      if (!nextPoint) return [null, null];

      const nextDeadline = OrderHelper.getDeadline(nextPoint, order);
      if (!nextDeadline) return [null, null];

      return [nextPoint, nextDeadline];
    };

    switch (point.category) {
      case OrderPointCategory.CHECKPOINT: {
        const [_, nextDeadline] = getNext();
        if (!nextDeadline) return null;

        return chain(nextDeadline)
          .thru((nextDeadline) =>
            Array.from({ length: 4 }).map((_, index) =>
              nextDeadline.minus({ days: index }),
            ),
          )
          .map((date) => {
            let openingHours;
            if (date.weekday === 7) {
              openingHours = point.businessHours.sunday;
            } else if (date.weekday === 6) {
              openingHours = point.businessHours.saturday;
            } else {
              openingHours = point.businessHours.weekday;
            }
            if (!openingHours) return null;

            const timeFrom = DateTime.fromISO(openingHours.from);
            const timeTo = DateTime.fromISO(openingHours.to);

            const from = date.set({
              hour: timeFrom.hour,
              minute: timeFrom.minute,
            });
            const to = date.set({
              hour: timeTo.hour,
              minute: timeTo.minute,
            });

            return { from, to };
          })
          .filter((interval) => interval != null)
          .thru(
            (intervals) =>
              intervals.find(
                (interval) =>
                  interval.to >=
                  DateTime.now()
                    .plus({ seconds: point.route?.summary.duration ?? 0 })
                    .plus({ seconds: point.serviceTime ?? 0 }),
              ) ?? intervals.at(-1),
          )
          .thru((interval) =>
            interval.to.minus({ seconds: point.serviceTime ?? 0 }),
          )
          .value();
      }

      case OrderPointCategory.TRANSIT: {
        const timeWindow = point.timeWindow;

        if (timeWindow.type === OrderTransitPointTimeWindowType.ALL_TIME) {
          const [nextPoint, nextDeadline] = getNext();
          if (!nextDeadline) return null;

          return nextDeadline
            .minus({ seconds: nextPoint.route?.summary.duration ?? 0 })
            .minus({ seconds: point.serviceTime ?? 0 });
        }

        return DateTime.fromISO(`${timeWindow.date}T${timeWindow.time}`);
      }

      default: {
        const timeWindows = point.timeWindows;

        if (
          timeWindows.type === OrderTimeWindowType.INTERVAL ||
          timeWindows.type === OrderTimeWindowType.OPENING_HOURS
        ) {
          return chain(timeWindows.windows)
            .map((interval) =>
              DateTime.fromISO(`${interval.date}T${interval.timeRange.to}`),
            )
            .thru(
              (intervals) =>
                intervals.find(
                  (interval) =>
                    interval >=
                    DateTime.now()
                      .plus({ seconds: point.route?.summary.duration ?? 0 })
                      .plus({ seconds: point.serviceTime }),
                ) ?? intervals.at(-1),
            )
            .thru((interval) => interval.minus({ seconds: point.serviceTime }))
            .value();
        }

        return DateTime.fromISO(
          `${timeWindows.windows[0].date}T${timeWindows.windows[0].time}`,
        );
      }
    }
  }

  public static getRoute(order: Order | OrderPoint[]): OnyxRoute {
    const points = isArray(order) ? order : order.points;
    const routePoints = points.map((point) => point.route);

    return {
      points: routePoints,
      summary: routePoints
        .map((point) => point.summary)
        .slice(1)
        .reduce(
          (result, summary) => ({
            ...result,
            distance: result.distance + summary.distance,
            duration: result.duration + summary.duration,
            countries: uniq([...result.countries, ...summary.countries]),
            adBlueConsumption:
              result.adBlueConsumption + summary.adBlueConsumption,
            adBlueCost:
              result.adBlueCost && summary.adBlueCost
                ? AmountHelper.add(result.adBlueCost, summary.adBlueCost)
                : summary.adBlueCost,
            fuelConsumption: result.fuelConsumption + summary.fuelConsumption,
            fuelCost:
              result.fuelCost && summary.fuelCost
                ? AmountHelper.add(result.fuelCost, summary.fuelCost)
                : summary.fuelCost,
            totalFuelCost:
              result.totalFuelCost && summary.totalFuelCost
                ? AmountHelper.add(result.totalFuelCost, summary.totalFuelCost)
                : summary.totalFuelCost,
            tollsCost: AmountHelper.add(result.tollsCost, summary.tollsCost),
            tolls: chain([...result.tolls, ...summary.tolls])
              .groupBy((toll) => `${toll.countryCode}-${toll.systemName}`)
              .map((group) => {
                const { countryCode, systemName } = group[0];
                return {
                  countryCode,
                  systemName,
                  cost: group
                    .slice(1)
                    .reduce(
                      (sum, toll) => AmountHelper.add(sum, toll.cost),
                      group[0].cost,
                    ),
                };
              })
              .value(),
            totalCost: AmountHelper.add(result.totalCost, summary.totalCost),
          }),
          routePoints[0].summary,
        ),
    };
  }
}
