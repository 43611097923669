import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { chain } from 'lodash';
import { map, Observable } from 'rxjs';
import { PartialDeep } from 'type-fest';
import { FleetValidationByType } from '../../../../common/interfaces/validation/fleet-validation';
import { CacheService } from '../../../../common/services/cache.service';
import { ValidationService } from '../../../../common/services/validation.service';
import { OrderForm } from '../../order-form/order-form.component';
import { OrderPointForm } from '../../order-form/order-points-form/order-point-form/order-point-form.component';
import { OrderPointCategory } from '../enums/order-point-category';
import { OrderStateKey } from '../enums/order-state-key';
import { OrdersStorageKey } from '../enums/orders-storage-key';
import { OrderHelper } from '../helpers/order.helper';
import { Order } from '../interfaces/order';

export const addOrderResolver: ResolveFn<
  Observable<{
    form: PartialDeep<OrderForm>;
    fleetValidation: FleetValidationByType;
  }>
> = () => {
  const router = inject(Router);
  const cacheService = inject(CacheService);
  const validationService = inject(ValidationService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const template: Order | undefined = state?.[OrderStateKey.TEMPLATE];

  return validationService.getFleetValidationByType().pipe(
    map((fleetValidation) => {
      if (template) {
        return {
          fleetValidation,
          form: chain(template)
            .thru((dto) => OrderHelper.fromDto(dto))
            .thru((form) => ({
              ...form,
              points: form.points.map((item) => {
                const point = item as OrderPointForm;
                if ('referenceNumber' in point) {
                  point.referenceNumber = null;
                }
                if (point.category === OrderPointCategory.LOADING) {
                  point.goods = point.goods.map((good) => ({
                    ...good,
                    referenceNumber: null,
                    statisticalNumber: null,
                  }));
                }
                return point;
              }),
            }))
            .value(),
        };
      }

      const cache = cacheService.get<PartialDeep<OrderForm>>(
        OrdersStorageKey.ADD_ORDER_FORM,
        { keepOnNavigation: true },
      );
      return {
        fleetValidation,
        form: cache ?? {},
      };
    }),
  );
};
