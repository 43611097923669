import { HttpErrorResponse } from '@angular/common/http';
import { WritableSignal } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxComponentInputs,
  OnyxDropdownOptions,
  OnyxMaybeArray,
  OnyxOption,
  OnyxToastOverride,
  OnyxToastService,
  OnyxToastType,
} from '@onyx/angular';
import { captureException } from '@sentry/angular-ivy';
import { castArray, chain } from 'lodash';
import { ValidationTooltipComponent } from '../components/tooltips/validation-tooltip/validation-tooltip.component';
import { DictionaryCode } from '../enums/dictionary-code';
import { ValidationSeverity } from '../enums/validation-severity';
import { ValidationField } from '../interfaces/validation/validation-field';
import { ValidationIssue } from '../interfaces/validation/validation-issue';
import { ValidationResult } from '../interfaces/validation/validation-result';
import { DictionariesService } from '../services/dictionaries.service';

export class ValidationHelper {
  public static toggleControls(
    controls: OnyxMaybeArray<AbstractControl>,
    enabled: boolean,
    reset = true,
  ): void {
    enabled
      ? this.enableControls(controls)
      : this.disableControls(controls, reset);
  }

  public static enableControls(controls: OnyxMaybeArray<AbstractControl>) {
    for (const control of castArray(controls)) {
      control.enable();
    }
  }

  public static disableControls(
    controls: OnyxMaybeArray<AbstractControl>,
    reset = true,
  ) {
    for (const control of castArray(controls)) {
      control.disable();
      if (reset) FormHelper.reset(control);
    }
  }

  public static toggleRequiredValidator(
    controls: OnyxMaybeArray<AbstractControl>,
    required: boolean,
  ): void {
    required
      ? this.addRequiredValidator(controls)
      : this.removeRequiredValidator(controls);
  }

  public static addRequiredValidator(
    controls: OnyxMaybeArray<AbstractControl>,
  ) {
    for (const control of castArray(controls)) {
      control.addValidators([Validators.required]);
      control.updateValueAndValidity();
    }
  }

  public static removeRequiredValidator(
    controls: OnyxMaybeArray<AbstractControl>,
  ) {
    for (const control of castArray(controls)) {
      control.removeValidators([Validators.required]);
      control.updateValueAndValidity();
    }
  }

  public static checkValidity(
    form: AbstractControl,
    toastService: OnyxToastService | null,
  ): boolean {
    if (form.valid) return true;

    FormHelper.submit(form);
    toastService?.showCustom(OnyxToastType.INVALID_DATA);
    return false;
  }

  public static updateControl(
    control: AbstractControl,
    field: ValidationField,
  ): void {
    switch (field.type) {
      case 'conditional':
        break;

      case 'forbidden':
        control.disable();
        FormHelper.reset(control);
        break;

      case 'optional':
      case 'optional-in-list':
        control.enable();
        control.removeValidators(Validators.required);
        break;

      case 'required':
      case 'required-in-list':
        control.enable();
        control.addValidators(Validators.required);
        break;

      case 'required-in-range':
        control.enable();
        control.addValidators([
          Validators.required,
          Validators.min(field.min),
          Validators.max(field.max),
        ]);
        break;
    }

    control.updateValueAndValidity();
  }

  public static updateListControl(
    control: AbstractControl,
    field: ValidationField,
    translateService: TranslateService,
    dictionariesService: DictionariesService,
    dictionaryCode: DictionaryCode,
    options: WritableSignal<OnyxDropdownOptions<unknown> | null>,
    multiple: boolean,
  ): void {
    this.updateControl(control, field);
    options.set(null);

    if (field.type === 'forbidden') return;

    if (
      field.type === 'required-in-list' ||
      field.type === 'optional-in-list'
    ) {
      options.set(
        field.values.map((value) => {
          const key = `${dictionaryCode}.${value}`;

          let name = translateService.instant(key);
          if (name === key) name = value;

          return { name, value };
        }),
      );

      if (field.values.length === 1) {
        control.setValue(multiple ? field.values : field.values[0]);
        control.disable();
      } else {
        const controlValue = control.value;
        if (multiple) {
          control.setValue(
            controlValue?.filter((value: any) =>
              field.values.includes(value),
            ) ?? null,
          );
        } else if (!field.values.includes(controlValue)) {
          control.setValue(null);
        }
      }
    } else {
      dictionariesService
        .getDictionary(dictionaryCode)
        .subscribe((dictionary) => options.set(dictionary));
    }
  }

  public static handleUnexpectedError(
    response: HttpErrorResponse,
    toastService: OnyxToastService,
    toastOverride?: OnyxToastOverride,
  ) {
    // eslint-disable-next-line no-console
    console.error(response);
    captureException(response);
    toastService.showCustom(OnyxToastType.SERVER_ERROR, toastOverride);
  }

  public static mapIssues<T = unknown>(
    issues: ValidationIssue[],
    option?: OnyxOption<T>,
  ): ValidationResult<T> {
    return chain(issues)
      .orderBy((issue) => issue.severity === ValidationSeverity.ERROR, 'desc')
      .thru((issues) => ({
        severity: issues.at(0)?.severity ?? null,
        issues,
        option: option ? ValidationHelper.mapOption(option, issues) : undefined,
      }))
      .value();
  }

  private static mapOption<T = unknown>(
    option: OnyxOption<T>,
    issues: ValidationIssue[],
  ): OnyxOption<T> {
    const severity = issues.at(0)?.severity ?? null;
    return {
      ...option,
      ...(issues.length && {
        rightIcon: {
          name: severity === ValidationSeverity.ERROR ? 'clear' : 'alert',
          size: 16,
        },
        rightIconColor: severity === ValidationSeverity.ERROR ? 'red' : 'black',
        rightIconTooltip: {
          ref: ValidationTooltipComponent,
          inputs: {
            issues,
          } satisfies OnyxComponentInputs<ValidationTooltipComponent>,
        },
        disabled: severity === ValidationSeverity.ERROR,
      }),
    };
  }
}
