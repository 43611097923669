<div
  class="data-widget {{ color() }}"
  [class.selected]="selected()"
  [style.gap.px]="gap()"
  [style.padding.px]="isNumber(padding()) ? padding() : undefined"
  [style.padding]="isNumber() ? undefined : padding()"
>
  @if (supheading(); as supheading) {
    <small class="f-medium-3">{{ supheading | translate }}</small>
  }

  <div class="heading f-h4">
    @if (headingIcon(); as icon) {
      <onyx-icon
        class="{{ icon.color }}"
        [name]="icon.name"
        [size]="icon.size"
        [frame]="icon.frame"
      ></onyx-icon>
    }

    <ng-content select="[heading]"></ng-content>
  </div>

  <div class="heading f-h4">
    <ng-content select="[secondaryHeading]"></ng-content>
  </div>

  <small class="f-medium-3">
    <ng-content select="[description]"></ng-content>
  </small>

  @if (showDivider()) {
    <div class="divider"></div>
  }

  <ng-content></ng-content>
</div>
