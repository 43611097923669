import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxColor } from '../../badges';

@Component({
  selector: 'onyx-progress',
  imports: [],
  templateUrl: './onyx-progress.component.html',
  styleUrl: './onyx-progress.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxProgressComponent {
  public value = input(0);
  public max = input(100);
  public color = input<OnyxColor>('green');
  public disabled = input(false);
}
