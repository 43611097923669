import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxInformationHeadingComponent,
  OnyxModalService,
  OnyxOptionsGroup,
  OnyxTableComponent,
  OnyxTableData,
  OnyxToastService,
} from '@onyx/angular';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { I18nPipe } from '../../../../../common/pipes/i18n.pipe';
import { ORDER_MODAL_PAYMENTS_HISTORY_COLUMNS } from '../../../common/constants/order-modal-payments-history-columns';
import { Order } from '../../../common/interfaces/order';
import { OrdersService } from '../../../common/services/orders.service';
import { PaymentsHistoryData } from '../order-modal-payment-form-container/order-modal-payment-form-container.component';
import {
  OrderModalPaymentEditComponent,
  OrderPaymentEditModalData,
} from './order-modal-payment-edit/order-modal-payment-edit.component';

@Component({
  selector: 'app-order-modal-payments-history',
  imports: [
    OnyxTableComponent,
    OnyxInformationHeadingComponent,
    TranslatePipe,
    I18nPipe,
  ],
  templateUrl: './order-modal-payments-history.component.html',
  styleUrl: './order-modal-payments-history.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPaymentsHistoryComponent {
  protected readonly I18N = 'orders.orderModal';
  protected readonly ORDER_MODAL_PAYMENTS_HISTORY_COLUMNS =
    ORDER_MODAL_PAYMENTS_HISTORY_COLUMNS;

  public order = input.required<Order>();

  protected paymentsHistoryData = computed<PaymentsHistoryData[]>(() =>
    this.order().payments.history.map((payment) => {
      const percentage = (payment.value.value / this.order().price.value) * 100;
      return { ...payment, percentage };
    }),
  );

  constructor(
    private ordersService: OrdersService,
    private toastService: OnyxToastService,
    private modalService: OnyxModalService,
  ) {}

  protected getPaymentsOptions(
    data: OnyxTableData<PaymentsHistoryData>,
  ): OnyxOptionsGroup<() => void>[] {
    return [
      {
        options: [
          {
            name: 'labels.edit',
            value: () =>
              this.modalService.open<OrderPaymentEditModalData>(
                OrderModalPaymentEditComponent,
                { order: this.order(), paymentsHistoryData: data.item },
              ),
            leftIcon: { name: 'edit', size: 16 },
            leftIconColor: 'gray',
          },
          {
            name: 'labels.delete',
            value: () => this.deletePayment(data.item.uuid),
            leftIcon: { name: 'delete', size: 16 },
            leftIconColor: 'gray',
          },
        ],
      },
    ];
  }

  private deletePayment(uuid: string) {
    this.ordersService.deletePayment(this.order().uuid, uuid).subscribe({
      next: () =>
        this.toastService.showSuccess(`${this.I18N}.toasts.paymentDeleted`, {
          keepOnNavigation: true,
        }),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }
}
