import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxModalComponent,
  OnyxTextareaComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { OrderRejectReason } from '../../../../common/enums/order-reject-reason';
import { OrdersService } from '../../../../common/services/orders.service';

export enum OtherReasonModalMode {
  REJECT = 'reject',
  CHANGE = 'change',
}

export interface OtherReasonModalData {
  uuid: string;
  mode: OtherReasonModalMode;
}

@Component({
  selector: 'app-other-reason-modal',
  imports: [
    OnyxModalComponent,
    TranslatePipe,
    OnyxTextareaComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
  ],
  templateUrl: './other-reason-modal.component.html',
  styleUrl: './other-reason-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherReasonModalComponent {
  protected readonly I18N = 'orders.orderModal.rejectDropdown';

  protected reason = this.fb.control<string | null>(null, [
    Validators.required,
  ]);

  protected loading = signal(false);
  protected close$ = new Subject<boolean | undefined>();

  constructor(
    @Inject(DIALOG_DATA) protected data: OtherReasonModalData,
    protected dialogRef: DialogRef<boolean>,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private ordersService: OrdersService,
  ) {}

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.reason, this.toastService)) return;

    const form = this.reason.getRawValue();

    this.loading.set(true);
    this.ordersService
      .rejectVehicle(this.data.uuid, {
        reason: OrderRejectReason.OTHER,
        other: form!,
      })
      .subscribe({
        next: () => {
          const isReject = this.data.mode === OtherReasonModalMode.REJECT;
          if (isReject) {
            this.toastService.showInformation(
              'orders.orderModal.toasts.vehicleRejected',
            );
          }

          this.close$.next(!isReject ? true : undefined);
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }
}
