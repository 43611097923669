import { Duration } from 'luxon';

export const TACHOGRAPH_LIMITS = {
  continuosDrivingTime: Duration.fromObject({ hours: 4, minutes: 30 }).as(
    'seconds',
  ),
  dailyDrivingTime: Duration.fromObject({ hours: 9 }).as('seconds'),
  weeklyDrivingTime: Duration.fromObject({ hours: 56 }).as('seconds'),
  fortnightDrivingTime: Duration.fromObject({ hours: 90 }).as('seconds'),
  weeklyDailyDrivingTimeExtensions: 2,
} as const;
