<div class="not-found" [style.height]="height()">
  <div class="animation">
    <div class="outer" [style.width.px]="size()" [style.height.px]="size()">
      <div class="inner">
        <ng-content></ng-content>
      </div>
    </div>

    @let circleSize = size() - 4;
    <div
      class="circle"
      [class.disabled]="disabled()"
      [style.width.px]="circleSize"
      [style.height.px]="circleSize"
    ></div>
  </div>

  @if (heading(); as heading) {
    <p class="f-paragraph-regular-3">{{ heading | translate }}</p>
  }

  @if (description(); as description) {
    <p
      class="description f-medium-3"
      [class.alt]="alt()"
      [innerHTML]="description | translate"
    ></p>
  }

  @if (link(); as link) {
    <onyx-link
      class="link"
      [color]="link?.color ?? 'gray'"
      [font]="link?.font ?? 'f-label-2'"
      [routerLink]="link?.href"
      (click)="link?.callback?.(); $event.stopPropagation()"
    >
      {{ link.link | translate }}
    </onyx-link>
  }
</div>
