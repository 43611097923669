@if (subpoints()?.length && subpoints(); as subpoints) {
  <button class="subpoints" (click)="toggleSubpoints()">
    @if (subpoints.length > 2) {
      <onyx-icon
        class="f-gray"
        [name]="subpoints[0].icon"
        [size]="12"
      ></onyx-icon>

      <span class="f-paragraph-semibold-3 f-gray">
        {{ subpoints.length - 1 }}
      </span>
    } @else {
      @for (subpoint of subpoints; track $index) {
        <onyx-icon
          class="f-gray"
          [name]="subpoint.icon"
          [size]="12"
        ></onyx-icon>
      }
    }

    <onyx-icon
      [name]="subpointsExpanded() ? 'arrow-alt-up' : 'arrow-alt-down'"
      [size]="16"
    ></onyx-icon>
  </button>
}

<p class="summary f-medium-2 f-gray">
  @let summary = routeSummary();
  <span>{{ summary ? (summary.distance | onyxDistance) : '?? km' }}</span>
  <span>·</span>
  <span>
    {{
      summary
        ? (summary.duration
          | duration: { format: 'to', size: 'm', units: ['h'] })
        : '?? h'
    }}
  </span>
  <span>·</span>
  <onyx-icon name="trend-down" [size]="7"></onyx-icon>
  <span>
    {{ summary ? (summary.fuelConsumption / 100 | number) : '??' }}
    {{ fuelUnit$() | async }}
  </span>
  <span>·</span>
  <span>
    {{ summary ? (summary.totalCost | onyxAmount: 'resultCurrency') : '?? €' }}
  </span>
</p>

@if (SHOW_ADD) {
  <div class="actions" [class.attached]="actionsAttached()">
    <onyx-icon-button
      type="ghost"
      color="black"
      size="s"
      [circle]="true"
      onyxTooltip="labels.addPoint"
      onyxTooltipColor="white"
      [onyxDropdownOptions]="addOptions$ | async"
      (onyxDropdownValueChange)="$event?.(index())"
      (onyxDropdownAttachedChange)="actionsAttached.set($event)"
    >
      <onyx-icon name="zoom-in" [size]="12"></onyx-icon>
    </onyx-icon-button>
  </div>
}
