import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OrdersRoute } from '../../orders.routes';
import { OrderHelper } from '../helpers/order.helper';

export const orderCardResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const orderHelper = inject(OrderHelper);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;
  orderHelper.openModal(uuid);

  const urlTree = router.parseUrl(OrdersRoute.ORDERS_LIST);
  return new RedirectCommand(urlTree, { replaceUrl: true });
};
