@let delegation = this.driver().delegation;
<div class="delegation" [class.active]="delegation">
  @let context = delegationContext();
  <onyx-widget-box
    [heading]="context.heading ?? 'labels.delegation'"
    [color]="context.color"
    [hover]="!delegation"
    (click)="!delegation ? addDelegation() : null"
  >
    <div class="content">
      <p>{{ context.content | translate }}</p>

      @if (context?.delay; as delay) {
        <p class="f-red">+{{ delay }}</p>
      }
    </div>
  </onyx-widget-box>
</div>
