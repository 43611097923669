<div class="container" [style.gap.px]="gap()">
  <div
    class="box {{ color() }}"
    [class.background]="hasColoredBackground()"
    [class.transparent]="hasTransparentBackground()"
    [class.shadow]="hasShadow()"
    [style.width.px]="size()"
    [style.height.px]="size()"
  >
    <ng-content select="onyx-icon"></ng-content>
    <ng-content select="[icon]"></ng-content>
  </div>

  <div class="wrapper" [class.edit]="editCallback() != null">
    <button
      class="content f-paragraph-medium-3"
      [class.underline]="showCallback() != null"
      (click)="showCallback()?.()"
      [tabindex]="showCallback() != null ? 0 : -1"
    >
      @if (label(); as label) {
        <p class="label">{{ label | translate }}</p>
      }

      <ng-content></ng-content>
    </button>

    @if (editCallback(); as edit) {
      <onyx-icon-button
        type="outlined"
        color="black"
        size="s"
        [circle]="true"
        (click)="edit()"
      >
        <onyx-icon name="edit" [size]="12"></onyx-icon>
      </onyx-icon-button>
    }
  </div>
</div>
