<div
  class="checkbox"
  [ngClass]="{
    active: value(),
    disabled: disabled(),
    invalid: isInvalid(),
  }"
  [tabindex]="disabled() ? -1 : 0"
  (focusout)="onTouched?.()"
>
  @if (value()) {
    <onyx-icon
      class="checkbox-icon"
      [name]="indeterminate() ? 'indeterminate' : 'check'"
      [size]="indeterminate() ? 16 : 10"
    ></onyx-icon>
  }
</div>

@if (label(); as label) {
  <div class="label">
    <onyx-input-label
      type="title"
      [label]="label"
      [optional]="!!formControl() && isOptional() && showOptional()"
      [disabled]="disabled()"
    ></onyx-input-label>

    @if (count(); as count) {
      <span class="f-gray" [class.disabled]="disabled()">
        {{ count | number: '1.0-0' }}
      </span>
    }
  </div>
}

@if (hint(); as hint) {
  <onyx-icon
    class="f-gray"
    [class.disabled]="disabled()"
    name="tooltip"
    [size]="16"
    [onyxTooltip]="hint"
    [onyxTooltipEnabled]="!disabled()"
  ></onyx-icon>
}

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [controls]="[formControl()!]"
    type="checkbox"
  ></onyx-form-control-error>
}
