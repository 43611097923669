@if (routes(); as routes) {
  <onyx-tabs
    [tabs]="routes"
    [templateRef]="routeTabTemplate"
    [value]="activeRoute()"
    [fill]="true"
    size="l"
    width="100%"
    (valueChange)="$event ? changeRoute($event) : null"
  ></onyx-tabs>
} @else {
  <div class="skeleton">
    @if (hasInvalidAddress()) {
      <p class="f-medium-2 f-gray">
        {{ 'invalidAddressMessage' | i18n: I18N | translate }}
      </p>
    } @else {
      @for (
        skeletonSizes of [
          [110, 186],
          [70, 160],
        ];
        track skeletonSizes
      ) {
        <div class="item">
          <div class="block" [style.width.px]="skeletonSizes[0]"></div>
          <div class="block" [style.width.px]="skeletonSizes[1]"></div>
        </div>
      }
    }
  </div>
}

<ng-template let-tab="tab" let-active="active" #routeTabTemplate>
  <div class="tab" [class.active]="active">
    <div class="heading f-gray">
      <p>{{ tab.name | i18n: 'labels' | translate }}</p>

      <div class="difference">
        @if (tab.costDifferenceTrend === 'down') {
          <onyx-icon name="trend-down" [size]="8"></onyx-icon>

          <p>{{ tab.costDifference | onyxAmount: 'resultCurrency' }}</p>
        } @else if (tab.durationDifference) {
          <onyx-icon
            [name]="
              tab.durationDifferenceTrend === 'down' ? 'trend-down' : 'trend-up'
            "
            [size]="8"
          ></onyx-icon>

          <p>
            {{
              tab.durationDifference | duration: { format: 'to', size: 'xs' }
            }}
          </p>
        }
      </div>
    </div>

    <div class="content">
      @for (type of tab.types; track type) {
        <onyx-icon [name]="$any(TYPE_ICONS)[type]" [size]="20"></onyx-icon>
      }

      <h3>{{ tab.cost | onyxAmount: 'resultCurrency' }}</h3>
    </div>
  </div>
</ng-template>
