import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { OnyxIconName } from '../interfaces';

@Component({
  selector: 'onyx-custom-icon',
  imports: [SvgIconComponent, NgStyle],
  templateUrl: './onyx-custom-icon.component.html',
  styleUrl: './onyx-custom-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxCustomIconComponent {
  public source = input.required<string>();
  public name = input<OnyxIconName>();
  public size = input.required<number>();
}
