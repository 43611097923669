import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import {
  OnyxAmount,
  OnyxComponentInputs,
  OnyxRouteSummary,
  OnyxTableColumn,
  OnyxTableComponent,
} from '@onyx/angular';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { FuelType } from '../../../enums/fuel-type';
import { AmountCellComponent } from '../../cells/amount-cell/amount-cell.component';
import { NumberCellComponent } from '../../cells/number-cell/number-cell.component';
import { StringCellComponent } from '../../cells/string-cell/string-cell.component';

interface FuelData {
  name: string;
  consumption: number;
  unit: string;
  cost: OnyxAmount | null;
}

@Component({
  selector: 'app-fuel-summary-tooltip',
  standalone: true,
  imports: [OnyxTableComponent],
  templateUrl: './fuel-summary-tooltip.component.html',
  styleUrl: './fuel-summary-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuelSummaryTooltipComponent {
  protected readonly COLUMNS: OnyxTableColumn<FuelData>[] = [
    {
      id: 'name',
      width: 'fill',
      component: {
        ref: StringCellComponent,
        inputs: ({ item }) =>
          ({
            value: item.name,
          }) satisfies OnyxComponentInputs<StringCellComponent>,
      },
    },
    {
      id: 'consumption',
      width: '120px',
      component: {
        ref: NumberCellComponent,
        inputs: ({ item }) =>
          ({
            value: item.consumption,
            decimalPlaces: 2,
            unit: item.unit,
          }) satisfies OnyxComponentInputs<NumberCellComponent>,
      },
    },
    {
      id: 'cost',
      width: '120px',
      component: {
        ref: AmountCellComponent,
        inputs: ({ item }) =>
          ({
            amount: item.cost,
            show: 'resultCurrency',
          }) satisfies OnyxComponentInputs<AmountCellComponent>,
      },
    },
  ];

  public type = input.required<FuelType>();
  public unit = input.required<string | null>();
  public summary = input.required<OnyxRouteSummary>();

  protected data = computed<FuelData[]>(() => {
    const unit = this.unit() ?? '';
    return [
      {
        name: 'labels.adBlue',
        consumption: this.summary().adBlueConsumption,
        unit,
        cost: this.summary().adBlueCost,
      },
      {
        name: `${DictionaryCode.FUEL_TYPE}.${this.type()}`,
        consumption: this.summary().fuelConsumption,
        unit,
        cost: this.summary().fuelCost,
      },
    ];
  });
}
