import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Injector,
  input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { OrderPointFormGroup } from '../../../dashboard/orders/order-form/order-points-form/order-point-form/order-point-form.component';
import { RouteConfig } from '../../interfaces/address/calculate-route-request';
import { DictionariesService } from '../../services/dictionaries.service';
import { RouteService } from '../../services/route.service';
import {
  RoutePoint,
  RoutePointComponent,
} from './route-point/route-point.component';

@Component({
  selector: 'app-route',
  imports: [RoutePointComponent],
  providers: [
    {
      provide: RouteService,
      useFactory: (
        parent: RouteService,
        dictionariesService: DictionariesService,
      ): RouteService => {
        if (parent) return parent;
        return new RouteService(dictionariesService);
      },
      deps: [
        [new Optional(), new SkipSelf(), RouteService],
        DictionariesService,
      ],
    },
  ],
  templateUrl: './route.component.html',
  styleUrl: './route.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteComponent implements OnInit {
  public points = input.required<RoutePoint[]>();
  public config = input.required<RouteConfig | null>();

  constructor(
    private routeService: RouteService,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    effect(
      () => {
        this.routeService.points.set(this.points());
        this.routeService.config.set(this.config());
      },
      { injector: this.injector },
    );
  }

  protected trackPoint(point: RoutePoint): OrderPointFormGroup | string {
    if ('form' in point) return point.form;
    return point.uuid;
  }

  protected isVisible(
    point: RoutePoint,
    isFirst: boolean,
    isLast: boolean,
  ): boolean {
    if (isFirst || isLast) return true;

    const value = 'value' in point ? point.value : point;
    return !('includeInOrder' in value) || value.includeInOrder;
  }
}
