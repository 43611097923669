import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, map, of } from 'rxjs';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { DelegationForm } from '../../delegation-form/delegation-form.component';
import { DelegationsRoute } from '../../delegations.routes';
import { DelegationStatus } from '../enums/delegation-status';
import { DelegationHelper } from '../helpers/delegation.helper';
import { DelegationsService } from '../services/delegations.service';

export const editDelegationResolver: ResolveFn<{
  form: DelegationForm;
  status: DelegationStatus;
}> = (route: ActivatedRouteSnapshot) => {
  const delegationService = inject(DelegationsService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;
  const urlTree = router.parseUrl(DelegationsRoute.DELEGATIONS_LIST);

  return delegationService.getDelegation(uuid).pipe(
    map((dto) => {
      if (!DelegationHelper.hasEdit(dto)) {
        toastService.showError('toasts.archived', { keepOnNavigation: true });
        return new RedirectCommand(urlTree, { replaceUrl: true });
      }

      return {
        form: DelegationHelper.fromDto(dto),
        status: dto.status.value,
      };
    }),
    catchError((response: HttpErrorResponse) => {
      if (response.status === HttpStatusCode.BadRequest) {
        toastService.showError('delegations.delegationNotFound', {
          keepOnNavigation: true,
        });
      } else {
        ValidationHelper.handleUnexpectedError(response, toastService, {
          keepOnNavigation: true,
        });
      }

      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};
