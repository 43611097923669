import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxDatePipe,
  OnyxTime,
  OnyxTimePipe,
} from '@onyx/angular';
import { DateTime, DurationUnits } from 'luxon';
import { CommonHelper } from '../../../../../../../common/helpers/common.helper';
import { DurationPipe } from '../../../../../../../common/pipes/duration.pipe';
import { I18nPipe } from '../../../../../../../common/pipes/i18n.pipe';
import { OrderHelper } from '../../../../../common/helpers/order.helper';
import { Order } from '../../../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-plan-duration',
  imports: [
    OnyxDataWidgetComponent,
    TranslatePipe,
    DurationPipe,
    OnyxDatePipe,
    OnyxTimePipe,
    I18nPipe,
  ],
  templateUrl: './order-modal-plan-duration.component.html',
  styleUrl: './order-modal-plan-duration.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPlanDurationComponent {
  protected readonly I18N = 'orders.orderModal.plan';

  public order = input.required<Order>();

  protected firstPointTimestamp = computed(() => {
    const points = this.order().points;
    const firstPoint = points[0];

    const startedAt = firstPoint.timestamps.startedAt;
    if (startedAt) return this.parseTimestamp(startedAt, false);

    const firstPointEta = firstPoint.estimations?.arrivalTime;
    if (firstPointEta) return this.parseTimestamp(firstPointEta, true);

    const firstPointDeadline = OrderHelper.getDeadline(
      firstPoint,
      this.order(),
    )!;
    const date = firstPointDeadline.toISODate()!;
    const time = firstPointDeadline.toFormat('HH:mm:ss')! as OnyxTime;

    return {
      date,
      time,
      full: `${date}T${time}`,
      isEstimated: false,
    };
  });

  protected lastPointTimestamp = computed(() => {
    const points = this.order().points;
    const lastPoint = points.at(-1)!;

    const completedAt = lastPoint.timestamps.completedAt;
    if (completedAt) return this.parseTimestamp(completedAt, false);

    const lastPointEta = lastPoint.estimations?.arrivalTime;
    if (lastPointEta) return this.parseTimestamp(lastPointEta, true);

    const lastPointDeadline = (() => {
      for (const point of points.toReversed()) {
        const deadline = OrderHelper.getDeadline(point, this.order());
        if (deadline !== null) return deadline;
      }
      return null;
    })();

    const date = lastPointDeadline?.toISODate();
    const time = lastPointDeadline?.toFormat('HH:mm:ss') as OnyxTime;

    return {
      date,
      time,
      full: `${date}T${time}`,
      isEstimated: false,
    };
  });

  protected isOrderFinished = computed(() =>
    OrderHelper.isOrderFinished(this.order().status.value),
  );

  protected plannedData = computed(() => {
    const { points } = this.order();
    if (!this.isOrderFinished()) return;

    const firstPoint = points[0];
    const lastPoint = points.at(-1)!;

    return {
      firstPoint: OrderHelper.getDeadline(firstPoint, this.order())!.toISO()!,
      lastPoint: OrderHelper.getDeadline(lastPoint, this.order())!.toISO()!,
    };
  });

  protected realizationResult = computed(() => {
    const plannedData = this.plannedData();
    if (!OrderHelper.isOrderFinished(this.order()) || !plannedData) {
      return null;
    }

    const { firstPoint, lastPoint } = plannedData;
    const compareOptions: DurationUnits = ['days', 'hours', 'minutes'];

    const calculateDiff = (start: string, end: string) =>
      DateTime.fromISO(end).diff(DateTime.fromISO(start), compareOptions);

    const plannedDuration = calculateDiff(firstPoint, lastPoint);
    const completedDuration = calculateDiff(
      this.firstPointTimestamp().full,
      this.lastPointTimestamp().full,
    );

    const diff = plannedDuration.minus(completedDuration);
    const isDelayed = diff.toMillis() < 0;

    return {
      difference: CommonHelper.formatDuration(diff),
      color: isDelayed ? 'red' : 'green',
      sign: isDelayed ? '+' : '-',
    };
  });

  private parseTimestamp(timestamp: string, isEstimated: boolean) {
    const [date, time] = timestamp.split('T');
    return {
      date,
      time: time as OnyxTime,
      full: timestamp,
      isEstimated,
    };
  }
}
