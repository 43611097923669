import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const availableGoodQuantityValidator =
  (availableQuantity: number): ValidatorFn =>
  (control: AbstractControl<number | null>): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;

    if (availableQuantity < 0) {
      return { availableGoodQuantity: Math.abs(availableQuantity) };
    }
    return null;
  };
