import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { OnyxOptional } from '../interfaces';

export enum OnyxDateFormat {
  DAY_SHORT_DATE = 'day-short-date',
  SHORT_DATE = 'short-date',
  DATE = 'date',
  SHORT_DATE_DOT = 'short-date-dot',
  DATE_DOT = 'date-dot',
  SHORT_DATE_TIME = 'short-date-time',
  DATE_TIME = 'date-time',
  SHORT_DATE_DOT_TIME = 'short-date-dot-time',
  DATE_DOT_TIME = 'date-dot-time',
  SHORT_TIME_DATE = 'short-time-date',
  TIME_DATE = 'time-date',
  SHORT_TIME_DATE_DOT = 'short-time-date-dot',
  TIME_DATE_DOT = 'time-date-dot',
}

@Pipe({
  name: 'onyxDate',
  pure: true,
})
export class OnyxDatePipe implements PipeTransform {
  public transform(
    startDate: OnyxOptional<string | DateTime>,
    format: `${OnyxDateFormat}`,
    endDate?: string | DateTime,
  ): string {
    if (!startDate) return '-';

    const startDateTime = DateTime.isDateTime(startDate)
      ? startDate
      : DateTime.fromJSDate(new Date(startDate));
    const endDateTime = endDate
      ? DateTime.isDateTime(endDate)
        ? endDate
        : DateTime.fromJSDate(new Date(endDate))
      : startDateTime;

    const dates = this.transformDatesRange(startDateTime, endDateTime, format);
    const hours = this.transformHoursRange(startDateTime, endDateTime);

    return {
      [OnyxDateFormat.DAY_SHORT_DATE]: dates,
      [OnyxDateFormat.SHORT_DATE]: dates,
      [OnyxDateFormat.DATE]: dates,
      [OnyxDateFormat.SHORT_DATE_DOT]: dates,
      [OnyxDateFormat.DATE_DOT]: dates,
      [OnyxDateFormat.SHORT_DATE_TIME]: `${dates}, ${hours}`,
      [OnyxDateFormat.DATE_TIME]: `${dates}, ${hours}`,
      [OnyxDateFormat.SHORT_DATE_DOT_TIME]: `${dates}, ${hours}`,
      [OnyxDateFormat.DATE_DOT_TIME]: `${dates}, ${hours}`,
      [OnyxDateFormat.SHORT_TIME_DATE]: `${hours}, ${dates}`,
      [OnyxDateFormat.TIME_DATE]: `${hours}, ${dates}`,
      [OnyxDateFormat.SHORT_TIME_DATE_DOT]: `${hours}, ${dates}`,
      [OnyxDateFormat.TIME_DATE_DOT]: `${hours}, ${dates}`,
    }[format];
  }

  private transformDatesRange(
    startDate: DateTime,
    endDate: DateTime,
    format: `${OnyxDateFormat}`,
  ): string {
    const formatDateTime = (dateTime: DateTime): string =>
      ({
        [OnyxDateFormat.DAY_SHORT_DATE]: dateTime.toFormat('ccc d LLL'),
        [OnyxDateFormat.SHORT_DATE]: dateTime.toFormat('d LLL'),
        [OnyxDateFormat.DATE]: dateTime.toFormat('d LLL yyyy'),
        [OnyxDateFormat.SHORT_DATE_DOT]: dateTime.toFormat('dd.MM'),
        [OnyxDateFormat.DATE_DOT]: dateTime.toFormat('dd.MM.yyyy'),
        [OnyxDateFormat.SHORT_DATE_TIME]: dateTime.toFormat('d LLL'),
        [OnyxDateFormat.DATE_TIME]: dateTime.toFormat('d LLL yyyy'),
        [OnyxDateFormat.SHORT_DATE_DOT_TIME]: dateTime.toFormat('dd.MM'),
        [OnyxDateFormat.DATE_DOT_TIME]: dateTime.toFormat('dd.MM.yyyy'),
        [OnyxDateFormat.SHORT_TIME_DATE]: dateTime.toFormat('d LLL'),
        [OnyxDateFormat.TIME_DATE]: dateTime.toFormat('d LLL yyyy'),
        [OnyxDateFormat.SHORT_TIME_DATE_DOT]: dateTime.toFormat('dd.MM'),
        [OnyxDateFormat.TIME_DATE_DOT]: dateTime.toFormat('dd.MM.yyyy'),
      })[format];

    const formatStartDate = (dateTime: DateTime): string =>
      ({
        [OnyxDateFormat.DAY_SHORT_DATE]: formatDateTime(dateTime),
        [OnyxDateFormat.SHORT_DATE]: formatDateTime(dateTime),
        [OnyxDateFormat.DATE]: dateTime.toFormat('d LLL'),
        [OnyxDateFormat.SHORT_DATE_DOT]: formatDateTime(dateTime),
        [OnyxDateFormat.DATE_DOT]: dateTime.toFormat('dd.MM'),
        [OnyxDateFormat.SHORT_DATE_TIME]: formatDateTime(dateTime),
        [OnyxDateFormat.DATE_TIME]: dateTime.toFormat('d LLL'),
        [OnyxDateFormat.SHORT_DATE_DOT_TIME]: formatDateTime(dateTime),
        [OnyxDateFormat.DATE_DOT_TIME]: dateTime.toFormat('dd.MM'),
        [OnyxDateFormat.SHORT_TIME_DATE]: formatDateTime(dateTime),
        [OnyxDateFormat.SHORT_TIME_DATE_DOT]: formatDateTime(dateTime),
        [OnyxDateFormat.TIME_DATE]: dateTime.toFormat('d LLL'),
        [OnyxDateFormat.TIME_DATE_DOT]: dateTime.toFormat('dd.MM'),
      })[format];

    startDate = startDate.startOf('day');
    endDate = endDate.startOf('day');

    const end = formatDateTime(endDate);
    if (endDate.equals(startDate)) return end;

    if (endDate.hasSame(startDate, 'year')) {
      if (endDate.hasSame(startDate, 'month')) {
        const start = startDate.toFormat('dd');
        return `${start}-${end}`;
      }

      const start = formatStartDate(startDate);
      return `${start} - ${end}`;
    }

    const start = formatDateTime(startDate);
    return `${start} - ${end}`;
  }

  private transformHoursRange(startDate: DateTime, endDate: DateTime): string {
    const start = startDate.toFormat('HH:mm');
    const end = endDate.toFormat('HH:mm');

    if (start === end) return start;
    return `${start}-${end}`;
  }
}
