@let point = data.point;
<onyx-modal
  type="center"
  size="s"
  [showBottomBar]="false"
  (closeModal)="dialogRef.close()"
>
  <h3 headingImage class="modal-heading">
    {{ point.type | i18n: DictionaryCode.ORDER_POINT_TYPE | translate }}
    <span class="f-gray">{{ point.address?.poiName }}</span>
  </h3>

  <div class="container">
    <div class="summary">
      <onyx-data-widget [padding]="10">
        <onyx-icon name="good" [size]="16"></onyx-icon>

        <p class="f-paragraph-medium-2">
          @if (totalGoods) {
            {{ totalGoods.quantity }}x
            {{ totalGoods.unit.names | onyxLanguage }}
          } @else {
            {{ 'labels.variousUnits' | translate }}
          }
        </p>
      </onyx-data-widget>

      <onyx-data-widget [padding]="10">
        <onyx-icon name="weight-fill" [size]="16"></onyx-icon>
        <p class="f-paragraph-medium-2">{{ totalWeight | number }} kg</p>
      </onyx-data-widget>
    </div>

    @for (good of goods; track good.uuid) {
      <div class="good">
        <div class="heading">
          <onyx-icon
            [style.color]="good.color"
            name="good"
            [size]="16"
          ></onyx-icon>

          <h6>
            {{ good.type ? (good.type.names | onyxLanguage) : good.typeName }}
          </h6>
        </div>

        <div class="details">
          <onyx-information-row label="labels.unit">
            {{ good.quantity }}x {{ good.unit.names | onyxLanguage }}
          </onyx-information-row>

          <onyx-information-row label="labels.totalWeight">
            {{ good.totalWeight | number }} kg
          </onyx-information-row>

          <onyx-information-row label="labels.size">
            {{ good.size | goodsSize }}
          </onyx-information-row>

          @if (good.referenceNumber; as referenceNumber) {
            <onyx-information-row label="labels.referenceNumber">
              {{ referenceNumber }}
            </onyx-information-row>
          }

          @if (good.unitedNationsNumber; as unitedNationsNumber) {
            <onyx-information-row label="labels.unitedNations">
              {{ unitedNationsNumber }}
            </onyx-information-row>
          }

          @if (good.statisticalNumber; as statisticalNumber) {
            <onyx-information-row label="labels.statisticalNumber">
              {{ statisticalNumber }}
            </onyx-information-row>
          }
        </div>
      </div>
    }
  </div>
</onyx-modal>
