import { DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxButtonComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxOption,
  OnyxRadioButtonComponent,
  OnyxToastService,
  OnyxToastType,
} from '@onyx/angular';
import { forkJoin, Subject } from 'rxjs';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { DictionariesService } from '../../../../../common/services/dictionaries.service';
import { ValidationService } from '../../../../../common/services/validation.service';
import { FleetRoute } from '../../../fleet.routes';
import { FleetCategory } from '../../enums/fleet-category';
import { FleetRouterStateKey } from '../../enums/fleet-router-state-key';

enum FormStep {
  CATEGORY,
  TYPE,
}

@Component({
  selector: 'app-add-fleet-modal',
  imports: [
    OnyxModalComponent,
    TranslatePipe,
    ReactiveFormsModule,
    AsyncPipe,
    OnyxIconComponent,
    OnyxRadioButtonComponent,
    OnyxButtonComponent,
    NgClass,
  ],
  templateUrl: './add-fleet-modal.component.html',
  styleUrl: './add-fleet-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFleetModalComponent {
  protected readonly I18N = 'fleet.addFleetModal';
  protected readonly FormStep = FormStep;

  protected readonly categories$ =
    this.dictionariesService.getFleetCategories();

  protected types = signal<OnyxOption<string>[]>([]);

  protected form = this.buildForm();
  protected formStep = computed(() =>
    this.step() === FormStep.CATEGORY || this.types().length === 0
      ? FormStep.CATEGORY
      : FormStep.TYPE,
  );
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  protected categoryControl = this.form.controls.category;
  protected category = toSignal(this.categoryControl.valueChanges, {
    initialValue: this.categoryControl.value,
  });

  protected typeControl = this.form.controls.type;
  protected type = toSignal(this.typeControl.valueChanges, {
    initialValue: this.typeControl.value,
  });

  protected disabled = computed(
    () =>
      this.loading() ||
      (this.step() === FormStep.CATEGORY ? !this.category() : !this.type()),
  );

  private step = signal(FormStep.CATEGORY);

  constructor(
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private dictionariesService: DictionariesService,
    private toastService: OnyxToastService,
    private validationService: ValidationService,
    private router: Router,
  ) {}

  protected continue(): void {
    const control = {
      [FormStep.CATEGORY]: this.categoryControl,
      [FormStep.TYPE]: this.typeControl,
    }[this.step()];

    if (control.invalid) {
      FormHelper.submit(control);
      this.toastService.showCustom(OnyxToastType.INVALID_DATA);
      return;
    }

    if (this.step() === FormStep.CATEGORY) {
      this.form.disable();
      this.loading.set(true);

      const category = this.category()!;
      forkJoin([
        this.dictionariesService.getDictionary(DictionaryCode.VEHICLE_TYPE),
        this.validationService.getFleetValidation(category),
      ]).subscribe(([vehicleTypes, validation]) => {
        const allowedTypes = Object.keys(validation[category]);
        this.types.set(
          vehicleTypes.filter((type) => allowedTypes.includes(type.value)),
        );
        this.step.set(FormStep.TYPE);

        if (this.types().length === 0) {
          this.typeControl.disable();
          this.continue();
        } else {
          this.form.enable();
          this.loading.set(false);
        }
      });
    } else {
      const { category, type } = this.form.value;
      this.router.navigateByUrl(FleetRoute.ADD_FLEET, {
        state: {
          [FleetRouterStateKey.CATEGORY]: category,
          [FleetRouterStateKey.TYPE]: type,
        },
      });

      this.close$.next();
    }
  }

  protected back(): void {
    this.formStep() === FormStep.CATEGORY
      ? this.close$.next()
      : this.step.set(FormStep.CATEGORY);
  }

  private buildForm() {
    return this.fb.group({
      category: this.fb.control<FleetCategory | null>(null, [
        Validators.required,
      ]),
      type: this.fb.control<string | null>(null, [Validators.required]),
    });
  }
}
