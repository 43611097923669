import { Routes } from '@angular/router';

export enum MapRoute {
  MAP = '/map',
}

export const MAP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./map/map.component').then((m) => m.MapComponent),
  },
] as const;
