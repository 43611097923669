<onyx-modal
  type="right"
  size="m"
  [heading]="vehicle() ? (vehicle() | fleetIdentifier) : ''"
  [loading]="globalLoading()"
  [(error)]="globalError"
  [extend]="true"
  [close$]="close$"
  (errorChange)="vehicle$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container rightOptions>
    @if (vehicle(); as vehicle) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        onyxTooltip="labels.options"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon
          class="f-gray"
          name="options-horizontal"
          [size]="16"
        ></onyx-icon>
      </onyx-icon-button>

      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        onyxTooltip="labels.share"
        (click)="share()"
      >
        <onyx-icon class="f-gray" name="share" [size]="16"></onyx-icon>
      </onyx-icon-button>

      @if (FleetHelper.hasTelematics(vehicle)) {
        <onyx-icon-button
          color="black"
          type="transparent"
          size="m"
          onyxTooltip="labels.showOnGantt"
          (click)="showOnGantt()"
        >
          <onyx-icon class="f-gray" name="menu-gantt" [size]="16"></onyx-icon>
        </onyx-icon-button>

        <onyx-icon-button
          color="blue"
          type="primary"
          size="m"
          [circle]="true"
          onyxTooltip="labels.showOnMap"
          (click)="showOnMap()"
        >
          <onyx-icon name="menu-map" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }
    }
  </ng-container>

  <ng-container headingLeftOptions>
    @if (vehicle(); as vehicle) {
      <onyx-icon
        class="f-gray"
        [name]="vehicle.generalInformation.category"
        [size]="20"
      ></onyx-icon>
    }
  </ng-container>

  <ng-container leftOptions>
    @if (vehicle()?.additionalParameters?.isLowDeck != null) {
      <onyx-icon
        class="f-gray"
        name="low-deck"
        [size]="16"
        onyxTooltip="labels.lowDeck"
      ></onyx-icon>
    }

    @if (semiTrailerHeaderConfig(); as config) {
      <onyx-icon
        class="f-gray"
        [name]="config.icon"
        [size]="16"
        [onyxTooltip]="config.size | i18n: DictionaryCode.SEMI_TRAILER_SIZE"
      ></onyx-icon>
    }
  </ng-container>

  <ng-container bottomOptions>
    @if (vehicle(); as vehicle) {
      <app-fleet-modal-vehicle-heading
        [vehicle]="vehicle"
        [isVehicle]="isVehicle()!"
      ></app-fleet-modal-vehicle-heading>
    }
  </ng-container>

  @if (vehicle(); as vehicle) {
    <div class="container">
      @if (warnings() !== false) {
        <app-fleet-modal-warnings
          [vehicle]="vehicle"
          (redirect)="redirectToDocument()"
          (warnings)="warnings.set($event)"
        ></app-fleet-modal-warnings>
      }

      <app-fleet-modal-main-section
        [vehicle]="vehicle"
        [isVehicle]="isVehicle()!"
      ></app-fleet-modal-main-section>

      <div class="separator"></div>

      @if (!isArchived()) {
        @if (loading()) {
          @defer (on timer(1s)) {
            <section class="loading-section">
              <onyx-spinner color="blue" [size]="48"></onyx-spinner>
            </section>
          }
        } @else if (error()) {
          <section class="loading-section">
            <onyx-error
              [(error)]="error"
              (errorChange)="assignedVehicle$.next()"
            ></onyx-error>
          </section>
        } @else {
          <app-fleet-modal-assigned-vehicle
            [vehicle]="assignedVehicle()"
            [primaryVehicle]="vehicle"
            [isVehicle]="isVehicle()!"
          ></app-fleet-modal-assigned-vehicle>
        }

        <div class="separator"></div>
      }

      <div class="chips-container">
        <onyx-chips
          [chips]="chips()"
          type="secondary"
          [single]="true"
          [wrap]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>

        @switch (group()[0]) {
          <!-- @case (FleetModalSection.ORDER) {
            N/A
          }
          @case (FleetModalSection.DELEGATION) {
            N/A
          } -->
          @case (FleetModalSection.PROFITABILITY) {
            <img
              style="margin-top: -20px"
              src="img/mocks/fleet-profitability.svg"
              alt=""
              draggable="false"
            />
          }
          @case (FleetModalSection.CREW) {
            @if (isVehicle(); as isVehicle) {
              <app-fleet-modal-crew
                [vehicle]="vehicle"
                [isVehicle]="isVehicle"
              ></app-fleet-modal-crew>
            }
          }
          @case (FleetModalSection.DOCUMENTS) {
            <app-fleet-modal-documents
              [vehicle]="vehicle"
            ></app-fleet-modal-documents>
          }
          @case (FleetModalSection.DETAILS) {
            <app-fleet-modal-details
              [vehicle]="vehicle"
              [isVehicle]="isVehicle()!"
            ></app-fleet-modal-details>
          }
          @case (FleetModalSection.NOTE) {
            <app-modal-note
              [note]="vehicle.note"
              [route]="FleetRoute.EDIT_FLEET.replace(':uuid', vehicle.uuid)"
              [close$]="close$"
            ></app-modal-note>
          }
        }
      </div>
    </div>
  }
</onyx-modal>
