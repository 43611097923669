import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  effect,
  forwardRef,
  input,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNumber, isObject, isString } from 'lodash';
import { Duration } from 'luxon';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { OnyxTooltipContext } from '../../tooltip';
import { OnyxTime, OnyxTimeRange } from '../interfaces';
import {
  OnyxTimepickerInputComponent,
  TimepickerInputValue,
} from './onyx-timepicker-input/onyx-timepicker-input.component';

type Value = OnyxTime | OnyxTimeRange | number | null;

@Component({
  selector: 'onyx-timepicker',
  imports: [
    OnyxTimepickerInputComponent,
    OnyxFormControlErrorComponent,
    NgClass,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => OnyxTimepickerComponent),
    },
  ],
  templateUrl: './onyx-timepicker.component.html',
  styleUrl: './onyx-timepicker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTimepickerComponent
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor
{
  public label = input<string>();
  public hint = input<OnyxTooltipContext>();
  public labelTo = input<string>();
  public type = input<'time' | 'duration'>('time');
  public showTimeRange = input(false);
  public showClockIcon = input(true);
  public forceError = input(false);
  public showErrors = input(true);
  public width = input('100%');

  protected onChange?: (value: Value) => void;
  protected onTouched?: () => void;
  protected value = signal<{
    from?: TimepickerInputValue;
    to?: TimepickerInputValue;
  }>({});
  protected disabled = signal(false);

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private elementRef: ElementRef<HTMLElement>,
  ) {
    super(injector, destroyRef);
    effect(() => (this.elementRef.nativeElement.style.width = this.width()));

    effect(() => {
      const from = this.fromInput(this.value().from);
      const singleValue = from != null ? from : null;

      if (this.type() === 'duration' || !this.showTimeRange()) {
        this.onChange?.(singleValue);
        return;
      }

      const to = this.fromInput(this.value().to);
      const rangeValue = from != null && to != null ? { from, to } : null;
      this.onChange?.(rangeValue as OnyxTimeRange);
    });
  }

  public writeValue(value: Value): void {
    if (value == null) {
      this.value.set({});
    } else if (this.showTimeRange() && isObject(value)) {
      value = value as OnyxTimeRange;
      this.value.set({
        from: this.fromString(value.from),
        to: this.fromString(value.to),
      });
    } else if (isString(value)) {
      this.value.set({ from: this.fromString(value) });
    } else if (isNumber(value)) {
      this.value.set({ from: this.fromSeconds(value) });
    }
  }

  public registerOnChange(fn: (value: Value) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  protected changeValue(
    type: keyof OnyxTimeRange,
    time: TimepickerInputValue,
  ): void {
    this.value.update((value) => ({
      ...value,
      [type]: time,
    }));
  }

  private fromSeconds(seconds: number): TimepickerInputValue {
    const duration = Duration.fromObject({ seconds })
      .shiftTo('hours', 'minutes')
      .normalize();
    return { hour: duration.hours, minutes: duration.minutes };
  }

  private fromString(value: OnyxTime): TimepickerInputValue {
    const [hour, minutes] = value.split(':').map(Number);
    return { hour, minutes };
  }

  private fromInput(value?: TimepickerInputValue): OnyxTime | number | null {
    if (value?.hour == null || value?.minutes == null) return null;

    if (this.type() === 'duration') {
      return Duration.fromObject(value).as('seconds');
    }

    return `${String(value.hour).padStart(2, '0')}:${String(
      value.minutes,
    ).padStart(2, '0')}:00`;
  }
}
