import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  OnInit,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxInputLabelComponent,
  OnyxLinkComponent,
  OnyxPointOfInterestAddress,
  OnyxRoutePoint,
} from '@onyx/angular';
import { map } from 'rxjs';
import { OrderPointCategory } from '../../../common/enums/order-point-category';
import { OrderPointType } from '../../../common/enums/order-point-type';
import { OrderFormGood } from '../../../common/interfaces/order-form-good';
import { OrderPointBaseFormComponent } from '../order-point-form/order-point-base-form/order-point-base-form.component';
import { OrderUnloadingPointFormGoodComponent } from './order-unloading-point-form-good/order-unloading-point-form-good.component';
import {
  OrderUnloadingPointGoodFormComponent,
  OrderUnloadingPointGoodFormGroup,
} from './order-unloading-point-form-good/order-unloading-point-good-form/order-unloading-point-good-form.component';

export type OrderUnloadingPointFormGroup = ReturnType<
  typeof OrderUnloadingPointFormComponent.buildForm
>;
export type OrderUnloadingPointForm = ReturnType<
  OrderUnloadingPointFormGroup['getRawValue']
>;

@Component({
  selector: 'app-order-unloading-point-form',
  imports: [
    OrderPointBaseFormComponent,
    OnyxInputLabelComponent,
    TranslatePipe,
    OnyxLinkComponent,
    NgClass,
    OrderUnloadingPointFormGoodComponent,
  ],
  templateUrl: './order-unloading-point-form.component.html',
  styleUrl: './order-unloading-point-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderUnloadingPointFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.points.unloadingPoint';

  public form = input.required<OrderUnloadingPointFormGroup>();
  public goods = input.required<OrderFormGood[]>();

  protected hasAllGoodsAvailable = computed(() => {
    const activeGoods = this.activeGoods();
    if (!activeGoods.length) return false;

    return activeGoods.every(
      (good) => good.availableQuantity === good.good.quantity,
    );
  });
  protected hasAnyGoodsAvailable = computed(() =>
    this.activeGoods().some((good) => good.availableQuantity > 0),
  );

  protected goodForms = computed(() => {
    this.formValue();
    return new Map(
      this.form().controls.goods.controls.map((control) => [
        control.controls.uuid.value,
        control,
      ]),
    );
  });
  protected hasEmptyGoods = computed(
    () => !this.formValue().goods.length && this.form().touched,
  );

  private activeGoods = computed(() =>
    this.goods().filter((good) => good.good.quantity && good.availableQuantity),
  );
  private goodsFormArray = computed(() => this.form().controls.goods);
  private formValue!: Signal<OrderUnloadingPointForm>;

  constructor(
    private injector: Injector,
    private fb: NonNullableFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.formValue = toSignal(
      this.form().valueChanges.pipe(map(() => this.form().getRawValue())),
      {
        initialValue: this.form().getRawValue(),
        injector: this.injector,
      },
    );

    effect(
      () => {
        const availableGoods = new Set(
          this.goods().map((good) => good.good.uuid),
        );
        for (const form of this.goodsFormArray().controls) {
          const uuid = form.controls.uuid.value;
          if (!availableGoods.has(uuid)) this.removeGood(uuid);
        }
      },
      { injector: this.injector },
    );
  }

  protected unloadActiveGoods(): void {
    for (const good of this.activeGoods()) {
      const form = this.goodForms().get(good.good.uuid);
      if (!form) {
        this.addGood(good);
        continue;
      }

      const quantityControl = form.controls.quantity;
      quantityControl.setValue(
        (quantityControl.value || 0) + good.availableQuantity,
      );
    }
  }

  protected addGood(good: OrderFormGood): void {
    const uuid = good.good.uuid;
    if (this.goodForms().has(uuid)) return;

    this.goodsFormArray().push(
      OrderUnloadingPointGoodFormComponent.buildForm(
        this.fb,
        uuid,
        good.availableQuantity,
      ),
    );
  }

  protected removeGood(uuid: string): void {
    if (!this.goodForms().has(uuid)) return;

    const index = this.goodsFormArray().controls.findIndex(
      (control) => control.controls.uuid.value === uuid,
    );
    this.goodsFormArray().removeAt(index);
  }

  public static buildForm(
    fb: NonNullableFormBuilder,
    timeWindowsCount = 1,
    goodsCount = 0,
  ) {
    return fb.group({
      uuid: fb.control<string | null>(null),
      category: fb.control(OrderPointCategory.UNLOADING as const),
      type: fb.control(OrderPointType.UNLOADING as const),
      address: fb.control<OnyxPointOfInterestAddress | null>(null, [
        Validators.required,
      ]),
      referenceNumber: fb.control<string | null>(null),
      serviceTime: fb.control<number | null>(null, [Validators.required]),
      driverNote: fb.control<string | null>(null),
      timeWindows: OrderPointBaseFormComponent.buildTimeWindowsForm(
        fb,
        timeWindowsCount,
      ),
      goods: fb.array<OrderUnloadingPointGoodFormGroup>(
        Array.from({ length: goodsCount }).map(() =>
          OrderUnloadingPointGoodFormComponent.buildForm(fb, '', 0),
        ),
        [Validators.required],
      ),
      route: fb.control<OnyxRoutePoint | null>(null),
    });
  }
}
