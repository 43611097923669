import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxNamePipe,
  OnyxPhonePipe,
} from '@onyx/angular';
import { DictionaryCode } from '../../../../../../common/enums/dictionary-code';
import { Contractor } from '../../interfaces/contractor';

@Component({
  selector: 'app-contractor-contacts',
  imports: [
    OnyxInformationHeadingComponent,
    OnyxInformationRowComponent,
    OnyxIconButtonComponent,
    OnyxPhonePipe,
    OnyxIconComponent,
    TranslatePipe,
    OnyxNamePipe,
  ],
  templateUrl: './contractor-contacts.component.html',
  styleUrl: './contractor-contacts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorContactsComponent {
  protected readonly ActionHelper = ActionHelper;
  protected readonly DictionaryCode = DictionaryCode;

  public contractor = input.required<Contractor>();
}
