import { Pipe, PipeTransform } from '@angular/core';
import { OnyxOptional } from '../interfaces';

@Pipe({
  name: 'onyxName',
  pure: true,
})
export class OnyxNamePipe implements PipeTransform {
  public transform(
    value: OnyxOptional<{
      firstName?: OnyxOptional<string>;
      lastName?: OnyxOptional<string>;
    }>,
    format: 'full' | 'initialFirstName' = 'full',
  ): string {
    if (!value || (!value.firstName && !value.lastName)) return '-';

    const { firstName, lastName } = value;
    return {
      full: `${firstName ?? ''}${lastName ? ` ${lastName}` : ''}`,
      initialFirstName: `${firstName ? `${firstName.charAt(0)}.` : ''}${lastName ? ` ${lastName}` : ''}`,
    }[format];
  }
}
