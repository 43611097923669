import {
  OnyxComponentInputs,
  OnyxDateFormat,
  OnyxTableColumn,
} from '@onyx/angular';
import { AmountCellComponent } from '../../../../common/components/cells/amount-cell/amount-cell.component';
import { DateCellComponent } from '../../../../common/components/cells/date-cell/date-cell.component';
import { UnitCellComponent } from '../../../../common/components/cells/unit-cell/unit-cell.component';
import { PaymentsHistoryData } from '../../order-modal/order-modal-finished-section/order-modal-payment-form-container/order-modal-payment-form-container.component';

export const ORDER_MODAL_PAYMENTS_HISTORY_COLUMNS: OnyxTableColumn<PaymentsHistoryData>[] =
  [
    {
      id: 'date',
      name: 'labels.date',
      width: '200px',
      component: {
        ref: DateCellComponent,
        inputs: (data): OnyxComponentInputs<DateCellComponent> => ({
          date: data.item.date,
          format: OnyxDateFormat.DATE,
        }),
      },
    },
    {
      id: 'net',
      name: 'labels.net',
      width: '200px',
      component: {
        ref: AmountCellComponent,
        inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
          amount: data.item.value,
        }),
      },
    },
    {
      id: 'paymentPercentage',
      name: 'labels.paymentPercentage',
      width: 'fill',
      component: {
        ref: UnitCellComponent,
        inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
          value: data.item.percentage,
          unit: '%',
        }),
      },
    },
  ];
