<div class="delegation-period">
  <div class="period-item">
    <div class="icon-box">
      <div class="icon">
        <onyx-icon class="green" name="location" [size]="20"></onyx-icon>
      </div>
    </div>

    <div class="details">
      <div class="date f-medium-3">
        <span>{{ 'labels.beginning' | translate }}</span>

        {{ start().date | onyxDate: 'date' }}

        <span>{{ start().time | onyxTime }}</span>
      </div>

      <app-address
        class="f-paragraph-medium-3"
        [address]="start().address"
        format="full"
        [showFlag]="false"
      ></app-address>
    </div>
  </div>

  <onyx-icon class="separator" name="arrow-timeline" [size]="12"></onyx-icon>

  <div class="period-item">
    <div class="icon-box">
      @let isCompleteEndAddress = end().address && end().date && end().time;
      <div class="icon">
        <onyx-icon
          [class]="isCompleteEndAddress ? 'red' : 'yellow'"
          name="location"
          [size]="20"
        ></onyx-icon>
      </div>
    </div>

    @if (end().address || end().date || end().time) {
      <div class="details">
        @if (end().date || end().time) {
          <div class="date f-medium-3" [ngClass]="{ late: isLate() }">
            @if (end().date; as date) {
              <span>{{ 'labels.end' | translate }}</span>

              {{ date | onyxDate: 'date' }}
            } @else {
              <ng-container
                *ngTemplateOutlet="
                  editLink;
                  context: { label: I18N + '.setDate' }
                "
              ></ng-container>
            }

            @if (end().time; as time) {
              <span>{{ time | onyxTime }}</span>
            } @else {
              <ng-container
                *ngTemplateOutlet="
                  editLink;
                  context: { label: I18N + '.setTime' }
                "
              ></ng-container>
            }
          </div>
        } @else {
          <ng-container
            *ngTemplateOutlet="editLink; context: { label: 'labels.set' }"
          ></ng-container>
        }

        @if (end().address; as address) {
          <app-address
            class="f-paragraph-medium-3"
            [address]="address"
            format="full"
            [showFlag]="false"
          ></app-address>
        } @else {
          <ng-container
            *ngTemplateOutlet="
              editLink;
              context: { label: I18N + '.setAddress' }
            "
          ></ng-container>
        }
      </div>
    } @else {
      @if (hasEdit()) {
        <onyx-button
          type="outlined"
          color="black"
          size="s"
          (click)="edit.emit()"
        >
          {{ I18N + '.setEnd' | translate }}
        </onyx-button>
      } @else {
        -
      }
    }
  </div>
</div>

<ng-template let-label="label" #editLink>
  @if (hasEdit()) {
    <onyx-link color="blue" font="f-medium-2" (click)="edit.emit()">
      {{ label | translate }}
    </onyx-link>
  } @else {
    -
  }
</ng-template>
