<app-order-point-base-form
  class="form"
  [form]="form()"
></app-order-point-base-form>

<div class="goods-heading">
  <onyx-input-label
    [label]="I18N + '.goodsToUnload' | translate"
  ></onyx-input-label>

  <onyx-link
    [ngClass]="{ hidden: !hasAnyGoodsAvailable() }"
    color="blue"
    font="f-label-2"
    (click)="unloadActiveGoods()"
  >
    @if (hasAllGoodsAvailable()) {
      {{ I18N + '.unloadAll' | translate }}
    } @else {
      {{ I18N + '.unloadActive' | translate }}
    }
  </onyx-link>
</div>

<div class="goods" [ngClass]="{ invalid: hasEmptyGoods() }">
  @for (good of goods(); track good.good.uuid) {
    <app-order-unloading-point-form-good
      [good]="good"
      [form]="goodForms().get(good.good.uuid) ?? null"
      (add)="addGood(good)"
      (remove)="removeGood(good.good.uuid)"
    ></app-order-unloading-point-form-good>
  } @empty {
    <p class="empty">{{ I18N + '.empty' | translate }}</p>
  }
</div>

@if (hasEmptyGoods()) {
  <p class="error f-label-2">{{ I18N + '.emptyError' | translate }}</p>
}
