<div class="statistics">
  <ng-content></ng-content>
</div>

<div class="controls">
  <div class="page-items">
    @if (showLimit()) {
      <onyx-dropdown
        [formControl]="limitControl"
        [options]="LIMIT_OPTIONS"
        [optional]="false"
        size="s"
        width="60px"
      ></onyx-dropdown>
    }

    @if (totalItems() > 0) {
      <p>
        {{
          I18N + '.pageItems'
            | translate
              : {
                  pageItems: pageItemsTranslation(),
                  totalItems: totalItems() | number,
                }
        }}
      </p>
    }
  </div>

  @if (pages().length) {
    <div class="page-buttons">
      <onyx-icon
        class="page-button"
        [ngClass]="{ disabled: pagination().page === 1 }"
        name="arrow-alt-left"
        [size]="16"
        [tabIndex]="pagination().page === 1 ? -1 : 0"
        (click)="previousPage()"
        (keydown.space)="previousPage(); $event.preventDefault()"
      ></onyx-icon>

      <div class="pages">
        @if (pages().length > 5) {
          @if (pagination().page > 1) {
            @if (pagination().page > 2) {
              <button
                class="page-button page-number-button"
                (click)="changePage(1)"
              >
                1
              </button>
            }

            @if (pagination().page > 3) {
              <div class="page-numbers-hidden">...</div>
            }

            @if (pagination().page === pages().length) {
              <button
                class="page-button page-number-button"
                (click)="changePage(pagination().page - 2)"
              >
                {{ pagination().page - 2 }}
              </button>
            }

            <button
              class="page-button page-number-button"
              (click)="changePage(pagination().page - 1)"
            >
              {{ pagination().page - 1 }}
            </button>
          }

          <button class="page-button page-number-button active">
            {{ pagination().page }}
          </button>

          @if (pagination().page < pages().length) {
            <button
              class="page-button page-number-button"
              (click)="changePage(pagination().page + 1)"
            >
              {{ pagination().page + 1 }}
            </button>

            @if (pagination().page === 1) {
              <button
                class="page-button page-number-button"
                (click)="changePage(pagination().page + 2)"
              >
                {{ pagination().page + 2 }}
              </button>
            }

            @if (pagination().page < pages().length - 2) {
              <div class="page-numbers-hidden">...</div>
            }

            @if (pagination().page < pages().length - 1) {
              <button
                class="page-button page-number-button"
                (click)="changePage(pages().length)"
              >
                {{ pages().length }}
              </button>
            }
          }
        } @else {
          @for (page of pages(); track page) {
            <button
              class="page-button page-number-button"
              [ngClass]="{ active: pagination().page === page }"
              (click)="pagination().page !== page ? changePage(page) : null"
            >
              <p>{{ page }}</p>
            </button>
          }
        }
      </div>

      <onyx-icon
        class="page-button"
        [ngClass]="{
          disabled: pagination().page === pages().length || !pages().length,
        }"
        name="arrow-alt-right"
        [size]="16"
        [tabIndex]="pagination().page === pages().length ? -1 : 0"
        (click)="nextPage()"
        (keydown.space)="nextPage(); $event.preventDefault()"
      ></onyx-icon>
    </div>
  }
</div>
