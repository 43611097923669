<form [formGroup]="form()">
  @let controls = form().controls;
  <div class="address">
    <onyx-icon-box class="bar-icon" color="white" [hasShadow]="true">
      <onyx-icon name="location" [size]="16"></onyx-icon>
    </onyx-icon-box>

    <onyx-address-input
      [formControl]="controls.address"
      label="labels.address"
      [types]="[OnyxAddressType.POINT_OF_INTEREST]"
      [showAddOption]="true"
      [showApartment]="true"
      apartmentWidth="calc(2/9 * 100% - 12px)"
      [gap]="16"
      (addOption)="addPointOfInterest($event)"
    ></onyx-address-input>
  </div>

  <onyx-text-field
    class="reference-number"
    [formControl]="controls.referenceNumber"
    label="labels.referenceNumber"
  ></onyx-text-field>

  <onyx-timepicker
    class="service-time"
    [formControl]="controls.serviceTime"
    [label]="serviceTimeLabel()"
    hint="labels.includeWaitingTime"
    type="duration"
  ></onyx-timepicker>

  <onyx-text-field
    class="driver-note"
    [formControl]="controls.driverNote"
    label="labels.driverNote"
  ></onyx-text-field>

  @let timeWindowsGroup = controls.timeWindows;
  <div class="time-windows" [formGroup]="timeWindowsGroup">
    @let timeWindowsControls = timeWindowsGroup.controls;
    <onyx-icon-box class="bar-icon" color="white" [hasShadow]="true">
      <onyx-icon name="clock" [size]="16"></onyx-icon>
    </onyx-icon-box>

    <onyx-tabs
      [formControl]="timeWindowsControls.type"
      [label]="timeWindowTypeLabel()"
      [tabs]="timeWindowTypes$ | async"
      size="m"
    ></onyx-tabs>

    <div class="windows">
      @for (control of timeWindowsFormArray().controls; track control) {
        <app-order-point-time-window-form
          [form]="control"
          [first]="$first"
          [last]="$last"
          [type]="timeWindowType()"
          [pointOfInterest]="pointOfInterest()"
          (add)="addTimeWindow()"
          (remove)="removeTimeWindow($index)"
        ></app-order-point-time-window-form>
      }
    </div>
  </div>

  @if (showAfterHoursWarning(); as time) {
    <onyx-message
      class="fix-warning"
      type="warning"
      size="s"
      [borderRadius]="true"
    >
      <onyx-icon name="error-empty" [size]="16"></onyx-icon>

      <p>
        {{
          I18N + '.afterBusinessHoursWarning'
            | translate: { time: time | onyxTime }
        }}
        <br />
        @if (pointOfInterest()?.businessHours; as businessHours) {
          {{ businessHours | businessHours }}
        }
      </p>
    </onyx-message>
  }
</form>
