<onyx-modal
  type="center"
  heading="labels.settings"
  [close$]="close$"
  (closeModal)="dialogRef.close($event)"
>
  <form [formGroup]="form">
    @let controls = form.controls;
    <onyx-dropdown
      [formControl]="controls.vehicleCategory"
      label="labels.vehicleCategory"
      [options]="vehicleCategories$ | async"
      (selectedValueChange)="vehicleCategory$.next($event)"
    ></onyx-dropdown>

    <div class="placeholder"></div>
    <div class="placeholder"></div>

    <onyx-dropdown
      [formControl]="controls.fuelType"
      label="labels.fuelType"
      [options]="fuelTypes$ | async"
      (selectedOptionChange)="fuelType$.next($any($event))"
    ></onyx-dropdown>

    <onyx-text-field
      [formControl]="controls.fuelAverageConsumption"
      label="labels.averageFuelConsumption"
      [decimalPlaces]="2"
      [unit]="(fuelAverageConsumptionUnit$ | async) ?? ''"
    ></onyx-text-field>

    @let fuelPriceControls = controls.fuelPrice.controls;
    <onyx-form-group
      [label]="(fuelPriceLabel$ | async) ?? ''"
      [controls]="[fuelPriceControls.value, fuelPriceControls.currency]"
      (labelClick)="fuelPriceValueControlElement.focus()"
    >
      <onyx-text-field
        [formControl]="fuelPriceControls.value"
        [decimalPlaces]="2"
        [showErrors]="false"
        #fuelPriceValueControlElement
      ></onyx-text-field>

      <onyx-dropdown
        [formControl]="fuelPriceControls.currency"
        [options]="currencies$ | async"
        [showErrors]="false"
      ></onyx-dropdown>
    </onyx-form-group>

    <onyx-dropdown
      class="span"
      [formControl]="controls.excludeCountries"
      label="labels.exclude"
      [options]="countries$ | async"
      [multiple]="true"
      [search]="true"
      [optional]="true"
    ></onyx-dropdown>

    <div class="span avoid-features" [formGroup]="controls.avoidFeatures">
      <onyx-input-label label="labels.avoid"></onyx-input-label>

      <div class="items">
        @for (feature of FEATURES; track feature) {
          <onyx-toggle
            [formControlName]="feature"
            [label]="feature | i18n: DictionaryCode.CALCULATE_ROUTE_FEATURE"
          ></onyx-toggle>
        }
      </div>
    </div>
  </form>

  <onyx-button
    bottomLeftOptions
    type="outlined"
    color="black"
    [spinner]="loading()"
    (click)="restoreDefaults()"
  >
    {{ 'labels.restoreDefaults' | translate }}
  </onyx-button>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'labels.cancel' | translate }}
    </onyx-button>

    <onyx-button [disabled]="loading()" (click)="save()">
      {{ 'labels.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
