@if (route(); as route) {
  @let summary = route.summary;
  <onyx-icon-box class="item" [color]="complianceColor()">
    <onyx-icon name="clock" [size]="16"></onyx-icon>

    <p class="f-paragraph-medium-3">
      <span class="f-gray">{{ 'labels.drivingTime' | translate }}</span>

      <span>
        {{
          summary.duration | duration: { format: 'to', size: 'm', units: ['h'] }
        }}
      </span>

      @if (compliance()?.routeCompliance; as routeCompliance) {
        <span class="f-gray">
          · {{ routeCompliance | i18n: 'labels' | translate }}
        </span>
      }
    </p>
  </onyx-icon-box>

  @let showFuelSummary =
    !!(routeConfig()?.fuelType && route.summary.adBlueConsumption);
  <div
    class="item"
    [onyxTooltip]="fuelSummaryTooltipTemplate"
    onyxTooltipColor="white"
    [onyxTooltipEnabled]="showFuelSummary"
  >
    <onyx-icon-box color="violet">
      <onyx-icon name="gas-station" [size]="16"></onyx-icon>

      <p class="f-paragraph-medium-3">
        <span class="f-gray">{{ 'labels.fuel' | translate }}</span>

        <span>
          @if (summary.fuelConsumption; as fuelConsumption) {
            {{ fuelConsumption / 100 | number }} {{ fuelUnit$ | async }}
            @if (routeConfig()?.fuelType; as fuelType) {
              {{ fuelType | i18n: DictionaryCode.FUEL_TYPE | translate }}
            }

            ·
            @if (summary.totalFuelCost; as totalFuelCost) {
              {{ totalFuelCost | onyxAmount: 'resultCurrency' }}
            } @else {
              ??
            }
          } @else {
            -
          }
        </span>
      </p>
    </onyx-icon-box>

    @if (showFuelSummary) {
      <onyx-tooltip tooltip></onyx-tooltip>
    }

    <ng-template #fuelSummaryTooltipTemplate>
      <app-fuel-summary-tooltip
        [type]="routeConfig()!.fuelType!"
        [unit]="fuelUnit$ | async"
        [summary]="route.summary"
      ></app-fuel-summary-tooltip>
    </ng-template>
  </div>

  <onyx-icon-box class="item" color="violet">
    <onyx-icon name="finance" [size]="16"></onyx-icon>

    <p class="f-paragraph-medium-3">
      <span class="f-gray">{{ 'labels.rate' | translate }}</span>

      <span>
        @if (pricePerKm(); as pricePerKm) {
          {{ pricePerKm | onyxAmount }}/km
        } @else {
          -
        }
      </span>
    </p>
  </onyx-icon-box>

  <div
    class="item"
    [onyxTooltip]="tollsTooltipTemplate"
    onyxTooltipColor="white"
    [onyxTooltipEnabled]="route.summary.tolls.length !== 0"
  >
    <onyx-icon-box color="violet">
      <onyx-icon name="road" [size]="16"></onyx-icon>

      <p class="f-paragraph-medium-3">
        <span class="f-gray">{{ 'labels.route' | translate }}</span>

        <span> {{ summary.distance | onyxDistance }}</span>
        <span> · {{ summary.tollsCost | onyxAmount: 'resultCurrency' }}</span>
      </p>
    </onyx-icon-box>

    <onyx-tooltip tooltip></onyx-tooltip>

    <ng-template #tollsTooltipTemplate>
      <app-tolls-tooltip [tolls]="route.summary.tolls"></app-tolls-tooltip>
    </ng-template>
  </div>
} @else {
  @for (skeletonSize of [187, 157, 120, 120]; track skeletonSize) {
    <div class="skeleton">
      <div class="icon"></div>
      <div class="content" [style.width.px]="skeletonSize"></div>
    </div>
  }
}
