<onyx-section [heading]="I18N + '.heading'">
  <form>
    <div class="points">
      @for (point of points(); track point.form) {
        <app-order-point-form
          [form]="$any(point.form)"
          [category]="point.category"
          [type]="point.type"
          [typeIndex]="point.typeIndex"
          [goods]="point.goods"
          [first]="$first"
          [last]="$last"
          [pointTypes]="pointTypes()"
          (movePoint)="movePoint($index, $event)"
          (removePoint)="removePoint($index)"
        ></app-order-point-form>
      } @empty {
        <p class="empty f-regular-1">{{ I18N + '.empty' | translate }}</p>
      }
    </div>

    <div class="toolbar">
      @let options = pointTypesOptions();
      @for (point of options?.buttons; track point.value) {
        @let tooltip = pointsDisabledCategories()[point.category];
        @let disabled = !!tooltip;
        <onyx-button
          type="outlined"
          color="black"
          [disabled]="disabled"
          [onyxTooltip]="I18N + '.' + tooltip"
          [onyxTooltipEnabled]="disabled"
          (click)="!disabled ? addPoint(point) : null"
        >
          <onyx-icon
            [class]="point.color"
            [name]="point.icon"
            [size]="16"
          ></onyx-icon>

          {{ point.name }}
        </onyx-button>
      }

      @if (isDedicated()) {
        <onyx-icon-button
          type="transparent"
          color="black"
          size="l"
          [onyxDropdownOptions]="options?.dropdown"
          (onyxDropdownValueChange)="$event?.()"
        >
          <onyx-icon name="arrow-double-down" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }
    </div>
  </form>
</onyx-section>
