import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import {
  OnyxDatePipe,
  OnyxTimePipe,
  OnyxTooltipDirective,
  OnyxWidgetBoxComponent,
} from '@onyx/angular';
import { BusinessHoursPipe } from '../../../../../common/pipes/business-hours.pipe';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { OrderPoint } from '../../../common/interfaces/order-point';

@Component({
  selector: 'app-order-modal-point-time-windows',
  imports: [OnyxWidgetBoxComponent, OnyxTooltipDirective],
  providers: [OnyxTimePipe, OnyxDatePipe, BusinessHoursPipe],
  templateUrl: './order-modal-point-time-windows.component.html',
  styleUrl: './order-modal-point-time-windows.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPointTimeWindowsComponent {
  public point = input.required<OrderPoint>();

  protected pointTimeWindows = computed(() =>
    this.orderHelper.getTimeWindows(this.point()),
  );

  constructor(private orderHelper: OrderHelper) {}
}
