<div
  class="avatar {{ type() }} {{ size() }} {{ color() }}"
  [ngClass]="{
    hover: showHover(),
    'f-regular-4': size() === 's',
    'f-paragraph-medium-2': size() === 'l',
  }"
>
  @if (type() === 'user') {
    {{ initials() || '?' }}

    @if (user()?.avatar) {
      <div
        class="backdrop {{ size() }}"
        [ngClass]="{ hover: showHover() }"
      ></div>
    }
  } @else if (type() === 'company') {
    @if (company() && company()?.logo == null) {
      <onyx-icon name="contractor" [size]="companyIconSize()"></onyx-icon>
    } @else {
      <span>?</span>
    }
  }

  <div
    class="image {{ size() }}"
    [ngStyle]="{ background: background$() | async }"
  ></div>
</div>
