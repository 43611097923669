import { DictionariesDriverPermissionForm } from '../../dictionaries/dictionaries-driver-permissions/dictionaries-driver-permission-modal/dictionaries-driver-permission-modal.component';
import { DictionariesGoodTypeForm } from '../../dictionaries/dictionaries-good-type/dictionaries-good-type-modal/dictionaries-good-type-modal.component';
import { DictionariesUnitForm } from '../../dictionaries/dictionaries-units/dictionaries-unit-modal/dictionaries-unit-modal.component';
import { DictionariesVehicleParameterForm } from '../../dictionaries/dictionaries-vehicle-parameters/dictionaries-vehicle-parameter-modal/dictionaries-vehicle-parameter-modal.component';
import { CompanyDriverPermission } from '../interfaces/company-driver-permission';
import { CompanyGoodType } from '../interfaces/company-good-type';
import { CompanyUnit } from '../interfaces/company-unit';
import { CompanyVehicleParameter } from '../interfaces/company-vehicle-parameter';

export enum CompanyDictionaryCode {
  GOOD_TYPE = 'good-type',
  UNIT = 'unit',
  DRIVER_PERMISSION = 'driver-permission',
  VEHICLE_PARAMETER = 'vehicle-parameter',
}

export interface CompanyDictionaryByCode {
  [CompanyDictionaryCode.DRIVER_PERMISSION]: CompanyDriverPermission;
  [CompanyDictionaryCode.GOOD_TYPE]: CompanyGoodType;
  [CompanyDictionaryCode.UNIT]: CompanyUnit;
  [CompanyDictionaryCode.VEHICLE_PARAMETER]: CompanyVehicleParameter;
}

export interface CompanyDictionaryFormByCode {
  [CompanyDictionaryCode.DRIVER_PERMISSION]: DictionariesDriverPermissionForm;
  [CompanyDictionaryCode.GOOD_TYPE]: DictionariesGoodTypeForm;
  [CompanyDictionaryCode.UNIT]: DictionariesUnitForm;
  [CompanyDictionaryCode.VEHICLE_PARAMETER]: DictionariesVehicleParameterForm;
}
