<onyx-widget-box heading="labels.status" [color]="statusColor()">
  <div class="container">
    @if (statusData().icon; as icon) {
      <onyx-icon
        class="{{ statusColor() }}"
        [class.animation]="icon === 'vehicle-waiting-alt-two-tone'"
        [name]="icon"
        [size]="24"
      ></onyx-icon>
    }

    <onyx-badge [color]="statusColor()">
      {{ statusData().name | translate }}
    </onyx-badge>

    @if (paidPercentage(); as percentage) {
      <onyx-badge [color]="statusColor()"> {{ percentage }}% </onyx-badge>
    }
  </div>
</onyx-widget-box>
