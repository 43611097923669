<section>
  <onyx-information-heading>
    {{ 'labels.route' | translate }}

    <onyx-button
      action
      type="outlined"
      color="black"
      size="s"
      [disabled]="!hasRouteEdit()"
      onyxTooltip="labels.routeBlocked"
      [onyxTooltipEnabled]="!hasRouteEdit()"
      (click)="hasRouteEdit() ? planRoute() : null"
    >
      <onyx-icon name="route" [size]="16"></onyx-icon>

      {{ 'labels.planRoute' | translate }}
    </onyx-button>
  </onyx-information-heading>

  <div class="route">
    @let loadingAddress = loadingPoints().point.address;
    @let unloadingAddress = unloadingPoints().point.address;

    <onyx-data-widget
      [headingIcon]="{ name: 'loading', size: 16, color: 'black' }"
    >
      <app-address
        heading
        [address]="loadingAddress"
        format="city"
      ></app-address>

      <div class="list">
        <p class="label">
          {{ 'labels.loadingsCount' | translate }}
        </p>

        <p>{{ loadingPoints().count }}</p>
      </div>
    </onyx-data-widget>

    <onyx-data-widget
      [headingIcon]="{ name: 'unloading', size: 16, color: 'black' }"
    >
      <app-address
        heading
        [address]="unloadingAddress"
        format="city"
      ></app-address>

      <div class="list">
        <p class="label">
          {{ 'labels.unloadingsCount' | translate }}
        </p>

        <p>{{ unloadingPoints().count }}</p>
      </div>
    </onyx-data-widget>
  </div>
</section>
