<onyx-modal
  type="right"
  [heading]="pointOfInterest()?.name ?? ''"
  [loading]="loading()"
  [(error)]="error"
  [close$]="close$"
  (errorChange)="pointOfInterest$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container rightOptions>
    @if (pointOfInterest(); as pointOfInterest) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        onyxTooltip="labels.options"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
      </onyx-icon-button>

      <onyx-icon-button
        color="black"
        type="outlined"
        size="m"
        [circle]="true"
        onyxTooltip="labels.edit"
        (click)="edit()"
      >
        <onyx-icon name="edit" [size]="16"></onyx-icon>
      </onyx-icon-button>

      <onyx-icon-button
        color="blue"
        type="primary"
        size="m"
        [circle]="true"
        onyxTooltip="labels.call"
        (click)="ActionHelper.makeCall(pointOfInterest.phone)"
      >
        <onyx-icon name="telephone" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </ng-container>

  @if (pointOfInterest(); as pointOfInterest) {
    <div class="container">
      <onyx-map [defer]="true"></onyx-map>

      <onyx-information-heading>
        {{ 'labels.location' | translate }}
      </onyx-information-heading>

      <onyx-information-row label="labels.address">
        <app-address
          [address]="pointOfInterest.address"
          format="short"
        ></app-address>

        <div class="copy">
          <onyx-icon-button
            type="transparent"
            size="s"
            color="black"
            (click)="copyAddress(pointOfInterest.address)"
          >
            <onyx-icon name="copy" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      </onyx-information-row>

      <onyx-information-row label="labels.coordinates">
        <app-address
          [address]="pointOfInterest.address"
          format="coordinates"
        ></app-address>

        <div class="copy">
          <onyx-icon-button
            type="transparent"
            size="s"
            color="black"
            (click)="copyCoordinates(pointOfInterest.address)"
          >
            <onyx-icon name="copy" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      </onyx-information-row>

      <onyx-information-heading>
        {{ 'labels.openingHours' | translate }}
      </onyx-information-heading>

      @let businessHours = pointOfInterest.businessHours;
      <onyx-information-row
        [label]="businessHours | businessHours: 'weekday' : 'long' : 'label'"
      >
        {{ businessHours | businessHours: 'weekday' : 'long' : 'value' }}
      </onyx-information-row>

      <onyx-information-row
        [label]="businessHours | businessHours: 'saturday' : 'long' : 'label'"
      >
        {{ businessHours | businessHours: 'saturday' : 'long' : 'value' }}
      </onyx-information-row>

      <onyx-information-row
        [label]="businessHours | businessHours: 'sunday' : 'long' : 'label'"
      >
        {{ businessHours | businessHours: 'sunday' : 'long' : 'value' }}
      </onyx-information-row>

      <onyx-information-heading>
        {{ 'labels.times' | translate }}
      </onyx-information-heading>

      <onyx-information-row label="labels.averageServiceTime">
        {{
          pointOfInterest.time.averageServiceTime
            | duration: { format: 'to', size: 'm', units: ['h'] }
        }}
      </onyx-information-row>

      <onyx-information-heading>
        {{ 'labels.contactData' | translate }}
      </onyx-information-heading>

      <onyx-information-row label="labels.phone">
        {{ pointOfInterest.phone | onyxPhone }}

        @if (pointOfInterest.phone; as phone) {
          <div class="copy">
            <onyx-icon-button
              type="transparent"
              size="s"
              color="black"
              (click)="copyPhone(phone)"
            >
              <onyx-icon name="copy" [size]="16"></onyx-icon>
            </onyx-icon-button>
          </div>
        }
      </onyx-information-row>

      <div class="chips">
        <onyx-chips
          [chips]="chips()"
          type="secondary"
          [single]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>
      </div>

      @switch (group()[0]) {
        <!-- @case (PointOfInterestModalSection.REALIZATIONS) { // TEMP: N/A
          <onyx-spinner></onyx-spinner>
        } -->
        @case (PointOfInterestModalSection.ASSIGNED_CONTRACTORS) {
          <onyx-table
            [data]="pointOfInterest.contractors"
            [columns]="COLUMNS"
            tableSize="s"
            [tableShadow]="false"
            [rowSize]="48"
            [rowOptions]="getContractorOptions.bind(this)"
            [(pagination)]="pagination"
            [notFound]="NOT_FOUND"
          ></onyx-table>
        }
        @case (PointOfInterestModalSection.NOTE) {
          <app-modal-note
            [note]="pointOfInterest.note"
            [route]="
              PointsOfInterestRoute.EDIT_POINT_OF_INTEREST.replace(
                ':uuid',
                pointOfInterest.uuid
              )
            "
            [close$]="close$"
          ></app-modal-note>
        }
      }
    </div>
  }
</onyx-modal>
