import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { chain } from 'lodash';
import { ValidationSeverity } from '../../../enums/validation-severity';
import { ValidationIssue } from '../../../interfaces/validation/validation-issue';

@Component({
  selector: 'app-validation-tooltip',
  imports: [TranslatePipe],
  templateUrl: './validation-tooltip.component.html',
  styleUrl: './validation-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationTooltipComponent {
  public issues = input.required<ValidationIssue[]>();

  protected groupedIssues = computed(() =>
    chain(this.issues())
      .groupBy((issue) => issue.severity)
      .thru((groups) => [
        {
          heading: 'labels.criticalErrors',
          issues: groups[ValidationSeverity.ERROR] ?? [],
        },
        {
          heading: 'labels.warnings',
          issues: groups[ValidationSeverity.WARNING] ?? [],
        },
      ])
      .filter(({ issues }) => issues.length > 0)
      .value(),
  );
}
