import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { chain } from 'lodash';
import { PartialDeep } from 'type-fest';
import { CacheService } from '../../../../common/services/cache.service';
import { DelegationForm } from '../../delegation-form/delegation-form.component';
import { DelegationsQueryParamKey } from '../enums/delegations-query-param-key';
import { DelegationsStorageKey } from '../enums/delegations-storage-key';

export const addDelegationResolver: ResolveFn<{
  form: PartialDeep<DelegationForm>;
}> = (route) => {
  const cacheService = inject(CacheService);

  const cacheKey = DelegationsStorageKey.ADD_DELEGATION_FORM;
  const cache = cacheService.get<PartialDeep<DelegationForm>>(cacheKey, {
    keepOnNavigation: true,
  });

  const driverAndVehicle = chain(DelegationsQueryParamKey)
    .values()
    .map((key) => [key, route.queryParamMap.get(key)])
    .filter(([, value]) => !!value)
    .fromPairs()
    .value();

  return { form: cache ?? { driverAndVehicle } };
};
