import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  OnyxModalService,
  OnyxOptionsGroup,
  OnyxToastService,
} from '@onyx/angular';
import { isString } from 'lodash';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { HelperOptions } from '../../../../common/interfaces/utilities/helper-options';
import {
  DelegationActionModalComponent,
  DelegationActionModalData,
  DelegationActionModalMode,
} from '../../delegation-action-modal/delegation-action-modal.component';
import { DelegationForm } from '../../delegation-form/delegation-form.component';
import {
  DelegationModalComponent,
  DelegationModalData,
} from '../../delegation-modal/delegation-modal.component';
import { DelegationsRoute } from '../../delegations.routes';
import { DelegationStatus } from '../enums/delegation-status';
import { Delegation, SimplifiedDelegation } from '../interfaces/delegation';
import { DelegationsService } from '../services/delegations.service';

@Injectable({
  providedIn: 'root',
})
export class DelegationHelper {
  private readonly I18N = 'delegations';

  constructor(
    private router: Router,
    private modalService: OnyxModalService,
    private delegationService: DelegationsService,
    private toastService: OnyxToastService,
  ) {}

  public getOptions(
    delegation: Delegation,
    options: HelperOptions,
  ): OnyxOptionsGroup<() => void>[] {
    return [
      {
        options: [
          ...([DelegationStatus.ACTIVE, DelegationStatus.PLANNED].includes(
            delegation.status.value,
          )
            ? [
                {
                  name: 'labels.edit',
                  value: () => this.edit(delegation.uuid, options),
                  leftIcon: { name: 'edit' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
        ],
      },
      {
        options: [
          ...(delegation.status.value === DelegationStatus.PLANNED
            ? [
                {
                  name: `${this.I18N}.activateDelegation`,
                  value: () =>
                    this.openDelegationActionModal(
                      DelegationActionModalMode.ACTIVATE,
                      delegation,
                    ),
                  leftIcon: { name: 'check' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
          ...(delegation.status.value === DelegationStatus.ACTIVE
            ? [
                {
                  name: `${this.I18N}.finishDelegation`,
                  value: () =>
                    this.openDelegationActionModal(
                      DelegationActionModalMode.FINISH,
                      delegation,
                    ),
                  leftIcon: { name: 'check' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
        ],
      },
      {
        options: [
          ...(delegation.status.value === DelegationStatus.PLANNED
            ? [
                {
                  name: `${this.I18N}.cancelDelegation`,
                  value: () => this.cancelDelegation(delegation.uuid),
                  leftIcon: { name: 'close' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
        ],
      },
    ].filter((group) => group.options.length);
  }

  public openModal(delegation: DelegationModalData): void {
    this.modalService.open<DelegationModalData>(
      DelegationModalComponent,
      delegation,
    );
  }

  public edit(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(
      `${DelegationsRoute.EDIT_DELEGATION.replace(':uuid', uuid)}`,
    );
    CommonHelper.handleOptions(uuid, options);
  }

  public openDelegationActionModal(
    mode: DelegationActionModalMode,
    delegation: Delegation,
  ): void {
    this.modalService.open<DelegationActionModalData>(
      DelegationActionModalComponent,
      { mode, delegation },
    );
  }

  private cancelDelegation(uuid: string): void {
    this.delegationService.cancelDelegation(uuid).subscribe({
      next: () =>
        this.toastService.showSuccess(`${this.I18N}.delegationCanceled`),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  public static hasEdit(delegation: Delegation | DelegationStatus): boolean {
    const status = isString(delegation) ? delegation : delegation.status.value;
    return [DelegationStatus.ACTIVE, DelegationStatus.PLANNED].includes(status);
  }

  public static isArchived(delegation: Delegation | DelegationStatus): boolean {
    const status = isString(delegation) ? delegation : delegation.status.value;
    return status === DelegationStatus.ARCHIVED;
  }

  public static fromDto(dto: Delegation): DelegationForm {
    return {
      driverAndVehicle: {
        primaryDriverUuid: dto.primaryDriver.uuid,
        secondaryDriverUuid: dto.secondaryDriver?.uuid ?? null,
        vehicleUuid: dto.vehicle.uuid,
        trailerUuid: dto.trailer?.uuid ?? null,
      },
      dailyReturns: {
        daysOfWeek: dto.daysOfWeek,
        hasDailyReturns: dto.hasDailyReturns,
        hasSelectedDays: dto.daysOfWeek != null,
      },
      start: {
        departurePlace: dto.start.address,
        departureDate: dto.start.date,
        departureTime: dto.start.time,
      },
      end: {
        arrivalDate: dto.end.date,
        arrivalPlace: dto.end.address,
        arrivalTime: dto.end.time,
      },
      note: { content: dto.note?.content ?? null },
    };
  }

  public static isDelegationType(
    delegation: SimplifiedDelegation | Delegation,
  ): delegation is Delegation {
    return 'status' in delegation;
  }
}
