<button
  class="chip {{ color() }} f-medium-1"
  [ngClass]="{ active: active(), disabled: disabled() }"
>
  @if (chip().leftIcon; as icon) {
    <onyx-icon
      [name]="icon.name"
      [size]="icon.size"
      [frame]="icon.frame"
    ></onyx-icon>
  }

  <div class="content">
    <p>{{ chip().name | translate }}</p>

    @if (chip().count != null) {
      <span class="number f-medium-3">{{ chip().count }}</span>
    }
  </div>
</button>
