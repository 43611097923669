import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  OnInit,
  output,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDatepickerComponent,
  OnyxDatePipe,
  OnyxFormGroupComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxSuggestionsComponent,
  OnyxTime,
  OnyxTimepickerComponent,
  OnyxTimeRange,
  onyxTimeRangeValidator,
} from '@onyx/angular';
import { map } from 'rxjs';
import { CommonHelper } from '../../../../../../../common/helpers/common.helper';
import { SuggestionHelper } from '../../../../../../../common/helpers/suggestions.helper';
import { ValidationHelper } from '../../../../../../../common/helpers/validation.helper';
import { PointOfInterest } from '../../../../../../management-panel/points-of-interest/common/interfaces/point-of-interest';
import { OrderTimeWindowType } from '../../../../../common/enums/order-time-window-type';

export type OrderPointTimeWindowFormGroup = ReturnType<
  typeof OrderPointTimeWindowFormComponent.buildForm
>;

@Component({
  selector: 'app-order-point-time-window-form',
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    OnyxIconComponent,
    OnyxTimepickerComponent,
    NgClass,
    OnyxDatepickerComponent,
    OnyxSuggestionsComponent,
    OnyxIconButtonComponent,
    OnyxButtonComponent,
    OnyxFormGroupComponent,
  ],
  templateUrl: './order-point-time-window-form.component.html',
  styleUrl: './order-point-time-window-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPointTimeWindowFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.points.timeWindow';
  protected readonly TIME_RANGE_SUGGESTIONS =
    SuggestionHelper.getTimeRangeSuggestions();
  protected readonly DATE_SUGGESTIONS = SuggestionHelper.getDateSuggestions(
    this.translateService,
    this.datePipe,
  );

  protected readonly OrderTimeWindowType = OrderTimeWindowType;

  public form = input.required<OrderPointTimeWindowFormGroup>();
  public first = input.required<boolean>();
  public last = input.required<boolean>();
  public type = input.required<OrderTimeWindowType>();
  public pointOfInterest = input.required<PointOfInterest | null>();

  public add = output<void>();
  public remove = output<void>();

  protected isOverlapping!: Signal<boolean>;

  protected timeWindowTimeLabel = computed(
    () =>
      ({
        [OrderTimeWindowType.INTERVAL]: 'labels.interval',
        [OrderTimeWindowType.FIX]: 'Fix',
        [OrderTimeWindowType.OPENING_HOURS]: 'labels.openingHours',
      })[this.type()],
  );

  constructor(
    private injector: Injector,
    private translateService: TranslateService,
    private datePipe: OnyxDatePipe,
  ) {}

  public ngOnInit(): void {
    this.isOverlapping = toSignal(
      this.form().statusChanges.pipe(
        map(() => this.form().hasError('orderPointTimeWindowOverlap')),
      ),
      { initialValue: false, injector: this.injector },
    );

    const controls = this.form().controls;
    effect(
      () => {
        const type = this.type();

        controls.date.setErrors(null);
        ValidationHelper.toggleControls(
          controls.time,
          type === OrderTimeWindowType.FIX,
        );
        ValidationHelper.toggleControls(
          controls.timeRange,
          type !== OrderTimeWindowType.FIX,
        );
        this.form().updateValueAndValidity();

        if (type === OrderTimeWindowType.OPENING_HOURS) {
          ValidationHelper.disableControls(controls.timeRange);
        }
      },
      { injector: this.injector },
    );

    const dateValue = toSignal(controls.date.valueChanges, {
      initialValue: controls.date.value,
      injector: this.injector,
    });
    effect(
      () => {
        if (this.type() !== OrderTimeWindowType.OPENING_HOURS) return;

        const [pointOfInterest, date] = [this.pointOfInterest(), dateValue()];
        if (!pointOfInterest || !date) {
          controls.date.setErrors(null);
          controls.timeRange.setValue(null);
          return;
        }

        const openingHours = CommonHelper.getOpeningHours(
          date,
          pointOfInterest.businessHours,
        );
        controls.date.setErrors(
          !openingHours ? { businessHoursClosedDay: true } : null,
        );
        controls.timeRange.setValue(openingHours);
      },
      { injector: this.injector },
    );
  }

  public static buildForm(fb: NonNullableFormBuilder) {
    return fb.group({
      date: fb.control<string | null>(null, [Validators.required]),
      time: fb.control<OnyxTime | null>(null, [Validators.required]),
      timeRange: fb.control<OnyxTimeRange | null>(null, [
        Validators.required,
        onyxTimeRangeValidator,
      ]),
    });
  }
}
