<section [class.button]="showAddButton()">
  @if (showAddButton()) {
    <onyx-button color="black" type="outlined" size="s" (click)="addDocument()">
      <onyx-icon left class="add-icon" name="zoom-in" [size]="16"></onyx-icon>

      {{ 'labels.add' | translate }}
    </onyx-button>
  }

  <app-documents-form-list
    [documents]="order().documents"
    [uuid]="order().uuid"
    placeholder="orders.orderModal.noDocuments"
    [close$]="close$()"
  ></app-documents-form-list>
</section>
