import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  forwardRef,
  HostListener,
  Injector,
  input,
  model,
  OnInit,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  map,
  Subject,
  switchMap,
} from 'rxjs';
import {
  OnyxDropdownDirective,
  OnyxOption,
  OnyxTooltipDirective,
} from '../../../directives';
import { AddressHelper, OverflowHelper } from '../../../helpers';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxHighlightPipe } from '../../../pipes';
import { OnyxModalService } from '../../../services';
import { OnyxAddressService } from '../../../services/onyx-address.service';
import {
  OnyxClearButtonComponent,
  OnyxIconButtonComponent,
} from '../../buttons';
import { OnyxIconComponent } from '../../icons';
import { OnyxInputLabelComponent } from '../../labels';
import { OnyxTextFieldComponent } from '../../text-field/onyx-text-field/onyx-text-field.component';
import { OnyxTooltipContext } from '../../tooltip';
import { OnyxAddressType } from '../enums';
import { OnyxAddress, OnyxCustomAddress } from '../interfaces';
import {
  OnyxAddressPickerModalComponent,
  OnyxAddressPickerModalData,
} from '../onyx-address-picker-modal/onyx-address-picker-modal.component';

@Component({
  selector: 'onyx-address-input',
  imports: [
    NgClass,
    OnyxClearButtonComponent,
    OnyxDropdownDirective,
    OnyxFormControlErrorComponent,
    OnyxHighlightPipe,
    OnyxIconComponent,
    OnyxInputLabelComponent,
    TranslatePipe,
    OnyxTextFieldComponent,
    OnyxIconButtonComponent,
    OnyxTooltipDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxAddressInputComponent),
      multi: true,
    },
  ],
  templateUrl: './onyx-address-input.component.html',
  styleUrl: './onyx-address-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxAddressInputComponent
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor, AfterViewInit, OnInit
{
  protected readonly I18N = `${I18N_NAMESPACE}.address`;

  protected readonly OnyxAddressType = OnyxAddressType;
  protected readonly OverflowHelper = OverflowHelper;

  public label = input<string>();
  public hint = input<OnyxTooltipContext>();
  public value = model<OnyxAddress | null>(null);
  public types = input([OnyxAddressType.CUSTOM]);
  public limit = input(5);
  public width = input('100%');
  public notFoundMessage = input(`${this.I18N}.noResults`);
  public showAddOption = input<boolean | 'value'>(false);
  public addOptionMessage = input<string>();
  public showPicker = input(true);
  public showApartment = input(false);
  public apartmentWidth = input('40%');
  public gap = input(8);
  public placeholder = input(`${this.I18N}.placeholder`);
  public showOptional = input(true);
  public showErrors = input(true);

  public nameChange = output<string>();
  public addOption = output<string | undefined>();

  protected onChange?: (value: OnyxAddress | null) => void;
  protected onTouched?: () => void;
  protected disabled = signal(false);
  protected query = signal('');
  protected apartmentNumber = signal<string | null>(null);
  protected expanded = signal(false);

  protected inputValue = computed(() => {
    const address = this.value();
    if (!address) return '';

    if (address.type === OnyxAddressType.POINT_OF_INTEREST) {
      return `${address.poiName}, ${address.label}`;
    }
    return address.label;
  });

  private _addresses$ = new BehaviorSubject<OnyxOption<OnyxAddress>[]>([]);
  protected get addresses$() {
    return this._addresses$.asObservable();
  }

  private inputElementRef =
    viewChild.required<ElementRef<HTMLInputElement>>('inputElement');
  private request$ = new Subject<void>();

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private onyxAddressService: OnyxAddressService,
    private elementRef: ElementRef<HTMLElement>,
    private modalService: OnyxModalService,
  ) {
    super(injector, destroyRef);

    effect(() => {
      this.elementRef.nativeElement.style.width = this.width();
      this.elementRef.nativeElement.style.gap = `${this.gap()}px`;
    });

    this.request$
      .pipe(
        debounceTime(300),
        switchMap(() =>
          this.query()
            ? this.onyxAddressService
                .geocode(this.types(), this.query(), this.limit())
                .pipe(map((data) => data.items))
            : this.onyxAddressService.getRecentSearches(
                this.types(),
                this.limit(),
              ),
        ),
        map((addresses) =>
          addresses.map((address) => ({
            name: address.label,
            value: address,
          })),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((addresses) => this._addresses$.next(addresses));

    effect(() => {
      this.query();
      this.request$.next();
    });
    effect(() => {
      const value = this.value();
      if (!value || !this.showApartment()) return;

      if (value.apartmentNumber !== this.apartmentNumber()) {
        this.changeValue(value, this.apartmentNumber());
      }
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    toObservable(this.value, { injector: this.injector })
      .pipe(distinctUntilChanged(isEqual), takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => this.writeValue(value));
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.updateInputValue();
  }

  public writeValue(value: OnyxAddress | null): void {
    if (value && 'name' in value) {
      if (value.name) this.nameChange.emit(value.name);
      delete (value as any).name;
    }

    this.value.set(value);
    if (!this.inputElementRef) return;

    if (this.showApartment() && value?.apartmentNumber) {
      this.apartmentNumber.set(value.apartmentNumber);
    }

    this.updateInputValue();
  }

  public registerOnChange(fn: (value: OnyxAddress | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  @HostListener('focusout')
  protected restoreOnFocusout(): void {
    setTimeout(() => {
      if (this.expanded()) return;
      if (this.elementRef.nativeElement.contains(document.activeElement)) {
        return;
      }

      this.query.set('');
      this.updateInputValue();
      this.onTouched?.();
    });
  }

  protected focus() {
    this.inputElementRef().nativeElement.focus();
  }

  protected changeQuery(event: Event): void {
    let { value } = event.target as HTMLInputElement;
    value = value.trim();

    if (!value) {
      this.clearValue();
      return;
    }

    this.query.set(value);
    this.expanded.set(true);
  }

  protected changeValue(
    value: OnyxAddress | null,
    apartmentNumber?: string | null,
  ): void {
    if (value?.houseNumber && apartmentNumber !== undefined) {
      value = {
        ...value,
        apartmentNumber,
      };
      value.label = AddressHelper.composeLabel(value);
    }

    if (value) {
      this.onyxAddressService.addToRecentSearches(value).subscribe();

      if ('name' in value) {
        if (value.name) this.nameChange.emit(value.name);
        delete (value as any).name;
      }
    }

    this.value.set(value);
    this.apartmentNumber.set(value?.apartmentNumber ?? null);
    this.onChange?.(value);
    this.onTouched?.();
  }

  protected clearValue(): void {
    this.query.set('');
    this.value.set(null);
    this.apartmentNumber.set(null);
    this.expanded.set(false);
    this.updateInputValue();
    this.onChange?.(null);
    this.onTouched?.();
  }

  protected openPicker(): void {
    if (this.disabled()) return;
    if (!this.types().includes(OnyxAddressType.CUSTOM)) return;

    const address = this.value();
    if (address && address.type !== OnyxAddressType.CUSTOM) return;

    this.expanded.set(false);
    this.modalService
      .open<
        OnyxAddressPickerModalData,
        OnyxCustomAddress
      >(OnyxAddressPickerModalComponent, { address })
      .result.subscribe((address) => {
        if (address) this.changeValue(address);
      });
  }

  private updateInputValue(): void {
    this.inputElementRef().nativeElement.value = this.inputValue();
  }
}
