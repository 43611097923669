@if (active()) {
  <div class="controls" #controlsElement>
    <ng-container
      *ngTemplateOutlet="
        controlButtonTemplate;
        context: {
          layers: true,
          tooltip: 'layers',
          icon: 'layers',
        }
      "
    ></ng-container>

    <ng-container
      *ngTemplateOutlet="
        controlButtonTemplate;
        context: {
          tooltip: 'zoomIn',
          icon: 'zoom-in',
          click: zoomIn.bind(this),
        }
      "
    ></ng-container>

    <ng-container
      *ngTemplateOutlet="
        controlButtonTemplate;
        context: {
          tooltip: 'zoomOut',
          icon: 'zoom-out',
          click: zoomOut.bind(this),
        }
      "
    ></ng-container>

    @if (sizes()) {
      @switch (mode()) {
        @case (OnyxMapMode.MINIMIZED) {
          <ng-container
            *ngTemplateOutlet="
              controlButtonTemplate;
              context: {
                tooltip: 'maximize',
                icon: 'maximize',
                click: mode.set.bind(this, OnyxMapMode.MAXIMIZED),
              }
            "
          ></ng-container>
        }
        @case (OnyxMapMode.MAXIMIZED) {
          <ng-container
            *ngTemplateOutlet="
              controlButtonTemplate;
              context: {
                tooltip: 'minimize',
                icon: 'minimize',
                click: mode.set.bind(this, OnyxMapMode.MINIMIZED),
              }
            "
          ></ng-container>
        }
      }
    }

    <!-- @if (mode() !== OnyxMapMode.STANDALONE) {
      <ng-container
        *ngTemplateOutlet="
          controlButtonTemplate;
          context: {
            tooltip: 'newTab',
            icon: 'new-tab',
            click: openStandaloneMap.bind(this),
          }
        "
      ></ng-container>
    } -->
  </div>
} @else {
  <div class="inactive-map-information">
    <onyx-icon name="tooltip" [size]="16"></onyx-icon>

    <p>{{ I18N + '.inactiveMapInformation' | translate }}</p>
  </div>
}

<ng-template
  let-layers="layers"
  let-tooltip="tooltip"
  let-click="click"
  let-icon="icon"
  #controlButtonTemplate
>
  @let active = layers && layerButtonActive();
  <onyx-icon-button
    [class.active]="active"
    type="transparent"
    color="black"
    size="m"
    [active]="active"
    [onyxTooltip]="I18N + '.' + tooltip"
    [onyxTooltipPositions]="[OnyxOverlayPosition.LEFT]"
    [onyxTooltipArrowOffsets]="{ y: -4 }"
    [onyxDropdownDisabled]="!layers"
    [onyxDropdownOptions]="LAYER_TYPE_OPTIONS"
    [onyxDropdownValues]="[layerType()]"
    [(onyxDropdownAttached)]="layerButtonActive"
    [onyxDropdownPositions]="[OnyxOverlayPosition.LEFT]"
    [onyxDropdownOverridePositions]="{ overlayX: 'end' }"
    (onyxDropdownValueChange)="layerType.set($event!)"
    (click)="click?.()"
  >
    <onyx-icon [name]="icon" [size]="16"></onyx-icon
  ></onyx-icon-button>
</ng-template>
