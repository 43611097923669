import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxAmountPipe,
  OnyxIconComponent,
  OnyxIconName,
  OnyxRoute,
  OnyxTabsComponent,
} from '@onyx/angular';
import { DurationPipe } from '../../../../../../common/pipes/duration.pipe';
import { I18nPipe } from '../../../../../../common/pipes/i18n.pipe';
import { OrderFormRouteType } from '../../../../common/enums/order-form-route-type';
import { OrderFormService } from '../../../../common/services/order-form.service';

@Component({
  selector: 'app-order-form-route-modal-tabs',
  standalone: true,
  imports: [
    OnyxTabsComponent,
    OnyxAmountPipe,
    OnyxIconComponent,
    DurationPipe,
    I18nPipe,
    TranslatePipe,
  ],
  templateUrl: './order-form-route-modal-tabs.component.html',
  styleUrl: './order-form-route-modal-tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormRouteModalTabsComponent {
  protected readonly I18N = 'orders.orderForm.route';
  protected readonly TYPE_ICONS: Record<OrderFormRouteType, OnyxIconName> = {
    [OrderFormRouteType.CHEAPEST]: 'finance',
    [OrderFormRouteType.FASTEST]: 'dynamic',
    [OrderFormRouteType.ALTERNATIVE]: 'road',
  } as const;

  protected readonly Math = Math;

  protected routes = computed(() => {
    const routes = this.orderFormService.routes();
    const activeRoute = this.orderFormService.activeRoute();
    if (!activeRoute) return null;

    return routes.map((option) => {
      if (option.value === activeRoute) return option;

      const activeRouteCost = activeRoute.summary.totalCost;
      return {
        ...option,
        costDifference: {
          ...option.cost,
          resultCurrencyValue: Math.abs(
            option.cost.resultCurrencyValue -
              activeRouteCost.resultCurrencyValue,
          ),
        },
        costDifferenceTrend:
          option.types.includes(OrderFormRouteType.CHEAPEST) &&
          option.cost.resultCurrencyValue < activeRouteCost.resultCurrencyValue
            ? 'down'
            : 'up',
        durationDifference: Math.abs(
          option.duration - activeRoute.summary.duration,
        ),
        durationDifferenceTrend:
          option.duration < activeRoute.summary.duration ? 'down' : 'up',
      };
    });
  });
  protected activeRoute = this.orderFormService.activeRoute;
  protected hasInvalidAddress = this.orderFormService.hasInvalidAddress;

  constructor(private orderFormService: OrderFormService) {}

  protected changeRoute(route: OnyxRoute): void {
    this.orderFormService.changeRoute(route);
  }
}
