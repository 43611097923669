import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { PartialDeep } from 'type-fest';
import { CacheService } from '../../../../../common/services/cache.service';
import { PointOfInterestForm } from '../../point-of-interest-form/point-of-interest-form.component';
import { PointsOfInterestQueryParamKey } from '../enums/points-of-interest-query-param-key';
import { PointsOfInterestStorageKey } from '../enums/points-of-interest-storage-key';

export const addPointOfInterestResolver: ResolveFn<
  PartialDeep<PointOfInterestForm>
> = (route) => {
  const cacheService = inject(CacheService);

  const cacheKey = PointsOfInterestStorageKey.ADD_POINT_OF_INTEREST_FORM;
  const cache = cacheService.get<PartialDeep<PointOfInterestForm>>(cacheKey, {
    keepOnNavigation: true,
  });

  const name =
    route.queryParamMap.get(PointsOfInterestQueryParamKey.NAME) ?? '';
  return cache ?? { pointInformation: { name } };
};
