@let size = driverData.size;
@let workPhone = driver()?.driverData?.workPhone;
<onyx-modal
  type="right"
  [size]="size"
  [heading]="
    driver()
      ? driver()!.driverData.firstName + ' ' + driver()!.driverData.lastName
      : ''
  "
  [loading]="loading()"
  [extend]="!!workPhone"
  [(error)]="error"
  [close$]="close$"
  (errorChange)="driver$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container headingLeftOptions>
    @if (driver()) {
      <!-- TEMP: Replace with actual tacho -->
      <onyx-icon class="f-gray" name="tacho-offline" [size]="20"></onyx-icon>
    }
  </ng-container>

  <ng-container leftOptions>
    @if (driver(); as driver) {
      <app-unavailability-icon
        [unavailability]="driver.unavailability"
      ></app-unavailability-icon>
    }
  </ng-container>

  <ng-container bottomOptions>
    @if (workPhone; as phone) {
      {{ phone | onyxPhone }}
    }
  </ng-container>

  <ng-container rightOptions>
    @if (driver(); as driver) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        onyxTooltip="labels.options"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon
          class="f-gray"
          name="options-horizontal"
          [size]="16"
        ></onyx-icon>
      </onyx-icon-button>

      @if (workPhone; as phone) {
        <onyx-icon-button
          color="black"
          type="transparent"
          size="m"
          onyxTooltip="labels.call"
          (click)="ActionHelper.makeCall(phone)"
        >
          <onyx-icon class="f-gray" name="telephone" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }

      @if (FleetHelper.hasTelematics(driver.assignedVehicle)) {
        <onyx-icon-button
          color="black"
          type="transparent"
          size="m"
          onyxTooltip="labels.showOnGantt"
          (click)="showOnGantt()"
        >
          <onyx-icon class="f-gray" name="menu-gantt" [size]="16"></onyx-icon>
        </onyx-icon-button>

        <onyx-icon-button
          color="blue"
          type="primary"
          size="m"
          [circle]="true"
          onyxTooltip="labels.showOnMap"
          (click)="showOnMap()"
        >
          <onyx-icon name="menu-map" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }
    }
  </ng-container>

  @if (driver(); as driver) {
    <div class="container {{ size }}">
      <app-driver-modal-main-section
        [driver]="driver"
        [close$]="close$"
      ></app-driver-modal-main-section>

      <div class="chips">
        <onyx-chips
          [chips]="GROUP_CHIPS"
          type="secondary"
          [single]="true"
          [wrap]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>
      </div>

      @switch (group()[0]) {
        @case (DriverModalSection.WORK_TIME) {
          <app-drivers-tachograph
            [primaryDriver]="driver"
            [secondaryDriver]="null"
            type="vehicle"
            format="full"
          ></app-drivers-tachograph>
        }
        <!-- @case (DriverModalSection.DELEGATION) {
          N/A
        }
        @case (DriverModalSection.STATISTICS) {
          N/A
        } -->
        @case (DriverModalSection.UNAVAILABILITIES) {
          <app-unavailabilities [driver]="driver"></app-unavailabilities>
        }
        @case (DriverModalSection.DRIVER_DATA) {
          <app-driver-modal-data
            [driver]="driver"
            [size]="size"
          ></app-driver-modal-data>
        }
        @case (DriverModalSection.DOCUMENTS) {
          <app-driver-modal-documents
            [driver]="driver"
            [size]="size"
          ></app-driver-modal-documents>
        }
        @case (DriverModalSection.NOTE) {
          <app-modal-note
            [note]="driver.note"
            [route]="DriversRoute.EDIT_DRIVER.replace(':uuid', driver.uuid)"
            [close$]="close$"
          ></app-modal-note>
        }
      }
    </div>
  }
</onyx-modal>
