export const ICON_BUTTONS = {
  primary: {
    blue: 'primary blue',
    green: 'primary green',
    red: 'primary red',
    black: 'primary black',
  },
  outlined: {
    black: 'outlined black',
    gray: 'outlined gray',
    blue: 'outlined blue',
  },
  ghost: {
    black: 'ghost black',
    gray: 'ghost gray',
    blue: 'ghost blue',
  },
  transparent: {
    black: 'transparent black',
    gray: 'transparent gray',
    blue: 'transparent blue',
  },
} as const;
