<div class="warning-items">
  @let errorItem = vehicleExpiryStatuses().error[0];
  @let errorCount = vehicleExpiryStatuses().error.length;
  @if (errorCount === 1) {
    <app-fleet-modal-warning
      [color]="errorItem.color"
      [expiryDate]="errorItem.expiryDate"
      [label]="errorItem.key"
      (click)="redirect.emit()"
    >
    </app-fleet-modal-warning>
  } @else if (errorCount > 1) {
    <app-fleet-modal-warning
      [color]="errorItem.color"
      (click)="redirect.emit()"
    >
      <span>
        {{ I18N + '.documentsExpired' | translate: { count: errorCount } }}
      </span>
    </app-fleet-modal-warning>
  }

  @let warningItem = vehicleExpiryStatuses().warning[0];
  @let warningCount = vehicleExpiryStatuses().warning.length;
  @if (warningCount === 1) {
    <app-fleet-modal-warning
      [color]="warningItem.color"
      [expiryDate]="warningItem.expiryDate"
      [label]="warningItem.key"
      (click)="redirect.emit()"
    >
    </app-fleet-modal-warning>
  } @else if (warningCount > 1) {
    <app-fleet-modal-warning
      [color]="warningItem.color"
      [expiryDate]="warningItem.expiryDate"
      (click)="redirect.emit()"
    >
      <span>
        {{ I18N + '.documentsExpire' | translate: { count: warningCount } }}
      </span>
    </app-fleet-modal-warning>
  }
</div>
