import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  linkedSignal,
  viewChild,
} from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxChip,
  OnyxChipsComponent,
  OnyxInformationRowComponent,
  OnyxMapComponent,
  OnyxMapEvent,
  OnyxMapMarkerType,
} from '@onyx/angular';
import { AddressComponent } from '../../../../../common/components/address/address.component';
import { Contractor } from '../../common/interfaces/contractor';
import { ContractorBranch } from '../../common/interfaces/contractor-branch';

@Component({
  selector: 'app-contractor-modal-branches',
  imports: [
    OnyxMapComponent,
    TranslatePipe,
    OnyxChipsComponent,
    OnyxInformationRowComponent,
    AddressComponent,
  ],
  templateUrl: './contractor-modal-branches.component.html',
  styleUrl: './contractor-modal-branches.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorModalBranchesComponent implements AfterViewInit {
  private readonly map = viewChild.required(OnyxMapComponent);

  public contractor = input.required<Contractor>();

  protected branches = computed<OnyxChip<Contractor | ContractorBranch>[]>(
    () => [
      {
        name: 'labels.mainBranch',
        value: this.contractor(),
      },
      ...this.contractor().branches.map((branch) => ({
        name: `${this.translateService.instant('labels.branch')} ${branch.name}`,
        value: branch,
      })),
    ],
  );
  protected activeBranch = linkedSignal<(Contractor | ContractorBranch)[]>(
    () => [this.contractor()],
  );

  constructor(
    private injector: Injector,
    private translateService: TranslateService,
  ) {}

  public ngAfterViewInit(): void {
    effect(
      () => {
        const map = this.map();
        const contractor = this.contractor();

        const addresses = contractor.addresses;
        const drawnMarkers = new Set<string>();

        map.dispatch(
          new OnyxMapEvent.AddUpdateMarker({
            id: 'mainAddress',
            type: OnyxMapMarkerType.BASE,
            coordinates: addresses.mainAddress.coordinates,
          }),
        );
        drawnMarkers.add('mainAddress');
        if (addresses.correspondenceAddress) {
          map.dispatch(
            new OnyxMapEvent.AddUpdateMarker({
              id: 'correspondenceAddress',
              type: OnyxMapMarkerType.CORRESPONDENCE_ADDRESS,
              coordinates: addresses.correspondenceAddress.coordinates,
            }),
          );
          drawnMarkers.add('correspondenceAddress');
        }

        for (const [index, branch] of contractor.branches.entries()) {
          const branchAddresses = branch.addresses;

          const branchMainAddressId = `branch-${index}-mainAddress`;
          map.dispatch(
            new OnyxMapEvent.AddUpdateMarker({
              id: branchMainAddressId,
              type: OnyxMapMarkerType.POINT,
              coordinates: branchAddresses.mainAddress.coordinates,
            }),
          );
          drawnMarkers.add(branchMainAddressId);

          if (branchAddresses.correspondenceAddress) {
            const branchCorrespondenceAddressId = `branch-${index}-correspondenceAddress`;
            map.dispatch(
              new OnyxMapEvent.AddUpdateMarker({
                id: branchCorrespondenceAddressId,
                type: OnyxMapMarkerType.CORRESPONDENCE_ADDRESS,
                coordinates: branchAddresses.correspondenceAddress.coordinates,
              }),
            );
            drawnMarkers.add(branchCorrespondenceAddressId);
          }
        }

        for (const id of map.markers) {
          if (drawnMarkers.has(id)) continue;
          this.map().dispatch(new OnyxMapEvent.RemoveMarker({ id }));
        }

        this.map().dispatch(new OnyxMapEvent.FitContent());
      },
      { injector: this.injector },
    );
  }
}
