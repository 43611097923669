import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxFormMode,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalService,
  OnyxToastService,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { concatMap, filter, from, Subject } from 'rxjs';
import { OrderHelper } from '../../../../../dashboard/orders/common/helpers/order.helper';
import { ValidationHelper } from '../../../../helpers/validation.helper';
import { StorageService } from '../../../../services/storage.service';
import { DocumentsFormGroup } from '../documents-form.component';
import {
  DocumentForm,
  DocumentModalData,
  DocumentModalResult,
  DocumentsModalFormComponent,
} from '../documents-modal-form/documents-modal-form.component';

@Component({
  selector: 'app-documents-form-list',
  imports: [
    OnyxIconComponent,
    TranslatePipe,
    OnyxIconButtonComponent,
    OnyxTooltipDirective,
  ],
  templateUrl: './documents-form-list.component.html',
  styleUrl: './documents-form-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsFormListComponent {
  public documents = input<DocumentForm[]>();
  public form = input<DocumentsFormGroup>();
  public uuid = input<string | null>(null);
  public placeholder = input.required<string>();
  public close$ = input<Subject<void>>();

  constructor(
    private modalService: OnyxModalService,
    private storageService: StorageService,
    private toastService: OnyxToastService,
    private orderHelper: OrderHelper,
  ) {}

  protected editDocument(index?: number): void {
    const form = this.form();
    if (index == null || !form) {
      this.orderHelper.edit(this.uuid()!, {
        options: { close$: this.close$() },
      });
      return;
    }

    const document = form.at(index).getRawValue();
    this.modalService
      .open<DocumentModalData, DocumentModalResult>(
        DocumentsModalFormComponent,
        { mode: OnyxFormMode.EDIT, document },
      )
      .result.pipe(filter((document) => document !== undefined))
      .subscribe((document) => {
        if (document === null) return this.removeDocument(index);
        form.at(index).setValue(document);
      });
  }

  protected downloadDocument(
    index?: number,
    orderDocument?: DocumentForm,
  ): void {
    const document =
      index != null && this.form()
        ? this.form()?.at(index).getRawValue()
        : orderDocument;
    if (!document) return;

    from(document.attachments)
      .pipe(
        concatMap((attachment) => this.storageService.downloadFile(attachment)),
      )
      .subscribe({
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      });
  }

  protected removeDocument(index?: number): void {
    if (index != null) this.form()?.removeAt(index);
  }

  protected showFiles(scans: string[]): void {
    for (const scan of scans) {
      this.storageService.previewFile(scan).subscribe();
    }
  }
}
