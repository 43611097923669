import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxAmount,
  OnyxButtonComponent,
  OnyxInformationHeadingComponent,
  OnyxToastService,
} from '@onyx/angular';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { I18nPipe } from '../../../../../common/pipes/i18n.pipe';
import { OrderStatus } from '../../../common/enums/order-status';
import { Order } from '../../../common/interfaces/order';
import { OrdersService } from '../../../common/services/orders.service';
import { OrderModalPaymentFormComponent } from '../order-modal-payment-form/order-modal-payment-form.component';
import { OrderModalPaymentsHistoryComponent } from '../order-modal-payments-history/order-modal-payments-history.component';

export interface PaymentsHistoryData {
  uuid: string;
  value: OnyxAmount;
  date: string;
  percentage: number;
}

@Component({
  selector: 'app-order-modal-payment-form-container',
  imports: [
    OnyxInformationHeadingComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
    OrderModalPaymentsHistoryComponent,
    TranslatePipe,
    I18nPipe,
    OrderModalPaymentFormComponent,
  ],
  templateUrl: './order-modal-payment-form-container.component.html',
  styleUrl: './order-modal-payment-form-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPaymentFormContainerComponent {
  protected readonly I18N = 'orders.orderModal';

  protected readonly OrderStatus = OrderStatus;

  public order = input.required<Order>();

  protected form = OrderModalPaymentFormComponent.buildForm(this.fb);
  protected loading = signal(false);
  protected isParitallyPaid = signal(false);

  constructor(
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private ordersService: OrdersService,
  ) {}

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const dto = this.form.getRawValue();

    this.loading.set(true);
    this.ordersService
      .payForOrder(this.order().uuid, dto)
      .subscribe({
        next: () => {
          if (dto.partialPayment) {
            this.toastService.showSuccess(`${this.I18N}.toasts.partiallyPaid`);
          } else {
            this.toastService.showSuccess(`${this.I18N}.toasts.paid`);
          }
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected deletePayment(uuid: string) {
    this.ordersService.deletePayment(this.order().uuid, uuid).subscribe({
      next: () =>
        this.toastService.showSuccess(`${this.I18N}.toasts.paymentDeleted`, {
          keepOnNavigation: true,
        }),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }
}
