import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OnyxDropdownOptionsSourceResult,
  OnyxFilterPipe,
  OnyxPaginated,
  OnyxPagination,
  OnyxPhonePipe,
  OnyxToastService,
} from '@onyx/angular';
import { chain } from 'lodash';
import {
  catchError,
  concatMap,
  defaultIfEmpty,
  EMPTY,
  from,
  map,
  Observable,
  of,
  Subject,
  takeWhile,
  tap,
} from 'rxjs';
import { ConflictHelper } from '../../../../../common/helpers/conflict.helper';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { ConflictField } from '../../../../../common/interfaces/utilities/conflict-field';
import { ApiService } from '../../../../../common/services/api.service';
import { ImportProgress } from '../../../../common/interfaces/import-progress';
import { PointOfInterestFormDto } from '../../point-of-interest-form/point-of-interest-form.component';
import { PointsOfInterestImportData } from '../constants/points-of-interest-import-schema';
import { PointOfInterestType } from '../enums/point-of-interest-type';
import { PointOfInterest } from '../interfaces/point-of-interest';

@Injectable({
  providedIn: 'root',
})
export class PointsOfInterestService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private filterPipe: OnyxFilterPipe,
    private phonePipe: OnyxPhonePipe,
    private conflictHelper: ConflictHelper,
    private toastService: OnyxToastService,
  ) {
    super(http);
  }

  public listPointsOfInterest(
    params: {
      type: PointOfInterestType | 'all';
    } & OnyxPagination,
  ): Observable<OnyxPaginated<PointOfInterest>> {
    return this.get('/point-of-interest', {
      params: {
        ...(params.type !== 'all' && { type: params.type }),
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public searchPointsOfInterest(
    query: string,
    limit: number,
    params: Omit<
      Parameters<PointsOfInterestService['listPointsOfInterest']>[0],
      keyof OnyxPagination
    >,
  ): Observable<OnyxDropdownOptionsSourceResult<PointOfInterest>> {
    return this.listPointsOfInterest({
      ...params,
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
    }).pipe(
      map((response) => ({
        options: chain(response.items)
          .map((pointOfInterest) => ({
            name: pointOfInterest.name,
            value: pointOfInterest,
            leftFlag: pointOfInterest.address.countryCode,
          }))
          .orderBy((option) => option.name)
          .thru((options) =>
            this.filterPipe.transform(options, query, ['name']).slice(0, limit),
          )
          .value(),
        totalItems: response.totalItems,
      })),
    );
  }

  public getPointOfInterest(uuid: string): Observable<PointOfInterest> {
    return this.get(`/point-of-interest/${uuid}`);
  }

  public addPointOfInterest(dto: PointOfInterestFormDto): Observable<void> {
    return this.post<void>('/point-of-interest', [dto]).pipe(
      catchError((response) => this.catchConflictErrors(dto, response)),
      tap(() => this._reload$.next()),
    );
  }

  public importPointsOfInterest(
    data: PointsOfInterestImportData[],
  ): Observable<ImportProgress> {
    const dto = data.map(
      (item): PointOfInterestFormDto => ({
        name: item.name,
        address: item.address,
        businessHours: item.businessHours,
        time: { averageServiceTime: item.averageServiceTime },
        phone: item.phone,
        note: { content: item.note },
        contractors: item.assignedContractors.map(({ contractor, gate }) => ({
          uuid: contractor.uuid,
          gate,
        })),
      }),
    );

    return from(dto).pipe(
      concatMap((dto, index) =>
        this.addPointOfInterest(dto).pipe(
          map(() => ({ ok: true, index })),
          defaultIfEmpty({ ok: false, index }),
          catchError((response) => {
            ValidationHelper.handleUnexpectedError(response, this.toastService);
            return of({ ok: false, index });
          }),
        ),
      ),
      takeWhile(({ ok }) => ok, true),
    );
  }

  public editPointOfInterest(
    uuid: string,
    dto: PointOfInterestFormDto,
  ): Observable<void> {
    return this.put<void>(`/point-of-interest/${uuid}`, dto).pipe(
      catchError((response) => this.catchConflictErrors(dto, response)),
      tap(() => this._reload$.next()),
    );
  }

  public deletePointOfInterest(uuid: string): Observable<void> {
    return this.delete<void>(`/point-of-interest/${uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }

  private catchConflictErrors(
    dto: PointOfInterestFormDto,
    response: HttpErrorResponse,
  ): Observable<never> {
    if (response.status !== HttpStatusCode.BadRequest) throw response;

    const confictFields: ConflictField[] = [
      {
        key: '0.name',
        message: 'pointsOfInterest.uniqueErrors.name',
        params: { name: dto.name },
      },
      {
        key: '0.phone',
        message: 'pointsOfInterest.uniqueErrors.phone',
        params: { phone: this.phonePipe.transform(dto.phone!) },
      },
    ];

    if (this.conflictHelper.handleConflictErrors(response, confictFields)) {
      return EMPTY;
    }

    throw response;
  }
}
