@if (widgetData(); as data) {
  <onyx-widget-box [heading]="data.heading" [color]="data?.color ?? 'gray'">
    <div class="content">
      <p>{{ data.content }}</p>

      @if (data?.delay; as delay) {
        <p class="delay">+{{ delay }}</p>
      }
    </div>
  </onyx-widget-box>
}
