import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  OnyxConfirmModalComponent,
  OnyxConfirmModalData,
  OnyxDropdownOptionsSource,
  OnyxFormMode,
  OnyxModalService,
  OnyxNamePipe,
  OnyxOption,
  OnyxOptionsGroup,
  OnyxToastService,
} from '@onyx/angular';
import { isString, orderBy } from 'lodash';
import { map } from 'rxjs';
import { AuthService } from '../../../../../auth/common/services/auth.service';
import { UnavailabilityType } from '../../../../../common/components/unavailabilities/unavailabilities.component';
import {
  UnavailabilityModalComponent,
  UnavailabilityModalData,
} from '../../../../../common/components/unavailabilities/unavailability-modal/unavailability-modal.component';
import { CommonHelper } from '../../../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { HelperOptions } from '../../../../../common/interfaces/utilities/helper-options';
import {
  EmployeeModalComponent,
  EmployeeModalData,
} from '../../employees-list/employee-modal/employee-modal.component';
import {
  EditEmployeeModalComponent,
  EditEmployeeModalData,
} from '../components/edit-employee-modal/edit-employee-modal.component';
import {
  EmployeeAssignVehiclesModalComponent,
  EmployeeAssignVehiclesModalData,
} from '../components/employee-assign-vehicles-modal/employee-assign-vehicles-modal.component';
import { EmployeeCategory } from '../enums/employee-category';
import { EmployeeRole } from '../enums/employee-role';
import { EmployeeStatus } from '../enums/employee-status';
import { Employee, SimplifiedEmployee } from '../interfaces/employee';
import { EmployeesService } from '../services/employees.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeHelper {
  private readonly I18N = 'employees.employeesList';

  constructor(
    private employeesService: EmployeesService,
    private modalService: OnyxModalService,
    private translateService: TranslateService,
    private toastService: OnyxToastService,
    private authService: AuthService,
    private namePipe: OnyxNamePipe,
  ) {}

  public getEmployeesSource(options?: {
    sort: boolean;
  }): OnyxDropdownOptionsSource<Employee> {
    const { sort = false } = options ?? {};

    return {
      list: (query, limit) =>
        this.employeesService
          .searchEmployees(query, limit, {
            category: EmployeeCategory.EMPLOYED,
            roles: [],
            statuses: [EmployeeStatus.ACTIVE],
          })
          .pipe(
            map(({ options, totalItems }) => {
              if (options == null) return { options, totalItems };

              const sortByLastName = (items: OnyxOption<Employee>[]) =>
                orderBy(items, (option) => option.name.split(' ')[1], 'asc');
              return {
                options: sort
                  ? options.every((option) => 'options' in option)
                    ? options.map(({ options, ...headings }) => ({
                        ...headings,
                        options: sortByLastName(options),
                      }))
                    : sortByLastName(options)
                  : options,
                totalItems,
              };
            }),
          ),
      get: (uuid) =>
        this.employeesService.getEmployee(uuid).pipe(
          map((employee) => ({
            name: this.namePipe.transform(employee),
            value: employee,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getOptions(
    employee: Employee,
    options: HelperOptions,
  ): OnyxOptionsGroup<() => void>[] {
    return [
      {
        options:
          this.hasEditPermission(employee) &&
          employee.status.value !== EmployeeStatus.INVITED
            ? [
                {
                  name: `${this.I18N}.editEmployee`,
                  value: () => this.edit(employee),
                  leftIcon: { name: 'edit' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : [],
      },
      {
        options: [
          ...(this.hasEditPermission(employee) &&
          employee.status.value !== EmployeeStatus.INVITED
            ? [
                {
                  name: 'unavailabilities.addUnavailability',
                  value: () =>
                    this.modalService.open<UnavailabilityModalData>(
                      UnavailabilityModalComponent,
                      {
                        mode: OnyxFormMode.ADD,
                        type: UnavailabilityType.EMPLOYEE,
                        item: employee,
                      },
                    ),
                  leftIcon: { name: 'vacation' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
          ...([EmployeeStatus.INVITED, EmployeeStatus.ARCHIVED].includes(
            employee.status.value,
          ) || !this.hasEditPermission(employee)
            ? []
            : [
                {
                  name: `${this.I18N}.assignVehicles`,
                  value: () => this.assignVehicles(employee),
                  leftIcon: { name: 'zoom-in' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
                ...(employee.assignedVehicles.length !== 0
                  ? [
                      {
                        name: `${this.I18N}.unassignVehicles`,
                        value: () =>
                          this.modalService
                            .open<OnyxConfirmModalData, boolean>(
                              OnyxConfirmModalComponent,
                              {
                                heading: `${this.translateService.instant(
                                  `${this.I18N}.sureToUnassignVehicles`,
                                )} ${employee.firstName || employee.lastName}?`,
                                confirmButtonText: `${this.I18N}.unassignVehicles`,
                              },
                            )
                            .result.subscribe((ok) => {
                              if (!ok) return;

                              this.unassign(employee.uuid);
                            }),
                        leftIcon: { name: 'zoom-out' as const, size: 16 },
                        leftIconColor: 'gray-alt' as const,
                      },
                    ]
                  : []),
              ]),
        ],
      },
      {
        options: [
          ...(this.hasEditPermission(employee) &&
          employee.status.value === EmployeeStatus.ARCHIVED
            ? [
                {
                  name: `${this.I18N}.unarchiveEmployee`,
                  value: () => this.unarchive(employee.uuid),
                  leftIcon: { name: 'undo' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
          ...(this.hasEditPermission(employee)
            ? [
                ...([EmployeeStatus.INVITED, EmployeeStatus.ARCHIVED].includes(
                  employee.status.value,
                )
                  ? [
                      {
                        name: `${this.I18N}.deleteEmployee`,
                        value: () => this.delete(employee, options),
                        leftIcon: { name: 'delete-fill' as const, size: 16 },
                        leftIconColor: 'gray-alt' as const,
                      },
                    ]
                  : [
                      {
                        name: `${this.I18N}.archiveEmployee`,
                        value: () => this.archiveEmployee(employee),
                        leftIcon: { name: 'archives' as const, size: 16 },
                        leftIconColor: 'gray-alt' as const,
                      },
                    ]),
              ]
            : []),
        ],
      },
    ].filter((group) => group.options.length !== 0);
  }

  public openModal(employee: EmployeeModalData): void {
    this.modalService.open<EmployeeModalData>(EmployeeModalComponent, employee);
  }

  public edit(employee: Employee): void {
    this.modalService.open<EditEmployeeModalData>(
      EditEmployeeModalComponent,
      employee,
    );
  }

  public assignVehicles(employee: Employee): void {
    this.modalService.open<EmployeeAssignVehiclesModalData>(
      EmployeeAssignVehiclesModalComponent,
      employee,
    );
  }

  public hasEditPermission(employee: Employee): boolean {
    const userRoles = this.authService.user()?.roles ?? [];
    if (userRoles.includes(EmployeeRole.BUSINESS_ADMIN)) return true;

    const employeeRoles = employee.roles.map((role) => role.value);
    if (employeeRoles.includes(EmployeeRole.BUSINESS_ADMIN)) return false;
    if (employeeRoles.includes(EmployeeRole.MANAGER)) return false;

    return userRoles.includes(EmployeeRole.MANAGER);
  }

  private unarchive(uuid: string): void {
    this.employeesService.unarchiveEmployee(uuid).subscribe({
      next: () =>
        this.toastService.showSuccess('employees.toasts.employeeUnarchived'),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  private archiveEmployee(employee: Employee): void {
    if (employee.assignedVehicles.length > 0) {
      this.modalService
        .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
          heading: `${this.translateService.instant(
            `${this.I18N}.hasAssignedVehicles`,
            {
              count: employee.assignedVehicles.length,
              name: `${employee.firstName} ${employee.lastName}`,
            },
          )}`,
          message: 'labels.continueArchive',
          confirmButtonText: 'labels.archive',
        })
        .result.subscribe((ok) => {
          if (ok) this.archive(employee.uuid);
        });
    } else {
      this.archive(employee.uuid);
    }
  }

  private archive(uuid: string): void {
    this.employeesService.archiveEmployee(uuid).subscribe({
      next: () =>
        this.toastService.showSuccess('employees.toasts.employeeArchived'),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  private delete(employee: Employee, options: HelperOptions): void {
    this.modalService
      .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
        heading: this.translateService.instant('employees.delete.heading', {
          employee:
            employee.status.value !== EmployeeStatus.INVITED
              ? this.namePipe.transform(employee)
              : employee.email,
        }),
        message: 'employees.delete.message',
        confirmButtonText: 'labels.delete',
        confirmButtonColor: 'red',
      })
      .result.subscribe((ok) => {
        if (!ok) return;

        this.employeesService.deleteEmployee(employee.uuid).subscribe({
          next: () => {
            this.toastService.showSuccess('employees.toasts.employeeDeleted');
            CommonHelper.handleOptions(employee, options);
          },
          error: (response) =>
            ValidationHelper.handleUnexpectedError(response, this.toastService),
        });
      });
  }

  private unassign(uuid: string): void {
    this.employeesService.assignVehicles(uuid, []).subscribe({
      next: () =>
        this.toastService.showSuccess(`${this.I18N}.vehiclesUnassigned`),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  public static isArchived(employee: Employee | EmployeeStatus): boolean {
    const status = isString(employee) ? employee : employee.status.value;
    return status === EmployeeStatus.ARCHIVED;
  }

  public static isEmployeeType(
    employee: SimplifiedEmployee | Employee,
  ): employee is Employee {
    return 'email' in employee;
  }
}
