import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'onyx-section',
  imports: [TranslatePipe],
  templateUrl: './onyx-section.component.html',
  styleUrl: './onyx-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxSectionComponent {
  public heading = input.required<string>();
  public count = input<number>();
  public justify = input<'left' | 'right'>('left');
}
