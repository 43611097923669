@if (validation(); as validation) {
  @let controls = form.controls;
  <form>
    <div class="column">
      <app-order-basic-information-form
        [form]="controls.basicInformation"
        [validation]="validation"
      ></app-order-basic-information-form>

      <app-parameters-form
        type="order"
        [form]="controls.parameters"
        [validation]="validation['additionalParameters']"
      ></app-parameters-form>

      <app-documents-form
        [form]="controls.documents"
        [placeholder]="I18N + '.documents.placeholder'"
      ></app-documents-form>

      <app-note-form
        [form]="controls.note"
        placeholder="labels.officeNote"
      ></app-note-form>
    </div>

    <div class="column">
      <app-order-points-form [form]="controls.points"></app-order-points-form>
    </div>
  </form>
}

@if (showRoutePreview()) {
  <app-order-form-route></app-order-form-route>
}

<ng-template
  let-message="message"
  let-order="order"
  let-close="close"
  #restorePointToastTemplate
>
  {{ message | translate }}
  <onyx-button
    type="ghost"
    color="black"
    size="s"
    (click)="restoreForm(order); close()"
  >
    {{ 'labels.undo' | translate }}
  </onyx-button>
</ng-template>
