<onyx-information-heading>
  {{ 'isOrderPaid' | i18n: I18N | translate }}
</onyx-information-heading>

<div class="container">
  <app-order-modal-payment-form
    [order]="order()"
    [form]="form"
    (partiallyPaidChanged)="isParitallyPaid.set($event)"
  ></app-order-modal-payment-form>

  <onyx-button
    color="green"
    [spinner]="loading()"
    (click)="!loading() ? submit() : null"
  >
    {{
      (isParitallyPaid() ? 'markAsPartiallyPaid' : 'markAsPaid')
        | i18n: I18N
        | translate
    }}
  </onyx-button>
</div>

@if (isParitallyPaid() && order().payments.history.length) {
  <app-order-modal-payments-history
    [order]="order()"
  ></app-order-modal-payments-history>
}
