import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, defaultIfEmpty, of } from 'rxjs';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { FleetRoute } from '../../fleet.routes';
import { Fleet } from '../interfaces/fleet';
import { FleetService } from '../services/fleet.service';

export const fleetResolver: ResolveFn<Fleet> = (
  route: ActivatedRouteSnapshot,
) => {
  const fleetService = inject(FleetService);
  const router = inject(Router);
  const toastService = inject(OnyxToastService);

  const uuid = route.paramMap.get('uuid')!;

  const redirect = () => {
    const urlTree = router.parseUrl(FleetRoute.FLEET_LIST);
    return new RedirectCommand(urlTree, { replaceUrl: true });
  };

  return fleetService.getFleet(uuid).pipe(
    defaultIfEmpty(redirect()),
    catchError((response: HttpErrorResponse) => {
      ValidationHelper.handleUnexpectedError(response, toastService, {
        keepOnNavigation: true,
      });
      return of(redirect());
    }),
  );
};
