import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export class PdfHelper {
  public static createPdf(element: HTMLElement, fileName: string): void {
    const A4_WIDTH = 595.28;
    const A4_HEIGHT = 841.89;
    const WIDTH_MARGIN = 32;
    const HEIGHT_MARGIN = 32;
    const PAGE_HEIGHT = A4_HEIGHT - 2 * HEIGHT_MARGIN;

    const pdf = new jsPDF('p', 'pt', 'a4');

    html2canvas(element, {
      scale: 1.5,
      logging: false,
      useCORS: true,
    }).then((canvas) => {
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      const imageWidth = A4_WIDTH - 2 * WIDTH_MARGIN;
      const imageHeight = (imageWidth / canvasWidth) * canvasHeight;

      const pageImage = canvas.toDataURL('image/png', 1.0);

      let position = HEIGHT_MARGIN;
      if (imageHeight > PAGE_HEIGHT) {
        let heightUnprinted = imageHeight;
        while (heightUnprinted > 0) {
          pdf.addImage(
            pageImage,
            'PNG',
            WIDTH_MARGIN,
            position,
            imageWidth,
            imageHeight,
          );

          pdf.setFillColor(255, 255, 255);
          pdf.rect(0, 0, A4_WIDTH, HEIGHT_MARGIN, 'F');
          pdf.rect(0, A4_HEIGHT - HEIGHT_MARGIN, A4_WIDTH, HEIGHT_MARGIN, 'F');

          heightUnprinted -= PAGE_HEIGHT;
          position -= PAGE_HEIGHT;

          if (heightUnprinted > 0) pdf.addPage();
        }
      } else {
        pdf.addImage(
          pageImage,
          'PNG',
          WIDTH_MARGIN,
          HEIGHT_MARGIN,
          imageWidth,
          imageHeight,
        );
      }

      pdf.save(fileName);
    });
  }
}

// TODO: Improve commented logic

//   public static async createPdf(
//     element: HTMLElement,
//     selectors: string,
//     pdfName: string,
//   ): Promise<void> {
//     const A4_HEIGHT = 841.89;
//     const A4_WIDTH = 595.28;
//     const WIDTH_MARGIN = 32;
//     const HEIGHT_MARGIN = 32;
//     const PAGE_HEIGHT = A4_HEIGHT - 2 * HEIGHT_MARGIN;
//     const SPACING = 16;

//     const PDF = new jsPDF('p', 'pt', 'a4');
//     const sections = Array.from(
//       element.querySelectorAll(selectors),
//     ) as HTMLElement[];

//     const images = await Promise.all(
//       sections.map(async (section) => {
//         const canvas = await html2canvas(section, {
//           scale: 2,
//           logging: false,
//           useCORS: true,
//         });

//         return {
//           url: canvas.toDataURL('image/png', 1.0),
//           width: A4_WIDTH - 2 * WIDTH_MARGIN,
//           height:
//             ((A4_WIDTH - 2 * WIDTH_MARGIN) / canvas.width) * canvas.height,
//         };
//       }),
//     );

//     let positionY = HEIGHT_MARGIN;

//     for (const { url: dataUrl, width, height } of images) {
//       if (positionY + height > PAGE_HEIGHT) {
//         PDF.addPage();
//         positionY = HEIGHT_MARGIN;
//       }

//       PDF.addImage(dataUrl, 'PNG', WIDTH_MARGIN, positionY, width, height);
//       positionY += height + SPACING;
//     }

//     PDF.save(pdfName);
//   }
