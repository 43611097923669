import { LowerCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDatePipe,
  OnyxIconComponent,
  OnyxIconName,
  OnyxOptional,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { DriverUnavailabilityReason } from '../../../enums/driver-unavailability-reason';
import { Unavailability } from '../../../interfaces/common/unavailability';
import { I18nPipe } from '../../../pipes/i18n.pipe';

@Component({
  selector: 'app-unavailability-icon',
  imports: [
    OnyxIconComponent,
    OnyxTooltipDirective,
    I18nPipe,
    TranslatePipe,
    OnyxDatePipe,
    LowerCasePipe,
  ],
  templateUrl: './unavailability-icon.component.html',
  styleUrl: './unavailability-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnavailabilityIconComponent {
  protected readonly DicitionaryCode = DictionaryCode;

  public unavailability = input<OnyxOptional<Unavailability>>(null);

  protected icon = computed<OnyxIconName | null>(() => {
    const unavailability = this.unavailability();
    if (!unavailability) return null;

    const reasons = {
      [DriverUnavailabilityReason.SICK_LEAVE]: 'sick-leave',
      [DriverUnavailabilityReason.VACATION]: 'vacation',
    } as const;
    return reasons[unavailability.reason];
  });
}
