import { Router } from '@angular/router';
import { ActionHelper, OnyxIconName, OnyxOptional } from '@onyx/angular';
import { chain } from 'lodash';
import { ScheduleCompliance } from '../../../../common/enums/schedule-compliance';
import { DelegationsQueryParamKey } from '../../../delegations/common/enums/delegations-query-param-key';
import { SimplifiedDelegation } from '../../../delegations/common/interfaces/delegation';
import { DelegationsRoute } from '../../../delegations/delegations.routes';
import { FleetHelper } from '../../../fleet/common/helpers/fleet.helper';
import { OrderPointType } from '../../../orders/common/enums/order-point-type';
import { TelematicsDriver } from '../interfaces/telematics-driver';
import { TelematicsFleet } from '../interfaces/telematics-fleet';

export class TelematicsHelper {
  public static getNearestDelegation(
    vehicle: Pick<TelematicsFleet, 'drivers'>,
  ): SimplifiedDelegation | null {
    return chain(vehicle.drivers)
      .map((driver) => driver.delegation)
      .minBy((delegation) => {
        if (!delegation) return '';
        if (!delegation.end?.date) return '~';

        const { date, time } = delegation.end;
        return `${date}T${time ?? '23:59:00'}`;
      })
      .value();
  }

  public static getOldestDelegation(
    vehicle: Pick<TelematicsFleet, 'drivers'>,
  ): SimplifiedDelegation | null {
    return chain(vehicle.drivers)
      .map((driver) => driver.delegation)
      .minBy((delegation) => {
        if (!delegation) return '';
        if (!delegation.start?.date) return '~';

        const { date, time } = delegation.start;
        return `${date}T${time}`;
      })
      .value();
  }

  public static addDelegation(vehicle: TelematicsFleet, router: Router): void {
    const queryParams = {
      [DelegationsQueryParamKey.VEHICLE_UUID]: FleetHelper.isVehicle(vehicle)
        ? vehicle.uuid
        : vehicle.assignedVehicle?.uuid,
      [DelegationsQueryParamKey.TRAILER_UUID]: FleetHelper.isTrailer(vehicle)
        ? vehicle.uuid
        : vehicle.assignedTrailer?.uuid,
      [DelegationsQueryParamKey.PRIMARY_DRIVER_UUID]:
        vehicle.drivers.at(0)?.uuid,
      [DelegationsQueryParamKey.SECONDARY_DRIVER_UUID]:
        vehicle.drivers.at(1)?.uuid,
    };

    const url = router
      .createUrlTree([DelegationsRoute.ADD_DELEGATION], {
        queryParams: chain(queryParams)
          .omitBy((value) => !value)
          .value(),
      })
      .toString();
    ActionHelper.openLink(url);
  }

  public static getDriverIcon(
    data: TelematicsFleet | OnyxOptional<Pick<TelematicsDriver, 'tachograph'>>,
  ): OnyxIconName {
    const driver = data
      ? 'drivers' in data
        ? data.drivers.at(0)
        : data
      : null;
    if (!driver) return 'no-user';

    const workingMode = driver.tachograph?.workingMode;
    if (!workingMode) return 'tacho-connection-waiting';

    return `tacho-${workingMode}`;
  }

  public static getPointIcon(type: OrderPointType): OnyxIconName {
    switch (type) {
      case OrderPointType.CAR_WASH:
        return 'car-wash';
      case OrderPointType.CUSTOMS:
        return 'customs';
      case OrderPointType.PARKING:
        return 'parking';
      case OrderPointType.TRAIN:
        return 'train';
      case OrderPointType.FERRY:
        return 'ferry';
      case OrderPointType.UNLOADING:
        return 'unloading';
      case OrderPointType.GAS_STATION:
        return 'gas-station';
      case OrderPointType.TUNNEL:
        return 'tunnel';
      case OrderPointType.LOADING:
        return 'loading';
      case OrderPointType.OTHER:
        return 'place';
    }
  }

  public static getScheduleComplianceColor(compliance: ScheduleCompliance) {
    switch (compliance) {
      case ScheduleCompliance.ON_TIME:
        return 'green';
      case ScheduleCompliance.DELAY_RISK:
        return 'orange';
      case ScheduleCompliance.DELAYED:
        return 'red';
    }
  }
}
