import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxPhonePipe,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { OutsourcingDriver } from '../../interfaces/order';

@Component({
  selector: 'app-order-outsourcing-driver',
  imports: [
    OnyxIconButtonComponent,
    OnyxTooltipDirective,
    OnyxIconComponent,
    TranslatePipe,
    OnyxPhonePipe,
  ],
  templateUrl: './order-outsourcing-driver.component.html',
  styleUrl: './order-outsourcing-driver.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderOutsourcingDriverComponent {
  protected readonly ActionHelper = ActionHelper;

  public driver = input.required<OutsourcingDriver>();
}
