import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { map } from 'rxjs';
import { UtilityHelper } from '../../../helpers';
import { OnyxStorageService } from '../../../services';
import { OnyxIconComponent } from '../../icons';
import { OnyxAvatarCompany, OnyxAvatarUser } from '../interfaces';

@Component({
  selector: 'onyx-avatar',
  imports: [NgStyle, OnyxIconComponent, NgClass, AsyncPipe],
  templateUrl: './onyx-avatar.component.html',
  styleUrl: './onyx-avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxAvatarComponent {
  private readonly COLORS = [
    'green',
    'yellow',
    'red',
    'violet',
    'orange',
    'pink',
  ] as const;

  public type = input.required<'user' | 'company'>();
  public size = input.required<'s' | 'm' | 'l'>();
  public user = input<OnyxAvatarUser | null>();
  public company = input<OnyxAvatarCompany | null>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  public click = output<never>();

  protected color = computed(() => {
    if (this.company()) return;

    const user = this.user();
    if (!user?.firstName || !user?.lastName) return 'disabled';

    const fullName =
      `${user.firstName.trim()}${user.lastName.trim()}`.toLowerCase();
    const hash = UtilityHelper.djb2Hash(fullName);

    return this.COLORS[hash % this.COLORS.length];
  });

  protected background$ = computed(() => {
    const backgroundUuid =
      this.type() === 'user' ? this.user()?.avatar : this.company()?.logo;
    if (!backgroundUuid) return;

    return this.storageService.getFile(backgroundUuid).pipe(
      map((file) => URL.createObjectURL(file)),
      map((url) => `center / cover url(${url}), #fff`),
    );
  });

  protected initials = computed(() => {
    const user = this.user();
    if (!user?.firstName || !user?.lastName) return;

    const initials = `${user.firstName.trimStart().charAt(0)}${user.lastName.trimStart().charAt(0)}`;
    return initials.toUpperCase();
  });

  protected showHover = computed(() => this.click['listeners'] != null);

  protected companyIconSize = computed(
    () => ({ l: 28, m: 16, s: 12 })[this.size()],
  );

  constructor(private storageService: OnyxStorageService) {}
}
