<onyx-section heading="labels.openingHours">
  <div class="container">
    <onyx-toggle
      [(value)]="isAlwaysOpen"
      [label]="I18N + '.alwaysOpen'"
    ></onyx-toggle>

    @let controls = form().controls;
    <form [formGroup]="form()">
      <onyx-form-group label="labels.weekday" [controls]="controls.weekday">
        <onyx-timepicker
          [formControl]="controls.weekday"
          [showTimeRange]="true"
          [showErrors]="false"
        ></onyx-timepicker>

        <onyx-suggestions
          [control]="controls.weekday"
          [suggestions]="TIME_RANGE_SUGGESTIONS"
        ></onyx-suggestions>
      </onyx-form-group>

      <div class="group">
        <onyx-timepicker
          [formControl]="controls.saturday"
          label="labels.saturday"
          [showTimeRange]="true"
        ></onyx-timepicker>

        <onyx-checkbox
          label="labels.closed"
          [(value)]="isSaturdayClosed"
          [showOptional]="false"
        ></onyx-checkbox>
      </div>

      <div class="group">
        <onyx-timepicker
          [formControl]="controls.sunday"
          label="labels.sunday"
          [showTimeRange]="true"
        ></onyx-timepicker>

        <onyx-checkbox
          label="labels.closed"
          [(value)]="isSundayClosed"
          [showOptional]="false"
        ></onyx-checkbox>
      </div>
    </form>
  </div>
</onyx-section>
