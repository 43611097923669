import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxDatePipe, OnyxTimePipe } from '@onyx/angular';
import { isEqual } from 'lodash';
import { OrderPointCategory } from '../../../../../dashboard/orders/common/enums/order-point-category';
import { OrderPoint } from '../../../../../dashboard/orders/common/interfaces/order-point';
import { OrderPointForm } from '../../../../../dashboard/orders/order-form/order-points-form/order-point-form/order-point-form.component';
import { BUSINESS_HOURS_KEYS } from '../../../../constants/common/business-hours-keys';
import { TIME_RANGE_ALL_DAY } from '../../../../constants/common/time-range-all-day';
import { BusinessHours } from '../../../../interfaces/common/business-hours';
import { BusinessHoursPipe } from '../../../../pipes/business-hours.pipe';
import { I18nPipe } from '../../../../pipes/i18n.pipe';

@Component({
  selector: 'app-route-point-time-windows',
  imports: [
    I18nPipe,
    TranslatePipe,
    OnyxTimePipe,
    OnyxDatePipe,
    BusinessHoursPipe,
  ],
  templateUrl: './route-point-time-windows.component.html',
  styleUrl: './route-point-time-windows.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutePointTimeWindowsComponent {
  protected readonly BUSINESS_HOURS_KEYS = BUSINESS_HOURS_KEYS;

  public point = input.required<OrderPoint | OrderPointForm>();

  protected timeWindows = computed(() => {
    const point = this.point();
    switch (point.category) {
      case OrderPointCategory.LOADING:
      case OrderPointCategory.UNLOADING:
        return point.timeWindows.windows;
      default:
        return null;
    }
  });
  protected businessHours = computed(() => {
    const point = this.point();
    switch (point.category) {
      case OrderPointCategory.CHECKPOINT:
        return point.businessHours as BusinessHours;
      default:
        return null;
    }
  });
  protected businessHoursAlwaysOpen = computed(() => {
    const businessHours = this.businessHours();
    if (!businessHours) return false;

    return Object.values(businessHours).every((openingHours) =>
      isEqual(openingHours, TIME_RANGE_ALL_DAY),
    );
  });
}
