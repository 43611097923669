export enum OnyxMapEventType {
  ADD_UPDATE_VEHICLE = 'add-update-vehicle',
  REMOVE_VEHICLE = 'remove-vehicle',
  SELECT_VEHICLE = 'select-vehicle',
  ADD_UPDATE_ROUTE_POINT = 'add-update-route-point',
  REMOVE_ROUTE_POINT = 'remove-route-point',
  ADD_UPDATE_ROUTE = 'add-update-route',
  REMOVE_ROUTE = 'remove-route',
  ADD_UPDATE_MARKER = 'add-update-marker',
  REMOVE_MARKER = 'remove-marker',
  FIT_CONTENT = 'fit-content',
}
