<onyx-icon-box class="bar-icon" color="white" [hasShadow]="true">
  <onyx-icon [ngStyle]="{ color: color() }" name="good" [size]="16"></onyx-icon>
</onyx-icon-box>

<form [formGroup]="form()">
  @let controls = form().controls;
  <onyx-form-group class="type" [controls]="controls.type">
    <onyx-dropdown
      [formControl]="controls.type"
      [label]="first() ? 'labels.goodType' : undefined"
      placeholder="labels.goodType"
      [options]="GOOD_TYPE_SOURCE"
      showAddOption="value"
      [addOptionMessage]="I18N + '.customGoodType' | translate"
      size="s"
      [showErrors]="false"
      (selectedValueChange)="type$.next($event)"
      (addOption)="setCustomType($event!)"
    ></onyx-dropdown>

    <onyx-suggestions
      [control]="controls.type"
      [suggestions]="typeSuggestions$ | async"
    ></onyx-suggestions>
  </onyx-form-group>

  <onyx-text-field
    class="reference-number"
    [formControl]="controls.referenceNumber"
    [label]="first() ? 'labels.referenceNumber' : undefined"
    placeholder="labels.referenceNumber"
    size="s"
  ></onyx-text-field>

  <onyx-form-group class="unit" [controls]="controls.unit">
    <onyx-dropdown
      [formControl]="controls.unit"
      [label]="first() ? 'labels.unit' : undefined"
      placeholder="labels.unit"
      [options]="unitOptions$ | async"
      [showAddOption]="true"
      size="s"
      [showErrors]="false"
      (selectedOptionChange)="unit$.next($any($event)?.unit ?? null)"
      (addOption)="addUnit($event)"
    ></onyx-dropdown>

    <onyx-suggestions
      [control]="controls.unit"
      [suggestions]="unitSuggestions$ | async"
    ></onyx-suggestions>
  </onyx-form-group>

  <onyx-form-group class="quantity" [controls]="controls.quantity">
    <onyx-text-field
      [formControl]="controls.quantity"
      [label]="first() ? 'labels.quantity' : undefined"
      placeholder="labels.quantity"
      [decimalPlaces]="0"
      size="s"
      [showErrors]="false"
    ></onyx-text-field>

    <onyx-suggestions
      [control]="controls.quantity"
      [suggestions]="QUANTITY_SUGGESTIONS"
    ></onyx-suggestions>
  </onyx-form-group>

  <onyx-form-group class="weight" [controls]="controls.totalWeight">
    <onyx-text-field
      [formControl]="controls.totalWeight"
      [label]="first() ? 'labels.totalWeight' : undefined"
      placeholder="labels.totalWeight"
      [decimalPlaces]="0"
      unit="kg"
      size="s"
      [showErrors]="false"
    ></onyx-text-field>

    @if (isDedicated()) {
      <onyx-suggestions
        [control]="controls.totalWeight"
        [suggestions]="DEDICATED_WEIGHT_SUGGESTIONS"
      ></onyx-suggestions>
    }
  </onyx-form-group>

  <ng-container [formGroup]="controls.size">
    @let sizeControls = controls.size.controls;
    <onyx-tabs
      class="size-type"
      [formControl]="sizeControls.type"
      [label]="first() ? 'labels.size' : undefined"
      [tabs]="sizeTypes$ | async"
    ></onyx-tabs>

    @switch (sizeType()) {
      @case (GoodsSizeType.LOADING_METERS) {
        <onyx-form-group
          class="size-value"
          [controls]="sizeControls.loadingMeters"
        >
          <onyx-text-field
            [formControl]="sizeControls.loadingMeters"
            [label]="first() ? 'labels.loadingMeters' : undefined"
            placeholder="labels.size"
            [decimalPlaces]="2"
            unit="LDM"
            size="s"
            [showErrors]="false"
          ></onyx-text-field>

          <onyx-suggestions
            [control]="sizeControls.loadingMeters"
            [suggestions]="loadingMetersSuggestions$ | async"
          ></onyx-suggestions>
        </onyx-form-group>
      }

      @case (GoodsSizeType.DIMENSIONS) {
        @let dimensionsSuggestions = dimensionsSuggestions$ | async;

        <onyx-form-group
          class="size-dimension"
          [controls]="sizeControls.length"
        >
          <onyx-text-field
            [formControl]="sizeControls.length"
            [label]="first() ? 'labels.length' : undefined"
            placeholder="labels.length"
            [decimalPlaces]="2"
            unit="m"
            size="s"
            [showErrors]="false"
          ></onyx-text-field>

          <onyx-suggestions
            [control]="sizeControls.length"
            [suggestions]="dimensionsSuggestions?.['length'] ?? []"
          ></onyx-suggestions>
        </onyx-form-group>

        <onyx-form-group class="size-dimension" [controls]="sizeControls.width">
          <onyx-text-field
            [formControl]="sizeControls.width"
            [label]="first() ? 'labels.width' : undefined"
            placeholder="labels.width"
            [decimalPlaces]="2"
            unit="m"
            size="s"
            [showErrors]="false"
          ></onyx-text-field>

          <onyx-suggestions
            [control]="sizeControls.width"
            [suggestions]="dimensionsSuggestions?.['width'] ?? []"
          ></onyx-suggestions>
        </onyx-form-group>

        <onyx-form-group
          class="size-dimension"
          [controls]="sizeControls.height"
        >
          <onyx-text-field
            [formControl]="sizeControls.height"
            [label]="first() ? 'labels.height' : undefined"
            placeholder="labels.height"
            [decimalPlaces]="2"
            unit="m"
            size="s"
            [showErrors]="false"
          ></onyx-text-field>

          <onyx-suggestions
            [control]="sizeControls.height"
            [suggestions]="dimensionsSuggestions?.['height'] ?? []"
          ></onyx-suggestions>
        </onyx-form-group>
      }

      @case (GoodsSizeType.VOLUME) {
        <onyx-form-group class="size-value" [controls]="sizeControls.volume">
          <onyx-text-field
            [formControl]="sizeControls.volume"
            [label]="first() ? 'labels.volume' : undefined"
            placeholder="labels.volume"
            [decimalPlaces]="2"
            unit="m³"
            size="s"
            [showErrors]="false"
          ></onyx-text-field>

          <onyx-suggestions
            [control]="sizeControls.volume"
            [suggestions]="volumeSuggestions$ | async"
          ></onyx-suggestions>
        </onyx-form-group>
      }
    }
  </ng-container>

  <onyx-icon-button
    class="remove"
    type="transparent"
    color="black"
    size="m"
    (click)="remove.emit()"
  >
    <onyx-icon name="delete" [size]="16"></onyx-icon>
  </onyx-icon-button>

  @if (hasUnitedNationsNumber()) {
    <onyx-text-field
      class="united-nations-number"
      [formControl]="controls.unitedNationsNumber"
      label="labels.unitedNations"
      placeholder="labels.unitedNations"
      [maxLength]="4"
      size="s"
    ></onyx-text-field>
  }

  @if (hasStatisticalNumber()) {
    <onyx-text-field
      class="statistical-number"
      [formControl]="controls.statisticalNumber"
      label="labels.statisticalNumber"
      placeholder="labels.statisticalNumber"
      size="s"
    ></onyx-text-field>
  }
</form>
