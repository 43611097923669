<div class="parameters f-medium-3">
  @if (fleet().fuelTankCapacity?.fuelType; as fuelType) {
    <p>{{ DictionaryCode.FUEL_TYPE + '.' + fuelType | translate }}</p>
  }

  @let lowDeck = fleet().additionalParameters.isLowDeck;
  @if (lowDeck != null) {
    <p>
      {{ (lowDeck ? 'labels.lowDeck' : 'labels.standardDeck') | translate }}
    </p>
  }

  @if (fleet().generalInformation.type; as type) {
    <p>{{ type | i18n: DictionaryCode.VEHICLE_TYPE | translate }}</p>
  }

  @let additionalParameters = fleet().additionalParameters;
  @if (additionalParameters.semiTrailerSize; as semiTrailerSize) {
    <p>
      {{ semiTrailerSize | i18n: DictionaryCode.SEMI_TRAILER_SIZE | translate }}
    </p>
  }

  @if (additionalParameters.trailerSize; as trailerSize) {
    <p>{{ trailerSize | i18n: DictionaryCode.TRAILER_SIZE | translate }}</p>
  }

  @if (cabotages(); as cabotages) {
    @let cabotage = cabotages[0];
    @let value = cabotage.value;
    @let limit = cabotage.limit;
    @let isLimitExceeded = value > limit;

    <p class="cabotages" [class.error]="isLimitExceeded">
      {{ 'labels.cabotages' | translate }}
      {{ cabotage.country | uppercase }}
      {{ value }}/{{ limit }}

      @if (cabotages.length > 1) {
        , +{{ cabotages.length - 1 }}
      }

      @if (isLimitExceeded) {
        <onyx-icon
          name="error-circle-empty"
          [size]="14"
          [onyxTooltip]="cabotageLimitExceededTemplate"
        ></onyx-icon>
      }
    </p>
  }
</div>

<ng-template #cabotageLimitExceededTemplate>
  <p class="tooltip">
    <span>{{ 'labels.cabotagesLimitExceeded' | translate }}</span>
    @for (cabotage of cabotages(); track cabotage.country) {
      <span>
        {{ cabotage.country | i18n: DictionaryCode.COUNTRY | translate }}
        {{ cabotage.value }}/{{ cabotage.limit }}
      </span>
    }
  </p>
</ng-template>
