import {
  AsyncPipe,
  DecimalPipe,
  LowerCasePipe,
  UpperCasePipe,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  Optional,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  AddressHelper,
  OnyxIconComponent,
  OnyxLanguagePipe,
  OnyxModalService,
  OnyxPhonePipe,
  OnyxTooltipComponent,
  OnyxTooltipDirective,
  UtilityHelper,
} from '@onyx/angular';
import { map } from 'rxjs';
import { PointOfInterestHelper } from '../../../../../dashboard/management-panel/points-of-interest/common/helpers/point-of-interest.helper';
import { OrderPointCategory } from '../../../../../dashboard/orders/common/enums/order-point-category';
import {
  OrderLoadingGood,
  OrderPoint,
} from '../../../../../dashboard/orders/common/interfaces/order-point';
import { OrderFormService } from '../../../../../dashboard/orders/common/services/order-form.service';
import { OrderPointForm } from '../../../../../dashboard/orders/order-form/order-points-form/order-point-form/order-point-form.component';
import { TransitHelper } from '../../../../helpers/transit.helper';
import { DurationPipe } from '../../../../pipes/duration.pipe';
import { RouteService } from '../../../../services/route.service';
import { AddressComponent } from '../../../address/address.component';
import {
  RoutePointGoodsModalComponent,
  RoutePointGoodsModalComponentData,
} from '../route-point-goods-modal/route-point-goods-modal.component';
import { RoutePointTimeWindowsComponent } from '../route-point-time-windows/route-point-time-windows.component';

@Component({
  selector: 'app-route-point-details',
  standalone: true,
  imports: [
    OnyxIconComponent,
    OnyxTooltipComponent,
    TranslatePipe,
    AddressComponent,
    OnyxPhonePipe,
    UpperCasePipe,
    AsyncPipe,
    OnyxLanguagePipe,
    DecimalPipe,
    LowerCasePipe,
    DurationPipe,
    RoutePointTimeWindowsComponent,
    OnyxTooltipDirective,
  ],
  templateUrl: './route-point-details.component.html',
  styleUrl: './route-point-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutePointDetailsComponent {
  protected readonly I18N = 'route.details';

  protected readonly AddressHelper = AddressHelper;
  protected readonly ActionHelper = ActionHelper;

  public point = input.required<OrderPoint | OrderPointForm>();
  public goods = input.required<OrderLoadingGood[] | null>();

  protected includedInOrder = computed(() =>
    this.routeService.isIncludedInOrder(this.point()),
  );
  protected address = computed(() => {
    const point = this.point();
    return 'address' in point ? point.address : point.startAddress;
  });
  protected addressName = computed(() => {
    const point = this.point();
    const address = this.address();

    if (address && 'poiName' in address && address.poiName) {
      return address.poiName;
    }
    return 'name' in point ? point.name : null;
  });
  protected phone = computed(() => {
    const point = this.point();
    return 'phone' in point ? point.phone : null;
  });
  protected referenceNumber = computed(() => {
    const point = this.point();
    return 'referenceNumber' in point ? point.referenceNumber : null;
  });

  protected transitRoute$ = computed(() => {
    const point = this.point();
    if (point.category !== OrderPointCategory.TRANSIT) return null;

    const transitData$ = UtilityHelper.maybeAsyncToObservable(
      this.transitHelper.getRoutesSource(point.type).get(point.name),
    );
    return transitData$.pipe(map((data) => data?.value ?? null));
  });

  protected isHandlingPoint = computed(() =>
    this.routeService.isHandlingPoint(this.point()),
  );
  protected poiLink = computed(() => {
    const address = this.address();
    const poiUuid = address && 'poiUuid' in address ? address.poiUuid : null;
    if (!poiUuid) return null;

    return () => this.pointOfInterestHelper.openModal(poiUuid);
  });

  constructor(
    protected actionHelper: ActionHelper,
    private transitHelper: TransitHelper,
    private pointOfInterestHelper: PointOfInterestHelper,
    private modalService: OnyxModalService,
    private routeService: RouteService,
    @Optional() private orderFormService?: OrderFormService,
  ) {}

  protected openGoodsPreview(): void {
    const goods = this.goods();
    if (!goods) return;

    this.modalService.open<RoutePointGoodsModalComponentData>(
      RoutePointGoodsModalComponent,
      {
        point: this.point() as RoutePointGoodsModalComponentData['point'],
        goods,
      },
      {
        providers: [
          { provide: OrderFormService, useValue: this.orderFormService },
        ],
      },
    );
  }
}
