import { DecimalPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxDistancePipe,
  OnyxIconComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { AuthService } from '../../../../../../../auth/common/services/auth.service';
import { UnitCellComponent } from '../../../../../../../common/components/cells/unit-cell/unit-cell.component';
import { I18nPipe } from '../../../../../../../common/pipes/i18n.pipe';
import { NumberDecorationPipe } from '../../../../../../../common/pipes/number-decoration.pipe';
import { OrderHelper } from '../../../../../common/helpers/order.helper';
import { Order } from '../../../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-plan-kilometers',
  imports: [
    OnyxDataWidgetComponent,
    UnitCellComponent,
    TranslatePipe,
    NumberDecorationPipe,
    DecimalPipe,
    OnyxTooltipDirective,
    NgClass,
    I18nPipe,
    OnyxIconComponent,
    OnyxDistancePipe,
  ],
  templateUrl: './order-modal-plan-kilometers.component.html',
  styleUrl: './order-modal-plan-kilometers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPlanKilometersComponent {
  protected readonly I18N = 'orders.orderModal.plan';

  public order = input.required<Order>();

  protected totalDistance = computed(() =>
    OrderHelper.getTotalDistance(this.order()),
  );
  protected distance = computed(() => OrderHelper.getDistance(this.order()));
  protected emptyDistance = computed(() =>
    OrderHelper.getEmptyDistance(this.order()),
  );
  protected plannedData = computed(() => {
    const { status, route, assignedData } = this.order();
    if (OrderHelper.isOrderPending(status.value)) return;

    const distance = route.summary.distance;
    const emptyDistance = assignedData?.emptyDistance ?? 0;

    return {
      totalDistance: distance + emptyDistance,
      distance,
      emptyDistance,
    };
  });

  protected realizationResult = computed(() => {
    const plannedData = this.plannedData();
    if (!OrderHelper.isOrderFinished(this.order()) || !plannedData) {
      return null;
    }

    const plannedDistance = plannedData.totalDistance / 1000;
    const difference = this.totalDistance() - plannedDistance;
    return {
      distance: Math.abs(difference),
      color: difference > 0 ? 'red' : 'green',
      sign: difference > 0 ? '+' : '-',
    };
  });

  protected isEmptyDistanceError = computed(() => {
    const emptyDistance = this.emptyDistance();
    if (
      !emptyDistance ||
      this.order().manualProposalData?.result?.ok !== false
    ) {
      return false;
    }

    return OrderHelper.hasEmptyDistanceError(
      emptyDistance,
      this.distance(),
      this.authService,
    );
  });

  constructor(private authService: AuthService) {}
}
