<section>
  @switch (order().status.value) {
    @case (OrderStatus.COMPLETING_DOCUMENTS) {
      <onyx-information-heading>
        {{ 'areDocumentsComplete' | i18n: I18N | translate }}

        <onyx-button
          action
          type="outlined"
          color="black"
          size="s"
          (click)="addDocument()"
        >
          <onyx-icon
            leftIcon
            class="add-icon"
            name="zoom-in"
            [size]="16"
          ></onyx-icon>

          {{ 'labels.add' | translate }}
        </onyx-button>
      </onyx-information-heading>

      <div class="wrapper">
        <app-order-modal-documents
          [order]="order()"
          [close$]="close$()"
          [showAddButton]="false"
        ></app-order-modal-documents>

        <onyx-button
          color="green"
          [spinner]="loading()"
          (click)="!loading() ? completeDocuments() : null"
        >
          {{ 'labels.ready' | translate }}
        </onyx-button>
      </div>
    }

    @case (OrderStatus.INVOICE_TO_ISSUE) {
      @if (showInvoiceForm()) {
        <app-order-modal-invoice-form
          [order]="order()"
          (hideFormChange)="this.toggleInvoiceForm()"
        ></app-order-modal-invoice-form>
      } @else {
        <div class="container">
          <onyx-button (click)="toggleInvoiceForm()">
            {{ 'addInvoice' | i18n: I18N | translate }}
          </onyx-button>

          <p class="f-gray">
            {{ 'workingOnGeneratingInvoices' | i18n: I18N | translate }}
          </p>
        </div>
      }
    }

    @case (OrderStatus.PAID) {
      <app-order-modal-payments-history
        [order]="order()"
      ></app-order-modal-payments-history>
    }

    @case (OrderStatus.WAITING_FOR_PAYMENT) {
      <app-order-modal-payment-form-container
        [order]="order()"
      ></app-order-modal-payment-form-container>
    }

    @case (OrderStatus.PARTIALLY_PAID) {
      <app-order-modal-payment-form-container
        [order]="order()"
      ></app-order-modal-payment-form-container>
    }
  }
</section>
