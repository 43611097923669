import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { OnyxTime, OnyxTimeRange } from '../components';
import { OnyxOptional } from '../interfaces';

export const TIME_PATTERN = /^(?:[01]\d|2[0-3]):[0-5]\d:00$/;

@Pipe({
  name: 'onyxTime',
  pure: true,
})
export class OnyxTimePipe implements PipeTransform {
  public transform(
    value: OnyxOptional<DateTime | (OnyxTime | string) | OnyxTimeRange>,
  ): string {
    if (!value) return '-';

    if (DateTime.isDateTime(value)) return value.toFormat('HH:mm');
    if (isString(value)) return this.formatTime(value);

    const from = this.formatTime(value.from);
    const to = this.formatTime(value.to);

    return `${from} - ${to}`;
  }

  private formatTime(time: OnyxTime | string): string {
    if (TIME_PATTERN.test(time)) return time.slice(0, 5);
    return DateTime.fromISO(time).toFormat('HH:mm');
  }
}
