import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxIconRegistryService } from '../../../internal/services/onyx-icon-registry.service';
import { OnyxIconName } from '../interfaces';
import { OnyxCustomIconComponent } from '../onyx-custom-icon/onyx-custom-icon.component';

@Component({
  selector: 'onyx-icon',
  imports: [OnyxCustomIconComponent, NgStyle],
  templateUrl: './onyx-icon.component.html',
  styleUrl: './onyx-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxIconComponent {
  public name = input.required<OnyxIconName>();
  public size = input.required<number>();
  public frame = input<number>();

  constructor(private iconRegistryService: OnyxIconRegistryService) {}
}
