import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DecimalPipe, LowerCasePipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxAmountPipe,
  OnyxChip,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxFormMode,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxModalComponent,
  OnyxOverlayPosition,
  OnyxPluralTranslatePipe,
  OnyxTableComponent,
  OnyxToastService,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { isString } from 'lodash';
import { catchError, EMPTY, Subject, switchMap, tap } from 'rxjs';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { ContractorContactsComponent } from '../common/components/contractor-contacts/contractor-contacts.component';
import { getContractorsFormDocumentsColumns } from '../common/constants/contractors-form-documents-columns';
import { ContractorStatus } from '../common/enums/contractor-status';
import { ContractorHelper } from '../common/helpers/contractor.helper';
import {
  Contractor,
  SimplifiedContractor,
} from '../common/interfaces/contractor';
import { ContractorsService } from '../common/services/contractors.service';
import { ContractorModalBranchesComponent } from './contractor-modal-branches/contractor-modal-branches.component';

export type ContractorModalData = Contractor | SimplifiedContractor | string;

enum ContractorModalSection {
  // ORDERS = 'orders', // TEMP: N/A
  BRANCHES = 'branches',
  DOCUMENTS = 'documents',
  COMPANY = 'company',
}

@Component({
  selector: 'app-contractor-modal',
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    TranslatePipe,
    OnyxInformationHeadingComponent,
    OnyxInformationRowComponent,
    OnyxChipsComponent,
    OnyxTableComponent,
    OnyxTooltipDirective,
    OnyxPluralTranslatePipe,
    OnyxDropdownDirective,
    ContractorContactsComponent,
    DecimalPipe,
    LowerCasePipe,
    OnyxAmountPipe,
    ContractorModalBranchesComponent,
    UpperCasePipe,
  ],
  templateUrl: './contractor-modal.component.html',
  styleUrl: './contractor-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorModalComponent {
  protected readonly I18N = 'contractors';
  protected readonly COLUMNS = getContractorsFormDocumentsColumns(
    OnyxFormMode.ADD,
  );

  protected readonly ActionHelper = ActionHelper;
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly ContractorModalSection = ContractorModalSection;
  protected readonly ContractorStatus = ContractorStatus;
  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;

  private readonly uuid: string;

  protected groupChips = computed<OnyxChip<ContractorModalSection>[]>(() => {
    const contractor = this.contractor();
    return [
      // { name: 'labels.orders', value: ContractorModalSection.ORDERS }, // TEMP: N/A
      {
        name: 'labels.branches',
        value: ContractorModalSection.BRANCHES,
        count: contractor ? 1 + contractor.branches.length : undefined,
      },
      { name: 'labels.documents', value: ContractorModalSection.DOCUMENTS },
      {
        name: `${this.I18N}.aboutCompany`,
        value: ContractorModalSection.COMPANY,
      },
    ];
  });

  protected contractor = signal<Contractor | null>(null);
  protected group = signal([this.groupChips()[0].value]);
  protected loading = signal(false);
  protected error = signal(false);
  protected close$ = new Subject<void>();
  protected contractor$ = new Subject<void>();

  protected options = computed(() => {
    const contractor = this.contractor();
    return contractor
      ? this.contractorHelper.getOptions(contractor, { close$: this.close$ })
      : null;
  });
  protected isArchived = computed(() => {
    const contractor = this.contractor();
    return contractor ? ContractorHelper.isArchived(contractor) : false;
  });

  constructor(
    @Inject(DIALOG_DATA) protected data: ContractorModalData,
    protected dialogRef: DialogRef<void>,
    private contractorsService: ContractorsService,
    private toastService: OnyxToastService,
    private destroyRef: DestroyRef,
    private contractorHelper: ContractorHelper,
  ) {
    this.uuid = isString(this.data) ? this.data : this.data.uuid;

    this.contractor$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() =>
          this.contractorsService.getContractor(this.uuid).pipe(
            catchError((response) => {
              this.contractor.set(null);
              this.loading.set(false);
              this.error.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (contractor) => {
          this.contractor.set(contractor);
          this.loading.set(false);
          this.error.set(false);
        },
      });

    if (isString(this.data) || !ContractorHelper.isContractorType(this.data)) {
      this.contractor$.next();
    } else {
      this.contractor.set(this.data);
    }

    this.contractorsService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.contractor$.next());
  }

  protected block(): void {
    const contractor = this.contractor();
    if (!contractor) return;

    this.contractorHelper.block(contractor);
  }

  protected unblock(): void {
    const contractor = this.contractor();
    if (!contractor) return;

    this.contractorHelper.unblock(contractor.uuid);
  }

  protected edit(): void {
    const contractor = this.contractor();
    if (!contractor) return;

    this.contractorHelper.edit(contractor.uuid, { close$: this.close$ });
  }

  protected unarchiveContractor(uuid: string): void {
    this.contractorsService.unarchiveContractor(uuid).subscribe({
      next: () => {
        this.toastService.showSuccess(
          `${this.I18N}.toasts.contractorUnarchived`,
        );
        this.close$.next();
      },
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }
}
