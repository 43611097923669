import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  OnyxAddresByType,
  OnyxAddressType,
  OnyxPaginated,
} from '../components';
import { OnyxAddress } from '../components/address-input/interfaces';

@Injectable({
  providedIn: 'root',
})
export abstract class OnyxAddressService {
  public abstract geocode<T extends OnyxAddressType>(
    types: T[],
    query: string,
    limit: number,
  ): Observable<OnyxPaginated<OnyxAddresByType[T]>>;

  public abstract getRecentSearches<T extends OnyxAddressType>(
    types: T[],
    limit: number,
  ): Observable<OnyxAddresByType[T][]>;

  public abstract addToRecentSearches(_address: OnyxAddress): Observable<void>;
}
