<section>
  @if (showHeading()) {
    <onyx-information-heading>
      {{ 'labels.vehicle' | translate }}
    </onyx-information-heading>
  }

  <div class="vehicles">
    <onyx-data-widget
      [headingIcon]="
        vehicle()
          ? {
              name: searchingVehicleData().iconName,
              size: 16,
              color: 'black',
            }
          : null
      "
      [showDivider]="
        !!vehicle() && !hasApprovedVehicle() && !!vehicleParameters().length
      "
      [color]="isVehicleInvalid() ? 'red' : 'gray'"
    >
      <ng-container heading>
        @if (vehicle(); as vehicle) {
          {{ vehicle | fleetIdentifier }}
        }
      </ng-container>

      <ng-container description>
        @if (vehicle(); as vehicle) {
          <app-fleet-description-parameters
            [fleet]="vehicle"
            [cabotages]="cabotages()"
          ></app-fleet-description-parameters>
        }
      </ng-container>

      @if (!hasApprovedVehicle()) {
        <div class="list">
          @if (vehicleParameters().length) {
            <p class="gray f-medium-3">
              @let categories = searchingVehicleData().categories;
              @if (categories.length === 1) {
                @let category = categories[0];
                {{
                  (category === FleetCategory.SEMI_TRUCK
                    ? 'semiTruckRequirements'
                    : category === FleetCategory.VAN
                      ? 'vanRequirements'
                      : 'straightTruckRequirements'
                  )
                    | i18n: I18N
                    | translate
                }}
              } @else {
                {{ 'vehicleRequirements' | i18n: I18N | translate }}
              }
            </p>
          }

          @for (item of vehicleParameters(); track item.name) {
            <div class="item" [class.f-red]="vehicle() && !item.ok()">
              @if (vehicle()) {
                @if (item.ok()) {
                  <onyx-icon
                    class="check"
                    name="check-fill"
                    [size]="16"
                  ></onyx-icon>
                } @else {
                  <onyx-icon
                    class="f-red"
                    name="clear-two-tone"
                    [size]="16"
                  ></onyx-icon>
                }
              } @else {
                <div class="icon"></div>
              }

              @let value = $any(item.value);
              <p>
                @let isTrue = value === true;
                <span>
                  {{ item.name | translate }}{{ isTrue ? null : ':' }}
                </span>

                @let key = item.key;
                @if (!isTrue) {
                  <span>
                    @switch (key) {
                      @case ('adrClasses') {
                        @for (class of value; track class) {
                          {{
                            DictionaryCode.ADR_CLASS + '.' + class | translate
                          }}
                        }
                      }
                      @case ('temperatureRange') {
                        {{ value | onyxTemperature }}
                      }
                      @case ('other') {
                        @for (parameter of value; track parameter.uuid) {
                          {{ parameter.names | onyxLanguage }}
                        }
                      }
                      @default {
                        {{ value }}
                      }
                    }
                  </span>
                }
              </p>
            </div>
          }
        </div>
      }

      <ng-container
        *ngTemplateOutlet="modalButtonTemplate; context: { fleet: vehicle() }"
      ></ng-container>
    </onyx-data-widget>

    @if (
      searchingTrailerTypes() &&
      (searchingVehicleData().isLowDeck || !!trailer())
    ) {
      <onyx-data-widget
        [headingIcon]="
          trailer()
            ? {
                name: searchingVehicleData().isLowDeck
                  ? 'semi-trailer'
                  : 'trailer',
                size: 16,
                color: 'black',
              }
            : null
        "
        [showDivider]="!!trailer() && !hasApprovedVehicle()"
        [color]="isTrailerInvalid() ? 'red' : 'gray'"
      >
        <ng-container heading>
          @if (trailer(); as trailer) {
            {{ trailer | fleetIdentifier }}
          }
        </ng-container>

        <ng-container description>
          @if (trailer(); as trailer) {
            <app-fleet-description-parameters
              [fleet]="trailer"
            ></app-fleet-description-parameters>
          }
        </ng-container>

        @if (!hasApprovedVehicle()) {
          <div class="list">
            <p class="gray f-medium-3">
              {{
                (searchingVehicleData().isLowDeck
                  ? 'semiTrailerRequirements'
                  : 'trailerRequirements'
                )
                  | i18n: I18N
                  | translate
              }}
            </p>

            @for (item of trailerParameters(); track item.name) {
              <div
                class="item"
                [class.f-red]="trailer() && !item.ok"
                [onyxTooltip]="trailerTypesTooltip"
                [onyxTooltipEnabled]="item.showTooltip"
              >
                @if (trailer()) {
                  @if (item.ok) {
                    <onyx-icon
                      class="check"
                      name="check-fill"
                      [size]="16"
                    ></onyx-icon>
                  } @else {
                    <onyx-icon
                      class="f-red"
                      name="clear-two-tone"
                      [size]="16"
                    ></onyx-icon>
                  }
                } @else {
                  <div class="icon"></div>
                }

                <p>{{ item.name | translate }}</p>
              </div>
            }
          </div>
        }

        <ng-container
          *ngTemplateOutlet="modalButtonTemplate; context: { fleet: trailer() }"
        ></ng-container>
      </onyx-data-widget>
    }
  </div>
</section>

<ng-template let-fleet="fleet" #modalButtonTemplate>
  @if (fleet) {
    <onyx-icon-button
      type="transparent"
      color="black"
      size="m"
      (click)="openFleetModal(fleet)"
    >
      <onyx-icon name="side-modal" [size]="16"></onyx-icon>
    </onyx-icon-button>
  }
</ng-template>

<ng-template #trailerTypesTooltip>
  @let types = this.searchingTrailerTypes() ?? [];
  @if (types.length) {
    <div class="tooltip">
      @for (type of types; track type) {
        <div class="item">
          <span>•</span>

          <span>
            {{ DictionaryCode.VEHICLE_TYPE + '.' + type | translate }}
          </span>
        </div>
      }
    </div>
  }
</ng-template>
