import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  AddressHelper,
  OnyxFlagsComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxIconName,
  OnyxRouteBorder,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { AddressComponent } from '../../../address/address.component';

@Component({
  selector: 'app-route-point-border',
  imports: [
    OnyxIconComponent,
    OnyxIconButtonComponent,
    OnyxTooltipDirective,
    AddressComponent,
    OnyxFlagsComponent,
    TranslatePipe,
  ],
  templateUrl: './route-point-border.component.html',
  styleUrl: './route-point-border.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutePointBorderComponent {
  public border = input.required<OnyxRouteBorder>();
  public icon = input.required<OnyxIconName>();

  constructor(private actionHelper: ActionHelper) {}

  protected copyCoordinates(): void {
    this.actionHelper.copy(
      AddressHelper.composeCoordinatesLabel({
        coordinates:
          this.border().address?.coordinates ?? this.border().coordinates,
      }),
    );
  }
}
