@let color = config.color;
<div class="tooltip {{ color }} {{ config.textAlign }}">
  @if (config.showArrow) {
    @let arrowPosition = config.arrowPosition$ | async;
    <span
      class="arrow {{ color }}"
      [ngStyle]="{
        'left.px': arrowPosition?.x,
        'bottom.px': arrowPosition?.y,
      }"
    ></span>
  }

  @let context = config.context;
  @if (isString(context)) {
    <span
      [ngClass]="{ 'f-medium-1': color === 'white' }"
      [innerHTML]="context | translate"
    ></span>
  } @else if (isTemplateRef(context)) {
    <ng-template
      [ngTemplateOutlet]="context"
      [ngTemplateOutletContext]="config.templateContext ?? null"
    ></ng-template>
  } @else {
    <ng-container
      *ngComponentOutlet="context.ref; inputs: context.inputs"
    ></ng-container>
  }
</div>
