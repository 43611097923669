import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  OnInit,
  output,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { FleetHelper } from '../../common/helpers/fleet.helper';
import { Fleet } from '../../common/interfaces/fleet';
import { FleetModalWarningComponent } from './fleet-modal-warning/fleet-modal-warning.component';

@Component({
  selector: 'app-fleet-modal-warnings',
  imports: [TranslatePipe, FleetModalWarningComponent],
  templateUrl: './fleet-modal-warnings.component.html',
  styleUrl: './fleet-modal-warnings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalWarningsComponent implements OnInit {
  protected readonly I18N = 'fleet.fleetModal';

  public vehicle = input.required<Fleet>();

  public redirect = output<void>();
  public warnings = output<boolean>();

  protected vehicleExpiryStatuses = computed(() =>
    FleetHelper.getVehicleExpiryStatuses(this.vehicle()),
  );

  constructor(private injector: Injector) {}

  public ngOnInit(): void {
    effect(
      () => {
        const { error, warning } = this.vehicleExpiryStatuses();
        this.warnings.emit(error.length > 0 || warning.length > 0);
      },
      { injector: this.injector },
    );
  }
}
