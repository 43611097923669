import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  OnyxConfirmModalComponent,
  OnyxConfirmModalData,
  OnyxDropdownOptionsSource,
  OnyxMaybeArray,
  OnyxModalService,
  OnyxOptionsGroup,
  OnyxToastService,
} from '@onyx/angular';
import { castArray, chain, isString, uniq } from 'lodash';
import {
  catchError,
  EMPTY,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { PickDeep } from 'type-fest';
import { ValidationSeverity } from '../../../../common/enums/validation-severity';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import {
  ValidationConditionsHelper,
  ValidationContext,
} from '../../../../common/helpers/validation-conditions.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { HelperOptions } from '../../../../common/interfaces/utilities/helper-options';
import { ValidationIssue } from '../../../../common/interfaces/validation/validation-issue';
import { FleetIdentifierPipe } from '../../../../common/pipes/fleet-identifier.pipe';
import { FleetService } from '../../../fleet/common/services/fleet.service';
import { DriverForm } from '../../driver-form/driver-form.component';
import {
  DriverModalComponent,
  DriverModalData,
} from '../../driver-modal/driver-modal.component';
import { DriversRoute } from '../../drivers.routes';
import {
  DriverAssignVehicleModalComponent,
  DriverAssignVehicleModalData,
} from '../components/driver-assign-vehicle-modal/driver-assign-vehicle-modal.component';
import { Driver, SimplifiedDriver } from '../interfaces/driver';
import { DriverCategory } from '../interfaces/driver-category';
import { DriverStatus } from '../interfaces/driver-status';
import { DriversService } from '../services/drivers.service';

@Injectable({
  providedIn: 'root',
})
export class DriverHelper {
  private readonly I18N = 'drivers.driversList';

  constructor(
    private driversService: DriversService,
    private router: Router,
    private modalService: OnyxModalService,
    private toastService: OnyxToastService,
    private translateService: TranslateService,
    private fleetService: FleetService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
    private validationConditionsHelper: ValidationConditionsHelper,
  ) {}

  public getDriversSource(): OnyxDropdownOptionsSource<Driver> {
    return {
      list: (query, limit) =>
        this.driversService.searchDrivers(query, limit, {
          category: DriverCategory.ACTIVE,
        }),
      get: (uuid) =>
        this.driversService.getDriver(uuid).pipe(
          map((driver) => ({
            name: `${driver.driverData.firstName} ${driver.driverData.lastName}`,
            value: driver,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getOptions(
    driver: Driver,
    options: HelperOptions,
  ): OnyxOptionsGroup<() => void>[] {
    const isArchived = DriverHelper.isArchived(driver);
    const issues: ValidationIssue[] = [];
    this.validationConditionsHelper.checkRequiredDrivingLicense(
      ValidationContext.DRIVER,
      driver,
      issues,
    );
    this.validationConditionsHelper.checkDrivingLicenseExpiryDate(
      ValidationContext.DRIVER,
      driver,
      issues,
    );

    return [
      {
        options: [
          ...(!isArchived
            ? [
                {
                  name: 'labels.edit',
                  value: () => this.edit(driver.uuid, options),
                  leftIcon: { name: 'edit' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
        ],
      },
      {
        options: [
          ...(!isArchived
            ? [
                ValidationHelper.mapIssues(
                  issues.filter(
                    (issue) => issue.severity === ValidationSeverity.ERROR,
                  ),
                  {
                    name: `${this.I18N}.assignVehicle`,
                    value: () =>
                      this.modalService.open<DriverAssignVehicleModalData>(
                        DriverAssignVehicleModalComponent,
                        driver,
                      ),
                    leftIcon: { name: 'zoom-in' as const, size: 16 },
                    leftIconColor: 'gray-alt' as const,
                  },
                ).option!,
              ]
            : []),
          ...(driver.assignedVehicle
            ? [
                {
                  name: `${this.I18N}.unassignVehicles`,
                  value: () =>
                    this.modalService
                      .open<OnyxConfirmModalData, boolean>(
                        OnyxConfirmModalComponent,
                        {
                          heading: `${this.translateService.instant(
                            `${this.I18N}.sureToUnassignVehicles`,
                          )} ${driver.driverData.firstName} ${
                            driver.driverData.lastName
                          }?`,
                          confirmButtonText: `${this.I18N}.unassignVehicles`,
                        },
                      )
                      .result.subscribe((ok) => {
                        if (!ok) return;

                        this.unassignVehicle(
                          driver.uuid,
                          driver.assignedVehicle!.uuid,
                        );
                      }),
                  leftIcon: { name: 'zoom-out' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
        ],
      },
      {
        options: [
          {
            name: isArchived ? 'labels.unarchive' : 'labels.archive',
            value: () => this.toggleArchived(driver, options),
            leftIcon: {
              name: isArchived ? ('undo' as const) : ('archives' as const),
              size: 16,
            },
            leftIconColor: 'gray-alt' as const,
          },
        ],
      },
    ].filter((group) => group.options.length !== 0);
  }

  public openModal(
    driver: DriverModalData['driver'],
    size: DriverModalData['size'] = 'm',
  ): void {
    this.modalService.open<DriverModalData>(DriverModalComponent, {
      driver,
      size,
    });
  }

  public edit(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(
      `${DriversRoute.EDIT_DRIVER.replace(':uuid', uuid)}`,
    );
    CommonHelper.handleOptions(uuid, options);
  }

  public toggleArchived(
    drivers: OnyxMaybeArray<Driver>,
    options: HelperOptions,
    force?: boolean,
  ): void {
    const items = chain(drivers)
      .thru((drivers) => castArray(drivers))
      .groupBy((driver) => force ?? !DriverHelper.isArchived(driver))
      .thru((groups) => ({
        archive: groups['true'] ?? [],
        unarchive: groups['false'] ?? [],
      }))
      .value();
    const showConfirmModal = items.archive.some(
      (driver) => driver.assignedVehicle,
    );

    const toggle$ = (items: Driver[], force: boolean): Observable<void> => {
      if (!items.length) return of(undefined);

      return this.driversService
        .batchDrivers(
          items.map((item) => item.uuid),
          { ...(force ? { archive: true } : { unarchive: true }) },
        )
        .pipe(
          catchError((response) => {
            ValidationHelper.handleUnexpectedError(response, this.toastService);
            return EMPTY;
          }),
          tap(() => {
            this.toastService.showSuccess(
              this.translateService.instant(
                `drivers.toasts.${items.length === 1 ? 'driver' : 'drivers'}${force ? 'Archived' : 'Unarchived'}`,
                { count: items.length },
              ),
            );
          }),
        );
    };
    const getName = (driver: Driver) => {
      const { firstName, lastName } = driver.driverData;
      return `${firstName} ${lastName}`;
    };

    of(items)
      .pipe(
        switchMap((items) => {
          if (showConfirmModal) {
            return this.modalService
              .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
                heading: this.translateService.instant(
                  `${this.I18N}.sureToArchive${items.archive.length === 1 ? 'Driver' : 'Drivers'}`,
                  {
                    name: getName(items.archive[0]),
                    vehicle: this.fleetIdentifierPipe.transform(
                      items.archive[0].assignedVehicle,
                    ),
                  },
                ),
                badges:
                  items.archive.length > 1
                    ? items.archive.map((driver) => ({
                        value: getName(driver),
                        color: 'outlined-black',
                      }))
                    : undefined,
                message: `${this.I18N}.sureToContinueArchive`,
                confirmButtonText: 'labels.archive',
              })
              .result.pipe(switchMap((ok) => (ok ? of(items) : EMPTY)));
          }
          return of(items);
        }),
        switchMap((items) =>
          forkJoin([
            toggle$(items.archive, true),
            toggle$(items.unarchive, false),
          ]),
        ),
      )
      .subscribe(() => CommonHelper.handleOptions(drivers, options));
  }

  private unassignVehicle(driver: string, vehicle: string): void {
    this.fleetService.unassignDrivers(vehicle, driver).subscribe({
      next: () => {
        this.toastService.showSuccess(`${this.I18N}.vehicleUnassigned`);
        this.driversService.reload();
      },
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  public static getDriverAdrClasses(
    driver: Driver,
    vehicleType: string | null,
  ): string[] {
    const REQUIREMENTS_ADR = [
      'adr-2-1',
      'adr-2-2',
      'adr-2-3',
      'adr-3',
      'adr-4-1',
      'adr-4-2',
      'adr-4-3',
      'adr-5-1',
      'adr-5-2',
      'adr-6-1',
      'adr-6-2',
      'adr-8',
      'adr-9',
    ];

    const isFuelTanker = vehicleType === 'fuel-tanker';
    const adrClassMap: Record<string, string[]> = {
      'adr-1': ['adr-1'],
      'adr-7': ['adr-7'],
      'requirements-adr': !isFuelTanker ? REQUIREMENTS_ADR : [],
      'requirements-adr-tank': isFuelTanker ? REQUIREMENTS_ADR : [],
    };

    return uniq(
      driver.permissions.flatMap((permission) => {
        const icon = permission.type.icon;
        if (!icon) return [];

        return adrClassMap[icon] ?? [];
      }),
    );
  }

  public static isActive(category: DriverCategory): boolean {
    return category === DriverCategory.ACTIVE;
  }

  public static isArchived(driver: Driver | DriverStatus): boolean {
    const status = isString(driver) ? driver : driver.status.status;
    return status === DriverStatus.ARCHIVED;
  }

  public static getDriverExpiryDocuments(
    driver: PickDeep<
      Driver,
      | 'driverCard'
      | 'identityDocuments'
      | 'identityDocuments.identityDocument'
      | 'identityDocuments.polishCard'
      | 'identityDocuments.passport'
      | 'identityDocuments.residenceCard'
    >,
  ) {
    return [
      {
        key: 'driverCard',
        expirationDate: driver.driverCard?.expirationDate,
      },
      {
        key: 'identityDocument',
        expirationDate:
          driver.identityDocuments.identityDocument?.expirationDate,
      },
      {
        key: 'polishCard',
        expirationDate: driver.identityDocuments.polishCard?.expirationDate,
      },
      {
        key: 'passport',
        expirationDate: driver.identityDocuments.passport?.expirationDate,
      },
      {
        key: 'residenceCard',
        expirationDate: driver.identityDocuments.residenceCard?.expirationDate,
      },
    ];
  }

  public static isDriverType(
    driver: SimplifiedDriver | Driver,
  ): driver is Driver {
    return 'employmentConditions' in driver;
  }

  public static fromDto(dto: Driver): DriverForm {
    return {
      ...dto,
      driverData: {
        ...dto.driverData,
        assignedBase: dto.driverData.assignedBase?.uuid ?? null,
      },
      employmentConditions: {
        ...dto.employmentConditions,
        mileageRate: dto.employmentConditions
          .netMileagePatePerKm as DriverForm['employmentConditions']['mileageRate'],
        dailyRate: dto.employmentConditions
          .dailyRate as DriverForm['employmentConditions']['dailyRate'],
        baseRate: dto.employmentConditions
          .baseRate as DriverForm['employmentConditions']['baseRate'],
        roundTripRate: dto.employmentConditions
          .roundTripRate as DriverForm['employmentConditions']['roundTripRate'],
        scans: dto.employmentConditions
          .scans as DriverForm['employmentConditions']['scans'],
      },
      driversLicenseAndProfessionalQualifications: {
        ...dto.driversLicenseAndProfessionalQualifications,
        selectedCategories:
          dto.driversLicenseAndProfessionalQualifications.categories.map(
            (category) => category.category,
          ),
        scans: dto.driversLicenseAndProfessionalQualifications
          .scans as DriverForm['driversLicenseAndProfessionalQualifications']['scans'],
      },
      driverCard: dto.driverCard as DriverForm['driverCard'],
      identityDocuments: {
        hasDocuments: true,
        passport: dto.identityDocuments
          .passport as DriverForm['identityDocuments']['passport'],
        identityDocument: dto.identityDocuments
          .identityDocument as DriverForm['identityDocuments']['identityDocument'],
        residenceCard: dto.identityDocuments
          .residenceCard as DriverForm['identityDocuments']['residenceCard'],
        polishCard: dto.identityDocuments
          .polishCard as DriverForm['identityDocuments']['polishCard'],
      },
      clearCriminalRecordCertificate: {
        ...dto.clearCriminalRecordCertificate,
        scans: dto.clearCriminalRecordCertificate
          .scans as DriverForm['clearCriminalRecordCertificate']['scans'],
      },
      visas: dto.visas.map((visa) => ({
        ...visa,
        scans: visa.scans as DriverForm['visas'][number]['scans'],
      })),
      permissions: dto.permissions.map((permission) => ({
        ...permission,
        type: permission.type.uuid,
        scans: permission.scans as DriverForm['permissions'][number]['scans'],
      })),
      note: {
        content: dto.note?.content ?? null,
      },
    };
  }
}
