import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxIconBoxComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { RouteComponent } from '../../../../../common/components/route/route.component';
import { RouteService } from '../../../../../common/services/route.service';
import { OrdersQueryParamKey } from '../../../common/enums/orders-query-param-key';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-route',
  imports: [
    TranslatePipe,
    OnyxInformationHeadingComponent,
    OnyxTooltipDirective,
    OnyxIconComponent,
    RouteComponent,
    OnyxIconButtonComponent,
    OnyxIconBoxComponent,
  ],
  providers: [RouteService],
  templateUrl: './order-modal-route.component.html',
  styleUrl: './order-modal-route.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalRouteComponent {
  protected readonly I18N = 'orders.ordersList.cells';

  public order = input.required<Order>();
  public close$ = input.required<Subject<void>>();

  protected isExpanded = this.routeService.isRouteExpanded;
  protected hasEdit = computed(() =>
    this.orderHelper.hasRouteEdit(this.order()),
  );

  constructor(
    private routeService: RouteService,
    private orderHelper: OrderHelper,
  ) {}

  protected toggleExpanded(): void {
    this.routeService.toggleRouteExpanded();
  }

  protected planRoute(): void {
    this.orderHelper.edit(this.order().uuid, {
      options: { close$: this.close$() },
      params: { [OrdersQueryParamKey.PLAN_ROUTE]: true },
    });
  }
}
