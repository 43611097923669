import { DecimalPipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxDatePipe,
  OnyxTableComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { AmountCellComponent } from '../../../../../../../common/components/cells/amount-cell/amount-cell.component';
import { DictionaryCode } from '../../../../../../../common/enums/dictionary-code';
import { I18nPipe } from '../../../../../../../common/pipes/i18n.pipe';
import { NumberDecorationPipe } from '../../../../../../../common/pipes/number-decoration.pipe';
import { ORDER_MODAL_PLAN_FINANCES_COLUMNS } from '../../../../../common/constants/order-modal-plan-finances-columns';
import {
  OrderHelper,
  OrderSettlementData,
} from '../../../../../common/helpers/order.helper';
import { Order } from '../../../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-plan-finances',
  imports: [
    AmountCellComponent,
    OnyxDataWidgetComponent,
    NumberDecorationPipe,
    DecimalPipe,
    TranslatePipe,
    UpperCasePipe,
    OnyxTableComponent,
    OnyxTooltipDirective,
    OnyxDatePipe,
    I18nPipe,
  ],
  templateUrl: './order-modal-plan-finances.component.html',
  styleUrl: './order-modal-plan-finances.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPlanFinancesComponent {
  protected readonly I18N = 'orders.orderModal.plan';
  protected readonly ORDER_MODAL_PLAN_FINANCES_COLUMNS =
    ORDER_MODAL_PLAN_FINANCES_COLUMNS;

  protected readonly DictionaryCode = DictionaryCode;

  public order = input.required<Order>();

  protected settlementData = computed<OrderSettlementData[]>(() =>
    OrderHelper.getSettlementData(this.order()),
  );

  protected ratePerKm = computed(() => OrderHelper.getRatePerKm(this.order()));
  protected pricePerDay = computed(() =>
    OrderHelper.getPricePerDay(this.order()),
  );
  protected plannedData = computed(() => {
    const { status, route, price } = this.order();
    if (!OrderHelper.isOrderFinished(status.value)) return;

    return {
      price,
      ratePerKm: price.defaultCurrencyValue / (route.summary.distance / 1000),
    };
  });
}
