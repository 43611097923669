import {
  ChangeDetectionStrategy,
  Component,
  linkedSignal,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxCheckboxComponent,
  OnyxFormMode,
  OnyxTooltipComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { map } from 'rxjs';
import { I18nPipe } from '../../../../../../common/pipes/i18n.pipe';
import { OrderFormService } from '../../../../common/services/order-form.service';

@Component({
  selector: 'app-order-form-route-modal-footer',
  standalone: true,
  imports: [
    OnyxTooltipComponent,
    OnyxCheckboxComponent,
    OnyxButtonComponent,
    I18nPipe,
    TranslatePipe,
    OnyxTooltipDirective,
  ],
  templateUrl: './order-form-route-modal-footer.component.html',
  styleUrl: './order-form-route-modal-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormRouteModalFooterComponent {
  protected readonly I18N = 'orders.orderForm';

  protected readonly OnyxFormMode = OnyxFormMode;

  protected mode = this.orderFormService.mode;
  protected isOverCreditLimit = toSignal(
    this.orderFormService.price$.pipe(
      map(({ isOverCreditLimit }) => isOverCreditLimit),
    ),
    { initialValue: false },
  );
  protected loading = this.orderFormService.loading;
  protected activeRoute = this.orderFormService.activeRoute;

  protected requireVerification = signal(false);
  protected blockRoute = linkedSignal(() => {
    this.requireVerification();
    return false;
  });

  constructor(private orderFormService: OrderFormService) {}

  protected returnToForm(): void {
    this.orderFormService.closeRoutePreview();
  }

  protected submit(): void {
    if (this.activeRoute() == null) return;

    this.orderFormService.submit({
      verifyRoute: false,
      ignoreCreditLimit: this.isOverCreditLimit(),
      isVerified: !this.requireVerification(),
      isBlocked: this.blockRoute(),
    });
  }
}
