import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DropdownHelper,
  OnyxConfirmModalComponent,
  OnyxConfirmModalData,
  OnyxDropdownOptionsSource,
  OnyxMaybeArray,
  OnyxModalService,
  OnyxOptional,
  OnyxOptionsGroup,
  OnyxToastService,
} from '@onyx/angular';
import { castArray, chain, isString, orderBy } from 'lodash';
import {
  catchError,
  concatMap,
  EMPTY,
  forkJoin,
  from,
  last,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { PickDeep } from 'type-fest';
import {
  CellColor,
  ColorHelper,
} from '../../../../common/helpers/color.helper';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import {
  ValidationConditionsHelper,
  ValidationContext,
} from '../../../../common/helpers/validation-conditions.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { HelperOptions } from '../../../../common/interfaces/utilities/helper-options';
import { FleetIdentifierPipe } from '../../../../common/pipes/fleet-identifier.pipe';
import { FleetForm } from '../../fleet-form/fleet-form.component';
import {
  FleetModalComponent,
  FleetModalData,
} from '../../fleet-modal/fleet-modal.component';
import { FleetRoute } from '../../fleet.routes';
import {
  FleetAssignDriverModalComponent,
  FleetAssignDriverModalData,
} from '../components/fleet-assign-driver-modal/fleet-assign-driver-modal.component';
import {
  FleetAssignEmployeesModalComponent,
  FleetAssignEmployeesModalData,
} from '../components/fleet-assign-employees-modal/fleet-assign-employees-modal.component';
import {
  FleetSetsModalComponent,
  FleetSetsModalData,
} from '../components/fleet-sets-modal/fleet-sets-modal.component';
import { FleetCategory } from '../enums/fleet-category';
import { FleetRouterStateKey } from '../enums/fleet-router-state-key';
import { FleetState } from '../enums/fleet-state';
import { Fleet, SimplifiedFleet } from '../interfaces/fleet';
import { FleetService } from '../services/fleet.service';
import { FleetValidationHelper } from './fleet-validation.helper';

type FleetTypeIdentifier =
  | PickDeep<Fleet, 'generalInformation.category'>
  | Pick<Fleet['generalInformation'], 'category'>
  | FleetCategory;

@Injectable({
  providedIn: 'root',
})
export class FleetHelper {
  private readonly I18N = 'fleet.fleetList';

  constructor(
    private fleetService: FleetService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
    private fleetValidationHelper: FleetValidationHelper,
    private validationConditionsHelper: ValidationConditionsHelper,
    private modalService: OnyxModalService,
    private router: Router,
    private translateService: TranslateService,
    private toastService: OnyxToastService,
  ) {}

  public getVehiclesSource(
    params?: Parameters<FleetService['searchFleet']>[2],
    warnings?: {
      enableWarnings: boolean;
      context: ValidationContext;
    },
  ): OnyxDropdownOptionsSource<Fleet> {
    return {
      list: (query, limit) =>
        this.fleetService
          .searchFleet(query, limit, {
            ...params,
            state: FleetState.ACTIVE,
          })
          .pipe(
            map((result) => {
              if (warnings?.enableWarnings) return result;

              return {
                ...result,
                options: chain(DropdownHelper.mapToOptions(result.options)!)
                  .map((option) => {
                    return this.fleetValidationHelper.validateFleet({
                      context: warnings!.context,
                      primary: option.value,
                      options: {
                        option,
                      },
                    }).option!;
                  })
                  .thru((options) =>
                    this.validationConditionsHelper.groupOptions(options),
                  )
                  .value(),
              };
            }),
          ),
      get: (uuid) =>
        this.fleetService.getFleet(uuid).pipe(
          map((vehicle) => ({
            name: params?.showSetsOnly
              ? `${this.fleetIdentifierPipe.transform(vehicle, 'bracket')} · ${this.fleetIdentifierPipe.transform(vehicle.trailer!.generalInformation, 'bracket')}`
              : this.fleetIdentifierPipe.transform(vehicle),
            value: vehicle,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getTrailersSource(): OnyxDropdownOptionsSource<Fleet> {
    return {
      list: (query, limit) =>
        this.fleetService.searchFleet(query, limit, {
          category: [FleetCategory.SEMI_TRAILER, FleetCategory.TRAILER],
          state: FleetState.ACTIVE,
        }),
      get: (uuid) =>
        this.fleetService.getFleet(uuid).pipe(
          map((trailer) => ({
            name: this.fleetIdentifierPipe.transform(trailer),
            value: trailer,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getOptions(
    fleet: Fleet,
    options: HelperOptions,
  ): OnyxOptionsGroup<() => void>[] {
    const [isArchived, isVehicle] = [
      FleetHelper.isArchived(fleet),
      FleetHelper.isVehicle(fleet),
    ];

    return [
      {
        options: [
          {
            name: 'labels.edit',
            value: () => this.edit(fleet.uuid, options),
            leftIcon: { name: 'edit' as const, size: 16 },
            leftIconColor: 'gray-alt' as const,
          },
        ],
      },
      {
        options: [
          {
            name: `${this.I18N}.duplicate`,
            value: () => this.duplicateFleet(fleet, options),
            leftIcon: { name: 'copy' as const, size: 16 },
            leftIconColor: 'gray-alt' as const,
          },
        ],
      },
      ...(!isArchived
        ? [
            {
              options: [
                {
                  name: 'fleet.services.scheduleService',
                  value: () => this.scheduleService(fleet.uuid, options),
                  leftIcon: { name: 'menu-repairs' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
                {
                  name: 'fleet.fleetModal.switchVehicle',
                  value: () => this.openAssignVehicleModal(fleet),
                  leftIcon: { name: 'exchange' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
                ...(isVehicle
                  ? [
                      {
                        name: `${this.I18N}.assignEmployees`,
                        value: () => this.assignEmployees(fleet),
                        leftIcon: { name: 'menu-employees' as const, size: 16 },
                        leftIconColor: 'gray-alt' as const,
                      },
                    ]
                  : []),
                ...(isVehicle
                  ? [
                      {
                        name: `${this.I18N}.assignDrivers`,
                        value: () => this.openAssignDriverModal(fleet),
                        leftIcon: { name: 'steering-wheel' as const, size: 16 },
                        leftIconColor: 'gray-alt' as const,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      {
        options: [
          ...(fleet.assignedEmployees?.length !== 0 && isVehicle
            ? [
                {
                  name: `${this.I18N}.unassignEmployees`,
                  value: () =>
                    this.modalService
                      .open<OnyxConfirmModalData, boolean>(
                        OnyxConfirmModalComponent,
                        {
                          heading: `${this.translateService.instant(
                            `${this.I18N}.sureToUnassignEmployees`,
                          )} ${this.fleetIdentifierPipe.transform(fleet)}?`,
                          confirmButtonText: `${this.I18N}.unassignEmployees`,
                        },
                      )
                      .result.subscribe((ok) => {
                        if (!ok) return;

                        this.unassignEmployees(
                          fleet.uuid,
                          fleet.assignedEmployees?.length ?? 0,
                        );
                      }),
                  leftIcon: { name: 'no-user-fill' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
          ...(fleet.drivers?.primaryDriver || fleet.drivers?.secondaryDriver
            ? [
                {
                  name: `${this.I18N}.unassignDrivers`,
                  value: () =>
                    this.modalService
                      .open<OnyxConfirmModalData, boolean>(
                        OnyxConfirmModalComponent,
                        {
                          heading: `${this.translateService.instant(
                            `${this.I18N}.sureToUnassignDrivers`,
                          )} ${this.fleetIdentifierPipe.transform(fleet)}?`,
                          confirmButtonText: `${this.I18N}.unassignDrivers`,
                        },
                      )
                      .result.subscribe((ok) => {
                        if (!ok) return;

                        this.unassignDrivers(fleet.uuid, fleet.drivers);
                      }),
                  leftIcon: { name: 'steering-wheel' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
          ...(fleet.generalInformation.state === FleetState.ARCHIVED
            ? [
                {
                  name: 'labels.delete',
                  value: () => this.delete(fleet, options),
                  leftIcon: { name: 'delete-fill' as const, size: 16 },
                  leftIconColor: 'gray-alt' as const,
                },
              ]
            : []),
        ],
      },
      {
        options: [
          {
            name: isArchived ? 'labels.unarchive' : 'labels.archive',
            value: () => this.toggleArchived(fleet, options),
            leftIcon: {
              name: isArchived ? ('undo' as const) : ('archives' as const),
              size: 16,
            },
            leftIconColor: 'gray-alt' as const,
          },
        ],
      },
    ].filter((group) => group.options.length !== 0);
  }

  public openModal(data: FleetModalData): void {
    this.modalService.open<FleetModalData>(FleetModalComponent, data);
  }

  public openAssignDriverModal(data: FleetAssignDriverModalData): void {
    this.modalService.open<FleetAssignDriverModalData>(
      FleetAssignDriverModalComponent,
      data,
    );
  }

  public openAssignVehicleModal(data: FleetSetsModalData): void {
    this.modalService.open<FleetSetsModalData>(FleetSetsModalComponent, data);
  }

  public edit(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(FleetRoute.EDIT_FLEET.replace(':uuid', uuid));
    CommonHelper.handleOptions(uuid, options);
  }

  public scheduleService(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(FleetRoute.FLEET_SERVICE.replace(':uuid', uuid));
    CommonHelper.handleOptions(uuid, options);
  }

  public assignEmployees(
    fleet: OnyxMaybeArray<FleetAssignEmployeesModalData[number]>,
  ): void {
    this.modalService.open<FleetAssignEmployeesModalData>(
      FleetAssignEmployeesModalComponent,
      castArray(fleet),
    );
  }

  public toggleArchived(
    fleet: OnyxMaybeArray<Fleet>,
    options: HelperOptions,
    force?: boolean,
  ): void {
    const items = chain(fleet)
      .thru((fleet) => castArray(fleet))
      .groupBy((fleet) => force ?? !FleetHelper.isArchived(fleet))
      .thru((groups) => ({
        archive: groups['true'] ?? [],
        unarchive: groups['false'] ?? [],
      }))
      .value();
    const showConfirmModal = items.archive.some(
      (fleet) =>
        fleet.trailer ||
        fleet.vehicle ||
        fleet.assignedEmployees?.length ||
        fleet.drivers,
    );

    const toggle$ = (items: Fleet[], force: boolean): Observable<void> => {
      if (!items.length) return of(undefined);

      return this.fleetService
        .batchFleet(
          items.map((item) => item.uuid),
          { state: force ? FleetState.ARCHIVED : FleetState.ACTIVE },
        )
        .pipe(
          catchError((response) => {
            ValidationHelper.handleUnexpectedError(response, this.toastService);
            return EMPTY;
          }),
          tap(() =>
            this.toastService.showSuccess(
              this.translateService.instant(
                `fleet.toasts.${items.length === 1 ? 'vehicle' : 'vehicles'}${force ? 'Archived' : 'Activated'}`,
                { count: items.length },
              ),
            ),
          ),
        );
    };

    of(items)
      .pipe(
        switchMap((items) => {
          if (showConfirmModal) {
            return this.modalService
              .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
                heading: this.translateService.instant(
                  `${this.I18N}.sureToArchive${items.archive.length === 1 ? 'Vehicle' : 'Vehicles'}`,
                  {
                    vehicle: this.fleetIdentifierPipe.transform(
                      items.archive[0],
                    ),
                  },
                ),
                badges:
                  items.archive.length > 1
                    ? items.archive.map((fleet) => ({
                        value: this.fleetIdentifierPipe.transform(fleet),
                        color: 'outlined-black',
                      }))
                    : undefined,
                message: `${this.I18N}.sureToContinueArchive`,
                confirmButtonText: 'labels.archive',
              })
              .result.pipe(switchMap((ok) => (ok ? of(items) : EMPTY)));
          }
          return of(items);
        }),
        switchMap((items) =>
          forkJoin([
            toggle$(items.archive, true),
            toggle$(items.unarchive, false),
          ]),
        ),
      )
      .subscribe(() => CommonHelper.handleOptions(fleet, options));
  }

  private duplicateFleet(fleet: Fleet, options: HelperOptions): void {
    this.router.navigateByUrl(FleetRoute.ADD_FLEET, {
      state: {
        [FleetRouterStateKey.CATEGORY]: fleet.generalInformation.category,
        [FleetRouterStateKey.TYPE]: fleet.generalInformation.type,
        [FleetRouterStateKey.TEMPLATE]: fleet,
      },
    });
    CommonHelper.handleOptions(fleet.uuid, options);
  }

  private delete(fleet: Fleet, options: HelperOptions): void {
    this.modalService
      .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
        heading: this.translateService.instant('fleet.delete.heading', {
          fleet: this.fleetIdentifierPipe.transform(fleet),
        }),
        message: 'fleet.delete.message',
        confirmButtonText: 'labels.delete',
        confirmButtonColor: 'red',
      })
      .result.subscribe((ok) => {
        if (!ok) return;

        this.fleetService.deleteFleet(fleet).subscribe({
          next: () => {
            this.toastService.showSuccess(
              `fleet.toasts.delete.${fleet.generalInformation.category}`,
            );
            CommonHelper.handleOptions(fleet, options);
          },
          error: (response) =>
            ValidationHelper.handleUnexpectedError(response, this.toastService),
        });
      });
  }

  private unassignEmployees(uuid: string, count: number): void {
    this.fleetService.assignEmployees(uuid, { employees: [] }).subscribe({
      next: () =>
        this.toastService.showSuccess(
          this.translateService.instant('fleet.toasts.employeesUnassigned', {
            count,
          }),
        ),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  private unassignDrivers(uuid: string, drivers: Fleet['drivers']): void {
    const requests = Object.values(drivers!)
      .filter((item) => item != null)
      .map((driver) => this.fleetService.unassignDrivers(uuid, driver.uuid));
    if (!requests.length) return;

    from(requests)
      .pipe(
        concatMap((unassignDriver) => unassignDriver),
        last(),
      )
      .subscribe({
        next: () =>
          this.toastService.showSuccess(
            this.translateService.instant('fleet.toasts.driversUnassigned', {
              count: requests.length,
            }),
          ),
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      });
  }

  public static isVehicle(fleet: FleetTypeIdentifier): boolean {
    return this.getType(fleet) === 'vehicle';
  }

  public static isTrailer(fleet: FleetTypeIdentifier): boolean {
    return this.getType(fleet) === 'trailer';
  }

  public static getVehicleExpiryDocuments(
    vehicle: Pick<
      Fleet,
      | 'registrationCertificate'
      | 'comprehensiveInsurance'
      | 'thirdPartyLiabilityInsurance'
      | 'tachograph'
    >,
  ) {
    return [
      {
        key: 'technicalInspection',
        expiryDate:
          vehicle.registrationCertificate.technicalInspectionExpiryDate,
      },
      {
        key: 'ac',
        expiryDate: vehicle.comprehensiveInsurance?.expiryDate,
      },
      {
        key: 'oc',
        expiryDate: vehicle.thirdPartyLiabilityInsurance?.expiryDate,
      },
      {
        key: 'tachographLegalization',
        expiryDate: vehicle.tachograph?.nextLegalizationDate,
      },
    ];
  }

  public static getVehicleExpiryStatuses(vehicle: Fleet) {
    const getExpiryStatus = (
      expiryDate: string | null | undefined,
    ): {
      color: CellColor;
    } | null => {
      if (!expiryDate) return null;

      return {
        color: ColorHelper.getCellColor(expiryDate),
      };
    };

    const statuses = orderBy(
      FleetHelper.getVehicleExpiryDocuments(vehicle),
      (status) => status.expiryDate,
    )
      .map((status) => {
        const expiryStatus = getExpiryStatus(status.expiryDate);
        return expiryStatus ? { ...status, ...expiryStatus } : null;
      })
      .filter((status) => status != null);

    return {
      error: statuses.filter((status) => status.color === 'red'),
      warning: statuses.filter((status) => status.color === 'yellow'),
    };
  }

  public static getTrailerLabel(vehicle: OnyxOptional<Fleet>): string {
    if (!vehicle) return 'labels.semiTrailerOrTrailer';

    switch (vehicle?.generalInformation.category) {
      case FleetCategory.SEMI_TRUCK:
        return 'labels.semiTrailer';

      default:
        return 'labels.trailer';
    }
  }

  public static isArchived(
    fleet: Fleet | SimplifiedFleet | FleetState,
  ): boolean {
    const state = isString(fleet) ? fleet : fleet.generalInformation.state;
    return state === FleetState.ARCHIVED;
  }

  public static isFleetType(
    vehicle: Fleet | SimplifiedFleet,
  ): vehicle is Fleet {
    return 'status' in vehicle;
  }

  public static hasTelematics(fleet: Fleet | SimplifiedFleet | null) {
    if (!fleet) return false;
    return fleet.telematicsProvider != null;
  }

  public static fromDto(dto: Fleet): FleetForm {
    return {
      ...dto,
      generalInformation: {
        ...dto.generalInformation,
        assignedBase: dto.generalInformation.assignedBase?.uuid ?? null,
      },
      cargoSpace: {
        ...dto.cargoSpace,
        ...dto.cargoSpace.dimensions,
      },
      fuelTankCapacity: dto.fuelTankCapacity as FleetForm['fuelTankCapacity'],
      ownership: {
        ...dto.ownership,
        vehicleValue: dto.ownership
          .vehicleValue as FleetForm['ownership']['vehicleValue'],
        leasingProfile: null,
        loanProfile: null,
        rentalProfile: null,
        selfDeposit: dto.ownership
          .selfDeposit as FleetForm['ownership']['selfDeposit'],
        buyout: dto.ownership.buyout as FleetForm['ownership']['buyout'],
        installment: dto.ownership
          .installment as FleetForm['ownership']['installment'],
      },
      licensesAndPermits:
        dto.licensesAndPermits as FleetForm['licensesAndPermits'],
      additionalParameters: {
        ...dto.additionalParameters,
        temperatureRange: dto.additionalParameters
          .temperatureRange as FleetForm['additionalParameters']['temperatureRange'],
        other:
          dto.additionalParameters.other?.map((parameter) => parameter.uuid) ??
          null,
      },
      note: {
        content: dto.note?.content ?? null,
      },
      tachograph: dto.tachograph as FleetForm['tachograph'],
      registrationCertificate:
        dto.registrationCertificate as FleetForm['registrationCertificate'],
      co2Emissions: dto.co2Emissions as FleetForm['co2Emissions'],
      comprehensiveInsurance:
        dto.comprehensiveInsurance as FleetForm['comprehensiveInsurance'],
    };
  }

  private static getType(
    typeIdentifier: FleetTypeIdentifier,
  ): 'vehicle' | 'trailer' {
    const category = isString(typeIdentifier)
      ? typeIdentifier
      : 'generalInformation' in typeIdentifier
        ? typeIdentifier.generalInformation.category
        : typeIdentifier.category;

    return (
      {
        [FleetCategory.SEMI_TRAILER]: 'trailer',
        [FleetCategory.SEMI_TRUCK]: 'vehicle',
        [FleetCategory.STRAIGHT_TRUCK]: 'vehicle',
        [FleetCategory.TRAILER]: 'trailer',
        [FleetCategory.VAN]: 'vehicle',
      } as const
    )[category];
  }
}
