<onyx-modal
  type="left"
  [extend]="true"
  [showClose]="false"
  [showBottomBar]="true"
>
  <div class="heading" headingLeftOptions>
    <onyx-icon class="f-gray" name="route" [size]="20"></onyx-icon>

    <h3>
      @if (order()?.identifier; as identifier) {
        {{ identifier }}
      } @else {
        {{ 'heading' | i18n: I18N | translate }}
      }

      @if ((price$ | async)?.price; as price) {
        · {{ price | onyxAmount }}
      }
    </h3>

    @if (hasSafeParking()) {
      <onyx-icon
        class="f-gray"
        name="safe-parking"
        [size]="16"
        onyxTooltip="labels.safeParking"
        onyxTooltipColor="white"
      ></onyx-icon>
    }
  </div>

  <onyx-icon-button
    rightOptions
    type="transparent"
    color="gray"
    size="m"
    onyxTooltip="labels.settings"
    onyxTooltipColor="white"
    (click)="editRouteConfig()"
  >
    <onyx-icon class="f-gray" name="settings" [size]="16"></onyx-icon>
  </onyx-icon-button>

  <div bottomOptions class="subheading">
    @let config = routeConfig();
    @if (config?.vehicleCategory; as vehicleCategory) {
      {{ vehicleCategory | i18n: DictionaryCode.VEHICLE_CATEGORY | translate }}
    }

    @if (config?.fuelType; as fuelType) {
      <span class="f-gray">
        · {{ fuelType | i18n: DictionaryCode.FUEL_TYPE | translate }}
      </span>

      @if (config?.fuelPrice; as fuelPrice) {
        {{ fuelPrice | onyxAmount }}/{{ fuelUnit$ | async }}
      }
    }

    @if (config?.fuelAverageConsumption; as fuelAverageConsumption) {
      <span class="f-gray"> · {{ 'labels.fuelConsumption' | translate }}</span>

      {{ fuelAverageConsumption / 100 | number }} {{ fuelUnit$ | async }}/100 km
    }

    @if (
      config?.excludeCountries?.length && config?.excludeCountries;
      as excludeCountries
    ) {
      <span class="f-gray">·</span>

      <p class="item">
        <span class="f-gray">{{ 'labels.exclude' | translate }}:</span>
        {{ excludeCountries | join: ', ' : 2 : DictionaryCode.COUNTRY }}
      </p>
    }

    @if (
      config?.avoidFeatures?.length && config?.avoidFeatures;
      as avoidFeatures
    ) {
      <span class="f-gray">·</span>

      <p class="item">
        <span class="f-gray">{{ 'labels.avoid' | translate }}:</span>
        {{
          avoidFeatures
            | join: ', ' : 2 : DictionaryCode.CALCULATE_ROUTE_FEATURE
        }}
      </p>
    }
  </div>

  <div class="container">
    <app-order-form-route-modal-tabs></app-order-form-route-modal-tabs>
    <app-order-form-route-modal-summary></app-order-form-route-modal-summary>
    <app-order-form-route-modal-route></app-order-form-route-modal-route>
  </div>

  <app-order-form-route-modal-footer
    bottomBar
  ></app-order-form-route-modal-footer>
</onyx-modal>
