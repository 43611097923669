@if (!context().isFirst && (type() === 'point' || context().isLast)) {
  <div class="line"></div>
}

@if (!context().isFirst) {
  <div class="sub row">
    <app-route-point-route-summary
      [routeSummary]="routeSummary()"
      [index]="context().index"
      [subpoints]="subpoints()"
      [subpointsExpanded]="subpointsExpanded()"
    ></app-route-point-route-summary>
  </div>
}

@if (subpointsExpanded()) {
  @for (subpoint of subpoints(); track trackSubpoint(subpoint, $index)) {
    @switch (subpoint.type) {
      @case ('border') {
        <div class="sub row">
          <app-route-point-border
            [border]="subpoint.data"
            [icon]="subpoint.icon"
          ></app-route-point-border>
        </div>
      }
      @case ('point') {
        <app-route-point [point]="subpoint.data"></app-route-point>
      }
    }
  }

  @if (!context().isFirst && hasWaypoints()) {
    <div class="sub row">
      <app-route-point-route-summary
        [routeSummary]="value().route?.summary ?? null"
        [index]="context().index"
      ></app-route-point-route-summary>
    </div>
  }
}

<div
  class="heading-container"
  [class.attached]="actionsAttached()"
  [class.expanded]="headingExpanded()"
>
  @let frame = 2 * icon().size;
  @if (!context().isFirst) {
    <div
      class="dissolve-effect top"
      [style.width.px]="frame"
      [style.height.px]="frame"
    ></div>
  }

  <button class="heading row" (click)="toggleDetails()">
    <div class="point-marker">
      <div class="marker">
        <onyx-icon
          class="marker-icon"
          [style.width.px]="frame"
          [style.height.px]="frame"
          [name]="icon().name"
          [size]="icon().size"
        ></onyx-icon>

        @if (compliance(); as compliance) {
          <onyx-icon
            class="compliance {{ compliance.color }}"
            name="clock"
            [size]="icon().size - 2"
            [frame]="icon().size"
            [onyxTooltip]="`labels.${compliance.value}`"
            onyxTooltipColor="white"
          ></onyx-icon>
        }

        <onyx-icon
          class="marker-icon hover"
          [style.width.px]="frame"
          [style.height.px]="frame"
          [name]="headingExpanded() ? 'arrow-alt-up' : 'arrow-alt-down'"
          [size]="1.25 * icon().size"
        ></onyx-icon>
      </div>
    </div>

    <div class="header" [class.form]="showForm()">
      @if (!showForm()) {
        <app-address
          class="f-h6"
          [address]="startAddress()"
          format="city-flag"
        ></app-address>

        <p class="f-medium-4 f-gray">
          <ng-container *ngTemplateOutlet="typeIndexTemplate"></ng-container>
        </p>
      }
      @if (showForm() && form(); as form) {
        <h6>
          <ng-container *ngTemplateOutlet="typeIndexTemplate"></ng-container>
        </h6>

        <div class="include-in-order">
          <onyx-toggle
            [formControl]="$any(form.controls).includeInOrder"
            label="labels.includeInOrder"
            size="m"
            [showOptional]="false"
            (click)="$event.stopPropagation()"
          ></onyx-toggle>

          <onyx-tooltip
            tooltip="orders.orderForm.points.includeInOrderHint"
            (click)="$event.stopPropagation()"
          ></onyx-tooltip>
        </div>
      }

      <ng-template #typeIndexTemplate>
        {{ `${DictionaryCode.ORDER_POINT_TYPE}.${value().type}` | translate }}
        {{ typeIndex() + 1 }}
      </ng-template>
    </div>

    @if (!showForm()) {
      <app-route-point-time-windows
        class="f-medium-3"
        [point]="value()"
      ></app-route-point-time-windows>

      @if (HAS_MOVE || OPTIONS.length) {
        <button
          class="actions"
          tabindex="-1"
          (click)="$event.stopPropagation()"
        >
          @if (HAS_MOVE) {
            <onyx-icon-button
              type="ghost"
              color="gray"
              size="m"
              [circle]="true"
              [tabindex]="-1"
              [disabled]="context().isFirst"
              onyxTooltip="labels.moveUp"
              onyxTooltipColor="white"
              (click)="!context().isFirst ? movePoint(-1) : null"
            >
              <onyx-icon name="arrow-up" [size]="16"></onyx-icon>
            </onyx-icon-button>

            <onyx-icon-button
              type="ghost"
              color="gray"
              size="m"
              [circle]="true"
              [tabindex]="-1"
              [disabled]="context().isLast"
              onyxTooltip="labels.moveDown"
              onyxTooltipColor="white"
              (click)="!context().isLast ? movePoint(1) : null"
            >
              <onyx-icon name="arrow-down" [size]="16"></onyx-icon>
            </onyx-icon-button>
          }

          @if (OPTIONS.length) {
            <onyx-icon-button
              type="ghost"
              color="gray"
              size="m"
              [circle]="true"
              [tabindex]="-1"
              [onyxDropdownOptions]="OPTIONS"
              [(onyxDropdownAttached)]="actionsAttached"
              onyxTooltip="labels.options"
              onyxTooltipColor="white"
              (onyxDropdownValueChange)="$event?.(); actionsAttached.set(false)"
            >
              <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
            </onyx-icon-button>
          }
        </button>
      }
    } @else {
      <button
        class="form-actions"
        tabindex="-1"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon-button
          type="transparent"
          color="gray"
          size="m"
          [circle]="true"
          [disabled]="!formHasChanges()"
          onyxTooltip="labels.undoChanges"
          onyxTooltipColor="white"
          (click)="formHasChanges() ? undoFormChanges() : null"
        >
          <onyx-icon name="undo" [size]="16"></onyx-icon>
        </onyx-icon-button>

        @if (HAS_REMOVE) {
          <onyx-icon-button
            type="transparent"
            color="gray"
            size="m"
            [circle]="true"
            onyxTooltip="labels.delete"
            onyxTooltipColor="white"
            (click)="removePoint()"
          >
            <onyx-icon name="delete-fill" [size]="16"></onyx-icon>
          </onyx-icon-button>
        }
      </button>
    }
  </button>

  @if (headingExpanded()) {
    <div class="container row">
      @if (showDetails()) {
        <app-route-point-details
          [point]="value()"
          [goods]="goods$() | async"
        ></app-route-point-details>
      }
      @if (showForm() && form(); as form) {
        <app-route-point-form
          [category]="value().category"
          [form]="form"
        ></app-route-point-form>
      }
    </div>
  }

  @if (!context().isLast) {
    <div
      class="dissolve-effect bottom"
      [style.width.px]="frame"
      [style.height.px]="frame"
    ></div>
  }
</div>
