<button class="banner" (click)="openModal()">
  <div class="content">
    @if (driver()) {
      <!-- TEMP: Replace with actual tacho -->
      <onyx-icon
        class="tacho-icon"
        name="tacho-offline"
        [size]="16"
        [frame]="24"
      ></onyx-icon>
    } @else {
      <onyx-avatar
        type="user"
        size="s"
        [user]="{
          firstName: employee()?.firstName,
          lastName: employee()?.lastName,
        }"
      ></onyx-avatar>
    }

    <p>
      @if (driver(); as driver) {
        @let driverData = driver.driverData;
        {{ driverData | onyxName }}
      } @else {
        {{ employee() | onyxName }}
      }
    </p>

    <app-unavailability-icon
      [unavailability]="driver()?.unavailability ?? employee()?.unavailability"
    ></app-unavailability-icon>

    <div class="roles">
      @if (driver()) {
        <onyx-badge color="blue">
          {{ 'labels.driver' | translate }}
        </onyx-badge>
      } @else {
        @for (role of employee()?.roles; track role.value) {
          <onyx-badge [color]="role.color">
            {{ DictionaryCode.EMPLOYEE_ROLE + '.' + role.value | translate }}
          </onyx-badge>
        }
      }
    </div>
  </div>

  <div class="buttons">
    <onyx-icon-button
      type="transparent"
      color="black"
      size="s"
      (click)="
        ActionHelper.makeCall(
          driver() ? driver()!.driverData.privatePhone : employee()!.phone
        );
        $event.stopPropagation()
      "
    >
      <onyx-icon class="phone" name="telephone" [size]="16"></onyx-icon>
    </onyx-icon-button>

    @let email = driver()?.driverData?.privateEmail || employee()?.email;
    @if (email) {
      <onyx-icon-button
        type="transparent"
        color="black"
        size="s"
        (click)="ActionHelper.sendEmail(email); $event.stopPropagation()"
      >
        <onyx-icon name="e-mail" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </div>
</button>
