import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { OnyxPhone } from '@onyx/angular';
import { PartialDeep } from 'type-fest';
import { CacheService } from '../../../../common/services/cache.service';
import { DriverForm } from '../../driver-form/driver-form.component';
import { DriversRouterStateKey } from '../enums/drivers-router-state-key';
import { DriversStorageKey } from '../enums/drivers-storage-key';

export const addDriverResolver: ResolveFn<PartialDeep<DriverForm>> = () => {
  const router = inject(Router);
  const cacheService = inject(CacheService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const privatePhone: OnyxPhone = state?.[DriversRouterStateKey.PRIVATE_PHONE];
  const nationality = state?.[DriversRouterStateKey.NATIONALITY];

  const { areaCode, number } = privatePhone;
  const cacheKey = `${DriversStorageKey.ADD_DRIVER_FORM}-${nationality}-${areaCode}-${number}`;
  const cache = cacheService.get<PartialDeep<DriverForm>>(cacheKey, {
    keepOnNavigation: true,
  });

  return (
    cache ?? {
      driverData: {
        nationality,
        privatePhone,
      },
    }
  );
};
