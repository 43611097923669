import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OnyxMaybeArray, OnyxTimeRange } from '@onyx/angular';
import { castArray, chain, isArray, isFinite, isNaN, isString } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { Fleet } from '../../dashboard/fleet/common/interfaces/fleet';
import { GanttQueryParamKey } from '../../dashboard/gantt/common/enums/gantt-query-param-key';
import { GanttRoute } from '../../dashboard/gantt/gantt.routes';
import { MapQueryParamKey } from '../../dashboard/map/common/enums/map-query-param-key';
import { MapRoute } from '../../dashboard/map/map.routes';
import { Order } from '../../dashboard/orders/common/interfaces/order';
import { BusinessHours } from '../interfaces/common/business-hours';
import { HelperOptions } from '../interfaces/utilities/helper-options';
import { DURATION_UNITS, DurationUnit } from '../pipes/duration.pipe';

export class CommonHelper {
  public static getOpeningHours(
    date: string | DateTime,
    businessHours: BusinessHours,
  ): OnyxTimeRange | null {
    return chain(date)
      .thru((date) => (isString(date) ? DateTime.fromISO(date) : date))
      .thru((dateTime) => dateTime.weekday)
      .thru((weekday): OnyxTimeRange | null => {
        if (weekday === 7) return businessHours.sunday;
        if (weekday === 6) return businessHours.saturday;
        return businessHours.weekday;
      })
      .value();
  }

  public static getSortedParameters(
    parameters: Fleet['additionalParameters'] | Order['parameters'],
    translateService: TranslateService,
  ) {
    return chain(parameters)
      .entries()
      .map(([key, value]) => ({ key, value }))
      .filter(
        ({ value }) =>
          value != null &&
          value !== false &&
          (isArray(value) ? value.length !== 0 : true),
      )
      .map(({ key, value }) => ({
        key,
        name: `labels.${key}`,
        value: value as NonNullable<typeof value>,
      }))
      .orderBy((parameter) =>
        translateService.instant(parameter.name).toLowerCase(),
      )
      .value();
  }

  public static handleOptions(
    items: OnyxMaybeArray<{ uuid: string } | string>,
    options: HelperOptions,
  ): void {
    const itemsUuid = new Set(
      chain(items)
        .thru((items) => castArray(items))
        .map((item) => (isString(item) ? item : item.uuid))
        .value(),
    );

    options?.selectedItems?.update((items) =>
      items.filter(({ uuid }) => !itemsUuid.has(uuid)),
    );
    options?.close$?.next();
  }

  public static isNumeric(value: string): boolean {
    return !isNaN(parseFloat(value)) && !isFinite(value);
  }

  public static showOnMap(router: Router, uuid: string): void {
    const route = router
      .createUrlTree([MapRoute.MAP], {
        queryParams: { [MapQueryParamKey.VEHICLE_UUID]: uuid },
      })
      .toString();
    router.navigateByUrl(route);
  }

  public static showOnGantt(
    router: Router,
    type: 'vehicle' | 'order',
    uuid: string,
  ): void {
    const route = router
      .createUrlTree([GanttRoute.GANTT], {
        queryParams: {
          [type === 'vehicle'
            ? GanttQueryParamKey.VEHICLE_UUID
            : GanttQueryParamKey.ORDER_UUID]: uuid,
        },
      })
      .toString();
    router.navigateByUrl(route);
  }

  public static toCompareString(value: string): string {
    return value.replace(/\s+/g, '').toLowerCase();
  }

  public static isEnum<T extends Record<string, string>>(
    enumType: T,
    value: unknown,
  ): value is T[keyof T] {
    return Object.values(enumType).includes(value as T[keyof T]);
  }

  public static formatDuration(
    duration: Duration,
    options?: {
      size?: 'm' | 's' | 'xs';
      units?: DurationUnit[];
      showPast?: boolean;
    },
  ): string {
    if (!duration.isValid) return '-';

    const {
      size = 's',
      units = DURATION_UNITS,
      showPast = false,
    } = options ?? {};

    if (duration.toMillis() < 0) {
      if (!showPast) return '-';
      duration = duration.negate();
    }

    const { days, hasDays, hours, hasHours, minutes } = {
      days: Math.round(duration.as('days')),
      hasDays: Math.round(duration.as('days')) > 0,
      hours: Math.round(duration.as('hours')),
      hasHours: Math.round(duration.as('hours')) > 0,
      minutes: Math.ceil(duration.as('minutes')),
    };

    const isSmall = size.includes('s');
    const [daysLabel, hoursLabel, minutesLabel] = ['d', 'h', 'm'].map(
      (unit) => `${isSmall ? '' : ' '}${unit}`,
    );

    let result: string;
    if (hasDays && units.includes('d')) {
      result =
        size === 'xs'
          ? `${days}${daysLabel}`
          : `${days}${daysLabel} ${hours % 24}${hoursLabel}`;
    } else if (hasHours && units.includes('h')) {
      result =
        size === 'xs'
          ? `${hours}${hoursLabel}`
          : `${hours}${hoursLabel} ${minutes % 60}${minutesLabel}`;
    } else {
      result = `${minutes}${minutesLabel}`;
    }

    return showPast && duration.as('minutes') < 0 ? `+${result}` : result;
  }
}
