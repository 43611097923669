import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxIconComponent,
  OnyxInputLabelComponent,
  OnyxPointOfInterestAddress,
  OnyxRoutePoint,
} from '@onyx/angular';
import { OrderFormRestorePointType } from '../../../common/enums/order-form-cancel-action-type';
import { OrderPointCategory } from '../../../common/enums/order-point-category';
import { OrderPointType } from '../../../common/enums/order-point-type';
import { OrderFormGood } from '../../../common/interfaces/order-form-good';
import { OrderFormService } from '../../../common/services/order-form.service';
import { OrderPointBaseFormComponent } from '../order-point-form/order-point-base-form/order-point-base-form.component';
import { OrderLoadingPointGoodFormComponent } from './order-loading-point-good-form/order-loading-point-good-form.component';

export type OrderLoadingPointFormGroup = ReturnType<
  typeof OrderLoadingPointFormComponent.buildForm
>;
export type OrderLoadingPointForm = ReturnType<
  OrderLoadingPointFormGroup['getRawValue']
>;

@Component({
  selector: 'app-order-loading-point-form',
  imports: [
    OrderPointBaseFormComponent,
    OnyxInputLabelComponent,
    TranslatePipe,
    ReactiveFormsModule,
    OrderLoadingPointGoodFormComponent,
    OnyxIconComponent,
    OnyxButtonComponent,
  ],
  templateUrl: './order-loading-point-form.component.html',
  styleUrl: './order-loading-point-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderLoadingPointFormComponent {
  protected readonly I18N = 'orders.orderForm.points.loadingPoint';

  public form = input.required<OrderLoadingPointFormGroup>();
  public goods = input.required<OrderFormGood[]>();

  protected goodsFormArray = computed(() => this.form().controls.goods);

  constructor(
    private fb: NonNullableFormBuilder,
    private orderFormService: OrderFormService,
  ) {}

  protected addGood(): void {
    this.goodsFormArray().push(
      OrderLoadingPointGoodFormComponent.buildForm(this.fb),
    );
  }

  protected removeGood(index: number): void {
    this.orderFormService.addRestorePoint(
      OrderFormRestorePointType.REMOVED_GOOD,
    );
    this.goodsFormArray().removeAt(index);
    if (!this.goodsFormArray().length) this.addGood();
  }

  public static buildForm(
    fb: NonNullableFormBuilder,
    timeWindowsCount = 1,
    goodsCount = 1,
  ) {
    return fb.group({
      uuid: fb.control<string | null>(null),
      category: fb.control(OrderPointCategory.LOADING as const),
      type: fb.control(OrderPointType.LOADING as const),
      address: fb.control<OnyxPointOfInterestAddress | null>(null, [
        Validators.required,
      ]),
      referenceNumber: fb.control<string | null>(null),
      serviceTime: fb.control<number | null>(null, [Validators.required]),
      driverNote: fb.control<string | null>(null),
      timeWindows: OrderPointBaseFormComponent.buildTimeWindowsForm(
        fb,
        timeWindowsCount,
      ),
      goods: fb.array(
        Array.from({ length: goodsCount }).map(() =>
          OrderLoadingPointGoodFormComponent.buildForm(fb),
        ),
      ),
      route: fb.control<OnyxRoutePoint | null>(null),
    });
  }
}
