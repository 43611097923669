import { NgClass } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  OnInit,
  computed,
  effect,
  input,
  output,
  viewChildren,
} from '@angular/core';
import { OnyxBaseFormControlComponent } from '../../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { INTEGER_REGEXP } from '../../../../internal/constants';
import { OnyxClearButtonComponent } from '../../../buttons';
import { OnyxIconComponent } from '../../../icons';
import { OnyxInputLabelComponent } from '../../../labels';
import { OnyxTooltipContext } from '../../../tooltip';

const TIME_PATTERN = /[0-2][0-9](:[0-5][0-9]){1,2}/;
const DURATION_PATTERN = /[0-9]{1,2}(:[0-5][0-9]){1,2}/;

export interface TimepickerInputValue {
  hour?: number;
  minutes?: number;
}

@Component({
  selector: 'onyx-timepicker-input',
  imports: [
    NgClass,
    OnyxIconComponent,
    OnyxClearButtonComponent,
    OnyxInputLabelComponent,
  ],
  templateUrl: './onyx-timepicker-input.component.html',
  styleUrl: './onyx-timepicker-input.component.scss',
})
export class OnyxTimepickerInputComponent
  extends OnyxBaseFormControlComponent
  implements OnInit
{
  public label = input<string | boolean>();
  public hint = input<OnyxTooltipContext>();
  public type = input.required<'time' | 'duration'>();
  public showClockIcon = input(true);
  public value = input.required<TimepickerInputValue>();
  public disabled = input.required<boolean>();
  public invalid = input<boolean>();

  public changeValue = output<TimepickerInputValue>();

  protected isTime = computed(() => this.type() === 'time');

  private inputElementRefs =
    viewChildren<ElementRef<HTMLInputElement>>('inputElement');

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
  ) {
    super(injector, destroyRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    effect(() => this.setInputValues(this.value()), {
      injector: this.injector,
    });
  }

  public focus(): void {
    this.getInputs().hour.focus();
  }

  protected changeHour(event: Event): void {
    const input = event.target as HTMLInputElement;
    const data = (event as InputEvent).data!;

    if (!data && data !== '0') {
      input.value = '';
    } else if (Number(input.value) > (this.isTime() ? 23 : 99)) {
      input.value = data;
    }

    const value = {
      ...this.value(),
      hour: input.value ? Number(input.value) : undefined,
    };
    if (input.value && value.minutes == null) value.minutes = 0;

    this.changeValue.emit(value);
  }

  protected changeMinutes(event: Event): void {
    const input = event.target as HTMLInputElement;
    const data = (event as InputEvent).data!;

    if (!data && data !== '0') {
      input.value = '';
    } else if (Number(input.value) > 59) {
      input.value = data;
    }

    this.changeValue.emit({
      ...this.value(),
      minutes: input.value ? Number(input.value) : undefined,
    });
  }

  protected validateInput(event: KeyboardEvent, maxValue: number): boolean {
    if (INTEGER_REGEXP.test(event.key) && Number(event.key) < maxValue) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  protected clearValue(): void {
    this.changeValue.emit({});
  }

  protected pasteTime(event: ClipboardEvent): boolean {
    const value = event.clipboardData?.getData('text');
    const pattern = this.isTime() ? TIME_PATTERN : DURATION_PATTERN;
    if (!value || !pattern.test(value)) return false;

    const [hour, minutes] = value.split(':').map(Number);
    this.changeValue.emit({ hour, minutes });

    this.getInputs().minutes.focus();
    return false;
  }

  protected focusInputs(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = this.value();
    const inputs = this.getInputs();

    if (value.hour == null) {
      inputs.hour.focus();
    } else if (value.minutes == null) {
      inputs.minutes.focus();
    } else if (target.nodeName !== 'INPUT') {
      inputs.hour.focus();
    }
  }

  protected focusHoursInput(): void {
    this.getInputs().hour.focus();
  }

  private setInputValues(value: TimepickerInputValue): void {
    const inputs = this.getInputs();

    inputs.hour.value =
      value.hour != null ? String(value.hour).padStart(2, '0') : '';
    if (value.hour != null && value.hour > (this.isTime() ? 2 : 9)) {
      inputs.minutes.focus();
    }

    inputs.minutes.value =
      value.minutes != null ? String(value.minutes).padStart(2, '0') : '';
  }

  private getInputs(): { hour: HTMLInputElement; minutes: HTMLInputElement } {
    const hour = this.inputElementRefs()[0].nativeElement;
    const minutes = this.inputElementRefs()[1].nativeElement;
    return { hour, minutes };
  }
}
