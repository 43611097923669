import { Injectable } from '@angular/core';
import {
  OnyxToastOverride,
  OnyxToastService,
  OnyxToastType,
} from '@onyx/angular';
import {
  cloneDeepWith,
  isArray,
  isEmpty,
  isObject,
  isPlainObject,
} from 'lodash';
import { DateTime, Duration } from 'luxon';
import { finalize, Observable, of, shareReplay, tap } from 'rxjs';

interface CacheItem<T> {
  value: T;
  expiry: string;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private pendingRequests$ = new Map<string, Observable<any>>();

  constructor(private toastService: OnyxToastService) {
    this.clean();
  }

  public get<T>(
    key: string,
    toastOverride?: OnyxToastOverride | false,
  ): T | null {
    const item: CacheItem<T> | null = JSON.parse(localStorage.getItem(key)!);
    if (item == null) return null;

    const expired = DateTime.fromISO(item.expiry) < DateTime.utc();
    if (expired) {
      this.delete(key);
      return null;
    }

    if (toastOverride !== false) {
      this.toastService.showCustom(
        OnyxToastType.FORM_CHANGES_RESTORED,
        toastOverride,
      );
    }
    return item.value;
  }

  public set<T>(
    key: string,
    value: T,
    ttl = Duration.fromObject({ minutes: 60 }),
  ): void {
    const cacheValue = cloneDeepWith(value, (value) => {
      const isEmptyOrFile = (value: unknown): boolean => {
        return (
          value instanceof File || (isPlainObject(value) && isEmpty(value))
        );
      };

      if (isArray(value) && value.every((item) => isEmptyOrFile(item))) {
        return [];
      } else if (isEmptyOrFile(value)) {
        return null;
      }

      return undefined;
    });

    localStorage.setItem(
      key,
      JSON.stringify({
        value: cacheValue,
        expiry: DateTime.utc().plus(ttl).toISO(),
      } satisfies CacheItem<T>),
    );
  }

  public cacheRequest<T>(
    key: string,
    request$: Observable<T>,
    ttl = Duration.fromObject({ minutes: 5 }),
  ): Observable<T> {
    const cache = this.get<T>(key, false);
    if (cache) return of(cache);

    let pendingRequest$ = this.pendingRequests$.get(key);
    if (pendingRequest$) return pendingRequest$;

    pendingRequest$ = request$.pipe(
      tap((result) => this.set(key, result, ttl)),
      finalize(() => this.pendingRequests$.delete(key)),
      shareReplay(1),
    );
    this.pendingRequests$.set(key, pendingRequest$);

    return pendingRequest$;
  }

  public delete(key: string): void {
    localStorage.removeItem(key);
  }

  private clean(): void {
    const isCacheItem = (item: unknown): item is CacheItem<unknown> => {
      if (!isObject(item)) return false;
      return 'value' in item && 'expiry' in item;
    };

    for (const key of Object.keys(localStorage)) {
      let item;
      try {
        const value = localStorage.getItem(key)!;
        item = JSON.parse(value);
      } catch {
        continue;
      }

      if (!isCacheItem(item)) continue;
      if (DateTime.fromISO(item.expiry) < DateTime.now()) this.delete(key);
    }
  }
}
