import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnyxOption, OnyxOptional } from '@onyx/angular';
import { chain } from 'lodash';
import { forkJoin, map, Observable, of, shareReplay } from 'rxjs';
import { AuthService } from '../../auth/common/services/auth.service';
import { FleetCategory } from '../../dashboard/fleet/common/enums/fleet-category';
import { VehiclePlanningMode } from '../../dashboard/fleet/common/enums/vehicle-planning-mode';
import { EmployeeRole } from '../../dashboard/management-panel/employees/common/enums/employee-role';
import { OrderRejectReason } from '../../dashboard/orders/common/enums/order-reject-reason';
import {
  DictionaryCode,
  DictionaryResponseByCode,
  DictionaryType,
} from '../enums/dictionary-code';
import { FuelType } from '../enums/fuel-type';
import { DictionariesStorageKey } from '../enums/storage/dictionaries-storage-key';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private translateService: TranslateService,
    private authService: AuthService,
    private preferencesService: PreferencesService,
    private cacheService: CacheService,
  ) {
    super(http);
  }

  public getDictionary<T extends DictionaryCode>(
    code: T,
    { icons = true }: { icons?: boolean } = {},
  ): Observable<DictionaryType<T>> {
    const language =
      this.translateService.currentLang ?? this.translateService.defaultLang;
    const cacheKey = `${DictionariesStorageKey.DICTIONARY}-${code}-${language}`;

    return this.cacheService
      .cacheRequest(
        cacheKey,
        this.get<DictionaryResponseByCode[T][]>(
          `/dictionaries/${code}/${language}`,
        ).pipe(
          map((dictionary) => {
            switch (code) {
              // TEMP: Wait for BE implementation
              case DictionaryCode.ORDER_REJECT_REASON:
                return [
                  {
                    name: 'Puste km',
                    value: OrderRejectReason.EMPTY_KM,
                  },
                  {
                    name: 'Czas oczekiwania',
                    value: OrderRejectReason.WAITING_TIME,
                  },
                  {
                    name: 'Pojazd niedostępny',
                    value: OrderRejectReason.VEHICLE_UNAVAILABLE,
                  },
                  {
                    name: 'Inny',
                    value: OrderRejectReason.OTHER,
                  },
                ] satisfies DictionaryType<DictionaryCode.ORDER_REJECT_REASON> as DictionaryResponseByCode[T][];
              // END TEMP
              default:
                return dictionary;
            }
          }),
        ),
      )
      .pipe(
        map((dictionary) => {
          if (!icons) return dictionary;

          return dictionary.map((option) => {
            switch (code) {
              case DictionaryCode.ACCOUNT_TYPE:
                return {
                  ...option,
                  leftIcon: {
                    name: `account-${option.value}`,
                    size: 16,
                  },
                  leftIconColor: 'blue',
                };

              case DictionaryCode.ADR_CLASS:
                return {
                  ...option,
                  leftIcon: { name: option.value, size: 16 },
                };

              case DictionaryCode.CONTRACTOR_STATUS:
              case DictionaryCode.EMPLOYEE_STATUS:
              case DictionaryCode.FLEET_STATE: {
                const getDotColor = () => {
                  switch (option.value) {
                    case 'active':
                      return 'green';
                    case 'blocked':
                      return 'red';
                    case 'unavailable':
                      return 'red';
                    case 'invited':
                      return 'yellow';
                    default:
                      return 'gray-alt';
                  }
                };
                return {
                  ...option,
                  leftDotColor: getDotColor(),
                };
              }

              case DictionaryCode.COUNTRY:
                return {
                  ...option,
                  leftFlag: option.value,
                };

              case DictionaryCode.CURRENCY:
                return {
                  ...option,
                  leftFlag: (
                    option as DictionaryResponseByCode[DictionaryCode.CURRENCY]
                  ).flag,
                };

              case DictionaryCode.DRIVER_UNAVAILABILITY_REASON:
              case DictionaryCode.EMPLOYEE_UNAVAILABILITY_REASON:
                return {
                  ...option,
                  leftIcon: {
                    name: option.value,
                    size: 16,
                  },
                  leftIconColor: 'gray-alt',
                };

              case DictionaryCode.ORDER_POINT_TYPE:
                return {
                  ...option,
                  leftIcon: {
                    name: (
                      option as DictionaryResponseByCode[DictionaryCode.ORDER_POINT_TYPE]
                    ).icon,
                    size: 16,
                  },
                  leftIconColor: (
                    option as DictionaryResponseByCode[DictionaryCode.ORDER_POINT_TYPE]
                  ).color,
                };

              case DictionaryCode.SEMI_TRAILER_SIZE:
                return {
                  ...option,
                  leftIcon: {
                    name: `semi-trailer-${option.value}`,
                    size: 16,
                  },
                  leftIconColor: 'blue',
                };

              case DictionaryCode.TACHOGRAPH_WORKING_MODE:
                return {
                  ...option,
                  leftIcon: {
                    name: `tacho-${option.value}`,
                    size: 16,
                  },
                  leftIconColor: 'gray-alt',
                };

              case DictionaryCode.TRAILER_CATEGORY:
              case DictionaryCode.VEHICLE_CATEGORY:
                return {
                  ...option,
                  leftIcon: { name: option.value, size: 16 },
                  leftIconColor: 'gray-alt',
                };

              case DictionaryCode.VEHICLE_PLANNING_MODE:
                return {
                  ...option,
                  leftIcon: {
                    name:
                      option.value === VehiclePlanningMode.AUTO
                        ? 'signet-light'
                        : 'manual',
                    size: 16,
                  },
                  leftIconColor:
                    option.value === VehiclePlanningMode.AUTO
                      ? 'black'
                      : 'violet',
                };

              default:
                return option;
            }
          });
        }),
        map((dictionary) => {
          switch (code) {
            case DictionaryCode.CURRENCY:
              return chain(dictionary)
                .groupBy(
                  ({ value }) =>
                    value === this.preferencesService.defaultCurrency(),
                )
                .thru((groups) => [groups['true'], groups['false']])
                .compact()
                .map((options) => ({ options }))
                .value();

            default:
              return dictionary;
          }
        }),
        map((dictionary) => dictionary as DictionaryType<T>),
        shareReplay(1),
      );
  }

  public getAvailableEmployeeRoles(): Observable<
    DictionaryType<DictionaryCode.EMPLOYEE_ROLE>
  > {
    return this.getDictionary(DictionaryCode.EMPLOYEE_ROLE).pipe(
      map((roles) => {
        const userRoles = this.authService.user()?.roles;
        const isAdmin = userRoles?.includes(EmployeeRole.BUSINESS_ADMIN);
        const isManager = isAdmin || userRoles?.includes(EmployeeRole.MANAGER);

        return roles.filter((role) => {
          if (role.value === EmployeeRole.BUSINESS_ADMIN) return isAdmin;
          if (role.value === EmployeeRole.MANAGER) return isAdmin;
          return isManager;
        });
      }),
      shareReplay(1),
    );
  }

  public getFleetCategories({
    icons = true,
  }: { icons?: boolean } = {}): Observable<OnyxOption<FleetCategory>[]> {
    return forkJoin([
      this.getDictionary(DictionaryCode.VEHICLE_CATEGORY, { icons }),
      this.getDictionary(DictionaryCode.TRAILER_CATEGORY, { icons }),
    ]).pipe(
      map((categories) => categories.flat()),
      shareReplay(1),
    );
  }

  public getFuelUnit(fuelType: OnyxOptional<FuelType>): Observable<string> {
    if (!fuelType) return of('??');

    return this.getDictionary(DictionaryCode.FUEL_TYPE, { icons: false }).pipe(
      map(
        (fuelTypes) =>
          fuelTypes.find(({ value }) => value === fuelType)?.units[0] ?? '??',
      ),
      shareReplay(1),
    );
  }

  public getVatIdCountries(): Observable<OnyxOption<string>[]> {
    return this.getDictionary(DictionaryCode.COUNTRY).pipe(
      map((countries) =>
        chain(countries)
          .map((country) => ({
            ...country,
            name: country.value.toUpperCase(),
          }))
          .orderBy('name')
          .value(),
      ),
      shareReplay(1),
    );
  }
}
