import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { isEqual } from 'lodash';
import { map } from 'rxjs';
import { RouteComponent } from '../../../../../../common/components/route/route.component';
import { RouteService } from '../../../../../../common/services/route.service';
import { OrderFormService } from '../../../../common/services/order-form.service';

@Component({
  selector: 'app-order-form-route-modal-route',
  standalone: true,
  imports: [
    TranslatePipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxTooltipDirective,
    OnyxDropdownDirective,
    AsyncPipe,
    RouteComponent,
  ],
  templateUrl: './order-form-route-modal-route.component.html',
  styleUrl: './order-form-route-modal-route.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormRouteModalRouteComponent {
  protected readonly I18N = 'orders.orderForm.route';

  protected readonly addOptions$ = this.routeService.addOptions$;

  private readonly restorePoint = this.orderFormService.form.getRawValue();

  protected isExpanded = this.routeService.isRouteExpanded;
  protected isChanged = toSignal(
    this.orderFormService.valueChanges$.pipe(
      map((value) => !isEqual(value, this.restorePoint)),
      takeUntilDestroyed(this.destroyRef),
    ),
    { initialValue: false },
  );

  protected points = this.orderFormService.points;
  protected config = this.orderFormService.routeConfig;

  constructor(
    private orderFormService: OrderFormService,
    private destroyRef: DestroyRef,
    private routeService: RouteService,
  ) {}

  protected undoChanges(): void {
    this.orderFormService.restoreForm(this.restorePoint);
  }

  protected toggleExpanded(): void {
    this.routeService.toggleRouteExpanded();
  }
}
