export enum UserSettingsScope {
  // Filters
  CONTRACTORS_FILTERS = 'contractors-filters',
  DELEGATIONS_FILTERS = 'delegations-filters',
  DRIVERS_FILTERS = 'drivers-filters',
  EMPLOYEES_FILTERS = 'employees-filters',
  FLEET_FILTERS = 'fleet-filters',
  GANTT_FILTERS = 'gantt-filters',
  MAP_FILTERS = 'map-filters',
  POINTS_OF_INTEREST_FILTERS = 'points-of-interest-filters',

  // Pagination limits
  CONTRACTORS_PAGINATION_LIMIT = 'contractors-pagination-limit',
  DELEGATIONS_PAGINATION_LIMIT = 'delegations-pagination-limit',
  DRIVERS_PAGINATION_LIMIT = 'drivers-pagination-limit',
  EMPLOYEES_PAGINATION_LIMIT = 'employees-pagination-limit',
  FLEET_PAGINATION_LIMIT = 'fleet-pagination-limit',
  INTEGRATIONS_DRIVERS_PAGINATION_LIMIT = 'integrations-drivers-pagination-limit',
  INTEGRATIONS_VEHICLES_PAGINATION_LIMIT = 'integrations-vehicles-pagination-limit',
  ORDERS_PAGINATION_LIMIT = 'orders-pagination-limit',
  POINTS_OF_INTEREST_PAGINATION_LIMIT = 'points-of-interest-pagination-limit',

  // Views
  CONTRACTORS_VIEWS = 'contractor-views',
  DELEGATIONS_VIEWS = 'delegations-views',
  DRIVERS_VIEWS = 'drivers-views',
  FLEET_VIEWS = 'fleet-views',
  ORDERS_VIEWS = 'orders-views',
  GANTT_VIEWS = 'gantt-views',
}
