import { OnyxAddressType, OnyxAmount, OnyxPaginated } from '@onyx/angular';
import { uniqueId } from 'lodash';
import { GoodsSizeType } from '../../../../common/enums/goods-size-type';
import { VatRate } from '../../../../common/enums/vat-rate';
import { FleetCategory } from '../../../fleet/common/enums/fleet-category';
import { FleetState } from '../../../fleet/common/enums/fleet-state';
import { SemiTrailerSize } from '../../../fleet/common/enums/semi-trailer-size';
import { VehiclePlanningMode } from '../../../fleet/common/enums/vehicle-planning-mode';
import { SimplifiedFleet } from '../../../fleet/common/interfaces/fleet';
import { EmployeeRole } from '../../../management-panel/employees/common/enums/employee-role';
import { TelematicsProvider } from '../../../management-panel/integrations/common/enums/telematics-provider';
import { OrderPointCategory } from '../enums/order-point-category';
import { OrderPointStatus } from '../enums/order-point-status';
import { OrderPointType } from '../enums/order-point-type';
import { OrderRateType } from '../enums/order-rate-type';
import { OrderSemiTrailerSize } from '../enums/order-semi-trailer-size';
import { OrderStatus } from '../enums/order-status';
import { OrderTimeWindowType } from '../enums/order-time-window-type';
import { Order } from '../interfaces/order';

export const ORDER_MOCKS: OnyxPaginated<Order> = {
  items: [
    {
      uuid: uniqueId('order'),
      identifier: 'OR-0021-11-24-ONY',
      status: {
        value: OrderStatus.COMPLETING_DOCUMENTS,
        color: 'blue',
      },
      payments: {
        paymenthMethod: 'gold',
        invoiceTerm: '2025-01-10T12:00:00',
        invoice: {
          number: 'FV/02/2024/0872/7754',
          sellDate: '2025-01-10',
          paymentTerm: '2025-01-20',
          nbpRateDay: '2025-01-10',
          net: {
            currency: 'pln',
            value: 100000,
          } as OnyxAmount,
          vat: {
            currency: 'pln',
            value: 23000,
          } as OnyxAmount,
          scan: null,
        },
        history: [
          {
            uuid: '123333',
            date: '2025-01-30',
            value: {
              currency: 'pln',
              value: 50000,
            } as OnyxAmount,
          },
          {
            uuid: '1232333',
            date: '2025-01-31',
            value: {
              currency: 'pln',
              value: 100000,
            } as OnyxAmount,
          },
        ],
      },
      timestamps: {
        createdAt: '2025-01-01',
        vehicleSearchAt: '2025-01-01',
        toAcceptAt: null,
        toDoAt: null,
        inProgressAt: null,
        completingDocumentsAt: '2025-01-28T12:00:00',
        invoiceToIssueAt: '2025-01-28T12:00:00',
        waitingForPaymentAt: null,
        partiallyPaidAt: '2025-02-20',
        paidAt: '2025-02-20T12:00:00',
        canceledAt: null,
        archivedAt: null,
      },
      route: {
        isVerified: true,
        isBlocked: false,
        config: {
          resultCurrency: 'pln',
          vehicleCategory: FleetCategory.SEMI_TRUCK,
        },
        summary: {
          countries: ['pl', 'fi', 'de', 'fr'],
          distance: 400000,
          duration: 1000000,
          tollsCost: {
            date: '2024-11-27',
            currency: 'pln',
            value: 100,
            defaultCurrency: 'pln',
            defaultCurrencyValue: 5300,
          } as OnyxAmount,
          tolls: [],
          adBlueConsumption: 0,
          adBlueCost: null,
          fuelConsumption: 0,
          fuelCost: null,
          totalFuelCost: null,
          totalCost: {
            date: '2024-11-27',
            currency: 'pln',
            value: 100,
            defaultCurrency: 'pln',
            defaultCurrencyValue: 5300,
          } as OnyxAmount,
        },
        author: {
          uuid: '15',
          firstName: 'Patryk',
          lastName: 'Kawiak',
          avatar: null,
          roles: [
            {
              value: EmployeeRole.BUSINESS_ADMIN,
              color: 'red',
            },
          ],
        },
        updatedAt: '2025-01-28T12:00:00',
      },

      engineProposalData: null,
      // outsourcingData: null,

      // engineProposalData: {
      //   emptyDistance: 50,
      //   cabotage: 3,
      //   cabotageLimit: 3,

      //   emptyDistanceDuration: 1000,
      //   vehicle: {
      //     uuid: '87a3b13f-2701-45af-bc1d-731d47e73c50',
      //     generalInformation: {
      //       category: FleetCategory.SEMI_TRUCK,
      //       type: null,
      //       registrationNumber: 'GSZ 3AU7',
      //       vinNumber: '2LMDU68C27BJ83210',
      //       manufactureYear: 2015,
      //       firstRegistrationDate: '2024-11-12',
      //       make: 'Hyundai',
      //       model: 'Forentern',
      //       sideNumber: '099',
      //       maxSpeed: 15,
      //       initialMileage: null,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //       assignedBase: null,
      //     },
      //     fuelTankCapacity: {
      //       fuelType: 'diesel',
      //       mainTank: 100000,
      //       adBlueTank: null,
      //       additionalTank: 5000,
      //       generatorFuelTank: null,
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: true,
      //       beltsNumber: 24,
      //       hooksNumber: 14,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: null,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   trailer: {
      //     uuid: '94397e19-439b-4cdc-ad74-231c38ceec63',
      //     generalInformation: {
      //       assignedBase: null,
      //       category: FleetCategory.SEMI_TRAILER,
      //       type: 'insulated',
      //       registrationNumber: 'K1 WIZZY',
      //       vinNumber: 'WAUAF48H99K021450',
      //       manufactureYear: 1999,
      //       firstRegistrationDate: '2024-11-01',
      //       make: 'Gniotpol',
      //       model: 'Jool',
      //       sideNumber: '015',
      //       maxSpeed: null,
      //       initialMileage: 15555,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: null,
      //       beltsNumber: null,
      //       hooksNumber: null,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: SemiTrailerSize.MEGA,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   drivers: {
      //     primaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Adrian',
      //         lastName: 'Nowak ',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //     secondaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Adrian',
      //         lastName: 'Nowak II',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //   },
      // },
      outsourcingData: null,
      // outsourcingData: {
      //   contactPerson: {
      //     timocomId: '123',
      //     transId: '123',
      //     email: '123',
      //     firstName: '123',
      //     lastName: '123',
      //     note: '123',
      //     phone: {
      //       areaCode: '123',
      //       number: '123',
      //     },
      //     roles: [EmployeeRole.BUSINESS_ADMIN],
      //   },
      //   contractor: {
      //     note: null,
      //     uuid: '123',
      //     companyProfile: {
      //       companyName: '123',
      //       country: 'pl',
      //       displayName: 'Omega',
      //       phone: {
      //         areaCode: '+48',
      //         number: '1234214124',
      //       },
      //       types: [],
      //       vatId: '123',
      //     },
      //   },
      //   paymentTerm: '2024-11-27',
      //   primaryDriver: {
      //     name: 'Adam Róża',
      //     phone: {
      //       areaCode: '+48',
      //       number: '1234214124',
      //     },
      //   },
      //   secondaryDriver: {
      //     name: 'Tomasz Róża',
      //     phone: {
      //       areaCode: '+48',
      //       number: '1234214124',
      //     },
      //   },

      //   executionTerms: '',
      //   marginPercentage: 2,
      //   marginRate: 20000,
      //   note: '',
      //   rate: {
      //     value: 100,
      //     currency: 'pln',
      //     defaultCurrencyValue: 100,
      //     defaultCurrency: 'pln',
      //     date: null,
      //   } as Amount,
      //   rateType: OrderRateType.FREIGHT,
      //   trailerRegistrationNumber: null,
      //   vehicleRegistrationNumber: 'GKW RS6GT',
      //   vatRate: '12',
      // },
      realizationData: {
        elapsedDistance: 400000,
        elapsedDuration: 1000000,
        emptyDistance: 50000,
        emptyDistanceDuration: 10000,
        remainingDistance: 150000,
        remainingDuration: 120000,
      },
      // realizationData: null,
      assignedEmployees: [
        {
          uuid: '15',
          firstName: 'Patryk',
          lastName: 'Kawiak',
          avatar: null,
          roles: [
            {
              value: EmployeeRole.BUSINESS_ADMIN,
              color: 'red',
            },
          ],
        },
        {
          uuid: '001',
          firstName: 'Adam',
          lastName: 'Rogacz',
          avatar: null,
          roles: [
            {
              value: EmployeeRole.BUSINESS_ADMIN,
              color: 'red',
            },
          ],
        },
      ],
      assignedData: {
        emptyDistance: 40000,
        emptyDistanceDuration: 1000,
        price: {
          date: '2024-11-27',
          currency: 'pln',
          value: 300000,
          defaultCurrency: 'pln',
          defaultCurrencyValue: 300000,
        } as OnyxAmount,
        vehicle: {
          uuid: '87a3b13f-2701-45af-bc1d-731d47e73c50',
          telematicsProvider: TelematicsProvider.ONYX,
          generalInformation: {
            category: FleetCategory.SEMI_TRUCK,
            type: null,
            registrationNumber: 'GSZ 3AU7',
            vinNumber: '2LMDU68C27BJ83210',
            manufactureYear: 2015,
            firstRegistrationDate: '2024-11-12',
            make: 'Hyundai',
            model: 'Forentern',
            sideNumber: '099',
            maxSpeed: 15,
            initialMileage: null,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
            assignedBase: null,
          },
          fuelTankCapacity: {
            fuelType: 'diesel',
            mainTank: 100000,
            adBlueTank: null,
            additionalTank: 5000,
            totalCapacity: 15555,
            generatorFuelTank: null,
          },
          additionalParameters: {
            adrClasses: null,
            isLowDeck: true,
            beltsNumber: 24,
            hooksNumber: 14,
            hasSanitaryInspection: null,
            hasHaccp: null,
            hasHdsCrane: null,
            matsNumber: null,
            coilWellLength: null,
            trailerSize: null,
            semiTrailerSize: null,
            hasLoadingsRamps: null,
            temperatureRange: null,
            hasTailLift: null,
            hasDumpContainer: null,
            hasForklift: null,
            other: [],
            hasCustomsSecuringRope: null,
          },
          cargoSpace: {
            loadCapacity: null,
            volumeCapacity: null,
            chambersNumber: null,
            tankCertificates: null,
            dischargeTypes: null,
            europalletsNumber: null,
            dimensions: {
              length: null,
              width: null,
              height: null,
            },
          },
        },
        trailer: {
          uuid: '94397e19-439b-4cdc-ad74-231c38ceec63',
          telematicsProvider: TelematicsProvider.ONYX,
          generalInformation: {
            assignedBase: null,
            category: FleetCategory.SEMI_TRAILER,
            type: 'insulated',
            registrationNumber: 'K1 WIZZY',
            vinNumber: 'WAUAF48H99K021450',
            manufactureYear: 1999,
            firstRegistrationDate: '2024-11-01',
            make: 'Gniotpol',
            model: 'Jool',
            sideNumber: '015',
            maxSpeed: null,
            initialMileage: 15555,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
          },
          additionalParameters: {
            adrClasses: null,
            isLowDeck: null,
            beltsNumber: null,
            hooksNumber: null,
            hasSanitaryInspection: null,
            hasHaccp: null,
            hasHdsCrane: null,
            matsNumber: null,
            coilWellLength: null,
            trailerSize: null,
            semiTrailerSize: SemiTrailerSize.MEGA,
            hasLoadingsRamps: null,
            temperatureRange: null,
            hasTailLift: null,
            hasDumpContainer: null,
            hasForklift: null,
            other: [],
            hasCustomsSecuringRope: null,
          },
          cargoSpace: {
            loadCapacity: 24000,
            volumeCapacity: null,
            chambersNumber: null,
            tankCertificates: null,
            dischargeTypes: ['top', 'rear', 'side'],
            europalletsNumber: 16,
            dimensions: {
              length: 100,
              width: 100,
              height: 100,
            },
          },
        },
        primaryDriver: {
          uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
          driverData: {
            assignedBase: null,
            firstName: 'Adam',
            lastName: 'Różalski',
            birthDate: '1985-12-04',
            nationality: 'pl',
            privatePhone: {
              areaCode: '+48',
              number: '559099422',
            },
            workPhone: null,
            privateEmail: 'a.nowak@onyxtms.com',
            homeAddress: {
              label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
              street: 'Jeziorna',
              houseNumber: '11B',
              apartmentNumber: null,
              zipCode: '82-520',
              city: 'Krzykosy',
              name: '123',
              countryCode: 'pl',
              coordinates: {
                latitude: 53.68513,
                longitude: 19.01345,
              },
              type: OnyxAddressType.CUSTOM,
            },
            peselNumber: '85120441461',
          },
          driverCard: {
            number: '123123123123123',
            issuingCountry: 'pl',
            expirationDate: '2024-11-30',
            scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
          },
          tachograph: null,
        },
        secondaryDriver: {
          uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
          driverData: {
            assignedBase: null,
            firstName: 'Michał',
            lastName: 'Nowiński',
            birthDate: '1985-12-04',
            nationality: 'pl',
            privatePhone: {
              areaCode: '+48',
              number: '559099422',
            },
            workPhone: null,
            privateEmail: 'a.nowak@onyxtms.com',
            homeAddress: {
              label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
              street: 'Jeziorna',
              houseNumber: '11B',
              name: '123',
              apartmentNumber: null,
              zipCode: '82-520',
              city: 'Krzykosy',
              countryCode: 'pl',
              coordinates: {
                latitude: 53.68513,
                longitude: 19.01345,
              },
              type: OnyxAddressType.CUSTOM,
            },
            peselNumber: '85120441461',
          },
          driverCard: {
            number: '123123123123123',
            issuingCountry: 'pl',
            expirationDate: '2024-11-30',
            scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
          },
          tachograph: null,
        },
      },

      // manualProposalData: {
      //   isLoading: true,
      //   deleyedRoute: null,
      //   isOk: null,
      //   emptyDistance: 0,
      //   emptyDistanceDuration: 0,
      //   cabotage: 3,
      //   cabotageLimit: 4,

      //   vehicle: {
      //     uuid: '87a3b13f-2701-45af-bc1d-731d47e73c50',
      //     generalInformation: {
      //       category: FleetCategory.SEMI_TRUCK,
      //       type: null,
      //       registrationNumber: 'GSZ 3AU7',
      //       vinNumber: '2LMDU68C27BJ83210',
      //       manufactureYear: 2015,
      //       firstRegistrationDate: '2024-11-12',
      //       make: 'Hyundai',
      //       model: 'Forentern',
      //       sideNumber: '099',
      //       maxSpeed: 15,
      //       initialMileage: null,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //       assignedBase: null,
      //     },
      //     fuelTankCapacity: {
      //       fuelType: 'diesel',
      //       mainTank: 100000,
      //       adBlueTank: null,
      //       additionalTank: 5000,
      //       generatorFuelTank: null,
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: true,
      //       beltsNumber: 24,
      //       hooksNumber: 14,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: null,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   trailer: {
      //     uuid: '94397e19-439b-4cdc-ad74-231c38ceec63',
      //     generalInformation: {
      //       assignedBase: null,
      //       category: FleetCategory.SEMI_TRAILER,
      //       type: 'insulated',
      //       registrationNumber: 'K1 WIZZY',
      //       vinNumber: 'WAUAF48H99K021450',
      //       manufactureYear: 1999,
      //       firstRegistrationDate: '2024-11-01',
      //       make: 'Gniotpol',
      //       model: 'Jool',
      //       sideNumber: '015',
      //       maxSpeed: null,
      //       initialMileage: 15555,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: null,
      //       beltsNumber: null,
      //       hooksNumber: null,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: SemiTrailerSize.MEGA,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   drivers: {
      //     primaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Adrian',
      //         lastName: 'Nowak ',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //     secondaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Adrian',
      //         lastName: 'Nowak II',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //   },
      // },

      manualProposalData: null,

      // assignedData: null,

      price: {
        date: '2024-11-27',
        currency: 'pln',
        value: 300000,
        defaultCurrency: 'pln',
        defaultCurrencyValue: 300000,
      } as OnyxAmount,
      author: {
        uuid: '111',
        firstName: 'Michał',
        lastName: 'Nowakowski',
        avatar: null,
        roles: [
          {
            value: EmployeeRole.DISPATCHER,
            color: 'green',
          },
        ],
      },

      note: {
        content: '123',
        updatedAt: '2024-04-04T15:00:00',
      },

      basicInformation: {
        sender: {
          note: null,
          uuid: 'e0e67f43-fd17-41b9-ada5-86a54e4b8243',
          companyProfile: {
            country: 'pl',
            displayName: 'Wiert MirBud ',
            companyName: 'Mango',
            phone: {
              areaCode: '+48',
              number: '152512512',
            },
            types: [],
            vatId: '000',
          },
        },
        ourBranch: {
          uuid: '123',
          name: 'Oddział I',
          mainAddress: {
            label: 'Jeziorna 32/12, 82-520 Krzykosy, PL',
            street: 'Jeziorna',
            houseNumber: '32',
            apartmentNumber: '12',
            zipCode: '82-520',
            city: 'Krzykosy',
            countryCode: 'pl',
            name: '123',
            coordinates: {
              latitude: 53.6825313,
              longitude: 19.0129617,
            },
            type: OnyxAddressType.CUSTOM,
          },
          correspondenceAddress: null,
          vatIdentification: {
            country: 'pl',
            vatId: '8992447196',
          },
        },

        senderBranch: {
          uuid: '123',
          name: 'Oddział I',
          addresses: {
            mainAddress: {
              label: 'Jeziorna 32/12, 82-520 Krzykosy, PL',
              street: 'Jeziorna',
              houseNumber: '32',
              apartmentNumber: '12',
              zipCode: '82-520',
              city: 'Krzykosy',
              name: '123',
              countryCode: 'pl',
              coordinates: {
                latitude: 53.6825313,
                longitude: 19.0129617,
              },
              type: OnyxAddressType.CUSTOM,
            },
            correspondenceAddress: {
              label: 'Jeziorna 32/12, 82-520 Krzykosy, PL',
              street: 'Jeziorna',
              houseNumber: '32',
              apartmentNumber: '12',
              zipCode: '82-520',
              city: 'Krzykosy',
              name: '123',
              countryCode: 'pl',
              coordinates: {
                latitude: 53.6825313,
                longitude: 19.0129617,
              },
              type: OnyxAddressType.CUSTOM,
            },
          },
        },
        dischargeTypes: ['side', 'bottom'],
        rate: { value: 3000, currency: 'pln', date: null } as OnyxAmount,
        rateType: OrderRateType.FREIGHT,
        vehicleTypes: ['insulated', 'curtainside'],
        loadedSemiTrailerUuid: {
          uuid: '94397e19-439b-4cdc-ad74-231c38ceec63',
          generalInformation: {
            registrationNumber: 'GTR 86XX',
            sideNumber: '015',
          },
        } as SimplifiedFleet,
        loadedSemiTrailerRegistrationNumber: null,
        minSemiTrailerSize: OrderSemiTrailerSize.MEGA,
        vatRate: VatRate.RATE_23,
      },

      parameters: {
        adrClasses: null,
        beltsNumber: null,
        coilWellLength: null,
        driverLanguages: ['pl', 'de'],
        hasCustomsSecuringRope: null,
        hasDoubleCrew: true,
        hasDumpContainer: null,
        hasEcmr: false,
        isExpress: false,
        hasForklift: false,
        hasHaccp: false,
        hasHdsCrane: false,
        hasLoadingsRamps: false,
        hasPalletExchange: false,
        hasSanitaryInspection: false,
        hasTailLift: false,
        hooksNumber: null,
        isDedicated: true,
        isExclusive: true,
        matsNumber: null,
        hasDoubleDeck: true,
        hasSafeParking: true,
        sentNotificationNumber: '04624',
        temperatureRange: null,
        other: [],
      },

      documents: [
        {
          name: 'CMR',
          note: '',
          attachments: ['144b9127-2035-4f5c-9f16-473a5fcc5171'],
        },
        {
          name: 'Faktura za parking',
          note: 'Do opłacenia',
          attachments: ['144b9127-2035-4f5c-9f16-473a5fcc5171'],
        },
        {
          name: 'Beförderungsdokument',
          note: '',
          attachments: ['144b9127-2035-4f5c-9f16-473a5fcc5171'],
        },
      ],

      points: [
        {
          estimations: {
            arrivalTime: '2025-01-13T13:20:00',
            departureTime: '2025-01-16T09:00:00',
            deadlineTime: '2025-01-14T15:00:00',
          },
          timestamps: {
            arrivedAt: null,
            canceledAt: null,
            completedAt: null,
            startedAt: '2025-01-14T02:06:00',
          },
          route: {
            departure: {
              latitude: 0,
              longitude: 0,
            },
            legs: [],
            summary: {
              distance: 1000,
              duration: 6000,
              countries: ['pl'],
              adBlueConsumption: 0,
              adBlueCost: null,
              fuelConsumption: 0,
              fuelCost: null,
              totalFuelCost: null,
              tollsCost: {
                currency: 'pln',
                value: 5000,
              } as OnyxAmount,
              tolls: [],
              totalCost: {
                currency: 'pln',
                value: 5000,
              } as OnyxAmount,
            },
            remainingDistance: 900,
            remainingDuration: 5000,
          },
          status: OrderPointStatus.TO_DO,

          uuid: '123124214',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            name: '123',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.CHECKPOINT,
          driverNote: 'uważaj na dziki',
          serviceTime: 3600,
          phone: {
            areaCode: '+48',
            number: '155215521',
          },
          businessHours: {
            saturday: null,
            weekday: { from: '10:00:00', to: '15:00:00' },
            sunday: null,
          },
          type: OrderPointType.GAS_STATION,
          includeInOrder: true,
          name: '123',
        },
        {
          estimations: {
            arrivalTime: '2025-01-16T10:30:00',
            departureTime: '2025-01-16T11:00:00',
            deadlineTime: '2024-11-27T08:00:00',
          },
          timestamps: {
            arrivedAt: '2024-11-27T08:06:00',
            canceledAt: '2024-11-27T08:06:00',
            completedAt: '2024-11-27T08:06:00',
            startedAt: '2024-11-27T08:06:00',
          },
          route: {
            departure: {
              latitude: 0,
              longitude: 0,
            },
            legs: [],
            summary: {
              distance: 1000,
              duration: 6000,
              countries: ['pl'],
              adBlueConsumption: 0,
              adBlueCost: null,
              fuelConsumption: 0,
              fuelCost: null,
              totalFuelCost: null,
              tollsCost: {
                currency: 'pln',
                value: 5000,
              } as OnyxAmount,
              tolls: [],
              totalCost: {
                currency: 'pln',
                value: 5000,
              } as OnyxAmount,
            },
            remainingDistance: 900,
            remainingDuration: 5000,
          },
          status: OrderPointStatus.WAITING,

          uuid: '123',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.POINT_OF_INTEREST,
            poiName: '123',
            poiUuid: '123',
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointType.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: 3600,
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [
              { date: '2025-02-24', time: '13:18:00', timeRange: null },
            ],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
                loadingMeters: null,
                volume: null,
              },
              statisticalNumber: '15',
              totalWeight: 15500,
              type: null,
              typeName: 'Nasiona',
              unit: {
                uuid: '67ecb815-e96a-41b9-9bff-05b2e90e47d0',
                names: {
                  de: 'Europalette',
                  en: 'Euro pallet',
                  es: 'Palet europeo',
                  fr: 'Europalette',
                  it: 'Pallet europeo',
                  pl: 'Europaleta',
                },
                hasRelatedObjects: false,
                relatedObjects: [],
                size: {
                  type: GoodsSizeType.DIMENSIONS,
                  loadingMeters: null,
                  length: 120,
                  width: 80,
                  height: 200,
                  volume: null,
                },
                isDefault: true,
              },
              unitedNationsNumber: '125',
            },
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
                loadingMeters: null,
                volume: null,
              },
              statisticalNumber: '15',
              totalWeight: 13500,
              type: null,
              typeName: 'Zboże',
              unit: {
                uuid: '67ecb815-e96a-41b9-9bff-05b2e90e47d0',
                names: {
                  de: 'Europalette',
                  en: 'Euro pallet',
                  es: 'Palet europeo',
                  fr: 'Europalette',
                  it: 'Pallet europeo',
                  pl: 'Europaleta',
                },
                hasRelatedObjects: false,
                relatedObjects: [],
                size: {
                  type: GoodsSizeType.DIMENSIONS,
                  loadingMeters: null,
                  length: 120,
                  width: 80,
                  height: 200,
                  volume: null,
                },
                isDefault: true,
              },
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          estimations: {
            arrivalTime: '2024-11-27T08:06:00',
            departureTime: '2024-11-27T08:06:00',
            deadlineTime: '2024-11-27T08:00:00',
          },
          timestamps: {
            arrivedAt: '2024-11-27T08:06:00',
            canceledAt: '2024-11-27T08:06:00',
            completedAt: '2024-11-27T08:06:00',
            startedAt: '2024-11-27T08:06:00',
          },
          route: {
            departure: {
              latitude: 0,
              longitude: 0,
            },
            legs: [],
            summary: {
              distance: 1000,
              duration: 6000,
              countries: ['pl'],
              adBlueConsumption: 0,
              adBlueCost: null,
              fuelConsumption: 0,
              fuelCost: null,
              totalFuelCost: null,
              tollsCost: {
                currency: 'pln',
                value: 5000,
              } as OnyxAmount,
              tolls: [],
              totalCost: {
                currency: 'pln',
                value: 5000,
              } as OnyxAmount,
            },
            remainingDistance: 900,
            remainingDuration: 5000,
          },
          status: 4 as never,

          uuid: '456',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.POINT_OF_INTEREST,
            poiName: '123',
            poiUuid: '123',
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointType.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: 3600,
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [
              { date: '2024-05-16', time: '15:00:00', timeRange: null },
            ],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
                loadingMeters: null,
                volume: null,
              },
              statisticalNumber: '15',
              totalWeight: 13500,
              type: null,
              typeName: 'Nasiona',
              unit: {
                uuid: '67ecb815-e96a-41b9-9bff-05b2e90e47d0',
                names: {
                  de: 'Europalette',
                  en: 'Euro pallet',
                  es: 'Palet europeo',
                  fr: 'Europalette',
                  it: 'Pallet europeo',
                  pl: 'Europaleta',
                },
                hasRelatedObjects: false,
                relatedObjects: [],
                size: {
                  type: GoodsSizeType.DIMENSIONS,
                  loadingMeters: null,
                  length: 120,
                  width: 80,
                  height: 200,
                  volume: null,
                },
                isDefault: true,
              },
              unitedNationsNumber: '125',
            },
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
                loadingMeters: null,
                volume: null,
              },
              statisticalNumber: '15',
              totalWeight: 1200,
              type: null,
              typeName: 'Amunicja',
              unit: {
                uuid: '67ecb815-e96a-41b9-9bff-05b2e90e47d0',
                names: {
                  de: 'Europalette',
                  en: 'Euro pallet',
                  es: 'Palet europeo',
                  fr: 'Europalette',
                  it: 'Pallet europeo',
                  pl: 'Europaleta',
                },
                hasRelatedObjects: false,
                relatedObjects: [],
                size: {
                  type: GoodsSizeType.DIMENSIONS,
                  loadingMeters: null,
                  length: 120,
                  width: 80,
                  height: 200,
                  volume: null,
                },
                isDefault: true,
              },
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          estimations: {
            arrivalTime: '2025-01-20T08:06:00',
            departureTime: '2025-01-20T08:06:00',
            deadlineTime: '2025-01-28T06:30:00',
          },
          timestamps: {
            arrivedAt: '2024-11-27T12:06:00',
            canceledAt: '2024-11-27T012:06:00',
            completedAt: '2025-01-27T08:06:00',
            startedAt: '2024-11-27T08:06:00',
          },
          route: {
            departure: {
              latitude: 0,
              longitude: 0,
            },
            legs: [],
            summary: {
              distance: 1000,
              duration: 6000,
              countries: ['pl'],
              adBlueConsumption: 0,
              adBlueCost: null,
              fuelConsumption: 0,
              fuelCost: null,
              totalFuelCost: null,
              tollsCost: {
                currency: 'pln',
                value: 5000,
              } as OnyxAmount,
              tolls: [],
              totalCost: {
                currency: 'pln',
                value: 5000,
              } as OnyxAmount,
            },
            remainingDistance: 900,
            remainingDuration: 5000,
          },
          status: OrderPointStatus.COMPLETED,

          uuid: '1245156666',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.POINT_OF_INTEREST,
            poiUuid: '123',
            poiName: '123',
            zipCode: '82-500',
          },
          category: OrderPointCategory.UNLOADING,
          type: OrderPointType.UNLOADING,
          goods: [
            {
              uuid: '00012',
              quantity: 15,
            },
          ],
          referenceNumber: '15',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [
              { date: '2025-01-22', time: '15:00:00', timeRange: null },
              { date: '2025-01-23', time: '15:00:00', timeRange: null },
            ],
          },
          driverNote: 'uważaj na dziki',
          serviceTime: 3600,
        },
      ],
    },
  ],
  limit: 10,
  page: 1,
  totalItems: 1,
};
