<onyx-section heading="labels.note">
  <h6 heading class="optional">
    {{ 'labels.optional' | translate }}
  </h6>

  <form>
    <onyx-textarea
      [formControl]="form().controls.content"
      [placeholder]="placeholder()"
    ></onyx-textarea>
  </form>
</onyx-section>
