import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  Inject,
  linkedSignal,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  ActionHelper,
  OnyxChip,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxErrorComponent,
  OnyxIconBoxColor,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxSpinnerComponent,
  OnyxToastService,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { isString } from 'lodash';
import { catchError, EMPTY, of, Subject, switchMap, tap } from 'rxjs';
import { environment } from '../../../../config/environment';
import { ModalNoteComponent } from '../../../common/components/modals/modal-note/modal-note.component';
import { DictionaryCode } from '../../../common/enums/dictionary-code';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../common/helpers/validation.helper';
import { FleetIdentifierPipe } from '../../../common/pipes/fleet-identifier.pipe';
import { I18nPipe } from '../../../common/pipes/i18n.pipe';
import { FleetRouterStateKey } from '../common/enums/fleet-router-state-key';
import { FleetHelper } from '../common/helpers/fleet.helper';
import { Fleet, SimplifiedFleet } from '../common/interfaces/fleet';
import { FleetService } from '../common/services/fleet.service';
import { FleetRoute } from '../fleet.routes';
import { FleetModalAssignedVehicleComponent } from './fleet-modal-assigned-vehicle/fleet-modal-assigned-vehicle.component';
import { FleetModalCrewComponent } from './fleet-modal-crew/fleet-modal-crew.component';
import { FleetModalDetailsComponent } from './fleet-modal-details/fleet-modal-details.component';
import { FleetModalDocumentsComponent } from './fleet-modal-documents/fleet-modal-documents.component';
import { FleetModalMainSectionComponent } from './fleet-modal-main-section/fleet-modal-main-section.component';
import { FleetModalVehicleHeadingComponent } from './fleet-modal-vehicle-heading/fleet-modal-vehicle-heading.component';
import { FleetModalWarningsComponent } from './fleet-modal-warnings/fleet-modal-warnings.component';

export type FleetModalData = Fleet | SimplifiedFleet | string;

enum FleetModalSection {
  // TEMP: N/A
  // ORDER = 'order',
  // DELEGATION = 'delegation',
  PROFITABILITY = 'profitability',
  CREW = 'crew',
  DOCUMENTS = 'documents',
  DETAILS = 'details',
  NOTE = 'note',
}

@Component({
  selector: 'app-fleet-modal',
  imports: [
    OnyxModalComponent,
    FleetIdentifierPipe,
    OnyxIconComponent,
    OnyxChipsComponent,
    FleetModalMainSectionComponent,
    FleetModalAssignedVehicleComponent,
    FleetModalVehicleHeadingComponent,
    FleetModalDocumentsComponent,
    FleetModalWarningsComponent,
    FleetModalCrewComponent,
    FleetModalDetailsComponent,
    OnyxSpinnerComponent,
    OnyxIconButtonComponent,
    OnyxDropdownDirective,
    ModalNoteComponent,
    OnyxErrorComponent,
    OnyxTooltipDirective,
    I18nPipe,
  ],
  templateUrl: './fleet-modal.component.html',
  styleUrl: './fleet-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalComponent {
  protected readonly I18N = 'fleet.fleetModal';

  protected readonly OnyxIconBoxColor = OnyxIconBoxColor;
  protected readonly FleetModalSection = FleetModalSection;
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly FleetRoute = FleetRoute;
  protected readonly FleetRouterStateKey = FleetRouterStateKey;
  protected readonly FleetHelper = FleetHelper;

  private readonly documentsElement = viewChild.required(
    FleetModalDocumentsComponent,
    { read: ElementRef<any> },
  );
  private readonly uuid: string;

  protected vehicle = signal<Fleet | null>(null);
  protected assignedVehicle = signal<Fleet | null>(null);
  protected group = linkedSignal<
    OnyxChip<FleetModalSection>[],
    [FleetModalSection]
  >({
    source: () => this.chips(),
    computation: (chips, previous) => [
      chips.find(({ value }) => value === previous?.value?.at(0))?.value ??
        chips[0].value,
    ],
  });
  protected globalLoading = signal(false);
  protected globalError = signal(false);
  protected warnings = signal<boolean | null>(null);
  protected loading = signal(false);
  protected error = signal(false);
  protected close$ = new Subject<void>();
  protected vehicle$ = new Subject<void>();
  protected assignedVehicle$ = new Subject<void>();

  protected isArchived = computed(() => {
    const vehicle = this.vehicle();
    return vehicle ? FleetHelper.isArchived(vehicle) : null;
  });
  protected isVehicle = computed(() => {
    const vehicle = this.vehicle();
    return vehicle ? FleetHelper.isVehicle(vehicle) : null;
  });
  protected options = computed(() => {
    const fleet = this.vehicle();
    return fleet
      ? this.fleetHelper.getOptions(fleet, { close$: this.close$ })
      : null;
  });
  protected chips = computed<OnyxChip<FleetModalSection>[]>(() => [
    // TEMP: N/A
    // { name: 'labels.order', value: FleetModalSection.ORDER },
    // { name: 'labels.delegation', value: FleetModalSection.DELEGATION },
    ...(this.isVehicle() && !this.isArchived()
      ? [
          ...(['sandbox', 'dev'].includes(environment.environment)
            ? [
                {
                  name: 'labels.profitability',
                  value: FleetModalSection.PROFITABILITY,
                },
              ]
            : []),
          {
            name: `${this.I18N}.crew`,
            value: FleetModalSection.CREW,
          },
        ]
      : []),
    { name: 'labels.documents', value: FleetModalSection.DOCUMENTS },
    { name: `${this.I18N}.details`, value: FleetModalSection.DETAILS },
    {
      name: 'labels.note',
      value: FleetModalSection.NOTE,
    },
  ]);
  protected semiTrailerHeaderConfig = computed(() => {
    const semiTrailerSize =
      this.vehicle()?.additionalParameters?.semiTrailerSize;
    if (!semiTrailerSize) return null;

    return {
      icon: `semi-trailer-${semiTrailerSize}` as const,
      size: semiTrailerSize,
    };
  });

  constructor(
    @Inject(DIALOG_DATA) protected vehicleData: FleetModalData,
    protected dialogRef: DialogRef<void>,
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private destroyRef: DestroyRef,
    private fleetHelper: FleetHelper,
    private router: Router,
  ) {
    this.uuid = isString(this.vehicleData)
      ? this.vehicleData
      : this.vehicleData.uuid;

    this.vehicle$
      .pipe(
        tap(() => this.globalLoading.set(true)),
        switchMap(() =>
          this.fleetService.getFleet(this.uuid).pipe(
            catchError((response) => {
              this.vehicle.set(null);
              this.globalLoading.set(false);
              this.globalError.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (vehicle) => {
          this.vehicle.set(vehicle);
          this.assignedVehicle$.next();
          this.globalLoading.set(false);
          this.globalError.set(false);
        },
      });

    this.assignedVehicle$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() => {
          const { trailer, vehicle } = this.vehicle() ?? {};
          const assignedVehicle = trailer?.uuid ?? vehicle?.uuid;
          if (!assignedVehicle) return of(null);

          return this.fleetService.getFleet(assignedVehicle).pipe(
            catchError((response) => {
              this.assignedVehicle.set(null);
              this.loading.set(false);
              this.error.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (vehicle) => {
          this.assignedVehicle.set(vehicle);
          this.loading.set(false);
          this.error.set(false);
        },
      });

    if (
      isString(this.vehicleData) ||
      !FleetHelper.isFleetType(this.vehicleData)
    ) {
      this.vehicle$.next();
    } else {
      this.vehicle.set(this.vehicleData);
      this.assignedVehicle$.next();
    }

    this.fleetService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.vehicle$.next());
  }

  protected edit(): void {
    this.fleetHelper.edit(this.uuid, { close$: this.close$ });
  }

  protected share(): void {
    const vehicle = this.vehicle();
    if (!vehicle) return;

    ActionHelper.share(
      FleetRoute.FLEET_CARD.replace(':uuid', vehicle.uuid),
      this.toastService,
    );
  }

  protected showOnMap(): void {
    const vehicle = this.vehicle();
    if (!vehicle || FleetHelper.hasTelematics(vehicle)) return;

    CommonHelper.showOnMap(this.router, vehicle.uuid);
    this.close$.next();
  }

  protected showOnGantt(): void {
    const vehicle = this.vehicle();
    if (!vehicle || !FleetHelper.hasTelematics(vehicle)) return;

    CommonHelper.showOnGantt(this.router, 'vehicle', vehicle.uuid);
    this.close$.next();
  }

  protected redirectToDocument(): void {
    this.group.set([FleetModalSection.DOCUMENTS]);

    setTimeout(() =>
      this.documentsElement().nativeElement.scrollIntoView({
        behavior: 'smooth',
      }),
    );
  }
}
