<form [formGroup]="form()">
  @let controls = form().controls;
  <onyx-form-group [controls]="controls.quantity" [gap]="8" errorsAlign="right">
    <div class="details">
      <p>
        @if (good().unit) {
          {{ (unitName$ | async) ?? '&nbsp;' }}
        } @else {
          {{ I18N + '.emptyUnit' | translate }}
        }
      </p>

      <p class="available">
        {{ I18N + '.availableQuantity' | translate }}
        {{ Math.max(availableQuantity(), 0) | number }}
      </p>
    </div>

    <onyx-text-field
      [formControl]="controls.quantity"
      placeholder="labels.quantity"
      [decimalPlaces]="0"
      [showClear]="false"
      size="s"
      [showErrors]="false"
    ></onyx-text-field>
  </onyx-form-group>

  <onyx-text-field
    [value]="totalWeight() ? (totalWeight() | number: '1.0-0') : '-'"
    [disabled]="true"
    unit="kg"
    size="s"
  ></onyx-text-field>
</form>
