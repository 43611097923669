@if (label() === true) {
  <div class="label-placeholder"></div>
} @else if (label()) {
  <onyx-input-label
    [label]="$any(label())"
    [hint]="hint()"
    [optional]="isOptional()"
    [disabled]="disabled()"
    (click)="focus()"
  ></onyx-input-label>
}

<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div
  class="input"
  [ngClass]="{
    active: value().hour != null || value().minutes != null,
    invalid: invalid(),
    disabled: disabled(),
  }"
  (click)="focusInputs($event)"
>
  <input
    [placeholder]="isTime() ? '00' : 'hh'"
    [disabled]="disabled()"
    (keypress)="validateInput($event, isTime() ? 23 : 99)"
    (input)="changeHour($event)"
    (paste)="pasteTime($event)"
    #inputElement
  />

  <div class="separator">:</div>

  <input
    [placeholder]="isTime() ? '00' : 'mm'"
    [disabled]="disabled()"
    [tabIndex]="value().hour != null ? 0 : -1"
    (keypress)="validateInput($event, 59)"
    (input)="changeMinutes($event)"
    (paste)="pasteTime($event)"
    (keydown.backspace)="value().minutes == null ? focusHoursInput() : null"
    (focus)="value().hour == null ? focusHoursInput() : null"
    #inputElement
  />

  <div class="actions">
    <div class="hidden">
      @if ((value().hour != null || value().minutes != null) && !disabled()) {
        <onyx-clear-button
          class="clear"
          (click)="clearValue(); $event.stopPropagation()"
        ></onyx-clear-button>
      }
    </div>
  </div>

  @if (showClockIcon()) {
    <onyx-icon
      class="clock"
      [name]="isTime() ? 'clock' : 'hourglass-top'"
      [size]="16"
    ></onyx-icon>
  }
</div>
