import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  viewChild,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxDataWidgetComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxNamePipe,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { PdfHelper } from '../../../../../../common/helpers/pdf.helper';
import { FleetIdentifierPipe } from '../../../../../../common/pipes/fleet-identifier.pipe';
import { ContractorHelper } from '../../../../../management-panel/contractors/common/helpers/contractor.helper';
import { OrderOutsourcingDriverComponent } from '../../../../common/components/order-outsourcing-driver/order-outsourcing-driver.component';
import { Order } from '../../../../common/interfaces/order';
import { OrderPdfTemplateComponent } from '../../../order-modal-main-section/outsourcing/order-pdf-template/order-pdf-template.component';

@Component({
  selector: 'app-order-modal-outsourcing-data',
  imports: [
    OnyxDataWidgetComponent,
    OnyxInformationHeadingComponent,
    OnyxIconComponent,
    TranslatePipe,
    OnyxNamePipe,
    FleetIdentifierPipe,
    OnyxIconButtonComponent,
    OnyxTooltipDirective,
    OnyxIconComponent,
    OrderOutsourcingDriverComponent,
    OrderPdfTemplateComponent,
  ],
  templateUrl: './order-modal-outsourcing-data.component.html',
  styleUrl: './order-modal-outsourcing-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalOutsourcingDataComponent {
  protected readonly ActionHelper = ActionHelper;

  public order = input.required<Order>();

  private pdfContent =
    viewChild.required<ElementRef<HTMLElement>>('pdfContent');

  constructor(private contractorHelper: ContractorHelper) {}

  protected openContractorModal(): void {
    const contractor = this.order().outsourcingData?.contractor;
    if (contractor) this.contractorHelper.openModal(contractor);
  }

  protected createPdf(): void {
    PdfHelper.createPdf(
      this.pdfContent().nativeElement,
      `${this.order().identifier}.pdf`,
    );
  }
}
