@let controls = form().controls;
<form [class.compact]="compact()" [formGroup]="form()">
  <div class="address">
    <onyx-icon-box class="bar-icon location" color="white" [hasShadow]="true">
      <onyx-icon name="location" [size]="16"></onyx-icon>
    </onyx-icon-box>

    <onyx-address-input
      [formControl]="controls.address"
      label="labels.address"
      [showApartment]="true"
      apartmentWidth="calc({{ !compact() ? '2/9' : '1/3' }} * 100% - 12px)"
      [gap]="16"
      (nameChange)="setName($event)"
    ></onyx-address-input>
  </div>

  <onyx-text-field
    class="name"
    [formControl]="controls.name"
    label="labels.name"
  ></onyx-text-field>

  <onyx-timepicker
    class="service-time"
    [formControl]="controls.serviceTime"
    [label]="serviceTimeLabel()"
    type="duration"
  ></onyx-timepicker>

  @if (!compact()) {
    <onyx-text-field
      class="driver-note"
      [formControl]="controls.driverNote"
      label="labels.driverNote"
    ></onyx-text-field>
  } @else {
    <onyx-textarea
      class="driver-note"
      [formControl]="controls.driverNote"
      label="labels.driverNote"
      minResizeHeight="80px"
    ></onyx-textarea>
  }

  <onyx-phone-number-input
    class="phone"
    [formControl]="controls.phone"
    label="labels.phone"
  ></onyx-phone-number-input>

  <ng-container [formGroup]="controls.businessHours">
    @let businessHoursControls = controls.businessHours.controls;
    <div class="opening-hours">
      <onyx-icon-box class="bar-icon clock" color="white" [hasShadow]="true">
        <onyx-icon name="clock" [size]="16"></onyx-icon>
      </onyx-icon-box>

      <onyx-input-label
        [label]="'labels.openingHours' | translate"
      ></onyx-input-label>

      <onyx-toggle [(value)]="isAlwaysOpen" label="24/7"></onyx-toggle>
    </div>

    @if (!isAlwaysOpen()) {
      <onyx-form-group
        class="opening-day"
        label="{{ 'labels.monday' | translate }} - {{
          'labels.friday' | translate
        }}"
        [controls]="businessHoursControls.weekday"
      >
        <onyx-timepicker
          [formControl]="businessHoursControls.weekday"
          [showTimeRange]="true"
          [showClockIcon]="false"
          [showErrors]="false"
        ></onyx-timepicker>

        <onyx-suggestions
          [control]="businessHoursControls.weekday"
          [suggestions]="TIME_RANGE_SUGGESTIONS"
        ></onyx-suggestions>
      </onyx-form-group>

      <div class="opening-day">
        <onyx-form-group label="labels.saturday">
          <onyx-timepicker
            [formControl]="businessHoursControls.saturday"
            [showTimeRange]="true"
            [showClockIcon]="false"
          ></onyx-timepicker>
        </onyx-form-group>

        <onyx-checkbox
          label="labels.closed"
          [(value)]="isSaturdayClosed"
          [showOptional]="false"
        ></onyx-checkbox>
      </div>

      <div class="opening-day">
        <onyx-form-group label="labels.sunday">
          <onyx-timepicker
            [formControl]="businessHoursControls.sunday"
            [showTimeRange]="true"
            [showClockIcon]="false"
          ></onyx-timepicker>
        </onyx-form-group>

        <onyx-checkbox
          label="labels.closed"
          [(value)]="isSundayClosed"
          [showOptional]="false"
        ></onyx-checkbox>
      </div>
    }
  </ng-container>
</form>
