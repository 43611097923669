import { Pipe, PipeTransform } from '@angular/core';
import { OnyxMaybeArray } from '@onyx/angular';
import { castArray } from 'lodash';

@Pipe({
  name: 'i18n',
  pure: true,
})
export class I18nPipe implements PipeTransform {
  public transform(value: string, key: OnyxMaybeArray<string>): string {
    return `${castArray(key).join('.')}.${value}`;
  }
}
