import { ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import {
  ElementRef,
  Injectable,
  reflectComponentType,
  ViewContainerRef,
} from '@angular/core';
import {
  BehaviorSubject,
  delay,
  filter,
  fromEvent,
  takeUntil,
  timer,
} from 'rxjs';
import {
  OnyxTooltipContext,
  OnyxTooltipTemplateContext,
} from '../../components';
import { OnyxOverlayPosition } from '../../enums';
import {
  ONYX_TOOLTIP_CONFIG,
  OnyxTooltipColor,
  OnyxTooltipConfig,
  OnyxTooltipOverlayComponent,
} from '../components/onyx-tooltip-overlay/onyx-tooltip-overlay.component';
import { OnyxOverlayService } from './onyx-overlay.service';

interface OnyxTooltipArrowOffsets {
  x?: number;
  y?: number;
  calculateY?: boolean;
  calculateX?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OnyxTooltipService {
  private readonly VIEWPORT_MARGIN = 8;

  private readonly overlayService: OnyxOverlayService;

  private arrowPosition$ = new BehaviorSubject({ x: 0, y: 0 });

  constructor(private overlay: Overlay) {
    this.overlayService = new OnyxOverlayService(this.overlay);
  }

  public attachTooltip(
    sourceRef: ElementRef,
    viewContainerRef: ViewContainerRef,
    context: OnyxTooltipContext,
    showArrow = true,
    textAlign: OnyxTooltipConfig['textAlign'] = 'left',
    arrowOffsets: {
      x?: number;
      y?: number;
      calculateX?: boolean;
      calculateY?: boolean;
    } = { x: 0, y: 0 },
    positions: OnyxOverlayPosition[] = [],
    targetElement: HTMLElement | undefined = undefined,
    delay = 0,
    overridePositions: Partial<ConnectedPosition> = {},
    color: OnyxTooltipColor = 'black',
    params: OnyxTooltipTemplateContext = null,
  ): void {
    this.detachTooltip();
    this.detachOnMouseLeave(sourceRef);

    timer(delay || 1)
      .pipe(takeUntil(this.overlayService.detach$))
      .subscribe(() => {
        this.createOverlayWithPositions(
          sourceRef,
          positions,
          targetElement,
          overridePositions,
        );
        this.createTooltipAndAttachToOverlay(
          viewContainerRef,
          context,
          params,
          color,
          showArrow,
          textAlign,
        );

        if (!showArrow) return;
        setTimeout(() =>
          this.updateArrowPosition(
            sourceRef,
            this.overlayService.overlayElementRect!,
            arrowOffsets,
            color,
          ),
        );
      });
  }

  public detachTooltip(): void {
    this.overlayService.detachFromOverlay();
  }

  private createOverlayWithPositions(
    sourceRef: ElementRef,
    positions: OnyxOverlayPosition[] = [],
    targetElement?: HTMLElement,
    overridePositions: Partial<ConnectedPosition> = {},
  ): void {
    const offsetY = targetElement
      ? sourceRef.nativeElement.getBoundingClientRect().top -
        targetElement.getBoundingClientRect().top
      : 0;
    const overlayPositions = this.getOverlayPositions(positions, offsetY).map(
      (position) => ({
        ...position,
        ...overridePositions,
        panelClass: [
          ...(position.panelClass ?? []),
          ...(overridePositions.panelClass ?? []),
        ],
      }),
    );
    const positionStrategy = this.overlayService.getDefaultPositionStrategy(
      targetElement ?? sourceRef.nativeElement,
      overlayPositions,
      this.VIEWPORT_MARGIN,
    );
    this.overlayService.createOverlay(sourceRef, {
      positionStrategy,
      scrollStrategy: this.overlayService.scrollStrategies.close(),
      hasBackdrop: false,
    });
  }

  private createTooltipAndAttachToOverlay(
    viewContainerRef: ViewContainerRef,
    context: OnyxTooltipContext,
    params: OnyxTooltipTemplateContext,
    color: OnyxTooltipColor,
    showArrow: boolean,
    textAlign: OnyxTooltipConfig['textAlign'],
  ): void {
    const tooltipInjector = this.overlayService.createComponentInjector(
      ONYX_TOOLTIP_CONFIG,
      {
        color,
        context,
        templateContext: params,
        showArrow,
        textAlign: textAlign,
        arrowPosition$: this.arrowPosition$.asObservable(),
        close: () => this.detachTooltip(),
      },
    );
    const tooltipPortal = this.overlayService.createComponentPortal(
      OnyxTooltipOverlayComponent,
      tooltipInjector,
      viewContainerRef,
    );
    this.overlayService.attachToOverlay(tooltipPortal);
    this.overlayService.componentRef()?.onDestroy(() => {
      this.detachTooltip();
    });
  }

  private getOverlayPositions(
    positions: OnyxOverlayPosition[] = [],
    offsetY = 0,
  ): ConnectedPosition[] {
    if (!positions?.length) {
      positions = [
        OnyxOverlayPosition.TOP,
        OnyxOverlayPosition.BOTTOM,
        OnyxOverlayPosition.RIGHT,
        OnyxOverlayPosition.LEFT,
      ];
    }

    return positions.map(
      (position) =>
        ({
          [OnyxOverlayPosition.TOP]: OnyxOverlayService.positionStrategyTop,
          [OnyxOverlayPosition.RIGHT]: {
            ...OnyxOverlayService.positionStrategyRight,
            originY: 'top',
            overlayY: 'top',
            offsetY,
          } satisfies ConnectedPosition,
          [OnyxOverlayPosition.BOTTOM]:
            OnyxOverlayService.positionStrategyBottom,
          [OnyxOverlayPosition.LEFT]: {
            ...OnyxOverlayService.positionStrategyLeft,
            originY: 'top',
            overlayY: 'top',
            offsetY,
          } satisfies ConnectedPosition,
        })[position],
    );
  }

  private updateArrowPosition(
    elementRef: ElementRef,
    overlayElementRect: DOMRect,
    arrowOffsets: OnyxTooltipArrowOffsets,
    color: OnyxTooltipColor,
  ): void {
    const arrowPosition = this.getArrowPosition(
      elementRef,
      overlayElementRect,
      arrowOffsets.x,
      arrowOffsets.y,
      arrowOffsets.calculateX,
      arrowOffsets.calculateY,
      color,
    );
    this.arrowPosition$.next(arrowPosition);
  }

  private getArrowPosition(
    elementRef: ElementRef,
    overlayElementRect: DOMRect,
    offsetX = 0,
    offsetY = 0,
    calculateX = true,
    calculateY = true,
    color: OnyxTooltipColor,
  ): { x: number; y: number } {
    const arrowSize = color === 'white' ? 12 : 8;

    const elementRect = elementRef.nativeElement.getBoundingClientRect();
    const x = calculateX
      ? elementRect.left -
        overlayElementRect.left +
        elementRect.width / 2 -
        offsetX -
        arrowSize / 2
      : -4;
    const y = calculateY
      ? overlayElementRect.height / 2 -
        (elementRect.top - overlayElementRect.top) / 2 +
        offsetY
      : 0;

    return { x, y };
  }

  private detachOnMouseLeave(elementRef: ElementRef): void {
    fromEvent(document, 'mousemove')
      .pipe(
        filter((event) => event instanceof MouseEvent),
        delay(100),
        takeUntil(this.overlayService.detach$),
      )
      .subscribe((event) => {
        const tooltipOverlaySelector = reflectComponentType(
          OnyxTooltipOverlayComponent,
        )!.selector;
        const ancestors = [
          elementRef.nativeElement,
          ...Array.from(document.querySelectorAll(tooltipOverlaySelector)).map(
            (element) => element.parentElement ?? element,
          ),
        ];

        const target = event.target as HTMLElement;
        for (const ancestor of ancestors) {
          if (ancestor.contains(target)) return;
        }

        this.detachTooltip();
      });
  }
}
