<onyx-information-heading>
  {{ 'addInvoice' | i18n: I18N | translate }}
</onyx-information-heading>

<div class="container">
  <form [formGroup]="form">
    @let controls = form.controls;
    <div class="controls">
      <onyx-text-field
        [formControl]="controls.number"
        label="labels.invoiceNumber"
      ></onyx-text-field>

      <onyx-form-group [controls]="controls.sellDate">
        <onyx-datepicker
          [formControl]="controls.sellDate"
          label="labels.sellDate"
          [showErrors]="false"
        ></onyx-datepicker>

        <onyx-suggestions
          [control]="controls.sellDate"
          [suggestions]="sellDateSuggestions()"
        ></onyx-suggestions>
      </onyx-form-group>

      <onyx-datepicker
        [formControl]="controls.paymentTerm"
        label="labels.paymentTerm"
      ></onyx-datepicker>

      <onyx-upload
        [formControl]="controls.scan"
        label="labels.scan"
      ></onyx-upload>
    </div>

    <div class="price">
      <p class="f-medium-2">{{ 'priceToPay' | i18n: I18N | translate }}</p>

      <onyx-datepicker
        [formControl]="controls.nbpRateDay"
        label="labels.nbpRateDay"
      ></onyx-datepicker>

      @let netGroup = controls.net;
      @let netControls = netGroup.controls;
      <onyx-form-group
        [formGroup]="netGroup"
        [controls]="[netControls.value, netControls.currency]"
        label="labels.net"
        [gap]="16"
      >
        <onyx-text-field
          [formControl]="netControls.value"
          [decimalPlaces]="0"
          size="s"
          [showErrors]="false"
          width="60%"
        ></onyx-text-field>

        <onyx-dropdown
          [formControl]="netControls.currency"
          [options]="currencies$ | async"
          size="s"
          [showErrors]="false"
          width="40%"
        ></onyx-dropdown>
      </onyx-form-group>

      @let vatGroup = controls.vat;
      @let vatControls = vatGroup.controls;
      <onyx-form-group
        [formGroup]="vatGroup"
        [controls]="[vatControls.value, vatControls.currency]"
        label="labels.vat"
        [gap]="16"
      >
        <onyx-text-field
          [formControl]="vatControls.value"
          [decimalPlaces]="0"
          size="s"
          [showErrors]="false"
          width="60%"
        ></onyx-text-field>

        <onyx-dropdown
          [formControl]="vatControls.currency"
          [options]="currencies$ | async"
          size="s"
          [showErrors]="false"
          width="40%"
        ></onyx-dropdown>
      </onyx-form-group>

      <div class="divider"></div>

      <div class="gross">
        <p>{{ 'labels.gross' | translate }}</p>

        <div class="prices">
          @for (result of grossResult(); track result.currency) {
            <p>{{ result.value }} {{ result.currency | uppercase }}</p>
          } @empty {
            <p class="f-gray">
              {{ 'enterAllFields' | i18n: I18N | translate }}
            </p>
          }
        </div>
      </div>
    </div>
  </form>

  <div class="buttons">
    <onyx-button color="red" (click)="hideFormChange.emit()">
      {{ 'labels.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="green"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'labels.save' | translate }}
    </onyx-button>
  </div>
</div>
