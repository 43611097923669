import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Injector,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxFormMode,
  OnyxToastService,
} from '@onyx/angular';
import { isEqual, pick } from 'lodash';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  skip,
  switchMap,
} from 'rxjs';
import { MergeDeep, PartialDeep } from 'type-fest';
import { AuthService } from '../../../auth/common/services/auth.service';
import { DocumentsFormComponent } from '../../../common/components/forms/documents-form/documents-form.component';
import { NoteFormComponent } from '../../../common/components/forms/note-form/note-form.component';
import { ParametersFormComponent } from '../../../common/components/forms/parameters-form/parameters-form.component';
import { RouteConfig } from '../../../common/interfaces/address/calculate-route-request';
import { CacheService } from '../../../common/services/cache.service';
import { PreferencesService } from '../../../common/services/preferences.service';
import { TopBarButton } from '../../common/interfaces/top-bar-button';
import { DashboardService } from '../../common/services/dashboard.service';
import { FleetCategory } from '../../fleet/common/enums/fleet-category';
import { OrdersQueryParamKey } from '../common/enums/orders-query-param-key';
import { OrdersStorageKey } from '../common/enums/orders-storage-key';
import { OrderHelper } from '../common/helpers/order.helper';
import { OrderRoute } from '../common/interfaces/order';
import { OrderFormService } from '../common/services/order-form.service';
import { OrdersRoute } from '../orders.routes';
import { OrderBasicInformationFormComponent } from './order-basic-information-form/order-basic-information-form.component';
import { OrderFormRouteComponent } from './order-form-route/order-form-route.component';
import { OrderLoadingPointFormComponent } from './order-points-form/order-loading-point-form/order-loading-point-form.component';
import { OrderPointsFormComponent } from './order-points-form/order-points-form.component';

export type OrderFormGroup = ReturnType<typeof OrderFormComponent.buildForm>;
export type OrderForm = ReturnType<OrderFormGroup['getRawValue']>;

export type OrderFormDto = MergeDeep<
  OrderForm,
  {
    basicInformation: {
      loadedSemiTrailer: undefined;
      loadedSemiTrailerUuid: string | null;
      loadedSemiTrailerRegistrationNumber: string | null;
    };
  }
>;

@Component({
  selector: 'app-order-form',
  imports: [
    OrderBasicInformationFormComponent,
    ParametersFormComponent,
    DocumentsFormComponent,
    NoteFormComponent,
    OrderPointsFormComponent,
    TranslatePipe,
    OnyxButtonComponent,
    OrderFormRouteComponent,
  ],
  providers: [OrderFormService],
  templateUrl: './order-form.component.html',
  styleUrl: './order-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormComponent {
  protected readonly I18N = 'orders.orderForm';

  private readonly parametersFormTemplateRef = viewChild.required(
    ParametersFormComponent,
  );
  private readonly restorePointToastTemplateRef = viewChild.required<
    TemplateRef<unknown>
  >('restorePointToastTemplate');

  protected form = this.orderFormService.form;
  protected showRoutePreview = this.orderFormService.showRoutePreview;
  protected validation = this.orderFormService.validation;

  private mode = this.orderFormService.mode;
  private order = this.orderFormService.order;

  private hasRouteEdit = computed(() => {
    const order = this.order();
    return order ? this.orderHelper.hasRouteEdit(order) : true;
  });

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private destroyRef: DestroyRef,
    private cacheService: CacheService,
    private dashboardService: DashboardService,
    private injector: Injector,
    private toastService: OnyxToastService,
    private translateService: TranslateService,
    private orderFormService: OrderFormService,
    private orderHelper: OrderHelper,
  ) {
    const initialState = this.reset();

    if (this.mode() === OnyxFormMode.ADD) {
      this.orderFormService.valueChanges$.subscribe((value) =>
        this.cacheService.set(OrdersStorageKey.ADD_ORDER_FORM, value),
      );
    }

    if (
      this.route.snapshot.queryParamMap.get(OrdersQueryParamKey.PLAN_ROUTE) &&
      this.hasRouteEdit()
    ) {
      this.form.statusChanges
        .pipe(
          filter((status) => status === 'VALID'),
          first(),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() =>
          this.orderFormService.submit({
            verifyRoute: true,
            ignoreCreditLimit: true,
          }),
        );
    }

    this.orderFormService.valueChanges$
      .pipe(
        map((form) => ({
          currency: form.basicInformation.rate.currency,
          isVan:
            form.basicInformation.vehicleTypes.length > 0 &&
            form.basicInformation.vehicleTypes.every((type) =>
              type.startsWith(`${FleetCategory.VAN}-`),
            ),
          route: form.points.map((point) =>
            pick(point, ['address', 'startAddress', 'endAddress']),
          ),
        })),
        distinctUntilChanged(isEqual),
        skip(1),
        filter(() => {
          if (this.showRoutePreview()) return true;

          this.orderFormService.setRoutes([]);
          return false;
        }),
        switchMap(() => this.orderFormService.updateRoutes()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    const value = toSignal(this.orderFormService.valueChanges$, {
      initialValue: this.form.getRawValue(),
    });
    const isOverCreditLimit = toSignal(
      this.orderFormService.price$.pipe(
        map(({ isOverCreditLimit }) => isOverCreditLimit),
      ),
      { initialValue: false },
    );

    effect(() => {
      const getSubmitButton = (verifyRoute: boolean): TopBarButton => ({
        text: `${this.I18N}.${this.mode() === OnyxFormMode.ADD ? `addOrder${verifyRoute ? 'Verified' : 'Unverified'}` : `saveOrder${verifyRoute ? 'Verified' : 'Unverified'}`}`,
        callback: () =>
          !verifyRoute || this.hasRouteEdit()
            ? this.orderFormService.submit({
                verifyRoute,
                ignoreCreditLimit: isOverCreditLimit(),
              })
            : undefined,
        color: isOverCreditLimit() ? 'red' : 'blue',
        type: verifyRoute ? 'primary' : 'ghost',
        size: 'm',
        spinner: this.orderFormService.loading(),
        disabled: verifyRoute && !this.hasRouteEdit(),
        tooltip:
          verifyRoute && !this.hasRouteEdit()
            ? `${this.I18N}.blockedRouteError`
            : isOverCreditLimit()
              ? `${this.I18N}.overCreditLimitWarning`
              : undefined,
      });

      this.dashboardService.setTopBar({
        color: 'gray',
        breadcrumbs: [
          {
            segment:
              this.mode() === OnyxFormMode.ADD
                ? OrdersRoute.ADD_ORDER
                : OrdersRoute.EDIT_ORDER.replace(':uuid', this.order()!.uuid),
            label:
              this.mode() === OnyxFormMode.ADD
                ? `${this.I18N}.newOrder`
                : this.translateService.instant(`${this.I18N}.editOrder`, {
                    identifier: this.order()?.identifier,
                  }),
          },
        ],
        buttons: [
          {
            text:
              this.mode() === OnyxFormMode.ADD
                ? 'labels.clear'
                : 'labels.undoChanges',
            callback: () => this.reset(),
            color: 'black',
            type: 'ghost',
            size: 'm',
            disabled: isEqual(initialState, value()),
          },
          {
            text: 'labels.cancel',
            callback: () => this.orderFormService.cancel(true),
            color: 'red',
            type: 'ghost',
            size: 'm',
          },
          getSubmitButton(false),
          getSubmitButton(true),
        ],
      });
    });

    this.orderFormService.restorePoints$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((restorePoint) =>
        this.toastService.showInformation(this.restorePointToastTemplateRef(), {
          templateContext: {
            message: `${this.I18N}.toasts.${restorePoint}`,
            order: this.form.getRawValue(),
          },
        }),
      );
  }

  protected restoreForm(order: PartialDeep<OrderForm>): void {
    this.orderFormService.restoreForm(order);
    afterNextRender(() => this.parametersFormTemplateRef().reset(), {
      injector: this.injector,
    });
  }

  private reset(): OrderForm {
    const data = this.route.snapshot.data;
    this.mode.set(data['mode']);
    this.orderFormService.fleetValidation.set(data['order'].fleetValidation);
    this.order.set(data['order'].order ?? null);

    const order: PartialDeep<OrderForm> = data['order'].form;
    order.points ??= [
      OrderLoadingPointFormComponent.buildForm(this.fb).getRawValue(),
    ];
    this.restoreForm(order);

    this.orderFormService.routeConfig.set(
      (order?.route?.config ?? null) as RouteConfig | null,
    );

    return this.form.getRawValue();
  }

  public static buildForm(
    fb: NonNullableFormBuilder,
    authService: AuthService,
    preferencesService: PreferencesService,
  ) {
    return fb.group({
      basicInformation: OrderBasicInformationFormComponent.buildForm(
        fb,
        authService,
        preferencesService,
      ),
      parameters: ParametersFormComponent.buildForm('order', fb),
      documents: DocumentsFormComponent.buildForm(fb),
      note: NoteFormComponent.buildForm(fb),
      points: OrderPointsFormComponent.buildForm(fb),
      route: fb.control<Omit<OrderRoute, 'author' | 'updatedAt'> | null>(null),
    });
  }
}
