<section>
  <onyx-information-heading>
    {{ 'labels.details' | translate }}
  </onyx-information-heading>

  @let author = order().author;
  <onyx-information-row label="labels.orderAuthor">
    <span>{{ author | onyxName }}</span>
  </onyx-information-row>

  <onyx-information-row label="labels.additionDate">
    {{ order().timestamps.createdAt | onyxDate: 'date-dot-time' }}
  </onyx-information-row>

  @let outsourcingData = order().outsourcingData;
  @if (outsourcingData) {
    <onyx-information-heading>
      <div class="outsourcing-heading">
        <onyx-icon name="cursor" [size]="16"></onyx-icon>

        {{ 'labels.outsourcing' | translate }}
      </div>
    </onyx-information-heading>

    <div class="outsourcing">
      <div class="information-row">
        <div class="item f-paragraph-semibold-2">
          <p class="heading f-medium-3">{{ 'labels.sellRate' | translate }}</p>

          {{ outsourcingData.rate | onyxAmount: 'value' : false }}
        </div>

        <div class="item f-paragraph-semibold-2">
          <p class="heading f-medium-3">{{ 'labels.margin' | translate }}</p>

          <div class="amount">
            {{ outsourcingData.marginRate | onyxAmount: 'value' : false }}
            ({{ outsourcingData.marginPercentage / 100 | number: '1.0-1' }}%)
          </div>
        </div>

        <div class="item f-paragraph-semibold-2">
          <p class="heading f-medium-3">
            {{ 'labels.paymentTerm' | translate }}
          </p>

          {{ outsourcingData.paymentTerm | onyxDate: 'date' }}
        </div>
      </div>

      @let OUTSOURCING_FORM_I18N = 'orders.orderModal.outsourcingForm';
      @let executionTerms = outsourcingData.executionTerms;
      <onyx-data-widget
        [supheading]="
          executionTerms
            ? ('executionTerms' | i18n: OUTSOURCING_FORM_I18N)
            : 'labels.missingExecutionTerms'
        "
      >
        @if (executionTerms) {
          <p class="execution-terms">{{ executionTerms }}</p>
        }
      </onyx-data-widget>

      @let note = outsourcingData.note;
      @let hasContent = note && note.content;
      <div class="note">
        <div class="details f-regular-3 f-gray">
          @if (hasContent) {
            <span>{{ 'labels.lastUpdatedAt' | translate }}</span>
            <span>·</span>
            <span>{{ note.updatedAt | onyxDate: 'date-time' }}</span>
          } @else {
            <span>
              {{ 'labels.missingOfficeNote' | translate }}
            </span>
          }
        </div>

        @if (hasContent) {
          <p class="content">{{ note.content }}</p>
        }
      </div>
    </div>
  }

  @let vehicleRequirements = parameters().vehicleRequirements;
  @if (vehicleRequirements.length) {
    <onyx-information-heading>
      {{ 'labels.vehicleRequirements' | translate }}
    </onyx-information-heading>

    @for (requirement of vehicleRequirements; track requirement) {
      <onyx-information-row [label]="requirement.name | translate">
        @let value = $any(requirement.value);
        @if (value === true) {
          {{ 'labels.yes' | translate }}
        } @else {
          @switch (requirement.key) {
            @case ('adrClasses') {
              @for (class of value; track class) {
                {{ DictionaryCode.ADR_CLASS + '.' + class | translate }}
              }
            }
            @case ('temperatureRange') {
              {{ value | onyxTemperature }}
            }
            @case ('minSemiTrailerSize') {
              <div class="trailer-requirements">
                <onyx-icon
                  [name]="semiTrailerSizeIcon()"
                  [size]="16"
                ></onyx-icon>

                {{
                  DictionaryCode.ORDER_SEMI_TRAILER_SIZE + '.' + value
                    | translate
                }}
              </div>
            }
            @case ('dischargeTypes') {
              {{ value | join: ', ' : 5 : DictionaryCode.DISCHARGE_TYPE }}
            }
            @case ('cargoSpace') {
              {{ value | join: ', ' : 5 : DictionaryCode.VEHICLE_TYPE }}
            }
            @case ('other') {
              @for (parameter of value; track parameter.uuid) {
                {{ parameter.names | onyxLanguage }}{{ !$last ? ',' : '' }}
              }
            }
            @default {
              {{ value }}
            }
          }
        }
      </onyx-information-row>
    }
  }

  @let driverRequirements = parameters().driverRequirements;
  @if (driverRequirements.length) {
    <onyx-information-heading>
      {{ 'labels.driverRequirements' | translate }}
    </onyx-information-heading>

    @for (requirement of driverRequirements; track requirement) {
      <onyx-information-row [label]="requirement.name | translate">
        @let value = $any(requirement.value);
        @if (value === true) {
          {{ 'labels.yes' | translate }}
        } @else {
          {{ value | join: ', ' : 4 : DictionaryCode.LANGUAGE }}
        }
      </onyx-information-row>
    }
  }

  @let otherRequirements = parameters().otherRequirements;
  @if (otherRequirements.length) {
    <onyx-information-heading>
      {{ 'labels.otherRequirements' | translate }}
    </onyx-information-heading>

    @for (requirement of otherRequirements; track requirement) {
      <onyx-information-row [label]="requirement.name | translate">
        @let value = $any(requirement.value);
        @if (value === true) {
          {{ 'labels.yes' | translate }}
        } @else {
          {{ value }}
        }
      </onyx-information-row>
    }
  }
</section>
