import { Pipe, PipeTransform } from '@angular/core';
import { match } from 'fuzzy';
import { isString } from 'lodash';

@Pipe({
  name: 'onyxHighlight',
  pure: true,
})
export class OnyxHighlightPipe implements PipeTransform {
  public transform(value: string, query: string): string {
    if (!query || !isString(value)) return value;

    return (
      match(query, value, {
        pre: '<span class="f-highlight">',
        post: '</span>',
      })?.rendered || value
    );
  }
}
