import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const onyxMinNumberValidator =
  (minValue: number, decimalPlaces = 0): ValidatorFn =>
  (control: AbstractControl<number>): ValidationErrors | null => {
    const value = control.value;
    if (value == null) return null;

    const decimalValue =
      decimalPlaces !== 0 ? value / Math.pow(10, decimalPlaces) : value;
    if (decimalValue >= minValue) return null;

    return {
      min: {
        min: minValue,
        actual: value,
      },
    };
  };
