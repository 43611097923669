import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnyxOption } from '@onyx/angular';
import { chain, intersection, uniqBy } from 'lodash';
import { ValidationSeverity } from '../../../../common/enums/validation-severity';
import {
  ValidationConditionsHelper,
  ValidationContext,
} from '../../../../common/helpers/validation-conditions.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { ValidationIssue } from '../../../../common/interfaces/validation/validation-issue';
import { ValidationResult } from '../../../../common/interfaces/validation/validation-result';
import { Driver } from '../../../drivers/common/interfaces/driver';
import { FleetCategory } from '../enums/fleet-category';
import { SemiTrailerSize } from '../enums/semi-trailer-size';
import { TrailerSize } from '../enums/trailer-size';
import { Fleet, SimplifiedFleet } from '../interfaces/fleet';
import { FleetHelper } from './fleet.helper';

@Injectable({
  providedIn: 'root',
})
export class FleetValidationHelper {
  private readonly I18N = 'issues';

  constructor(
    private translateService: TranslateService,
    private validationConditionsHelper: ValidationConditionsHelper,
  ) {}

  public validateSets(data: {
    context: ValidationContext;
    primary: Fleet;
    options: {
      warningsFocus?: 'vehicle' | 'trailer';
      secondary: Fleet | SimplifiedFleet;
      driver?: Driver;
      secondaryDriver?: Driver;
      option?: OnyxOption<Fleet>;
    };
  }): ValidationResult<Fleet> {
    const { primary, options, context } = data;
    const {
      warningsFocus = 'vehicle',
      secondary,
      option,
      driver,
      secondaryDriver,
    } = options;
    const issues: ValidationIssue[] = [];

    const fleet = warningsFocus === 'vehicle' ? primary : secondary;
    if (FleetHelper.isFleetType(secondary) && FleetHelper.isFleetType(fleet)) {
      this.checkExpiryStatuses(fleet, issues, context);
      this.checkIsForSale(fleet, issues);
      this.checkSetAllowedCountries(primary, secondary, issues, context);
      this.validationConditionsHelper.checkCountries(context, issues, {
        driver,
        secondaryDriver,
        vehicle: primary,
        trailer: secondary,
      });

      for (const driverItem of [driver, secondaryDriver]) {
        if (!driverItem) continue;

        this.validationConditionsHelper.checkRequiredDrivingLicense(
          context,
          driverItem,
          issues,
          { vehicle: primary, trailer: secondary },
        );
        this.validationConditionsHelper.checkDrivingLicenseExpiryDate(
          context,
          driverItem,
          issues,
          { vehicle: primary, trailer: secondary },
        );
      }
    }

    this.checkTrailerCompatibility(primary, secondary, issues);

    return ValidationHelper.mapIssues(
      uniqBy(issues, (issue) => issue.message),
      option,
    );
  }

  public validateFleet(data: {
    context: ValidationContext;
    primary: Fleet;
    options: {
      driver?: Driver;
      secondaryDriver?: Driver;
      option?: OnyxOption<Fleet>;
    };
  }): ValidationResult<Fleet> {
    const { primary, options, context } = data;
    const { option, driver, secondaryDriver } = options;
    const issues: ValidationIssue[] = [];

    this.checkExpiryStatuses(primary, issues, context);
    this.validationConditionsHelper.checkCountries(context, issues, {
      driver,
      vehicle: primary,
      secondaryDriver,
    });
    this.checkIsForSale(primary, issues);

    for (const driverItem of [driver, secondaryDriver]) {
      if (!driverItem) continue;

      this.validationConditionsHelper.checkRequiredDrivingLicense(
        context,
        driverItem,
        issues,
        { vehicle: primary },
      );
      this.validationConditionsHelper.checkDrivingLicenseExpiryDate(
        context,
        driverItem,
        issues,
        { vehicle: primary },
      );
    }

    return ValidationHelper.mapIssues(
      uniqBy(issues, (issue) => issue.message),
      option,
    );
  }

  public validateDriver(data: {
    context: ValidationContext;
    vehicle: Fleet;
    options: {
      driver: Driver;
      option?: OnyxOption<Fleet>;
    };
  }) {
    const { vehicle, options, context } = data;
    const { driver, option } = options;
    const issues: ValidationIssue[] = [];

    this.validationConditionsHelper.checkSkills(driver, issues, vehicle);
    this.checkIsFullCrew(vehicle, issues);
    this.validationConditionsHelper.checkAdr(driver, issues, vehicle);
    this.checkIsForSale(vehicle, issues);
    this.checkExpiryStatuses(vehicle, issues, context);
    this.validationConditionsHelper.checkCountries(context, issues, {
      driver,
      vehicle,
    });
    this.validationConditionsHelper.checkRequiredDrivingLicense(
      context,
      driver,
      issues,
      { vehicle },
    );
    this.validationConditionsHelper.checkDrivingLicenseExpiryDate(
      context,
      driver,
      issues,
      { vehicle },
    );

    return ValidationHelper.mapIssues(issues, option);
  }

  private checkIsFullCrew(fleet: Fleet, issues: ValidationIssue[]) {
    const drivers = chain(fleet.drivers).values().compact().value();
    if (drivers.length !== 2) return;

    issues.push({
      severity: ValidationSeverity.WARNING,
      message: `${this.I18N}.fullCrew`,
    });
  }

  private checkExpiryStatuses(
    fleet: Fleet,
    issues: ValidationIssue[],
    context: ValidationContext,
  ) {
    const vehicleExpiryStatuses = FleetHelper.getVehicleExpiryStatuses(fleet);
    if (!vehicleExpiryStatuses) return;

    this.validationConditionsHelper.addExpiryIssue(
      vehicleExpiryStatuses.error,
      vehicleExpiryStatuses.error.length
        ? `<span class='f-semibold-3'>${this.translateService.instant(`labels.${vehicleExpiryStatuses.error[0].key}`)}</span> ${this.translateService.instant('fleet.fleetModal.expired')}`
        : '',
      `${this.I18N}.documents.documentsExpired`,
      issues,
      {
        forceSeverity: {
          [ValidationContext.DELEGATION]: ValidationSeverity.ERROR,
          [ValidationContext.SET]: ValidationSeverity.WARNING,
          [ValidationContext.DRIVER]: ValidationSeverity.WARNING,
          [ValidationContext.FLEET]: ValidationSeverity.WARNING,
        }[context],
      },
    );
    this.validationConditionsHelper.addExpiryIssue(
      vehicleExpiryStatuses.warning,
      vehicleExpiryStatuses.warning.length
        ? `<span class='f-semibold-3'>${this.translateService.instant(`labels.${vehicleExpiryStatuses.warning[0].key}`)}</span> ${this.translateService.instant('fleet.fleetModal.expires')}`
        : '',
      `${this.I18N}.documents.documentsExpires`,
      issues,
      { forceSeverity: ValidationSeverity.WARNING },
    );
  }

  private checkIsForSale(fleet: Fleet, issues: ValidationIssue[]) {
    if (fleet.ownership.isForSale) {
      issues.push({
        severity: ValidationSeverity.WARNING,
        message: `${this.I18N}.vehicleForSale`,
      });
    }
  }

  private checkSetAllowedCountries(
    primary: Fleet,
    secondary: Fleet,
    issues: ValidationIssue[],
    context: ValidationContext,
  ) {
    if (
      intersection(
        primary.allowedCountries.whitelist,
        secondary.allowedCountries.whitelist,
      ).length === 0
    ) {
      issues.push({
        severity: {
          [ValidationContext.SET]: ValidationSeverity.WARNING,
          [ValidationContext.DELEGATION]: ValidationSeverity.ERROR,
          [ValidationContext.DRIVER]: ValidationSeverity.WARNING,
          [ValidationContext.FLEET]: ValidationSeverity.WARNING,
        }[context],
        message: `${this.I18N}.differentCountries`,
      });
    }
  }

  private checkTrailerCompatibility(
    primary: Fleet,
    secondary: Fleet | SimplifiedFleet,
    issues: ValidationIssue[],
  ) {
    const category = primary.generalInformation.category;
    const { isLowDeck } = primary.additionalParameters;
    const { semiTrailerSize, trailerSize } = secondary.additionalParameters;

    const errors = [
      {
        condition:
          isLowDeck &&
          category === FleetCategory.SEMI_TRUCK &&
          semiTrailerSize !== SemiTrailerSize.MEGA,
        message: `${this.I18N}.standardTrailer`,
      },
      {
        condition:
          !isLowDeck &&
          category === FleetCategory.SEMI_TRUCK &&
          semiTrailerSize === SemiTrailerSize.MEGA,
        message: `${this.I18N}.lowDeck`,
      },
      {
        condition:
          category === FleetCategory.VAN && trailerSize !== TrailerSize.VAN,
        message: `${this.I18N}.straightTruckTrailer`,
      },
      {
        condition:
          category === FleetCategory.STRAIGHT_TRUCK &&
          trailerSize !== TrailerSize.STRAIGHT_TRUCK,
        message: `${this.I18N}.vanTrailer`,
      },
    ];

    for (const { condition, message } of errors) {
      if (!condition) continue;

      issues.push({ severity: ValidationSeverity.ERROR, message });
    }
  }
}
