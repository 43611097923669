export enum OnyxMapVehicleStatus {
  FREE_UNDER_24H = 'free-under-24h',
  FREE_OVER_24H = 'free-over-24h',
  FREE_DELEGATION_UNDER_24H = 'free-delegation-under-24h',
  FREE_DELEGATION_OVER_24H = 'free-delegation-over-24h',
  RIDE_ON_TIME = 'ride-on-time',
  RIDE_DELAY_RISK = 'ride-delay-risk',
  RIDE_DELAYED = 'ride-delayed',
  OFF_ROUTE_ON_TIME = 'off-route-on-time',
  OFF_ROUTE_DELAY_RISK = 'off-route-delay-risk',
  OFF_ROUTE_DELAYED = 'off-route-delayed',
  FAILURE = 'failure',
  FAILURE_FREE = 'failure-free',
  SEMI_TRAILER_FREE = 'semi-trailer-free',
  SEMI_TRAILER_LOADED = 'semi-trailer-loaded',
  TRAILER_FREE = 'trailer-free',
  TRAILER_LOADED = 'trailer-loaded',
}
