import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  OnDestroy,
  OnInit,
  viewChild,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  MapHelper,
  OnyxLoadingBannerComponent,
  OnyxMapComponent,
  OnyxMapEvent,
  OnyxMapRoutePoint,
  OnyxModalService,
} from '@onyx/angular';
import { chain, isEqual } from 'lodash';
import { RoutePointState } from '../../../../common/enums/route/route-point-state';
import { RouteService } from '../../../../common/services/route.service';
import { OrderFormService } from '../../common/services/order-form.service';
import { OrderFormRouteModalComponent } from './order-form-route-modal/order-form-route-modal.component';

@Component({
  selector: 'app-order-form-route',
  standalone: true,
  imports: [OnyxMapComponent, OnyxLoadingBannerComponent, TranslatePipe],
  providers: [RouteService],
  templateUrl: './order-form-route.component.html',
  styleUrl: './order-form-route.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormRouteComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  protected readonly I18N = 'orders.orderForm.route';

  private readonly map = viewChild.required(OnyxMapComponent);

  protected activeRoute = this.orderFormService.activeRoute;

  private close?: () => void;

  constructor(
    private modalService: OnyxModalService,
    private orderFormService: OrderFormService,
    private injector: Injector,
    private routeService: RouteService,
  ) {
    effect(() =>
      this.routeService.isDedicated.set(this.orderFormService.isDedicated()),
    );

    this.routeService.add$.subscribe(({ point, index }) =>
      this.orderFormService.addPoint(point, index),
    );
    this.routeService.move$.subscribe(({ index, direction }) =>
      this.orderFormService.movePoint(index, direction),
    );
    this.routeService.edit$.subscribe(() =>
      this.orderFormService.closeRoutePreview(),
    );
    this.routeService.remove$.subscribe(({ index }) =>
      this.orderFormService.removePoint(index),
    );
  }

  public ngOnInit(): void {
    this.close = this.modalService.open(
      OrderFormRouteModalComponent,
      undefined,
      {
        disableClose: true,
        providers: [
          { provide: OrderFormService, useValue: this.orderFormService },
          { provide: RouteService, useValue: this.routeService },
        ],
      },
    ).forceClose;
  }

  public ngAfterViewInit(): void {
    const routePointsSignal = computed(
      () =>
        this.orderFormService.points().map(
          ({ value, index }): OnyxMapRoutePoint => ({
            type: value.type,
            coordinates: ('address' in value
              ? value.address
              : value.startAddress
            )?.coordinates,
            included: 'includeInOrder' in value ? value.includeInOrder : true,
            active:
              this.routeService.getPointState(index) !==
              RoutePointState.DEFAULT,
            completed: false,
            onTime: false,
          }),
        ),
      { equal: isEqual },
    );

    effect(
      () => {
        const routePoints = routePointsSignal();

        const drawnRoutePoints = new Set<number>();
        for (const [index, point] of routePoints.entries()) {
          this.map().dispatch(
            new OnyxMapEvent.AddUpdateRoutePoint({
              id: index,
              point,
              callback: () => this.routeService.togglePointState(index),
            }),
          );
          drawnRoutePoints.add(index);
        }

        for (const id of this.map().routePoints) {
          if (drawnRoutePoints.has(id)) continue;
          this.map().dispatch(new OnyxMapEvent.RemoveRoutePoint({ id }));
        }

        this.map().dispatch(new OnyxMapEvent.FitContent());
      },
      { injector: this.injector },
    );

    effect(
      () => {
        const routePoints = routePointsSignal();
        const activeRoute = this.orderFormService.activeRoute();
        const routes = chain(this.orderFormService.routes())
          .map(({ value }) => value)
          .orderBy((route) => route === activeRoute)
          .value();

        const drawnRoutes = new Set<number>();
        for (const route of routes) {
          const id = MapHelper.getRouteId(route);
          this.map().dispatch(
            new OnyxMapEvent.AddUpdateRoute({
              id,
              route,
              points: routePoints,
              callback: (index) => {
                if (route === activeRoute) {
                  this.routeService.toggleSection(index);
                } else {
                  this.orderFormService.changeRoute(route);
                }
              },
              options: { alternative: route !== activeRoute },
            }),
          );
          drawnRoutes.add(id);
        }

        for (const id of this.map().routes) {
          if (drawnRoutes.has(id)) continue;
          this.map().dispatch(new OnyxMapEvent.RemoveRoute({ id }));
        }
      },
      { injector: this.injector },
    );
  }

  public ngOnDestroy(): void {
    this.close?.();
  }
}
