import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  Directive,
  ElementRef,
  HostListener,
  ViewContainerRef,
  input,
} from '@angular/core';
import { isFunction } from 'lodash';
import { OnyxTooltipContext, OnyxTooltipTemplateContext } from '../components';
import { ONYX_TOOLTIP_DELAY } from '../constants';
import { OnyxOverlayPosition } from '../enums';
import {
  OnyxTooltipColor,
  OnyxTooltipConfig,
} from '../internal/components/onyx-tooltip-overlay/onyx-tooltip-overlay.component';
import { OnyxTooltipService } from '../internal/services/onyx-tooltip.service';

@Directive({
  selector: '[onyxTooltip]',
})
export class OnyxTooltipDirective {
  public onyxTooltipContext = input.required<OnyxTooltipContext | undefined>({
    alias: 'onyxTooltip',
  });
  public onyxTooltipTemplateContext = input<OnyxTooltipTemplateContext>(null);
  public onyxTooltipColor = input<OnyxTooltipColor>('black');
  public onyxTooltipShowArrow = input(true);
  public onyxTooltipTextAlign = input<OnyxTooltipConfig['textAlign']>('left');
  public onyxTooltipArrowOffsets = input<{
    x?: number;
    y?: number;
    calculateY?: boolean;
    calculateX?: boolean;
  }>({});
  public onyxTooltipPositions = input<OnyxOverlayPosition[]>([]);
  public onyxTooltipTargetRef = input<HTMLElement>();
  public onyxTooltipDelay = input(ONYX_TOOLTIP_DELAY);
  public onyxTooltipTheme = input<string[]>([]);
  public onyxTooltipOverridePositions = input<Partial<ConnectedPosition>>();
  public onyxTooltipEnabled = input<boolean | (() => boolean)>();

  constructor(
    private tooltipService: OnyxTooltipService,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) {}

  @HostListener('mouseenter')
  @HostListener('focus')
  public show(): void {
    const context = this.onyxTooltipContext();
    const enabled = this.onyxTooltipEnabled();
    const active =
      enabled == null || (isFunction(enabled) ? enabled() : !!enabled);
    if (!context || !active) return;

    this.tooltipService.attachTooltip(
      this.elementRef,
      this.viewContainerRef,
      context,
      this.onyxTooltipShowArrow(),
      this.onyxTooltipTextAlign(),
      this.onyxTooltipArrowOffsets(),
      this.onyxTooltipPositions(),
      this.onyxTooltipTargetRef(),
      this.onyxTooltipDelay(),
      this.onyxTooltipOverridePositions(),
      this.onyxTooltipColor(),
      this.onyxTooltipTemplateContext(),
    );
  }

  @HostListener('click')
  @HostListener('keydown.shift.tab')
  @HostListener('keydown.tab')
  public close(): void {
    this.tooltipService.detachTooltip();
  }
}
