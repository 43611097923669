@if (cashDiscount()?.length && cashDiscount(); as cashDiscounts) {
  @for (cashDiscount of cashDiscounts | slice: 0 : MAX_VISIBLE; track $index) {
    {{ cashDiscount.percentage / 100 | number: '1.0-2' }}%
    {{ 'labels.inTerm' | translate | lowercase }} {{ cashDiscount.termDays }}
    {{ 'labels.days' | translate | lowercase
    }}<!---->
    @if (!$last) {
      <!---->, <br />
    } @else if (cashDiscounts.length > MAX_VISIBLE) {
      ...
    }
  }
} @else {
  -
}
