import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Injector,
  input,
  OnInit,
  Signal,
  untracked,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ActionHelper,
  FormHelper,
  OnyxDropdownComponent,
  OnyxDropdownOptionsSource,
  OnyxFormGroupComponent,
  OnyxOption,
  OnyxSectionComponent,
  OnyxSuggestion,
  OnyxSuggestionsComponent,
  OnyxTab,
  OnyxTabsComponent,
  OnyxTextFieldComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { chain, isObject, uniq } from 'lodash';
import {
  combineLatestWith,
  filter,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  skip,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { validate as validateUuid } from 'uuid';
import { AuthService } from '../../../../auth/common/services/auth.service';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { StatisticsType } from '../../../../common/enums/statistics-type';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import {
  FleetType,
  FleetValidationByType,
} from '../../../../common/interfaces/validation/fleet-validation';
import { FleetIdentifierPipe } from '../../../../common/pipes/fleet-identifier.pipe';
import { I18nPipe } from '../../../../common/pipes/i18n.pipe';
import { DictionariesService } from '../../../../common/services/dictionaries.service';
import { PreferencesService } from '../../../../common/services/preferences.service';
import { StatisticsService } from '../../../../common/services/statistics.service';
import { FleetCategory } from '../../../fleet/common/enums/fleet-category';
import { FleetState } from '../../../fleet/common/enums/fleet-state';
import { Fleet } from '../../../fleet/common/interfaces/fleet';
import { FleetService } from '../../../fleet/common/services/fleet.service';
import { ContractorHelper } from '../../../management-panel/contractors/common/helpers/contractor.helper';
import { Contractor } from '../../../management-panel/contractors/common/interfaces/contractor';
import { ContractorsRoute } from '../../../management-panel/contractors/contractors.routes';
import { OrderRateType } from '../../common/enums/order-rate-type';
import { OrderSemiTrailerSize } from '../../common/enums/order-semi-trailer-size';
import { OrderFormService } from '../../common/services/order-form.service';

type OrderBasicInformationFormGroup = ReturnType<
  typeof OrderBasicInformationFormComponent.buildForm
>;

@Component({
  selector: 'app-order-basic-information-form',
  imports: [
    OnyxSectionComponent,
    ReactiveFormsModule,
    OnyxDropdownComponent,
    AsyncPipe,
    OnyxTextFieldComponent,
    OnyxTabsComponent,
    OnyxFormGroupComponent,
    OnyxSuggestionsComponent,
    OnyxFormGroupComponent,
    OnyxTooltipDirective,
    I18nPipe,
  ],
  templateUrl: './order-basic-information-form.component.html',
  styleUrl: './order-basic-information-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderBasicInformationFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.basicInformation';
  protected readonly SENDERS_SOURCE =
    this.contractorHelper.getContractorsSource();
  protected readonly LOADED_SEMI_TRAILER_SOURCE: OnyxDropdownOptionsSource<
    Fleet | string
  > = {
    list: (query, limit) =>
      this.fleetService.searchFleet(query, limit, {
        category: [FleetCategory.SEMI_TRAILER],
        state: FleetState.ACTIVE,
      }),
    get: (uuid) => {
      if (!validateUuid(uuid)) return { name: uuid, value: uuid };

      return this.fleetService.getFleet(uuid).pipe(
        map((trailer) => ({
          name: this.fleetIdentifierPipe.transform(trailer),
          value: trailer,
        })),
      );
    },
    idKey: 'uuid',
  };

  protected readonly ourBranches = computed(() => {
    const form = untracked(this.form);
    const business = untracked(this.authService.business)!;

    const branches = [
      { name: 'labels.mainBranch', value: business.uuid },
      ...business.branches.map((branch) => ({
        name: branch.name,
        value: branch.uuid,
      })),
    ];
    if (branches.length === 1) form.controls.ourBranch.disable();

    return branches;
  });
  protected readonly rateTypes$ = this.dictionariesService.getDictionary(
    DictionaryCode.RATE_TYPE,
  );
  protected readonly currencies$ = this.dictionariesService.getDictionary(
    DictionaryCode.CURRENCY,
  );
  protected readonly vatRates$ = this.dictionariesService.getDictionary(
    DictionaryCode.VAT_RATE,
  );
  protected readonly vehicleTypes$ = this.dictionariesService.getDictionary(
    DictionaryCode.VEHICLE_TYPE,
  );

  public form = input.required<OrderBasicInformationFormGroup>();
  public validation = input.required<FleetValidationByType[FleetType]>();

  protected sender$ = this.orderFormService.sender$;
  protected senderLink$ = this.sender$.pipe(
    map((sender) => {
      if (!sender) return undefined;
      return {
        link: 'labels.preview',
        color: 'blue' as const,
        font: 'f-label-2' as const,
        callback: () => this.contractorHelper.openModal(sender),
      };
    }),
  );
  protected senderBranches$!: Observable<OnyxOption<string>[] | null>;
  protected loadedSemiTrailerLoaded$ = new Subject<void>();
  protected loadedSemiTrailer$ = new ReplaySubject<Fleet | string | null>(1);

  protected rateTypeLabel = computed(
    () =>
      (
        ({
          [OrderRateType.FREIGHT]: 'labels.rate',
          [OrderRateType.TONNE]: 'labels.tonneRate',
          [OrderRateType.EVERY_KILOMETER]: 'labels.everyKilometerRate',
          [OrderRateType.LOADED_KILOMETER]: 'labels.loadedKilometerRate',
          [OrderRateType.CUBIC_METER]: 'labels.cubicMeterRate',
        }) satisfies Record<OrderRateType, string>
      )[this.rateType()],
  );
  protected vehicleTypeSuggestions$ = this.statisticsService
    .getStatistics(StatisticsType.VEHICLE_TYPE)
    .pipe(
      map((statistics): OnyxSuggestion<string>[] =>
        chain(statistics)
          .entries()
          .orderBy(([_, count]) => count, 'desc')
          .take(2)
          .filter(([_, count]) => count > 0)
          .map(([value]) => ({
            name: `${DictionaryCode.VEHICLE_TYPE}.${value}`,
            value,
          }))
          .value(),
      ),
    );
  protected semiTrailerSizes$ = this.dictionariesService
    .getDictionary(DictionaryCode.ORDER_SEMI_TRAILER_SIZE)
    .pipe(
      combineLatestWith(this.loadedSemiTrailer$),
      map(([options, loadedSemiTrailer]) =>
        options.map(
          (option): OnyxTab<OrderSemiTrailerSize> => ({
            value: option.value,
            icon: {
              name:
                option.value === OrderSemiTrailerSize.AUTO
                  ? 'refresh'
                  : `semi-trailer-${option.value}`,
              size: 16,
            },
            iconColor:
              option.value === OrderSemiTrailerSize.AUTO ? 'gray' : 'blue',
            tooltip: option.name,
            disabled:
              option.value === OrderSemiTrailerSize.AUTO &&
              loadedSemiTrailer != null,
          }),
        ),
      ),
      shareReplay(1),
    );
  protected dischargeTypes$!: Observable<OnyxOption<string>[]>;

  private rateType!: Signal<OrderRateType>;

  constructor(
    private dictionariesService: DictionariesService,
    private injector: Injector,
    private authService: AuthService,
    private fleetService: FleetService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
    private contractorHelper: ContractorHelper,
    private statisticsService: StatisticsService,
    private orderFormService: OrderFormService,
  ) {}

  public ngOnInit() {
    const controls = this.form().controls;

    const senderBranchControl = controls.senderBranch;
    this.senderBranches$ = this.sender$.pipe(
      map((sender) =>
        sender ? [{ name: 'labels.mainBranch', value: sender.uuid }] : null,
      ),
      tap((branches) => {
        ValidationHelper.toggleControls(senderBranchControl, branches != null);
        setTimeout(() =>
          senderBranchControl.setValue(branches?.[0]?.value ?? null),
        );
      }),
    );

    const rateTypeControl = controls.rateType;
    this.rateType = toSignal(rateTypeControl.valueChanges, {
      initialValue: rateTypeControl.value,
      injector: this.injector,
    });

    this.loadedSemiTrailerLoaded$
      .pipe(
        switchMap(() => this.loadedSemiTrailer$),
        skip(1),
      )
      .subscribe((loadedSemiTrailer) => {
        if (!isObject(loadedSemiTrailer)) {
          if (loadedSemiTrailer != null) {
            controls.minSemiTrailerSize.setValue(null);
          }
          return;
        }

        const vehicleType = loadedSemiTrailer.generalInformation.type!;
        controls.vehicleTypes.setValue(
          uniq([vehicleType, ...controls.vehicleTypes.value]),
        );

        const dischargeTypes = loadedSemiTrailer.cargoSpace.dischargeTypes!;
        controls.dischargeTypes.setValue(
          uniq([...dischargeTypes, ...controls.dischargeTypes.value]),
        );

        const semiTrailerSize =
          loadedSemiTrailer.additionalParameters.semiTrailerSize!;
        controls.minSemiTrailerSize.setValue(semiTrailerSize as any);
      });

    const validation$ = toObservable(this.validation, {
      injector: this.injector,
    });
    this.dischargeTypes$ = this.dictionariesService
      .getDictionary(DictionaryCode.DISCHARGE_TYPE)
      .pipe(
        combineLatestWith(validation$),
        map(([dischargeTypes, validation]) => {
          const field = validation['cargoSpace']?.fields['dischargeTypes'];
          const allowedDischargeTypes =
            field && 'values' in field ? field.values : [];

          return dischargeTypes.filter((type) =>
            allowedDischargeTypes.includes(type.value),
          );
        }),
        tap((dischargeTypes) => {
          ValidationHelper.toggleControls(
            controls.dischargeTypes,
            dischargeTypes.length > 0,
            false,
          );

          controls.dischargeTypes.setValue(
            dischargeTypes.map((type) => type.value),
          );
        }),
      );

    controls.vehicleTypes.valueChanges
      .pipe(
        map(
          (types) =>
            types?.length &&
            types.every((type) => type.startsWith(`${FleetCategory.VAN}-`)),
        ),
      )
      .subscribe((areAllVans) =>
        ValidationHelper.toggleControls(
          controls.minSemiTrailerSize,
          !areAllVans,
        ),
      );
  }

  protected changeSender(sender: Contractor | null): void {
    this.orderFormService.changeSender(sender);
  }

  protected addSender(): void {
    ActionHelper.openLink(ContractorsRoute.ADD_CONTRACTOR);
  }

  protected editSender(): void {
    this.sender$
      .pipe(
        take(1),
        filter((sender) => sender != null),
      )
      .subscribe((sender) =>
        ActionHelper.openLink(
          ContractorsRoute.EDIT_CONTRACTOR.replace(':uuid', sender.uuid),
        ),
      );
  }

  protected addExternalTrailer(registrationNumber: string): void {
    registrationNumber = registrationNumber.toUpperCase().trim();
    this.form().controls.loadedSemiTrailer.setValue(registrationNumber);
  }

  public static buildForm(
    fb: NonNullableFormBuilder,
    authService: AuthService,
    preferencesService: PreferencesService,
  ) {
    return fb.group({
      sender: fb.control<string | null>(null, [Validators.required]),
      senderBranch: fb.control<string | null>(null, [Validators.required]),
      ourBranch: fb.control(authService.business()!.uuid, [
        Validators.required,
      ]),
      rateType: fb.control(preferencesService.defaultRateType(), [
        Validators.required,
      ]),
      rate: FormHelper.buildAmountForm(fb, preferencesService, {
        minValue: Number.EPSILON,
      }),
      vatRate: fb.control(preferencesService.defaultVatRate(), [
        Validators.required,
      ]),
      loadedSemiTrailer: fb.control<string | null>(null),
      vehicleTypes: fb.control<string[]>([], [Validators.required]),
      minSemiTrailerSize: fb.control<OrderSemiTrailerSize | null>(
        OrderSemiTrailerSize.AUTO,
        [Validators.required],
      ),
      dischargeTypes: fb.control<string[]>([], [Validators.required]),
    });
  }
}
