import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  Injector,
  input,
  OnInit,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxErrorComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxSpinnerComponent,
  OnyxToastService,
} from '@onyx/angular';
import { catchError, EMPTY, of, Subject, switchMap, tap, timer } from 'rxjs';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { FleetCategory } from '../../../../fleet/common/enums/fleet-category';
import { Fleet } from '../../../../fleet/common/interfaces/fleet';
import { FleetService } from '../../../../fleet/common/services/fleet.service';
import { FleetModalAssignedVehicleComponent } from '../../../../fleet/fleet-modal/fleet-modal-assigned-vehicle/fleet-modal-assigned-vehicle.component';
import { Order } from '../../../common/interfaces/order';
import { OrderModalVehiclesComponent } from '../../order-modal-main-section/order-modal-vehicles/order-modal-vehicles.component';

@Component({
  selector: 'app-order-modal-vehicles-preview',
  imports: [
    FleetModalAssignedVehicleComponent,
    OnyxSpinnerComponent,
    OnyxErrorComponent,
    OnyxInformationHeadingComponent,
    OnyxIconComponent,
    TranslatePipe,
    OnyxDataWidgetComponent,
    NgClass,
    OrderModalVehiclesComponent,
  ],
  templateUrl: './order-modal-vehicles-preview.component.html',
  styleUrl: './order-modal-vehicles-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalVehiclesPreviewComponent implements OnInit {
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly FleetCategory = FleetCategory;

  public order = input.required<Order>();

  protected vehicle = signal<Fleet | null>(null);
  protected trailer = signal<Fleet | null>(null);
  protected vehicleLoading = signal(false);
  protected trailerLoading = signal(false);
  protected showLoading = signal(false);
  protected vehicleError = signal(false);
  protected trailerError = signal(false);
  protected vehicle$ = new Subject<void>();
  protected trailer$ = new Subject<void>();

  constructor(
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private destroyRef: DestroyRef,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    effect(
      () => {
        this.showLoading.set(false);
        if (this.vehicleLoading() || this.trailerLoading()) {
          timer(1000)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.showLoading.set(true));
        }
      },
      { injector: this.injector },
    );

    this.vehicle$
      .pipe(
        tap(() => this.vehicleLoading.set(true)),
        switchMap(() => {
          const assignedVehicle = this.order().assignedData?.vehicle;
          if (!assignedVehicle) return of(null);

          return this.fleetService.getFleet(assignedVehicle.uuid).pipe(
            catchError((response) => {
              this.vehicle.set(null);
              this.vehicleLoading.set(false);
              this.vehicleError.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (vehicle) => {
          this.vehicle.set(vehicle);
          this.vehicleLoading.set(false);
          this.vehicleError.set(false);
        },
      });

    this.trailer$
      .pipe(
        tap(() => this.trailerLoading.set(true)),
        switchMap(() => {
          const assignedTrailer =
            this.order().assignedData?.trailer ||
            this.order().basicInformation.loadedSemiTrailerUuid;
          if (!assignedTrailer) return of(null);

          return this.fleetService.getFleet(assignedTrailer.uuid).pipe(
            catchError((response) => {
              this.trailer.set(null);
              this.trailerLoading.set(false);
              this.trailerError.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (trailer) => {
          this.trailer.set(trailer);
          this.trailerLoading.set(false);
          this.trailerError.set(false);
        },
      });

    effect(
      () => {
        this.order();
        untracked(() => {
          this.vehicle$.next();
          this.trailer$.next();
        });
      },
      { injector: this.injector },
    );
  }
}
