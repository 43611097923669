@let emptyQuantity = !good().good.quantity;
@let emptyAvailableQuantity = good().availableQuantity <= 0 && form() == null;
@let disabled = emptyQuantity || emptyAvailableQuantity;

<onyx-icon-box class="bar-icon" color="white" [hasShadow]="true">
  <onyx-icon
    [ngStyle]="{ color: good().goodColor }"
    name="good"
    [size]="16"
  ></onyx-icon>
</onyx-icon-box>

<onyx-toggle
  [value]="form() != null"
  [disabled]="disabled"
  (valueChange)="$event ? add.emit() : remove.emit()"
></onyx-toggle>

<div class="good" [ngClass]="{ disabled }">
  <p>{{ goodTypeName$ | async }}</p>

  <div class="details">
    <p>
      @if (good().good.referenceNumber?.trim(); as referenceNumber) {
        <span>{{ referenceNumber }}</span>
      }

      <span class="separator">&nbsp;·&nbsp;</span>

      @if (good().loadingPoint.address; as address) {
        <span>
          {{ address.zipCode }} {{ address.countryCode | uppercase }}
        </span>
      }
    </p>
  </div>
</div>

@if (emptyQuantity) {
  <p class="information">
    {{ I18N + '.emptyQuantity' | translate }}
  </p>
} @else if (emptyAvailableQuantity) {
  <p class="information">
    {{ I18N + '.emptyAvailableQuantity' | translate }}

    @for (
      unloadingPoint of good().unloadingPoints.keys();
      track unloadingPoint
    ) {
      {{ unloadingPoint + 1 }}{{ !$last ? ', ' : '' }}
    }
  </p>
} @else if (form()) {
  <app-order-unloading-point-good-form
    [form]="form()!"
    [good]="good().good"
    [availableQuantity]="good().availableQuantity"
  ></app-order-unloading-point-good-form>
}
