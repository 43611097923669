import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DelegationPeriodComponent } from '../../common/components/delegation-period/delegation-period.component';
import { DelegationStatus } from '../../common/enums/delegation-status';
import { Delegation } from '../../common/interfaces/delegation';
import { DelegationsRoute } from '../../delegations.routes';
import { DelegationModalRealizationStatusComponent } from './delegation-modal-realization-status/delegation-modal-realization-status.component';
import { DelegationModalStatusComponent } from './delegation-modal-status/delegation-modal-status.component';
import { DelegationModalVehiclesComponent } from './delegation-modal-vehicles/delegation-modal-vehicles.component';

@Component({
  selector: 'app-delegation-modal-main-section',
  imports: [
    DelegationModalStatusComponent,
    DelegationModalRealizationStatusComponent,
    DelegationPeriodComponent,
    DelegationModalVehiclesComponent,
    NgClass,
  ],
  templateUrl: './delegation-modal-main-section.component.html',
  styleUrl: './delegation-modal-main-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalMainSectionComponent {
  protected readonly DelegationStatus = DelegationStatus;

  public delegation = input.required<Delegation>();
  public close$ = input.required<Subject<void>>();

  constructor(private router: Router) {}

  protected edit(): void {
    this.router.navigateByUrl(
      `${DelegationsRoute.EDIT_DELEGATION.replace(':uuid', this.delegation().uuid)}`,
    );
    this.close$().next();
  }
}
