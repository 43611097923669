@for (group of groups(); track group[0]) {
  @let countryCode = group[0];
  @let tolls = group[1];

  <div class="group">
    <div class="heading">
      <onyx-flag [countryCode]="countryCode"></onyx-flag>
      <p class="f-medium-2">
        {{ countryCode | i18n: DictionaryCode.COUNTRY | translate }}
      </p>
    </div>

    <onyx-table
      [data]="tolls"
      [columns]="COLUMNS"
      [showHeader]="false"
      tableSize="s"
      [tableShadow]="false"
      [rowColor]="getRowColor.bind(this, tolls.length)"
    ></onyx-table>
  </div>
}
