import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { OnyxColor, OnyxDatePipe, OnyxWidgetBoxComponent } from '@onyx/angular';
import { OrderStatus } from '../../../common/enums/order-status';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-finished-section-main-widget',
  imports: [OnyxWidgetBoxComponent],
  templateUrl: './order-modal-finished-section-main-widget.component.html',
  styleUrl: './order-modal-finished-section-main-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalFinishedSectionMainWidgetComponent {
  protected readonly I18N = 'orders.orderModal';

  public order = input.required<Order>();

  protected widgetData = computed(() => {
    const paymentDelay = this.orderHelper.getPaymentDelay(this.order());

    switch (this.order().status.value) {
      case OrderStatus.COMPLETING_DOCUMENTS: {
        return {
          heading: `${this.I18N}.invoiceTerm`,
          // HOLD: Wait for BE implmentation
          content: '-',
          // content: this.datePipe.transform(
          //   this.order().payments.invoiceTerm ?? '-',
          //   'date',
          // ),
        };
      }

      case OrderStatus.INVOICE_TO_ISSUE: {
        return {
          heading: `${this.I18N}.documentsCompleted`,
          content: this.datePipe.transform(
            this.order().timestamps.invoiceToIssueAt!,
            'date-time',
          ),
        };
      }

      case OrderStatus.WAITING_FOR_PAYMENT:
      case OrderStatus.PARTIALLY_PAID: {
        const paymentTerm = this.order().payments.invoice!.paymentTerm;
        const paymentColor = paymentDelay ? 'red' : 'gray';

        return {
          heading: 'labels.paymentTerm',
          content: this.datePipe.transform(paymentTerm, 'date'),
          color: paymentColor as OnyxColor,
          delay: paymentDelay ?? '',
        };
      }

      case OrderStatus.PAID: {
        const paidDate = this.order().timestamps.paidAt!;
        const paymentColor = paymentDelay ? 'red' : 'gray';

        return {
          heading: `${this.I18N}.paidDate`,
          content: this.datePipe.transform(paidDate, 'date'),
          color: paymentColor as OnyxColor,
          delay: paymentDelay ?? '',
        };
      }

      default:
        return null;
    }
  });

  constructor(
    private datePipe: OnyxDatePipe,
    private orderHelper: OrderHelper,
  ) {}
}
