import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated, OnyxPagination } from '@onyx/angular';
import { Observable, of, Subject, tap } from 'rxjs';
import { ApiService } from '../../../../common/services/api.service';
import { OrderFormDto } from '../../order-form/order-form.component';
import { OrderModalInvoiceForm } from '../../order-modal/order-modal-finished-section/order-modal-invoice-form/order-modal-invoice-form.component';
import { OrderModalPaymentForm } from '../../order-modal/order-modal-finished-section/order-modal-payment-form/order-modal-payment-form.component';
import { OrderModalOutsourcingFormDto } from '../../order-modal/order-modal-main-section/order-modal-outsourcing-form/order-modal-outsourcing-form.component';
import { OrderCategory } from '../enums/order-category';
import { OrderRejectReason } from '../enums/order-reject-reason';
import { OrderStatus } from '../enums/order-status';
import { ManualProposalData, Order } from '../interfaces/order';
import { ORDER_MOCKS } from '../mocks/order-mocks';

export const SHOW_MOCKED_ORDERS = window.location.href.includes('?mocks');

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  public listOrders(
    params: {
      category: OrderCategory;
      statuses: OrderStatus[] | null;
      recommendedAction: boolean;
      showAssignedOnly: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Order>> {
    if (SHOW_MOCKED_ORDERS) return of(ORDER_MOCKS);

    return this.get('/orders', {
      params: {
        category: params.category,
        'status[]': params.statuses ?? [],
        recommendedAction: params.recommendedAction,
        showAssignedOnly: params.showAssignedOnly,
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public getOrder(uuid: string): Observable<Order> {
    if (SHOW_MOCKED_ORDERS) return of(ORDER_MOCKS.items[0]);

    return this.get(`/orders/${uuid}`);
  }

  public addOrder(dto: OrderFormDto): Observable<Order> {
    return this.post<Order>('/orders', dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public editOrder(uuid: string, dto: OrderFormDto): Observable<Order> {
    return this.put<Order>(`/orders/${uuid}`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public deleteOrder(uuid: string): Observable<void> {
    return this.delete<void>(`/orders/${uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public acceptVehicle(uuid: string): Observable<void> {
    return this.post<void>(`/orders/${uuid}/accept-vehicle`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public rejectVehicle(
    uuid: string,
    dto:
      | {
          reason: Exclude<OrderRejectReason, OrderRejectReason.OTHER>;
          other: null;
        }
      | {
          reason: OrderRejectReason.OTHER;
          other: string;
        },
  ): Observable<void> {
    return this.post<void>(`/orders/${uuid}/reject-vehicle`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public addManualProposal(
    uuid: string,
    vehicleUuid: string,
  ): Observable<ManualProposalData> {
    return this.post<ManualProposalData>(`/orders/${uuid}/manual-proposal`, {
      vehicleUuid,
    }).pipe(tap(() => this._reload$.next()));
  }

  public deleteManualProposal(uuid: string): Observable<void> {
    return this.delete<void>(`/orders/${uuid}/manual-proposal`).pipe(
      tap(() => this._reload$.next()),
    );
  }
  public confirmManualProposal(
    uuid: string,
    vehicleUuid: string,
  ): Observable<void> {
    return this.post<void>(`/orders/${uuid}/manual-proposal/confirm`, {
      vehicleUuid,
    }).pipe(tap(() => this._reload$.next()));
  }

  public addOutsourcing(
    uuid: string,
    dto: OrderModalOutsourcingFormDto,
  ): Observable<void> {
    return this.post<void>(`/orders/${uuid}/outsourcing`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public completeDocuments(uuid: string): Observable<void> {
    return this.post<void>(
      `/orders/${uuid}/complete-documents`,
      undefined,
    ).pipe(tap(() => this._reload$.next()));
  }

  public addInvoice(
    uuid: string,
    dto: OrderModalInvoiceForm,
  ): Observable<void> {
    return this.post<void>(`/orders/${uuid}/invoice`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public payForOrder(
    uuid: string,
    dto: OrderModalPaymentForm,
  ): Observable<void> {
    return this.post<void>(`/orders/${uuid}/pay`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public editPayment(
    uuid: string,
    dto: OrderModalPaymentForm,
    paymentUuid: string,
  ): Observable<void> {
    return this.put<void>(`/orders/${uuid}/pay/${paymentUuid}`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public deletePayment(uuid: string, paymentUuid: string): Observable<void> {
    return this.delete<void>(`/orders/${uuid}/pay/${paymentUuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }
}
