<div class="container">
  @if (label(); as label) {
    <onyx-input-label
      [label]="label"
      [hint]="hint()"
      [optional]="isOptional()"
      [disabled]="disabled()"
      (click)="focus()"
    ></onyx-input-label>
  }

  <div
    class="wrapper"
    [ngClass]="{
      invalid: isInvalid(),
      disabled: disabled(),
      active: areaCode() && phoneNumber(),
    }"
  >
    <div
      class="dropdown"
      [ngClass]="{ expanded: expanded() }"
      [tabindex]="disabled() ? -1 : 0"
      [onyxDropdownOptions]="options$"
      [onyxDropdownSelectedOptions]="selectedOptions()"
      [(onyxDropdownQuery)]="query"
      [(onyxDropdownAttached)]="expanded"
      [onyxDropdownSearch]="true"
      [onyxDropdownDisabled]="disabled()"
      [onyxDropdownOptionTemplateRef]="dropdownOptionTemplate"
      (onyxDropdownSelectedOptionsChange)="changeSelectedOptions($any($event))"
      (focusout)="onTouched?.()"
    >
      @if (selectedOptions().length !== 0) {
        <onyx-flag [countryCode]="selectedOptions()[0].value"></onyx-flag>
      }

      <onyx-icon class="arrow" name="arrow-alt-down" [size]="16"></onyx-icon>
    </div>

    <div class="separator"></div>

    <input
      type="text"
      [tabindex]="disabled() ? -1 : 0"
      [value]="phoneNumber()"
      placeholder="000 000 000"
      [disabled]="disabled()"
      (input)="handleValueChange($event)"
      (paste)="pasteNumber($event)"
      #inputElement
    />
  </div>
</div>

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [controls]="[formControl()!]"
  ></onyx-form-control-error>
}

<ng-template let-option="option" let-query="query" #dropdownOptionTemplate>
  <div class="dropdown-option">
    <p [innerHTML]="option.name | onyxHighlight: query"></p>

    <p
      class="area-code"
      [innerHTML]="option.areaCode | onyxHighlight: query"
    ></p>
  </div>
</ng-template>
