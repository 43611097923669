@let isAdd = data.mode === OnyxFormMode.ADD;
<onyx-modal
  type="center"
  [heading]="I18N + (isAdd ? '.addDocument' : '.editDocument')"
  size="m"
  [showClose]="!loading()"
  [close$]="close$"
  (closeModal)="dialogRef.close($event)"
>
  <form [formGroup]="form">
    @let controls = form.controls;
    <onyx-text-field
      [formControl]="controls.name"
      label="labels.name"
    ></onyx-text-field>

    <onyx-text-field
      [formControl]="controls.note"
      label="labels.note"
    ></onyx-text-field>

    <onyx-upload
      [formControl]="controls.attachments"
      label="labels.scanPhoto"
      [limit]="6"
      [columns]="2"
    ></onyx-upload>
  </form>

  @if (!isAdd) {
    <onyx-button
      bottomLeftOptions
      color="red"
      type="outlined"
      [spinner]="loading()"
      (click)="!loading() ? remove() : null"
    >
      {{ 'labels.delete' | translate }}
    </onyx-button>
  }

  <ng-container bottomRightOptions>
    <onyx-button
      color="black"
      type="outlined"
      [spinner]="loading()"
      (click)="!loading() ? cancel() : null"
    >
      {{ 'labels.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ (isAdd ? 'labels.add' : 'labels.saveChanges') | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
