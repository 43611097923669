import { OnyxMapRoutePoint } from '../interfaces';

const COLORS = {
  DELAYED_BORDER: '#4C0707',
  DELAYED_BACKGROUND: '#F26A6A',
  ON_TIME_BORDER: '#14532D',
  ON_TIME_BACKGROUND: '#22C55E',
  COMPLETED_BORDER: '#475569',
  COMPLETED_BACKGROUND: '#94A3B8',
  ACTIVE_BORDER: '#085BC5',
  ACTIVE_BACKGROUND: '#068CFF',
  INACTIVE_BORDER: '#085BC5',
  INACTIVE_BACKGROUND: '#83DEFF',
  ALTERNATIVE_BORDER: '#475569',
  ALTERNATIVE_BACKGROUND: '#CBD5E1',
} as const;
const DOT_DASH = 0.1 as const;
const EMPTY_GAP_DASH = 3 as const;

export interface PolylineOptions extends OnyxMapRoutePoint {
  style: 'empty' | 'vehicle' | 'ferry' | 'train';
  alternative?: boolean;
}
type PolylineColor = (typeof COLORS)[keyof typeof COLORS];
type PolylineStyles = H.map.SpatialStyle.Options[];

export const getPolylineStyles = (options: PolylineOptions): PolylineStyles => {
  const { style } = options;

  let buildPolylineStyles;
  if (style === 'empty') {
    buildPolylineStyles = buildEmptyPolylineStyles;
  } else if (style === 'vehicle') {
    buildPolylineStyles = buildVehiclePolylineStyles;
  } else if (style === 'ferry') {
    buildPolylineStyles = buildFerryPolylineStyles;
  } else if (style === 'train') {
    buildPolylineStyles = buildTrainPolylineStyles;
  } else {
    throw new Error(`Unknown polyline style: ${style}`);
  }

  const { alternative, completed, active, onTime } = options;
  if (alternative) {
    return buildPolylineStyles(
      COLORS.ALTERNATIVE_BORDER,
      COLORS.ALTERNATIVE_BACKGROUND,
    );
  }

  if (completed) {
    if (!active) {
      return buildPolylineStyles(
        COLORS.COMPLETED_BORDER,
        COLORS.COMPLETED_BACKGROUND,
      );
    }

    if (!onTime) {
      return buildPolylineStyles(
        COLORS.DELAYED_BORDER,
        COLORS.DELAYED_BACKGROUND,
      );
    }
    return buildPolylineStyles(
      COLORS.ON_TIME_BORDER,
      COLORS.ON_TIME_BACKGROUND,
    );
  }

  if (active) {
    return buildPolylineStyles(COLORS.ACTIVE_BORDER, COLORS.ACTIVE_BACKGROUND);
  }
  return buildPolylineStyles(
    COLORS.INACTIVE_BORDER,
    COLORS.INACTIVE_BACKGROUND,
  );
};

const buildEmptyPolylineStyles = (
  borderColor: PolylineColor,
  backgroundColor: PolylineColor,
): PolylineStyles => [
  {
    strokeColor: borderColor,
    fillColor: 'transparent',
    lineWidth: 8,
    lineCap: 'round',
    lineDash: [DOT_DASH, EMPTY_GAP_DASH],
  },
  {
    strokeColor: backgroundColor,
    fillColor: 'transparent',
    lineWidth: 6,
    lineCap: 'round',
    lineDash: [DOT_DASH * (8 / 6), EMPTY_GAP_DASH * (8 / 6)],
  },
];

const buildVehiclePolylineStyles = (
  borderColor: PolylineColor,
  backgroundColor: PolylineColor,
): PolylineStyles => [
  {
    strokeColor: borderColor,
    fillColor: 'transparent',
    lineWidth: 8,
  },
  {
    strokeColor: backgroundColor,
    fillColor: 'transparent',
    lineWidth: 6,
  },
];

const buildFerryPolylineStyles = (
  borderColor: PolylineColor,
  backgroundColor: PolylineColor,
): PolylineStyles => {
  return [
    {
      strokeColor: borderColor,
      fillColor: 'transparent',
      lineWidth: 8,
    },
    {
      strokeColor: backgroundColor,
      fillColor: 'transparent',
      lineWidth: 5,
      lineCap: 'round',
      lineDash: [DOT_DASH * (8 / 5), 2],
    },
  ];
};

const buildTrainPolylineStyles = (
  borderColor: PolylineColor,
  backgroundColor: PolylineColor,
): PolylineStyles => {
  return [
    {
      strokeColor: borderColor,
      fillColor: 'transparent',
      lineWidth: 8,
    },
    {
      strokeColor: backgroundColor,
      fillColor: 'transparent',
      lineWidth: 4,
      lineDash: [6, 4],
    },
  ];
};
