import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  OnyxCallbackOption,
  OnyxConfirmModalComponent,
  OnyxConfirmModalData,
  OnyxDropdownOptionsSource,
  OnyxModalService,
  OnyxToastService,
} from '@onyx/angular';
import { map } from 'rxjs';
import { CommonHelper } from '../../../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { HelperOptions } from '../../../../../common/interfaces/utilities/helper-options';
import { BaseForm } from '../../base-form/base-form.component';
import { BasesRoute } from '../../bases.routes';
import {
  BaseModalComponent,
  BaseModalData,
} from '../components/base-modal/base-modal.component';
import { Base, SimplifiedBase } from '../interfaces/base';
import { BasesService } from '../services/bases.service';

@Injectable({
  providedIn: 'root',
})
export class BaseHelper {
  private readonly I18N = 'bases';

  constructor(
    private basesServive: BasesService,
    private router: Router,
    private modalService: OnyxModalService,
    private basesService: BasesService,
    private translateService: TranslateService,
    private toastService: OnyxToastService,
  ) {}

  public getBasesSource(): OnyxDropdownOptionsSource<Base> {
    return {
      list: (query, limit) => this.basesServive.searchBases(query, limit),
      get: (uuid) =>
        this.basesServive.getBase(uuid).pipe(
          map((base) => ({
            name: base.name,
            value: base,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getOptions(base: Base, options: HelperOptions): OnyxCallbackOption[] {
    return [
      {
        name: 'labels.edit',
        value: () => this.edit(base.uuid, options),
      },
      {
        name: 'labels.delete',
        value: () => this.delete(base, options),
      },
    ];
  }

  public openModal(base: BaseModalData): void {
    this.modalService.open<BaseModalData>(BaseModalComponent, base);
  }

  public edit(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(`${BasesRoute.EDIT_BASE.replace(':uuid', uuid)}`);
    CommonHelper.handleOptions(uuid, options);
  }

  public delete(base: Base, options: HelperOptions): void {
    this.modalService
      .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
        heading: this.translateService.instant(
          `${this.I18N}.confirmModal.delete.heading`,
          { base: base.name },
        ),
        message: `${this.I18N}.confirmModal.delete.description`,
        confirmButtonText: 'labels.delete',
        confirmButtonColor: 'red',
      })
      .result.subscribe((ok) => {
        if (!ok) return;

        this.basesService.deleteBase(base.uuid).subscribe({
          next: () => {
            this.toastService.showSuccess(`${this.I18N}.toasts.baseDeleted`);
            CommonHelper.handleOptions(base, options);
          },
          error: (response) =>
            ValidationHelper.handleUnexpectedError(response, this.toastService),
        });
      });
  }

  public static isBaseType(base: SimplifiedBase | Base): base is Base {
    return 'businessHours' in base;
  }

  public static fromDto(dto: Base): BaseForm {
    return {
      ...dto,
      assignedDrivers: {
        selectedDrivers: dto.assignedDrivers.map((driver) => driver.uuid),
        drivers: dto.assignedDrivers.map((driver) => ({
          uuid: driver.uuid,
          driver: driver,
        })),
      },
      assignedVehicles: {
        selectedVehicles: dto.assignedVehicles.map((vehicle) => vehicle.uuid),
        vehicles: dto.assignedVehicles.map((vehicle) => ({
          uuid: vehicle.uuid,
          vehicle: vehicle,
        })),
      },
      baseInformation: {
        address: dto.address,
        name: dto.name,
      },
      contact: {
        phone: dto.phone,
      },
    };
  }
}
