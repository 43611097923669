@if (label(); as label) {
  <onyx-input-label
    [disabled]="disabled()"
    [optional]="isOptional() && !forceOptional()"
    [label]="label"
    [hint]="hint()"
    (click)="focus()"
  ></onyx-input-label>
}

<div
  class="tabs {{ size() }}"
  [ngClass]="{
    fill: fill(),
    invalid: isInvalid(),
    disabled: disabled(),
  }"
  #containerElement
>
  @for (tab of tabs(); track tab.value) {
    @let icon = $any(tab).icon;
    @let active = $index === activeTabIndex();
    @let isDisabled = tab.disabled ?? disabled();

    <button
      class="tab f-regular-1"
      [ngClass]="{ active, disabled: isDisabled, icon: icon }"
      type="button"
      [tabindex]="isDisabled ? -1 : 0"
      [onyxTooltip]="tab.tooltip"
      (click)="changeTab($index)"
      (keydown.space)="changeTab($index)"
      #tabElement
    >
      @if (templateRef(); as templateRef) {
        <ng-container
          *ngTemplateOutlet="
            templateRef;
            context: {
              tab,
              disabled: isDisabled,
              active,
            }
          "
        ></ng-container>
      } @else {
        <div class="content">
          @if (tab.icon; as icon) {
            <onyx-icon
              class="{{ disabled() ? 'gray' : tab.iconColor }}"
              [name]="icon.name"
              [size]="icon.size"
              [frame]="icon.frame"
            ></onyx-icon>
          }

          @let name = tab.name ?? '' | translate;
          @if (name) {
            <p [attr.content]="name">{{ name }}</p>
          }
        </div>
      }
    </button>
  }

  <div class="indicator" #indicatorElement></div>
</div>

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [controls]="[formControl()!]"
  ></onyx-form-control-error>
}
