import { Pipe, PipeTransform } from '@angular/core';
import { OnyxOptional } from '@onyx/angular';
import { PickDeep } from 'type-fest';
import { Fleet } from '../../dashboard/fleet/common/interfaces/fleet';

@Pipe({
  name: 'fleetIdentifier',
  pure: true,
})
export class FleetIdentifierPipe implements PipeTransform {
  public transform(
    identifier: OnyxOptional<
      | PickDeep<
          Fleet,
          | 'generalInformation.sideNumber'
          | 'generalInformation.registrationNumber'
        >
      | Pick<Fleet['generalInformation'], 'sideNumber' | 'registrationNumber'>
    >,
    format: 'dot' | 'bracket' | 'short' | 'registrationNumber' = 'dot',
  ): string {
    if (!identifier) return '-';

    const { sideNumber, registrationNumber } =
      'generalInformation' in identifier
        ? identifier.generalInformation
        : identifier;
    if (!sideNumber) return registrationNumber;

    return {
      dot: `${registrationNumber} · ${sideNumber}`,
      bracket: `${registrationNumber} (${sideNumber})`,
      short: sideNumber || registrationNumber,
      registrationNumber: registrationNumber,
    }[format];
  }
}
