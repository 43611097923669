@let outsourcingData = order().outsourcingData;
<section [class.outsourcing]="outsourcingData">
  @if (outsourcingData) {
    <onyx-information-heading>
      <div class="outsourcing-heading">
        <onyx-icon name="cursor" [size]="16"></onyx-icon>

        {{ 'labels.outsourcing' | translate }}
      </div>
    </onyx-information-heading>

    <div class="outsourcing-drivers">
      <onyx-data-widget supheading="labels.drivers">
        <app-order-outsourcing-driver
          heading
          [driver]="outsourcingData.primaryDriver"
        ></app-order-outsourcing-driver>
      </onyx-data-widget>

      @if (outsourcingData?.secondaryDriver; as secondaryDriver) {
        <onyx-data-widget supheading="labels.secondaryDriver">
          <app-order-outsourcing-driver
            heading
            [driver]="secondaryDriver"
          ></app-order-outsourcing-driver>
        </onyx-data-widget>
      }
    </div>
  } @else {
    @let drivers =
      order().manualProposalData?.data ??
      order().engineProposalData ??
      order().assignedData;

    <app-drivers-tachograph
      [primaryDriver]="drivers?.primaryDriver"
      [secondaryDriver]="drivers?.secondaryDriver"
      type="order"
      format="full"
      (driverChange)="driver.set($event)"
    ></app-drivers-tachograph>
  }
</section>
