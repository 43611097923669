import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { PartialDeep } from 'type-fest';
import { CompanyData } from '../../../../../common/interfaces/data-providers/company-data';
import { CacheService } from '../../../../../common/services/cache.service';
import { DataProvidersService } from '../../../../../common/services/data-providers.service';
import { ContractorForm } from '../../contractor-form/contractor-form.component';
import { ContractorsRouterStateKey } from '../enums/contractors-router-state-key';
import { ContractorsStorageKey } from '../enums/contractors-storage-key';

export const addContractorResolver: ResolveFn<{
  form: PartialDeep<ContractorForm>;
  companyData: CompanyData | null;
}> = () => {
  const router = inject(Router);
  const cacheService = inject(CacheService);
  const dataProvidersService = inject(DataProvidersService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const country = state?.[ContractorsRouterStateKey.COUNTRY];
  const vatId = state?.[ContractorsRouterStateKey.VAT_ID];

  const cacheKey = `${ContractorsStorageKey.ADD_CONTRACTOR_FORM}-${country}-${vatId}`;
  const cache = cacheService.get<PartialDeep<ContractorForm>>(cacheKey, {
    keepOnNavigation: true,
  });

  return dataProvidersService.getCompanyData(country, vatId).pipe(
    catchError(() => of(null)),
    map((companyData) => ({
      form: cache ?? {
        companyProfile: {
          country,
          vatId,
          companyName: companyData?.companyName,
        },
        addresses: {
          mainAddress: companyData?.mainAddress,
        },
      },
      companyData,
    })),
  );
};
