<onyx-icon
  name="tooltip"
  [size]="16"
  [tabIndex]="0"
  [onyxTooltip]="tooltip()"
  [onyxTooltipColor]="color()"
  [onyxTooltipShowArrow]="showArrow()"
  [onyxTooltipTextAlign]="textAlign()"
  [onyxTooltipDelay]="delay()"
  [onyxTooltipEnabled]="enabled()"
></onyx-icon>
