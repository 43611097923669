<form [formGroup]="form()">
  <onyx-form-group
    [label]="label()"
    [controls]="form().controls[language()]"
    (labelClick)="nameControl.focus()"
  >
    <onyx-text-field
      [formControlName]="language()"
      [showErrors]="false"
      #nameControl
    ></onyx-text-field>

    <onyx-button type="outlined" color="black" (click)="toggleTranslations()">
      <onyx-icon
        left
        class="icon"
        [ngClass]="{ active: showTranslations() }"
        name="translations"
        [size]="16"
      ></onyx-icon>

      {{ 'labels.translations' | translate }}
    </onyx-button>
  </onyx-form-group>

  @if (showTranslations()) {
    <div class="translations">
      <div class="heading">
        <div class="buttons">
          <onyx-button
            type="outlined"
            color="black"
            size="s"
            [spinner]="loading()"
            (click)="!loading() ? suggestTranslations() : null"
          >
            {{ I18N + '.suggestTranslations' | translate }}
          </onyx-button>

          <onyx-button
            type="outlined"
            color="black"
            size="s"
            (click)="clearTranslations()"
          >
            {{ 'labels.clear' | translate }}
          </onyx-button>
        </div>

        <onyx-icon-button
          type="outlined"
          color="black"
          size="m"
          (click)="toggleTranslations()"
        >
          <onyx-icon name="arrow-alt-up" [size]="16"></onyx-icon>
        </onyx-icon-button>
      </div>

      <div class="content">
        @for (language of languages(); track language) {
          <onyx-text-field
            [formControlName]="language"
            [label]="DictionaryCode.LANGUAGE + '.' + language"
          ></onyx-text-field>
        }
      </div>
    </div>
  }
</form>
