import { Pipe, PipeTransform } from '@angular/core';
import { isNumber, isString } from 'lodash';
import { DateTime } from 'luxon';
import { CommonHelper } from '../helpers/common.helper';

export const DURATION_UNITS = ['d', 'h', 'm'] as const;

type DurationDate = string | number | DateTime;
export type DurationUnit = Exclude<(typeof DURATION_UNITS)[number], 'm'>;

@Pipe({
  name: 'duration',
  pure: false,
})
export class DurationPipe implements PipeTransform {
  public transform(
    start: DurationDate,
    options?: {
      format?: 'from' | 'to';
      end?: DurationDate;
      size?: 'xs' | 's' | 'm';
      units?: DurationUnit[];
      showPast?: boolean;
    },
  ): string {
    const { format = 'from', end = DateTime.now() } = options ?? {};
    const startDate = this.getDateTime(start);
    const endDate = this.getDateTime(end);

    const duration =
      format === 'from'
        ? endDate.diff(startDate, ['days', 'hours'])
        : startDate.diff(endDate, ['days', 'hours']);

    return CommonHelper.formatDuration(duration, options);
  }

  private getDateTime(date: DurationDate): DateTime {
    if (isNumber(date)) return DateTime.now().plus({ seconds: date });
    if (isString(date)) return DateTime.fromISO(date);
    return date;
  }
}
