import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  model,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDropdownDirective,
  OnyxInformationHeadingComponent,
  OnyxModalService,
  OnyxOptionsGroup,
  OnyxToastService,
} from '@onyx/angular';
import { map, Observable } from 'rxjs';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { DictionariesService } from '../../../../../common/services/dictionaries.service';
import { OrderPlanningMode } from '../../../common/enums/order-planning-mode';
import { OrderRejectReason } from '../../../common/enums/order-reject-reason';
import { Order } from '../../../common/interfaces/order';
import { OrdersService } from '../../../common/services/orders.service';
import {
  OtherReasonModalComponent,
  OtherReasonModalData,
  OtherReasonModalMode,
} from './other-reason-modal/other-reason-modal.component';

@Component({
  selector: 'app-order-modal-decision-buttons',
  imports: [
    OnyxButtonComponent,
    OnyxInformationHeadingComponent,
    TranslatePipe,
    OnyxDropdownDirective,
    AsyncPipe,
  ],
  templateUrl: './order-modal-decision-buttons.component.html',
  styleUrl: './order-modal-decision-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalDecisionButtonsComponent {
  protected readonly I18N = 'orders.orderModal';
  protected readonly rejectReasons$ = this.dictionariesService.getDictionary(
    DictionaryCode.ORDER_REJECT_REASON,
  );
  protected readonly rejectOptions$ = this.getOptions(
    OtherReasonModalMode.REJECT,
  );
  protected readonly changeOptions$ = this.getOptions(
    OtherReasonModalMode.CHANGE,
  );

  public tab = model<OrderPlanningMode>();
  public order = input.required<Order>();

  constructor(
    private toastService: OnyxToastService,
    private modalService: OnyxModalService,
    private ordersService: OrdersService,
    private dictionariesService: DictionariesService,
  ) {}

  protected accept(): void {
    this.ordersService.acceptVehicle(this.order().uuid).subscribe({
      next: () =>
        this.toastService.showSuccess(`${this.I18N}.toasts.vehicleAccepted`),
      error: (error) =>
        ValidationHelper.handleUnexpectedError(error, this.toastService),
    });
  }

  private change(reason: OrderRejectReason): void {
    this.handleReason(reason, OtherReasonModalMode.CHANGE);
  }

  private reject(reason: OrderRejectReason): void {
    this.handleReason(reason, OtherReasonModalMode.REJECT);
  }

  private handleReason(
    reason: OrderRejectReason,
    mode: OtherReasonModalMode,
  ): void {
    if (reason === OrderRejectReason.OTHER) {
      this.modalService
        .open<OtherReasonModalData>(OtherReasonModalComponent, {
          uuid: this.order().uuid,
          mode,
        })
        .result.subscribe((result) => {
          if (result && mode === OtherReasonModalMode.CHANGE) {
            this.tab.set(OrderPlanningMode.MANUAL);
          }
        });
      return;
    }

    this.ordersService
      .rejectVehicle(this.order().uuid, { reason, other: null })
      .subscribe({
        // TEMP: replace error => next, when BE ready
        error: () => {
          if (mode === OtherReasonModalMode.CHANGE) {
            this.tab.set(OrderPlanningMode.MANUAL);
          } else {
            this.toastService.showInformation(
              `${this.I18N}.toasts.vehicleRejected`,
            );
          }
        },
        // error: (error) =>
        //   ValidationHelper.handleUnexpectedError(error, this.toastService),
      });
  }

  private getOptions(
    action: OtherReasonModalMode,
  ): Observable<OnyxOptionsGroup<() => void>[]> {
    return this.rejectReasons$.pipe(
      map((reasons) => [
        {
          subheading: `${this.I18N}.rejectDropdown.subheading`,
          options: reasons.map((reason) => ({
            name: reason.name,
            value: () => this[action](reason.value),
          })),
        },
      ]),
    );
  }
}
