import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxSpinnerComponent } from '../../spinner';

@Component({
  selector: 'onyx-loading-banner',
  imports: [OnyxSpinnerComponent],
  templateUrl: './onyx-loading-banner.component.html',
  styleUrl: './onyx-loading-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxLoadingBannerComponent {
  public delay = input(true);
  public visible = input.required<boolean>();
}
