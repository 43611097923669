import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxSectionComponent, OnyxTextareaComponent } from '@onyx/angular';

type NoteFormGroup = ReturnType<(typeof NoteFormComponent)['buildForm']>;

@Component({
  selector: 'app-note-form',
  imports: [
    OnyxSectionComponent,
    TranslatePipe,
    ReactiveFormsModule,
    OnyxTextareaComponent,
  ],
  templateUrl: './note-form.component.html',
  styleUrl: './note-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteFormComponent {
  public form = input.required<NoteFormGroup>();
  public placeholder = input.required<string>();

  public static buildForm(fb: NonNullableFormBuilder) {
    return fb.group({
      content: fb.control<string | null>(null),
    });
  }
}
