import { Routes } from '@angular/router';
import { OnyxFormMode, OnyxTableRowSize } from '@onyx/angular';
import { importGuard } from '../../common/guards/import.guard';
import { POINTS_OF_INTEREST_IMPORT_COLUMNS } from './common/constants/points-of-interest-import-columns';
import { addPointOfInterestResolver } from './common/resolvers/add-point-of-interest.resolver';
import { editPointOfInterestResolver } from './common/resolvers/edit-point-of-interest.resolver';
import { importPointsOfInterestResolver } from './common/resolvers/import-points-of-interest.resolver';

export enum PointsOfInterestRoute {
  POINTS_OF_INTEREST_LIST = '/management-panel/points-of-interest',
  ADD_POINT_OF_INTEREST = '/management-panel/points-of-interest/add',
  IMPORT_RESULT = '/management-panel/points-of-interest/import',
  EDIT_POINT_OF_INTEREST = '/management-panel/points-of-interest/:uuid/edit',
}

export const POINTS_OF_INTEREST_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import(
        './points-of-interest-list/points-of-interest-list.component'
      ).then((m) => m.PointsOfInterestListComponent),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./point-of-interest-form/point-of-interest-form.component').then(
        (m) => m.PointOfInterestFormComponent,
      ),
    data: { mode: OnyxFormMode.ADD },
    resolve: { form: addPointOfInterestResolver },
  },
  {
    path: 'import',
    loadComponent: () =>
      import(
        '../../common/components/import/import-result/import-result.component'
      ).then((m) => m.ImportResultComponent),
    canActivate: [importGuard],
    data: {
      columns: POINTS_OF_INTEREST_IMPORT_COLUMNS,
      rowSize: OnyxTableRowSize.XL,
    },
    resolve: { import: importPointsOfInterestResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./point-of-interest-form/point-of-interest-form.component').then(
        (m) => m.PointOfInterestFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { form: editPointOfInterestResolver },
  },
] as const;
