<div class="container">
  <onyx-information-heading>
    {{ 'paymentsHistory' | i18n: I18N | translate }}
  </onyx-information-heading>

  <onyx-table
    [data]="paymentsHistoryData()"
    [columns]="ORDER_MODAL_PAYMENTS_HISTORY_COLUMNS"
    [rowOptions]="getPaymentsOptions.bind(this)"
    tableSize="s"
  ></onyx-table>
</div>
