import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxAddressInputComponent,
  OnyxCheckboxComponent,
  OnyxCustomAddress,
  OnyxFormGroupComponent,
  OnyxIconBoxComponent,
  OnyxIconComponent,
  OnyxInputLabelComponent,
  OnyxPhone,
  OnyxPhoneNumberInputComponent,
  OnyxRoutePoint,
  OnyxSuggestionsComponent,
  OnyxTextareaComponent,
  OnyxTextFieldComponent,
  OnyxTimepickerComponent,
  OnyxToggleComponent,
} from '@onyx/angular';
import { chain, isEqual, toArray } from 'lodash';
import { map } from 'rxjs';
import { BusinessHoursFormComponent } from '../../../../../common/components/forms/business-hours-form/business-hours-form.component';
import { TIME_RANGE_ALL_DAY } from '../../../../../common/constants/common/time-range-all-day';
import { SuggestionHelper } from '../../../../../common/helpers/suggestions.helper';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { OrderPointCategory } from '../../../common/enums/order-point-category';
import { OrderPointTypeByCategory } from '../../../common/enums/order-point-type';

export type OrderCheckpointFormGroup = ReturnType<
  typeof OrderCheckpointFormComponent.buildForm
>;

@Component({
  selector: 'app-order-checkpoint-form',
  imports: [
    ReactiveFormsModule,
    OnyxIconBoxComponent,
    OnyxIconComponent,
    TranslatePipe,
    OnyxAddressInputComponent,
    OnyxTextFieldComponent,
    OnyxTimepickerComponent,
    OnyxPhoneNumberInputComponent,
    OnyxInputLabelComponent,
    OnyxToggleComponent,
    OnyxFormGroupComponent,
    OnyxSuggestionsComponent,
    OnyxCheckboxComponent,
    OnyxTextareaComponent,
  ],
  templateUrl: './order-checkpoint-form.component.html',
  styleUrl: './order-checkpoint-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCheckpointFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.points';
  protected readonly TIME_RANGE_SUGGESTIONS =
    SuggestionHelper.getTimeRangeSuggestions();

  public form = input.required<OrderCheckpointFormGroup>();
  public compact = input(false);

  protected serviceTimeLabel = computed(
    () => `${this.I18N}.serviceTime.${this.form().controls.type.value}`,
  );

  protected isAlwaysOpen = signal(false);
  protected isSaturdayClosed = signal(false);
  protected isSundayClosed = signal(false);

  constructor(private injector: Injector) {}

  public ngOnInit(): void {
    const controls = this.form().controls;

    const businessHoursForm = controls.businessHours;
    const businessHours = toSignal(
      businessHoursForm.valueChanges.pipe(
        map(() => businessHoursForm.getRawValue()),
      ),
      {
        initialValue: businessHoursForm.getRawValue(),
        injector: this.injector,
      },
    );

    this.isAlwaysOpen.set(
      chain(businessHours())
        .toArray()
        .every((value) => isEqual(value, TIME_RANGE_ALL_DAY))
        .value(),
    );
    this.isSaturdayClosed.set(businessHours()?.saturday === null);
    this.isSundayClosed.set(businessHours()?.sunday === null);

    effect(
      () => {
        if (this.isSaturdayClosed() || this.isSundayClosed()) {
          this.isAlwaysOpen.set(false);
        }
      },
      { injector: this.injector },
    );

    effect(
      () => {
        ValidationHelper.toggleControls(
          businessHoursForm,
          !this.isAlwaysOpen(),
        );

        if (this.isAlwaysOpen()) {
          this.isSaturdayClosed.set(false);
          this.isSundayClosed.set(false);

          const controls = toArray(businessHoursForm.controls);
          for (const control of controls) {
            control.setValue(TIME_RANGE_ALL_DAY);
          }
        }

        const weekendControls = new Map([
          [businessHoursForm.controls.saturday, this.isSaturdayClosed()],
          [businessHoursForm.controls.sunday, this.isSundayClosed()],
        ]);
        for (const [control, disabled] of weekendControls) {
          if (disabled) ValidationHelper.disableControls(control);
        }
      },
      { injector: this.injector },
    );
  }

  protected setName(name: string): void {
    this.form().controls.name.setValue(name);
  }

  public static buildForm(
    fb: NonNullableFormBuilder,
    type: OrderPointTypeByCategory[OrderPointCategory.CHECKPOINT],
    includeInOrder: boolean,
    isAlwaysOpen: boolean,
  ) {
    return fb.group({
      uuid: fb.control<string | null>(null),
      category: fb.control(OrderPointCategory.CHECKPOINT as const),
      type: fb.control(type),
      includeInOrder: fb.control(includeInOrder),
      address: fb.control<OnyxCustomAddress | null>(null, [
        Validators.required,
      ]),
      name: fb.control<string | null>(null),
      serviceTime: fb.control<number | null>(null),
      driverNote: fb.control<string | null>(null),
      phone: fb.control<OnyxPhone | null>(null),
      businessHours: BusinessHoursFormComponent.buildForm(fb, isAlwaysOpen),
      route: fb.control<OnyxRoutePoint | null>(null),
    });
  }
}
