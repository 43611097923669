import { OnyxComponentInputs, OnyxTableColumn } from '@onyx/angular';
import { Duration } from 'luxon';
import { NumberCellComponent } from '../../../../../common/components/cells/number-cell/number-cell.component';
import { PhoneCellComponent } from '../../../../../common/components/cells/phone-cell/phone-cell.component';
import { StringCellComponent } from '../../../../../common/components/cells/string-cell/string-cell.component';
import { CommonHelper } from '../../../../../common/helpers/common.helper';
import { PointsOfInterestAssignedContractorsCellComponent } from '../cells/points-of-interest-assigned-contractors-cell/points-of-interest-assigned-contractors-cell.component';
import { PointsOfInterestLocationCellComponent } from '../cells/points-of-interest-location-cell/points-of-interest-location-cell.component';
import { PointsOfInterestOpeningHoursCellComponent } from '../cells/points-of-interest-opening-hours-cell/points-of-interest-opening-hours-cell.component';
import { PointsOfInterestImportData } from './points-of-interest-import-schema';

export const POINTS_OF_INTEREST_IMPORT_COLUMNS: OnyxTableColumn<
  PointsOfInterestImportData & { index: number }
>[] = [
  {
    required: true,
    id: 'index',
    name: 'labels.row',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.index,
      }),
    },
  },
  {
    id: 'name',
    name: 'labels.name',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.name,
      }),
    },
  },
  {
    id: 'address',
    name: 'labels.address',
    width: 'fill',
    component: {
      ref: PointsOfInterestLocationCellComponent,
      inputs: (
        data,
      ): OnyxComponentInputs<PointsOfInterestLocationCellComponent> => ({
        address: data.item.address,
      }),
    },
  },
  {
    id: 'openingHours',
    name: 'labels.openingHours',
    width: 'fill',
    component: {
      ref: PointsOfInterestOpeningHoursCellComponent,
      inputs: (
        data,
      ): OnyxComponentInputs<PointsOfInterestOpeningHoursCellComponent> => ({
        businessHours: data.item.businessHours,
      }),
    },
  },
  {
    id: 'phone',
    name: 'labels.phone',
    width: 'fill',
    component: {
      ref: PhoneCellComponent,
      inputs: (data): OnyxComponentInputs<PhoneCellComponent> => ({
        phone: data.item.phone,
      }),
    },
  },
  {
    id: 'averageServiceTime',
    name: 'labels.averageServiceTime',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: CommonHelper.formatDuration(
          Duration.fromObject({ seconds: data.item.averageServiceTime }),
          { size: 'm', units: ['h'] },
        ),
      }),
    },
  },
  {
    id: 'note',
    name: 'labels.note',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.note,
      }),
    },
  },
  {
    id: 'assignedContractors',
    name: 'labels.assignedContractors',
    width: 'fill',
    component: {
      ref: PointsOfInterestAssignedContractorsCellComponent,
      inputs: (
        data,
      ): OnyxComponentInputs<PointsOfInterestAssignedContractorsCellComponent> => ({
        contractors: data.item.assignedContractors,
      }),
    },
  },
];
