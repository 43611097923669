import { Routes } from '@angular/router';
import { engineConfigResolver } from './common/resolvers/engine-config.resolver';

export enum EngineConfigRoute {
  ENGINE_CONFIG = '/management-panel/engine-config',
}

export const ENGINE_CONFIG_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./engine-config/engine-config.component').then(
        (m) => m.EngineConfigComponent,
      ),
    resolve: { engineConfig: engineConfigResolver },
  },
] as const;
