import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OrderPointCategory } from '../../../../../dashboard/orders/common/enums/order-point-category';
import { OrderCheckpointFormComponent } from '../../../../../dashboard/orders/order-form/order-points-form/order-checkpoint-form/order-checkpoint-form.component';
import { OrderPointFormGroup } from '../../../../../dashboard/orders/order-form/order-points-form/order-point-form/order-point-form.component';
import { OrderTransitFormComponent } from '../../../../../dashboard/orders/order-form/order-points-form/order-transit-form/order-transit-form.component';

@Component({
  selector: 'app-route-point-form',
  standalone: true,
  imports: [OrderCheckpointFormComponent, OrderTransitFormComponent],
  templateUrl: './route-point-form.component.html',
  styleUrl: './route-point-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutePointFormComponent {
  protected readonly OrderPointCategory = OrderPointCategory;

  public category = input.required<OrderPointCategory>();
  public form = input.required<OrderPointFormGroup>();
}
