import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
} from '@onyx/angular';
import { DriversTachographComponent } from '../../../../../common/components/drivers-tachograph/drivers-tachograph.component';
import { SimplifiedDriver } from '../../../../drivers/common/interfaces/driver';
import { OrderOutsourcingDriverComponent } from '../../../common/components/order-outsourcing-driver/order-outsourcing-driver.component';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-drivers',
  imports: [
    DriversTachographComponent,
    OnyxDataWidgetComponent,
    TranslatePipe,
    OnyxIconComponent,
    OnyxInformationHeadingComponent,
    OrderOutsourcingDriverComponent,
  ],
  templateUrl: './order-modal-drivers.component.html',
  styleUrl: './order-modal-drivers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalDriversComponent {
  public order = input.required<Order>();

  protected driver = signal<SimplifiedDriver | null>(null);
}
