import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import Intercom, {
  shutdown as shutdownIntercom,
} from '@intercom/messenger-js-sdk';
import { OnyxNamePipe, OnyxPhonePipe } from '@onyx/angular';
import { setUser as setSentryUser } from '@sentry/angular-ivy';
import { omit } from 'lodash';
import { EMPTY, Observable, Subject, catchError, map, tap } from 'rxjs';
import { UserSettingsScope } from '../../../common/enums/user-settings-scope';
import { ConflictHelper } from '../../../common/helpers/conflict.helper';
import { BusinessDocuments } from '../../../common/interfaces/documents/business-documents';
import { ApiService } from '../../../common/services/api.service';
import { LanguageService } from '../../../common/services/language.service';
import { DashboardPermission } from '../../../dashboard/common/enums/dashboard-permission';
import { Business } from '../../../dashboard/management-panel/business/common/interfaces/business';
import { EngineConfig } from '../../../dashboard/management-panel/engine-config/common/interfaces/engine-config';
import { AuthRoute } from '../../auth.routes';
import { ForgotPasswordForm } from '../../forgot-password/forgot-password.component';
import { JoinForm } from '../../join/join.component';
import { LoginForm } from '../../login/login.component';
import { RegisterCompanyForm } from '../../register-company/register-company.component';
import { RegisterForm } from '../../register/register.component';
import { ResetPasswordForm } from '../../reset-password/reset-password.component';
import { SupportForm } from '../../support/support.component';
import { AUTH_SENSITIVE_KEYS, AuthStorageKey } from '../enums/auth-storage-key';
import { AUTH_INTERCEPTOR_NAMESPACE_HEADER } from '../interceptors/auth.interceptor';
import { AuthData } from '../interfaces/auth-data';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiService {
  private _companyName = signal<string | null>(null);
  public get companyName() {
    return this._companyName.asReadonly();
  }

  private _token = signal<string | null>(null);
  public get token() {
    return this._token.asReadonly();
  }

  private _user = signal<User | null>(null);
  public get user() {
    return this._user.asReadonly();
  }

  private _business = signal<Business | null>(null);
  public get business() {
    return this._business.asReadonly();
  }

  private _documents = signal<BusinessDocuments | null>(null);
  public get documents() {
    return this._documents.asReadonly();
  }

  private _engineConfig = signal<EngineConfig | null>(null);
  public get engineConfig() {
    return this._engineConfig.asReadonly();
  }

  private permissions = signal<DashboardPermission[] | null>(null);

  private _loggedOut$ = new Subject<void>();
  public get loggedOut$() {
    return this._loggedOut$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private router: Router,
    private languageService: LanguageService,
    private conflictHelper: ConflictHelper,
    private phonePipe: OnyxPhonePipe,
    private namePipe: OnyxNamePipe,
  ) {
    super(http);
    this.loadData();
  }

  public login(form: LoginForm): Observable<void> {
    const companyName = form.companyName;
    const body = omit(form, 'companyName');

    return this.post<AuthData>('/login', body, {
      headers: {
        ...(companyName && {
          [AUTH_INTERCEPTOR_NAMESPACE_HEADER]: companyName,
        }),
      },
    }).pipe(
      catchError((error) => {
        if (error.status === 0) return this.post<AuthData>('/login', body);
        throw error;
      }),
      tap((data) => {
        this.saveData(data, companyName);
        this.languageService.setLanguage(data.user.interfaceLanguage);
      }),
      map(() => undefined),
    );
  }

  public activateAccount(token: string): Observable<void> {
    return this.post<AuthData>(`/activate-account/${token}`, undefined).pipe(
      tap((data) => this.saveData(data, null)),
      map(() => undefined),
    );
  }

  public register(form: RegisterForm): Observable<void> {
    return this.post('/register', form);
  }

  public registerBusinessData(form: RegisterCompanyForm): Observable<void> {
    return this.post<Business>('/complete-business-data', form).pipe(
      tap((business) => this.updateBusiness(business)),
      map(() => undefined),
    );
  }

  public joinCompany(
    form: JoinForm,
    companyId: string,
    token: string,
  ): Observable<void> {
    return this.put<AuthData>(`/employees/activate/${token}`, form, {
      headers: { [AUTH_INTERCEPTOR_NAMESPACE_HEADER]: companyId },
    }).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status !== HttpStatusCode.BadRequest) throw response;

        if (
          this.conflictHelper.handleConflictErrors(response, [
            {
              key: 'phone',
              message: 'auth.join.numberRegistered',
              params: {
                phone: this.phonePipe.transform(form.phone),
              },
            },
          ])
        ) {
          return EMPTY;
        }

        throw response;
      }),
      map(() => undefined),
    );
  }

  public registerDocuments(
    form: Omit<BusinessDocuments, 'status'>,
  ): Observable<void> {
    return this.post<BusinessDocuments>('/upload-documents', form).pipe(
      tap((documents) => {
        const business = { ...this._business()!, documents };
        this.updateBusiness(business);
      }),
      map(() => undefined),
    );
  }

  public sendContactMessage(form: SupportForm): Observable<void> {
    return this.post<void>('/send-contact-message', form);
  }

  public logout(redirect = true): void {
    this.clearData();
    this._loggedOut$.next();
    if (redirect) this.router.navigateByUrl(AuthRoute.LOGIN);
  }

  public recoverPassword(form: ForgotPasswordForm): Observable<void> {
    const companyName = form.companyName;
    const body = omit(form, 'companyName');

    return this.post('/recover-password', body, {
      headers: { [AUTH_INTERCEPTOR_NAMESPACE_HEADER]: companyName },
    });
  }

  public resetPassword(
    form: ResetPasswordForm,
    token: string,
    companyId: string,
  ): Observable<void> {
    return this.post(`/reset-password/${token}`, form, {
      headers: {
        ...(companyId && {
          [AUTH_INTERCEPTOR_NAMESPACE_HEADER]: companyId,
        }),
      },
    });
  }

  public hasPermission(permission: DashboardPermission): boolean {
    const permissions = this.permissions() ?? [];
    return permissions.includes(permission);
  }

  public setCompanyName(companyName: string): void {
    this._companyName.set(companyName);
    localStorage.setItem(AuthStorageKey.COMPANY_NAME, this._companyName()!);
  }

  public updateUser(user: Partial<User> | null): void {
    if (user == null) {
      this._user.set(null);
      localStorage.removeItem(AuthStorageKey.USER);
      setSentryUser(null);
      shutdownIntercom();
      return;
    }

    this._user.update((current) => ({ ...current, ...user }) as User);
    user = this._user()!;

    localStorage.setItem(AuthStorageKey.USER, JSON.stringify(user));
    setSentryUser({
      id: user.uuid,
      email: user.email,
      username: `${user.firstName} ${user.lastName}`,
      roles: user.roles,
      business: this._companyName(),
    });
    /* eslint-disable camelcase */
    Intercom({
      app_id: 'lsm17xih',
      user_id: user.uuid,
      name: this.namePipe.transform(user),
      email: user.email,
      created_at: undefined,
      business: this._companyName(),
      roles: user.roles,
      phone: this.phonePipe.transform(user.phone),
    });
  }

  public updateBusiness(business: Partial<Business>): void {
    this._business.update(
      (current) => ({ ...current, ...business }) as Business,
    );
    localStorage.setItem(
      AuthStorageKey.BUSINESS,
      JSON.stringify(this._business()),
    );
  }

  public updateDocuments(documents: Partial<BusinessDocuments>): void {
    this._documents.update(
      (current) => ({ ...current, ...documents }) as BusinessDocuments,
    );
    localStorage.setItem(
      AuthStorageKey.DOCUMENTS,
      JSON.stringify(this._documents()),
    );
  }

  public updateEngineConfig(engineConfig: Partial<EngineConfig>): void {
    this._engineConfig.update(
      (current) => ({ ...current, ...engineConfig }) as EngineConfig,
    );
    localStorage.setItem(
      AuthStorageKey.ENGINE_CONFIG,
      JSON.stringify(this._engineConfig()),
    );
  }

  private loadData(): void {
    const companyName = localStorage.getItem(AuthStorageKey.COMPANY_NAME);
    this._companyName.set(companyName);

    const [token, user, business, documents, engineConfig, permissions] = [
      localStorage.getItem(AuthStorageKey.TOKEN),
      localStorage.getItem(AuthStorageKey.USER),
      localStorage.getItem(AuthStorageKey.BUSINESS),
      localStorage.getItem(AuthStorageKey.DOCUMENTS),
      localStorage.getItem(AuthStorageKey.ENGINE_CONFIG),
      localStorage.getItem(AuthStorageKey.PERMISSIONS),
    ];
    if (!token || !user || !documents || !engineConfig || !permissions) return;

    this._token.set(token);
    this.updateUser(JSON.parse(user));
    this._business.set(business ? JSON.parse(business) : null);
    this._documents.set(documents ? JSON.parse(documents) : null);
    this._engineConfig.set(engineConfig ? JSON.parse(engineConfig) : null);
    this.permissions.set(JSON.parse(permissions));

    this.languageService.setLanguage(this.user()!.interfaceLanguage);
  }

  private saveData(authData: AuthData, companyName: string | null): void {
    this._companyName.set(companyName);
    this._token.set(authData.token);
    this.updateUser(authData.user);
    this._business.set({ ...authData.business, branches: authData.branches });
    this._documents.set(authData.documents);
    this._engineConfig.set(authData.engineConfig);
    this.permissions.set(authData.permissions);

    companyName
      ? localStorage.setItem(AuthStorageKey.COMPANY_NAME, companyName)
      : localStorage.removeItem(AuthStorageKey.COMPANY_NAME);

    localStorage.setItem(AuthStorageKey.TOKEN, this._token()!);
    localStorage.setItem(AuthStorageKey.USER, JSON.stringify(this._user()));
    localStorage.setItem(
      AuthStorageKey.BUSINESS,
      JSON.stringify(this._business()),
    );
    localStorage.setItem(
      AuthStorageKey.DOCUMENTS,
      JSON.stringify(this._documents()),
    );
    localStorage.setItem(
      AuthStorageKey.ENGINE_CONFIG,
      JSON.stringify(this._engineConfig()),
    );
    localStorage.setItem(
      AuthStorageKey.PERMISSIONS,
      JSON.stringify(this.permissions()),
    );
  }

  private clearData(): void {
    const keys = [...AUTH_SENSITIVE_KEYS, ...Object.values(UserSettingsScope)];
    for (const key of keys) {
      localStorage.removeItem(key);
    }

    this._token.set(null);
    this.updateUser(null);
    this._business.set(null);
    this._documents.set(null);
    this._engineConfig.set(null);
    this.permissions.set(null);
  }
}
