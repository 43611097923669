import { OnyxComponentInputs, OnyxTableColumn } from '@onyx/angular';
import { BadgesCellComponent } from '../../../../../common/components/cells/badges-cell/badges-cell.component';
import { StringCellComponent } from '../../../../../common/components/cells/string-cell/string-cell.component';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ContractorType } from '../../../contractors/common/enums/contractor-type';
import { PointsOfInterestContractorCellComponent } from '../cells/points-of-interest-contractor-cell/points-of-interest-contractor-cell.component';
import { PointOfInterest } from '../interfaces/point-of-interest';

export const MODAL_ASSIGNED_CONTRACTORS_COLUMNS: OnyxTableColumn<
  PointOfInterest['contractors'][number]
>[] = [
  {
    id: 'contractor',
    name: 'contractors.contractor',
    width: 'fill',
    component: {
      ref: PointsOfInterestContractorCellComponent,
      inputs: (
        data,
      ): OnyxComponentInputs<PointsOfInterestContractorCellComponent> => ({
        contractor: data.item.contractor,
      }),
    },
  },
  {
    id: 'vatId',
    name: 'labels.vatId',
    width: '110px',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: `${data.item.contractor.companyProfile.country.toUpperCase()} ${data.item.contractor.companyProfile.vatId}`,
      }),
    },
  },
  {
    id: 'contractorType',
    name: 'labels.contractorType',
    width: '150px',
    component: {
      ref: BadgesCellComponent,
      inputs: (
        data,
      ): OnyxComponentInputs<BadgesCellComponent<ContractorType>> => ({
        badges: data.item.contractor.companyProfile.types,
        dictionaryCode: DictionaryCode.CONTRACTOR_TYPE,
      }),
    },
  },
  {
    id: 'gate',
    name: 'labels.gate',
    width: '100px',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.gate,
      }),
    },
  },
];
