import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnyxDatePipe, OnyxLanguagePipe, OnyxOption } from '@onyx/angular';
import { chain } from 'lodash';
import { DateTime } from 'luxon';
import { DOCUMENT_EXPIRATION_WARNING_THRESHOLD } from '../../../../common/constants/common/document-expiration-warning-threshold';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { ValidationSeverity } from '../../../../common/enums/validation-severity';
import { ColorHelper } from '../../../../common/helpers/color.helper';
import {
  ValidationConditionsHelper,
  ValidationContext,
} from '../../../../common/helpers/validation-conditions.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { ValidationIssue } from '../../../../common/interfaces/validation/validation-issue';
import { ValidationResult } from '../../../../common/interfaces/validation/validation-result';
import { FleetIdentifierPipe } from '../../../../common/pipes/fleet-identifier.pipe';
import { JoinPipe } from '../../../../common/pipes/join.pipe';
import { Fleet } from '../../../fleet/common/interfaces/fleet';
import { Driver } from '../interfaces/driver';
import { DriverHelper } from './driver.helper';

@Injectable({
  providedIn: 'root',
})
export class DriverValidationHelper {
  private readonly I18N = 'issues';

  constructor(
    private translateService: TranslateService,
    private joinPipe: JoinPipe,
    private languagePipe: OnyxLanguagePipe,
    private datePipe: OnyxDatePipe,
    private fleetIdentifierPipe: FleetIdentifierPipe,
    private validationConditionsHelper: ValidationConditionsHelper,
  ) {}

  public validate(data: {
    context: ValidationContext;
    driver: Driver;
    options: {
      vehicle?: Fleet;
      trailer?: Fleet;
      option?: OnyxOption<Driver>;
    };
  }): ValidationResult<Driver> {
    const { driver, options, context } = data;
    const { vehicle, option, trailer } = options;
    const issues: ValidationIssue[] = [];

    this.checkAssignedDriver(driver, issues, vehicle);
    this.validationConditionsHelper.checkSkills(driver, issues, vehicle);
    this.checkUnavailability(driver, issues);
    this.validationConditionsHelper.checkAdr(driver, issues, vehicle);
    this.validationConditionsHelper.checkCountries(context, issues, {
      driver,
      vehicle,
      trailer,
    });
    this.checkContractEnd(driver, issues);
    this.checkVisas(driver, issues);
    this.checkDocuments(driver, issues);
    this.checkPermissions(driver, issues);
    this.validationConditionsHelper.checkRequiredDrivingLicense(
      context,
      driver,
      issues,
      { vehicle, trailer },
    );
    this.validationConditionsHelper.checkDrivingLicenseExpiryDate(
      context,
      driver,
      issues,
      { vehicle, trailer },
    );

    return ValidationHelper.mapIssues(issues, option);
  }

  private checkAssignedDriver(
    driver: Driver,
    issues: ValidationIssue[],
    vehicle?: Fleet,
  ): void {
    if (
      vehicle &&
      driver.assignedVehicle &&
      driver.assignedVehicle.uuid !== vehicle.uuid
    ) {
      issues.push({
        message: `${this.I18N}.driverIsAssigned`,
        data: {
          vehicle: this.fleetIdentifierPipe.transform(driver.assignedVehicle),
        },
        severity: ValidationSeverity.WARNING,
      });
    }
  }

  private checkUnavailability(driver: Driver, issues: ValidationIssue[]): void {
    if (!driver.unavailability) return;

    issues.push({
      message: `${this.I18N}.driverUnavailable`,
      data: {
        date: this.datePipe.transform(
          driver.unavailability.dateRange.to!,
          'date-dot',
        ),
      },
      severity: ValidationSeverity.WARNING,
    });
  }

  private checkContractEnd(driver: Driver, issues: ValidationIssue[]): void {
    const contractEnd = driver.employmentConditions.contractTerm?.to;
    if (contractEnd) {
      const expiryDate = DateTime.fromISO(contractEnd);
      const days = expiryDate.diffNow('days').days;

      const message =
        days <= 0
          ? `${this.I18N}.contract.contractExpired`
          : days <= DOCUMENT_EXPIRATION_WARNING_THRESHOLD
            ? `${this.I18N}.contract.contractExpires`
            : null;
      if (!message) return;

      issues.push({
        message,
        data: { date: this.datePipe.transform(contractEnd, 'date-dot') },
        severity:
          days <= 0 ? ValidationSeverity.ERROR : ValidationSeverity.WARNING,
      });
    }
  }

  private checkVisas(driver: Driver, issues: ValidationIssue[]): void {
    const visaStatuses = this.validationConditionsHelper.getExpiryStatuses(
      driver.visas,
      (visa) => visa.expirationDate,
      ColorHelper.getCellColor,
    );
    if (!visaStatuses) return;

    const checkVisaByStatus = (
      items: typeof visaStatuses.errors | typeof visaStatuses.warnings,
      single: string,
      multiple: string,
    ) =>
      this.validationConditionsHelper.addExpiryIssue(
        items,
        `${this.I18N}.visas.${single}`,
        `${this.I18N}.visas.${multiple}`,
        issues,
        {
          forceSeverity: ValidationSeverity.WARNING,
          getData: (item) => ({
            visa: this.translateService.instant(
              `${DictionaryCode.COUNTRY}.${item.country}`,
            ),
            date: this.datePipe.transform(item.expirationDate!, 'date-dot'),
          }),
          getListData: (items) => ({
            visas: this.joinPipe.transform(
              items.map((item) => item.country),
              ', ',
              3,
              DictionaryCode.COUNTRY,
            ),
          }),
        },
      );

    checkVisaByStatus(visaStatuses.errors, 'visaExpired', 'visasExpired');
    checkVisaByStatus(visaStatuses.warnings, 'visaExpires', 'visasExpires');
  }

  private checkDocuments(driver: Driver, issues: ValidationIssue[]): void {
    const documentStatuses = this.validationConditionsHelper.getExpiryStatuses(
      DriverHelper.getDriverExpiryDocuments(driver),
      (status) => status.expirationDate,
      ColorHelper.getCellColor,
    );
    if (!documentStatuses) return;

    const checkDocumentByStatus = (
      items: typeof documentStatuses.errors | typeof documentStatuses.warnings,
      single: string,
      multiple: string,
    ) =>
      this.validationConditionsHelper.addExpiryIssue(
        items,
        `${this.I18N}.documents.${single}`,
        `${this.I18N}.documents.${multiple}`,
        issues,
        {
          getData: (item) => ({
            document: this.translateService.instant(`labels.${item.key}`),
            date: this.datePipe.transform(item.expirationDate!, 'date-dot'),
          }),
        },
      );

    checkDocumentByStatus(
      documentStatuses.errors,
      'documentExpired',
      'documentsExpired',
    );
    checkDocumentByStatus(
      documentStatuses.warnings,
      'documentExpires',
      'documentsExpires',
    );
  }

  private checkPermissions(driver: Driver, issues: ValidationIssue[]): void {
    const permissionStatuses = chain(driver.permissions)
      .filter((permission) => permission.expirationDate != null)
      .thru((permissions) =>
        this.validationConditionsHelper.getExpiryStatuses(
          permissions,
          (permission) => permission.expirationDate,
          ColorHelper.getCellColor,
        ),
      )
      .value();
    if (!permissionStatuses) return;

    const checkPermissionByStatus = (
      items:
        | typeof permissionStatuses.errors
        | typeof permissionStatuses.warnings,
      single: string,
      multiple: string,
    ) =>
      this.validationConditionsHelper.addExpiryIssue(
        items,
        `${this.I18N}.permissions.${single}`,
        `${this.I18N}.permissions.${multiple}`,
        issues,
        {
          getData: (item) => ({
            permission: this.languagePipe.transform(item.type.names),
            date: this.datePipe.transform(item.expirationDate!, 'date-dot'),
          }),
          getListData: (items) => ({
            permissions: this.joinPipe.transform(
              items.map((item) => this.languagePipe.transform(item.type.names)),
              ', ',
              3,
              null,
            ),
          }),
        },
      );

    checkPermissionByStatus(
      permissionStatuses.errors,
      'permissionExpired',
      'permissionsExpired',
    );
    checkPermissionByStatus(
      permissionStatuses.warnings,
      'permissionExpires',
      'permissionsExpires',
    );
  }
}
