import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { OnyxTimeRange } from '@onyx/angular';
import { OrderPointTimeWindowFormGroup } from '../../order-form/order-points-form/order-point-form/order-point-base-form/order-point-time-window-form/order-point-time-window-form.component';

export const orderPointTimeWindowOverlap: ValidatorFn = (
  formArray: AbstractControl<OrderPointTimeWindowFormGroup>,
): ValidationErrors | null => {
  if (!(formArray instanceof FormArray)) return null;

  const windows: { date: string; timeRange: OnyxTimeRange }[] = [];
  for (const control of formArray.controls) {
    const timeWindowControl = control as OrderPointTimeWindowFormGroup;
    const timeWindow = timeWindowControl.getRawValue();

    const date = timeWindow.date!;
    const timeRange = {
      from: timeWindow.timeRange?.from ?? timeWindow.time!,
      to: timeWindow.timeRange?.to ?? timeWindow.time!,
    };
    if (!date || !timeRange.from || !timeRange.to) continue;

    const isValid = windows.every(
      (window) =>
        window.date !== date ||
        window.timeRange.to < timeRange.from ||
        window.timeRange.from > timeRange.to,
    );
    timeWindowControl.setErrors(
      isValid ? null : { orderPointTimeWindowOverlap: true },
    );

    windows.push({
      date,
      timeRange: timeRange as OnyxTimeRange,
    });
  }

  return null;
};
