import { AsyncPipe, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  signal,
} from '@angular/core';
import {
  OnyxAmountPipe,
  OnyxDistancePipe,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxRouteSummary,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { RouteSubpoint } from '../../../../interfaces/route/route-subpoint';
import { DurationPipe } from '../../../../pipes/duration.pipe';
import { DictionariesService } from '../../../../services/dictionaries.service';
import { RouteService } from '../../../../services/route.service';

export type RoutePointRouteSummary = Pick<
  OnyxRouteSummary,
  'distance' | 'duration' | 'fuelConsumption' | 'totalCost'
>;

@Component({
  selector: 'app-route-point-route-summary',
  imports: [
    OnyxDistancePipe,
    DurationPipe,
    OnyxIconComponent,
    DecimalPipe,
    AsyncPipe,
    OnyxAmountPipe,
    OnyxIconButtonComponent,
    OnyxTooltipDirective,
    OnyxDropdownDirective,
  ],
  templateUrl: './route-point-route-summary.component.html',
  styleUrl: './route-point-route-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutePointRouteSummaryComponent {
  protected readonly SHOW_ADD = this.routeService.add$.observed;
  protected readonly addOptions$ = this.routeService.addOptions$;

  public routeSummary = input.required<RoutePointRouteSummary | null>();
  public index = input.required<number>();
  public subpoints = input<RouteSubpoint[]>();
  public subpointsExpanded = input<boolean>();

  protected actionsAttached = signal(false);

  protected fuelUnit$ = computed(() => {
    const config = this.routeService.config();
    return this.dictionariesService.getFuelUnit(config?.fuelType);
  });

  constructor(
    private routeService: RouteService,
    private dictionariesService: DictionariesService,
  ) {}

  protected toggleSubpoints(): void {
    this.routeService.togglePointExpanded(this.index());
  }
}
