<onyx-modal
  type="center"
  [heading]="I18N + '.' + data.mode + 'VehicleParameter'"
  size="m"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <app-dictionaries-names-translation-form
    [form]="form.controls.names"
    label="labels.parameterName"
    [initialValue]="data.mode === OnyxFormMode.ADD ? data.name : undefined"
  ></app-dictionaries-names-translation-form>

  <ng-container bottomRightOptions>
    <onyx-button color="black" type="outlined" (click)="close$.next()">
      {{ 'labels.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{
        'labels.' + (data.mode === OnyxFormMode.ADD ? 'add' : 'saveChanges')
          | translate
      }}
    </onyx-button>
  </ng-container>
</onyx-modal>
