import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDataWidgetComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { AddressComponent } from '../../../../../../common/components/address/address.component';
import { OrderPointCategory } from '../../../../common/enums/order-point-category';
import { OrdersQueryParamKey } from '../../../../common/enums/orders-query-param-key';
import { OrderHelper } from '../../../../common/helpers/order.helper';
import { Order } from '../../../../common/interfaces/order';
import {
  OrderLoadingPoint,
  OrderUnloadingPoint,
} from '../../../../common/interfaces/order-point';

@Component({
  selector: 'app-order-modal-route-summary',
  imports: [
    OnyxDataWidgetComponent,
    OnyxInformationHeadingComponent,
    TranslatePipe,
    AddressComponent,
    OnyxButtonComponent,
    OnyxIconComponent,
    OnyxTooltipDirective,
  ],
  templateUrl: './order-modal-route-summary.component.html',
  styleUrl: './order-modal-route-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalRouteSummaryComponent {
  public order = input.required<Order>();
  public close$ = input.required<Subject<void>>();

  protected hasRouteEdit = computed(() =>
    this.orderHelper.hasRouteEdit(this.order()),
  );

  protected loadingPoints = computed(() =>
    this.getPointsData(OrderPointCategory.LOADING),
  );
  protected unloadingPoints = computed(() =>
    this.getPointsData(OrderPointCategory.UNLOADING),
  );

  constructor(private orderHelper: OrderHelper) {}

  protected getPointsData<
    Category extends OrderPointCategory.LOADING | OrderPointCategory.UNLOADING,
    Point = Category extends OrderPointCategory.LOADING
      ? OrderLoadingPoint
      : OrderUnloadingPoint,
  >(
    category: Category,
  ): {
    point: Point;
    count: number;
  } {
    const points = this.order().points.filter(
      (point) => point.category === category,
    );

    if (category === OrderPointCategory.LOADING) {
      return { point: points[0] as Point, count: points.length };
    }
    return {
      point: points.at(-1) as Point,
      count: points.length,
    };
  }

  protected planRoute(): void {
    this.orderHelper.edit(this.order().uuid, {
      options: { close$: this.close$() },
      params: { [OrdersQueryParamKey.PLAN_ROUTE]: true },
    });
  }
}
