import {
  AsyncPipe,
  NgClass,
  NgComponentOutlet,
  NgStyle,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  InjectionToken,
  TemplateRef,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { isString } from 'lodash';
import { Observable } from 'rxjs';
import {
  OnyxTooltipContext,
  OnyxTooltipTemplateContext,
} from '../../../components';

export interface OnyxTooltipConfig {
  color: OnyxTooltipColor;
  context: OnyxTooltipContext;
  templateContext: OnyxTooltipTemplateContext;
  showArrow: boolean;
  textAlign: 'left' | 'center' | 'right';
  arrowPosition$: Observable<{ x: number; y: number }>;
  close: () => void;
}

export const ONYX_TOOLTIP_CONFIG = new InjectionToken<OnyxTooltipConfig>(
  'Tooltip configuration',
);

export type OnyxTooltipColor = 'black' | 'white';

@Component({
  selector: 'onyx-tooltip-overlay',
  imports: [
    AsyncPipe,
    NgStyle,
    NgTemplateOutlet,
    TranslatePipe,
    NgClass,
    NgComponentOutlet,
  ],
  templateUrl: './onyx-tooltip-overlay.component.html',
  styleUrls: ['./onyx-tooltip-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTooltipOverlayComponent {
  protected readonly isString = isString;

  constructor(
    @Inject(ONYX_TOOLTIP_CONFIG) public readonly config: OnyxTooltipConfig,
  ) {}

  @HostListener('mouseleave')
  public close(): void {
    this.config.close();
  }

  protected isTemplateRef(
    context: OnyxTooltipContext,
  ): context is TemplateRef<any> {
    return context instanceof TemplateRef;
  }
}
