import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxInformationHeadingComponent } from '@onyx/angular';
import { OrderStatus } from '../../../../common/enums/order-status';
import { OrderHelper } from '../../../../common/helpers/order.helper';
import { Order } from '../../../../common/interfaces/order';
import { OrderModalPlanDurationComponent } from './order-modal-plan-duration/order-modal-plan-duration.component';
import { OrderModalPlanFinancesComponent } from './order-modal-plan-finances/order-modal-plan-finances.component';
import { OrderModalPlanKilometersComponent } from './order-modal-plan-kilometers/order-modal-plan-kilometers.component';
import { OrderModalPlanTollsComponent } from './order-modal-plan-tolls/order-modal-plan-tolls.component';

@Component({
  selector: 'app-order-modal-plan',
  imports: [
    OnyxInformationHeadingComponent,
    TranslatePipe,
    OrderModalPlanKilometersComponent,
    OrderModalPlanTollsComponent,
    OrderModalPlanDurationComponent,
    OrderModalPlanFinancesComponent,
  ],
  templateUrl: './order-modal-plan.component.html',
  styleUrl: './order-modal-plan.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPlanComponent {
  protected readonly I18N = 'orders.orderModal.plan';

  public order = input.required<Order>();

  protected heading = computed(() => {
    const status = this.order().status.value;

    if (status === OrderStatus.IN_PROGRESS) {
      return `${this.I18N}.inProgress`;
    } else if (OrderHelper.isOrderPending(status)) {
      return `${this.I18N}.orderPlan`;
    }

    return `${this.I18N}.realizationResult`;
  });
}
