import { computed, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { isNumber } from 'lodash';
import { map, shareReplay, Subject, withLatestFrom } from 'rxjs';
import { OrderPointCategory } from '../../dashboard/orders/common/enums/order-point-category';
import { OrderPointType } from '../../dashboard/orders/common/enums/order-point-type';
import { OrderPoint } from '../../dashboard/orders/common/interfaces/order-point';
import {
  OrderPointForm,
  OrderPointFormGroup,
} from '../../dashboard/orders/order-form/order-points-form/order-point-form/order-point-form.component';
import { RoutePoint } from '../components/route/route-point/route-point.component';
import { DictionaryCode, DictionaryType } from '../enums/dictionary-code';
import { RoutePointState } from '../enums/route/route-point-state';
import { RouteConfig } from '../interfaces/address/calculate-route-request';
import { DictionariesService } from './dictionaries.service';

type RoutePointKey = OrderPoint | OrderPointFormGroup;

@Injectable()
export class RouteService {
  public points = signal<RoutePoint[]>([]);
  public config = signal<RouteConfig | null>(null);
  public isDedicated = signal(true);

  public add$ = new Subject<{
    point: DictionaryType<DictionaryCode.ORDER_POINT_TYPE>[number];
    index: number;
  }>();
  public move$ = new Subject<{ index: number; direction: -1 | 1 }>();
  public edit$ = new Subject<{ index: number }>();
  public remove$ = new Subject<{ index: number }>();

  public addOptions$ = this.dictionariesService
    .getDictionary(DictionaryCode.ORDER_POINT_TYPE)
    .pipe(
      withLatestFrom(toObservable(this.isDedicated)),
      map(([options, isDedicated]) => {
        if (isDedicated) return options;
        return options.filter((option) =>
          [
            OrderPointType.LOADING,
            OrderPointType.UNLOADING,
            OrderPointType.CUSTOMS,
          ].includes(option.value),
        );
      }),
      map((options) => [
        {
          subheading: 'labels.addPoint',
          options: options.map((point) => ({
            ...point,
            value: (index: number) => this.add$.next({ point, index }),
          })),
        },
      ]),
      shareReplay(1),
    );

  public isRouteExpanded = computed(() =>
    this.points().every(
      (point) =>
        this.getPointState(point) !== RoutePointState.DEFAULT &&
        this.isPointExpanded(point),
    ),
  );

  private pointsState = signal(new Map<RoutePointKey, RoutePointState>());
  private pointsExpanded = signal(new Set<RoutePointKey>());

  constructor(private dictionariesService: DictionariesService) {}

  public getPointState(point: RoutePoint | number): RoutePointState {
    const key = this.getKey(point);
    if (!key) return RoutePointState.DEFAULT;

    return this.pointsState().get(key) ?? RoutePointState.DEFAULT;
  }

  public togglePointState(
    point: RoutePoint | number,
    force?: RoutePointState,
  ): void {
    const key = this.getKey(point);
    if (!key) return;

    this.pointsState.update((pointsState) => {
      force ??=
        this.getPointState(point) === RoutePointState.DEFAULT
          ? RoutePointState.DETAILS
          : RoutePointState.DEFAULT;
      return new Map([...pointsState, [key, force]]);
    });

    if (this.pointsState().get(key) === RoutePointState.DEFAULT) return;
    if (this.isMainPoint(point)) return;

    const section = this.getSection(point);
    if (!section) return;

    const { end } = section;
    this.togglePointExpanded(end - 1, true);
  }

  public isPointExpanded(point: RoutePoint | number): boolean {
    const key = this.getKey(point);
    if (!key) return false;

    return this.pointsExpanded().has(key);
  }

  public togglePointExpanded(
    point: RoutePoint | number,
    force?: boolean,
  ): void {
    const key = this.getKey(point);
    if (!key) return;

    this.pointsExpanded.update((pointsExpanded) => {
      force ??= !this.isPointExpanded(point);
      force ? pointsExpanded.add(key) : pointsExpanded.delete(key);
      return new Set(pointsExpanded);
    });
  }

  public toggleSection(point: RoutePoint | number): void {
    const section = this.getSection(point);
    if (!section) return;

    const { start, end } = section;
    const sectionPoints = this.points().slice(start, end);
    const isActive = sectionPoints.every(
      (point) => this.getPointState(point) !== RoutePointState.DEFAULT,
    );

    for (const point of sectionPoints) {
      this.togglePointState(
        point,
        !isActive ? RoutePointState.DETAILS : RoutePointState.DEFAULT,
      );
      this.togglePointExpanded(point, !isActive);
    }
  }

  public toggleRouteExpanded(force?: boolean): void {
    const isExpanded = force ?? !this.isRouteExpanded();
    for (const point of this.points()) {
      this.togglePointState(
        point,
        isExpanded ? RoutePointState.DETAILS : RoutePointState.DEFAULT,
      );
      this.togglePointExpanded(point, isExpanded);
    }
  }

  public getValue(point: RoutePoint): OrderPoint | OrderPointForm {
    return 'value' in point ? point.value : point;
  }

  public isIncludedInOrder(point: ReturnType<typeof this.getValue>): boolean {
    if (this.isHandlingPoint(point)) return true;
    return 'includeInOrder' in point ? point.includeInOrder : true;
  }

  public isHandlingPoint(value: OrderPoint | OrderPointForm): boolean {
    return [OrderPointCategory.LOADING, OrderPointCategory.UNLOADING].includes(
      value.category,
    );
  }

  public isFirst(point: RoutePoint): boolean {
    return this.points()[0] === point;
  }

  public isLast(point: RoutePoint): boolean {
    return this.points().at(-1) === point;
  }

  private getSection(
    point: RoutePoint | number,
  ): { start: number; end: number } | null {
    const index = isNumber(point) ? point : this.points().indexOf(point);
    if (index === -1) return null;

    const start = this.points()
      .slice(0, index)
      .findLastIndex(this.isMainPoint.bind(this));
    const end = this.points()
      .slice(index)
      .findIndex(this.isMainPoint.bind(this), index + 1);

    return {
      start: start === -1 ? 0 : start,
      end: end === -1 ? this.points().length : end + index + 1,
    };
  }

  private isMainPoint(point: RoutePoint | number): boolean {
    point = isNumber(point) ? this.points()[point] : point;
    if (!point) return false;

    return (
      this.isIncludedInOrder(this.getValue(point)) ||
      this.isFirst(point) ||
      this.isLast(point)
    );
  }

  private getKey(point: RoutePoint | number): RoutePointKey | null {
    const routePoint = isNumber(point) ? this.points().at(point) : point;
    if (!routePoint) return null;

    return 'form' in routePoint ? routePoint.form : routePoint;
  }
}
