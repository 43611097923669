import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../../../../../auth/common/services/auth.service';
import { EngineConfig } from '../interfaces/engine-config';
import { EngineConfigService } from '../services/engine-config.service';

export const engineConfigResolver: ResolveFn<Observable<EngineConfig>> = () => {
  const engineConfigService = inject(EngineConfigService);
  const authService = inject(AuthService);

  return engineConfigService
    .getGlobalEngineConfig()
    .pipe(tap((engineConfig) => authService.updateEngineConfig(engineConfig)));
};
