<section>
  <div class="heading {{ justify() }}">
    <div class="title">
      <div class="title-container">
        <h6>{{ heading() | translate }}</h6>

        <ng-content select="[heading]"></ng-content>
      </div>

      @if (count() != null) {
        <span class="count f-semibold-2">{{ count() }}</span>
      }
    </div>

    <div class="options">
      <ng-content select="[options]"></ng-content>
    </div>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>
</section>
