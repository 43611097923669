import { Injectable } from '@angular/core';
import { OnyxToastType } from '../components/toast';
import { OnyxPhone } from '../interfaces';
import { OnyxToastService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ActionHelper {
  constructor(private readonly toastService: OnyxToastService) {}

  public copy(text: string): void {
    ActionHelper.copy(text, this.toastService);
  }

  public share(route: string): void {
    ActionHelper.share(route, this.toastService);
  }

  public static copy(text: string, toastService: OnyxToastService): void {
    navigator.clipboard.writeText(text);
    toastService.showCustom(OnyxToastType.COPIED_TO_CLIPBOARD);
  }

  public static share(route: string, toastService: OnyxToastService): void {
    const url = `${window.location.origin}${route}`;
    navigator.clipboard.writeText(url);
    toastService.showCustom(OnyxToastType.COPIED_TO_CLIPBOARD);
  }

  public static makeCall(phone: OnyxPhone | null): void {
    if (!phone) return;
    window.open(`tel:${phone.areaCode}${phone.number}`, '_self');
  }

  public static openRoute(route: string): void {
    const url = `${window.location.origin}${route}`;
    window.open(url, '_blank');
  }

  public static openLink(link: string): void {
    window.open(link, '_blank');
  }

  public static sendEmail(email: string): void {
    window.open(`mailto:${email}`, '_self');
  }

  public static downloadFile(file: File): void {
    const url = URL.createObjectURL(file);

    const linkElement = document.createElement('a');
    linkElement.href = url;
    linkElement.download = file.name;

    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);

    URL.revokeObjectURL(url);
  }
}
