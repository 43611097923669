import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  DestroyRef,
  Directive,
  ElementRef,
  HostListener,
  TemplateRef,
  ViewContainerRef,
  effect,
  input,
  model,
  output,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MaybeAsync } from '@angular/router';
import { take } from 'rxjs';
import { OnyxPaths } from '../components';
import { OnyxOverlayPosition } from '../enums';
import {
  ONYX_DROPDOWN_CONFIG,
  OnyxDropdownOverlayComponent,
} from '../internal/components/onyx-dropdown-overlay/onyx-dropdown-overlay.component';
import { OnyxDropdownOverlayService } from '../internal/services/onyx-dropdown-overlay.service';
import {
  OnyxDropdownConfig,
  OnyxDropdownOptions,
  OnyxOption,
} from './interfaces';

@Directive({
  selector: '[onyxDropdownOptions]',
})
export class OnyxDropdownDirective<T> {
  public onyxDropdownOptions =
    input.required<MaybeAsync<OnyxDropdownOptions<T>>>();
  public onyxDropdownSelectedOptions = input<OnyxOption<T>[]>();
  public onyxDropdownValues = input<T[]>();
  public onyxDropdownCompareKey = input<OnyxPaths<T>>();
  public onyxDropdownOptional = input(false);
  public onyxDropdownMultiple = input(false);
  public onyxDropdownDisabled = input(false);
  public onyxDropdownAttached = model(false);
  public onyxDropdownSearch = input(false);
  public onyxDropdownSearchPlaceholder = input<string>();
  public onyxDropdownQuery = model<string>();
  public onyxDropdownNotFoundMessage = input<string>();
  public onyxDropdownShowAddOption = input<boolean | 'value'>(false);
  public onyxDropdownAddOptionMessage = input<string>();
  public onyxDropdownSubheading = input<string>();
  public onyxDropdownSubheadingTemplateRef = input<TemplateRef<any>>();
  public onyxDropdownEmptyTemplateRef = input<TemplateRef<any>>();
  public onyxDropdownOptionTemplateRef = input<TemplateRef<any>>();
  public onyxDropdownOptionSize =
    input<OnyxDropdownConfig<T>['optionSize']>('m');
  public onyxDropdownTheme = input<OnyxDropdownConfig<T>['theme']>('light');
  public onyxDropdownWidth = input<OnyxDropdownConfig<T>['width']>('flex');
  public onyxDropdownPositions = input<OnyxOverlayPosition[]>();
  public onyxDropdownOverridePositions = input<Partial<ConnectedPosition>>();
  public onyxDropdownTargetRef = input<HTMLElement>();

  public onyxDropdownValueChange = output<T | null>();
  public onyxDropdownValuesChange = output<T[]>();
  public onyxDropdownSelectedOptionChange = output<OnyxOption<T> | null>();
  public onyxDropdownSelectedOptionsChange = output<OnyxOption<T>[]>();
  public onyxDropdownAddOption = output<string>();
  public onyxDropdownFocus = output();

  private attached = signal(false);

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private dropdownOverlayService: OnyxDropdownOverlayService,
    private destroyRef: DestroyRef,
  ) {
    effect(() => {
      const attached = this.attached();
      untracked(() => this.onyxDropdownAttached.set(attached));
    });

    effect(() =>
      this.onyxDropdownAttached()
        ? this.attachDropdown()
        : this.detachDropdown(),
    );
  }

  @HostListener('click')
  protected toggle(): void {
    if (this.onyxDropdownDisabled()) return;

    if (this.attached()) {
      this.detachDropdown();
    } else {
      this.show();
    }
  }

  @HostListener('keydown.space', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  protected show(event?: KeyboardEvent): void {
    if (this.attached() || this.onyxDropdownDisabled()) return;

    this.attachDropdown();
    if (this.onyxDropdownSearch()) setTimeout(() => this.focusDropdown());

    if (this.onyxDropdownSearch() || this.onyxDropdownQuery() === undefined) {
      event?.preventDefault();
    }
  }

  @HostListener('keydown.shift.tab')
  @HostListener('keydown.tab')
  protected close(): void {
    this.detachDropdown();
  }

  @HostListener('keydown.arrowdown', ['$event'])
  protected showAndFocus(event: KeyboardEvent): void {
    if (this.onyxDropdownDisabled()) return;

    this.attachDropdown();
    setTimeout(() => this.focusDropdown());

    event.preventDefault();
  }

  private attachDropdown(): void {
    if (this.attached() || this.onyxDropdownDisabled()) return;

    this.dropdownOverlayService.attachDropdown(
      this.elementRef,
      this.viewContainerRef,
      OnyxDropdownOverlayComponent,
      ONYX_DROPDOWN_CONFIG,
      {
        options: this.onyxDropdownOptions(),
        selectedOptions: this.onyxDropdownSelectedOptions(),
        values: this.onyxDropdownValues(),
        compareKey: this.onyxDropdownCompareKey() as any,
        optional: this.onyxDropdownOptional(),
        multiple: this.onyxDropdownMultiple(),
        search: this.onyxDropdownSearch(),
        searchPlaceholder: this.onyxDropdownSearchPlaceholder(),
        query: this.onyxDropdownQuery,
        notFoundMessage: this.onyxDropdownNotFoundMessage(),
        showAddOption: this.onyxDropdownShowAddOption(),
        addOptionMessage: this.onyxDropdownAddOptionMessage(),
        subheading: this.onyxDropdownSubheading(),
        subheadingTemplateRef: this.onyxDropdownSubheadingTemplateRef(),
        emptyTemplateRef: this.onyxDropdownEmptyTemplateRef(),
        optionTemplateRef: this.onyxDropdownOptionTemplateRef(),
        optionSize: this.onyxDropdownOptionSize(),
        theme: this.onyxDropdownTheme(),
        width: this.onyxDropdownWidth(),
        positions: this.onyxDropdownPositions(),
        overridePositions: this.onyxDropdownOverridePositions(),
        elementRef: this.elementRef.nativeElement,
        targetRef: this.onyxDropdownTargetRef(),
        focusElement: () => this.focusElement(),
        changeValues: ({ values, options }) => {
          this.onyxDropdownValueChange.emit(values[0] ?? null);
          this.onyxDropdownValuesChange.emit(values);
          this.onyxDropdownSelectedOptionChange.emit(options[0] ?? null);
          this.onyxDropdownSelectedOptionsChange.emit(options);
        },
        addOption: (query) => this.onyxDropdownAddOption.emit(query),
        close: () => {
          if (this.onyxDropdownSearch()) this.onyxDropdownQuery.set('');
          this.detachDropdown();
        },
      },
    );

    this.attached.set(true);
    this.dropdownOverlayService.detach$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.attached.set(false));
  }

  private detachDropdown(): void {
    if (!this.attached()) return;

    this.dropdownOverlayService.detachDropdown();
    this.focusElement();
    this.attached.set(false);
  }

  private focusElement(): void {
    if (this.onyxDropdownFocus['listeners'] != null) {
      this.onyxDropdownFocus.emit();
    } else {
      this.elementRef.nativeElement.focus();
    }
  }

  private focusDropdown(): void {
    const dropdown = this.dropdownOverlayService.componentRef()?.instance as
      | OnyxDropdownOverlayComponent<T>
      | undefined;
    dropdown?.focus();
  }
}
