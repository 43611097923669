<onyx-section [heading]="I18N + '.heading'">
  <form [formGroup]="form()">
    @let controls = form().controls;
    <onyx-dropdown
      [formControl]="controls.sender"
      label="labels.client"
      [link]="(senderLink$ | async) ?? undefined"
      [options]="SENDERS_SOURCE"
      [showAddOption]="true"
      (selectedValueChange)="changeSender($event)"
      (addOption)="addSender()"
    ></onyx-dropdown>

    <onyx-dropdown
      [formControl]="controls.senderBranch"
      label="labels.clientBranch"
      placeholder="labels.mainBranch"
      [options]="senderBranches$ | async"
      [showAddOption]="true"
      (addOption)="editSender()"
    ></onyx-dropdown>

    @if (controls.ourBranch.enabled) {
      <onyx-dropdown
        [formControl]="controls.ourBranch"
        label="labels.ourBranch"
        [options]="ourBranches()"
      ></onyx-dropdown>
    } @else {
      <div class="placeholder"></div>
    }

    <onyx-dropdown
      [formControl]="controls.rateType"
      label="labels.rateType"
      [options]="rateTypes$ | async"
    ></onyx-dropdown>

    @let rateGroup = controls.rate;
    @let rateControls = rateGroup.controls;
    <onyx-form-group
      [formGroup]="rateGroup"
      [label]="rateTypeLabel()"
      [controls]="[rateControls.value, rateControls.currency]"
      (labelClick)="rateValueControlElement.focus()"
    >
      <onyx-text-field
        [formControl]="rateControls.value"
        [decimalPlaces]="2"
        [showErrors]="false"
        #rateValueControlElement
      ></onyx-text-field>

      <onyx-dropdown
        [formControl]="rateControls.currency"
        [options]="currencies$ | async"
        [optional]="false"
        width="84px"
        [showErrors]="false"
      ></onyx-dropdown>
    </onyx-form-group>

    <onyx-dropdown
      [formControl]="controls.vatRate"
      label="labels.vat"
      [options]="vatRates$ | async"
      width="50%"
    ></onyx-dropdown>

    <onyx-dropdown
      [formControl]="controls.loadedSemiTrailer"
      label="labels.loadedSemiTrailer"
      [hint]="I18N + '.externalTrailerHint'"
      [options]="LOADED_SEMI_TRAILER_SOURCE"
      [optional]="true"
      [showOptional]="false"
      placeholder="labels.no"
      [searchPlaceholder]="I18N + '.externalTrailerSearch'"
      showAddOption="value"
      [addOptionMessage]="I18N + '.externalTrailer'"
      (optionsChange)="loadedSemiTrailerLoaded$.next()"
      (selectedValueChange)="loadedSemiTrailer$.next($event)"
      (addOption)="addExternalTrailer($event!)"
    ></onyx-dropdown>

    <onyx-form-group [controls]="controls.vehicleTypes">
      <onyx-dropdown
        [formControl]="controls.vehicleTypes"
        label="labels.cargoSpace"
        [options]="vehicleTypes$ | async"
        [multiple]="true"
        [search]="true"
        [showErrors]="false"
      ></onyx-dropdown>

      <onyx-suggestions
        [control]="controls.vehicleTypes"
        [suggestions]="vehicleTypeSuggestions$ | async"
        [multiple]="true"
      ></onyx-suggestions>
    </onyx-form-group>

    <onyx-tabs
      [formControl]="controls.minSemiTrailerSize"
      label="labels.minSemiTrailerSize"
      [tabs]="semiTrailerSizes$ | async"
      [fill]="true"
      size="m"
      [onyxTooltip]="'unavailableForVans' | i18n: I18N"
      [onyxTooltipEnabled]="controls.minSemiTrailerSize.disabled"
    ></onyx-tabs>

    <onyx-dropdown
      [formControl]="controls.dischargeTypes"
      label="labels.dischargeType"
      [multiple]="true"
      [options]="dischargeTypes$ | async"
    ></onyx-dropdown>
  </form>
</onyx-section>
