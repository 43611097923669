<section>
  <onyx-information-heading>
    {{ 'realizedBy' | i18n: I18N | translate }}
  </onyx-information-heading>

  <div class="container">
    <onyx-data-widget supheading="labels.vehicles">
      @let assignedData = order().assignedData;
      <ng-container heading>
        @if (assignedData?.vehicle; as vehicle) {
          {{ vehicle | fleetIdentifier }}
        }
      </ng-container>

      <ng-container secondaryHeading>
        @if (assignedData?.trailer; as trailer) {
          {{ trailer | fleetIdentifier }}
        }
      </ng-container>
    </onyx-data-widget>

    <onyx-data-widget supheading="labels.drivers">
      <ng-container heading>
        @if (assignedData?.primaryDriver; as primaryDriver) {
          {{ primaryDriver.driverData | onyxName }}
        }
      </ng-container>

      <ng-container secondaryHeading>
        @if (assignedData?.secondaryDriver; as secondaryDriver) {
          {{ secondaryDriver.driverData | onyxName }}
        }
      </ng-container>
    </onyx-data-widget>
  </div>
</section>
