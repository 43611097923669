import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  input,
  OnInit,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';
import {
  OnyxAddButtonComponent,
  OnyxFormMode,
  OnyxModalService,
  OnyxSectionComponent,
} from '@onyx/angular';
import { filter, map } from 'rxjs';
import { DocumentsFormListComponent } from './documents-form-list/documents-form-list.component';
import {
  DocumentForm,
  DocumentFormGroup,
  DocumentModalData,
  DocumentModalResult,
  DocumentsModalFormComponent,
} from './documents-modal-form/documents-modal-form.component';

export type DocumentsFormGroup = ReturnType<
  typeof DocumentsFormComponent.buildForm
>;

@Component({
  selector: 'app-documents-form',
  imports: [
    OnyxSectionComponent,
    OnyxAddButtonComponent,
    DocumentsFormListComponent,
  ],
  templateUrl: './documents-form.component.html',
  styleUrl: './documents-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsFormComponent implements OnInit {
  protected readonly I18N = 'forms.documents';

  public form = input.required<DocumentsFormGroup>();
  public placeholder = input.required<string>();

  protected documents!: Signal<DocumentForm[]>;

  constructor(
    private modalService: OnyxModalService,
    private fb: NonNullableFormBuilder,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    this.documents = toSignal(
      this.form().valueChanges.pipe(map(() => this.form().getRawValue())),
      { initialValue: this.form().getRawValue(), injector: this.injector },
    );
  }

  protected addDocument(): void {
    this.modalService
      .open<DocumentModalData, DocumentModalResult>(
        DocumentsModalFormComponent,
        { mode: OnyxFormMode.ADD },
      )
      .result.pipe(filter((document) => document != null))
      .subscribe((document) => {
        const control = DocumentsModalFormComponent.buildForm(this.fb);
        control.setValue(document);
        this.form().push(control);
      });
  }

  public static buildForm(fb: NonNullableFormBuilder) {
    return fb.array<DocumentFormGroup>([]);
  }
}
