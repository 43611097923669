import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxFormMode,
  OnyxModalComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject, switchMap } from 'rxjs';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { DictionariesNamesTranslationFormComponent } from '../../../common/components/forms/dictionaries-names-translation-form/dictionaries-names-translation-form.component';
import { CompanyDictionaryCode } from '../../../common/enums/company-dictionary-code';
import { DictionariesHelper } from '../../../common/helpers/dictionaries.helper';
import { CompanyVehicleParameter } from '../../../common/interfaces/company-vehicle-parameter';
import { CompanyDictionariesService } from '../../../common/services/company-dictionaries.service';

export type DictionariesVehicleParameterForm = ReturnType<
  ReturnType<
    DictionariesVehicleParameterModalComponent['buildForm']
  >['getRawValue']
>;

export type DictionariesVehicleParameterModalData =
  | {
      mode: OnyxFormMode.ADD;
      name?: string;
    }
  | {
      mode: OnyxFormMode.EDIT;
      vehicleParameter: CompanyVehicleParameter;
    };

@Component({
  selector: 'app-dictionaries-vehicle-parameter-modal',
  imports: [
    OnyxModalComponent,
    DictionariesNamesTranslationFormComponent,
    OnyxButtonComponent,
    TranslatePipe,
  ],
  templateUrl: './dictionaries-vehicle-parameter-modal.component.html',
  styleUrl: './dictionaries-vehicle-parameter-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DictionariesVehicleParameterModalComponent implements OnInit {
  protected readonly I18N = 'dictionaries';
  protected readonly OnyxFormMode = OnyxFormMode;

  protected form = this.buildForm();
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected data: DictionariesVehicleParameterModalData,
    protected dialogRef: DialogRef<void>,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private companyDictionariesService: CompanyDictionariesService,
    private dictionariesHelper: DictionariesHelper,
  ) {}

  public ngOnInit(): void {
    if (this.data.mode === OnyxFormMode.EDIT) {
      this.form.patchValue(this.data.vehicleParameter);
    }
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();

    let action$;
    switch (this.data.mode) {
      case OnyxFormMode.ADD:
        action$ = this.companyDictionariesService.addDictionaryItem(
          CompanyDictionaryCode.VEHICLE_PARAMETER,
          form,
        );
        break;

      case OnyxFormMode.EDIT:
        {
          const { vehicleParameter } = this.data;
          action$ = this.dictionariesHelper
            .confirmEdit(
              CompanyDictionaryCode.VEHICLE_PARAMETER,
              vehicleParameter,
              this.close$,
            )
            .pipe(
              switchMap(() =>
                this.companyDictionariesService.editDictionaryItem(
                  vehicleParameter.uuid,
                  form,
                ),
              ),
            );
        }
        break;
    }

    this.loading.set(true);
    action$
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            this.data.mode === OnyxFormMode.ADD
              ? `${this.I18N}.dictionaryAdded`
              : `${this.I18N}.dictionaryEdited`,
          );
          this.close$.next();
        },
        error: (response) =>
          this.dictionariesHelper.handleEditError(
            response,
            CompanyDictionaryCode.VEHICLE_PARAMETER,
            form,
          ),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      names: DictionariesNamesTranslationFormComponent.buildFom(this.fb),
    });
  }
}
