import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const onyxLengthValidator =
  (requiredLength: number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value == null || value.length === requiredLength) return null;

    return { requiredLength: requiredLength };
  };
