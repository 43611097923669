<div class="inputs" [ngClass]="{ range: showDateRange() }">
  <div>
    <onyx-datepicker-input
      [label]="label() || !!labelTo()"
      [hint]="hint()"
      [showIndefinite]="showIndefinite() && !showDateRange()"
      [indefiniteLabel]="indefiniteLabel()"
      [disabled]="_disabled()"
      [showOptional]="showOptional()"
      [showClear]="showClear()"
      [forceError]="forceError()"
      (changeValue)="changeFromValue($event)"
      (inputClick)="openCalendarAndFocus($event, datepickerInputFromComponent)"
      (inputFocusout)="focusoutCloseFromCalendar()"
      (keydown.tab)="enableFocusout()"
      (keydown.shift.tab)="enableFocusout()"
      #datepickerInputFromComponent
    ></onyx-datepicker-input>

    @if (formControl() && showErrors()) {
      <onyx-form-control-error
        [controls]="[formControl()!]"
      ></onyx-form-control-error>
    }
  </div>

  @if (showDateRange()) {
    <div class="separator" [ngClass]="{ label: label() || labelTo() }">-</div>

    <onyx-datepicker-input
      [label]="labelTo() || !!label()"
      [showIndefinite]="showIndefinite()"
      [indefiniteLabel]="indefiniteLabel()"
      [disabled]="_disabled()"
      [showOptional]="showOptional()"
      [showClear]="showClear()"
      [forceError]="forceError()"
      (changeValue)="changeToValue($event)"
      (inputClick)="openCalendarAndFocus($event, datepickerInputToComponent)"
      (inputFocusout)="focusoutCloseCalendar()"
      (keydown.tab)="enableFocusout()"
      #datepickerInputToComponent
    ></onyx-datepicker-input>
  }
</div>
