import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Injector,
  input,
  OnInit,
  Signal,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxSectionComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { chain, groupBy, isEqual, orderBy } from 'lodash';
import { distinctUntilChanged, map } from 'rxjs';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { DictionariesService } from '../../../../common/services/dictionaries.service';
import { ORDER_LTL_POINT_TYPES } from '../../common/constants/order-ltl-point-types';
import { OrderPointCategory } from '../../common/enums/order-point-category';
import { OrderFormService } from '../../common/services/order-form.service';
import {
  OrderPointFormComponent,
  OrderPointFormGroup,
} from './order-point-form/order-point-form.component';

export type OrderPointsFormGroup = ReturnType<
  typeof OrderPointsFormComponent.buildForm
>;

export type OrderPointsForm = ReturnType<OrderPointsFormGroup['getRawValue']>;

@Component({
  selector: 'app-order-points-form',
  imports: [
    OnyxSectionComponent,
    TranslatePipe,
    ReactiveFormsModule,
    OnyxIconComponent,
    OnyxButtonComponent,
    OnyxIconButtonComponent,
    OnyxDropdownDirective,
    OrderPointFormComponent,
    OnyxTooltipDirective,
  ],
  templateUrl: './order-points-form.component.html',
  styleUrl: './order-points-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPointsFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.points';

  protected readonly pointTypes = toSignal(
    this.dictionariesService.getDictionary(DictionaryCode.ORDER_POINT_TYPE),
  );
  protected readonly pointTypesOptions = computed(() => {
    const pointTypes = this.pointTypes();
    if (!pointTypes) return null;

    const { buttons = [], dropdown = [] } = groupBy(pointTypes, ({ value }) =>
      ORDER_LTL_POINT_TYPES.includes(value) ? 'buttons' : 'dropdown',
    );

    return {
      buttons: orderBy(buttons, ({ value }) =>
        ORDER_LTL_POINT_TYPES.indexOf(value),
      ),
      dropdown: dropdown.map((point) => ({
        ...point,
        value: () => this.addPoint(point),
      })),
    };
  });

  public form = input.required<OrderPointsFormGroup>();

  protected isDedicated = this.orderFormService.isDedicated;

  protected points = this.orderFormService.points;
  protected pointsCategoryCount = computed(
    () =>
      chain(this.formValue()).countBy('category').value() as Record<
        OrderPointCategory,
        number
      >,
  );
  protected pointsDisabledCategories = computed(() => {
    const categoryCount = this.pointsCategoryCount();
    const isDedicated = this.isDedicated();

    const loadingUnloadingCount =
      (categoryCount[OrderPointCategory.LOADING] ?? 0) +
      (categoryCount[OrderPointCategory.UNLOADING] ?? 0);

    let loadingError: string | null = null;
    let unloadingError: string | null = null;

    if (isDedicated) {
      if (loadingUnloadingCount >= 5) {
        loadingError = unloadingError = 'ftlDisabled';
      } else if (
        categoryCount[OrderPointCategory.UNLOADING] > 1 &&
        categoryCount[OrderPointCategory.LOADING] >= 1
      ) {
        loadingError = 'ftlDisabled';
      } else if (
        categoryCount[OrderPointCategory.LOADING] > 1 &&
        categoryCount[OrderPointCategory.UNLOADING] >= 1
      ) {
        unloadingError = 'ftlDisabled';
      }
    } else {
      if (categoryCount[OrderPointCategory.LOADING] >= 1) {
        loadingError = 'disabled';
      }
      if (categoryCount[OrderPointCategory.UNLOADING] >= 1) {
        unloadingError = 'disabled';
      }
    }

    return {
      [OrderPointCategory.LOADING]: loadingError,
      [OrderPointCategory.UNLOADING]: unloadingError,
      [OrderPointCategory.CHECKPOINT]: null,
      [OrderPointCategory.TRANSIT]: isDedicated ? null : 'disabled',
    };
  });

  protected pointsDropdownExpanded = signal(false);

  private formValue!: Signal<OrderPointsForm>;

  constructor(
    private dictionariesService: DictionariesService,
    private injector: Injector,
    private orderFormService: OrderFormService,
  ) {}

  public ngOnInit(): void {
    this.formValue = toSignal(
      this.form().valueChanges.pipe(
        map(() => this.form().getRawValue()),
        distinctUntilChanged(isEqual),
      ),
      {
        initialValue: this.form().getRawValue(),
        injector: this.injector,
      },
    );
  }

  protected addPoint(point: {
    category: OrderPointCategory;
    value: string;
    includeInOrder: boolean;
    isAlwaysOpen: boolean;
  }): void {
    this.orderFormService.addPoint(point);
  }

  protected movePoint(index: number, direction: -1 | 1): void {
    this.orderFormService.movePoint(index, direction);
  }

  protected removePoint(index: number): void {
    this.orderFormService.removePoint(index);
  }

  public static buildForm(fb: NonNullableFormBuilder) {
    return fb.array<OrderPointFormGroup>([]);
  }
}
