<onyx-modal
  type="center"
  size="m"
  [heading]="'editPayment' | i18n: I18N"
  [close$]="close$"
  (closeModal)="dialogRef.close($event)"
>
  <app-order-modal-payment-form
    [order]="data.order"
    [form]="form"
  ></app-order-modal-payment-form>

  <onyx-button
    bottomLeftOptions
    type="outlined"
    color="red"
    (click)="deletePayment()"
  >
    {{ 'labels.delete' | translate }}
  </onyx-button>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'labels.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'labels.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
