<onyx-modal
  type="center"
  [heading]="I18N + '.' + data.mode + 'Unit'"
  size="m"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <form [formGroup]="form">
    @let controls = form.controls;
    <app-dictionaries-names-translation-form
      [form]="controls.names"
      label="labels.unitName"
      [initialValue]="data.mode === OnyxFormMode.ADD ? data.name : undefined"
    ></app-dictionaries-names-translation-form>

    @let sizeGroup = controls.size;
    <div class="form-group" [formGroup]="sizeGroup">
      @let sizeControls = sizeGroup.controls;
      <div class="radio">
        <p class="f-paragraph-regular-3">
          {{ I18N + '.size' | translate }}
        </p>

        <div class="radio-buttons">
          @for (sizeType of sizeTypes$ | async; track sizeType.value) {
            <onyx-radio-button
              [formControl]="sizeControls.type"
              [label]="sizeType.name"
              [option]="sizeType.value"
            ></onyx-radio-button>
          }
        </div>
      </div>

      @switch (type()) {
        @case (GoodsSizeType.LOADING_METERS) {
          <div class="group">
            <onyx-text-field
              [formControl]="sizeControls.loadingMeters"
              label="labels.loadingMeters"
              [decimalPlaces]="2"
              unit="LDM"
            ></onyx-text-field>
          </div>
        }
        @case (GoodsSizeType.DIMENSIONS) {
          <div class="group">
            <onyx-text-field
              [formControl]="sizeControls.length"
              label="labels.length"
              [decimalPlaces]="2"
              unit="m"
            ></onyx-text-field>

            <onyx-text-field
              [formControl]="sizeControls.width"
              label="labels.width"
              [decimalPlaces]="2"
              unit="m"
            ></onyx-text-field>

            <onyx-text-field
              [formControl]="sizeControls.height"
              label="labels.height"
              [decimalPlaces]="2"
              unit="m"
            ></onyx-text-field>
          </div>
        }
        @case (GoodsSizeType.VOLUME) {
          <div class="group">
            <onyx-text-field
              [formControl]="sizeControls.volume"
              label="labels.volume"
              [decimalPlaces]="2"
              unit="m³"
            ></onyx-text-field>
          </div>
        }
      }
    </div>
  </form>

  <ng-container bottomRightOptions>
    <onyx-button color="black" type="outlined" (click)="close$.next()">
      {{ 'labels.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{
        'labels.' + (data.mode === OnyxFormMode.ADD ? 'add' : 'saveChanges')
          | translate
      }}
    </onyx-button>
  </ng-container>
</onyx-modal>
