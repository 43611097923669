import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxBadgeComponent, OnyxWidgetBoxComponent } from '@onyx/angular';
import { I18nPipe } from '../../../../../common/pipes/i18n.pipe';
import { Driver } from '../../../common/interfaces/driver';
import { DriverStatus } from '../../../common/interfaces/driver-status';

@Component({
  selector: 'app-driver-modal-status',
  imports: [
    OnyxWidgetBoxComponent,
    OnyxBadgeComponent,
    I18nPipe,
    TranslatePipe,
  ],
  templateUrl: './driver-modal-status.component.html',
  styleUrl: './driver-modal-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalStatusComponent {
  protected readonly I18N = 'drivers.driverModal';

  public driver = input.required<Driver>();

  protected statusContext = computed(() => {
    switch (this.driver().status.status) {
      case DriverStatus.FREE:
      case DriverStatus.DELEGATION:
      case DriverStatus.UNASSIGNED:
        return {
          name: `${this.I18N}.active`,
          backgroundColor: 'green',
          badgeColor: 'green',
        } as const;

      case DriverStatus.UNAVAILABLE:
        return {
          name: 'labels.unavailable',
          backgroundColor: 'gray',
          badgeColor: 'gray',
        } as const;

      default:
        return {
          name: 'labels.archived',
          backgroundColor: 'gray',
          badgeColor: 'black',
        } as const;
    }
  });
}
