import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  ViewContainerRef,
  afterNextRender,
  effect,
  forwardRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isString, omit } from 'lodash';
import { take } from 'rxjs';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { OnyxDropdownOverlayService } from '../../../internal/services/onyx-dropdown-overlay.service';
import { OnyxTooltipContext } from '../../tooltip';
import {
  ONYX_DATEPICKER_CONFIG,
  OnyxDate,
  OnyxDatepickerYearsRange,
} from '../interfaces';
import { OnyxDatepickerCalendarsComponent } from './onyx-datepicker-calendars/onyx-datepicker-calendars.component';
import { OnyxDatepickerInputComponent } from './onyx-datepicker-input/onyx-datepicker-input.component';

@Component({
  selector: 'onyx-datepicker',
  imports: [
    OnyxDatepickerInputComponent,
    OnyxFormControlErrorComponent,
    NgClass,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxDatepickerComponent),
      multi: true,
    },
  ],
  templateUrl: './onyx-datepicker.component.html',
  styleUrl: './onyx-datepicker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxDatepickerComponent
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor
{
  public value = input(null, {
    transform: (date: string | null) => {
      this._value.set({ from: date, indefinite: false });
      this.datepickerInputFromComponentRef().setValue(date);
    },
  });
  public label = input<string>();
  public hint = input<OnyxTooltipContext>();
  public disabled = input(false, {
    transform: (disabled: boolean): boolean => {
      if (disabled == null) return false;
      this._disabled.set(disabled);
      return disabled;
    },
  });
  public labelTo = input<string>();
  public showDateRange = input(false);
  public showIndefinite = input(false);
  public indefiniteLabel = input<string>();
  public showRelativeDays = input(false);
  public yearsRange = input<OnyxDatepickerYearsRange>({ past: 20, future: 20 });
  public forceError = input(false);
  public showOptional = input(true);
  public showClear = input(true);
  public showErrors = input(true);
  public width = input('100%');

  public valueChange = output<string | null>();

  protected onChange?: (value: OnyxDate | string | null) => void;
  protected onTouched?: () => void;
  protected _value = signal<OnyxDate>({});
  protected _disabled = signal(false);

  private datepickerInputFromComponentRef =
    viewChild.required<OnyxDatepickerInputComponent>(
      'datepickerInputFromComponent',
    );
  private datepickerInputToComponentRef =
    viewChild<OnyxDatepickerInputComponent>('datepickerInputToComponent');
  private allowFocusout = signal(false);
  private isCalendarAttached = signal(false);

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private viewContainerRef: ViewContainerRef,
    private onyxDropdownService: OnyxDropdownOverlayService,
    private elementRef: ElementRef,
  ) {
    super(injector, destroyRef);
    effect(() => (this.elementRef.nativeElement.style.width = this.width()));
  }

  public writeValue(value: OnyxDate | string | null): void {
    afterNextRender(
      {
        write: () => {
          if (!value) {
            this._value.set({});
            this.datepickerInputFromComponentRef().setValue(null);
            this.datepickerInputToComponentRef()?.setValue(null);
          } else if (isString(value)) {
            this._value.set({ from: value, indefinite: false });
            this.datepickerInputFromComponentRef().setValue(value);
          } else {
            value = {
              from: value.date ? value.date : value.from ? value.from : null,
              to: value.to ? value.to : null,
              indefinite: value.indefinite ?? false,
            };
            this._value.set(value);
            this.datepickerInputFromComponentRef().setValue(
              value.from,
              value.indefinite,
            );
            this.datepickerInputToComponentRef()?.setValue(
              value.to,
              value.indefinite,
            );
          }
        },
      },
      { injector: this.injector },
    );
  }

  public registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled.set(isDisabled);
  }

  protected changeFromValue(value: {
    date: string | null;
    indefinite: boolean | null;
  }): void {
    this.changeValue({
      ...this._value(),
      from: value.date,
      indefinite: value.indefinite ?? false,
    });
  }

  protected changeToValue(value: {
    date: string | null;
    indefinite: boolean | null;
  }): void {
    this.changeValue({
      ...this._value(),
      to: value.date,
      indefinite: value.indefinite ?? false,
    });
  }

  protected enableFocusout(): void {
    this.allowFocusout.set(true);
  }

  protected focusoutCloseFromCalendar(): void {
    if (this.showDateRange()) return;

    this.focusoutCloseCalendar();
  }

  protected focusoutCloseCalendar(): void {
    setTimeout(() => {
      if (this.allowFocusout() && this.isCalendarAttached()) {
        this.onyxDropdownService.detachDropdown();
        this.onTouched?.();
      }
    }, 100);
  }

  protected openCalendarAndFocus(
    event: Event,
    inputComponentRef: OnyxDatepickerInputComponent,
  ): void {
    event.stopPropagation();
    if (
      this._disabled() ||
      (this._value().indefinite && !this.showDateRange())
    ) {
      return;
    }

    inputComponentRef.focusInputs(event);
    this.allowFocusout.set(false);
    this.attachCalendar();
  }

  private attachCalendar(): void {
    this.onyxDropdownService.attachDropdown(
      this.datepickerInputFromComponentRef().inputElementRef(),
      this.viewContainerRef,
      OnyxDatepickerCalendarsComponent,
      ONYX_DATEPICKER_CONFIG,
      {
        date: this._value,
        changeDate: (value: OnyxDate | null) => this.changeCalendarDate(value),
        disableFocusout: () => this.allowFocusout.set(false),
        showDateRange: this.showDateRange(),
        showRelativeDays: this.showRelativeDays(),
        yearsRange: this.yearsRange(),
        width: 'flex',
      },
    );

    this.isCalendarAttached.set(true);
    this.onyxDropdownService.detach$
      .pipe(take(1))
      .subscribe(() => this.isCalendarAttached.set(false));
  }

  private changeCalendarDate(value: OnyxDate | null): void {
    value = {
      from: value?.from ?? null,
      to: value?.to ?? null,
      indefinite: value?.to ? false : this._value().indefinite,
    };
    this.changeValue(value);

    this.datepickerInputFromComponentRef().setValue(
      value?.from,
      this.showDateRange() ? false : value?.indefinite,
    );
    this.datepickerInputToComponentRef()?.setValue(
      value?.to,
      value?.indefinite,
    );
  }

  private changeValue(value: OnyxDate): void {
    this._value.set(value);
    value = {
      from: value?.from ?? null,
      to: value?.to ?? null,
      indefinite: value?.indefinite ?? false,
    };

    this.onChange?.(
      this.showDateRange()
        ? this.showIndefinite()
          ? value
          : omit(value, 'indefinite')
        : this.showIndefinite()
          ? { date: value.from, indefinite: value.indefinite }
          : (value.from ?? null),
    );
    this.onTouched?.();
    this.valueChange.emit(value.from ?? null);
  }
}
