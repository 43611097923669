import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Inject,
  Injector,
  OnInit,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxFormMode,
  OnyxModalComponent,
  OnyxRadioButtonComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { difference } from 'lodash';
import { Subject, switchMap } from 'rxjs';
import { DictionaryCode } from '../../../../../../common/enums/dictionary-code';
import { GoodsSizeType } from '../../../../../../common/enums/goods-size-type';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { DictionariesService } from '../../../../../../common/services/dictionaries.service';
import { DictionariesNamesTranslationFormComponent } from '../../../common/components/forms/dictionaries-names-translation-form/dictionaries-names-translation-form.component';
import { CompanyDictionaryCode } from '../../../common/enums/company-dictionary-code';
import { DictionariesHelper } from '../../../common/helpers/dictionaries.helper';
import { CompanyUnit } from '../../../common/interfaces/company-unit';
import { CompanyDictionariesService } from '../../../common/services/company-dictionaries.service';

export type DictionariesUnitForm = ReturnType<
  ReturnType<DictionariesUnitModalComponent['buildForm']>['getRawValue']
>;

export type DictionariesUnitModalData =
  | {
      mode: OnyxFormMode.ADD;
      name?: string;
    }
  | {
      mode: OnyxFormMode.EDIT;
      unit: CompanyUnit;
    };

@Component({
  selector: 'app-dictionaries-unit-modal',
  imports: [
    OnyxModalComponent,
    ReactiveFormsModule,
    DictionariesNamesTranslationFormComponent,
    OnyxTextFieldComponent,
    OnyxRadioButtonComponent,
    OnyxButtonComponent,
    TranslatePipe,
    AsyncPipe,
  ],
  templateUrl: './dictionaries-unit-modal.component.html',
  styleUrl: './dictionaries-unit-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DictionariesUnitModalComponent implements OnInit {
  protected readonly I18N = 'dictionaries';

  protected readonly DictionaryCode = DictionaryCode;
  protected readonly OnyxFormMode = OnyxFormMode;
  protected readonly GoodsSizeType = GoodsSizeType;

  protected readonly sizeTypes$ = this.dictionariesService.getDictionary(
    DictionaryCode.GOODS_SIZE_TYPE,
  );

  protected form = this.buildForm();
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  protected typeControl = this.form.controls.size.controls.type;
  protected type = toSignal(this.typeControl.valueChanges, {
    initialValue: this.typeControl.value,
  });

  constructor(
    @Inject(DIALOG_DATA) protected data: DictionariesUnitModalData,
    protected dialogRef: DialogRef<void>,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private injector: Injector,
    private companyDictionariesService: CompanyDictionariesService,
    private dictionariesService: DictionariesService,
    private dictionariesHelper: DictionariesHelper,
  ) {}

  public ngOnInit(): void {
    if (this.data.mode === OnyxFormMode.EDIT) {
      this.form.patchValue(this.data.unit);
    }

    const controls = this.form.controls;
    const sizeControls = controls.size.controls;

    const sizeTypeControls = {
      [GoodsSizeType.DIMENSIONS]: [
        sizeControls.length,
        sizeControls.height,
        sizeControls.width,
      ],
      [GoodsSizeType.LOADING_METERS]: [sizeControls.loadingMeters],
      [GoodsSizeType.VOLUME]: [sizeControls.volume],
    };

    effect(
      () => {
        const type = this.type();
        const enabledControls = [
          sizeControls.type,
          ...(type ? sizeTypeControls[type] : []),
        ];
        const disabledControls = difference(
          Object.values(sizeControls),
          enabledControls,
        );

        ValidationHelper.toggleControls(enabledControls, true);
        ValidationHelper.toggleControls(disabledControls, false);
      },
      { injector: this.injector },
    );
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();

    let action$;
    switch (this.data.mode) {
      case OnyxFormMode.ADD:
        action$ = this.companyDictionariesService.addDictionaryItem(
          CompanyDictionaryCode.UNIT,
          form,
        );
        break;

      case OnyxFormMode.EDIT:
        {
          const { unit } = this.data;
          action$ = this.dictionariesHelper
            .confirmEdit(CompanyDictionaryCode.UNIT, unit, this.close$)
            .pipe(
              switchMap(() =>
                this.companyDictionariesService.editDictionaryItem(
                  unit.uuid,
                  form,
                ),
              ),
            );
        }
        break;
    }

    this.loading.set(true);
    action$
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            this.data.mode === OnyxFormMode.ADD
              ? `${this.I18N}.dictionaryAdded`
              : `${this.I18N}.dictionaryEdited`,
          );
          this.close$.next();
        },
        error: (response) =>
          this.dictionariesHelper.handleEditError(
            response,
            CompanyDictionaryCode.UNIT,
            form,
          ),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      names: DictionariesNamesTranslationFormComponent.buildFom(this.fb),
      size: this.fb.group({
        type: this.fb.control(GoodsSizeType.LOADING_METERS, [
          Validators.required,
        ]),
        loadingMeters: this.fb.control<number | null>(null, [
          Validators.required,
        ]),
        length: this.fb.control<number | null>(null, [Validators.required]),
        width: this.fb.control<number | null>(null, [Validators.required]),
        height: this.fb.control<number | null>(null, [Validators.required]),
        volume: this.fb.control<number | null>(null, [Validators.required]),
      }),
    });
  }
}
