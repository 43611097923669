import { Routes } from '@angular/router';
import { businessResolver } from './common/resolvers/business.resolver';

export enum BusinessRoute {
  BUSINESS = '/management-panel/business',
}

export const BUSINESS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./business/business.component').then((m) => m.BusinessComponent),
    resolve: { business: businessResolver },
  },
] as const;
