import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDatePipe,
  OnyxIconComponent,
  OnyxLinkComponent,
  OnyxTimePipe,
} from '@onyx/angular';
import { DateTime } from 'luxon';
import { AddressComponent } from '../../../../../common/components/address/address.component';
import { DelegationStatus } from '../../enums/delegation-status';
import { DelegationHelper } from '../../helpers/delegation.helper';
import { Delegation } from '../../interfaces/delegation';

@Component({
  selector: 'app-delegation-period',
  imports: [
    OnyxIconComponent,
    OnyxDatePipe,
    OnyxTimePipe,
    AddressComponent,
    OnyxButtonComponent,
    OnyxLinkComponent,
    NgClass,
    TranslateModule,
    NgTemplateOutlet,
  ],
  templateUrl: './delegation-period.component.html',
  styleUrl: './delegation-period.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationPeriodComponent {
  protected readonly I18N = 'delegations';

  public start = input.required<Delegation['start']>();
  public end = input.required<Delegation['end']>();
  public status = input.required<DelegationStatus>();

  public edit = output<void>();

  protected hasEdit = computed(() => DelegationHelper.hasEdit(this.status()));

  protected isLate = computed(() => {
    if (!this.hasEdit()) return false;

    const { date, time } = this.end();
    if (!date || !time) return null;

    const endDate = DateTime.fromISO(`${date}T${time}`);
    return endDate < DateTime.now();
  });
}
