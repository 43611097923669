import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { GoodsSizeType } from '../enums/goods-size-type';
import { GoodsSize } from '../interfaces/common/goods-size';

@Pipe({
  name: 'goodsSize',
  pure: true,
})
export class GoodsSizePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  public transform(value: GoodsSize | null): string {
    if (!value) return '-';

    const format = (value: number) =>
      this.decimalPipe.transform(value / 100, '1.0-2') ?? '??';

    switch (value.type) {
      case GoodsSizeType.DIMENSIONS:
        return `${format(value.length)} × ${format(value.width)} × ${format(value.height)} m`;

      case GoodsSizeType.LOADING_METERS:
        return `${format(value.loadingMeters)} LDM`;

      case GoodsSizeType.VOLUME:
        return `${format(value.volume)} m³`;
    }
  }
}
