import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Injector,
  input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxInformationHeadingComponent,
  OnyxLogoComponent,
  OnyxTab,
  OnyxTabsComponent,
  OnyxToastService,
} from '@onyx/angular';
import { castArray } from 'lodash';
import { filter, map, skip, Subject, switchMap, tap } from 'rxjs';
import { DriversTachographComponent } from '../../../../common/components/drivers-tachograph/drivers-tachograph.component';
import { NotFoundPulseComponent } from '../../../../common/components/not-found-pulse/not-found-pulse.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { ValidationContext } from '../../../../common/helpers/validation-conditions.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { FleetHelper } from '../../../fleet/common/helpers/fleet.helper';
import { Fleet, SimplifiedFleet } from '../../../fleet/common/interfaces/fleet';
import { FleetService } from '../../../fleet/common/services/fleet.service';
import { OrderPlanningMode } from '../../common/enums/order-planning-mode';
import { OrderStatus } from '../../common/enums/order-status';
import { OrderHelper } from '../../common/helpers/order.helper';
import { ManualProposalData, Order } from '../../common/interfaces/order';
import { OrdersService } from '../../common/services/orders.service';
import { OrderModalOutsourcingDataComponent } from '../order-modal-sections/order-modal-summary/order-modal-outsourcing-data/order-modal-outsourcing-data.component';
import { OrderModalDecisionButtonsComponent } from './order-modal-decision-buttons/order-modal-decision-buttons.component';
import { OrderModalOutsourcingFormComponent } from './order-modal-outsourcing-form/order-modal-outsourcing-form.component';
import { OrderModalVehiclesComponent } from './order-modal-vehicles/order-modal-vehicles.component';

@Component({
  selector: 'app-order-modal-main-section',
  imports: [
    OnyxTabsComponent,
    OnyxInformationHeadingComponent,
    OrderModalOutsourcingFormComponent,
    TranslatePipe,
    OrderModalVehiclesComponent,
    DriversTachographComponent,
    NotFoundPulseComponent,
    OrderModalDecisionButtonsComponent,
    OnyxDropdownComponent,
    OnyxButtonComponent,
    OrderModalOutsourcingDataComponent,
    ReactiveFormsModule,
    OnyxLogoComponent,
  ],
  templateUrl: './order-modal-main-section.component.html',
  styleUrl: './order-modal-main-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalMainSectionComponent implements OnInit, OnDestroy {
  protected readonly I18N = 'orders.orderModal';

  protected readonly OrderPlanningMode = OrderPlanningMode;
  protected readonly OrderStatus = OrderStatus;

  public order = input.required<Order>();
  public mode = input.required({
    transform: (mode: OrderPlanningMode | undefined): OrderPlanningMode => {
      this.tab.update((tab) => mode ?? tab);
      return this.tab();
    },
  });
  public close$ = input.required<Subject<void>>();

  protected tab = signal(OrderPlanningMode.AUTO);
  protected selectedManualVehicle = signal<Fleet | null>(null);
  protected vehicle = signal<SimplifiedFleet | null>(null);
  protected trailer = signal<SimplifiedFleet | null>(null);
  protected isSetInvalid = signal<boolean | null>(null);
  protected isEngineCalculating = signal(false);
  protected manualProposalData = signal<ManualProposalData | null>(null);

  protected selectedManualVehicleControl = this.fb.control<string | null>(null);

  protected orderPlanningTabs = computed<OnyxTab<OrderPlanningMode>[]>(() => {
    const isExclusive = this.order().parameters.isExclusive;
    return [
      {
        name: `${DictionaryCode.VEHICLE_PLANNING_MODE}.auto`,
        value: OrderPlanningMode.AUTO,
        icon: { name: 'signet-light', size: 16 },
        iconColor: 'black',
      },
      {
        name: `${DictionaryCode.VEHICLE_PLANNING_MODE}.manual`,
        value: OrderPlanningMode.MANUAL,
        icon: { name: 'manual', size: 16 },
        iconColor: 'violet',
      },
      {
        name: 'labels.outsourcing',
        value: OrderPlanningMode.OUTSOURCING,
        icon: { name: 'cursor', size: 16 },
        disabled: isExclusive,
        tooltip: isExclusive ? `${this.I18N}.exclusiveParameter` : undefined,
        iconColor: 'orange',
      },
    ];
  });
  protected searchingVehicleData = computed(() =>
    OrderHelper.getSearchingVehicleData(this.order(), this.translateService),
  );
  protected vehiclesSource = computed(() => {
    const { categories, isLowDeck } = this.searchingVehicleData();

    return this.fleetHelper.getVehiclesSource(
      {
        category: castArray(categories),
        showSetsOnly: isLowDeck ?? false,
      },
      { enableWarnings: !!isLowDeck, context: ValidationContext.FLEET },
    );
  });
  protected hasApprovedVehicle = computed(() =>
    OrderHelper.hasApprovedVehicle(this.order().status.value),
  );
  protected hasEngineProposal = computed(
    () =>
      this.order().engineProposalData != null &&
      this.order().status.value === OrderStatus.TO_ACCEPT,
  );

  private deleteManualProposal$ = new Subject<void>();

  constructor(
    private injector: Injector,
    private fleetHelper: FleetHelper,
    private translateService: TranslateService,
    private ordersService: OrdersService,
    private toastService: OnyxToastService,
    private fb: NonNullableFormBuilder,
    private fleetService: FleetService,
    private destroyRef: DestroyRef,
  ) {
    this.deleteManualProposal$
      .pipe(
        filter(() => !!this.manualProposalData()),
        switchMap(() =>
          this.ordersService.deleteManualProposal(this.order().uuid),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => this.manualProposalData.set(null),
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      });

    toObservable(this.tab)
      .pipe(
        skip(1),
        tap((tab) => {
          if (
            tab !== OrderPlanningMode.MANUAL &&
            this.manualProposalData()?.loading
          ) {
            this.deleteManualProposal$.next();
          }
        }),
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.manualProposalData.set(this.order().manualProposalData);

    this.selectedManualVehicleControl.valueChanges
      .pipe(
        map(() => this.selectedManualVehicleControl.getRawValue()),
        skip(this.manualProposalData()?.data.vehicle ? 2 : 1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((vehicleUuid) => {
        const manualProposal = this.manualProposalData();
        if (
          !manualProposal ||
          vehicleUuid === manualProposal.data.vehicle.uuid
        ) {
          return;
        }

        this.deleteManualProposal$.next();
      });

    effect(
      () => {
        const manualProposal = this.manualProposalData();
        this.isEngineCalculating.set(manualProposal?.loading ?? false);

        const vehicleUuid = manualProposal?.data.vehicle?.uuid;
        if (!this.selectedManualVehicle() && vehicleUuid) {
          this.selectedManualVehicleControl.setValue(vehicleUuid);
        }
      },
      { injector: this.injector },
    );

    effect(
      () => {
        const isAuto = this.tab() === OrderPlanningMode.AUTO;
        const proposalData = isAuto ? this.order().engineProposalData : null;
        const selectedManualVehicle = isAuto
          ? null
          : this.selectedManualVehicle();
        const assignedData =
          !proposalData && !selectedManualVehicle
            ? this.order().assignedData
            : null;

        this.vehicle.set(
          assignedData?.vehicle ??
            proposalData?.vehicle ??
            selectedManualVehicle ??
            null,
        );
        this.trailer.set(
          assignedData?.trailer ??
            proposalData?.trailer ??
            selectedManualVehicle?.trailer ??
            null,
        );
      },
      { injector: this.injector },
    );

    effect(
      () => {
        if (
          this.tab() !== OrderPlanningMode.MANUAL &&
          !this.manualProposalData()
        ) {
          this.selectedManualVehicleControl.setValue(null);
        }
      },
      { injector: this.injector },
    );
  }

  public ngOnDestroy(): void {
    this.deleteManualProposal$.next();
  }

  protected addManualProposal(): void {
    const selectedManualVehicle = this.selectedManualVehicle();
    if (!selectedManualVehicle) return;

    this.ordersService
      .addManualProposal(this.order().uuid, selectedManualVehicle.uuid)
      .subscribe({
        next: (manualProposal) => this.manualProposalData.set(manualProposal),
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      });
  }

  protected confirmManualProposal(): void {
    const selectedManualVehicle = this.selectedManualVehicle();
    if (!selectedManualVehicle) return;

    this.ordersService
      .confirmManualProposal(this.order().uuid, selectedManualVehicle.uuid)
      .subscribe({
        next: () =>
          this.toastService.showSuccess('drivers.assignVehicleModal.success'),
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      });
  }
}
