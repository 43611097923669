import { MaybeAsync } from '@angular/router';
import { from, isObservable, Observable } from 'rxjs';

export class UtilityHelper {
  public static maybeAsyncToObservable<T>(input: MaybeAsync<T>): Observable<T> {
    return isObservable(input) ? input : from(Promise.resolve(input));
  }

  public static djb2Hash(input: string): number {
    let hash = 5381;
    for (const char of input) {
      hash = (hash << 5) + hash + char.charCodeAt(0);
    }
    return hash >>> 0;
  }
}
