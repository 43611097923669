<div class="heading">
  <h6>{{ `${I18N}.orderRoute` | translate }}</h6>

  <div class="buttons">
    <onyx-icon-button
      type="transparent"
      color="gray"
      size="m"
      [circle]="true"
      [disabled]="!isChanged()"
      [onyxTooltip]="'labels.undoChanges' | translate"
      onyxTooltipColor="white"
      (click)="isChanged() ? undoChanges() : null"
    >
      <onyx-icon name="undo" [size]="16"></onyx-icon>
    </onyx-icon-button>

    <onyx-icon-button
      type="transparent"
      color="gray"
      size="m"
      [circle]="true"
      [onyxTooltip]="`labels.${isExpanded() ? 'collapseAll' : 'expandAll'}`"
      onyxTooltipColor="white"
      (click)="toggleExpanded()"
    >
      <onyx-icon
        [name]="isExpanded() ? 'collapse-all' : 'expand-all'"
        [size]="23"
      ></onyx-icon>
    </onyx-icon-button>

    <onyx-icon-button
      type="transparent"
      color="gray"
      size="m"
      [circle]="true"
      [onyxTooltip]="'labels.addPoint' | translate"
      onyxTooltipColor="white"
      [onyxDropdownOptions]="addOptions$ | async"
      (onyxDropdownValueChange)="$event?.(points().length)"
    >
      <onyx-icon name="zoom-in" [size]="16"></onyx-icon>
    </onyx-icon-button>
  </div>
</div>

<app-route [points]="points()" [config]="config()"></app-route>
