import { OnyxCustomAddress, OnyxPointOfInterestAddress } from './interfaces';

export enum OnyxAddressType {
  CUSTOM = 'custom',
  POINT_OF_INTEREST = 'point-of-interest',
}

export interface OnyxAddresByType {
  [OnyxAddressType.CUSTOM]: OnyxCustomAddress;
  [OnyxAddressType.POINT_OF_INTEREST]: OnyxPointOfInterestAddress;
}
