import { NgTemplateOutlet } from '@angular/common';
import {
  afterNextRender,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  HostListener,
  Injector,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import {
  debounceTime,
  fromEventPattern,
  skip,
  Subject,
  takeUntil,
  timer,
} from 'rxjs';
import { ONYX_TRANSITION_DURATION } from '../../../constants';
import {
  OnyxDropdownDirective,
  OnyxTooltipDirective,
} from '../../../directives';
import { OnyxOption } from '../../../directives/interfaces';
import { OnyxOverlayPosition } from '../../../enums';
import { MapHelper } from '../../../helpers';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxHereMapsService } from '../../../internal/services/onyx-here-maps.service';
import { OnyxMapEventQueue } from '../../../internal/utilities/onyx-map-event-queue';
import { OnyxMapService } from '../../../services';
import { OnyxCoordinates } from '../../address-input';
import { OnyxIconButtonComponent } from '../../buttons/onyx-icon-button/onyx-icon-button.component';
import { OnyxIconComponent, OnyxIconName } from '../../icons';
import {
  getPolylineStyles,
  PolylineOptions,
} from '../constants/map-polyline-styles';
import {
  OnyxMapLayerType,
  OnyxMapMarkerType,
  OnyxMapMode,
  OnyxMapRoutePointType,
  OnyxMapVehicleStatus,
} from '../enums';
import { OnyxMapEventType } from '../enums/onyx-map-event-type';
import { OnyxMapObjectZIndex } from '../enums/onyx-map-object-z-index';
import {
  OnyxMapEvent,
  OnyxMapRoutePoint,
  OnyxRoute,
  OnyxRouteBorder,
} from '../interfaces';
import { OnyxMapRouteOptions } from '../interfaces/onyx-map-route-options';

@Component({
  selector: 'onyx-map',
  imports: [
    TranslatePipe,
    OnyxIconComponent,
    OnyxIconButtonComponent,
    OnyxDropdownDirective,
    OnyxTooltipDirective,
    NgTemplateOutlet,
  ],
  templateUrl: './onyx-map.component.html',
  styleUrl: './onyx-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxMapComponent implements AfterViewInit {
  protected readonly I18N = `${I18N_NAMESPACE}.map`;

  protected readonly OnyxMapMode = OnyxMapMode;
  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;

  protected readonly LAYER_TYPE_OPTIONS: OnyxOption<OnyxMapLayerType>[] =
    Object.values(OnyxMapLayerType).map((value) => ({
      name: `${this.I18N}.layerTypes.${value}`,
      value,
    }));

  private readonly controlsElementRef =
    viewChild<ElementRef<HTMLElement>>('controlsElement');

  public defer = input(false);
  public preview = input(true);
  public border = input(true);
  public sizes = input(true);

  public mapClick = output<void>();
  public selectedVehicleIdChange = output<string | null>();

  public mode = signal(OnyxMapMode.MINIMIZED);

  protected layerButtonActive = signal(false);
  protected layerType = signal(OnyxMapLayerType.DEFAULT);

  protected active = computed(() => {
    if (!this.mapService.standaloneMap()) return true;
    return this.mode() === OnyxMapMode.STANDALONE;
  });
  protected showPreview = computed(
    () => this.mode() === OnyxMapMode.MINIMIZED && this.preview(),
  );

  private map = signal<H.Map | null>(null);
  private behaviors: H.mapevents.Behavior | null = null;
  private ui: H.ui.UI | null = null;

  private _vehicles = new Map<string, H.map.DomMarker>();
  public get vehicles(): MapIterator<string> {
    return this._vehicles.keys();
  }

  private _routePoints = new Map<number, H.map.DomMarker>();
  public get routePoints(): MapIterator<number> {
    return this._routePoints.keys();
  }

  private _routes = new Map<number, (H.map.Polyline | H.map.DomMarker)[]>();
  public get routes(): MapIterator<number> {
    return this._routes.keys();
  }

  private _markers = new Map<string, H.map.Marker>();
  public get markers(): MapIterator<string> {
    return this._markers.keys();
  }

  private selectedVehicleId: string | null = null;

  private queue = new OnyxMapEventQueue();
  private destroy$ = new Subject<void>();

  constructor(
    private injector: Injector,
    private elementRef: ElementRef<HTMLElement>,
    private destroyRef: DestroyRef,
    private mapService: OnyxMapService,
    private hereMapsService: OnyxHereMapsService,
  ) {
    effect(() => {
      const classList = this.elementRef.nativeElement.classList;
      classList.toggle('border', this.border());
      classList.toggle('minimized', this.mode() === OnyxMapMode.MINIMIZED);
      classList.toggle('maximized', this.mode() === OnyxMapMode.MAXIMIZED);
      classList.toggle('standalone', this.mode() === OnyxMapMode.STANDALONE);
      classList.toggle('active', this.active());
    });

    effect(() => {
      if (!this.map()) return;
      if (this.mode() === OnyxMapMode.STANDALONE) return;

      if (this.showPreview()) this.dispatch(new OnyxMapEvent.FitContent());
      setTimeout(() => this.resizeMap());
    });

    effect(() => {
      const map = this.map();
      if (!map) return;

      const defaultLayers = this.hereMapsService.getDefaultLayers();
      const baseLayer = {
        [OnyxMapLayerType.DEFAULT]: defaultLayers.vector.normal.map,
        [OnyxMapLayerType.SATELLITE]: defaultLayers.raster.satellite.map,
      }[this.layerType()];
      baseLayer.setMin(this.mapService.MIN_ZOOM);

      map.setBaseLayer(baseLayer);
    });

    effect(() => {
      if (!this.behaviors) return;

      this.toggleBehaviors(this.active() && !this.showPreview());
    });
  }

  public ngAfterViewInit(): void {
    this.updateControlsDirection();

    timer(this.defer() ? 0 : ONYX_TRANSITION_DURATION)
      .pipe(takeUntil(this.destroy$), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        effect(() => (this.active() ? this.showMap() : this.hideMap()), {
          injector: this.injector,
        });
      });
  }

  public dispatch(event: OnyxMapEvent): void {
    this.queue.add(event);
  }

  protected openStandaloneMap(): void {
    this.mapService.openStandaloneMap();
  }

  protected zoomIn(): void {
    const map = this.map();
    if (!map) return;

    map.setZoom(map.getZoom() + this.mapService.ZOOM_STEP);
  }

  protected zoomOut(): void {
    const map = this.map();
    if (!map) return;

    map.setZoom(map.getZoom() - this.mapService.ZOOM_STEP);
  }

  @HostListener('document:keydown.escape')
  protected minimizeMap(): void {
    if (this.mode() !== OnyxMapMode.MAXIMIZED) return;
    this.mode.set(OnyxMapMode.MINIMIZED);
  }

  private toggleBehaviors(enable = true): void {
    for (const behavior of this.mapService.BEHAVIORS) {
      enable
        ? this.behaviors?.enable(behavior)
        : this.behaviors?.disable(behavior);
    }

    const element = this.map()?.getViewPort().element as HTMLElement;
    element.style.pointerEvents = enable ? 'all' : 'none';
  }

  private showMap(): void {
    const defaultLayers = this.hereMapsService.getDefaultLayers();
    const map = new H.Map(
      this.elementRef.nativeElement,
      defaultLayers.vector.normal.map,
      {
        center: this.mapService.CENTER_POINT,
        zoom: this.mapService.INITIAL_ZOOM,
        pixelRatio: window.devicePixelRatio || 1,
        padding: {
          top: 0,
          right: this.sizes() ? 0 : 32,
          bottom: 0,
          left: this.sizes() ? 0 : 640,
        },
      },
    );
    this.map.set(map);

    this.behaviors = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    const ui = H.ui.UI.createDefault(map, defaultLayers);
    ui.removeControl('mapsettings');
    ui.removeControl('zoom');
    ui.removeControl('scalebar');
    this.ui = ui;

    map.addEventListener('pointermove', (event) => {
      let isActive = false;
      if (event.target instanceof H.map.AbstractMarker) {
        isActive = event.target.getData()?.callback != null;
      } else if (event.target instanceof H.map.Polyline) {
        isActive = true;
      }

      this.elementRef.nativeElement.style.cursor = isActive
        ? 'pointer'
        : 'auto';
    });

    map.addEventListener('tap', (event) => {
      const isMarker = event.target instanceof H.map.AbstractMarker;
      const isPolyline = event.target instanceof H.map.Polyline;
      if (isMarker || isPolyline) return;

      this.dispatch(new OnyxMapEvent.SelectVehicle({ id: null }));
      this.mapClick.emit();
    });

    fromEventPattern<void>(
      (handler) => {
        const resizeObserver = new ResizeObserver(() => handler());
        resizeObserver.observe(this.elementRef.nativeElement);

        map.addOnDisposeCallback(() => resizeObserver?.disconnect());
        return resizeObserver;
      },
      (_, resizeObserver?: ResizeObserver) => resizeObserver?.disconnect(),
    )
      .pipe(skip(1), debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.resizeMap());

    setTimeout(() =>
      this.queue.events$
        .pipe(takeUntil(this.destroy$), takeUntilDestroyed(this.destroyRef))
        .subscribe((event) => this.processEvent(event)),
    );
  }

  private hideMap(): void {
    this.map()?.dispose();
    this.map.set(null);
    this.behaviors?.dispose();
    this.behaviors = null;
    this.ui?.dispose();
    this.ui = null;

    this.destroy$.next();
    this._markers.clear();
  }

  private processEvent(event: OnyxMapEvent): void {
    switch (event.TYPE) {
      case OnyxMapEventType.ADD_UPDATE_VEHICLE:
        return this.addUpdateVehicle(
          event.id,
          event.status,
          event.coordinates,
          event.azimuth,
          event.visible,
        );
      case OnyxMapEventType.REMOVE_VEHICLE:
        return this.removeVehicle(event.id);
      case OnyxMapEventType.SELECT_VEHICLE:
        return this.selectVehicle(event.id);

      case OnyxMapEventType.ADD_UPDATE_ROUTE_POINT:
        return this.addUpdateRoutePoint(event.id, event.point, event.callback);
      case OnyxMapEventType.REMOVE_ROUTE_POINT:
        return this.removeRoutePoint(event.id);

      case OnyxMapEventType.ADD_UPDATE_ROUTE:
        return this.addUpdateRoute(
          event.id,
          event.route,
          event.points,
          event.options,
          event.callback,
        );
      case OnyxMapEventType.REMOVE_ROUTE:
        return this.removeRoute(event.id);

      case OnyxMapEventType.ADD_UPDATE_MARKER:
        return this.addUpdateMarker(
          event.id,
          event.type,
          event.coordinates,
          event.callback,
        );
      case OnyxMapEventType.REMOVE_MARKER:
        return this.removeMarker(event.id);

      case OnyxMapEventType.FIT_CONTENT:
        return this.fitContent();
    }
  }

  private addUpdateVehicle(
    id: string,
    status: OnyxMapVehicleStatus,
    coordinates: OnyxCoordinates,
    azimuth: number,
    visible: boolean,
  ): void {
    azimuth = MapHelper.getVehicleStatusAzimuth(status, azimuth) ?? 0;
    const selected = this.selectedVehicleId === id;

    const createIcon = () => {
      const containerElement = document.createElement('div');
      containerElement.className = `vehicle ${status}`;
      containerElement.setAttribute('id', id);

      const markerElement = document.createElement('div');
      markerElement.className = 'marker';
      containerElement.appendChild(markerElement);

      const iconElement = document.createElement('div');
      iconElement.className = 'icon';
      iconElement.style.transform = `translate(-50%, -50%) rotate(${azimuth}deg)`;
      markerElement.appendChild(iconElement);

      return new H.map.DomIcon(containerElement);
    };

    const geometry: H.geo.IPoint = {
      lat: coordinates.latitude,
      lng: coordinates.longitude,
    };
    const data = { id, status, azimuth };

    let marker = this._vehicles.get(id);
    if (marker) {
      marker.setGeometry(geometry);

      if (!isEqual(marker.getData(), data)) {
        marker.setData(data);
        this.updateVehicleIcon(marker, selected);
      }

      if (marker.getVisibility() !== visible) marker.setVisibility(visible);
      return;
    }

    marker = new H.map.DomMarker(geometry, {
      icon: createIcon(),
      data,
      visibility: visible,
      zIndex: OnyxMapObjectZIndex.VEHICLE,
    });

    marker.addEventListener('tap', () =>
      this.dispatch(new OnyxMapEvent.SelectVehicle({ id })),
    );

    this.map()!.addObject(marker);
    this._vehicles.set(id, marker);
  }

  private removeVehicle(id: string): void {
    const marker = this._vehicles.get(id);
    if (!marker) return;

    this.map()!.removeObject(marker);
    this._vehicles.delete(id);
  }

  private selectVehicle(id: string | null): void {
    if (id === this.selectedVehicleId) return;

    if (this.selectedVehicleId) {
      const marker = this._vehicles.get(this.selectedVehicleId);
      if (marker) this.updateVehicleIcon(marker, false);
    }

    if (id != null) {
      const marker = this._vehicles.get(id);
      if (!marker) return;

      this.updateVehicleIcon(marker, true);
    }

    this.selectedVehicleId = id;
    this.selectedVehicleIdChange.emit(id);
  }

  private addUpdateRoutePoint(
    id: number,
    point: OnyxMapRoutePoint,
    callback: () => void,
  ): void {
    const { coordinates } = point;
    const geometry: H.geo.IPoint = {
      lat: coordinates?.latitude ?? 0,
      lng: coordinates?.longitude ?? 0,
    };
    const data = { id, point, callback };

    let marker = this._routePoints.get(id);
    if (marker) {
      marker.setGeometry(geometry);
      marker.setVisibility(!!coordinates);

      if (!isEqual(marker.getData(), data)) {
        marker.setData(data);
        this.updateRoutePointIcon(marker);
      }
      return;
    }

    marker = new H.map.DomMarker(geometry, {
      icon: this.createRoutePointIcon(id, point),
      data,
      visibility: !!coordinates,
      zIndex: OnyxMapObjectZIndex.ROUTE_POINT,
    });
    marker.addEventListener('tap', () => callback());

    this.map()!.addObject(marker);
    this._routePoints.set(id, marker);
  }

  private removeRoutePoint(id: number): void {
    const marker = this._routePoints.get(id);
    if (!marker) return;

    this.map()!.removeObject(marker);
    this._routePoints.delete(id);
  }

  private addUpdateRoute(
    id: number,
    route: OnyxRoute,
    points: OnyxMapRoutePoint[],
    options: OnyxMapRouteOptions | undefined,
    callback: (index: number) => void,
  ): void {
    const objects = this._routes.get(id) ?? [];
    if (objects.length) {
      this.removeRoute(id);
      objects.splice(0, objects.length);
    }

    const routePoints = points.filter((point) => point.coordinates != null);
    const activeSections = new Set<number>();

    let lastIncludedActive: number | null = null;
    for (const [index, point] of routePoints.entries()) {
      const isIncluded =
        point.included || index === 0 || index === routePoints.length - 1;
      if (point.active && isIncluded) {
        if (lastIncludedActive !== null) {
          for (let i = lastIncludedActive + 1; i <= index; i++) {
            activeSections.add(i);
          }
        }
        lastIncludedActive = index;
      } else if (isIncluded) {
        lastIncludedActive = null;
      }
    }

    for (const [pointIndex, point] of routePoints.entries()) {
      const legs = route.points[pointIndex].legs;
      for (const leg of legs) {
        const isFirstLeg = leg === legs[0];
        const isLastLeg = leg === legs.at(-1);

        let polylineStyle: PolylineOptions['style'];
        if (point.empty) {
          polylineStyle = 'empty';
        } else if (leg.type === 'transit') {
          polylineStyle =
            point.type === OnyxMapRoutePointType.TRAIN ? 'train' : 'ferry';
        } else if (leg.type === 'vehicle') {
          polylineStyle = 'vehicle';
        } else {
          throw new Error(`Unknown leg type: ${leg.type}`);
        }

        const geometry = H.geo.LineString.fromFlexiblePolyline(leg.polyline);
        if (pointIndex === 1 && isFirstLeg && points[0].coordinates) {
          geometry.spliceLatLngAlts(0, 0, [
            points[0].coordinates.latitude,
            points[0].coordinates.longitude,
            0,
          ]);
        } else if (isLastLeg && point.coordinates) {
          geometry.pushLatLngAlt(
            point.coordinates.latitude,
            point.coordinates.longitude,
            0,
          );
        }

        const styles = getPolylineStyles({
          ...point,
          active: activeSections.has(pointIndex),
          style: polylineStyle,
          alternative: options?.alternative,
        });
        for (const style of styles) {
          const polyline = new H.map.Polyline(geometry, {
            style,
            zIndex: OnyxMapObjectZIndex.ROUTE,
          });
          polyline.addEventListener('tap', () => callback(pointIndex));
          objects.push(polyline);
        }

        if (!point.completed && !options?.alternative) {
          for (const passthrough of leg.passthroughs) {
            const { latitude, longitude } = passthrough.coordinates;
            const marker = new H.map.DomMarker(
              { lat: latitude, lng: longitude },
              {
                icon: this.createRoutePointIcon(-1, passthrough),
                data: { callback },
                zIndex: OnyxMapObjectZIndex.ROUTE_POINT,
              },
            );
            marker.addEventListener('tap', () => callback(pointIndex));
            objects.push(marker);
          }
        }
      }
    }

    this.map()!.addObjects(objects);
    this._routes.set(id, objects);
  }

  private removeRoute(id: number): void {
    const objects = this._routes.get(id);
    if (!objects) return;

    this.map()!.removeObjects(objects);
    this._routes.delete(id);
  }

  private addUpdateMarker(
    id: string,
    type: OnyxMapMarkerType,
    coordinates: OnyxCoordinates,
    callback: (() => void) | undefined,
  ): void {
    const createIcon = () => new H.map.Icon(`map/markers/${type}.svg`);

    const geometry: H.geo.IPoint = {
      lat: coordinates.latitude,
      lng: coordinates.longitude,
    };
    const data = { id, type, callback };

    let marker = this._markers.get(id);
    if (marker) {
      marker.setGeometry(geometry);

      const previousData = marker.getData();
      if (!isEqual(previousData, data)) {
        marker.setIcon(createIcon());
        marker.setData(data);
        if (previousData.callback) {
          marker.removeEventListener('tap', previousData.callback);
        }
        if (callback) {
          marker.addEventListener('tap', callback);
        }
      }
      return;
    }

    marker = new H.map.Marker(geometry, {
      icon: createIcon(),
      data,
      zIndex: OnyxMapObjectZIndex.MARKER,
    });
    if (callback) marker.addEventListener('tap', callback);

    this.map()!.addObject(marker);
    this._markers.set(id, marker);
  }

  private removeMarker(id: string): void {
    const marker = this._markers.get(id);
    if (!marker) return;

    this.map()!.removeObject(marker);
    this._markers.delete(id);
  }

  private fitContent(): void {
    const map = this.map()!;
    const visibleObjects = map
      .getObjects()
      .filter(
        (object) =>
          object instanceof H.map.AbstractMarker ||
          object instanceof H.map.Polyline,
      )
      .filter((object) => object.getVisibility());
    if (!visibleObjects.length) {
      map.setCenter(this.mapService.CENTER_POINT);
      map.setZoom(this.mapService.INITIAL_ZOOM);
      return;
    }

    const boundingBox = visibleObjects
      .slice(1)
      .reduce(
        (boundingBox, object) =>
          boundingBox.mergeRect(object.getGeometry().getBoundingBox()),
        visibleObjects[0].getGeometry().getBoundingBox(),
      );
    const horizontalPadding = Math.max(0.5, 0.03 * boundingBox.getWidth());
    const verticalPadding = Math.max(0.5, 0.03 * boundingBox.getHeight());

    const viewBox = new H.geo.Rect(
      boundingBox.getTop() + verticalPadding,
      boundingBox.getLeft() - horizontalPadding,
      boundingBox.getBottom() - verticalPadding,
      boundingBox.getRight() + horizontalPadding,
    );

    map.getViewModel().setLookAtData({ bounds: viewBox });
  }

  private updateVehicleIcon(marker: H.map.DomMarker, selected: boolean): void {
    let containerElement: HTMLElement | null = null;
    let iconElement: HTMLElement | null = null;

    afterNextRender(
      {
        earlyRead: () => {
          const { id } = marker.getData();
          containerElement = this.elementRef.nativeElement.querySelector(
            `.vehicle[id="${id}"]`,
          );
          iconElement = containerElement?.querySelector('.icon') ?? null;
        },
        write: () => {
          if (!containerElement || !iconElement) return;

          const { status, azimuth } = marker.getData();
          containerElement.className = `vehicle ${status}`;
          containerElement.classList.toggle('selected', selected);

          iconElement.style.transform = `translate(-50%, -50%) rotate(${azimuth}deg)`;
        },
      },
      { injector: this.injector },
    );
  }

  private createRoutePointIcon(
    id: number,
    point: OnyxMapRoutePoint | OnyxRouteBorder,
  ): H.map.DomIcon {
    const isBorder = point.type === 'border';

    const containerElement = document.createElement('div');
    containerElement.className = `route-point ${point.type}`;

    if (!isBorder) {
      containerElement.classList.toggle('active', point.active);
      containerElement.classList.toggle('completed', point.completed);
      containerElement.classList.toggle('on-time', point.onTime);
      containerElement.setAttribute('id', `${id}`);
    }

    const markerElement = document.createElement('div');
    markerElement.className = 'marker';
    containerElement.appendChild(markerElement);

    const iconElement = document.createElement('div');
    iconElement.className = 'icon';
    markerElement.appendChild(iconElement);

    if (isBorder) {
      iconElement.style.backgroundImage = `url('flags/${point.to}.svg')`;
    } else {
      const icon: OnyxIconName = point.type === 'other' ? 'place' : point.type;
      iconElement.style.maskImage = `url('icons/${icon}.svg')`;
    }

    return new H.map.DomIcon(containerElement);
  }

  private updateRoutePointIcon(marker: H.map.DomMarker): void {
    let containerElement: HTMLElement | null = null;
    let iconElement: HTMLElement | null = null;

    afterNextRender(
      {
        earlyRead: () => {
          const { id } = marker.getData();

          containerElement = this.elementRef.nativeElement.querySelector(
            `.route-point[id="${id}"]`,
          );
          iconElement = containerElement?.querySelector('.icon') ?? null;
        },
        write: () => {
          if (!containerElement || !iconElement) return;

          const { type, active, completed, onTime } = marker.getData()
            .point as OnyxMapRoutePoint;
          containerElement.className = `route-point ${type}`;
          containerElement.classList.toggle('active', active);
          containerElement.classList.toggle('completed', completed);
          containerElement.classList.toggle('on-time', onTime);

          const icon: OnyxIconName = type === 'other' ? 'place' : type;
          iconElement.style.maskImage = `url('icons/${icon}.svg')`;
        },
      },
      { injector: this.injector },
    );
  }

  private resizeMap(): void {
    this.map()!.getViewPort().resize();
    this.updateControlsDirection();
  }

  private updateControlsDirection(): void {
    const CONTROLS_PADDING = 8;

    const controlsElement = this.controlsElementRef()?.nativeElement;
    if (!controlsElement) return;

    const mapHeight = this.elementRef.nativeElement.offsetHeight;
    const controlsSize =
      Math.max(controlsElement.offsetHeight, controlsElement.offsetWidth) +
      2 * CONTROLS_PADDING;

    this.controlsElementRef()!.nativeElement.classList.toggle(
      'column',
      controlsSize < mapHeight,
    );
  }
}
