import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DictionaryCode } from '../../../../../../common/enums/dictionary-code';
import { FleetIdentifierPipe } from '../../../../../../common/pipes/fleet-identifier.pipe';
import { FleetHelper } from '../../../../../fleet/common/helpers/fleet.helper';
import { SimplifiedFleet } from '../../../../../fleet/common/interfaces/fleet';

@Component({
  selector: 'app-delegation-modal-vehicle-item',
  imports: [FleetIdentifierPipe, TranslatePipe],
  templateUrl: './delegation-modal-vehicle-item.component.html',
  styleUrl: './delegation-modal-vehicle-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalVehicleItemComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public vehicle = input.required<SimplifiedFleet>();

  protected isVehicle = computed(() => FleetHelper.isVehicle(this.vehicle()));

  constructor(private fleetHelper: FleetHelper) {}

  protected openVehicleModal(): void {
    this.fleetHelper.openModal(this.vehicle());
  }
}
