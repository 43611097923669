import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxIconBoxComponent,
  OnyxIconComponent,
  OnyxModalService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { FleetIdentifierPipe } from '../../../../common/pipes/fleet-identifier.pipe';
import { I18nPipe } from '../../../../common/pipes/i18n.pipe';
import { FleetHelper } from '../../../fleet/common/helpers/fleet.helper';
import { BaseHelper } from '../../../management-panel/bases/common/helpers/base.helper';
import { DriverAssignVehicleModalComponent } from '../../common/components/driver-assign-vehicle-modal/driver-assign-vehicle-modal.component';
import { DriverHelper } from '../../common/helpers/driver.helper';
import { Driver } from '../../common/interfaces/driver';
import { DriverStatus } from '../../common/interfaces/driver-status';
import { DriverModalDelegationComponent } from './driver-modal-delegation/driver-modal-delegation.component';
import { DriverModalStatusComponent } from './driver-modal-status/driver-modal-status.component';

@Component({
  selector: 'app-driver-modal-main-section',
  imports: [
    DriverModalStatusComponent,
    DriverModalDelegationComponent,
    OnyxIconBoxComponent,
    OnyxIconComponent,
    FleetIdentifierPipe,
    TranslatePipe,
    I18nPipe,
  ],
  templateUrl: './driver-modal-main-section.component.html',
  styleUrl: './driver-modal-main-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalMainSectionComponent {
  protected readonly I18N = 'drivers.driverModal';

  protected readonly DriverStatus = DriverStatus;

  public driver = input.required<Driver>();
  public close$ = input.required<Subject<void>>();

  constructor(
    private driverHelper: DriverHelper,
    private fleetHelper: FleetHelper,
    private baseHelper: BaseHelper,
    private modalService: OnyxModalService,
  ) {}

  protected edit(): void {
    this.driverHelper.edit(this.driver().uuid, { close$: this.close$() });
  }

  protected assignVehicle(): void {
    this.modalService.open(DriverAssignVehicleModalComponent, this.driver());
  }

  protected openVehicleModal(): void {
    const vehicle = this.driver().assignedVehicle;
    if (!vehicle) return;

    this.fleetHelper.openModal(vehicle);
  }

  protected openBaseModal(): void {
    const base = this.driver().driverData?.assignedBase;
    if (!base) return;

    this.baseHelper.openModal(base);
  }
}
