<onyx-modal
  type="center"
  size="m"
  [heading]="
    fleet.trailer || fleet.vehicle ? I18N + '.editSet' : I18N + '.newSet'
  "
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <div class="container">
    @if (fleet.trailer) {
      <onyx-tabs
        [label]="I18N + '.whatToDo'"
        [tabs]="TABS"
        [(value)]="mode"
        [forceOptional]="true"
        [fill]="true"
        size="m"
        width="100%"
      ></onyx-tabs>
    }
    @let isChangeMode = mode() === FleetSetsModalSection.CHANGE;
    @if (isChangeMode) {
      <form class="switch" [formGroup]="changeForm">
        <div class="item">
          @let controls = changeForm.controls;
          <onyx-dropdown
            [formControl]="controls.vehicle"
            label="labels.vehicle"
            [options]="VEHICLES_SOURCE"
            [optional]="true"
            (selectedValueChange)="vehicle.set($event)"
          ></onyx-dropdown>

          <onyx-icon-button
            type="transparent"
            color="black"
            size="l"
            [disabled]="!vehicle()"
            (click)="openFleetModal(vehicle())"
          >
            <onyx-icon name="side-modal" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>

        <div class="item">
          <onyx-dropdown
            [formControl]="controls.trailer"
            [label]="trailerLabel()"
            [options]="TRAILERS_SOURCE"
            [optional]="true"
            (selectedValueChange)="trailer.set($event)"
          ></onyx-dropdown>

          <onyx-icon-button
            type="transparent"
            color="black"
            size="l"
            [disabled]="!trailer()"
            (click)="openFleetModal(trailer())"
          >
            <onyx-icon name="side-modal" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      </form>

      @if (warningMessage(); as message) {
        <onyx-message size="m" type="warning" [borderRadius]="true">
          <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

          {{ `${I18N}.${message.type}` | translate: message }}
        </onyx-message>
      }
    } @else {
      <form class="swap" [formGroup]="swapForm">
        @let first = firstSet(); @let second = secondSet();
        <div class="item">
          @let controls = swapForm.controls;
          <onyx-dropdown
            [formControl]="controls.firstSet"
            [label]="I18N + '.firstSet'"
            [hint]="I18N + '.secondaryDriverHint'"
            [options]="SETS_SOURCE"
            (selectedValueChange)="firstSet.set($event)"
          ></onyx-dropdown>

          @if (first && second) {
            <onyx-icon name="arrow-right" [size]="16"></onyx-icon>

            <onyx-text-field
              [label]="I18N + '.firstSet'"
              [disabled]="true"
              [value]="`${(first | fleetIdentifier: 'bracket')} · ${second.trailer | fleetIdentifier: 'bracket'}`"
            ></onyx-text-field>
          }
        </div>

        <div class="item">
          <onyx-dropdown
            [formControl]="controls.secondSet"
            [label]="I18N + '.secondSet'"
            [options]="SETS_SOURCE"
            [optional]="true"
            (selectedValueChange)="secondSet.set($event)"
          ></onyx-dropdown>

          @if (second && first) {
            <onyx-icon name="arrow-right" [size]="16"></onyx-icon>

            <onyx-text-field
              [label]="I18N + '.secondSet'"
              [disabled]="true"
              [value]="`${(second | fleetIdentifier: 'bracket')} · ${first.trailer | fleetIdentifier: 'bracket'}`"
            ></onyx-text-field>
          }
        </div>
      </form>
    }
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'labels.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [spinner]="loading()"
      [disabled]="isChangeMode ? !changeFormChanged() : false"
      (click)="
        !loading()
          ? isChangeMode
            ? submitChangeForm()
            : submitSwapForm()
          : null
      "
    >
      {{ 'labels.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
