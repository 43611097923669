@let generalInformation = vehicle().generalInformation;

<div class="vehicle-heading">
  <p>{{ generalInformation.make }} {{ generalInformation.model }}</p>

  @if (generalInformation.type; as type) {
    <span>·</span>

    <p>
      {{ DictionaryCode.VEHICLE_TYPE + '.' + type | translate }}
    </p>
  }
</div>
