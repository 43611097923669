<section class="rate">
  <p class="f-medium-5 f-gray">
    {{ `${I18N}.rate` | translate | uppercase }}
  </p>

  <div class="container f-regular-4">
    <div class="prices">
      <div class="item f-medium-3">
        <p class="f-gray">{{ 'labels.net' | translate }}</p>

        {{ settlementData().net | onyxAmount: 'value' : false }}
      </div>

      <div class="item">
        @let vatRate = settlementData().vat.vatRate;
        <p class="f-gray">
          {{ 'labels.vat' | translate }} ({{ vatRate
          }}{{ CommonHelper.isNumeric(vatRate) ? '%' : '' }})
        </p>

        {{ settlementData().vat | onyxAmount: 'value' : false }}
      </div>

      <div class="item">
        <p class="f-gray">{{ 'labels.gross' | translate }}</p>

        {{ settlementData().gross | onyxAmount: 'value' : false }}
      </div>
    </div>

    <div class="information">
      <div class="wrapper f-regular-3">
        <span class="f-gray">{{ 'labels.paymentTerm' | translate }}</span>

        @if (contractor$() | async; as contractor) {
          {{ contractor.payment.termDays }} {{ 'labels.days' | translate }}
        } @else {
          -
        }
      </div>

      <p class="f-regular-4 f-gray">{{`${I18N}.nbpRate` | translate}}</p>
    </div>
  </div>
</section>
