import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  FormControl,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  FormHelper,
  I18N_LANGUAGES,
  I18nLanguage,
  OnyxButtonComponent,
  OnyxFormGroupComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { map } from 'rxjs';
import { SetNonNullable } from 'type-fest';
import { DictionaryCode } from '../../../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../../../common/helpers/validation.helper';
import { CompanyDictionariesService } from '../../../services/company-dictionaries.service';

type DictionariesNamesTranslationFormGroup = ReturnType<
  typeof DictionariesNamesTranslationFormComponent.buildFom
>;

export type DictionariesNamesTranslation = SetNonNullable<
  ReturnType<DictionariesNamesTranslationFormGroup['getRawValue']>
>;

@Component({
  selector: 'app-dictionaries-names-translation-form',
  imports: [
    ReactiveFormsModule,
    OnyxFormGroupComponent,
    OnyxTextFieldComponent,
    OnyxButtonComponent,
    NgClass,
    TranslatePipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
  ],
  templateUrl: './dictionaries-names-translation-form.component.html',
  styleUrl: './dictionaries-names-translation-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DictionariesNamesTranslationFormComponent implements OnInit {
  protected readonly I18N = 'dictionaries';

  protected readonly DictionaryCode = DictionaryCode;

  public form = input.required<DictionariesNamesTranslationFormGroup>();
  public label = input.required<string>();
  public initialValue = input<string>();

  protected language = toSignal(
    this.translateService.onLangChange.pipe(
      map((event) => event.lang as I18nLanguage),
    ),
    {
      initialValue: (this.translateService.currentLang ??
        this.translateService.defaultLang) as I18nLanguage,
    },
  );
  protected languages = computed(() =>
    I18N_LANGUAGES.filter((language) => language !== this.language()),
  );
  protected showTranslations = signal(false);
  protected loading = signal(false);

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
    private toastService: OnyxToastService,
    private companyDictionariesService: CompanyDictionariesService,
  ) {}

  public ngOnInit(): void {
    const initialValue = this.initialValue();
    if (this.initialValue()) {
      this.form().patchValue({ [this.language()]: initialValue });
    }

    effect(
      () => {
        for (const language of I18N_LANGUAGES) {
          const control = this.form().controls[language];
          if (language === this.language()) {
            control.addValidators([Validators.required]);
          } else {
            control.removeValidators([Validators.required]);
          }
          control.updateValueAndValidity();
        }
      },
      { injector: this.injector },
    );
  }

  protected toggleTranslations(): void {
    this.showTranslations.update((showTranslations) => !showTranslations);
  }

  protected suggestTranslations(): void {
    const requiredControl = this.form().controls[this.language()];
    if (!ValidationHelper.checkValidity(requiredControl, this.toastService)) {
      return;
    }

    this.loading.set(true);
    this.companyDictionariesService
      .suggestTranslations(this.language(), requiredControl.value!)
      .subscribe({
        next: (translations) => this.form().setValue(translations),
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected clearTranslations(): void {
    for (const language of this.languages()) {
      FormHelper.reset(this.form().controls[language]);
    }
  }

  public static buildFom(fb: NonNullableFormBuilder) {
    return fb.group(
      Object.fromEntries(
        I18N_LANGUAGES.map((language) => [
          language,
          fb.control<string | null>(null),
        ]),
      ) as Record<I18nLanguage, FormControl<string | null>>,
    );
  }
}
