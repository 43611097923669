import { moveItemInArray } from '@angular/cdk/drag-drop';
import { get } from 'lodash';
import { Subject } from 'rxjs';
import { OnyxMapEvent } from '../../components/map';
import { OnyxMapEventType } from '../../components/map/enums/onyx-map-event-type';

export class OnyxMapEventQueue {
  private events$_ = new Subject<OnyxMapEvent>();
  public get events$() {
    setTimeout(() => {
      for (const event of this.events_) this.events$_.next(event);
    });
    return this.events$_.asObservable();
  }

  private events_: OnyxMapEvent[] = [];

  public add(event: OnyxMapEvent): void {
    this.addEvent(event);
    this.events$_.next(event);
  }

  private addEvent(event: OnyxMapEvent): void {
    switch (event.TYPE) {
      case OnyxMapEventType.ADD_UPDATE_VEHICLE:
      case OnyxMapEventType.REMOVE_VEHICLE:
        this.events_ = this.events_.filter(
          (e) =>
            !(
              [
                OnyxMapEventType.ADD_UPDATE_VEHICLE,
                OnyxMapEventType.REMOVE_VEHICLE,
              ].includes(e.TYPE) && get(e, 'id') === event.id
            ),
        );

        if (event.TYPE === OnyxMapEventType.ADD_UPDATE_VEHICLE) {
          const selectVehicleEvent = this.events_.findIndex(
            (e) =>
              e.TYPE === OnyxMapEventType.SELECT_VEHICLE && e.id === event.id,
          );
          this.events_.push(event);

          if (selectVehicleEvent !== -1) {
            moveItemInArray(
              this.events_,
              selectVehicleEvent,
              this.events_.length - 1,
            );
          }
        } else if (event.TYPE === OnyxMapEventType.REMOVE_VEHICLE) {
          this.events_ = this.events_.filter(
            (e) =>
              !(
                e.TYPE === OnyxMapEventType.SELECT_VEHICLE &&
                get(e, 'id') === event.id
              ),
          );
        }
        break;

      case OnyxMapEventType.SELECT_VEHICLE:
        this.events_ = this.events_.filter(
          (e) => e.TYPE !== OnyxMapEventType.SELECT_VEHICLE,
        );
        if (event.id) this.events_.push(event);
        break;

      case OnyxMapEventType.ADD_UPDATE_ROUTE_POINT:
      case OnyxMapEventType.REMOVE_ROUTE_POINT:
        this.events_ = this.events_.filter(
          (e) =>
            !(
              [
                OnyxMapEventType.ADD_UPDATE_ROUTE_POINT,
                OnyxMapEventType.REMOVE_ROUTE_POINT,
              ].includes(e.TYPE) && get(e, 'id') === event.id
            ),
        );

        if (event.TYPE === OnyxMapEventType.ADD_UPDATE_ROUTE_POINT) {
          this.events_.push(event);
        }
        break;

      case OnyxMapEventType.ADD_UPDATE_ROUTE:
      case OnyxMapEventType.REMOVE_ROUTE:
        this.events_ = this.events_.filter(
          (e) =>
            !(
              [
                OnyxMapEventType.ADD_UPDATE_ROUTE,
                OnyxMapEventType.REMOVE_ROUTE,
              ].includes(e.TYPE) && get(e, 'id') === event.id
            ),
        );

        if (event.TYPE === OnyxMapEventType.ADD_UPDATE_ROUTE) {
          this.events_.push(event);
        }
        break;

      case OnyxMapEventType.ADD_UPDATE_MARKER:
      case OnyxMapEventType.REMOVE_MARKER:
        this.events_ = this.events_.filter(
          (e) =>
            !(
              [
                OnyxMapEventType.ADD_UPDATE_MARKER,
                OnyxMapEventType.REMOVE_MARKER,
              ].includes(e.TYPE) && get(e, 'id') === event.id
            ),
        );
        if (event.TYPE === OnyxMapEventType.ADD_UPDATE_MARKER) {
          this.events_.push(event);
        }
        break;

      case OnyxMapEventType.FIT_CONTENT:
        this.events_ = this.events_.filter(
          (e) => e.TYPE !== OnyxMapEventType.FIT_CONTENT,
        );
        this.events_.push(event);
        break;
    }
  }
}
