@if (countryCode(); as countryCode) {
  <div class="country-cell">
    <onyx-flag [countryCode]="countryCode"></onyx-flag>

    @if (!onlyFlag()) {
      <p>
        @if (short()) {
          {{ countryCode | uppercase }}
        } @else {
          {{ countryCode | i18n: DictionaryCode.COUNTRY | translate }}
        }
      </p>
    }
  </div>
} @else {
  -
}
