@let overflows = flags().length > max();
<div
  class="container"
  [onyxTooltip]="flagsTooltipTemplate"
  [onyxTooltipDelay]="TOOLTIP_DELAY"
  [onyxTooltipEnabled]="overflows"
>
  @for (flag of visibleFlags(); track flag) {
    <onyx-flag
      [countryCode]="flag"
      [size]="size()"
      [showTooltip]="!overflows"
    ></onyx-flag>
  }

  @if (overflows) {
    <div
      class="counter f-medium-4"
      [style.width.px]="size()"
      [style.height.px]="size()"
    >
      +{{ flags().length - visibleFlags().length }}
    </div>
  }
</div>

<ng-template #flagsTooltipTemplate>
  <div class="tooltip">
    <p>{{ I18N + '.countries' | translate }}:</p>

    @for (flag of flags(); track flag) {
      <div class="item">
        <span>•</span>

        <span>{{ 'country.' + flag | translate }}</span>
      </div>
    }
  </div>
</ng-template>
