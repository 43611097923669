<onyx-loading-banner [visible]="!initialLoading() && loading() && !error()">
  {{ I18N + '.loading' | translate }}
</onyx-loading-banner>

<onyx-ribbon [marginBottom]="20">
  <ng-content select="[left]" left></ng-content>
  <ng-container right>
    <ng-content select="[right]"></ng-content>

    @if (views().length) {
      <onyx-dropdown
        [options]="views()"
        [value]="view()?.uuid"
        [ribbon]="true"
        [placeholder]="I18N + '.views'"
        [showAddOption]="!!customViews()"
        [addOptionMessage]="I18N + '.addView'"
        [optionTemplateRef]="viewOptionTemplate"
        width="max-content"
        (selectedOptionsChange)="changeView($any($event[0])?.view ?? null)"
        (addOption)="addView()"
      >
        <onyx-icon ribbonIcon name="views" [size]="16"></onyx-icon>
      </onyx-dropdown>
    }
    @if (showViewMode()) {
      <onyx-tabs [tabs]="VIEW_MODE_TABS" [(value)]="viewMode"></onyx-tabs>
    }
  </ng-container>
</onyx-ribbon>

@switch (viewMode()) {
  @case (OnyxListViewMode.LIST) {
    <div
      class="table"
      [ngClass]="{ shadow: tableShadow(), border: !tableShadow() }"
      [ngStyle]="{
        'height.px':
          (showHeader() ? 32 : 0) +
          (pagination() ? 48 : 0) +
          ((data()?.items?.length ?? 0) * rowSize() || 160) +
          (cdkVirtualScrollViewport?.elementRef?.nativeElement?.offsetHeight ??
            0) -
          (cdkVirtualScrollViewport?.elementRef?.nativeElement?.clientHeight ??
            0),
      }"
    >
      <cdk-virtual-scroll-viewport
        [itemSize]="rowSize()"
        #cdkVirtualScrollViewport
      >
        <table class="{{ tableSize() }}">
          @if (showHeader()) {
            <thead>
              @let top = stickyTop;
              <tr>
                <ng-container
                  *ngTemplateOutlet="
                    headerPaddingTemplate;
                    context: { top, frozenLeft: hasFrozenLeft() }
                  "
                ></ng-container>

                @if (selectionType() != null) {
                  <th
                    class="selection center frozen"
                    [ngClass]="{ shadow: hasFrozenShadows().left }"
                    [ngStyle]="{
                      'minWidth.px': SELECTION_COLUMN_WIDTH,
                      'width.px': SELECTION_COLUMN_WIDTH,
                      top,
                      'left.px': tablePadding(),
                    }"
                  >
                    <div class="cell">
                      @if (data()?.items?.length) {
                        @switch (selectionType()) {
                          @case (OnyxTableSelectionType.CHECKBOX) {
                            <onyx-checkbox
                              [value]="
                                isPageCheckboxChecked() ||
                                isPageCheckboxIndeterminate()
                              "
                              [indeterminate]="isPageCheckboxIndeterminate()"
                              (valueChange)="togglePageItems(data()!, $event)"
                              [onyxTooltip]="
                                I18N +
                                '.batchActions' +
                                (isPageCheckboxChecked() ||
                                isPageCheckboxIndeterminate()
                                  ? isPageCheckboxIndeterminate()
                                    ? '.unselectPageSelected'
                                    : '.unselectPage'
                                  : '.selectPage')
                              "
                              onyxTooltipColor="white"
                            ></onyx-checkbox>
                          }
                          @case (OnyxTableSelectionType.RADIO) {}
                        }
                      }
                    </div>
                  </th>
                }
                @for (column of visibleColumns(); track column.id) {
                  <!-- This empty ng-container fixes ERROR Error: ASSERTION ERROR: Expected [Text|Element|Container|ElementContainer|Projection|IcuContainer] but got LetDeclaration. -->
                  <ng-container></ng-container>
                  @let frozen = $first && column.required;
                  <th
                    [class]="column.align ?? 'left'"
                    [ngClass]="{
                      first: $first,
                      last: $last && !rowOptions(),
                      frozen,
                      shadow: hasFrozenShadows().left,
                    }"
                    [ngStyle]="{
                      minWidth:
                        column.width !== 'fill' ? column.width : undefined,
                      width: column.width === 'fill' ? '100%' : undefined,
                      top,
                      'left.px': frozen ? frozenRequiredLeft() : null,
                    }"
                  >
                    <div
                      class="cell"
                      [onyxTooltip]="column.name ?? ''"
                      [onyxTooltipEnabled]="
                        OverflowHelper.enableOverflowTooltip.bind(
                          this,
                          columnTextElement
                        )
                      "
                    >
                      @if (column.icon) {
                        <onyx-icon
                          class="f-gray"
                          [name]="column.icon.name"
                          [size]="column.icon.size"
                          [frame]="column.icon.frame"
                          [onyxTooltip]="column.tooltip ?? ''"
                        ></onyx-icon>
                      }

                      <p class="f-regular-4" #columnTextElement>
                        {{ column.name ?? '' | translate }}
                      </p>
                    </div>
                  </th>
                }
                @if (rowOptions()) {
                  <th
                    class="last frozen"
                    [ngClass]="{ shadow: hasFrozenShadows().right }"
                    [ngStyle]="{
                      'minWidth.px': OPTIONS_COLUMN_WIDTH,
                      'width.px': OPTIONS_COLUMN_WIDTH,
                      top,
                      'right.px': 0,
                    }"
                  ></th>
                } @else {
                  <ng-container
                    *ngTemplateOutlet="
                      headerPaddingTemplate;
                      context: { top, frozenRight: hasFrozenRight() }
                    "
                  ></ng-container>
                }
              </tr>
            </thead>
          }

          <tbody>
            <ng-container
              *cdkVirtualFor="
                let item of data()?.items;
                index as index;
                trackBy: itemIdentity.bind(this)
              "
            >
              <!-- This empty ng-container fixes ERROR Error: ASSERTION ERROR: Expected [Text|Element|Container|ElementContainer|Projection|IcuContainer] but got LetDeclaration. -->
              <ng-container></ng-container>
              @let id = getItemId(item);
              @let selected = selectedItemsId().includes(id);
              @let color =
                rowColor()
                  ? rowColor()!({ item, index })
                  : OnyxTableColor.WHITE;
              <tr
                class="{{ selected ? OnyxTableColor.BLUE : color }}"
                [ngClass]="{ hover: showRowClick() }"
                [ngStyle]="{ 'height.px': rowSize() }"
                (click)="rowClick.emit({ item, index })"
              >
                @if (!initialLoading()) {
                  <ng-container
                    *ngTemplateOutlet="
                      rowPaddingTemplate;
                      context: { frozenLeft: hasFrozenLeft() }
                    "
                  ></ng-container>

                  @if (selectionType() != null) {
                    <td
                      class="selection center frozen"
                      [ngClass]="{ shadow: hasFrozenShadows().left }"
                      [ngStyle]="{
                        'minWidth.px': SELECTION_COLUMN_WIDTH,
                        'width.px': SELECTION_COLUMN_WIDTH,
                        'left.px': tablePadding(),
                      }"
                      (click)="
                        toggleItem(item, !selected); $event.stopPropagation()
                      "
                    >
                      <div class="cell">
                        @switch (selectionType()) {
                          @case (OnyxTableSelectionType.CHECKBOX) {
                            <onyx-checkbox
                              [value]="selected"
                              (valueChange)="toggleItem(item, $event)"
                              (click)="$event.stopPropagation()"
                            ></onyx-checkbox>
                          }
                          @case (OnyxTableSelectionType.RADIO) {
                            <ng-container [formGroup]="radioButtonSelection">
                              <onyx-radio-button
                                [formControl]="
                                  radioButtonSelection.controls.itemId
                                "
                                [option]="id"
                                (click)="$event.stopPropagation()"
                              ></onyx-radio-button>
                            </ng-container>
                          }
                        }
                      </div>
                    </td>
                  }
                  @for (column of visibleColumns(); track column.id) {
                    <!-- This empty ng-container fixes ERROR Error: ASSERTION ERROR: Expected [Text|Element|Container|ElementContainer|Projection|IcuContainer] but got LetDeclaration. -->
                    <ng-container></ng-container>
                    @let frozen = $first && column.required;
                    <td
                      class="
                  {{ column.align ?? 'left' }} 
                  {{ column.color ? column.color({ item, index }) : '' }}
                  "
                      [ngClass]="{
                        first: $first,
                        last: $last && !rowOptions(),
                        frozen,
                        shadow: hasFrozenShadows().left,
                      }"
                      [ngStyle]="{
                        minWidth:
                          column.width !== 'fill' ? column.width : undefined,
                        width: column.width === 'fill' ? '100%' : undefined,
                        'left.px': frozen ? frozenRequiredLeft() : null,
                      }"
                    >
                      <div class="cell">
                        <ng-container
                          *ngComponentOutlet="
                            column.component.ref;
                            inputs: column.component.inputs({
                              item,
                              index,
                            })
                          "
                        ></ng-container>
                      </div>

                      @if (column.actions.length) {
                        <div class="actions">
                          @for (action of column.actions; track action) {
                            @if (
                              action.hidden == null ||
                              !action.hidden({ item, index })
                            ) {
                              <onyx-icon-button
                                type="transparent"
                                color="black"
                                size="s"
                                [onyxTooltip]="$any(action).tooltip"
                                (click)="
                                  action.callback({ item, index });
                                  $event.stopPropagation()
                                "
                              >
                                @let icon = action.icon;
                                <onyx-icon
                                  [name]="$any(icon).name"
                                  [size]="$any(icon).size"
                                  [frame]="$any(icon).frame"
                                ></onyx-icon>
                              </onyx-icon-button>
                            }
                          }
                        </div>
                      }
                    </td>
                  }
                  @if (rowOptions(); as rowOptions) {
                    <td
                      class="center last frozen {{ OnyxTableColor.WHITE }}"
                      [ngClass]="{ shadow: hasFrozenShadows().right }"
                      [ngStyle]="{
                        'minWidth.px': OPTIONS_COLUMN_WIDTH,
                        'width.px': OPTIONS_COLUMN_WIDTH,
                        'right.px': 0,
                      }"
                      (click)="$event.stopPropagation()"
                    >
                      @let options =
                        isFunction(rowOptions)
                          ? rowOptions({ item, index })
                          : rowOptions;
                      <div
                        class="cell"
                        tabindex="-1"
                        [onyxDropdownOptions]="options"
                        [onyxDropdownOptionTemplateRef]="rowOptionTemplateRef()"
                        (onyxDropdownValueChange)="$event!()"
                        [onyxDropdownDisabled]="!options?.length"
                        (click)="$event.stopPropagation()"
                        (keydown.space)="$event.stopPropagation()"
                      >
                        <onyx-icon-button
                          type="transparent"
                          color="black"
                          size="m"
                          [disabled]="!options?.length"
                        >
                          <onyx-icon
                            name="options-horizontal"
                            [size]="16"
                          ></onyx-icon>
                        </onyx-icon-button>
                      </div>
                    </td>
                  } @else {
                    <ng-container
                      *ngTemplateOutlet="
                        rowPaddingTemplate;
                        context: { frozenRight: hasFrozenRight() }
                      "
                    ></ng-container>
                  }
                }
              </tr>
            </ng-container>
          </tbody>
        </table>
      </cdk-virtual-scroll-viewport>

      <div
        class="empty-body"
        [ngStyle]="{
          'paddingTop.px': showHeader() ? 32 : 0,
          'paddingBottom.px': pagination() ? 48 : 0,
        }"
      >
        @if (initialLoading()) {
          <onyx-spinner></onyx-spinner>
        } @else if (data()?.items?.length === 0) {
          <div class="not-found">
            @let icon = notFound().icon ?? DEFAULT_NOT_FOUND_ICON;
            <onyx-icon
              [name]="icon.name"
              [size]="icon.size"
              [frame]="icon.frame"
            ></onyx-icon>

            <div class="text">
              <p class="f-semibold-2">
                @let title = notFound().title ?? DEFAULT_NOT_FOUND_TITLE;
                {{ title | translate }}
              </p>

              @if (notFound().text; as notFoundText) {
                <p>{{ notFoundText | translate }}</p>
              }
              @if (notFound().link; as notFoundLink) {
                <onyx-link
                  color="blue"
                  font="f-description-2"
                  (click)="notFoundLink.callback()"
                >
                  {{ notFoundLink.text | translate }}
                </onyx-link>
              }
            </div>
          </div>
        } @else if (error()) {
          <onyx-error [(error)]="error"></onyx-error>
        }
      </div>

      @if (pagination()) {
        <onyx-pagination
          [pagination]="pagination()!"
          [totalItems]="data()?.totalItems"
          [showLimit]="paginationShowLimit()"
          [padding]="paginationPadding()"
          (paginationChange)="pagination.set($event)"
        >
          <ng-content select="[statistics]"></ng-content>
        </onyx-pagination>
      }
    </div>
  }
  @default {
    <ng-content></ng-content>
  }
}
@let showBatchActions =
  batchActionsTemplateRef() &&
  selectionType() === OnyxTableSelectionType.CHECKBOX &&
  selectedItemsId().length;
<div class="batch-actions" [ngClass]="{ show: showBatchActions }">
  <p class="f-medium-2">
    {{ I18N + '.batchActions.selected' | translate }}
    {{ selectedItemsId().length | number }}
  </p>

  <onyx-icon class="arrow" name="arrow-right" [size]="16"></onyx-icon>

  <ng-container *ngTemplateOutlet="batchActionsTemplateRef()!"></ng-container>

  <onyx-icon-button
    class="close"
    type="primary"
    color="black"
    size="m"
    [onyxTooltip]="I18N + '.batchActions.unselectClose'"
    onyxTooltipColor="white"
    (click)="clearSelection()"
  >
    <onyx-icon name="close" [size]="12"></onyx-icon>
  </onyx-icon-button>
</div>

<ng-template let-option="option" #viewOptionTemplate>
  <div class="view-option">
    <p>{{ option.name | translate }}</p>

    @let view = option.view;
    @if (!view.default) {
      <onyx-icon-button
        type="transparent"
        color="black"
        size="xs"
        [circle]="true"
        (click)="editView(view)"
      >
        <onyx-icon name="edit" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </div>
</ng-template>

<ng-template
  let-top="top"
  let-frozenLeft="frozenLeft"
  let-frozenRight="frozenRight"
  #headerPaddingTemplate
>
  @if (tablePadding()) {
    <th
      class="padding"
      [ngClass]="{
        frozen: frozenLeft || frozenRight,
        shadow:
          (frozenLeft && hasFrozenShadows().left) ||
          (frozenRight && hasFrozenShadows().right),
      }"
      [ngStyle]="{
        'minWidth.px': tablePadding(),
        'width.px': tablePadding(),
        top,
        'left.px': frozenLeft ? 0 : null,
        'right.px': frozenRight ? 0 : null,
      }"
    ></th>
  }
</ng-template>

<ng-template
  let-frozenLeft="frozenLeft"
  let-frozenRight="frozenRight"
  #rowPaddingTemplate
>
  @if (tablePadding()) {
    <td
      class="padding"
      [ngClass]="{
        frozen: frozenLeft || frozenRight,
        shadow:
          (frozenLeft && hasFrozenShadows().left) ||
          (frozenRight && hasFrozenShadows().right),
      }"
      [ngStyle]="{
        'minWidth.px': tablePadding(),
        'width.px': tablePadding(),
        'left.px': frozenLeft ? 0 : null,
        'right.px': frozenRight ? 0 : null,
      }"
    ></td>
  }
</ng-template>
