import { AsyncPipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxDatePipe, OnyxNamePipe, OnyxPhonePipe } from '@onyx/angular';
import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { AuthService } from '../../../../../../../auth/common/services/auth.service';
import { AddressComponent } from '../../../../../../../common/components/address/address.component';
import { ContractorsService } from '../../../../../../management-panel/contractors/common/services/contractors.service';
import { Order } from '../../../../../common/interfaces/order';

@Component({
  selector: 'app-order-pdf-template-contractors',
  imports: [
    TranslatePipe,
    UpperCasePipe,
    OnyxDatePipe,
    AddressComponent,
    OnyxNamePipe,
    OnyxPhonePipe,
    AsyncPipe,
  ],
  templateUrl: './order-pdf-template-contractors.component.html',
  styleUrl: './order-pdf-template-contractors.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPdfTemplateContractorsComponent {
  protected readonly I18N = 'orders.pdfTemplate';

  public order = input.required<Order>();

  protected business = this.authService.business()!;
  protected today = DateTime.now().toISO();

  protected contractor$ = computed(() => {
    const contractorUuid = this.order().outsourcingData?.contractor.uuid;
    if (!contractorUuid) return of(null);

    return this.contractorsService.getContractor(contractorUuid);
  });

  constructor(
    private authService: AuthService,
    private contractorsService: ContractorsService,
  ) {}
}
