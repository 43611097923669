<section class="route">
  <p class="f-regular-5 f-gray">
    {{ 'labels.route' | translate | uppercase }}
  </p>

  <div class="container">
    <div class="bar-container">
      <div
        class="bar"
        [style.height]="`calc(100% - ${lastPointItemHeight()}px)`"
      ></div>
    </div>

    <div class="wrapper">
      @for (point of orderPoints(); track point.uuid) {
        <div class="item" #routeItem>
          <div class="details-container">
            @let isLoading = point.category === OrderPointCategory.LOADING;
            @let isUnloading = point.category === OrderPointCategory.UNLOADING;
            <div class="heading">
              <p class="f-medium-3">
                {{ `${DictionaryCode.ORDER_POINT_TYPE}.${point.type}` | translate }}
              </p>

              @if (
                (isLoading || isUnloading) && point.referenceNumber;
                as referenceNumber
              ) {
                <span class="f-gray f-regular-4">
                  {{ 'labels.referenceNumber' | translate }}
                  {{ referenceNumber }}
                </span>
              }
            </div>

            <div class="details f-regular-4">
              @let isTransit = point.category === OrderPointCategory.TRANSIT;
              @let isCheckpoint =
                point.category === OrderPointCategory.CHECKPOINT;
              @if (isTransit || isCheckpoint) {
                {{ point.name }}
              }

              <app-address
                [address]="isTransit ? point.startAddress : point.address"
                [endAddress]="isTransit ? point.endAddress : null"
                [format]="isTransit ? 'transit' : 'pdf'"
              ></app-address>

              @if (isLoading || isUnloading) {
                <div class="goods">
                  @for (good of goods$(point) | async; track good.uuid) {
                    <span class="f-gray">
                      {{
                        good.type
                          ? (good.type.names | onyxLanguage)
                          : good.typeName
                      }}
                      {{ good.quantity }}x
                      {{ good.unit.names | onyxLanguage }}

                      @if (good.referenceNumber) {
                        ·
                        {{ 'labels.referenceNumber' | translate }}
                        {{ good.referenceNumber }}
                      }
                    </span>
                  }
                </div>
              }

              @if (isCheckpoint && point.phone; as phone) {
                <p class="f-gray">{{ phone | onyxPhone }}</p>
              }

              <p class="f-gray">{{ point.driverNote }}</p>
            </div>
          </div>

          <div class="time-windows f-gray f-regular-4">
            @if (getTimeWindows(point); as pointTimeWindows) {
              @let timeWindows = pointTimeWindows.value;

              @for (timeWindow of timeWindows; track timeWindow) {
                <p>{{ timeWindow }}</p>
              }
            }
          </div>
        </div>
      }
    </div>
  </div>
</section>
