import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Injector,
  input,
  OnInit,
  output,
  Signal,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxToastService,
  OnyxToggleComponent,
  OnyxTooltipComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { map } from 'rxjs';
import {
  DictionaryCode,
  DictionaryType,
} from '../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { I18nPipe } from '../../../../../common/pipes/i18n.pipe';
import { OrderPointCategory } from '../../../common/enums/order-point-category';
import { OrderFormGood } from '../../../common/interfaces/order-form-good';
import {
  OrderCheckpointFormComponent,
  OrderCheckpointFormGroup,
} from '../order-checkpoint-form/order-checkpoint-form.component';
import {
  OrderLoadingPointFormComponent,
  OrderLoadingPointFormGroup,
} from '../order-loading-point-form/order-loading-point-form.component';
import {
  OrderTransitFormComponent,
  OrderTransitFormGroup,
} from '../order-transit-form/order-transit-form.component';
import {
  OrderUnloadingPointFormComponent,
  OrderUnloadingPointFormGroup,
} from '../order-unloading-point-form/order-unloading-point-form.component';

export type OrderPointFormGroup =
  | OrderLoadingPointFormGroup
  | OrderUnloadingPointFormGroup
  | OrderCheckpointFormGroup
  | OrderTransitFormGroup;
export type OrderPointForm = ReturnType<OrderPointFormGroup['getRawValue']>;

@Component({
  selector: 'app-order-point-form',
  imports: [
    TranslatePipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    NgClass,
    ReactiveFormsModule,
    OnyxToggleComponent,
    OnyxTooltipComponent,
    OrderLoadingPointFormComponent,
    OrderUnloadingPointFormComponent,
    OrderCheckpointFormComponent,
    OrderTransitFormComponent,
    OnyxTooltipDirective,
    I18nPipe,
  ],
  templateUrl: './order-point-form.component.html',
  styleUrl: './order-point-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPointFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.points';

  protected readonly DictionaryCode = DictionaryCode;
  protected readonly OrderPointCategory = OrderPointCategory;

  public form = input.required<OrderPointFormGroup>();
  public category = input.required<OrderPointCategory>();
  public type = input.required<string>();
  public typeIndex = input.required<number>();
  public goods = input.required<OrderFormGood[]>();
  public first = input.required<boolean>();
  public last = input.required<boolean>();
  public pointTypes = input.required<
    DictionaryType<DictionaryCode.ORDER_POINT_TYPE> | undefined
  >();

  public movePoint = output<-1 | 1>();
  public removePoint = output();

  protected expanded = signal(true);

  protected point = computed(() =>
    this.pointTypes()?.find(({ value }) => value === this.type()),
  );

  protected invalidTimeWindow!: Signal<boolean>;

  constructor(
    private toastService: OnyxToastService,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    this.invalidTimeWindow = toSignal(
      this.form().statusChanges.pipe(
        map(() => this.form().hasError('invalidTimeWindow')),
      ),
      { initialValue: false, injector: this.injector },
    );
  }

  protected toggleExpanded(): void {
    this.expanded.update((expanded) => {
      if (!expanded) return true;
      if (ValidationHelper.checkValidity(this.form(), null)) return false;

      this.toastService.showError(`${this.I18N}.collapseError`);
      return true;
    });
  }
}
