@if (categories()?.length) {
  <div class="driver-license-cell {{ cellColor() }}">
    <ng-container
      *ngTemplateOutlet="
        driverLicenseTemplate;
        context: { categories: categories() }
      "
    ></ng-container>
  </div>

  <div class="hidden">
    <ng-container
      *ngTemplateOutlet="
        driverLicenseTemplate;
        context: { categories: categories() }
      "
    ></ng-container>
  </div>
} @else {
  -
}

<ng-template let-categories="categories" #driverLicenseTemplate>
  @for (category of categories; track category.category) {
    <span>
      {{
        DictionaryCode.DRIVERS_LICENSE_CATEGORY + '.' + category.category
          | translate
      }}

      @if (category.expirationDate?.indefinite) {
        ({{ 'labels.indefinite' | translate | lowercase }}){{
          !$last ? ',' : ''
        }}
      } @else {
        ({{ 'labels.to' | translate | lowercase }}

        {{ category.expirationDate?.date | onyxDate: 'date-dot' }}){{
          !$last ? ',' : ''
        }}
      }
    </span>
  }
</ng-template>
