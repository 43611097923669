import { Injectable } from '@angular/core';
import {
  OnyxDropdownOptionsSource,
  OnyxFilterPipe,
  OnyxOption,
} from '@onyx/angular';
import { chain } from 'lodash';
import { OrderPointType } from '../../dashboard/orders/common/enums/order-point-type';
import { FERRY_ROUTES } from '../constants/transit/ferry-routes';
import { TRAIN_ROUTES } from '../constants/transit/train-routes';
import { TUNNEL_ROUTES } from '../constants/transit/tunnel-routes';
import { TransitType } from '../enums/transit/transit-type';
import { TransitRoute } from '../interfaces/transit/transit-route';
import { DurationPipe } from '../pipes/duration.pipe';

@Injectable({
  providedIn: 'root',
})
export class TransitHelper {
  constructor(
    private filterPipe: OnyxFilterPipe,
    private durationPipe: DurationPipe,
  ) {}

  public getRoutesSource(
    type: TransitType,
  ): OnyxDropdownOptionsSource<TransitRoute> {
    const ROUTES = {
      [OrderPointType.FERRY]: FERRY_ROUTES,
      [OrderPointType.TRAIN]: TRAIN_ROUTES,
      [OrderPointType.TUNNEL]: TUNNEL_ROUTES,
    }[type];

    const buildOption = (route: TransitRoute): OnyxOption<TransitRoute> => ({
      name: `${route.from.name} (${route.from.address.countryCode.toUpperCase()}) → ${route.to.name} (${route.to.address.countryCode.toUpperCase()})`,
      value: route,
      leftFlag: route.from.address.countryCode,
      description: `${route.operator} · ${route.frequency}`,
      hint: this.durationPipe.transform(route.duration, {
        format: 'to',
        units: ['h'],
      })!,
      rightFlag: route.to.address.countryCode,
    });

    return {
      list: (query, limit) => ({
        options: chain(ROUTES)
          .map((route) => buildOption(route))
          .orderBy((route) => route.name)
          .thru((options) =>
            this.filterPipe
              .transform(options, query, ['name', 'description'])
              .slice(0, limit),
          )
          .value(),
        totalItems: ROUTES.length,
      }),
      get: (id) => {
        const route = ROUTES.find((route) => route.id === id);
        return route ? buildOption(route) : null;
      },
      idKey: 'id',
    };
  }
}
