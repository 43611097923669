@let isTimeEstimated =
  firstPointTimestamp().isEstimated || lastPointTimestamp().isEstimated;
<onyx-data-widget
  [supheading]="
    isOrderFinished()
      ? 'labels.durationTime'
      : isTimeEstimated
        ? ('estimatedDuration' | i18n: I18N)
        : ('maxDuration' | i18n: I18N)
  "
  [headingIcon]="{ name: 'clock', size: 16, color: 'green' }"
>
  <div heading class="heading">
    {{
      this.firstPointTimestamp().full
        | duration: { end: this.lastPointTimestamp().full, size: 'm' }
    }}

    @if (realizationResult(); as result) {
      <span class="result {{ result.color }} f-semibold-2">
        {{ result.sign }}{{ result.difference }}
      </span>
    }
  </div>

  <div class="list">
    <div class="item f-medium-3">
      <p class="f-gray">
        {{
          'labels' + (firstPointTimestamp().isEstimated ? '.etaStart' : '.from')
            | translate
        }}
      </p>

      <div class="date">
        {{ firstPointTimestamp().date | onyxDate: 'short-date-dot' }}

        <span class="f-gray">·</span>

        <span class="f-gray">
          {{ firstPointTimestamp().time | onyxTime }}
        </span>
      </div>
    </div>

    <div class="item f-medium-3">
      <p class="f-gray">
        {{ 'labels' + (isTimeEstimated ? '.etaFinish' : '.to') | translate }}
      </p>

      <div class="date">
        {{ lastPointTimestamp().date | onyxDate: 'short-date-dot' }}

        <span class="f-gray">·</span>

        <span class="f-gray">
          {{ lastPointTimestamp().time | onyxTime }}
        </span>
      </div>
    </div>
  </div>

  @if (plannedData(); as plannedData) {
    <div class="plan f-gray">
      <span>{{ 'labels.plan' | translate }}</span>

      <p>
        {{ plannedData.firstPoint | duration: { end: plannedData.lastPoint } }}
      </p>
    </div>
  }
</onyx-data-widget>
