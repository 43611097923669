import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  Injector,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxAmountForm,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxFormGroupComponent,
  OnyxInputLabelComponent,
  OnyxModalComponent,
  OnyxOptional,
  OnyxTextFieldComponent,
  OnyxToastService,
  OnyxToggleComponent,
} from '@onyx/angular';
import { chain } from 'lodash';
import {
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  combineLatest,
  filter,
  forkJoin,
  map,
  of,
  startWith,
} from 'rxjs';
import { CalculateRouteFeature } from '../../../../../common/enums/calculate-route-feature';
import {
  DictionaryCode,
  DictionaryType,
} from '../../../../../common/enums/dictionary-code';
import { FuelType } from '../../../../../common/enums/fuel-type';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { RouteConfig } from '../../../../../common/interfaces/address/calculate-route-request';
import { I18nPipe } from '../../../../../common/pipes/i18n.pipe';
import { DataProvidersService } from '../../../../../common/services/data-providers.service';
import { DictionariesService } from '../../../../../common/services/dictionaries.service';
import { PreferencesService } from '../../../../../common/services/preferences.service';
import { FleetCategory } from '../../../../fleet/common/enums/fleet-category';

export type OrderFormRouteConfigModalData = OnyxOptional<RouteConfig>;

export type OrderFormRouteConfigModalResult = RouteConfig;

@Component({
  selector: 'app-order-form-route-config-modal',
  standalone: true,
  imports: [
    OnyxModalComponent,
    ReactiveFormsModule,
    TranslatePipe,
    OnyxButtonComponent,
    OnyxDropdownComponent,
    AsyncPipe,
    OnyxInputLabelComponent,
    OnyxToggleComponent,
    I18nPipe,
    OnyxFormGroupComponent,
    OnyxTextFieldComponent,
  ],
  templateUrl: './order-form-route-config-modal.component.html',
  styleUrl: './order-form-route-config-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormRouteConfigModalComponent {
  protected readonly FEATURES = chain(CalculateRouteFeature)
    .orderBy((feature) =>
      this.translateService.instant(
        `${DictionaryCode.CALCULATE_ROUTE_FEATURE}.${feature}`,
      ),
    )
    .value();

  protected readonly DictionaryCode = DictionaryCode;

  protected readonly vehicleCategories$ =
    this.dictionariesService.getDictionary(DictionaryCode.VEHICLE_CATEGORY);
  protected readonly fuelTypes$ = this.dictionariesService
    .getDictionary(DictionaryCode.FUEL_TYPE)
    .pipe(
      map((options) =>
        chain(options)
          .groupBy((option) => option.averageSemiTruckFuelConsumption !== 0)
          .thru((groups) => [
            { options: groups['true'] },
            {
              options: groups['false'].map((option) => ({
                ...option,
                tooltip: 'labels.unsupportedFuelType',
                disabled: true,
              })),
            },
          ])
          .value(),
      ),
    );
  protected readonly currencies$ = this.dictionariesService.getDictionary(
    DictionaryCode.CURRENCY,
  );
  protected readonly countries$ = this.dictionariesService.getDictionary(
    DictionaryCode.COUNTRY,
  );

  protected form = this.buildForm();
  protected loading = signal(false);

  protected vehicleCategory$ = new ReplaySubject<
    DictionaryType<DictionaryCode.VEHICLE_CATEGORY>[number]['value'] | null
  >(1);
  protected fuelType$ = new ReplaySubject<
    DictionaryType<DictionaryCode.FUEL_TYPE>[number] | null
  >(1);
  protected close$ = new Subject<OrderFormRouteConfigModalResult | void>();

  protected fuelAverageConsumptionUnit$ = this.fuelType$.pipe(
    startWith(null),
    map((fuelType) => `${fuelType?.units[0] ?? ''}/100 km`),
  );
  protected fuelPriceLabel$ = this.fuelType$.pipe(
    startWith(null),
    map((fuelType) => {
      if (!fuelType) return 'labels.fuelPrice';
      return `${this.translateService.instant('labels.fuelPrice')} (${fuelType.units[0]})`;
    }),
  );

  constructor(
    @Inject(DIALOG_DATA) protected data: OrderFormRouteConfigModalData,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private dictionariesService: DictionariesService,
    private translateService: TranslateService,
    private preferencesService: PreferencesService,
    private destroyRef: DestroyRef,
    private injector: Injector,
  ) {
    this.reset(this.data);

    combineLatest([this.fuelType$, this.vehicleCategory$])
      .pipe(
        filter((data) => data.every((value) => value != null)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([fuelType, vehicleCategory]) => {
        const averageFuelConsumption =
          OrderFormRouteConfigModalComponent.getAverageFuelConsumption(
            fuelType!,
            vehicleCategory!,
          );

        const controls = this.form.controls;
        controls.fuelAverageConsumption.setValue(averageFuelConsumption);
        ValidationHelper.toggleControls(
          [controls.fuelAverageConsumption, controls.fuelPrice],
          averageFuelConsumption !== 0,
        );
      });
  }

  protected restoreDefaults(): void {
    if (this.loading()) return;

    this.loading.set(true);
    OrderFormRouteConfigModalComponent.getDefaultConfig(this.injector, {
      vehicleCategory: this.data?.vehicleCategory,
      vehicleTypes: [],
      resultCurrency: this.data?.resultCurrency,
    })
      .subscribe({
        next: (config) => this.reset(config),
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected save(): void {
    if (this.loading()) return;
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const value = this.form.getRawValue();
    this.close$.next({
      resultCurrency:
        this.data?.resultCurrency ?? this.preferencesService.defaultCurrency(),
      vehicleCategory: value.vehicleCategory!,
      excludeCountries: value.excludeCountries?.length
        ? value.excludeCountries
        : undefined,
      fuelType: value.fuelType!,
      fuelAverageConsumption: value.fuelAverageConsumption!,
      fuelPrice: value.fuelPrice.value
        ? (value.fuelPrice as OnyxAmountForm)
        : undefined,
      avoidFeatures: chain(value.avoidFeatures)
        .entries()
        .filter(([_, value]) => value)
        .map(([key]) => key as CalculateRouteFeature)
        .orderBy((key) => this.FEATURES.indexOf(key))
        .thru((features) => (features.length ? features : undefined))
        .value(),
    });
  }

  private reset(config: OnyxOptional<RouteConfig>): void {
    FormHelper.reset(this.form);
    this.form.setValue({
      vehicleCategory: config?.vehicleCategory ?? null,
      fuelType: config?.fuelType ?? this.preferencesService.defaultFuelType(),
      fuelAverageConsumption: config?.fuelAverageConsumption ?? null,
      fuelPrice: {
        date: config?.fuelPrice?.date ?? null,
        value: config?.fuelPrice?.value ?? null,
        currency: config?.fuelPrice?.currency ?? null,
      },
      excludeCountries: config?.excludeCountries ?? null,
      avoidFeatures: chain(CalculateRouteFeature)
        .values()
        .map((feature) => [
          feature,
          config?.avoidFeatures?.includes(feature) ?? false,
        ])
        .fromPairs()
        .value() as Record<CalculateRouteFeature, boolean>,
    });
  }

  private buildForm() {
    return this.fb.group({
      vehicleCategory: this.fb.control<RouteConfig['vehicleCategory'] | null>(
        null,
        [Validators.required],
      ),
      fuelType: this.fb.control<FuelType | null>(null, [Validators.required]),
      fuelAverageConsumption: this.fb.control<number | null>(null, [
        Validators.required,
      ]),
      fuelPrice: FormHelper.buildAmountForm(this.fb, this.preferencesService),
      excludeCountries: this.fb.control<string[] | null>(null),
      avoidFeatures: this.fb.group(
        chain(CalculateRouteFeature)
          .values()
          .map((feature) => [
            feature,
            this.fb.control<boolean>(false, [Validators.required]),
          ])
          .fromPairs()
          .value() as Record<CalculateRouteFeature, FormControl<boolean>>,
      ),
    });
  }

  public static getDefaultConfig(
    injector: Injector,
    options: {
      vehicleCategory?: DictionaryType<DictionaryCode.VEHICLE_CATEGORY>[number]['value'];
      vehicleTypes: string[];
      resultCurrency?: string;
    },
  ): Observable<RouteConfig> {
    const dictionariesService = injector.get(DictionariesService);
    const preferencesService = injector.get(PreferencesService);
    const dataProvidersService = injector.get(DataProvidersService);

    const [countryCode, currency, fuelType] = [
      preferencesService.defaultCountry(),
      preferencesService.defaultCurrency(),
      preferencesService.defaultFuelType(),
    ];
    const vehicleCategory =
      options.vehicleCategory ??
      (options.vehicleTypes.every((type) =>
        type.startsWith(`${FleetCategory.VAN}-`),
      )
        ? FleetCategory.VAN
        : FleetCategory.SEMI_TRUCK);

    const fuelAverageConsumption$ = dictionariesService
      .getDictionary(DictionaryCode.FUEL_TYPE)
      .pipe(
        map((fuelTypes) => fuelTypes.find(({ value }) => value === fuelType)!),
        map((fuelTypeData) =>
          OrderFormRouteConfigModalComponent.getAverageFuelConsumption(
            fuelTypeData,
            vehicleCategory,
          ),
        ),
      );
    const fuelPrice$ = dataProvidersService.getFuelPrices().pipe(
      map(({ date, prices }): OnyxAmountForm | undefined => {
        const data = prices[countryCode]?.[currency];
        if (!data) return undefined;

        const value = (() => {
          switch (fuelType) {
            case FuelType.DIESEL:
            case FuelType.DIESEL_LNG:
              return data.diesel.price;

            case FuelType.LNG:
            case FuelType.LNG_DIESEL:
            case FuelType.PHEV:
            case FuelType.EV:
              return undefined;
          }
        })();
        if (!value) return undefined;

        return {
          date,
          value: Math.round(value * 100),
          currency,
        };
      }),
      catchError(() => of(undefined)),
    );

    return forkJoin([fuelAverageConsumption$, fuelPrice$]).pipe(
      map(([fuelAverageConsumption, fuelPrice]) => ({
        vehicleCategory,
        fuelType,
        fuelAverageConsumption,
        fuelPrice,
        resultCurrency: options?.resultCurrency ?? currency,
      })),
    );
  }

  private static getAverageFuelConsumption(
    fuelTypeData: DictionaryType<DictionaryCode.FUEL_TYPE>[number],
    vehicleCategory: DictionaryType<DictionaryCode.VEHICLE_CATEGORY>[number]['value'],
  ): number {
    switch (vehicleCategory) {
      case FleetCategory.SEMI_TRUCK:
        return fuelTypeData.averageSemiTruckFuelConsumption;
      case FleetCategory.STRAIGHT_TRUCK:
        return fuelTypeData.averageStraightTruckFuelConsumption;
      case FleetCategory.VAN:
        return fuelTypeData.averageVanFuelConsumption;
    }
  }
}
