<onyx-information-heading>
  <div class="title">
    <onyx-icon name="cursor" [size]="16"></onyx-icon>

    {{ 'labels.outsourcing' | translate }}
  </div>

  <div action class="actions">
    <onyx-icon-button
      type="transparent"
      color="black"
      size="m"
      onyxTooltip="labels.downloadPdf"
      (click)="createPdf()"
    >
      <onyx-icon name="download" [size]="16"></onyx-icon>
    </onyx-icon-button>
  </div>

  <div class="pdf" #pdfContent>
    <app-order-pdf-template [order]="order()"></app-order-pdf-template>
  </div>
</onyx-information-heading>

@let outsourcingData = order().outsourcingData!;
<div class="container">
  <onyx-data-widget supheading="labels.contractor">
    <div class="options-container" heading>
      <p>{{ outsourcingData.contractor.companyProfile.displayName }}</p>

      <div class="options">
        <onyx-icon-button
          type="transparent"
          color="black"
          size="s"
          onyxTooltip="labels.preview"
          (click)="openContractorModal()"
        >
          <onyx-icon name="side-modal" [size]="16"></onyx-icon>
        </onyx-icon-button>
      </div>
    </div>
  </onyx-data-widget>

  <onyx-data-widget supheading="labels.contactPerson">
    <div class="options-container" heading>
      @let contactPerson = outsourcingData.contactPerson;
      <p>{{ contactPerson | onyxName }}</p>

      @if (contactPerson) {
        <div class="options">
          <onyx-icon-button
            type="transparent"
            color="black"
            size="s"
            onyxTooltip="labels.sendEmail"
            (click)="ActionHelper.sendEmail(contactPerson.email)"
          >
            <onyx-icon name="envelope" [size]="16"></onyx-icon>
          </onyx-icon-button>

          <onyx-icon-button
            type="transparent"
            color="black"
            size="s"
            onyxTooltip="labels.call"
            (click)="ActionHelper.makeCall(contactPerson.phone)"
          >
            <onyx-icon name="telephone" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      }
    </div>
  </onyx-data-widget>

  <onyx-data-widget supheading="labels.vehicles">
    <div heading class="wrapper">
      <p>{{ outsourcingData.vehicleRegistrationNumber }}</p>

      <p>
        @if (
          outsourcingData.trailerRegistrationNumber;
          as trailerRegistrationNumber
        ) {
          {{ trailerRegistrationNumber }}
        } @else if (order().basicInformation.loadedSemiTrailerUuid) {
          {{ order().basicInformation.loadedSemiTrailerUuid | fleetIdentifier }}
        }
      </p>
    </div>
  </onyx-data-widget>

  <onyx-data-widget supheading="labels.drivers">
    <div heading class="wrapper">
      <app-order-outsourcing-driver
        [driver]="outsourcingData.primaryDriver"
      ></app-order-outsourcing-driver>

      @if (outsourcingData.secondaryDriver; as secondaryDriver) {
        <app-order-outsourcing-driver
          [driver]="secondaryDriver"
        ></app-order-outsourcing-driver>
      }
    </div>
  </onyx-data-widget>
</div>
