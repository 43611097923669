@for (group of groupedIssues(); track group.heading) {
  <div class="group">
    @if ($count > 1) {
      <p class="f-semibold-3">{{ group.heading | translate }}</p>
    }

    @let short = $count === 1 && group.issues.length === 1;
    <div class="issues" [class.short]="short">
      @for (issue of group.issues; track issue) {
        <div class="item">
          @if (!short) {
            <span>•</span>
          }

          <p
            class="f-regular-3"
            [innerHTML]="issue.message | translate: issue.data"
          ></p>
        </div>
      }
    </div>
  </div>
}
