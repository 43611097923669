<div class="delegation-modal-note">
  <h6>{{ 'labels.note' | translate }}</h6>

  @let data = note();
  @if (data && data.content) {
    <div class="note">
      <div class="details f-regular-3">
        <span>{{ 'labels.lastUpdatedAt' | translate }}</span>

        <span>·</span>

        <span>{{ data.updatedAt | onyxDate: 'date-time' }}</span>
      </div>

      <p class="content f-paragraph-regular-4">{{ data.content }}</p>

      <onyx-icon-button
        type="ghost"
        color="black"
        size="m"
        [circle]="true"
        onyxTooltip="labels.edit"
        (click)="edit()"
      >
        <onyx-icon name="edit" [size]="16"></onyx-icon>
      </onyx-icon-button>
    </div>
  } @else {
    <div class="empty">
      <p>{{ 'labels.missingNote' | translate }}</p>

      <onyx-button type="outlined" color="black" size="s" (click)="edit()">
        <onyx-icon
          leftIcon
          class="add-icon"
          name="zoom-in"
          [size]="16"
        ></onyx-icon>

        {{ 'labels.add' | translate }}
      </onyx-button>
    </div>
  }
</div>
