import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, map, of } from 'rxjs';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { PointOfInterestForm } from '../../point-of-interest-form/point-of-interest-form.component';
import { PointsOfInterestRoute } from '../../points-of-interest.routes';
import { PointOfInterestHelper } from '../helpers/point-of-interest.helper';
import { PointsOfInterestService } from '../services/points-of-interest.service';

export const editPointOfInterestResolver: ResolveFn<PointOfInterestForm> = (
  route: ActivatedRouteSnapshot,
) => {
  const pointsOfInterestService = inject(PointsOfInterestService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;

  return pointsOfInterestService.getPointOfInterest(uuid).pipe(
    map((dto) => PointOfInterestHelper.fromDto(dto)),
    catchError((response: HttpErrorResponse) => {
      if (response.status === HttpStatusCode.BadRequest) {
        toastService.showError('pointsOfInterest.pointNotFound', {
          keepOnNavigation: true,
        });
      } else {
        ValidationHelper.handleUnexpectedError(response, toastService, {
          keepOnNavigation: true,
        });
      }

      const urlTree = router.parseUrl(
        PointsOfInterestRoute.POINTS_OF_INTEREST_LIST,
      );
      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};
