import { DecimalPipe, NgClass } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  OnInit,
  computed,
  effect,
  input,
  model,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map } from 'rxjs';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxDropdownComponent } from '../../dropdown';
import { OnyxIconComponent } from '../../icons';
import { ONYX_PAGINATION, PAGINATION_LIMIT_OPTIONS } from '../constants';
import { OnyxPagination } from '../interfaces';

@Component({
  selector: 'onyx-pagination',
  imports: [
    OnyxDropdownComponent,
    OnyxIconComponent,
    TranslatePipe,
    ReactiveFormsModule,
    NgClass,
    DecimalPipe,
  ],
  templateUrl: './onyx-pagination.component.html',
  styleUrl: './onyx-pagination.component.scss',
})
export class OnyxPaginationComponent implements OnInit {
  protected readonly I18N = `${I18N_NAMESPACE}.pagination`;
  protected readonly LIMIT_OPTIONS = PAGINATION_LIMIT_OPTIONS;

  public pagination = model.required<OnyxPagination>();
  public totalItems = input.required({
    transform: (totalItems: number | null | undefined): number =>
      totalItems ?? 0,
  });
  public showLimit = input(true);
  public padding = input(8);

  protected limitControl = this.fb.control(ONYX_PAGINATION.limit);

  protected pageItemsTranslation = computed(() => {
    const pagination = this.pagination();
    return (
      (pagination.page - 1) * pagination.limit +
      1 +
      '-' +
      (pagination.page < this.pages().length
        ? pagination.page * pagination.limit
        : this.totalItems())
    );
  });
  protected pages = computed(() =>
    Array.from({
      length: Math.ceil(this.totalItems() / this.pagination().limit),
    }).map((_, index) => index + 1),
  );

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private fb: NonNullableFormBuilder,
    private injector: Injector,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    effect(
      () =>
        (this.elementRef.nativeElement.style.padding = `0 ${this.padding()}px`),
      { injector: this.injector },
    );

    toObservable(this.pagination, { injector: this.injector })
      .pipe(
        map((pagination) => pagination.limit),
        distinctUntilChanged(),
        filter((limit) => limit !== this.limitControl.value),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((limit) => this.limitControl.setValue(limit));

    const limit = toSignal(this.limitControl.valueChanges, {
      initialValue: this.limitControl.value,
      injector: this.injector,
    });
    effect(() => this.changeLimit(limit()), { injector: this.injector });
  }

  protected previousPage(): void {
    const page = this.pagination().page;
    if (page <= 1) return;

    this.changePage(page - 1);
  }

  protected nextPage(): void {
    const page = this.pagination().page;
    if (page >= this.pages().length) return;

    this.changePage(page + 1);
  }

  protected changePage(page: number): void {
    this.pagination.update((pagination) => ({
      ...pagination,
      page,
    }));
  }

  private changeLimit(limit: number): void {
    this.pagination.update((pagination) => ({
      page:
        Math.floor(((pagination.page - 1) * pagination.limit + 1) / limit) + 1,
      limit,
    }));
  }
}
