import { OnyxCoordinates } from '../../address-input';
import { OnyxMapEventType, OnyxMapMarkerType } from '../enums';
import { OnyxMapVehicleStatus } from '../enums/onyx-map-vehicle-status';
import { OnyxMapRouteOptions } from './onyx-map-route-options';
import { OnyxMapRoutePoint } from './onyx-map-route-point';
import { OnyxRoute } from './onyx-route';

export type OnyxMapEvent =
  | OnyxMapEvent.AddUpdateVehicle
  | OnyxMapEvent.RemoveVehicle
  | OnyxMapEvent.SelectVehicle
  | OnyxMapEvent.AddUpdateRoutePoint
  | OnyxMapEvent.RemoveRoutePoint
  | OnyxMapEvent.AddUpdateRoute
  | OnyxMapEvent.RemoveRoute
  | OnyxMapEvent.AddUpdateMarker
  | OnyxMapEvent.RemoveMarker
  | OnyxMapEvent.FitContent;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OnyxMapEvent {
  export class AddUpdateVehicle {
    public readonly TYPE = OnyxMapEventType.ADD_UPDATE_VEHICLE;
    public readonly id: string;
    public readonly status: OnyxMapVehicleStatus;
    public readonly coordinates: OnyxCoordinates;
    public readonly azimuth: number;
    public readonly visible: boolean;

    constructor(
      private data: {
        id: string;
        status: OnyxMapVehicleStatus;
        coordinates: OnyxCoordinates;
        azimuth: number;
        visible: boolean;
      },
    ) {
      ({
        id: this.id,
        status: this.status,
        coordinates: this.coordinates,
        azimuth: this.azimuth,
        visible: this.visible,
      } = data);
    }
  }

  export class RemoveVehicle {
    public readonly TYPE = OnyxMapEventType.REMOVE_VEHICLE;
    public readonly id: string;

    constructor(private data: { id: string }) {
      ({ id: this.id } = data);
    }
  }

  export class SelectVehicle {
    public readonly TYPE = OnyxMapEventType.SELECT_VEHICLE;
    public readonly id: string | null;

    constructor(private data: { id: string | null }) {
      ({ id: this.id } = data);
    }
  }

  export class AddUpdateRoutePoint {
    public readonly TYPE = OnyxMapEventType.ADD_UPDATE_ROUTE_POINT;
    public readonly id: number;
    public readonly point: OnyxMapRoutePoint;
    public readonly callback: () => void;

    constructor(
      private data: {
        id: number;
        point: OnyxMapRoutePoint;
        callback: () => void;
      },
    ) {
      ({ id: this.id, point: this.point, callback: this.callback } = data);
    }
  }

  export class RemoveRoutePoint {
    public readonly TYPE = OnyxMapEventType.REMOVE_ROUTE_POINT;
    public readonly id: number;

    constructor(private data: { id: number }) {
      ({ id: this.id } = data);
    }
  }

  export class AddUpdateRoute {
    public readonly TYPE = OnyxMapEventType.ADD_UPDATE_ROUTE;
    public readonly id: number;
    public readonly route: OnyxRoute;
    public readonly points: OnyxMapRoutePoint[];
    public readonly options?: OnyxMapRouteOptions;
    public readonly callback: (index: number) => void;

    constructor(
      private data: {
        id: number;
        route: OnyxRoute;
        points: OnyxMapRoutePoint[];
        options?: OnyxMapRouteOptions;
        callback: (index: number) => void;
      },
    ) {
      ({
        id: this.id,
        route: this.route,
        points: this.points,
        callback: this.callback,
        options: this.options,
      } = data);
    }
  }

  export class RemoveRoute {
    public readonly TYPE = OnyxMapEventType.REMOVE_ROUTE;
    public readonly id: number;

    constructor(private data: { id: number }) {
      ({ id: this.id } = data);
    }
  }

  export class AddUpdateMarker {
    public readonly TYPE = OnyxMapEventType.ADD_UPDATE_MARKER;
    public readonly id: string;
    public readonly type: OnyxMapMarkerType;
    public readonly coordinates: OnyxCoordinates;
    public readonly callback?: () => void;

    constructor(
      private data: {
        id: string;
        type: OnyxMapMarkerType;
        coordinates: OnyxCoordinates;
        callback?: () => void;
      },
    ) {
      ({
        id: this.id,
        type: this.type,
        coordinates: this.coordinates,
        callback: this.callback,
      } = data);
    }
  }

  export class RemoveMarker {
    public readonly TYPE = OnyxMapEventType.REMOVE_MARKER;
    public readonly id: string;

    constructor(private data: { id: string }) {
      ({ id: this.id } = data);
    }
  }

  export class FitContent {
    public readonly TYPE = OnyxMapEventType.FIT_CONTENT;
  }
}
