<onyx-map></onyx-map>

@if (!contractor().branches.length) {
  <h6>{{ 'labels.mainBranch' | translate }}</h6>
} @else {
  <onyx-chips
    [chips]="branches()"
    type="secondary"
    [single]="true"
    [gap]="12"
    [(activeValues)]="activeBranch"
  ></onyx-chips>
}

<div class="branch">
  @let branch = activeBranch()[0];
  @let addresses = branch.addresses;
  <onyx-information-row
    [label]="`labels.${branch === contractor() ? 'mainAddress' : 'branchAddress'}`"
  >
    <app-address
      [address]="addresses.mainAddress"
      format="full"
      [showFlag]="false"
    ></app-address>
  </onyx-information-row>

  <onyx-information-row label="labels.correspondenceAddress">
    <app-address
      [address]="addresses.correspondenceAddress"
      format="full"
      [showFlag]="false"
    ></app-address>
  </onyx-information-row>
</div>
