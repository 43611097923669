import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { I18nPipe } from '../../../../common/pipes/i18n.pipe';
import { OrderStatus } from '../../common/enums/order-status';
import { OrderHelper } from '../../common/helpers/order.helper';
import { Order } from '../../common/interfaces/order';
import { OrdersService } from '../../common/services/orders.service';
import { OrderModalDocumentsComponent } from '../order-modal-sections/order-modal-documents/order-modal-documents.component';
import { OrderModalInvoiceFormComponent } from './order-modal-invoice-form/order-modal-invoice-form.component';
import { OrderModalPaymentFormContainerComponent } from './order-modal-payment-form-container/order-modal-payment-form-container.component';
import { OrderModalPaymentsHistoryComponent } from './order-modal-payments-history/order-modal-payments-history.component';

@Component({
  selector: 'app-order-modal-finished-section',
  imports: [
    OnyxInformationHeadingComponent,
    OnyxIconComponent,
    OnyxButtonComponent,
    TranslatePipe,
    OrderModalDocumentsComponent,
    OnyxButtonComponent,
    OrderModalInvoiceFormComponent,
    OrderModalPaymentsHistoryComponent,
    OrderModalPaymentFormContainerComponent,
    I18nPipe,
  ],
  templateUrl: './order-modal-finished-section.component.html',
  styleUrl: './order-modal-finished-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalFinishedSectionComponent {
  protected readonly I18N = 'orders.orderModal';

  protected readonly OrderStatus = OrderStatus;

  public order = input.required<Order>();
  public close$ = input.required<Subject<void>>();

  protected loading = signal(false);
  protected showInvoiceForm = signal(false);

  constructor(
    private orderHelper: OrderHelper,
    private toastService: OnyxToastService,
    private ordersService: OrdersService,
  ) {}

  protected addDocument(): void {
    this.orderHelper.edit(this.order().uuid, {
      options: { close$: this.close$() },
    });
  }

  protected completeDocuments(): void {
    this.loading.set(true);
    this.ordersService
      .completeDocuments(this.order().uuid)
      .subscribe({
        next: () =>
          this.toastService.showSuccess(
            `${this.I18N}.toasts.documentsCompleted`,
            { keepOnNavigation: true },
          ),
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected toggleInvoiceForm(): void {
    this.showInvoiceForm.update((show) => !show);
  }
}
