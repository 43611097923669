@if (unavailability(); as unavailability) {
  <onyx-icon
    class="gray"
    [name]="icon()!"
    [size]="16"
    [onyxTooltip]="tooltipTemplate"
    [onyxTooltipTemplateContext]="{ unavailability }"
    onyxTooltipColor="white"
  ></onyx-icon>
}

<ng-template let-unavailability="unavailability" #tooltipTemplate>
  <div class="tooltip">
    <div class="heading">
      <onyx-icon class="gray" [name]="icon()!" [size]="16"></onyx-icon>

      <p class="f-medium-3">
        {{
          unavailability.reason
            | i18n: DicitionaryCode.EMPLOYEE_UNAVAILABILITY_REASON
            | translate
        }}
      </p>
    </div>

    <div class="range f-gray">
      <span>
        {{ 'labels.from' | translate }}
        {{ unavailability.dateRange.from | onyxDate: 'short-date-dot' }}
      </span>

      <span>
        {{ 'labels.to' | translate | lowercase }}
        {{ unavailability.dateRange.to | onyxDate: 'short-date-dot' }}
      </span>
    </div>
  </div>
</ng-template>
