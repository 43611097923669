@let routeI18n = [I18N, 'route'];

<onyx-tooltip [tooltip]="tooltipTemplate" color="white"></onyx-tooltip>

<onyx-checkbox
  label="labels.toBeVerified"
  [(value)]="requireVerification"
></onyx-checkbox>

<onyx-checkbox
  label="labels.block"
  [(value)]="blockRoute"
  [disabled]="requireVerification()"
></onyx-checkbox>

<onyx-button
  class="button"
  type="outlined"
  color="black"
  (click)="returnToForm()"
>
  {{ 'returnToForm' | i18n: routeI18n | translate }}
</onyx-button>

<onyx-button
  [color]="isOverCreditLimit() ? 'red' : 'blue'"
  [spinner]="loading()"
  [disabled]="activeRoute() == null"
  [onyxTooltip]="'overCreditLimitWarning' | i18n: I18N"
  [onyxTooltipEnabled]="isOverCreditLimit()"
  (click)="submit()"
>
  {{
    (mode() === OnyxFormMode.ADD ? 'addOrder' : 'saveOrder')
      | i18n: I18N
      | translate
  }}
</onyx-button>

<ng-template #tooltipTemplate>
  <div class="tooltip">
    <p
      [innerHTML]="'requireVerificationHint' | i18n: routeI18n | translate"
    ></p>

    <p [innerHTML]="'blockRouteHint' | i18n: routeI18n | translate"></p>
  </div>
</ng-template>
