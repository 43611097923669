<div class="order-heading">
  @let sender = order().basicInformation.sender;
  <span class="f-gray">{{ 'labels.client' | translate }}</span>
  <onyx-link
    color="black"
    font="f-regular-2"
    (click)="openContractorModal(sender)"
  >
    {{ sender.companyProfile.displayName }}
  </onyx-link>

  <!-- <span>·</span>

  <div class="item">
    <p class="f-gray">{{ 'labels.orders' | translate }}</p>

    <p>N/A</p>
  </div>

  <span>·</span>

  <div class="item">
    <p class="f-gray">{{ 'labels.payer' | translate }}</p>

    <p>N/A</p>
  </div> -->
</div>
