<div class="list">
  @for (document of documents(); track document) {
    <div
      class="item"
      tabindex="-1"
      (click)="form() ? editDocument($index) : showFiles(document.attachments)"
      (keydown)="(null)"
    >
      <onyx-icon
        class="icon"
        name="document"
        [size]="16"
        [frame]="24"
      ></onyx-icon>

      <p class="f-paragraph-medium-3">{{ document.name }}</p>

      <p class="note f-paragraph-medium-3">{{ document.note }}</p>

      <div class="options">
        <onyx-icon-button
          type="ghost"
          color="black"
          size="m"
          [circle]="true"
          onyxTooltip="labels.download"
          (click)="
            downloadDocument(form() ? $index : undefined, document);
            $event.stopPropagation()
          "
        >
          <onyx-icon name="download" [size]="16"></onyx-icon>
        </onyx-icon-button>

        <onyx-icon-button
          type="ghost"
          color="black"
          size="m"
          [circle]="true"
          onyxTooltip="labels.edit"
          (click)="editDocument($index); $event.stopPropagation()"
        >
          <onyx-icon name="edit" [size]="16"></onyx-icon>
        </onyx-icon-button>

        @if (form()) {
          <onyx-icon-button
            type="ghost"
            color="black"
            size="m"
            [circle]="true"
            onyxTooltip="labels.delete"
            (click)="removeDocument($index); $event.stopPropagation()"
          >
            <onyx-icon name="delete" [size]="16"></onyx-icon>
          </onyx-icon-button>
        }
      </div>
    </div>
  } @empty {
    <p class="empty f-regular-1">
      {{ placeholder() | translate }}
    </p>
  }
</div>
