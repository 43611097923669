<div
  class="container {{ type() }}"
  [ngClass]="{
    'f-label-2': type() === 'label',
    disabled: disabled(),
  }"
>
  <div class="content">
    <p
      class="label"
      [onyxTooltip]="label()"
      [onyxTooltipEnabled]="
        OverflowHelper.enableOverflowTooltip.bind(this, labelTextElement)
      "
      #labelTextElement
    >
      {{ label() | translate }}
    </p>

    @if (translatedHint(); as hint) {
      <onyx-icon
        name="tooltip"
        [size]="12"
        [onyxTooltip]="hint"
        onyxTooltipTextAlign="center"
      ></onyx-icon>
    }
  </div>

  <ng-content select="[content]"></ng-content>

  @if (!disabled() && optional()) {
    @let optionalLabel = I18N + '.optional' | translate;
    <p
      class="optional"
      [onyxTooltip]="optionalLabel"
      [onyxTooltipEnabled]="
        OverflowHelper.enableOverflowTooltip.bind(this, optionalLabelElement)
      "
      #optionalLabelElement
    >
      {{ optionalLabel }}
    </p>
  }

  @if (!optional() && link()) {
    <onyx-link
      class="link"
      [color]="link()?.color ?? 'gray'"
      [font]="link()?.font ?? 'f-label-2'"
      [routerLink]="link()?.href"
      (click)="link()?.callback?.(); $event.stopPropagation()"
    >
      {{ link()!.link | translate }}
    </onyx-link>
  }
</div>
