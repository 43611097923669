import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
} from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxDataWidgetComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxLanguagePipe,
  OnyxNumberRange,
  OnyxTemperaturePipe,
  OnyxTooltipDirective,
} from '@onyx/angular';
import {
  chain,
  intersection,
  isArray,
  isBoolean,
  isEqual,
  isNumber,
  isObject,
  isString,
} from 'lodash';
import { BASE_PARAMETERS_KEYS } from '../../../../../common/constants/common/base-parameters-keys';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { BaseParameters } from '../../../../../common/interfaces/common/base-parameters';
import { Cabotage } from '../../../../../common/interfaces/common/cabotage';
import { FleetIdentifierPipe } from '../../../../../common/pipes/fleet-identifier.pipe';
import { I18nPipe } from '../../../../../common/pipes/i18n.pipe';
import { JoinPipe } from '../../../../../common/pipes/join.pipe';
import { FleetDescriptionParametersComponent } from '../../../../fleet/common/components/fleet-description-parameters/fleet-desription-parameters.component';
import { FleetCategory } from '../../../../fleet/common/enums/fleet-category';
import { SemiTrailerSize } from '../../../../fleet/common/enums/semi-trailer-size';
import { FleetHelper } from '../../../../fleet/common/helpers/fleet.helper';
import { SimplifiedFleet } from '../../../../fleet/common/interfaces/fleet';
import { CompanyVehicleParameter } from '../../../../management-panel/dictionaries/common/interfaces/company-vehicle-parameter';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-vehicles',
  imports: [
    OnyxInformationHeadingComponent,
    TranslatePipe,
    FleetIdentifierPipe,
    OnyxIconComponent,
    OnyxDataWidgetComponent,
    OnyxTemperaturePipe,
    OnyxLanguagePipe,
    OnyxIconButtonComponent,
    FleetDescriptionParametersComponent,
    OnyxTooltipDirective,
    I18nPipe,
    NgTemplateOutlet,
  ],
  templateUrl: './order-modal-vehicles.component.html',
  styleUrl: './order-modal-vehicles.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalVehiclesComponent {
  protected readonly I18N = 'orders.orderModal';
  protected readonly TOOLTOP_DELAY = ONYX_TOOLTIP_DELAY;
  protected readonly BASE_PARAMETERS_KEYS = BASE_PARAMETERS_KEYS;

  protected readonly DictionaryCode = DictionaryCode;
  protected readonly SemiTrailerSize = SemiTrailerSize;
  protected readonly FleetCategory = FleetCategory;

  public order = input.required<Order>();
  public vehicle = input<SimplifiedFleet | null>(null);
  public trailer = input<SimplifiedFleet | null>(null);
  public cabotages = input<Cabotage[]>();
  public showHeading = input(true);

  public invalidChange = output<boolean>();

  protected searchingVehicleData = computed(() =>
    OrderHelper.getSearchingVehicleData(this.order(), this.translateService),
  );
  protected hasApprovedVehicle = computed(() =>
    OrderHelper.hasApprovedVehicle(this.order().status.value),
  );
  protected isValidAssignedTrailerType = computed(() =>
    this.searchingVehicleData().types?.includes(
      this.trailer()?.generalInformation?.type ?? '',
    ),
  );

  protected vehicleParameters = computed(() => {
    return chain(this.searchingVehicleData().parameters ?? [])
      .filter((parameter) =>
        BASE_PARAMETERS_KEYS.includes(parameter.key as keyof BaseParameters),
      )
      .map((parameter) => {
        const key = parameter.key as keyof BaseParameters;
        const value = parameter.value;
        const vehicleParameter = this.vehicle()?.additionalParameters[key];

        const ok = (): boolean => {
          if (vehicleParameter == null) return false;

          if (isString(value) || isBoolean(value)) {
            return isEqual(value, vehicleParameter);
          }

          if (isNumber(value)) return (vehicleParameter as number) >= value;

          if (isArray(value)) {
            if (
              value.every(
                (item): item is CompanyVehicleParameter =>
                  isObject(item) && 'names' in item,
              )
            ) {
              return value.every((item) =>
                (vehicleParameter as CompanyVehicleParameter[]).some(
                  (parameter) => parameter.uuid === item.uuid,
                ),
              );
            }

            return (
              intersection(vehicleParameter as any[], value).length ===
              value.length
            );
          }

          const vehicleTemperatureRange = vehicleParameter as OnyxNumberRange;
          if (
            value.from <= vehicleTemperatureRange.to &&
            vehicleTemperatureRange.from <= value.to
          ) {
            return true;
          }

          return false;
        };

        return {
          key: parameter.key,
          name: parameter.name,
          value: parameter.value,
          ok,
        };
      })
      .thru((parameters) => [
        ...parameters,
        ...(this.searchingVehicleData().isLowDeck
          ? [
              {
                key: 'lowDeck',
                name: this.translateService.instant('labels.lowDeck'),
                value: true,
                ok: () => !!this.vehicle()?.additionalParameters.isLowDeck,
              },
            ]
          : []),
        ...chain(this.searchingVehicleData()?.types)
          .filter(() => !this.trailer())
          .thru((types) => {
            if (!types.length) return [];

            return [
              {
                key: 'vehicleType',
                name: this.joinPipe.transform(
                  types,
                  ' / ',
                  2,
                  DictionaryCode.VEHICLE_TYPE,
                ),
                value: true,
                showTooltip: types.length > 2,
                ok: () =>
                  types.includes(
                    this.trailer()
                      ? (this.trailer()?.generalInformation?.type ?? '')
                      : (this.vehicle()?.generalInformation.type ?? ''),
                  ),
              },
            ];
          })
          .value(),
      ])
      .value();
  });
  protected isVehicleInvalid = computed(
    () =>
      this.vehicle() &&
      (this.vehicleParameters().some((parameter) => !parameter.ok()) ||
        this.cabotages()?.some(({ value, limit }) => value > limit)),
  );
  protected searchingTrailerTypes = computed(() =>
    OrderHelper.getSearchingTrailerTypes(this.order()),
  );
  protected trailerParameters = computed(() => {
    return chain(this.searchingTrailerTypes() ?? [])
      .thru((parameters) => [
        {
          key: 'vehicleType',
          name: this.joinPipe.transform(
            parameters,
            ' / ',
            2,
            DictionaryCode.VEHICLE_TYPE,
          ),
          showTooltip: parameters.length > 2,
          ok: parameters.includes(
            this.trailer()?.generalInformation?.type ?? '',
          ),
        },
        ...(this.searchingVehicleData().isLowDeck
          ? [
              {
                key: 'semiTrailerSize',
                name: `${DictionaryCode.SEMI_TRAILER_SIZE}.${SemiTrailerSize.MEGA}`,
                ok:
                  this.trailer()?.additionalParameters.semiTrailerSize ===
                  SemiTrailerSize.MEGA,
                showTooltip: false,
              },
            ]
          : []),
      ])
      .value();
  });
  protected isTrailerInvalid = computed(
    () =>
      this.trailer() &&
      this.trailerParameters().some((parameter) => !parameter.ok),
  );

  constructor(
    private translateService: TranslateService,
    private joinPipe: JoinPipe,
    private fleetHelper: FleetHelper,
  ) {
    effect(() => {
      if (!this.vehicle()) return;

      this.invalidChange.emit(
        !!this.isVehicleInvalid() || !!this.isTrailerInvalid(),
      );
    });
  }

  protected openFleetModal(fleet: SimplifiedFleet): void {
    this.fleetHelper.openModal(fleet);
  }
}
