@let route = order().route;
<onyx-information-heading class="heading">
  @let isVerified = route.isVerified;
  @let isBlocked = route.isBlocked;

  <onyx-icon-box
    icon
    [color]="isVerified ? 'green' : 'gray'"
    [hasColoredBackground]="true"
    [gap]="0"
    [onyxTooltip]="`${I18N}.${(isBlocked ? 'blockedRoute' : (isVerified ? 'verifiedRoute' : 'notVerifiedRoute') )}`"
  >
    <onyx-icon
      [name]="isBlocked ? 'locked' : 'verified'"
      [size]="16"
    ></onyx-icon>
  </onyx-icon-box>

  <h4>{{ 'labels.route' | translate }}</h4>

  <div action class="actions">
    <onyx-icon-button
      type="transparent"
      color="gray"
      size="m"
      [onyxTooltip]="`labels.${isExpanded() ? 'collapseAll' : 'expandAll'}`"
      (click)="toggleExpanded()"
    >
      <onyx-icon
        [name]="isExpanded() ? 'collapse-all' : 'expand-all'"
        [size]="23"
      ></onyx-icon>
    </onyx-icon-button>

    @if (hasEdit()) {
      <onyx-icon-button
        type="transparent"
        color="gray"
        size="m"
        onyxTooltip="labels.planRoute"
        (click)="planRoute()"
      >
        <onyx-icon name="route" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </div>
</onyx-information-heading>

<app-route [points]="order().points" [config]="route.config"></app-route>
