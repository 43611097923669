import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ButtonActionType } from '../../../internal/interfaces';
import { OnyxIconComponent } from '../../icons';

@Component({
  selector: 'onyx-add-button',
  imports: [OnyxIconComponent],
  templateUrl: './onyx-add-button.component.html',
  styleUrl: './onyx-add-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxAddButtonComponent {
  public textAlign = input<'left' | 'right'>('right');
  public actionType = input<ButtonActionType>('button');
}
