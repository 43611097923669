<div
  class="cell"
  [onyxTooltip]="parametersTemplate"
  [onyxTooltipTemplateContext]="{ isTooltip: true }"
  [onyxTooltipDelay]="TOOLTIP_DELAY"
  [onyxTooltipEnabled]="true"
  #valueTextElement
>
  <ng-container
    *ngTemplateOutlet="parametersTemplate; context: { isTooltip: false }"
  ></ng-container>
</div>

<ng-template let-isTooltip="isTooltip" #parametersTemplate>
  <div class="tooltip">
    @if (isTooltip) {
      <p>{{ 'labels.parameters' | translate }}:</p>
    }

    <div class="list" [class.tooltip]="isTooltip">
      @let displayedParameters = isTooltip ? parameters() : visibleParameters();
      @for (item of displayedParameters; track item.key) {
        @let value = $any(item.value);
        @if (value != null && value !== false) {
          <div class="item">
            @if (isTooltip) {
              <span>•</span>
            }

            <p>
              @let isTrue = value === true;
              <span>{{ item.name | translate }}{{ isTrue ? null : ':' }}</span>

              @let key = item.key;
              @if (!isTrue) {
                <span>
                  @switch (key) {
                    @case ('adrClasses') {
                      @for (class of value; track class) {
                        {{ DictionaryCode.ADR_CLASS + '.' + class | translate }}
                      }
                    }
                    @case ('temperatureRange') {
                      {{ value | onyxTemperature }}
                    }
                    @case ('trailerSize') {
                      {{
                        DictionaryCode.TRAILER_SIZE + '.' + value | translate
                      }}
                    }
                    @case ('semiTrailerSize') {
                      {{
                        DictionaryCode.SEMI_TRAILER_SIZE + '.' + value
                          | translate
                      }}
                    }
                    @case ('driverLanguages') {
                      {{ value | join: ', ' : 2 : DictionaryCode.LANGUAGE }}
                    }
                    @case ('other') {
                      @for (parameter of value; track parameter.uuid) {
                        {{ parameter.names | onyxLanguage }}
                      }
                    }
                    @default {
                      {{ value }}
                    }
                  }
                </span>
              }
            </p>

            @if (!isTooltip && displayedParameters.length > 1 && !$last) {
              <span>,</span>
            }
          </div>
        }
      } @empty {
        -
      }

      @if (!isTooltip && parameters().length > 2 && visibleParametersCount()) {
        <span class="counter f-semibold-3">+{{ parameters().length - 2 }}</span>
      }
    </div>
  </div>
</ng-template>
