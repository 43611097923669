import { Injectable } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { chain } from 'lodash';
import { forkJoin } from 'rxjs';
import { ICONS } from '../../components';

@Injectable({
  providedIn: 'root',
})
export class OnyxIconRegistryService {
  constructor(private iconRegistry: SvgIconRegistryService) {
    chain(ICONS)
      .flatMap((group) => group)
      .map((icon) => this.iconRegistry.loadSvg(`icons/${icon}.svg`, icon)!)
      .thru((promises) => forkJoin(promises).subscribe())
      .value();
  }
}
