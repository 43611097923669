import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxComponentInputs,
  OnyxFlagComponent,
  OnyxRouteToll,
  OnyxTableColor,
  OnyxTableColumn,
  OnyxTableComponent,
  OnyxTableData,
} from '@onyx/angular';
import { chain } from 'lodash';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { AmountHelper } from '../../../helpers/amount.helper';
import { I18nPipe } from '../../../pipes/i18n.pipe';
import { AmountCellComponent } from '../../cells/amount-cell/amount-cell.component';
import { StringCellComponent } from '../../cells/string-cell/string-cell.component';

@Component({
  selector: 'app-tolls-tooltip',
  standalone: true,
  imports: [OnyxFlagComponent, I18nPipe, TranslatePipe, OnyxTableComponent],
  templateUrl: './tolls-tooltip.component.html',
  styleUrl: './tolls-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TollsTooltipComponent {
  protected readonly COLUMNS: OnyxTableColumn<
    Pick<OnyxRouteToll, 'systemName' | 'cost'>
  >[] = [
    {
      id: 'systemName',
      width: 'fill',
      component: {
        ref: StringCellComponent,
        inputs: ({ item }) =>
          ({
            value: item.systemName,
          }) satisfies OnyxComponentInputs<StringCellComponent>,
      },
    },
    {
      id: 'cost',
      width: '120px',
      component: {
        ref: AmountCellComponent,
        inputs: ({ item }) =>
          ({
            amount: item.cost,
            show: 'resultCurrency',
          }) satisfies OnyxComponentInputs<AmountCellComponent>,
      },
    },
  ];

  protected readonly DictionaryCode = DictionaryCode;

  public tolls = input.required<OnyxRouteToll[]>();

  public groups = computed(() =>
    chain(this.tolls())
      .groupBy((toll) => toll.countryCode)
      .mapValues((tolls) => [
        ...tolls,
        ...(tolls.length > 1
          ? [
              {
                systemName: 'labels.sum',
                cost: tolls
                  .slice(1)
                  .reduce(
                    (totalCost, toll) => AmountHelper.add(totalCost, toll.cost),
                    tolls[0].cost,
                  ),
              },
            ]
          : []),
      ])
      .toPairs()
      .value(),
  );

  public getRowColor(
    length: number,
    data: OnyxTableData<unknown>,
  ): OnyxTableColor {
    return data.index === length - 1 && length > 1
      ? OnyxTableColor.BLUE
      : OnyxTableColor.WHITE;
  }
}
