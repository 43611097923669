<onyx-modal
  type="right"
  [heading]="
    employee() ? employee()!.firstName + ' ' + employee()!.lastName : ''
  "
  [loading]="loading()"
  [(error)]="error"
  [avatarUser]="employee() ?? undefined"
  [dictionaryCode]="DictionaryCode.EMPLOYEE_ROLE"
  [roles]="employee()?.roles ?? []"
  [close$]="close$"
  (errorChange)="employee$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container rightOptions>
    @if (employee(); as employee) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [disabled]="!options()?.length"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        onyxTooltip="labels.options"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
      </onyx-icon-button>

      @if (hasEdit()) {
        <onyx-icon-button
          color="black"
          type="outlined"
          size="m"
          [circle]="true"
          onyxTooltip="labels.edit"
          (click)="edit()"
        >
          <onyx-icon name="edit" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }

      <onyx-icon-button
        color="blue"
        type="primary"
        size="m"
        [circle]="true"
        onyxTooltip="labels.call"
        (click)="ActionHelper.makeCall(employee.phone)"
      >
        <onyx-icon name="telephone" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </ng-container>

  @if (employee(); as employee) {
    <div class="container">
      <div>
        <onyx-information-row label="labels.status">
          <app-employee-status
            [employee]="employee"
            [displayStatus]="true"
          ></app-employee-status>
        </onyx-information-row>

        <onyx-information-heading>
          {{ 'labels.contactData' | translate }}
        </onyx-information-heading>

        <div class="group">
          <onyx-information-row label="labels.phone">
            {{ employee.phone | onyxPhone }}
          </onyx-information-row>

          <onyx-information-row label="labels.email">
            {{ employee.email }}
          </onyx-information-row>
        </div>

        <onyx-information-heading>
          {{ 'labels.activity' | translate }}
        </onyx-information-heading>

        <div class="group">
          <onyx-information-row label="labels.joinDate">
            @if (employee.joinedAt) {
              {{ employee.joinedAt | onyxDate: 'time-date' }}
            } @else {
              -
            }
          </onyx-information-row>

          <onyx-information-row label="labels.lastActivity">
            @if (employee.lastActivityAt) {
              {{ employee.lastActivityAt | onyxDate: 'time-date' }}
            } @else {
              -
            }
          </onyx-information-row>
        </div>
      </div>

      <div class="wrapper">
        <onyx-chips
          left
          [chips]="chips()"
          type="secondary"
          [single]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>

        @switch (group()[0]) {
          @case (EmployeeModalSection.ASSIGNED_VEHICLES) {
            <div class="vehicles">
              @if (employee.status.value !== EmployeeStatus.ARCHIVED) {
                <onyx-button
                  class="edit-button"
                  color="black"
                  type="outlined"
                  size="s"
                  [disabled]="!hasEdit()"
                  (click)="hasEdit() ? assignVehicles() : null"
                >
                  <onyx-icon
                    left
                    class="edit-icon"
                    name="edit"
                    [size]="16"
                  ></onyx-icon>

                  {{ 'labels.edit' | translate }}
                </onyx-button>

                <!-- N/A: Add search -->
              }

              <onyx-table
                [data]="assignedVehicles()"
                [columns]="EMPLOYEES_ASSIGNED_VEHICLES_LIST_COLUMNS"
                [loading]="loading()"
                tableSize="s"
                [rowSize]="48"
                [notFound]="ASSIGNED_VEHICLES_NOT_FOUND"
                [(pagination)]="pagination"
                (rowClick)="openFleetModal($event.item)"
              ></onyx-table>
            </div>
          }
          @case (EmployeeModalSection.PLANNED_UNAVAILABILITIES) {
            <app-unavailabilities
              [employee]="employee"
              [enabled]="hasEdit()"
            ></app-unavailabilities>
          }
        }
      </div>
    </div>
  }
</onyx-modal>
