<section>
  <onyx-information-heading>
    {{ heading() | translate }}
  </onyx-information-heading>

  <div class="container">
    <app-order-modal-plan-kilometers
      [order]="order()"
    ></app-order-modal-plan-kilometers>

    <app-order-modal-plan-duration
      [order]="order()"
    ></app-order-modal-plan-duration>

    <app-order-modal-plan-tolls [order]="order()"></app-order-modal-plan-tolls>

    <app-order-modal-plan-finances
      [order]="order()"
    ></app-order-modal-plan-finances>
  </div>
</section>
