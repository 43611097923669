@switch (category()) {
  @case (OrderPointCategory.CHECKPOINT) {
    <app-order-checkpoint-form
      [form]="$any(form())"
      [compact]="true"
    ></app-order-checkpoint-form>
  }
  @case (OrderPointCategory.TRANSIT) {
    <app-order-transit-form
      [form]="$any(form())"
      [compact]="true"
    ></app-order-transit-form>
  }
}
