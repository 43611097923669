import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OnyxDropdownOptionsSourceResult,
  OnyxFilterPipe,
  OnyxPaginated,
  OnyxPagination,
} from '@onyx/angular';
import { chain, omit } from 'lodash';
import { Observable, Subject, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../../../../../auth/common/services/auth.service';
import { UnavailabilityForm } from '../../../../../common/components/unavailabilities/unavailability-modal/unavailability-modal.component';
import { Unavailability } from '../../../../../common/interfaces/common/unavailability';
import { BatchFileUpload } from '../../../../../common/interfaces/utilities/batch-file-upload';
import { ApiService } from '../../../../../common/services/api.service';
import { StorageService } from '../../../../../common/services/storage.service';
import { SimplifiedFleet } from '../../../../fleet/common/interfaces/fleet';
import { AddEmployeesModalForm } from '../../add-employees-modal/add-employees-modal.component';
import { EditEmployeeModalForm } from '../components/edit-employee-modal/edit-employee-modal.component';
import { EmployeeCategory } from '../enums/employee-category';
import { EmployeeRole } from '../enums/employee-role';
import { EmployeeStatus } from '../enums/employee-status';
import { Employee } from '../interfaces/employee';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
    private filterPipe: OnyxFilterPipe,
    private authService: AuthService,
  ) {
    super(http);
  }

  public listEmployees(
    params: {
      category: EmployeeCategory;
      roles: EmployeeRole[];
      statuses: EmployeeStatus[];
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Employee>> {
    return this.get('/employees', {
      params: {
        category: params.category,
        'roles[]': params.roles,
        'status[]': params.statuses,
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public searchEmployees(
    query: string,
    limit: number,
    params: Omit<
      Parameters<EmployeesService['listEmployees']>[0],
      keyof OnyxPagination
    >,
  ): Observable<OnyxDropdownOptionsSourceResult<Employee>> {
    return this.listEmployees({
      ...params,
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
    }).pipe(
      map((response) => ({
        options: chain(response.items)
          .map((employee) => ({
            name: `${employee.firstName} ${employee.lastName}`,
            value: employee,
            avatar: employee,
          }))
          .orderBy(({ value }) => value.lastName)
          .thru((options) =>
            this.filterPipe.transform(options, query, ['name']).slice(0, limit),
          )
          .value(),
        totalItems: response.totalItems,
      })),
    );
  }

  public getEmployee(uuid: string): Observable<Employee> {
    return this.get<Employee>(`/employees/${uuid}`);
  }

  public addEmployees(form: AddEmployeesModalForm): Observable<void> {
    return this.post<void>('/employees', form).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public editEmployee(
    uuid: string,
    form: EditEmployeeModalForm,
  ): Observable<Employee> {
    const body = omit(form, 'email');
    return this.put<Employee>(`/employees/${uuid}`, body).pipe(
      tap(() => {
        const user = this.authService.user();
        if (user?.employeeUuid !== uuid) return;

        this.authService.updateUser({
          firstName: form.firstName,
          lastName: form.lastName,
          phone: form.phone!,
          roles: form.roles,
        });
      }),
      tap(() => this._reload$.next()),
    );
  }

  public getEmployeeUnavailabilities(
    employeeUuid: string,
    params: {
      past: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Unavailability>> {
    return this.get(`/employees/${employeeUuid}/unavailabilities`, {
      params: {
        past: params.past,
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public addEmployeeUnavailability(
    employeeUuid: string,
    form: UnavailabilityForm,
  ): Observable<void> {
    return this.uploadUnavailabilityFile(form).pipe(
      switchMap((form) =>
        this.post<void>(`/employees/${employeeUuid}/unavailabilities`, form),
      ),
      tap(() => this._reload$.next()),
    );
  }

  public editEmployeeUnavailability(
    employeeUuid: string,
    uuid: string,
    form: UnavailabilityForm,
  ): Observable<void> {
    return this.uploadUnavailabilityFile(form).pipe(
      switchMap((form) =>
        this.put<void>(
          `/employees/${employeeUuid}/unavailabilities/${uuid}`,
          form,
        ),
      ),
      tap(() => this._reload$.next()),
    );
  }

  public deleteEmployeeUnavailability(
    employeeUuid: string,
    uuid: string,
  ): Observable<void> {
    return this.delete<void>(
      `/employees/${employeeUuid}/unavailabilities/${uuid}`,
    ).pipe(tap(() => this._reload$.next()));
  }

  public reinviteEmployee(uuid: string): Observable<void> {
    return this.post<void>(`/employees/${uuid}/resend-invite`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public unarchiveEmployee(uuid: string): Observable<void> {
    return this.post<void>(`/employees/${uuid}/unarchive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public archiveEmployee(uuid: string): Observable<void> {
    return this.post<void>(`/employees/${uuid}/archive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public deleteEmployee(uuid: string): Observable<void> {
    return this.delete<void>(`/employees/${uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public getAssignedVehicles(
    uuid: string,
    pagination: OnyxPagination,
  ): Observable<OnyxPaginated<SimplifiedFleet>> {
    return this.get(`/employees/${uuid}/assigned-vehicles`, {
      params: {
        page: pagination.page,
        limit: pagination.limit,
      },
    });
  }

  public assignVehicles(uuid: string, vehicles: string[]): Observable<void> {
    return this.put<void>(`/employees/${uuid}/assigned-vehicles`, {
      vehicles,
    }).pipe(tap(() => this._reload$.next()));
  }

  private uploadUnavailabilityFile(
    form: UnavailabilityForm,
  ): Observable<UnavailabilityForm> {
    return of(form).pipe(
      map((form): BatchFileUpload[] => [
        {
          path: 'file',
          files: form.file,
        },
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(form, data)),
    );
  }
}
