import { AsyncPipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  viewChildren,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxLanguagePipe, OnyxPhonePipe } from '@onyx/angular';
import { chain, isEqual, uniqBy } from 'lodash';
import { first, forkJoin, map, Observable, of } from 'rxjs';
import { validate as validateUuid } from 'uuid';
import { AddressComponent } from '../../../../../../../common/components/address/address.component';
import { BUSINESS_HOURS_KEYS } from '../../../../../../../common/constants/common/business-hours-keys';
import { TIME_RANGE_ALL_DAY } from '../../../../../../../common/constants/common/time-range-all-day';
import { DictionaryCode } from '../../../../../../../common/enums/dictionary-code';
import { BusinessHours } from '../../../../../../../common/interfaces/common/business-hours';
import { OrderPointCategory } from '../../../../../common/enums/order-point-category';
import { OrderHelper } from '../../../../../common/helpers/order.helper';
import { Order } from '../../../../../common/interfaces/order';
import {
  OrderLoadingGood,
  OrderLoadingPoint,
  OrderPoint,
  OrderUnloadingPoint,
} from '../../../../../common/interfaces/order-point';
import { OrderFormService } from '../../../../../common/services/order-form.service';

@Component({
  selector: 'app-order-pdf-template-route',
  imports: [
    TranslatePipe,
    UpperCasePipe,
    AddressComponent,
    OnyxLanguagePipe,
    OnyxPhonePipe,
    AsyncPipe,
  ],
  providers: [OrderFormService],
  templateUrl: './order-pdf-template-route.component.html',
  styleUrl: './order-pdf-template-route.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPdfTemplateRouteComponent {
  protected readonly I18N = 'orders.pdfTemplate';
  protected readonly BUSINESS_HOURS_KEYS = BUSINESS_HOURS_KEYS;

  protected readonly DictionaryCode = DictionaryCode;
  protected readonly OrderPointCategory = OrderPointCategory;

  public order = input.required<Order>();

  protected orderPoints = computed(() => {
    return chain(this.order().points)
      .filter((point) =>
        'includeInOrder' in point ? point.includeInOrder !== false : true,
      )
      .value();
  });

  protected loadingPoints = computed<OrderLoadingPoint[]>(() =>
    this.order().points.filter(
      (point) => point.category === OrderPointCategory.LOADING,
    ),
  );
  protected unloadingPoints = computed<OrderUnloadingPoint[]>(() =>
    this.order().points.filter(
      (point) => point.category === OrderPointCategory.UNLOADING,
    ),
  );

  protected lastPointItemHeight = computed(() => {
    const items = this.routeItems();
    if (!items.length) return 0;

    const lastItem = items.at(-1);
    return lastItem?.nativeElement?.clientHeight || 0;
  });

  private routeItems = viewChildren<ElementRef<HTMLElement>>('routeItem');

  constructor(
    private orderHelper: OrderHelper,
    private orderFormService: OrderFormService,
  ) {}

  protected businessHoursAlwaysOpen(businessHours: BusinessHours): boolean {
    return Object.values(businessHours).every((openingHours) =>
      isEqual(openingHours, TIME_RANGE_ALL_DAY),
    );
  }

  protected goods$(point: OrderPoint): Observable<OrderLoadingGood[] | null> {
    if (!('goods' in point)) return of(null);

    let formGoods: OrderLoadingGood[];
    if (point.category === OrderPointCategory.LOADING) {
      formGoods = point.goods;
    } else if (point.category === OrderPointCategory.UNLOADING) {
      formGoods = chain(point.goods)
        .map(({ uuid, quantity }) => {
          const good = this.loadingPointGoods().find(
            (good) => good.uuid === uuid,
          );
          if (!good) return null;

          return { ...good, quantity };
        })
        .compact()
        .value();
    }

    return forkJoin([
      this.orderFormService.goodTypes$.pipe(first()),
      this.orderFormService.units$.pipe(first()),
    ]).pipe(
      map(([goodTypes, units]) =>
        formGoods.map(
          (good) =>
            ({
              ...good,
              type: validateUuid(good.type?.uuid ?? '')
                ? (goodTypes.find(({ uuid }) => uuid === good.type?.uuid) ??
                  null)
                : null,
              typeName: !validateUuid(good.type?.uuid ?? '') ? good.type : null,
              unit: units.find(({ uuid }) => uuid === good.unit.uuid),
            }) as OrderLoadingGood,
        ),
      ),
    );
  }

  protected loadingPointGoods(): OrderLoadingGood[] {
    return this.order()
      .points.filter((point) => point.category === OrderPointCategory.LOADING)
      .map((point) => point.goods)
      .flatMap((goods) => uniqBy(goods, 'uuid'));
  }

  protected unloadingPointGoods(point: OrderPoint): OrderLoadingGood[] {
    if (point.category !== OrderPointCategory.UNLOADING) return [];

    const unloadingGoods = point.goods;
    const loadingsGoods = this.loadingPoints()?.flatMap((point) => point.goods);
    if (!unloadingGoods) return [];

    return loadingsGoods?.map((loadingGood) => {
      const unloadingGood = unloadingGoods.find(
        (good) => good.uuid === loadingGood.uuid,
      );

      return {
        ...loadingGood,
        quantity: unloadingGood ? unloadingGood.quantity : loadingGood.quantity,
      };
    });
  }

  protected getTimeWindows(point: OrderPoint): {
    label: string;
    value: string[];
  } {
    return this.orderHelper.getTimeWindows(point, 'long');
  }
}
