import { AsyncPipe, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Injector,
  input,
  OnInit,
  Signal,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxFormGroupComponent,
  OnyxLanguagePipe,
  OnyxTextFieldComponent,
} from '@onyx/angular';
import { combineLatestWith, distinctUntilChanged, map } from 'rxjs';
import { OrderFormService } from '../../../../../common/services/order-form.service';
import { availableGoodQuantityValidator } from '../../../../../common/validators/unloading-good-quantity.validator';
import { OrderLoadingPointGoodForm } from '../../../order-loading-point-form/order-loading-point-good-form/order-loading-point-good-form.component';

export type OrderUnloadingPointGoodFormGroup = ReturnType<
  typeof OrderUnloadingPointGoodFormComponent.buildForm
>;

@Component({
  selector: 'app-order-unloading-point-good-form',
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    OnyxFormGroupComponent,
    OnyxTextFieldComponent,
    DecimalPipe,
    AsyncPipe,
  ],
  templateUrl: './order-unloading-point-good-form.component.html',
  styleUrl: './order-unloading-point-good-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderUnloadingPointGoodFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.points.unloadingPoint.good';

  protected readonly Math = Math;

  private readonly units$ = this.orderFormService.units$;

  public form = input.required<OrderUnloadingPointGoodFormGroup>();
  public good = input.required<OrderLoadingPointGoodForm>();
  public availableQuantity = input.required<number>();

  protected unitName$ = toObservable(this.good).pipe(
    map((good) => good.unit),
    distinctUntilChanged(),
    combineLatestWith(this.units$),
    map(([goodUnit, units]) => {
      if (!goodUnit) return null;

      const unit = units.find((unit) => unit.uuid === goodUnit);
      return unit ? this.languagePipe.transform(unit.names) : null;
    }),
    takeUntilDestroyed(this.destroyRef),
  );

  protected totalWeight = computed(() => {
    const [quantity, good] = [this.quantity(), this.good()];
    if (!quantity || !good.quantity || !good.totalWeight) return null;
    return (quantity / good.quantity) * good.totalWeight;
  });

  private quantity!: Signal<number | null>;

  constructor(
    private injector: Injector,
    private languagePipe: OnyxLanguagePipe,
    private destroyRef: DestroyRef,
    private orderFormService: OrderFormService,
  ) {}

  public ngOnInit() {
    const controls = this.form().controls;
    effect(
      () => {
        controls.quantity.setValidators([
          Validators.required,
          Validators.min(1),
          availableGoodQuantityValidator(this.availableQuantity()),
        ]);
        if (controls.quantity.value) FormHelper.submit(controls.quantity);
      },
      { injector: this.injector },
    );

    this.quantity = toSignal(controls.quantity.valueChanges, {
      initialValue: controls.quantity.value,
      injector: this.injector,
    });
  }

  public static buildForm(
    fb: NonNullableFormBuilder,
    uuid: string,
    quantity: number,
  ) {
    return fb.group({
      uuid: fb.control(uuid),
      quantity: fb.control<number | null>(quantity, [
        Validators.required,
        Validators.min(1),
      ]),
    });
  }
}
