import { AsyncPipe, DecimalPipe, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Injector,
  input,
  OnInit,
  output,
  Signal,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDropdownComponent,
  OnyxDropdownOptionsSource,
  OnyxFormGroupComponent,
  OnyxFormMode,
  OnyxIconBoxComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxLanguagePipe,
  onyxLengthValidator,
  OnyxModalService,
  OnyxOption,
  OnyxOptionsGroup,
  OnyxSuggestion,
  OnyxSuggestionsComponent,
  OnyxTabsComponent,
  OnyxTextFieldComponent,
} from '@onyx/angular';
import { chain, difference, isArray, isEqual, isObject, orderBy } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  shareReplay,
  skip,
  startWith,
  switchMap,
} from 'rxjs';
import { v4 as uuidv4, validate as validateUuid } from 'uuid';
import { DictionaryCode } from '../../../../../../common/enums/dictionary-code';
import { GoodsSizeType } from '../../../../../../common/enums/goods-size-type';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { DictionariesService } from '../../../../../../common/services/dictionaries.service';
import { CompanyDictionaryCode } from '../../../../../management-panel/dictionaries/common/enums/company-dictionary-code';
import { CompanyGoodType } from '../../../../../management-panel/dictionaries/common/interfaces/company-good-type';
import { CompanyUnit } from '../../../../../management-panel/dictionaries/common/interfaces/company-unit';
import { CompanyDictionariesService } from '../../../../../management-panel/dictionaries/common/services/company-dictionaries.service';
import {
  DictionariesUnitModalComponent,
  DictionariesUnitModalData,
} from '../../../../../management-panel/dictionaries/dictionaries/dictionaries-units/dictionaries-unit-modal/dictionaries-unit-modal.component';
import { OrderFormGood } from '../../../../common/interfaces/order-form-good';
import { OrderFormService } from '../../../../common/services/order-form.service';

export type OrderLoadingPointGoodFormGroup = ReturnType<
  typeof OrderLoadingPointGoodFormComponent.buildForm
>;
export type OrderLoadingPointGoodForm = ReturnType<
  OrderLoadingPointGoodFormGroup['getRawValue']
>;

@Component({
  selector: 'app-order-loading-point-good-form',
  imports: [
    ReactiveFormsModule,
    OnyxIconBoxComponent,
    OnyxIconComponent,
    OnyxDropdownComponent,
    AsyncPipe,
    OnyxTextFieldComponent,
    OnyxSuggestionsComponent,
    OnyxTabsComponent,
    OnyxIconButtonComponent,
    NgStyle,
    TranslatePipe,
    OnyxFormGroupComponent,
  ],
  templateUrl: './order-loading-point-good-form.component.html',
  styleUrl: './order-loading-point-good-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderLoadingPointGoodFormComponent implements OnInit {
  protected readonly I18N = 'orders.orderForm.points.loadingPoint';

  protected readonly GOOD_TYPE_SOURCE: OnyxDropdownOptionsSource<
    CompanyGoodType | string
  > = {
    list: (query, limit) =>
      this.companyDictionariesService.searchDictionary(
        CompanyDictionaryCode.GOOD_TYPE,
        query,
        limit,
      ),
    get: (uuid) => {
      if (!validateUuid(uuid)) return { name: uuid, value: uuid };

      return this.companyDictionariesService
        .getDictionary<CompanyDictionaryCode.GOOD_TYPE>(uuid)
        .pipe(
          map((item) => ({
            name: this.languagePipe.transform(item.names),
            value: item,
          })),
        );
    },
    idKey: 'uuid',
  };

  protected readonly QUANTITY_SUGGESTIONS: OnyxSuggestion<number>[] = [33].map(
    (value) => this.buildDecimalSuggestion(value, 0),
  );
  protected readonly DEDICATED_WEIGHT_SUGGESTIONS: OnyxSuggestion<number>[] = [
    24_000,
  ].map((value) => this.buildDecimalSuggestion(value, 0));

  protected readonly GoodsSizeType = GoodsSizeType;

  protected readonly sizeTypes$ = this.dictionariesService.getDictionary(
    DictionaryCode.GOODS_SIZE_TYPE,
  );

  private readonly DEDICATED_LOADING_METERS_SUGGESTIONS: OnyxSuggestion<number>[] =
    [13.6 * 100].map((value) => this.buildDecimalSuggestion(value, 2));

  private readonly goodTypes$ = this.orderFormService.goodTypes$;
  private readonly units$ = this.orderFormService.units$;

  public form = input.required<OrderLoadingPointGoodFormGroup>();
  public first = input.required<boolean>();
  public goods = input.required<OrderFormGood[]>();

  public remove = output<void>();

  protected adrClasses = this.orderFormService.adrClasses;
  protected isDedicated = this.orderFormService.isDedicated;
  protected hasEcmr = this.orderFormService.hasEcmr;

  protected type$ = new BehaviorSubject<CompanyGoodType | string | null>(null);
  protected unit$ = new BehaviorSubject<CompanyUnit | null>(null);

  protected typeSuggestions$ = this.goodTypes$.pipe(
    map((items): OnyxSuggestion<string>[] =>
      chain(items)
        .filter((item) => ['Neutral'].includes(item.names.en))
        .map((item) => ({
          name: this.languagePipe.transform(item.names),
          value: item.uuid,
        }))
        .value(),
    ),
  );
  protected unitOptions$ = this.units$.pipe(
    combineLatestWith(this.type$),
    map(([units, type]): OnyxOptionsGroup<string>[] => {
      const relatedUnits = isObject(type) ? type.relatedUnits : [];
      return [
        {
          subheading: `${this.I18N}.relatedUnits`,
          options: relatedUnits.map((unit) => this.buildUnitOption(unit)),
        },
        {
          subheading: relatedUnits.length
            ? `${this.I18N}.otherUnits`
            : undefined,
          options: chain(units)
            .differenceBy(relatedUnits, 'uuid')
            .map((unit) => this.buildUnitOption(unit))
            .value(),
        },
      ].filter((group) => group.options.length);
    }),
    shareReplay(1),
  );
  protected unitSuggestions$ = this.type$.pipe(
    map((type) => (isObject(type) ? type.relatedUnits : [])),
    map((units) => units.map((unit) => this.buildUnitOption(unit)).slice(0, 2)),
  );

  protected loadingMetersSuggestions$!: Observable<OnyxSuggestion<number>[]>;
  protected dimensionsSuggestions$ = this.unit$.pipe(
    map((unit) => {
      const KEYS = ['length', 'width', 'height'] as const;
      return chain(KEYS)
        .map((key) => [
          key,
          unit?.size.type === GoodsSizeType.DIMENSIONS
            ? [this.buildDecimalSuggestion(unit.size[key], 2)]
            : [],
        ])
        .fromPairs()
        .value() as Record<(typeof KEYS)[number], OnyxSuggestion<number>[]>;
    }),
    shareReplay(1),
  );
  protected volumeSuggestions$!: Observable<OnyxSuggestion<number>[]>;

  protected color = computed(
    () =>
      this.goods().find(({ good }) => good.uuid === this.form().value.uuid)
        ?.goodColor,
  );
  protected sizeType!: Signal<GoodsSizeType>;
  protected hasUnitedNationsNumber = computed(() => {
    const adrClasses = this.adrClasses();
    return isArray(adrClasses) && adrClasses.length > 0;
  });
  protected hasStatisticalNumber = computed(() => this.hasEcmr());

  constructor(
    private dictionariesService: DictionariesService,
    private injector: Injector,
    private decimalPipe: DecimalPipe,
    private destroyRef: DestroyRef,
    private companyDictionariesService: CompanyDictionariesService,
    private languagePipe: OnyxLanguagePipe,
    private modalService: OnyxModalService,
    private orderFormService: OrderFormService,
  ) {}

  public ngOnInit(): void {
    const controls = this.form().controls;

    this.type$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((type) => isObject(type)),
        filter((type) => type.relatedUnits.length === 1),
        filter(() => controls.unit.value == null),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((type) => controls.unit.setValue(type.relatedUnits[0].uuid));

    this.unitOptions$
      .pipe(
        first(),
        delay(0),
        switchMap(() => this.unit$),
        skip(1),
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        filter((unit) => !!unit),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((unit) => this.form().patchValue({ size: unit.size }));

    const quantity$ = controls.quantity.valueChanges.pipe(
      startWith(controls.quantity.value),
    );
    this.loadingMetersSuggestions$ = combineLatest([
      toObservable(this.isDedicated, { injector: this.injector }),
      this.unit$,
      quantity$,
    ]).pipe(
      map(([isDedicated, unit, quantity]) => [
        ...(isDedicated ? this.DEDICATED_LOADING_METERS_SUGGESTIONS : []),
        ...(unit?.size.type === GoodsSizeType.LOADING_METERS
          ? [
              this.buildDecimalSuggestion(unit.size.loadingMeters, 2),
              ...(quantity != null && quantity > 1
                ? [
                    this.buildDecimalSuggestion(
                      unit.size.loadingMeters * quantity,
                      2,
                    ),
                  ]
                : []),
            ]
          : []),
      ]),
      map((suggestions) =>
        orderBy(suggestions, (suggestion) => suggestion.value),
      ),
      shareReplay(1),
      takeUntilDestroyed(this.destroyRef),
    );
    this.volumeSuggestions$ = combineLatest([this.unit$, quantity$]).pipe(
      map(([unit, quantity]) =>
        unit?.size.type === GoodsSizeType.VOLUME
          ? [
              this.buildDecimalSuggestion(unit.size.volume, 2),
              ...(quantity != null && quantity > 1
                ? [this.buildDecimalSuggestion(unit.size.volume * quantity, 2)]
                : []),
            ]
          : [],
      ),
      shareReplay(1),
      takeUntilDestroyed(this.destroyRef),
    );

    const sizeControls = controls.size.controls;
    this.sizeType = toSignal(sizeControls.type.valueChanges, {
      initialValue: sizeControls.type.value,
      injector: this.injector,
    });

    const SIZE_TYPE_CONTROLS = {
      [GoodsSizeType.DIMENSIONS]: [
        sizeControls.length,
        sizeControls.width,
        sizeControls.height,
      ],
      [GoodsSizeType.LOADING_METERS]: [sizeControls.loadingMeters],
      [GoodsSizeType.VOLUME]: [sizeControls.volume],
    };
    const sizeChanges = toSignal(
      controls.size.valueChanges.pipe(distinctUntilChanged(isEqual)),
      { injector: this.injector },
    );

    effect(
      () => {
        sizeChanges();

        const type = this.sizeType();
        const enabledSizeTypeControls = SIZE_TYPE_CONTROLS[type];
        const enabledControls = [sizeControls.type, ...enabledSizeTypeControls];
        const disabledControls = difference(
          Object.values(sizeControls),
          enabledControls,
        );

        ValidationHelper.toggleControls(enabledControls, true);
        ValidationHelper.toggleControls(disabledControls, false);

        // TEMP: Disable optional size, until BE will support it
        // const isDedicated = this.isDedicated();
        // const hasValue = enabledSizeTypeControls.some(
        //   (control) => control.value,
        // );

        // ValidationHelper.toggleRequiredValidator(
        //   sizeControls.type,
        //   !isDedicated,
        // );
        // ValidationHelper.toggleRequiredValidator(
        //   enabledSizeTypeControls,
        //   !isDedicated || hasValue,
        // );
      },
      { injector: this.injector },
    );

    controls.unitedNationsNumber.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((value) =>
        controls.unitedNationsNumber.setValue(
          value?.replace(/\D/g, '') ?? null,
          { emitEvent: false },
        ),
      );

    effect(
      () => {
        ValidationHelper.toggleControls(
          this.form().controls.unitedNationsNumber,
          this.hasUnitedNationsNumber(),
        );
        ValidationHelper.toggleControls(
          this.form().controls.statisticalNumber,
          this.hasStatisticalNumber(),
        );
      },
      { injector: this.injector },
    );
  }

  protected setCustomType(type: string): void {
    this.form().controls.type.setValue(type);
  }

  protected addUnit(name?: string): void {
    this.modalService.open<DictionariesUnitModalData>(
      DictionariesUnitModalComponent,
      { mode: OnyxFormMode.ADD, name },
    );
  }

  private buildUnitOption(
    unit: CompanyUnit,
  ): OnyxOption<string> & { unit: CompanyUnit } {
    return {
      name: this.languagePipe.transform(unit.names),
      value: unit.uuid,
      unit,
    };
  }

  private buildDecimalSuggestion(
    value: number,
    decimalPlaces: number,
  ): OnyxSuggestion<number> {
    return {
      name: this.decimalPipe.transform(value / Math.pow(10, decimalPlaces))!,
      value: value,
    };
  }

  public static buildForm(fb: NonNullableFormBuilder) {
    const uuid = uuidv4();
    return fb.group({
      uuid: fb.control(uuid),
      type: fb.control<string | null>(null, [Validators.required]),
      referenceNumber: fb.control<string | null>(null),
      unit: fb.control<string | null>(null, [Validators.required]),
      quantity: fb.control<number | null>(null, [
        Validators.required,
        Validators.min(1),
      ]),
      totalWeight: fb.control<number | null>(null, [
        Validators.required,
        Validators.min(Number.EPSILON),
      ]),
      size: fb.group({
        type: fb.control(GoodsSizeType.LOADING_METERS, [Validators.required]),
        loadingMeters: fb.control<number | null>(null, [
          Validators.required,
          Validators.min(Number.EPSILON),
        ]),
        length: fb.control<number | null>(null, [
          Validators.required,
          Validators.min(Number.EPSILON),
        ]),
        width: fb.control<number | null>(null, [
          Validators.required,
          Validators.min(Number.EPSILON),
        ]),
        height: fb.control<number | null>(null, [
          Validators.required,
          Validators.min(Number.EPSILON),
        ]),
        volume: fb.control<number | null>(null, [
          Validators.required,
          Validators.min(Number.EPSILON),
        ]),
      }),
      unitedNationsNumber: fb.control<string | null>(null, [
        onyxLengthValidator(4),
      ]),
      statisticalNumber: fb.control<string | null>(null),
    });
  }
}
