export const ICONS = {
  Menu: [
    'menu-messages',
    'menu-notifications',
    'menu-dashboard',
    'menu-orders',
    'menu-fleet',
    'menu-drivers',
    'menu-optimization',
    'menu-analytics',
    'menu-more',
    'menu-repairs',
    'menu-tasks',
    'menu-errands',
    'menu-delegations',
    'menu-opinion',
    'menu-control-panel',
    'menu-settings',
    'menu-distribution',
    'menu-settlements',
    'menu-map',
    'menu-business',
    'menu-documents',
    'menu-integrations',
    'menu-poi',
    'menu-contractors',
    'menu-calculator',
    'menu-dictionaries',
    'menu-password',
    'menu-engine',
    'menu-user-data',
    'menu-bases',
    'menu-employees',
    'menu-gantt',
  ],

  'View / Mode': [
    'list-view',
    'column-view',
    'calendar-view',
    'map-view',
    'dark-mode',
    'light-mode',
    'plus',
    'minus',
  ],

  System: [
    'options-horizontal',
    'options-vertical',
    'settings',
    'search',
    'views',
    'drag-horizontal',
    'drag-vertical',
    'verified',
    'check-fill',
    'check',
    'side-modal',
    'home',
    'contractor',
    'visibility-shown',
    'visibility-hidden',
    'coil-well',
    'mark-as-read',
    'tooltip',
    'tooltip-outlined',
    'shield',
    'scale',
    'scale-alt',
    'no-entry',
    'print',
    'hashtag',
    'exchange',
    'no-results',
    'ai',
    'placeholder',
    'bookmark-solid',
    'bookmark-outlined',
    'help',
    'finance',
    'offer',
    'more',
    'more-alt',
    'locked',
    'unlocked',
    'integrations',
    'indeterminate',
    'translations',
    'manual',
    'cursor',
    'summary',
    'archives',
    'new-order',
    'new-order-outlined',
    'delegation',
    'delegation-outlined',
    'decision',
    'compass-right',
    'integration',
    'rating',
  ],

  'Quick actions': [
    'refresh',
    'delete',
    'delete-fill',
    'close',
    'edit',
    'copy',
    'block',
    'clear',
    'clear-two-tone',
    'import',
    'export',
    'download',
    'upload',
    'forward',
    'share',
    'clear-unavailable',
  ],

  Users: [
    'user',
    'two-users',
    'group',
    'vacation',
    'sick-leave',
    'no-user',
    'no-user-fill',
  ],

  Arrows: [
    'arrow-right',
    'arrow-down',
    'arrow-left',
    'arrow-up',
    'arrow-alt-right',
    'arrow-alt-down',
    'arrow-alt-left',
    'arrow-alt-up',
    'arrow-double-right',
    'arrow-double-down',
    'arrow-double-left',
    'arrow-double-up',
    'arrow-triangle-right',
    'arrow-triangle-down',
    'arrow-triangle-left',
    'arrow-triangle-up',
    'arrow-timeline',
    'expand-all',
    'collapse-all',
    'undo',
    'trend-down',
    'trend-up',
  ],

  'Errors / Alerts': [
    'error',
    'alert',
    'error-empty',
    'error-circle',
    'alert-circle',
    'error-circle-empty',
  ],

  'Table / List / Filters': [
    'sort',
    'filters',
    'list',
    'table',
    'filters-on',
    'filters-off',
  ],

  'Files / Notes': [
    'document',
    'documents',
    'pdf',
    'attachment',
    'picture',
    'note',
  ],

  Time: [
    'clock',
    'clock-empty',
    'calendar',
    'calendar-alt',
    'history',
    'date-end',
    'hourglass-top',
  ],

  Map: [
    'localize',
    'location',
    'location-outlined',
    'zoom-in',
    'zoom-out',
    'maximize',
    'minimize',
    'layers',
    'new-tab',
    'dynamic',
    'static',
  ],

  Transport: [
    'loading',
    'unloading',
    'semi-trailer-standard',
    'semi-trailer-half-mega',
    'semi-trailer-mega',
    'empty-km',
    'road',
    'tire',
    'compass-up',
    'good',
    'ltl',
    'ftl',
    'weight-fill',
    'weight-half',
    'weight-empty',
    'speed',
    'sent',
    'safe-parking',
  ],

  Places: [
    'customs',
    'car-wash',
    'gas-station',
    'gas-station-outlined',
    'parking',
    'place',
    'base',
    'correspondence-address',
    'garage',
    'warehouse',
    'load-unload-point',
    'ferry',
    'port',
    'airport',
    'tunnel',
    'route',
    'border-crossing',
  ],

  Vehicles: [
    'vehicle',
    'semi-truck',
    'straight-truck',
    'van',
    'trailer',
    'semi-trailer',
    'steering-wheel',
    'low-deck',
    'train',
    'bridge',
  ],

  'Vehicle states': [
    'vehicle-ride',
    'vehicle-free',
    'vehicle-free-outlined',
    'vehicle-disconnected-outlined',
    'vehicle-disconnected-filled',
    'vehicle-failure',
    'vehicle-failure-free',
    'vehicle-good',
    'vehicle-good-fill-two-tone',
    'vehicle-loading-two-tone',
    'vehicle-unloading',
    'vehicle-unloading-two-tone',
    'vehicle-waiting',
    'vehicle-waiting-alt-two-tone',
    'vehicle-off-route',
    'vehicle-off-route-two-tone',
    'vehicle-off-route-on-time-two-tone',
    'vehicle-off-route-delay-risk-two-tone',
    'vehicle-off-route-delayed-two-tone',
    'vehicle-semi-trailer-free',
    'vehicle-trailer-free',
  ],

  'Vehicles list exceptions': [
    'vehicle-list-free-outlined',
    'vehicle-list-free',
    'vehicle-list-failure-free',
    'vehicle-list-semi-trailer-free',
    'vehicle-list-trailer-free',
  ],

  Tacho: [
    'tacho',
    'tacho-ride',
    'tacho-other-work',
    'tacho-break',
    'tacho-availability',
    'tacho-offline',
    'tacho-range',
    'tacho-connection-waiting',
  ],

  Contact: ['whatsapp', 'e-mail', 'telephone', 'envelope'],

  'ADR Class': [
    'adr-1',
    'adr-2-1',
    'adr-2-2',
    'adr-2-3',
    'adr-3',
    'adr-4-1',
    'adr-4-2',
    'adr-4-3',
    'adr-5-1',
    'adr-5-2',
    'adr-6-1',
    'adr-6-2',
    'adr-7',
    'adr-8',
    'adr-9',
  ],

  Requirements: [
    'requirements-food',
    'requirements-adr',
    'requirements-adr-tank',
    'requirements-explosives',
    'requirements-didb',
    'requirements-forklift',
    'requirements-other',
  ],

  Spinner: ['spinner-blue', 'spinner-white', 'spinner-black'],

  Logo: ['signet-light', 'signet-dark'],
} as const;

export const FLAGS = [
  'ad',
  'am',
  'au',
  'be',
  'bm',
  'bw',
  'cf',
  'cm',
  'cw',
  'dk',
  'eg',
  'fi',
  'ga',
  'gh',
  'gr',
  'hn',
  'il',
  'it',
  'kg',
  'kr',
  'lc',
  'lu',
  'me',
  'mn',
  'mv',
  'nc',
  'no',
  'pa',
  'pl',
  'py',
  'sa',
  'si',
  'sr',
  'sz',
  'tk',
  'tt',
  'us',
  'vg',
  'xk',
  'ae',
  'an',
  'aw',
  'bf',
  'bn',
  'by',
  'cg',
  'cn',
  'cx',
  'dm',
  'eh',
  'fj',
  'gb',
  'gi',
  'gt',
  'hr',
  'im',
  'je',
  'kh',
  'kw',
  'li',
  'lv',
  'mg',
  'mo',
  'mw',
  'ne',
  'np',
  'pe',
  'pn',
  'qa',
  'sb',
  'sk',
  'ss',
  'tc',
  'tl',
  'tv',
  'uy',
  'vi',
  'ye',
  'af',
  'ao',
  'az',
  'bg',
  'bo',
  'bz',
  'ch',
  'co',
  'cy',
  'do',
  'er',
  'fk',
  'gd',
  'gl',
  'gu',
  'ht',
  'in',
  'jm',
  'ki',
  'ky',
  'lk',
  'ly',
  'mh',
  'mr',
  'mx',
  'nf',
  'nr',
  'pf',
  'pr',
  'ro',
  'sc',
  'sl',
  'st',
  'td',
  'tm',
  'tw',
  'uz',
  'vn',
  'za',
  'ag',
  'ar',
  'ba',
  'bh',
  'br',
  'ca',
  'ci',
  'cr',
  'cz',
  'dz',
  'es',
  'fm',
  'ge',
  'gm',
  'gw',
  'hu',
  'iq',
  'jo',
  'km',
  'kz',
  'lr',
  'ma',
  'mk',
  'ms',
  'my',
  'ng',
  'nu',
  'pg',
  'ps',
  'rs',
  'sd',
  'sm',
  'sv',
  'tg',
  'tn',
  'tz',
  'va',
  'vu',
  'zm',
  'ai',
  'as',
  'bb',
  'bi',
  'bs',
  'cc',
  'ck',
  'cu',
  'de',
  'ec',
  'et',
  'fo',
  'gf',
  'gn',
  'gy',
  'id',
  'ir',
  'jp',
  'kn',
  'la',
  'ls',
  'mc',
  'ml',
  'mt',
  'mz',
  'ni',
  'nz',
  'ph',
  'pt',
  'ru',
  'se',
  'sn',
  'sx',
  'th',
  'to',
  'ua',
  'vc',
  'wf',
  'zw',
  'al',
  'at',
  'bd',
  'bj',
  'bt',
  'cd',
  'cl',
  'cv',
  'dj',
  'ee',
  'eu',
  'fr',
  'gg',
  'gq',
  'hk',
  'ie',
  'is',
  'ke',
  'kp',
  'lb',
  'lt',
  'md',
  'mm',
  'mu',
  'na',
  'nl',
  'om',
  'pk',
  'pw',
  'rw',
  'sg',
  'so',
  'sy',
  'tj',
  'tr',
  'ug',
  've',
  'ws',
] as const;
