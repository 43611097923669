import { Routes } from '@angular/router';
import { environment } from '../../../config/environment';
import { BASES_ROUTES } from './bases/bases.routes';
import { BUSINESS_ROUTES } from './business/business.routes';
import { CONTRACTORS_ROUTES } from './contractors/contractors.routes';
import { DICTIONARIES_ROUTES } from './dictionaries/dictionaries.routes';
import { DOCUMENTS_ROUTES } from './documents/documents.routes';
import { EMPLOYEES_ROUTES } from './employees/employees.routes';
import { ENGINE_CONFIG_ROUTES } from './engine-config/engine-config.routes';
import { INTEGRATIONS_ROUTES } from './integrations/integrations.routes';
import { POINTS_OF_INTEREST_ROUTES } from './points-of-interest/points-of-interest.routes';

export enum ManagementPanelRoute {
  EMPLOYEES = '/management-panel/employees',
  CONTRACTORS = '/management-panel/contractors',
  POINTS_OF_INTEREST = '/management-panel/points-of-interest',
  BASES = '/management-panel/bases',
  BUSINESS = '/management-panel/business',
  DICTIONARIES = '/management-panel/dictionaries',
  ENGINE_CONFIG = '/management-panel/engine-config',
  DOCUMENTS = '/management-panel/documents',
  INTEGRATIONS = '/management-panel/integrations',
}

export const MANAGEMENT_PANEL_ROUTES: Routes = [
  { path: '', pathMatch: 'prefix', redirectTo: 'employees' },
  { path: 'employees', children: EMPLOYEES_ROUTES },
  { path: 'contractors', children: CONTRACTORS_ROUTES },
  { path: 'points-of-interest', children: POINTS_OF_INTEREST_ROUTES },
  { path: 'bases', children: BASES_ROUTES },
  { path: 'business', children: BUSINESS_ROUTES },
  { path: 'dictionaries', children: DICTIONARIES_ROUTES },
  { path: 'engine-config', children: ENGINE_CONFIG_ROUTES },
  { path: 'documents', children: DOCUMENTS_ROUTES },
  ...(['sandbox', 'dev'].includes(environment.environment)
    ? [{ path: 'integrations', children: INTEGRATIONS_ROUTES }]
    : []),
] as const;
