import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { forkJoin, map, Observable } from 'rxjs';
import { FleetCategory } from '../../dashboard/fleet/common/enums/fleet-category';
import { ValidationStorageKey } from '../enums/storage/validation-storage-key';
import {
  FleetValidation,
  FleetValidationByType,
} from '../interfaces/validation/fleet-validation';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private cacheService: CacheService,
  ) {
    super(http);
  }

  public getFleetValidation(
    category: FleetCategory,
  ): Observable<FleetValidation> {
    const type = category.includes('trailer') ? 'trailer' : 'vehicle';
    const cacheKey = `${ValidationStorageKey.FLEET_VALIDATION}-${type}`;

    return this.cacheService.cacheRequest(
      cacheKey,
      this.get<FleetValidation>('/validation/fleet', {
        params: { type },
      }),
    );
  }

  public getFleetValidationByType(): Observable<FleetValidationByType> {
    const validations$ = [
      FleetCategory.SEMI_TRUCK,
      FleetCategory.SEMI_TRAILER,
    ].map((category) => this.getFleetValidation(category));

    return forkJoin(validations$).pipe(
      map((validations): FleetValidationByType => {
        const mergedValidations = merge({}, ...validations) as FleetValidation;
        return Object.values(mergedValidations).reduce(
          (validationsByType, validationTypes) => {
            for (const [type, validation] of Object.entries(validationTypes)) {
              validationsByType = merge(validationsByType, {
                [type]: validation,
              });
            }
            return validationsByType;
          },
          {} as FleetValidationByType,
        );
      }),
    );
  }
}
