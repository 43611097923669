import { AsyncPipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxAmountForm,
  OnyxButtonComponent,
  OnyxDatepickerComponent,
  OnyxDatePipe,
  OnyxDropdownComponent,
  OnyxFormGroupComponent,
  OnyxInformationHeadingComponent,
  onyxMaxDateValidator,
  onyxMinDateValidator,
  OnyxSuggestion,
  OnyxSuggestionsComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
  OnyxUploadComponent,
} from '@onyx/angular';
import { chain, isEqual, isObject, sumBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { I18nPipe } from '../../../../../common/pipes/i18n.pipe';
import { AmountService } from '../../../../../common/services/amount.service';
import { DictionariesService } from '../../../../../common/services/dictionaries.service';
import { PreferencesService } from '../../../../../common/services/preferences.service';
import { OrderTimeWindowType } from '../../../common/enums/order-time-window-type';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';
import { OrdersService } from '../../../common/services/orders.service';

type OrderModalInvoiceFormGroup = ReturnType<
  OrderModalInvoiceFormComponent['buildForm']
>;

export type OrderModalInvoiceForm = ReturnType<
  OrderModalInvoiceFormGroup['getRawValue']
>;

@Component({
  selector: 'app-order-modal-invoice-form',
  imports: [
    OnyxInformationHeadingComponent,
    OnyxDatepickerComponent,
    OnyxTextFieldComponent,
    OnyxDropdownComponent,
    OnyxUploadComponent,
    OnyxButtonComponent,
    OnyxFormGroupComponent,
    TranslatePipe,
    ReactiveFormsModule,
    AsyncPipe,
    OnyxSuggestionsComponent,
    UpperCasePipe,
    I18nPipe,
  ],
  templateUrl: './order-modal-invoice-form.component.html',
  styleUrl: './order-modal-invoice-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalInvoiceFormComponent {
  protected readonly I18N = 'orders.orderModal';

  protected readonly currencies$ = this.dictionariesService.getDictionary(
    DictionaryCode.CURRENCY,
  );

  public order = input.required<Order>();

  public hideFormChange = output<void>();

  protected form = this.buildForm();
  protected loading = signal(false);
  protected sellDateSuggestions = signal<OnyxSuggestion<string>[] | null>(null);
  protected grossResult = signal<{ value: number; currency: string }[]>([]);

  constructor(
    private fb: NonNullableFormBuilder,
    private dictionariesService: DictionariesService,
    private preferencesService: PreferencesService,
    private toastService: OnyxToastService,
    private datePipe: OnyxDatePipe,
    private ordersService: OrdersService,
    private amountService: AmountService,
    private destroyRef: DestroyRef,
  ) {
    const controls = this.form.controls;
    const sellDateControl = controls.sellDate;
    const nbpRateDayControl = controls.nbpRateDay;

    const sellDate$ = sellDateControl.valueChanges.pipe(distinctUntilChanged());

    sellDate$.pipe(take(1)).subscribe((value) => {
      if (!value) return;

      const previousDay = DateTime.fromISO(value)
        .minus({ days: 1 })
        .toISODate();
      nbpRateDayControl.setValue(previousDay);
    });

    sellDate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      const date = value ? DateTime.fromISO(value) : null;
      const validators = date?.isValid
        ? [onyxMinDateValidator(date), Validators.required]
        : [Validators.required];

      controls.paymentTerm.setValidators(validators);
      controls.paymentTerm.updateValueAndValidity();
    });

    controls.nbpRateDay.valueChanges
      .pipe(
        map(() => controls.nbpRateDay.getRawValue()),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => {
        if (controls.nbpRateDay.invalid) {
          controls.nbpRateDay.markAllAsTouched();
        }

        ValidationHelper.toggleControls(
          [controls.vat, controls.net],
          !!value && controls.nbpRateDay.valid,
        );
      });

    effect(() => {
      for (const control of [controls.net, controls.vat]) {
        control.controls.currency.setValue(this.order().price.currency);
      }
    });

    effect(() => {
      const point = OrderHelper.getLastUnloadingPoint(this.order().points);
      const { type, windows } = point.timeWindows;

      const date =
        type === OrderTimeWindowType.FIX
          ? windows[0].date
          : windows.at(-1)!.date;

      this.sellDateSuggestions.set([
        {
          name: this.datePipe.transform(date, 'date-dot'),
          value: date,
        },
      ]);
    });

    combineLatest([
      controls.nbpRateDay.valueChanges,
      controls.net.valueChanges,
      controls.vat.valueChanges,
    ])
      .pipe(
        map((values) => values.filter((value) => isObject(value))),
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        debounceTime(300),
        map((amounts) =>
          chain(amounts)
            .reduce(
              (result, { value, currency }) => {
                if (value != null && currency) {
                  result[currency] = (result[currency] ?? 0) + value;
                }
                return result;
              },
              {} as Record<string, number>,
            )
            .entries()
            .value(),
        ),
        switchMap((amounts): Observable<OnyxAmountForm[]> => {
          if (!amounts.length) return of([]);

          const rateDate = controls.nbpRateDay.getRawValue();
          const calculateAmount$ = (
            value: number,
            currency: string,
            resultCurrency: string,
          ): Observable<OnyxAmountForm> => {
            if (currency === resultCurrency) {
              return of({ value, currency, date: rateDate });
            }

            return this.amountService
              .calculateAmount({
                date: rateDate,
                value,
                currency,
                resultCurrency,
              })
              .pipe(
                map(({ resultCurrencyValue, resultCurrency, date }) => ({
                  value: resultCurrencyValue!,
                  currency: resultCurrency!,
                  date,
                })),
              );
          };

          const defaultCurrency = this.order().price.defaultCurrency;
          const resultCurrencies = uniq([
            defaultCurrency,
            ...amounts.map(([currency]) => currency),
          ]);

          const results$ = amounts.flatMap(([currency, value]) =>
            resultCurrencies.map((resultCurrency) =>
              calculateAmount$(value, currency, resultCurrency),
            ),
          );
          return forkJoin(results$).pipe(
            map((results) =>
              chain(results)
                .groupBy((result) => result.currency)
                .mapValues((values) => ({
                  ...values[0],
                  value: sumBy(values, (value) => value.value),
                }))
                .values()
                .value(),
            ),
          );
        }),
      )
      .subscribe((result) => this.grossResult.set(result));
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const dto = this.form.getRawValue();

    this.loading.set(true);
    this.ordersService
      .addInvoice(this.order().uuid, dto)
      .subscribe({
        next: () =>
          this.toastService.showSuccess(`${this.I18N}.toasts.invoiceAdded`, {
            keepOnNavigation: true,
          }),
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      number: this.fb.control<string | null>(null, [Validators.required]),
      sellDate: this.fb.control<string | null>(null, [Validators.required]),
      paymentTerm: this.fb.control<string | null>(null, [Validators.required]),
      nbpRateDay: this.fb.control<string | null>(null, [
        Validators.required,
        onyxMaxDateValidator(),
      ]),
      net: FormHelper.buildAmountForm(this.fb, this.preferencesService, {
        disabled: true,
      }),
      vat: FormHelper.buildAmountForm(this.fb, this.preferencesService, {
        disabled: true,
      }),
      scan: this.fb.control<string | null>(null),
    });
  }
}
