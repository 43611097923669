import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  computed,
  DestroyRef,
  Injectable,
  Injector,
  linkedSignal,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxAmountForm,
  OnyxFormMode,
  OnyxLanguagePipe,
  OnyxOptional,
  OnyxRoute,
  OnyxRouteSummary,
  OnyxToastService,
} from '@onyx/angular';
import {
  chain,
  isEqual,
  isObject,
  isString,
  mergeWith,
  minBy,
  sumBy,
  uniq,
} from 'lodash';
import { DateTime } from 'luxon';
import {
  catchError,
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  first,
  forkJoin,
  isObservable,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
  zip,
} from 'rxjs';
import { PartialDeep } from 'type-fest';
import { validate as validateUuid } from 'uuid';
import { AuthService } from '../../../../auth/common/services/auth.service';
import { DocumentsModalFormComponent } from '../../../../common/components/forms/documents-form/documents-modal-form/documents-modal-form.component';
import { SCHEDULE_COMPLIANCE_DELAY_RISK_THRESHOLD } from '../../../../common/constants/common/schedule-compliance-delay-risk-threshold';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { ScheduleCompliance } from '../../../../common/enums/schedule-compliance';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import {
  CalculateRouteRequest,
  RouteConfig,
} from '../../../../common/interfaces/address/calculate-route-request';
import { BusinessHours } from '../../../../common/interfaces/common/business-hours';
import {
  FleetType,
  FleetValidationByType,
} from '../../../../common/interfaces/validation/fleet-validation';
import { ValidationField } from '../../../../common/interfaces/validation/validation-field';
import { AddressService } from '../../../../common/services/address.service';
import { AmountService } from '../../../../common/services/amount.service';
import { CacheService } from '../../../../common/services/cache.service';
import { PreferencesService } from '../../../../common/services/preferences.service';
import { Contractor } from '../../../management-panel/contractors/common/interfaces/contractor';
import { CompanyDictionaryCode } from '../../../management-panel/dictionaries/common/enums/company-dictionary-code';
import { CompanyDictionariesService } from '../../../management-panel/dictionaries/common/services/company-dictionaries.service';
import { OrderFormRouteConfigModalComponent } from '../../order-form/order-form-route/order-form-route-config-modal/order-form-route-config-modal.component';
import {
  OrderForm,
  OrderFormComponent,
  OrderFormDto,
  OrderFormGroup,
} from '../../order-form/order-form.component';
import { OrderCheckpointFormComponent } from '../../order-form/order-points-form/order-checkpoint-form/order-checkpoint-form.component';
import {
  OrderLoadingPointForm,
  OrderLoadingPointFormComponent,
} from '../../order-form/order-points-form/order-loading-point-form/order-loading-point-form.component';
import {
  OrderPointForm,
  OrderPointFormGroup,
} from '../../order-form/order-points-form/order-point-form/order-point-form.component';
import { OrderTransitFormComponent } from '../../order-form/order-points-form/order-transit-form/order-transit-form.component';
import {
  OrderUnloadingPointForm,
  OrderUnloadingPointFormComponent,
} from '../../order-form/order-points-form/order-unloading-point-form/order-unloading-point-form.component';
import { OrdersRoute } from '../../orders.routes';
import { LOADING_METERS_DIMENSIONS } from '../constants/loading-meters-dimensions';
import { ORDER_BASE_PARAMETERS_KEYS } from '../constants/order-base-parameters-keys';
import { ORDER_FORM_GOOD_COLORS } from '../constants/order-form-good-colors';
import { OrderFormRestorePointType } from '../enums/order-form-cancel-action-type';
import { OrderFormRouteType } from '../enums/order-form-route-type';
import { OrderPointCategory } from '../enums/order-point-category';
import {
  OrderPointType,
  OrderPointTypeByCategory,
} from '../enums/order-point-type';
import { OrderRateType } from '../enums/order-rate-type';
import { OrderTransitPointTimeWindowType } from '../enums/order-transit-point-time-window-type';
import { OrdersStorageKey } from '../enums/orders-storage-key';
import { Order } from '../interfaces/order';
import { OrderFormGood } from '../interfaces/order-form-good';
import { OrderFormPoint } from '../interfaces/order-form-point';
import { OrderFormPrice } from '../interfaces/order-form-price';
import { OrderFormRoute } from '../interfaces/order-form-route';
import { OrdersService } from './orders.service';

@Injectable()
export class OrderFormService {
  public readonly goodTypes$ = this.companyDictionariesService.reload$.pipe(
    startWith(undefined),
    switchMap(() =>
      this.companyDictionariesService.listDictionary(
        CompanyDictionaryCode.GOOD_TYPE,
      ),
    ),
    shareReplay(1),
    takeUntilDestroyed(this.destroyRef),
  );
  public readonly units$ = this.companyDictionariesService.reload$.pipe(
    startWith(undefined),
    switchMap(() =>
      this.companyDictionariesService.listDictionary(
        CompanyDictionaryCode.UNIT,
      ),
    ),
    shareReplay(1),
    takeUntilDestroyed(this.destroyRef),
  );

  private readonly I18N = 'orders.orderForm';

  public form = this.buildForm();
  public mode = signal(OnyxFormMode.ADD);
  public order = signal<Order | null>(null);
  public fleetValidation = signal<FleetValidationByType | null>(null);
  public validation = computed(() =>
    this.getValidation(this.fleetValidation(), this.vehicleTypes()),
  );

  public valueChanges$ = this.form.valueChanges.pipe(
    map(() => this.form.getRawValue()),
    distinctUntilChanged((previous, current) => isEqual(previous, current)),
    shareReplay(1),
    takeUntilDestroyed(this.destroyRef),
  );
  public value = toSignal(this.valueChanges$, {
    initialValue: this.form.getRawValue(),
  });
  public vehicleTypes = toSignal(
    this.valueChanges$.pipe(map((form) => form.basicInformation.vehicleTypes)),
    { initialValue: [] },
  );
  public adrClasses = toSignal(
    this.valueChanges$.pipe(map((form) => form.parameters.adrClasses)),
    { initialValue: null },
  );
  public isDedicated = toSignal(
    this.valueChanges$.pipe(map((form) => form.parameters.isDedicated!)),
    { initialValue: true },
  );
  public hasEcmr = toSignal(
    this.valueChanges$.pipe(map((form) => form.parameters.hasEcmr!)),
    { initialValue: false },
  );

  public points = computed<OrderFormPoint[]>(() => {
    const formGoods = this.getGoods();
    const typeCounter = new Map<string, number>();

    const pointsForm = this.form.controls.points;
    const points = pointsForm.controls.map((form, index) => {
      const value = form.getRawValue() as OrderPointForm;
      const { category, type } = value;

      const typeIndex = typeCounter.get(type) ?? 0;
      typeCounter.set(type, typeIndex + 1);

      const goods = formGoods.filter(
        ({ good, pointIndex }) =>
          (pointIndex < index && good.type) || pointIndex === index,
      );

      return {
        form: form as OrderPointFormGroup,
        value,
        index,
        isFirst: index === 0,
        isLast: index === pointsForm.length - 1,
        category,
        type,
        typeIndex,
        goods,
      };
    });

    return points;
  });

  public hasInvalidAddress = computed(() =>
    this.value()
      .points.filter((point): point is OrderPointForm => point != null)
      .flatMap((point) =>
        'address' in point
          ? [point.address]
          : [point.startAddress, point.endAddress],
      )
      .some((address) => !address?.coordinates),
  );

  public routeConfig = signal<RouteConfig | null>(null);
  public routes = computed(() =>
    chain(this.routes_())
      .map(
        (route): OrderFormRoute => ({
          name: '',
          value: route,
          cost: route.summary.totalCost,
          duration: route.summary.duration,
          distance: route.summary.distance,
          types: [],
        }),
      )
      .orderBy([
        (route) => route.cost.resultCurrencyValue,
        (route) => route.duration,
        (route) => route.distance,
      ])
      .thru((options) => {
        const cheapest = minBy(
          options,
          (option) => option.cost.resultCurrencyValue,
        );
        const fastest = minBy(options, (option) => option.duration);
        cheapest?.types.push(OrderFormRouteType.CHEAPEST);
        fastest?.types.push(OrderFormRouteType.FASTEST);

        return options;
      })
      .orderBy(
        [
          (route) => route.types.includes(OrderFormRouteType.CHEAPEST),
          (route) => route.types.includes(OrderFormRouteType.FASTEST),
        ],
        ['desc', 'desc'],
      )
      .map((option) => {
        const types = option.types.length
          ? option.types
          : [OrderFormRouteType.ALTERNATIVE];

        return {
          ...option,
          name: types.length > 1 ? 'optimal' : types[0],
          types,
        };
      })
      .value(),
  );
  public activeRouteCompliance = computed(() => {
    this.value();
    if (!this.activeRoute()) return null;
    return this.validatePointsRoute({ showErrors: false });
  });

  private sender$_ = new ReplaySubject<Contractor | null>(1);
  public get sender$() {
    return this.sender$_.asObservable();
  }

  private price$_ = this.getOrderPrice$();
  public get price$() {
    return this.price$_;
  }

  private loading_ = signal(false);
  public get loading() {
    return this.loading_.asReadonly();
  }

  private showRoutePreview_ = signal(false);
  public get showRoutePreview() {
    return this.showRoutePreview_.asReadonly();
  }

  private activeRoute_ = linkedSignal(() => this.routes().at(0)?.value ?? null);
  public get activeRoute() {
    return this.activeRoute_.asReadonly();
  }

  private restorePoints$_ = new Subject<OrderFormRestorePointType>();
  public get restorePoints$() {
    return this.restorePoints$_.asObservable();
  }

  private routes_ = signal<OnyxRoute[]>([]);

  constructor(
    private companyDictionariesService: CompanyDictionariesService,
    private destroyRef: DestroyRef,
    private amountService: AmountService,
    private fb: NonNullableFormBuilder,
    private authService: AuthService,
    private preferencesService: PreferencesService,
    private toastService: OnyxToastService,
    private translateService: TranslateService,
    private languagePipe: OnyxLanguagePipe,
    private addressService: AddressService,
    private ordersService: OrdersService,
    private cacheService: CacheService,
    private router: Router,
    private injector: Injector,
  ) {}

  public getOrderPrice$(): Observable<OrderFormPrice> {
    return this.valueChanges$.pipe(
      map((form): OnyxAmountForm | null => {
        const { basicInformation, points, route } = form;
        const { rate, rateType } = basicInformation;
        const { value, currency } = rate;
        if (value == null || currency == null) return null;

        switch (rateType) {
          case OrderRateType.FREIGHT:
            return { value, currency, date: null };
          case OrderRateType.TONNE:
            return chain(points)
              .filter(
                (point): point is OrderLoadingPointForm =>
                  point['category'] === OrderPointCategory.LOADING,
              )
              .flatMap((point) => point.goods)
              .sumBy((good) => good.totalWeight ?? 0)
              .thru((totalWeight) => ({
                value: Math.ceil(value * (totalWeight / 1_000)),
                currency,
                date: null,
              }))
              .value();
          case OrderRateType.EVERY_KILOMETER:
          case OrderRateType.LOADED_KILOMETER: {
            if (!route) return null;
            return {
              value: Math.ceil(value * (route.summary.distance / 1_000)),
              currency,
              date: null,
            };
          }
          case OrderRateType.CUBIC_METER:
            return chain(points)
              .filter(
                (point): point is OrderLoadingPointForm =>
                  point['category'] === OrderPointCategory.LOADING,
              )
              .flatMap((point) => point.goods)
              .sumBy((good) => {
                const quantity = good.quantity ?? 0;
                const { size } = good;
                if (size.volume) return (quantity * size.volume) / 100;

                const dimensions = size.loadingMeters
                  ? { length: size.loadingMeters, ...LOADING_METERS_DIMENSIONS }
                  : size;
                return chain(dimensions)
                  .thru((size) => [size.length, size.width, size.height])
                  .reduce((result, dimension) => result * (dimension ?? 0), 1)
                  .thru((volume) => (quantity * volume) / Math.pow(10, 3 * 2))
                  .value();
              })
              .thru((totalVolume) => ({
                value: Math.ceil(value * (totalVolume / 100)),
                currency,
                date: null,
              }))
              .value();
          default:
            return null;
        }
      }),
      distinctUntilChanged((previous, current) => isEqual(previous, current)),
      shareReplay(1),
      combineLatestWith(this.sender$),
      switchMap(([price, sender]): Observable<OrderFormPrice> => {
        const remainingTradeCreditLimit =
          sender?.payment.parameters.tradeCreditLimit;
        // TEMP: replace with remaining trade credit limit when BE will be ready
        if (!price || !sender || remainingTradeCreditLimit == null) {
          return of({ price, senderPrice: null, isOverCreditLimit: false });
        }

        if (remainingTradeCreditLimit.currency === price.currency) {
          return of({
            price,
            senderPrice: price,
            isOverCreditLimit: remainingTradeCreditLimit.value < price.value,
          });
        }

        return this.amountService
          .calculateAmount({
            ...price,
            resultCurrency: remainingTradeCreditLimit.currency,
          })
          .pipe(
            debounceTime(300),
            map((senderPrice) => ({
              price,
              senderPrice,
              isOverCreditLimit:
                remainingTradeCreditLimit.value < senderPrice.value,
            })),
          );
      }),
      shareReplay(1),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  public addRestorePoint(restorePoint: OrderFormRestorePointType) {
    this.restorePoints$_.next(restorePoint);
  }

  public restoreForm(order: PartialDeep<OrderForm>): void {
    FormHelper.reset(this.form);

    const documents = order.documents ?? [];
    const documentsControl = this.form.controls.documents;
    for (const _ of documents) {
      documentsControl.push(DocumentsModalFormComponent.buildForm(this.fb));
    }

    const points = order.points as OrderPointForm[];
    const pointsControl = this.form.controls.points;
    for (const point of points ?? []) {
      switch (point.category) {
        case OrderPointCategory.LOADING:
          pointsControl.push(
            OrderLoadingPointFormComponent.buildForm(
              this.fb,
              point.timeWindows.windows.length,
              point.goods.length,
            ),
          );
          break;
        case OrderPointCategory.UNLOADING:
          pointsControl.push(
            OrderUnloadingPointFormComponent.buildForm(
              this.fb,
              point.timeWindows.windows.length,
              point.goods.length,
            ),
          );
          break;
        case OrderPointCategory.CHECKPOINT:
          pointsControl.push(
            OrderCheckpointFormComponent.buildForm(
              this.fb,
              point.type,
              point.includeInOrder,
              false,
            ),
          );
          break;
        case OrderPointCategory.TRANSIT:
          pointsControl.push(
            OrderTransitFormComponent.buildForm(
              this.fb,
              point.type,
              point.includeInOrder,
              false,
            ),
          );
          break;
      }
    }

    this.form.patchValue(order as Partial<OrderForm>);
  }

  public changeSender(sender: Contractor | null): void {
    this.sender$_.next(sender);
  }

  public addPoint(
    point: {
      category: OrderPointCategory;
      value: string;
      includeInOrder: boolean;
      isAlwaysOpen: boolean;
    },
    index?: number,
  ): void {
    const pointsForm = this.form.controls.points;
    index ??= pointsForm.length;

    switch (point.category) {
      case OrderPointCategory.LOADING:
        pointsForm.insert(
          index,
          OrderLoadingPointFormComponent.buildForm(this.fb),
        );
        this.closeRoutePreview();
        break;
      case OrderPointCategory.UNLOADING:
        pointsForm.insert(
          index,
          OrderUnloadingPointFormComponent.buildForm(this.fb),
        );
        this.closeRoutePreview();
        break;
      case OrderPointCategory.CHECKPOINT:
        pointsForm.insert(
          index,
          OrderCheckpointFormComponent.buildForm(
            this.fb,
            point.value as OrderPointTypeByCategory[OrderPointCategory.CHECKPOINT],
            point.includeInOrder,
            point.isAlwaysOpen,
          ),
        );
        break;
      case OrderPointCategory.TRANSIT:
        pointsForm.insert(
          index,
          OrderTransitFormComponent.buildForm(
            this.fb,
            point.value as OrderPointTypeByCategory[OrderPointCategory.TRANSIT],
            point.includeInOrder,
            point.isAlwaysOpen,
          ),
        );
        break;
    }
  }

  public movePoint(index: number, direction: -1 | 1): void {
    const points = this.form.controls.points;
    const hasRelatedGoods = chain([index, index + direction])
      .map((index) => points.at(index).getRawValue() as OrderPointForm)
      .filter((point) => 'goods' in point)
      .flatMap((point) => point.goods)
      .map((good) => good.uuid)
      .thru((goods) => uniq(goods).length !== goods.length)
      .value();

    if (hasRelatedGoods) {
      this.restorePoints$_.next(OrderFormRestorePointType.MOVED_POINT);
      this.closeRoutePreview();
    }

    moveItemInArray(points.controls, index, index + direction);
    points.updateValueAndValidity();
  }

  public removePoint(index: number): void {
    const pointsForm = this.form.controls.points;
    this.addRestorePoint(OrderFormRestorePointType.REMOVED_POINT);
    this.form.controls.points.removeAt(index);
    pointsForm.updateValueAndValidity();
  }

  public setRoutes(
    routes: OnyxRoute[],
    previousRouteSummary?: OnyxRouteSummary,
  ): void {
    const previousRoute = routes.find(
      ({ summary }) =>
        previousRouteSummary?.distance === summary.distance &&
        previousRouteSummary?.duration === summary.duration,
    );

    this.routes_.set(routes);
    this.changeRoute(previousRoute ?? this.activeRoute());
  }

  public updateRoutes(): Observable<OnyxRoute[]> {
    if (this.hasInvalidAddress()) return EMPTY;

    this.setRoutes([]);

    const dto = this.getDto();
    const previousRoute = dto.route?.summary;

    return this.getRouteConfig(dto).pipe(
      map(
        (routeConfig): CalculateRouteRequest => ({
          ...routeConfig,
          points: dto.points
            .filter((point): point is OrderPointForm => point != null)
            .map((point) =>
              'address' in point
                ? {
                    category: point.category,
                    coordinates: point.address!.coordinates,
                  }
                : {
                    category: point.category,
                    from: point.startAddress!.coordinates,
                    to: point.endAddress!.coordinates,
                  },
            ),
        }),
      ),
      switchMap((request) => this.addressService.calculateRoute(request)),
      catchError((response) => {
        ValidationHelper.handleUnexpectedError(response, this.toastService);
        return EMPTY;
      }),
      switchMap(({ routes }) => {
        this.setRoutes(routes, previousRoute);

        const route = routes.at(0);
        if (!route) {
          this.toastService.showError(`${this.I18N}.toasts.noRoute`);
          return EMPTY;
        } else if (!route.summary.distance) {
          this.toastService.showError(`${this.I18N}.toasts.emptyRoute`);
          return EMPTY;
        }

        return of(routes);
      }),
    );
  }

  public changeRoute(
    route: OnyxRoute | null,
    options?: { isVerified?: boolean; isBlocked?: boolean },
  ): void {
    this.activeRoute_.set(route);
    this.form.controls.route.setValue(
      route
        ? {
            isVerified: options?.isVerified ?? false,
            isBlocked: options?.isBlocked ?? false,
            config: this.routeConfig()!,
            summary: route.summary,
          }
        : null,
    );

    const pointsForm = this.form.controls.points;
    for (const index of this.form.controls.points.controls.keys()) {
      const pointForm = pointsForm.at(index) as OrderPointFormGroup;
      pointForm.controls.route.setValue(route?.points[index] ?? null);
    }
  }

  public cancel(force = false): void {
    this.cacheService.delete(OrdersStorageKey.ADD_ORDER_FORM);
    this.router.navigateByUrl(
      force || this.mode() === OnyxFormMode.ADD
        ? OrdersRoute.ORDERS_LIST
        : OrdersRoute.ORDER_CARD.replace(':uuid', this.order()!.uuid),
    );
  }

  public submit(options: {
    verifyRoute: boolean;
    ignoreCreditLimit: boolean;
    isVerified?: boolean;
    isBlocked?: boolean;
  }): void {
    if (this.loading()) return;

    for (const pointForm of this.form.controls.points.controls) {
      pointForm.setErrors(null);
    }
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const dto = this.getDto();
    if (!this.validatePoints(dto)) return;

    const { verifyRoute, ignoreCreditLimit } = options;

    this.loading_.set(true);
    of(dto)
      .pipe(
        switchMap((dto) => zip(of(dto), this.getRouteConfig(dto))),
        switchMap(([dto, routeConfig]) => {
          if (
            isEqual(dto.route?.config, routeConfig) &&
            (!verifyRoute ? dto.route : this.activeRoute())
          ) {
            return of(null);
          }

          return this.updateRoutes();
        }),
        switchMap(() => {
          if (
            !verifyRoute &&
            !this.validatePointsRoute({ showErrors: true }).ok
          ) {
            return EMPTY;
          }

          const continueAction = () => {
            if (verifyRoute) {
              this.showRoutePreview_.set(true);
              return EMPTY;
            }

            const dto = this.getDto();
            dto.route = {
              ...dto.route!,
              isVerified: options.isVerified ?? dto.route?.isVerified ?? false,
              isBlocked: options.isBlocked ?? dto.route?.isBlocked ?? false,
            };

            return this.mode() === OnyxFormMode.ADD
              ? this.ordersService.addOrder(dto)
              : this.ordersService.editOrder(this.order()!.uuid, dto);
          };
          if (ignoreCreditLimit) return continueAction();

          return this.getOrderPrice$().pipe(
            first(),
            filter(({ isOverCreditLimit }) => !isOverCreditLimit),
            switchMap(() => continueAction()),
          );
        }),
      )
      .subscribe({
        next: (order) => {
          this.toastService.showSuccess(
            this.translateService.instant(
              `${this.I18N}.${this.mode()}Success`,
              { identifier: order.identifier },
            ),
            { keepOnNavigation: true },
          );
          this.cancel();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading_.set(false));
  }

  public closeRoutePreview() {
    this.showRoutePreview_.set(false);
  }

  public getGoods(): OrderFormGood[] {
    const pointsForm = this.value().points as OrderPointForm[];
    return pointsForm
      .map((form, index) => ({ form, index }))
      .filter(({ form }) => form['category'] === OrderPointCategory.LOADING)
      .map(({ form, index }) => ({
        loadingPoint: form as OrderLoadingPointForm,
        index,
      }))
      .flatMap(({ loadingPoint, index }) =>
        loadingPoint.goods.map((good) => ({
          loadingPoint,
          index,
          good,
        })),
      )
      .map(({ loadingPoint, index, good }, goodIndex): OrderFormGood => {
        const unloadingPoints = pointsForm
          .filter(
            (form): form is OrderUnloadingPointForm =>
              form['category'] === OrderPointCategory.UNLOADING,
          )
          .map((unloadingPoint, index) => ({
            unloadingPoint,
            unloadingPointIndex: index,
            goodQuantity: unloadingPoint.goods.find(
              ({ uuid }) => uuid === good.uuid,
            )?.quantity,
          }))
          .filter(({ goodQuantity }) => goodQuantity !== undefined);

        return {
          good,
          goodIndex,
          goodColor:
            ORDER_FORM_GOOD_COLORS[goodIndex % ORDER_FORM_GOOD_COLORS.length],
          loadingPoint,
          pointIndex: index,
          unloadingPoints: new Map(
            unloadingPoints.map((unloadingPoint) => [
              unloadingPoint.unloadingPointIndex,
              unloadingPoint.goodQuantity || 0,
            ]),
          ),
          availableQuantity:
            (good.quantity || 0) - sumBy(unloadingPoints, 'goodQuantity'),
        };
      });
  }

  private getDto(): OrderFormDto {
    const form = this.form.getRawValue();
    const loadedSemiTrailer = form.basicInformation.loadedSemiTrailer;

    const dto: OrderFormDto = {
      ...form,
      basicInformation: {
        ...form.basicInformation,
        loadedSemiTrailer: undefined,
        loadedSemiTrailerUuid:
          loadedSemiTrailer && validateUuid(loadedSemiTrailer)
            ? loadedSemiTrailer
            : null,
        loadedSemiTrailerRegistrationNumber:
          loadedSemiTrailer && !validateUuid(loadedSemiTrailer)
            ? loadedSemiTrailer
            : null,
      },
      points: form.points.map((point) => {
        if (point['category'] !== OrderPointCategory.LOADING) return point;

        const loadingPoint = point as OrderLoadingPointForm;
        return {
          ...loadingPoint,
          goods: loadingPoint.goods.map((good) => ({
            ...good,
            type: good.type && validateUuid(good.type) ? good.type : null,
            typeName: good.type && !validateUuid(good.type) ? good.type : null,
          })),
        };
      }),
    };

    const validationFields =
      this.validation()?.['additionalParameters']?.fields;
    if (!validationFields) return dto;

    for (const fieldKey of Object.keys(
      dto.parameters,
    ) as (keyof typeof dto.parameters)[]) {
      if (ORDER_BASE_PARAMETERS_KEYS.includes(fieldKey as any)) continue;

      if (!validationFields[fieldKey]) {
        delete dto.parameters[fieldKey];
      } else if (validationFields[fieldKey].type === 'forbidden') {
        (dto.parameters as any)[fieldKey] = null as any;
      }
    }

    return dto;
  }

  private validatePoints(dto: OrderFormDto): boolean {
    try {
      const pointsCategoryCount = chain(dto.points)
        .countBy('category')
        .value() as Record<OrderPointCategory, number>;
      if (
        !pointsCategoryCount[OrderPointCategory.LOADING] ||
        !pointsCategoryCount[OrderPointCategory.UNLOADING]
      ) {
        throw `${this.I18N}.toasts.noLoadingOrUnloading`;
      }

      const isDedicated = this.isDedicated();
      const loadingUnloadingCount =
        (pointsCategoryCount[OrderPointCategory.LOADING] ?? 0) +
        (pointsCategoryCount[OrderPointCategory.UNLOADING] ?? 0);

      if (isDedicated) {
        if (loadingUnloadingCount > 5) {
          throw this.translateService.instant(
            `${this.I18N}.toasts.ftlPointsLimit`,
            { limit: 5, overflow: loadingUnloadingCount - 5 },
          );
        } else if (
          pointsCategoryCount[OrderPointCategory.LOADING] > 1 &&
          pointsCategoryCount[OrderPointCategory.UNLOADING] > 1
        ) {
          throw `${this.I18N}.toasts.ftlPointsCategoryLimit`;
        }
      } else if (!isDedicated && loadingUnloadingCount > 2) {
        throw this.translateService.instant(
          `${this.I18N}.toasts.ltlPointsLimit`,
          { limit: 2, overflow: loadingUnloadingCount - 2 },
        );
      }

      if (!isDedicated) {
        const illegalPoints = chain(dto.points as OrderPointForm[])
          .filter(
            (point) =>
              ![
                OrderPointCategory.LOADING,
                OrderPointCategory.UNLOADING,
              ].includes(point.category),
          )
          .filter((point) => point.type !== OrderPointType.CUSTOMS)
          .map((point) => point.type)
          .uniq()
          .map((type) =>
            this.translateService.instant(
              `${DictionaryCode.ORDER_POINT_TYPE}.${type}`,
            ),
          )
          .join(', ')
          .value();
        if (illegalPoints) {
          throw this.translateService.instant(
            `${this.I18N}.toasts.ltlIllegalPoints`,
            { illegalPoints },
          );
        }
      }

      const notUnloadedGoods$ = chain(this.getGoods())
        .filter((good) => good.availableQuantity > 0)
        .map((good) => good.good.type!)
        .map((goodType) => {
          if (!validateUuid(goodType)) return of(`"${goodType}"`);
          return this.goodTypes$.pipe(
            first(),
            map((goodTypes) => goodTypes.find(({ uuid }) => uuid === goodType)),
            map((type) =>
              type ? this.languagePipe.transform(type.names) : goodType,
            ),
            map((type) => `"${type}"`),
          );
        })
        .value();
      if (notUnloadedGoods$.length) {
        throw forkJoin(notUnloadedGoods$).pipe(
          map((notUnloadedGoods) => {
            if (notUnloadedGoods.length === 1) {
              return this.translateService.instant(
                `${this.I18N}.toasts.notUnloadedGood`,
                { good: notUnloadedGoods[0] },
              );
            }

            return this.translateService.instant(
              `${this.I18N}.toasts.notUnloadedGoods`,
              { goods: notUnloadedGoods.join(', ') },
            );
          }),
          tap((error) => this.toastService.showError(error)),
        );
      }
    } catch (error) {
      if (isString(error)) this.toastService.showError(error);
      if (isObservable(error)) error.subscribe();
      return false;
    }

    return true;
  }

  private validatePointsRoute(options: { showErrors: boolean }): {
    ok: boolean;
    routeCompliance: ScheduleCompliance;
    pointsCompliance: ScheduleCompliance[];
  } {
    const getNextDate = (
      previous: DateTime | null,
      window: { from: DateTime; to: DateTime } | null,
    ): { compliance: ScheduleCompliance; next: DateTime | null } => {
      if (window == null || previous == null) {
        return {
          compliance: ScheduleCompliance.ON_TIME,
          next: previous ?? window?.from ?? null,
        };
      }

      const next = DateTime.max(previous, window.from);
      const timeBuffer = window.to.diff(previous);

      if (timeBuffer.toMillis() < 0) {
        return { compliance: ScheduleCompliance.DELAYED, next };
      } else if (timeBuffer <= SCHEDULE_COMPLIANCE_DELAY_RISK_THRESHOLD) {
        return { compliance: ScheduleCompliance.DELAY_RISK, next };
      }
      return { compliance: ScheduleCompliance.ON_TIME, next };
    };

    const pointsCompliance = [];
    let lastDate: DateTime | null = null;

    const pointsControl = this.form.controls.points;
    for (const pointForm of pointsControl.controls) {
      const point = pointForm.getRawValue() as OrderPointForm;
      const { category, serviceTime } = point;

      if (lastDate) {
        lastDate = lastDate.plus({
          seconds: point.route?.summary.duration ?? 0,
        });
      }

      let result: ReturnType<typeof getNextDate>;
      if (
        category === OrderPointCategory.LOADING ||
        category === OrderPointCategory.UNLOADING
      ) {
        result = chain(point.timeWindows.windows)
          .map((window) => ({
            date: window.date!,
            timeFrom: window.timeRange?.from ?? window.time!,
            timeTo: window.timeRange?.to ?? window.time!,
          }))
          .map((window) => ({
            from: DateTime.fromISO(`${window.date}T${window.timeFrom}`),
            to: DateTime.fromISO(`${window.date}T${window.timeTo}`),
          }))
          .orderBy((window) => window.from)
          .map((window) => getNextDate(lastDate, window))
          .thru(
            (results) =>
              results.find(
                ({ compliance }) => compliance !== ScheduleCompliance.DELAYED,
              ) ?? results[0],
          )
          .value();
      } else if (category === OrderPointCategory.CHECKPOINT) {
        if (lastDate == null) {
          result = { compliance: ScheduleCompliance.ON_TIME, next: null };
        } else {
          result = chain(lastDate)
            .thru((lastDate) =>
              Array.from({ length: 4 }).map((_, index) =>
                lastDate.plus({ days: index }),
              ),
            )
            .map((date) => {
              const openingHours = CommonHelper.getOpeningHours(
                date,
                point.businessHours as BusinessHours,
              );
              if (!openingHours) return null;

              const timeFrom = DateTime.fromISO(openingHours.from);
              const timeTo = DateTime.fromISO(openingHours.to);

              const from = date.set({
                hour: timeFrom.hour,
                minute: timeFrom.minute,
              });
              const to = date.set({
                hour: timeTo.hour,
                minute: timeTo.minute,
              });

              return { from, to };
            })
            .map((window) => getNextDate(lastDate, window))
            .thru(
              (results) =>
                results.find(({ compliance: ok }) => ok) ?? results[0],
            )
            .value();
        }
      } else if (category === OrderPointCategory.TRANSIT) {
        const [type, date, time] = [
          point.timeWindow.type,
          point.timeWindow.date!,
          point.timeWindow.time!,
        ];

        if (type === OrderTransitPointTimeWindowType.FIX) {
          const dateTime = DateTime.fromISO(`${date}T${time}`);
          const window = { from: dateTime, to: dateTime };
          result = getNextDate(lastDate, window);
        } else {
          result = { compliance: ScheduleCompliance.ON_TIME, next: lastDate };
        }
      } else {
        result = { compliance: ScheduleCompliance.DELAYED, next: lastDate };
      }

      lastDate = result.next;
      if (lastDate && serviceTime) {
        lastDate = lastDate.plus({ seconds: serviceTime });
      }

      pointsCompliance.push(result.compliance);
      if (
        result.compliance === ScheduleCompliance.DELAYED &&
        options.showErrors
      ) {
        pointForm.setErrors({ invalidTimeWindow: true });
      }
    }

    let routeCompliance = ScheduleCompliance.ON_TIME;
    if (pointsCompliance.includes(ScheduleCompliance.DELAYED)) {
      routeCompliance = ScheduleCompliance.DELAYED;
    } else if (pointsCompliance.includes(ScheduleCompliance.DELAY_RISK)) {
      routeCompliance = ScheduleCompliance.DELAY_RISK;
    }

    const ok = routeCompliance !== ScheduleCompliance.DELAYED;
    if (!ok && options.showErrors) {
      this.toastService.showError(
        `${this.I18N}.toasts.invalidPointsTimeWindows`,
      );
    }

    return { ok, routeCompliance, pointsCompliance };
  }

  private getRouteConfig(dto: OrderFormDto): Observable<RouteConfig> {
    const routeConfig = this.routeConfig();
    const { vehicleTypes, rate } = dto.basicInformation;

    if (routeConfig) {
      routeConfig.resultCurrency = rate.currency!;
      this.routeConfig.set(routeConfig);

      return of(routeConfig);
    }

    return OrderFormRouteConfigModalComponent.getDefaultConfig(this.injector, {
      vehicleTypes,
      resultCurrency: rate.currency!,
    }).pipe(tap((routeConfig) => this.routeConfig.set(routeConfig)));
  }

  private getValidation(
    fleetValidation: FleetValidationByType | null,
    vehicleTypes: string[],
  ): OnyxOptional<FleetValidationByType[FleetType]> {
    if (!fleetValidation) return null;
    return mergeWith(
      {},
      ...vehicleTypes.map((type) => fleetValidation[type]),
      (object: any, source: any) => {
        if (
          isObject(object) &&
          isObject(source) &&
          'type' in object &&
          'type' in source
        ) {
          object = object as ValidationField;
          source = source as ValidationField;

          const TYPE_PRIORITY: ValidationField['type'][] = [
            'forbidden',
            'conditional',
            'optional',
            'optional-in-list',
            'required',
            'required-in-list',
            'required-in-range',
          ] as const;

          const objectTypePriority = TYPE_PRIORITY.indexOf(object.type);
          const sourceTypePriority = TYPE_PRIORITY.indexOf(source.type);

          const result =
            sourceTypePriority > objectTypePriority ? source : object;
          if ('values' in source) {
            if ('values' in object) {
              result.values = uniq([...object.values, ...source.values]);
            } else {
              result.values = source.values;
            }
          }

          return result;
        }

        return undefined;
      },
    );
  }

  private buildForm(): OrderFormGroup {
    return OrderFormComponent.buildForm(
      this.fb,
      this.authService,
      this.preferencesService,
    );
  }
}
