import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable, reflectComponentType, Type } from '@angular/core';
import { isEqual } from 'lodash';
import { filter, fromEvent, map, race, zip } from 'rxjs';
import { OnyxModalComponent } from '../components';
import { OnyxModalConfig, OnyxModalResult } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class OnyxModalService {
  private readonly ONYX_MODAL_TAG_NAME =
    reflectComponentType(OnyxModalComponent)!.selector.toUpperCase();

  private openedModals: {
    component: Type<any>;
    data?: unknown;
    dialogRef: DialogRef<any>;
  }[] = [];

  constructor(private dialog: Dialog) {}

  public open<Data = unknown, Result = unknown>(
    component: Type<any>,
    data?: Data,
    config?: OnyxModalConfig,
  ): OnyxModalResult<Result> {
    const getModalElement = (dialogRef: DialogRef<any>): Element | null => {
      const dialogElement: HTMLElement =
        dialogRef.componentRef!.location.nativeElement;
      const modalElement = dialogElement.querySelector(
        this.ONYX_MODAL_TAG_NAME,
      );
      return modalElement;
    };

    const forceClose = (modalElement: Element | null) => {
      const forceCloseEvent = new CustomEvent('onyxModalForceClose');
      modalElement?.dispatchEvent(forceCloseEvent);
    };

    const close = (modalElement: Element | null) => {
      const closeEvent = new CustomEvent('onyxModalClose');
      modalElement?.dispatchEvent(closeEvent);
    };

    const openedModal = this.openedModals.find(
      (openedModal) =>
        openedModal.component === component && isEqual(openedModal.data, data),
    );
    if (openedModal) {
      const modalElement = getModalElement(openedModal.dialogRef);
      const wasOnFront = this.openedModals.at(-1) === openedModal;

      wasOnFront ? close(modalElement) : forceClose(modalElement);

      this.openedModals = this.openedModals.filter(
        (modal) => modal !== openedModal,
      );

      if (wasOnFront) {
        return {
          result: openedModal.dialogRef.closed,
          close: () => close(modalElement),
          forceClose: () => {
            forceClose(modalElement);
            openedModal.dialogRef.close();
          },
        };
      }
    }

    const dialogRef = this.dialog.open<Result, Data, any>(component, {
      data,
      providers: config?.providers,
      hasBackdrop: false,
      disableClose: true,
    });
    const overlayContainerElement = document.querySelector(
      '.cdk-overlay-container',
    )!;
    const modalElement = getModalElement(dialogRef);

    if (config?.disableClose !== true) {
      const escapeKey$ = dialogRef.keydownEvents.pipe(
        filter((event) => event.key === 'Escape'),
        filter((event) => !event.defaultPrevented),
      );

      const mouseDown$ = fromEvent<MouseEvent>(document, 'mousedown');
      const mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup');

      const backdropClick$ = zip(mouseDown$, mouseUp$).pipe(
        map(([down, up]) => [down.target, up.target]),
        filter((targets): targets is Node[] =>
          targets.every((target) => target instanceof Node),
        ),
        filter((targets) =>
          targets.every(
            (target) =>
              !overlayContainerElement.contains(target) ||
              target.parentElement?.tagName === this.ONYX_MODAL_TAG_NAME,
          ),
        ),
      );

      race([escapeKey$, backdropClick$]).subscribe(() => close(modalElement));
    }

    if (modalElement?.getAttribute('type') === 'right') {
      dialogRef.config.restoreFocus = false;
      dialogRef.overlayRef.updateScrollStrategy(new NoopScrollStrategy());
    }

    const modal = { component, data, dialogRef };
    this.openedModals.push(modal);

    dialogRef.closed.subscribe(() => {
      this.openedModals = this.openedModals.filter(
        (openedModal) => openedModal !== modal,
      );
    });

    return {
      close: () => close(modalElement),
      forceClose: () => {
        forceClose(modalElement);
        dialogRef.close();
      },
      result: dialogRef.closed,
    };
  }
}
