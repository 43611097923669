import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  linkedSignal,
  OnInit,
  output,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxDataWidgetComponent,
  OnyxDatePipe,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxMessageComponent,
  OnyxNamePipe,
  OnyxOptional,
  OnyxProgressComponent,
  OnyxTab,
  OnyxTabsComponent,
  OnyxTimePipe,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { chain } from 'lodash';
import { DateTime } from 'luxon';
import { DriverHelper } from '../../../dashboard/drivers/common/helpers/driver.helper';
import { SimplifiedDriver } from '../../../dashboard/drivers/common/interfaces/driver';
import { TELEMATICS_OFFLINE_THRESHOLD } from '../../../dashboard/map/common/constants/telematics-offline-threshold';
import { TelematicsHelper } from '../../../dashboard/map/common/helpers/telematics.helper';
import { TACHOGRAPH_LIMITS } from '../../constants/common/tachograph-limits';
import { DictionaryCode } from '../../enums/dictionary-code';
import { TachographWorkingMode } from '../../enums/tachograph-working-mode';
import { DurationPipe } from '../../pipes/duration.pipe';
import { I18nPipe } from '../../pipes/i18n.pipe';

type TachographDriver = Pick<
  SimplifiedDriver,
  'uuid' | 'driverData' | 'tachograph'
>;

@Component({
  selector: 'app-drivers-tachograph',
  imports: [
    OnyxTabsComponent,
    OnyxIconComponent,
    OnyxDataWidgetComponent,
    TranslatePipe,
    OnyxIconButtonComponent,
    OnyxTooltipDirective,
    OnyxMessageComponent,
    OnyxNamePipe,
    I18nPipe,
    DurationPipe,
    OnyxDatePipe,
    OnyxProgressComponent,
    OnyxTimePipe,
  ],
  templateUrl: './drivers-tachograph.component.html',
  styleUrl: './drivers-tachograph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriversTachographComponent<T extends TachographDriver>
  implements OnInit
{
  protected readonly I18N = 'components.driversTachograph';
  protected readonly TACHOGRAPH_LIMITS = TACHOGRAPH_LIMITS;
  protected readonly WORKING_MODE_SUPHEADINGS = {
    [TachographWorkingMode.RIDE]: 'labels.continuousDriving',
    [TachographWorkingMode.OTHER_WORK]: `${this.I18N}.waitingSince`,
    [TachographWorkingMode.AVAILABILITY]: `${this.I18N}.availabilitySince`,
    [TachographWorkingMode.BREAK]: `${this.I18N}.breakSince`,
    [TachographWorkingMode.OFFLINE]: 'labels.continuousDriving',
  } as const satisfies Record<TachographWorkingMode, string>;

  protected readonly ActionHelper = ActionHelper;
  protected readonly TachographWorkingMode = TachographWorkingMode;

  public primaryDriver = input.required<OnyxOptional<T>>();
  public secondaryDriver = input.required<OnyxOptional<T>>();
  public type = input.required<'vehicle' | 'order'>();
  public format = input.required<'short' | 'compact' | 'full'>();

  public driverChange = output<T>();

  protected selectedDriver = linkedSignal(() => this.drivers()[0] ?? null);
  protected selectedDriverIcon = computed(() => {
    const workingMode = this.selectedDriver().tachograph?.workingMode;
    return {
      name: TelematicsHelper.getDriverIcon(this.selectedDriver()),
      tooltip: this.getTooltip(workingMode),
    };
  });
  protected showOutdatedWarning = computed(() => {
    const updatedAt = this.selectedDriver().tachograph?.updatedAt;
    if (!updatedAt) return true;

    return (
      DateTime.fromISO(updatedAt).diffNow().negate() >
      TELEMATICS_OFFLINE_THRESHOLD
    );
  });
  protected tachograph = computed(() => this.selectedDriver().tachograph);
  protected tachographUpdatedToday = computed(() => {
    const { updatedAt } = this.tachograph() ?? {};
    if (!updatedAt) return false;
    return DateTime.fromISO(updatedAt).hasSame(DateTime.now(), 'day');
  });

  protected tabs = computed<OnyxTab<T>[]>(() =>
    this.drivers().map((driver) => {
      const workingMode = driver.tachograph?.workingMode;
      return {
        name: this.namePipe.transform(driver.driverData),
        value: driver,
        icon: { name: TelematicsHelper.getDriverIcon(driver), size: 16 },
        tooltip: this.getTooltip(workingMode),
      };
    }),
  );
  protected drivers = computed(() =>
    chain([this.primaryDriver(), this.secondaryDriver()]).compact().value(),
  );

  constructor(
    private driverHelper: DriverHelper,
    private injector: Injector,
    private namePipe: OnyxNamePipe,
  ) {}

  public ngOnInit(): void {
    effect(() => this.driverChange.emit(this.selectedDriver()), {
      injector: this.injector,
    });
  }

  protected openDriverModal(driver: T): void {
    this.driverHelper.openModal(driver.uuid);
  }

  private getTooltip(workingMode: OnyxOptional<TachographWorkingMode>): string {
    return workingMode
      ? `${DictionaryCode.TACHOGRAPH_WORKING_MODE}.${workingMode}`
      : `${this.I18N}.connectionWaiting`;
  }
}
