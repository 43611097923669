import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  linkedSignal,
  OnInit,
  Optional,
  signal,
  untracked,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxIconName,
  OnyxOption,
  OnyxToastService,
  OnyxToggleComponent,
  OnyxTooltipComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { chain, isEqual, isObject, omit } from 'lodash';
import { first, forkJoin, map, Observable, of } from 'rxjs';
import { validate as validateUuid } from 'uuid';
import { CompanyDictionaryCode } from '../../../../dashboard/management-panel/dictionaries/common/enums/company-dictionary-code';
import { CompanyDictionariesService } from '../../../../dashboard/management-panel/dictionaries/common/services/company-dictionaries.service';
import { TelematicsHelper } from '../../../../dashboard/map/common/helpers/telematics.helper';
import { OrderPointCategory } from '../../../../dashboard/orders/common/enums/order-point-category';
import { OrderFormPoint } from '../../../../dashboard/orders/common/interfaces/order-form-point';
import {
  OrderLoadingGood,
  OrderPoint,
} from '../../../../dashboard/orders/common/interfaces/order-point';
import { OrderFormService } from '../../../../dashboard/orders/common/services/order-form.service';
import { OrderLoadingPointGoodForm } from '../../../../dashboard/orders/order-form/order-points-form/order-loading-point-form/order-loading-point-good-form/order-loading-point-good-form.component';
import { OrderPointFormGroup } from '../../../../dashboard/orders/order-form/order-points-form/order-point-form/order-point-form.component';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { RoutePointState } from '../../../enums/route/route-point-state';
import { ScheduleCompliance } from '../../../enums/schedule-compliance';
import { ValidationHelper } from '../../../helpers/validation.helper';
import { RouteSubpoint } from '../../../interfaces/route/route-subpoint';
import { RouteService } from '../../../services/route.service';
import { AddressComponent } from '../../address/address.component';
import { RoutePointBorderComponent } from './route-point-border/route-point-border.component';
import { RoutePointDetailsComponent } from './route-point-details/route-point-details.component';
import { RoutePointFormComponent } from './route-point-form/route-point-form.component';
import {
  RoutePointRouteSummary,
  RoutePointRouteSummaryComponent,
} from './route-point-route-summary/route-point-route-summary.component';
import { RoutePointTimeWindowsComponent } from './route-point-time-windows/route-point-time-windows.component';

export type RoutePoint = OrderPoint | OrderFormPoint;

@Component({
  selector: 'app-route-point',
  imports: [
    RoutePointRouteSummaryComponent,
    RoutePointBorderComponent,
    RoutePointComponent,
    OnyxIconComponent,
    AddressComponent,
    TranslatePipe,
    NgTemplateOutlet,
    OnyxToggleComponent,
    OnyxTooltipComponent,
    ReactiveFormsModule,
    RoutePointFormComponent,
    RoutePointDetailsComponent,
    AsyncPipe,
    OnyxTooltipDirective,
    RoutePointTimeWindowsComponent,
    OnyxIconButtonComponent,
    OnyxDropdownDirective,
  ],
  templateUrl: './route-point.component.html',
  styleUrl: './route-point.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.expanded]': `headingExpanded() && (type() === 'point' || context().isFirst || context().isLast)`,
  },
})
export class RoutePointComponent implements OnInit {
  protected readonly HAS_MOVE = this.routeService.move$.observed;
  protected readonly HAS_EDIT = this.routeService.edit$.observed;
  protected readonly HAS_REMOVE = this.routeService.remove$.observed;
  protected readonly OPTIONS: OnyxOption<() => void>[] = [
    ...(this.HAS_EDIT
      ? [
          {
            name: 'labels.edit',
            value: () => {
              const point = this.value();
              if (this.routeService.isHandlingPoint(point)) {
                this.routeService.edit$.next({ index: this.context().index });
                return;
              }

              this.routeService.togglePointState(
                this.point(),
                RoutePointState.FORM,
              );
            },
            leftIcon: { name: 'edit', size: 16 },
            leftIconColor: 'gray',
          } as const,
        ]
      : []),
    ...(this.HAS_REMOVE
      ? [
          {
            name: 'labels.delete',
            value: () => this.removePoint(),
            leftIcon: { name: 'delete', size: 16 },
            leftIconColor: 'gray',
          } as const,
        ]
      : []),
  ];

  protected readonly DictionaryCode = DictionaryCode;

  public point = input.required<RoutePoint>();

  protected showDetails = signal(false);
  protected showForm = signal(false);
  protected actionsAttached = signal(false);

  protected headingExpanded = computed(
    () => this.showDetails() || this.showForm(),
  );
  protected subpointsExpanded = signal(false);

  protected routeSummary = computed(() => {
    const summary = this.value().route?.summary;
    if (!summary) return null;

    return this.subpoints()
      .filter((subpoint) => subpoint.type === 'point')
      .map(({ data }) => this.routeService.getValue(data).route?.summary)
      .reduce(
        (result, summary) => ({
          distance: result.distance + (summary?.distance ?? 0),
          duration: result.duration + (summary?.duration ?? 0),
          fuelConsumption:
            result.fuelConsumption + (summary?.fuelConsumption ?? 0),
          totalCost: {
            ...result.totalCost,
            resultCurrencyValue:
              result.totalCost.resultCurrencyValue +
              (summary?.totalCost.resultCurrencyValue ?? 0),
          },
        }),
        summary as RoutePointRouteSummary,
      );
  });
  protected subpoints = computed(() => {
    if (this.type() !== 'point' && !this.context().isLast) return [];

    const points = this.routeService.points();
    const point = this.point();

    const index = points.indexOf(point);
    return chain(points)
      .slice(0, index)
      .thru((previousPoints) => {
        const previousIncludedIndex = previousPoints.findLastIndex(
          (point) =>
            this.routeService.isIncludedInOrder(
              this.routeService.getValue(point),
            ) || this.routeService.isFirst(point),
        );
        if (previousIncludedIndex === -1) return previousPoints;

        return previousPoints.slice(previousIncludedIndex + 1);
      })
      .flatMap((point) => [
        ...(this.routeService
          .getValue(point)
          .route?.legs.flatMap((leg) => leg.passthroughs) ?? []),
        point,
      ])
      .thru((subpoints) => [
        ...subpoints,
        ...(this.routeService
          .getValue(point)
          .route?.legs.flatMap((leg) => leg.passthroughs) ?? []),
      ])
      .map((subpoint) => {
        const isPoint = 'category' in subpoint;
        return {
          type: isPoint ? 'point' : 'border',
          data: subpoint,
          icon: ((): OnyxIconName => {
            if (isPoint) {
              return TelematicsHelper.getPointIcon(subpoint.type);
            }

            switch (subpoint.type) {
              case 'border':
                return 'border-crossing';
            }
          })(),
        } as RouteSubpoint;
      })
      .value();
  });
  protected hasWaypoints = computed(() =>
    this.subpoints().some((subpoint) => subpoint.type === 'point'),
  );

  protected goods$ = computed<Observable<OrderLoadingGood[] | null>>(() => {
    const point = this.value();
    if (!('goods' in point)) return of(null);

    let goods: (OrderLoadingGood | OrderLoadingPointGoodForm)[];
    if (point.category === OrderPointCategory.LOADING) {
      if ('status' in point) return of(point.goods);
      goods = point.goods;
    } else {
      const loadingGoods = this.routeService
        .points()
        .map((point) => this.routeService.getValue(point))
        .filter((point) => point.category === OrderPointCategory.LOADING)
        .flatMap((point) => point.goods as typeof goods);

      goods = chain(point.goods)
        .map(({ uuid, quantity }) => {
          const good = loadingGoods.find((good) => good.uuid === uuid);
          if (!good) return null;

          return {
            ...good,
            quantity: quantity ?? 0,
            totalWeight: Math.floor(
              (good.totalWeight ?? 0) *
                ((quantity ?? 0) / (good.quantity ?? 1)),
            ),
          };
        })
        .compact()
        .value();
    }

    if (
      goods.every(
        (good): good is OrderLoadingGood =>
          (isObject(good.type) || good.type == null) && isObject(good.unit),
      )
    ) {
      return of(goods);
    }

    const goodTypes$ =
      this.orderFormService?.goodTypes$ ??
      this.companyDictionariesService.listDictionary(
        CompanyDictionaryCode.GOOD_TYPE,
      );
    const units$ =
      this.orderFormService?.units$ ??
      this.companyDictionariesService.listDictionary(
        CompanyDictionaryCode.UNIT,
      );

    return forkJoin([goodTypes$.pipe(first()), units$.pipe(first())]).pipe(
      map(([goodTypes, units]) =>
        goods.map(
          (good) =>
            ({
              ...good,
              type: isObject(good.type)
                ? good.type
                : validateUuid(good.type ?? '')
                  ? (goodTypes.find(({ uuid }) => uuid === good.type) ?? null)
                  : null,
              typeName:
                'typeName' in good
                  ? good.typeName
                  : !validateUuid(good.type ?? '')
                    ? good.type
                    : null,
              unit: units.find(({ uuid }) => uuid === good.unit),
            }) as OrderLoadingGood,
        ),
      ),
    );
  });

  protected formRestorePoint = linkedSignal(() => {
    this.showForm();
    return untracked(() => omit(this.value(), 'route'));
  });
  protected formHasChanges = computed(
    () => !isEqual(omit(this.value(), 'route'), this.formRestorePoint()),
  );

  protected context = computed(() => ({
    index: this.routeService.points().indexOf(this.point()),
    isFirst: this.routeService.isFirst(this.point()),
    isLast: this.routeService.isLast(this.point()),
  }));
  protected type = computed(() =>
    this.includedInOrder() ? 'point' : 'waypoint',
  );
  protected typeIndex = computed(() => {
    const point = this.point();
    if ('typeIndex' in point) return point.typeIndex;

    let typeIndex = 0;
    for (const point of this.routeService.points()) {
      if (point === this.point()) break;

      const value = this.routeService.getValue(point);
      if (value.type !== this.value().type) continue;

      ++typeIndex;
    }

    return typeIndex;
  });
  protected icon = computed(() => ({
    name: TelematicsHelper.getPointIcon(this.point().type),
    size: this.type() === 'point' ? 16 : 12,
  }));
  protected compliance = computed(() => {
    const value = this.orderFormService
      ?.activeRouteCompliance()
      ?.pointsCompliance.at(this.context().index);
    if (!value) return null;

    if (value === ScheduleCompliance.ON_TIME && !this.headingExpanded()) {
      return null;
    }

    return { value, color: TelematicsHelper.getScheduleComplianceColor(value) };
  });
  protected startAddress = computed(() => {
    const value = this.value();
    if ('startAddress' in value && value.startAddress) {
      return value.startAddress;
    }
    if ('address' in value && value.address) return value.address;
    return null;
  });

  protected value = computed(() => this.routeService.getValue(this.point()));
  protected form = computed(() => {
    const point = this.point();
    return 'form' in point ? point.form : null;
  });
  protected includedInOrder = computed(() =>
    this.routeService.isIncludedInOrder(this.value()),
  );

  constructor(
    private routeService: RouteService,
    private toastService: OnyxToastService,
    private companyDictionariesService: CompanyDictionariesService,
    private injector: Injector,
    @Optional() private orderFormService?: OrderFormService,
  ) {}

  public ngOnInit(): void {
    effect(
      () => {
        let state = this.routeService.getPointState(this.point());
        if (this.form()?.invalid) state = RoutePointState.FORM;

        untracked(() => {
          this.showDetails.set(state === RoutePointState.DETAILS);
          this.showForm.set(state === RoutePointState.FORM);
        });
      },
      { injector: this.injector },
    );

    effect(
      () => {
        const isExpanded = this.routeService.isPointExpanded(this.point());
        untracked(() => this.subpointsExpanded.set(isExpanded));
      },
      { injector: this.injector },
    );
  }

  protected toggleDetails(): void {
    const form = this.form();
    if (form && !ValidationHelper.checkValidity(form, this.toastService)) {
      return;
    }

    this.routeService.togglePointState(this.point());
  }

  protected movePoint(direction: -1 | 1): void {
    this.routeService.move$.next({ index: this.context().index, direction });
  }

  protected removePoint(): void {
    this.routeService.remove$.next({ index: this.context().index });
  }

  protected undoFormChanges(): void {
    const form = this.form();
    if (form) form.patchValue(this.formRestorePoint() as any);
  }

  protected trackSubpoint(
    subpoint: RouteSubpoint,
    index: number,
  ): number | OrderPointFormGroup | OrderPoint {
    if (subpoint.type === 'border') return index;

    const { data } = subpoint;
    return 'form' in data ? data.form : data;
  }
}
