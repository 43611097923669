@let messageSize = format() === 'short' ? 's' : 'm';
@let tachographDurationFormat = $any({ format: 'to', size: 's', units: ['h'] });

<div class="container">
  @if (drivers().length) {
    <div class="bar">
      @if (drivers().length === 1) {
        @let driver = drivers()[0];
        <h6 class="heading">
          <onyx-icon
            [name]="selectedDriverIcon().name"
            [size]="16"
            [onyxTooltip]="selectedDriverIcon().tooltip"
          ></onyx-icon>

          {{ driver.driverData | onyxName }}
        </h6>
      } @else {
        <onyx-tabs
          [tabs]="tabs()"
          size="m"
          [(value)]="selectedDriver"
        ></onyx-tabs>
      }

      @if (selectedDriver(); as driver) {
        <div class="buttons">
          <onyx-icon-button
            color="black"
            type="transparent"
            size="m"
            [circle]="true"
            onyxTooltip="labels.call"
            (click)="ActionHelper.makeCall(driver.driverData.privatePhone)"
          >
            <onyx-icon class="phone" name="telephone" [size]="16"></onyx-icon>
          </onyx-icon-button>

          <onyx-icon-button
            type="transparent"
            color="black"
            size="m"
            [circle]="true"
            onyxTooltip="labels.driverPreview"
            (click)="openDriverModal(driver)"
          >
            <onyx-icon name="side-modal" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      }
    </div>

    @if (selectedDriver(); as driver) {
      @if (tachograph(); as tachograph) {
        @if (showOutdatedWarning()) {
          <onyx-message
            type="warning"
            [borderRadius]="true"
            [size]="messageSize"
          >
            <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

            {{
              'outdatedTachographData'
                | i18n: I18N
                | translate: { elapsedTime: tachograph.updatedAt | duration }
            }}
          </onyx-message>
        }

        <div class="tachograph">
          @let supheading =
            $any(WORKING_MODE_SUPHEADINGS)[tachograph.workingMode];
          @if (
            [
              TachographWorkingMode.RIDE,
              TachographWorkingMode.OFFLINE,
            ].includes(tachograph.workingMode)
          ) {
            <onyx-data-widget [supheading]="supheading" [gap]="4">
              <p heading>
                {{
                  tachograph.remainingDrivingTime
                    | duration: tachographDurationFormat
                }}
              </p>

              <onyx-progress
                [value]="tachograph.remainingDrivingTime"
                [max]="TACHOGRAPH_LIMITS.continuosDrivingTime"
              ></onyx-progress>
            </onyx-data-widget>
          } @else {
            <onyx-data-widget [supheading]="supheading" [gap]="4">
              <p heading>
                {{ tachograph.updatedAt | onyxTime }}
                @if (!tachographUpdatedToday()) {
                  <span class="f-gray">
                    {{ tachograph.updatedAt | onyxDate: 'short-date' }}
                  </span>
                }
              </p>

              <onyx-progress [disabled]="true"></onyx-progress>
            </onyx-data-widget>
          }

          <onyx-data-widget supheading="labels.dailyDriving" [gap]="4">
            <p heading>
              {{
                tachograph.remainingDailyDrivingTime
                  | duration: tachographDurationFormat
              }}
            </p>

            <onyx-progress
              [value]="tachograph.remainingDailyDrivingTime"
              [max]="TACHOGRAPH_LIMITS.dailyDrivingTime"
            ></onyx-progress>
          </onyx-data-widget>

          <onyx-data-widget supheading="labels.thisWeek" [gap]="4">
            <p heading>
              {{
                tachograph.remainingWeeklyDrivingTime
                  | duration: tachographDurationFormat
              }}
            </p>

            <onyx-progress
              [value]="tachograph.remainingWeeklyDrivingTime"
              [max]="TACHOGRAPH_LIMITS.weeklyDrivingTime"
            ></onyx-progress>
          </onyx-data-widget>

          @if (format() !== 'short') {
            @let remainingNextWeekDrivingTime =
              tachograph.remainingFortnightDrivingTime -
              tachograph.remainingWeeklyDrivingTime;
            <onyx-data-widget supheading="labels.nextWeek" [gap]="4">
              <p heading>
                {{
                  remainingNextWeekDrivingTime
                    | duration: tachographDurationFormat
                }}
              </p>

              <onyx-progress
                [value]="
                  TACHOGRAPH_LIMITS.fortnightDrivingTime -
                  remainingNextWeekDrivingTime
                "
                [max]="TACHOGRAPH_LIMITS.fortnightDrivingTime"
              ></onyx-progress>
            </onyx-data-widget>
          }
        </div>
      } @else {
        <onyx-message type="warning" [borderRadius]="true" [size]="messageSize">
          <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

          {{ 'noTachographData' | i18n: I18N | translate }}
        </onyx-message>
      }
    }
  } @else {
    <div class="heading">
      <onyx-icon name="no-user-fill" [size]="16"></onyx-icon>

      <h6>{{ 'labels.driver' | translate }}</h6>
    </div>

    <onyx-message type="warning" [borderRadius]="true" [size]="messageSize">
      <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

      {{
        (type() === 'vehicle' ? 'noDriverInVehicle' : 'noDriverInOrder')
          | i18n: I18N
          | translate
      }}
    </onyx-message>
  }
</div>
