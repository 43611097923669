import { OnyxAmount } from '@onyx/angular';

export class AmountHelper {
  public static add(a: OnyxAmount, b: OnyxAmount): OnyxAmount {
    if (a.currency !== b.currency) throw new Error(`Currencies don't match`);
    if (a.resultCurrency !== b.resultCurrency) {
      throw new Error(`Result currencies don't match`);
    }

    return {
      ...a,
      value: a.value + b.value,
      euroValue: a.euroValue + b.euroValue,
      defaultCurrencyValue: a.defaultCurrencyValue + b.defaultCurrencyValue,
      ...(a.resultCurrencyValue != null && {
        resultCurrencyValue: a.resultCurrencyValue + b.resultCurrencyValue,
      }),
    };
  }
}
