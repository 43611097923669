<div class="bar">
  @if (point(); as point) {
    <div
      class="point {{ point.color }}"
      tabindex="-1"
      [onyxTooltip]="'labels.' + (expanded() ? 'collapse' : 'expand')"
      (click)="toggleExpanded()"
      (keydown)="(null)"
    >
      <onyx-icon class="point-icon" [name]="point.icon" [size]="16"></onyx-icon>

      <onyx-icon
        class="expand-icon"
        [name]="expanded() ? 'arrow-alt-up' : 'arrow-alt-down'"
        [size]="16"
      ></onyx-icon>
    </div>
  }
</div>

<div class="container" [ngClass]="{ collapsed: !expanded() }">
  <div class="heading">
    <p
      class="name f-paragraph-semibold-2"
      [ngClass]="{ invalid: invalidTimeWindow() }"
    >
      {{ point()?.name }} {{ typeIndex() + 1 }}
    </p>

    @if (
      [OrderPointCategory.CHECKPOINT, OrderPointCategory.TRANSIT].includes(
        category()
      )
    ) {
      <div class="separator"></div>

      <div class="include-in-order">
        <onyx-toggle
          [formControl]="$any(form().controls).includeInOrder"
          label="labels.includeInOrder"
          [showOptional]="false"
        ></onyx-toggle>

        <onyx-tooltip
          [tooltip]="'includeInOrderHint' | i18n: I18N | translate"
        ></onyx-tooltip>
      </div>
    }

    <div class="options">
      <onyx-icon-button
        type="transparent"
        color="black"
        size="m"
        [disabled]="first()"
        onyxTooltip="labels.moveUp"
        [onyxTooltipEnabled]="!first()"
        (click)="movePoint.emit(-1)"
      >
        <onyx-icon name="arrow-up" [size]="16"></onyx-icon>
      </onyx-icon-button>

      <onyx-icon-button
        type="transparent"
        color="black"
        size="m"
        [disabled]="last()"
        onyxTooltip="labels.moveDown"
        [onyxTooltipEnabled]="!last()"
        (click)="movePoint.emit(1)"
      >
        <onyx-icon name="arrow-down" [size]="16"></onyx-icon>
      </onyx-icon-button>

      <div class="separator"></div>

      <onyx-icon-button
        type="transparent"
        color="black"
        size="m"
        onyxTooltip="labels.delete"
        (click)="removePoint.emit()"
      >
        <onyx-icon name="delete" [size]="16"></onyx-icon>
      </onyx-icon-button>
    </div>
  </div>

  <div class="content">
    @switch (category()) {
      @case (OrderPointCategory.LOADING) {
        <app-order-loading-point-form
          [form]="$any(form())"
          [goods]="goods()"
        ></app-order-loading-point-form>
      }
      @case (OrderPointCategory.UNLOADING) {
        <app-order-unloading-point-form
          [form]="$any(form())"
          [goods]="goods()"
        ></app-order-unloading-point-form>
      }
      @case (OrderPointCategory.CHECKPOINT) {
        <app-order-checkpoint-form
          [form]="$any(form())"
        ></app-order-checkpoint-form>
      }
      @case (OrderPointCategory.TRANSIT) {
        <app-order-transit-form [form]="$any(form())"></app-order-transit-form>
      }
    }
  </div>
</div>
