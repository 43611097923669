import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { LabelLink, OnyxLinkComponent } from '@onyx/angular';

@Component({
  selector: 'app-not-found-pulse',
  imports: [TranslatePipe, OnyxLinkComponent, RouterLink],
  templateUrl: './not-found-pulse.component.html',
  styleUrl: './not-found-pulse.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPulseComponent {
  public heading = input<string>();
  public description = input<string>();
  public link = input<LabelLink>();
  public alt = input(false);
  public size = input(72);
  public height = input<`${string}px`>();
  public disabled = input(false);
}
