<onyx-section [heading]="I18N + '.heading'">
  <div heading class="heading">
    <p class="f-paragraph-semibold-3">{{ form().length }}</p>

    <onyx-add-button (click)="addDocument()"></onyx-add-button>
  </div>

  <app-documents-form-list
    [documents]="documents()"
    [form]="form()"
    [placeholder]="placeholder()"
  >
  </app-documents-form-list>
</onyx-section>
