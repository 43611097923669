<div
  class="inputs"
  [ngClass]="{ range: showTimeRange() }"
  (focusout)="onTouched?.()"
>
  <onyx-timepicker-input
    [label]="label() || !!labelTo()"
    [hint]="hint()"
    [type]="type()"
    [showClockIcon]="showClockIcon()"
    [value]="value().from ?? {}"
    [disabled]="disabled()"
    [invalid]="isInvalid() || forceError()"
    (changeValue)="changeValue('from', $event)"
  ></onyx-timepicker-input>

  @if (showTimeRange()) {
    <div class="separator" [ngClass]="{ label: label() || labelTo() }">-</div>

    <onyx-timepicker-input
      [label]="labelTo() || !!label()"
      [type]="type()"
      [showClockIcon]="showClockIcon()"
      [value]="value().to ?? {}"
      [disabled]="disabled()"
      [invalid]="isInvalid() || forceError()"
      (changeValue)="changeValue('to', $event)"
    ></onyx-timepicker-input>
  }
</div>

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [controls]="[formControl()!]"
  ></onyx-form-control-error>
}
