<div
  class="toast {{ color() }} f-paragraph-regular-3"
  [ngClass]="{ 'fade-out': fadeOutAnimation() }"
  (mouseenter)="clearTimer()"
  (mouseleave)="startTimer()"
  #toastElement
>
  @if (icon(); as icon) {
    <onyx-icon
      [name]="icon.name"
      [size]="icon.size"
      [frame]="icon.frame"
    ></onyx-icon>
  }

  @if (messageAsString(); as message) {
    <span [innerHTML]="message | translate"></span>
  }

  @if (messageAsTemplate(); as template) {
    <ng-container
      *ngTemplateOutlet="template.templateRef; context: template.context"
    ></ng-container>
  }

  <onyx-message-close-button
    [color]="color()"
    (click)="close()"
  ></onyx-message-close-button>
</div>
