import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, map, of, switchMap } from 'rxjs';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { CompanyData } from '../../../../../common/interfaces/data-providers/company-data';
import { DataProvidersService } from '../../../../../common/services/data-providers.service';
import { ContractorForm } from '../../contractor-form/contractor-form.component';
import { ContractorsRoute } from '../../contractors.routes';
import { ContractorHelper } from '../helpers/contractor.helper';
import { Contractor } from '../interfaces/contractor';
import { ContractorsService } from '../services/contractors.service';

export const editContractorResolver: ResolveFn<{
  form: ContractorForm;
  companyData: CompanyData | null;
  contractor: Contractor;
}> = (route: ActivatedRouteSnapshot) => {
  const contractorsService = inject(ContractorsService);
  const dataProvidersService = inject(DataProvidersService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;
  const urlTree = router.parseUrl(ContractorsRoute.CONTRACTORS_LIST);

  return contractorsService.getContractor(uuid).pipe(
    switchMap((contractor) => {
      if (ContractorHelper.isArchived(contractor)) {
        toastService.showError('toasts.archived', { keepOnNavigation: true });
        return of(new RedirectCommand(urlTree, { replaceUrl: true }));
      }

      const form = ContractorHelper.fromDto(contractor);
      const { country, vatId } = form.companyProfile;

      return dataProvidersService.getCompanyData(country, vatId).pipe(
        catchError(() => of(null)),
        map((companyData) => {
          if (companyData) {
            form.companyProfile.companyName = companyData.companyName;
            form.addresses.mainAddress = companyData.mainAddress;
          }

          return {
            form,
            companyData,
            contractor,
          };
        }),
      );
    }),
    catchError((response: HttpErrorResponse) => {
      if (response.status === HttpStatusCode.BadRequest) {
        toastService.showError('contractors.toasts.contractorNotFound', {
          keepOnNavigation: true,
        });
      } else {
        ValidationHelper.handleUnexpectedError(response, toastService, {
          keepOnNavigation: true,
        });
      }

      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};
